import styled, { css } from 'styled-components';

import { AbaProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Abas = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    border: 1px solid ${theme.colors.placeholder};
    margin: -4rem 0 2rem 0;
    float: left;
    align-self: flex-start;
    border-radius: 0.5rem;
    overflow: hidden;
  `};
`;

export const Aba = styled.button.attrs({
  type: 'button',
})<AbaProps>`
  ${({ theme, $active }) => css`
    background: ${$active
      ? theme.colors.houver
      : theme.colors.defaultHighlight};
    // text-decoration: ${$active ? 'underline' : 'none'};
    padding: 1rem 2rem;
    color: ${theme.colors.label};
    border-left: 1px solid ${theme.colors.placeholder};

    &:first-child {
      border-left: none;
    }
  `};
`;

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    padding: 2rem 2rem 0 2rem;
    font-weight: 700;
  `};
`;

export const ListArea = styled.div`
  padding: 2rem 2rem 0 2rem;
  float: left;
  display: flex;
  flex-direction: column;
`;

export const ListOptions = styled.div`
  float: left;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ErrosArea = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    border-radius: 1rem;
    flex-direction: row;
    border: 0.1rem solid #ef524f;
    border-radius: 0.5rem;
    align-items: center;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.06);

    span {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
    }

    h1 {
      color: ${theme.colors.error};
      font-size: 20px;
    }

    a {
      font-size: 14px;
      text-decoration: underline;
    }
  `};
`;
