import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  width: 100%;

  form {
    display: flex;
    width: 100%;
    float: left;
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: column;
`;

export const FormBlock = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: column;
`;

export const InputsDivisor = styled.div`
  display: flex;
  width: 100%;
  float: left;
  padding: 20px 10px 0 10px;

  span {
    float: left;
    height: 0.1rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const FormSubmitContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  padding: 0 1rem 1rem 1rem;

  a {
    button {
      width: 100%;
    }
    width: 25%;
    margin-right: 1rem;
  }

  button {
    margin-left: 1rem;
    width: 25%;
  }
`;
