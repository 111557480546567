import { format } from 'date-fns';

const formatTheDateYall = (date: string): string | null => {
  if (!date) {
    return null;
  }

  const [year, month, day] = date.substr(0, 10).split('-');
  return format(
    new Date(parseInt(year, 0), parseInt(month, 0) - 1, parseInt(day, 0)),
    'dd/MM/yyyy',
  );
};

function formatTheBRDate(data: string, hora: '00:00:00'): string {
  const data_formatada = `${data.substr(6, 4)}-${data.substr(
    3,
    2,
  )}-${data.substr(0, 2)} ${hora}`;
  return data_formatada;
}

function formatCustomBR(data: Date): string {
  return format(data, 'dd/MM/yyyy HH:mm');
}

export { formatTheDateYall, formatTheBRDate, formatCustomBR };
