/* eslint-disable array-callback-return */
import React, { useCallback } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { HeaderSearchResultsContent } from './styles';
import api from '~/services/api';
import { useAuth } from '~/hooks/auth';

import { HeaderSearchResultsProps } from './interface';
import { ToolsProps } from '../interface';

const HeaderSearchResults: React.FC<HeaderSearchResultsProps> = ({
  searchQuery,
  tools,
}) => {
  const { colors } = useTheme();
  const history = useNavigate();
  const { user } = useAuth();

  const handlePage = useCallback(
    async (item: ToolsProps) => {
      try {
        await api.post('ferramentas', {
          parceiro_id: user.parceiro_id,
          ...item,
        });
        // eslint-disable-next-line no-empty
      } catch {}
      history(item.page);
    },
    [history, user],
  );

  return (
    <HeaderSearchResultsContent>
      <div>
        <h3>Resultados de busca</h3>
        <h4>
          {`Estes foram os resultados encontrados para <b>${searchQuery}</b>`}
        </h4>
        {tools?.map(item => (
          <button type="button" key={item.tag} onClick={() => handlePage(item)}>
            <IoIosArrowRoundForward size={22} color={colors.label} />
            <span>{item.tag}</span>
          </button>
        ))}
      </div>
    </HeaderSearchResultsContent>
  );
};

export default HeaderSearchResults;
