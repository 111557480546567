import React from 'react';

import { Routes, Route } from 'react-router-dom';

import MainScroll from '~/components/MainScroll';

import RequireAuth from './RequireAuth';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

import Dashboard from '~/pages/Dashboard';
import NovaAba from '~/pages/NovaAba';
import Profile from '~/pages/Profile';
import Parceiro from '~/pages/Parceiro';
import Parceiros from '~/pages/Parceiros';
import ConfiguracoesDoParceiros from '~/pages/ConfiguracoesDoParceiro';

import Produto from '~/pages/Produto';
import Produtos from '~/pages/Produtos';

import Unidade from '~/pages/Unidade';
import Unidades from '~/pages/Unidades';

import VariacoesGrade from '~/pages/VariacoesGrade';
import VariacaoGrade from '~/pages/VariacaoGrade';

import GruposDeTags from '~/pages/GruposDeTags';
import GrupoDeTag from '~/pages/GrupoDeTag';

import TabelaDePreco from '~/pages/TabelaDePreco';
import TabelasDePreco from '~/pages/TabelasDePreco';

import InstituicoesFinanceiras from '~/pages/InstituicoesFinanceiras';
import InstituicaoFinanceira from '~/pages/InstituicaoFinanceira';

import Pedido from '~/pages/Pedido';

import ContasCorrentes from '~/pages/ContasCorrentes';
import ContaCorrente from '~/pages/ContaCorrente';
import NaturezasDeOperacoes from '~/pages/NaturezasDeOperacoes';
import NaturezaDeOperacao from '~/pages/NaturezaDeOperacao';

import StatusDePedidos from '~/pages/StatusDePedidos';
import StatusDePedido from '~/pages/StatusDePedido';

import TiposDePagamentos from '~/pages/TiposDePagamentos';
import TipoDePagamento from '~/pages/TipoDePagamento';

import ContaAvulsa from '~/pages/ContaAvulsa';

import Contas from '~/pages/Contas';

import Pedidos from '~/pages/Pedidos';

import ResumoDoPedido from '~/pages/ResumoDoPedido';

import ACL from '~/pages/ACL';

import Caixa from '~/pages/Caixa';

import ImportacaoNFE from '~/pages/ImportacaoNFE';
import CompraManual from '~/pages/CompraManual';
import Relatorios from '~/pages/Relatorios';

import Tributacao from '~/pages/Tributacao';
import Tributacoes from '~/pages/Tributacoes';

import Estoques from '~/pages/Estoques';
import Precos from '~/pages/Precos';

import Etiquetas from '~/pages/Etiquetas';
import Etiqueta from '~/pages/Etiqueta';
import ImpressaoDeEtiquetas from '~/pages/ImpressaoDeEtiquetas';

import NFe from '~/pages/NFe';
import EmissaoDocumentoFiscal from '~/pages/EmissaoDocumentoFiscal';
import DocumentosFiscais from '~/pages/DocumentosFiscais';

const AllRoutes: React.FC = () => (
  <MainScroll>
    <Routes>
      <Route path="/" element={<SignIn />} />

      <Route path="/login" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />

      <Route
        path="/parceiros"
        element={
          <RequireAuth>
            <Parceiros />
          </RequireAuth>
        }
      />
      <Route
        path="/parceiro/:id"
        element={
          <RequireAuth>
            <Parceiro />
          </RequireAuth>
        }
      />

      <Route
        path="/grupos-de-tags/:tipo"
        element={
          <RequireAuth>
            <GruposDeTags />
          </RequireAuth>
        }
      />
      <Route
        path="/grupo-de-tags/:tipo/:id"
        element={
          <RequireAuth>
            <GrupoDeTag />
          </RequireAuth>
        }
      />

      <Route
        path="/produtos"
        element={
          <RequireAuth>
            <Produtos />
          </RequireAuth>
        }
      />
      <Route
        path="/produto/:id"
        element={
          <RequireAuth>
            <Produto />
          </RequireAuth>
        }
      />

      <Route
        path="/unidades"
        element={
          <RequireAuth>
            <Unidades />
          </RequireAuth>
        }
      />
      <Route
        path="/unidade/:id"
        element={
          <RequireAuth>
            <Unidade />
          </RequireAuth>
        }
      />

      <Route
        path="/variacoes-grade"
        element={
          <RequireAuth>
            <VariacoesGrade />
          </RequireAuth>
        }
      />
      <Route
        path="/variacao-grade/:id"
        element={
          <RequireAuth>
            <VariacaoGrade />
          </RequireAuth>
        }
      />

      <Route
        path="/novaaba"
        element={
          <RequireAuth>
            <NovaAba />
          </RequireAuth>
        }
      />

      <Route
        path="/tabelas-de-preco"
        element={
          <RequireAuth>
            <TabelasDePreco />
          </RequireAuth>
        }
      />
      <Route
        path="/tabela-de-preco/:id"
        element={
          <RequireAuth>
            <TabelaDePreco />
          </RequireAuth>
        }
      />

      <Route
        path="/instituicoes-financeiras"
        element={
          <RequireAuth>
            <InstituicoesFinanceiras />
          </RequireAuth>
        }
      />
      <Route
        path="/instituicao-financeira/:id"
        element={
          <RequireAuth>
            <InstituicaoFinanceira />
          </RequireAuth>
        }
      />

      <Route
        path="/tabela-de-preco/:id_empresa/:id"
        element={
          <RequireAuth>
            <TabelaDePreco />
          </RequireAuth>
        }
      />

      <Route
        path="/pedido/:id"
        element={
          <RequireAuth>
            <Pedido />
          </RequireAuth>
        }
      />

      <Route
        path="/resumo-do-pedido/:id"
        element={
          <RequireAuth>
            <ResumoDoPedido />
          </RequireAuth>
        }
      />

      <Route
        path="/contas-correntes"
        element={
          <RequireAuth>
            <ContasCorrentes />
          </RequireAuth>
        }
      />
      <Route
        path="/conta-corrente/:id"
        element={
          <RequireAuth>
            <ContaCorrente />
          </RequireAuth>
        }
      />
      <Route
        path="/naturezas-de-operacoes"
        element={
          <RequireAuth>
            <NaturezasDeOperacoes />
          </RequireAuth>
        }
      />

      <Route
        path="/natureza-de-operacao/:id"
        element={
          <RequireAuth>
            <NaturezaDeOperacao />
          </RequireAuth>
        }
      />

      <Route
        path="/status-de-pedidos"
        element={
          <RequireAuth>
            <StatusDePedidos />
          </RequireAuth>
        }
      />

      <Route
        path="/status-de-pedido/:id"
        element={
          <RequireAuth>
            <StatusDePedido />
          </RequireAuth>
        }
      />

      <Route
        path="/tipos-de-pagamentos"
        element={
          <RequireAuth>
            <TiposDePagamentos />
          </RequireAuth>
        }
      />

      <Route
        path="/tipo-de-pagamento/:id"
        element={
          <RequireAuth>
            <TipoDePagamento />
          </RequireAuth>
        }
      />

      <Route
        path="/conta-avulsa"
        element={
          <RequireAuth>
            <ContaAvulsa />
          </RequireAuth>
        }
      />

      <Route
        path="/contas"
        element={
          <RequireAuth>
            <Contas />
          </RequireAuth>
        }
      />
      <Route
        path="/pedidos"
        element={
          <RequireAuth>
            <Pedidos />
          </RequireAuth>
        }
      />

      <Route
        path="/acl"
        element={
          <RequireAuth>
            <ACL />
          </RequireAuth>
        }
      />

      <Route
        path="/caixa/:id"
        element={
          <RequireAuth>
            <Caixa />
          </RequireAuth>
        }
      />

      <Route
        path="/importacao-nfe"
        element={
          <RequireAuth>
            <ImportacaoNFE />
          </RequireAuth>
        }
      />

      <Route
        path="/compra-manual/:id"
        element={
          <RequireAuth>
            <CompraManual />
          </RequireAuth>
        }
      />

      <Route
        path="/relatorio/:tipo"
        element={
          <RequireAuth>
            <Relatorios />
          </RequireAuth>
        }
      />

      <Route
        path="/tributacao/:id"
        element={
          <RequireAuth>
            <Tributacao />
          </RequireAuth>
        }
      />
      <Route
        path="/tributacoes"
        element={
          <RequireAuth>
            <Tributacoes />
          </RequireAuth>
        }
      />

      <Route
        path="/configuracoes-do-parceiro/:id"
        element={
          <RequireAuth>
            <ConfiguracoesDoParceiros />
          </RequireAuth>
        }
      />

      <Route
        path="/estoques"
        element={
          <RequireAuth>
            <Estoques />
          </RequireAuth>
        }
      />
      <Route
        path="/precos"
        element={
          <RequireAuth>
            <Precos />
          </RequireAuth>
        }
      />

      <Route
        path="/etiquetas"
        element={
          <RequireAuth>
            <Etiquetas />
          </RequireAuth>
        }
      />
      <Route
        path="/etiqueta/:id"
        element={
          <RequireAuth>
            <Etiqueta />
          </RequireAuth>
        }
      />

      <Route
        path="/impressao-de-etiquetas"
        element={
          <RequireAuth>
            <ImpressaoDeEtiquetas />
          </RequireAuth>
        }
      />

      <Route
        path="/nfe/:id"
        element={
          <RequireAuth>
            <NFe />
          </RequireAuth>
        }
      />

      <Route
        path="/documentos-fiscais"
        element={
          <RequireAuth>
            <DocumentosFiscais />
          </RequireAuth>
        }
      />

      <Route
        path="/emissao-documento-fiscal/:id"
        element={
          <RequireAuth>
            <EmissaoDocumentoFiscal />
          </RequireAuth>
        }
      />
    </Routes>
  </MainScroll>
);

export default AllRoutes;
