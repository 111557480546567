import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  FindParceiro,
  DatePicker,
  Loader,
  Input,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  EmpresaType,
  SelectType,
  FiltroType,
  NaturezaOperacaoType,
} from '~/types';

import { FiltrosProps, FormData } from './interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const [cliente, setParceiro] = useState({ id: '', descricao: '' });
  const [empresa, setEmpresa] = useState<SelectType>({
    value: '',
    label: '',
    color: '',
  });

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [naturezas_de_operacoes, set_naturezas_de_operacoes] = useState<
    SelectType[]
  >([]);

  const submeterFormulario = useCallback(
    (data: FormData) => {
      const filtros: FiltroType[] = [];
      const {
        data_fim,
        data_inicio,
        codigo,
        modelo,
        tipo,
        natureza_operacao_id,
      } = data;

      filtros.push({
        nome: 'codigo',
        valor: codigo,
      });

      filtros.push({
        nome: 'cliente_id',
        valor: cliente.id,
      });

      filtros.push({
        nome: 'modelo',
        valor: modelo,
      });

      filtros.push({
        nome: 'tipo',
        valor: tipo,
      });

      filtros.push({
        nome: 'data_fim',
        valor: data_fim,
      });

      filtros.push({
        nome: 'data_inicio',
        valor: data_inicio,
      });

      filtros.push({
        nome: 'empresa_id',
        valor: empresa.value,
      });

      filtros.push({
        nome: 'natureza_operacao_id',
        valor: natureza_operacao_id,
      });

      aoAplicarFiltros(filtros);
    },
    [cliente, empresa, aoAplicarFiltros],
  );

  useEffect(() => {
    const carregarEmpresas = async () => {
      setLoading(true);
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        if (empresasFetched && empresasFetched.length) {
          const frstEmpresa = empresasFetched[0];
          if (frstEmpresa) {
            setEmpresa({
              value: `${frstEmpresa.value}`,
              label: frstEmpresa.label,
              color: '',
            });
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };

    const carregarNaturezaOperacoes = async () => {
      setLoading(true);
      try {
        const naturezaOperacoesFetch = await api.get(
          'pedido/natureza-operacao',
        );
        const naturezaOperacoesFetched = [] as SelectType[];
        naturezaOperacoesFetch.data.forEach((item: NaturezaOperacaoType) => {
          naturezaOperacoesFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });
        set_naturezas_de_operacoes(naturezaOperacoesFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };

    carregarEmpresas();
    carregarNaturezaOperacoes();
  }, []);

  return (
    <Container>
      <Loader visible={loading} />
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            data_inicio: format(
              new Date(new Date().setDate(new Date().getDate() - 5)),
              'yyyy-MM-dd',
            ),
            data_fim: format(new Date(), 'yyyy-MM-dd'),
          }}
        >
          <InputContainer>
            <span>Código</span>
            <div>
              <Input name="codigo" placeholder="Código..." />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tipo</span>
            <div>
              <Select
                name="tipo"
                loading={loading}
                options={[
                  { value: '0', label: 'Entrada', cor: '' },
                  { value: '1', label: 'Saída', cor: '' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Modelo</span>
            <div>
              <Select
                name="modelo"
                loading={loading}
                options={[
                  { value: '55', label: 'NFe', cor: '' },
                  { value: '65', label: 'NFce', cor: '' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Natureza Operação</span>
            <div>
              <Select
                name="natureza_operacao_id"
                loading={loading}
                options={naturezas_de_operacoes}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Destinatário</span>
            <FindParceiro
              placeholderInput="Informe o nome do destinatário"
              placeholderValue="Selecione um destinatário"
              tipos="cliente,fornecedor"
              onSelectValue={(selecionados, descricao) => {
                if (selecionados[0]) {
                  setParceiro({
                    id: selecionados[0].id,
                    descricao,
                  });
                }
              }}
            />
          </InputContainer>

          <InputContainer>
            <span>Empresa</span>
            <div>
              <Select
                name="empresa_id"
                loading={loading}
                options={empresas}
                value={empresa}
                onSelectedOption={item => {
                  setEmpresa({
                    value: item.value,
                    label: item.label,
                    color: '',
                  });
                }}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data inicial</span>
            <div>
              <DatePicker
                name="data_inicio"
                mask="99/99/9999"
                placeholder="Informe a Data Inicial"
                defaultValue={format(new Date(), 'yyyy-MM-dd')}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data final</span>
            <div>
              <DatePicker
                name="data_fim"
                mask="99/99/9999"
                placeholder="Informe a Data Final"
                defaultValue={format(new Date(), 'yyyy-MM-dd')}
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button
                style={{ width: 150 }}
                type="submit"
                background={colors.green}
              >
                Filtrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
