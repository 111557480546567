import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0 0 2rem 0;
    }

    button {
      span {
        margin-right: 1rem;
      }
    }
  }
`;

export const Image = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    float: left;
    max-width: 30rem;
    max-height: 30rem;
  }
`;

export const Helper = styled.p`
  float: left;
  margin: 0rem;
  padding: 0rem;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }

  button {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    align-self: flex-end;
    width: auto;
    margin-left: 1.5rem;
  }
`;
