import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsGrid3X3Gap } from 'react-icons/bs';

import { Header, Loader, Switch } from '~/components';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { ParamsProps, UnidadeFormData } from './interface';
import { handlerErrors } from '~/utils/error';

const VariacaoGrade: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id, ativo: true });
  const [registerId, setRegisterId] = useState('0');

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: UnidadeFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
          tipo: Yup.string().min(1).required('Informe o tipo do registro'),
        });

        await schema.validate(data, { abortEarly: false });

        const variacaoData = data;
        delete variacaoData.data_cadastro;
        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `produtos/variacao-grades${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            variacaoData,
          );

          history(`/variacao-grade/${response.data.id}`);
          setRegisterId(response.data.id);

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  const clearForm = useCallback(() => {
    setLoading(true);
    formRef.current?.reset();
    setRegisterData({ id: '0', ativo: true });
    setRegisterId('0');
    history(`/variacao-grade/0`);
    setLoading(false);
  }, [history]);

  useEffect(() => {
    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response = await api.get(`/produtos/variacao-grades/${id}`);
        setRegisterData({
          ...response.data,
          data_cadastro: format(
            new Date(response.data.created_at),
            'dd/MM/yyyy HH:mm',
          ),
          tipo: {
            label: response.data.tipo.toUpperCase(),
            value: response.data.tipo,
          },
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    loadRegister();
  }, [clearForm, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Variações de grade"
                      subtitle="Adicione os dados cadastrais do produto"
                      icon={() => (
                        <BsGrid3X3Gap size={32} color={colors.label} />
                      )}
                    />
                    <InputsContainer>
                      {registerData.id !== '0' && (
                        <>
                          <InputContainer disabled={formLoading}>
                            <span>Data do Cadastro</span>
                            <div>
                              <Input
                                name="data_cadastro"
                                disabled
                                placeholder="Data do Cadastro"
                              />
                            </div>
                          </InputContainer>
                        </>
                      )}
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe a descricao da variação"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Tipo</span>
                        <div>
                          <Select
                            name="tipo"
                            loading={loading}
                            options={[
                              { value: 'cor', label: 'COR' },
                              { value: 'tamanho', label: 'TAMANHO' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/variacoes-grade">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default VariacaoGrade;
