import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';

import { GiPriceTag } from 'react-icons/gi';

import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader, NoPermission } from '~/components';

import api from '~/services/api';

import { TabelaPrecoType } from '~/types';
import { validarPermissao } from '~/utils/permissions';

const Tabeladeprecos: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);

  const [precos, setPrecos] = useState<Array<TabelaPrecoType>>([]);

  const handlerEditTabeladepreco = useCallback(
    (tabelaDePreco: TabelaPrecoType) => {
      history(`/tabela-de-preco/${tabelaDePreco.id}`);
    },
    [history],
  );

  useEffect(() => {
    async function fetchPrecos() {
      try {
        setLoading(true);
        const response = await api.get(`tabelas-preco`);
        setPrecos(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    fetchPrecos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'tabelas-preco-produtos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Plate
          title="Tabelas de Preços"
          subtitle="Cadastre as tabelas de preços das empresas"
          icon={() => <GiPriceTag size={32} color={colors.label} />}
        >
          <Link to="/tabela-de-preco/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {precos ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'empresa',
                label: 'Empresa',
                search: false,
              },
              {
                column: 'ativo',
                label: 'Ativo',
                search: false,
              },
            ]}
            data={[
              ...precos.map(preco => ({
                ...preco,
                empresa: preco.empresa.fantasia,
                ativo: preco.ativo ? 'SIM' : 'NAO',
              })),
            ]}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditTabeladepreco(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default Tabeladeprecos;
