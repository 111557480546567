import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.footer};
    box-shadow: ${theme.shadows.highlight};
    padding-bottom: 2rem;
    border-bottom-left-radius: ${theme.radio.default};
    border-bottom-right-radius: ${theme.radio.default};

    > span {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.placeholder};
      margin: 1.5rem 1rem;
      width: 100%;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `}
`;

export const ListPaginationContainer = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 2rem 0 0rem;

    button,
    span {
      width: 3.6rem;
      height: 3.6rem;
      border: 0.1rem solid ${theme.colors.defaultHighlight};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      border-radius: 0.2rem;
      color: ${theme.colors.label};
      background: ${theme.colors.background};
      cursor: default;
      font-size: ${theme.fontSizes.big};
    }

    button:hover {
      cursor: pointer;
      background: ${theme.colors.primary};
    }

    button.active {
      background: ${theme.colors.primary};
      pointer-events: none;
      cursor: default;
    }

    div {
      height: 3.6rem;
      background: ${theme.colors.primaryDark};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      margin-left: 2rem;
      font-weight: 500;
      color: ${theme.colors.white};
    }
  `}
`;
