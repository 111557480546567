import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.section)`
  /* flex: 1; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-gap: 1rem;
  width: 100%;
  float: left;
`;
