import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { BiCheck } from 'react-icons/bi';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  TagsAreaFooter,
  TagsEmpty,
  TagsGroups,
  TagsGroupsItem,
} from './styles';

import { Button } from '~/components';

import TagsAreaOff from './TagsAreaOff';
import TagsAreaOn from './TagsAreaOn';

import { GrupotagsType, TagType } from '~/types';
import { TagsProps, currentGroupType, currentTagsType } from './interface';

const Tags: React.FC<TagsProps> = ({
  groupsTags,
  defaultTags,
  onRequestClose,
  onRequestExport,
}) => {
  const { colors } = useTheme();

  const [currentGroup, setCurrentGroup] = useState<currentGroupType>(undefined);
  const [selectedTags, setSelectedTags] = useState<currentTagsType>(
    defaultTags || undefined,
  );
  const [inRenderizer, setInRenderizer] = useState(false);

  const handlerSetGroup = (group: GrupotagsType): void => {
    setCurrentGroup(group);
  };

  const handlerSaveTag = (tag: TagType): void => {
    setInRenderizer(true);
    setSelectedTags(
      selectedTags
        ? [...selectedTags.filter(currentTag => currentTag.id !== tag.id), tag]
        : [tag],
    );
    setTimeout(() => {
      setInRenderizer(false);
    }, 150);
  };

  const handlerRemoveTag = (tag: TagType): void => {
    setInRenderizer(true);
    setSelectedTags(
      selectedTags
        ? [...selectedTags.filter(currentTag => currentTag.id !== tag.id)]
        : [],
    );
    setTimeout(() => {
      setInRenderizer(false);
    }, 150);
  };

  const handlerExportTags = (): void => {
    onRequestExport(selectedTags || []);
  };

  useEffect(() => {
    if (groupsTags && groupsTags.length) {
      handlerSetGroup(groupsTags[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TagsGroups>
        {groupsTags.map(group => (
          <TagsGroupsItem
            key={group.id}
            onClick={() => handlerSetGroup(group)}
            $active={currentGroup && currentGroup.id === group.id}
          >
            {group.nome}
            {currentGroup && currentGroup.id === group.id ? (
              <BiCheck color={colors.green} size="22" />
            ) : null}
          </TagsGroupsItem>
        ))}
      </TagsGroups>
      {!inRenderizer ? (
        <DndProvider backend={HTML5Backend}>
          {groupsTags.map(group => (
            <React.Fragment key={group.id}>
              <TagsAreaOff
                visible={!!(currentGroup && currentGroup.id === group.id)}
                tags={group.tags}
                selectedTags={selectedTags || []}
                groupId={group.id}
                onSaveTag={handlerSaveTag}
              />
            </React.Fragment>
          ))}

          <TagsAreaOn
            selectedTags={selectedTags || []}
            onRemoveTag={handlerRemoveTag}
          />
        </DndProvider>
      ) : (
        <TagsEmpty>
          <span>
            <i />
          </span>
        </TagsEmpty>
      )}

      {currentGroup && (
        <TagsAreaFooter $disabled={inRenderizer}>
          <Button
            type="button"
            background={colors.contrast}
            onClick={onRequestClose}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            background={colors.green}
            onClick={handlerExportTags}
          >
            Salvar
          </Button>
        </TagsAreaFooter>
      )}
    </>
  );
};

export default Tags;
