import React from 'react';

import { ListsContainer } from './styles';

import { ListsProvider } from '~/hooks/lists';

const Lists: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ListsProvider>
      <ListsContainer>{children}</ListsContainer>
    </ListsProvider>
  );
};

export default Lists;
