import React, { useRef, useState, useCallback, useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ChromePicker } from 'react-color';

import { ColorPickerProps } from './interface';

import { Content } from './styles';

import spectrumImg from '~/assets/spectrum.png';

const ColorPicker: React.FC<ColorPickerProps> = ({ onSave }) => {
  const [currentColor, setCurrentColor] = useState('');
  const [showColorPallet, setShowColorPallet] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleChangeCompleteColor = (color: any): void => {
    setCurrentColor(`${color.hex}`);
    onSave(`${color.hex}`);
  };

  const onRequestClose = useCallback(() => {
    setShowColorPallet(false);
  }, []);

  const handlerHideContent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  return (
    <Content>
      <button
        onClick={() => {
          setShowColorPallet(!showColorPallet);
        }}
        type="button"
      >
        <img src={spectrumImg} alt="OnStackERP" />
        <span
          style={{
            backgroundColor: `${currentColor || 'inherit'}`,
          }}
        />
      </button>
      {showColorPallet ? (
        <div ref={ref}>
          <AiOutlineCloseCircle
            onClick={() => setShowColorPallet(false)}
            size={32}
            color="#fff"
          />
          <ChromePicker
            color={currentColor}
            onChangeComplete={handleChangeCompleteColor}
            disableAlpha
          />
        </div>
      ) : null}
    </Content>
  );
};

export default ColorPicker;
