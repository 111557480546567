import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

// import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Textarea,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { ParamsProps, EmissaoDocumentoFiscalFormData } from './interface';

import { handlerErrors } from '~/utils/error';

import { NaturezaOperacaoType, PedidoType, SelectType } from '~/types';

import ambientes from '~/schemas/NFe/ambiente.json';
import modalidadesFrete from '~/schemas/NFe/tipo-frete.json';
import tiposDeEmissao from '~/schemas/NFe/tipo-emissao.json';
import { handlerNumberToString } from '~/utils/money';
import { formatTheDateYall } from '~/utils/formatDate';

import ResumoDoPedido from './ResumoDoPedido';

const EmissaoDocumentoFiscal: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [naturezas_de_operacoes, set_naturezas_de_operacoes] = useState<
    SelectType[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id });
  const [registerId, setRegisterId] = useState('0');
  const [pedido, setPedido] = useState<PedidoType | null>(null);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: EmissaoDocumentoFiscalFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          natureza_operacao_id: Yup.string().required(
            'Selecione a natureza da operação',
          ),
          transportadora: Yup.string().required('Selecione a transportadora'),
          ambiente: Yup.string().required('Selecione o ambiente'),
          modalide_de_frete: Yup.string().required('Selecione a modalidade'),
          tipo_emissao_nfe: Yup.string().required(
            'Selecione o tipo de emissão da NFe',
          ),
          informacao_complementar: Yup.string().required(
            'Informe a informações complementar',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `endpoint-da-emissao-nfe${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            {
              pedido: registerId,
            },
          );

          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'NFe emitida com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId],
  );

  function clearForm() {
    setRegisterData({ id: '0' });
    formRef.current?.reset();
  }

  useEffect(() => {
    // const carregarNaturezaOperacoes = async () => {
    //   setLoading(true);
    //   try {
    //     const naturezaOperacoesFetch = await api.get(
    //       'pedido/natureza-operacao',
    //     );
    //     const naturezaOperacoesFetched = [] as SelectType[];
    //     naturezaOperacoesFetch.data.forEach((item: NaturezaOperacaoType) => {
    //       naturezaOperacoesFetched.push({
    //         value: item.id,
    //         label: item.descricao,
    //         color: '',
    //       });
    //     });
    //     set_naturezas_de_operacoes(naturezaOperacoesFetched);
    //     setLoading(false);
    //   } catch (e) {
    //     setLoading(false);
    //   }
    // };

    // carregarNaturezaOperacoes();

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response: { data: PedidoType } = await api.get(`pedido/${id}`);
        const { data } = response;
        if (data) {
          const cPedido: PedidoType = { ...data };
          setPedido(cPedido);
          console.log('pedido', cPedido);
        }
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    loadRegister();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="resumo-do-pedido">Resumo do Pedido</AnchorLink>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <FormBlock>
                  <Plate
                    title="Emissão de Documento Fiscal"
                    subtitle="Realize a emissão de NFe do pedido"
                    icon={() => null}
                  />

                  {pedido ? (
                    <AnchorRef anchor="resumo-do-pedido">
                      <ResumoDoPedido pedido={pedido} />
                    </AnchorRef>
                  ) : null}

                  <AnchorRef anchor="dados-cadastrais">
                    <InputsContainer>
                      {/* <InputContainer>
                        <span>Natureza Operação:</span>
                        <div>
                          <Select
                            name="natureza_operacao_id"
                            loading={loading}
                            options={naturezas_de_operacoes}
                          />
                        </div>
                      </InputContainer> */}

                      <InputContainer disabled={formLoading}>
                        <span>Ambiente:</span>
                        <div>
                          <Select
                            name="ambiente"
                            loading={loading}
                            options={[
                              ...ambientes.map(ambiente => ({
                                value: ambiente.value,
                                label: ambiente.label,
                                cor: '',
                              })),
                            ]}
                            defaultValue={{
                              label: '1 - Produção',
                              value: 1,
                            }}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Transportadora:</span>
                        <div>
                          <Select
                            name="transportadora"
                            loading={loading}
                            options={[]}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Modalidade de Frete:</span>
                        <div>
                          <Select
                            name="modalide_de_frete"
                            loading={loading}
                            options={[
                              ...modalidadesFrete.map(modalidadeFrete => ({
                                value: modalidadeFrete.value,
                                label: modalidadeFrete.label,
                                cor: '',
                              })),
                            ]}
                            defaultValue={{
                              label: '9 - Sem frete',
                              value: 9,
                            }}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Tipo de emissão da NFe:</span>
                        <div>
                          <Select
                            name="tipo_emissao_nfe"
                            loading={loading}
                            options={[
                              ...tiposDeEmissao.map(tipoEmissao => ({
                                value: tipoEmissao.value,
                                label: tipoEmissao.label,
                                cor: '',
                              })),
                            ]}
                            defaultValue={{
                              label: '1 - Emissão normal',
                              description:
                                'Emissão normal (não em contingência)',
                              value: 1,
                            }}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Informação complementar</span>
                        <div>
                          <Textarea
                            name="informacao_complementar"
                            placeholder="Informação complementar da NFe"
                            maxLength={250}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </AnchorRef>
                </FormBlock>
              </FormContent>
              <FormFooter>
                <Link to="/pedidos">
                  <Button type="button" background={colors.contrast}>
                    Cancelar
                  </Button>
                </Link>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Emitir Doc. Fiscal
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default EmissaoDocumentoFiscal;
