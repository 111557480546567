import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
    height: 100%;

    @media (min-width: 1981px) {
      font-size: 80%;
    };
  }

  body {
    width: 100%;
    float: left;
    height: auto;
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.label};
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  body.lock {
    overflow: hidden;
  }

  #root {
    height: 100%;
  }

  * {
    font-family: 'Inter', sans-serif;
  }
  
  body, input, textarea, button {
    font-family: 'Inter', sans-serif;
  }

  body, input, textarea, button {
    line-height: 1.48;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  a {
    text-decoration: none;
    background: none;
    font-weight: 700;
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  .swal2-overlay {
    background: rgba(0, 0, 0, 0.9);
  }

  .swal2-confirm {
    min-width: 10rem !important;
  }

  .swal2-deny {
    min-width: 10rem !important;
  }

  .swal2-cancel {
    min-width: 10rem !important;
  }

  /* .swal-modal {
    border-radius: 2px;
    background: rgba(0, 0, 0, 0);
    margin-top: -100px;
    width: 800px;
  }
  .swal-title {
    color: rgba(255, 255, 255, 0.8);
    font-size: 28px;
    font-weight: normal;
  }
  .swal-title:not(:last-child) {
    margin-bottom: 0px;
  }

  .swal-text {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
    font-weight: normal;
  }

  .swal-button {
    padding: 20px 40px 20px 40px;
    border-radius: 0px;
    font-weight: normal;
    font-size: 12px;
    transition: all 0.5s ease;
  }

  .swal-button:hover {
    opacity: 0.5;
  }

  .swal-footer {
    text-align: center;
  }

  .swal-icon--success:after,
  .swal-icon--success:before,
  .swal-icon--success__hide-corners {
    background: rgba(0, 0, 0, 0);
  } */



`;
