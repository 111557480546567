import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';
import api from '~/services/api';

import { Container } from './styles';

import {
  FormContainer,
  FormContent,
  FormFooter,
  FormBlock,
  InputsContainer,
  InputContainer,
  Input,
  Button,
  Switch,
  Loader,
  Modal,
  ErrorMessage,
} from '~/components';

import { CPFormData, CondicaoDePagamentoProps } from './interface';
import { handlerErrors } from '~/utils/error';

const CondicaoDePagamento: React.FC<CondicaoDePagamentoProps> = ({
  id,
  tipo_pagamento_id,
  onRequestSave,
  onRequestClose,
}) => {
  const { colors } = useTheme();
  const [loading, setLoading] = useState(true);

  const [registerId, setRegisterId] = useState('0');
  const [registerData, setRegisterData] = useState({ id, ativo: true });

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CPFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Descrição obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            {
              ...data,
              mobile: false,
            },
          );

          setRegisterId(response.data.id);

          setFormLoading(false);

          onRequestSave();

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, onRequestSave, tipo_pagamento_id, registerId],
  );

  useEffect(() => {
    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id && id !== '0') {
        const response = await api.get(
          `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes/${id}`,
        );

        const cp = response.data;

        setRegisterData({
          ...cp,
          instituicao_financeira_id: cp.instituicao_financeira
            ? {
                value: cp.instituicao_financeira.id,
                label: cp.instituicao_financeira.descricao,
              }
            : {
                value: null,
                label: 'NENHUMA',
              },
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    const initialize = async () => {
      try {
        await loadRegister();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    initialize();
  }, [tipo_pagamento_id, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title="Condição de pagamento"
      visible
      width="60rem"
      onRequestClose={onRequestClose}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            initialData={registerData}
            onSubmit={handleSubmit}
          >
            <ErrorMessage
              error={formError}
              onDismiss={() => setFormError('')}
            />

            <FormContent>
              <FormBlock>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Descrição</span>
                    <div>
                      <Input
                        name="descricao"
                        placeholder="Informe um nome para a condição de pagamento"
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Parcelas</span>
                    <div>
                      <Input
                        name="parcelas"
                        placeholder="Ex. 1, 2 ... 10"
                        type="number"
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Recorrência</span>
                    <div>
                      <Input
                        name="recorrencia"
                        placeholder="Dê 1 á 30"
                        type="number"
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>1ª parcela</span>
                    <div>
                      <Input
                        name="primeira_parcela"
                        placeholder="Em dias"
                        type="number"
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Taxa R$</span>
                    <div>
                      <Input name="taxa" placeholder="Ex. 2,5%" type="number" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Compra?</span>
                    <div className="switch">
                      <Switch name="compra" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Venda?</span>
                    <div className="switch">
                      <Switch name="venda" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Ativo?</span>
                    <div className="switch">
                      <Switch name="ativo" />
                    </div>
                  </InputContainer>
                </InputsContainer>
              </FormBlock>
            </FormContent>

            <FormFooter>
              <Button
                type="button"
                background={colors.grey}
                onClick={onRequestClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                background={colors.green}
                loading={formLoading}
              >
                Salvar
              </Button>
            </FormFooter>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default CondicaoDePagamento;
