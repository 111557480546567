import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { RiFilterFill, RiFilterOffFill } from 'react-icons/ri';

import {
  Container,
  Content,
  Scroll,
  ScrollContent,
  Nav,
  Control,
} from './styles';

const BarraLateral: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { colors } = useTheme();

  const [barraVisivel, setBarraVisivel] = useState(true);

  const inverterVisibilidade = () => {
    setBarraVisivel(!barraVisivel);
  };

  return (
    <Container $visibilidade={barraVisivel}>
      <Content $visibilidade={barraVisivel}>
        <Nav>
          <Control onClick={inverterVisibilidade}>
            {barraVisivel ? (
              <RiFilterFill size={32} color={colors.label} />
            ) : (
              <RiFilterOffFill size={32} color={colors.label} />
            )}
          </Control>
        </Nav>
        <Scroll $visibilidade={barraVisivel}>
          <ScrollContent>{children}</ScrollContent>
        </Scroll>
      </Content>
    </Container>
  );
};

export default BarraLateral;
