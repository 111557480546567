import styled, { css } from 'styled-components';

import { ValProps, ItemProps } from './interface';

export const Container = styled.div`
  width: 100%;
  float: left;
  background: ${({ theme }) => theme.colors.secondary};
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8rem;
  float: left;
  width: 100%;
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    position: fixed;
    top: 8rem;
    border-bottom: 0.1rem solid ${theme.colors.grey};
    background: ${theme.colors.secondary};

    @media (max-width: 1024px) {
      position: inherit;
      flex-direction: column;
      padding: 2rem 0;
    }
  `}
`;

export const Block = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
    display: flex;
    width: 25%;
    float: left;
    padding: ${theme.spacing.default};

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0rem;
    }
  `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.grey};
    margin-right: 0.5rem;
    font-weight: 700;
  `}
`;

export const Val = styled.div<ValProps>`
  ${({ theme, cor }) => css`
    color: ${cor || theme.colors.label};
    font-size: ${theme.fontSizes.default};
    font-weight: 700;
  `}
`;

export const Left = styled.div`
  ${({ theme }) => css`
    width: 40rem;
    background: ${theme.colors.defaultHighlight};
    border: 0.1rem solid ${theme.colors.grey};
    padding: ${theme.spacing.default};
    border-radius: ${theme.radio.default};
    float: left;
    position: fixed;
    top: 18rem;
    left: 2rem;

    @media (max-width: 1024px) {
      position: inherit;
    }
  `}
`;

export const LeftInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    float: left;

    span {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
      font-weight: 700;
    }

    strong {
      color: ${theme.colors.primary};
      font-size: ${theme.fontSizes.default};
      font-weight: 700;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    left: 0rem;
    bottom: 0rem;
    background: ${theme.colors.secondary};
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    padding: ${theme.spacing.tiny};
    padding-left: 44rem;
    justify-content: space-between;
    border-top: 0.1rem solid ${theme.colors.grey};

    button {
      width: 15rem;
    }

    @media (max-width: 1024px) {
      position: inherit;
      padding: 0;
    }
  `}
`;

export const Opts = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentEmpty = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;

    span {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.placeholder};
    }
  `}
`;

export const Opt = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${theme.fontSizes.default};
    font-weight: 500;
    transition: all 0.5s ease;
    color: ${theme.colors.label};
    cursor: pointer;
    background: none;
    transition: all 0.5 ease;
    padding: 0rem 1rem;
    border-radius: ${theme.radio.default};
    margin-left: 1rem;

    span {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
      /* font-weight: 600; */
      margin: 0 1rem 0 0.6rem;
    }

    &:hover {
      background: ${theme.colors.defaultHighlight};
    }
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.horizontal};
    padding-left: 44rem;

    @media (max-width: 1024px) {
      padding: 0rem;
    }
  `}
`;

export const Item = styled.div<ItemProps>`
  ${({ theme, eImpar }) => css`
    display: flex;
    flex-direction: column;
    padding: 0;
    background: ${eImpar
      ? theme.colors.defaultHighlight
      : theme.colors.background};
    border: 0.1rem solid ${theme.colors.grey};
    border-bottom: none;
    padding: ${theme.spacing.default};

    &:first-child {
      border-radius: ${theme.radio.default} ${theme.radio.default} 0 0;
      margin-top: 10rem;
    }

    &:last-child {
      border-radius: 0 0 ${theme.radio.default} ${theme.radio.default};
      border-bottom: 0.1rem solid ${theme.colors.grey};
      margin-bottom: 10rem;
    }

    @media (max-width: 1024px) {
      margin: 0 !important;
    }
  `}
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Prop = styled.div<ValProps>`
  ${({ theme, cor }) => css`
    display: flex;
    flex-direction: row;
    width: 33.3%;
    float: left;

    @media (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
    }

    span {
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.grey};
      margin-right: 0.5rem;
      font-weight: 700;
    }

    strong {
      color: ${cor || theme.colors.label};
      font-size: ${theme.fontSizes.default};
      font-weight: 700;
    }
  `}
`;
