import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

export const List = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fffdcc;
  overflow-y: auto;
  float: left;
  border-radius: 0.5rem 0.5rem 0 0;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    background: ${theme.colors.defaultHighlight};
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0.8rem;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
    }

    h1 {
      font-size: ${theme.fontSizes.default};
      margin: 0rem;
    }
  `}
`;

export const Clear = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.darkRed};
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-left: 1rem;
    transition: all 0.5s ease;

    &:hover {
      background: ${darken(0.05, theme.colors.darkRed)};
    }
  `}
`;
