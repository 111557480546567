import React, { useState, useEffect, useCallback } from 'react';

import { BsGraphUp } from 'react-icons/bs';
import swal from 'sweetalert2';

import { useTheme } from 'styled-components';

import api from '~/services/api';

import { useCart } from '~/hooks/cart';
import { useAuth } from '~/hooks/auth';

import { ReactTooltip } from '~/components';

import { Container, MenuItem, Label } from './styles';

import { CaixaProps } from './interface';

import { ContaCorrenteType } from '~/types';

const Caixa: React.FC<CaixaProps> = () => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const { empresa_id, set_conta_corrente_id } = useCart();

  const [inLoad, setInLoad] = useState(true);

  const [conta_corrente, set_conta_corrente] =
    useState<ContaCorrenteType | null>(null);

  const loadContasCorrentes = useCallback(async () => {
    try {
      setInLoad(true);
      const response = await api.get(
        `/financeiros/contas-correntes?empresa_id=${empresa_id}&usuario_id=${user.parceiro_id}&tipo=CAIXA&status=ABERTA`,
      );
      const { data } = response;
      if (data) {
        const { data: results } = data;
        if (results && results.length) {
          set_conta_corrente_id(results[0].id);
          set_conta_corrente(results[0]);
        }
      }
      setInLoad(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setInLoad(false);
    }
  }, [empresa_id, set_conta_corrente_id, user]);

  const abrirContaCorrente = async () => {
    if (empresa_id) {
      swal
        .fire({
          title: 'Confirma a abertura de caixa?',
          text: `O caixa será aberto ao usuário logado!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              setInLoad(true);
              const response = await api.post(`financeiros/contas-correntes`, {
                empresa_id,
                ativo: true,
                descricao: 'CAIXA-FRENTE',
                tipo: 'CAIXA',
                instituicao_financeira_id: null,
                status: 'ABERTA',
                compartilhada: false,
              });
              const { data } = response;
              if (data) {
                loadContasCorrentes();
              }
              setInLoad(false);
              // eslint-disable-next-line no-empty
            } catch (e) {
              setInLoad(false);
            }
          }
        });
    } else {
      swal.fire({
        title: 'Atenção!',
        text: `Selecione uma empresa para abrir um caixa!`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  useEffect(() => {
    if (empresa_id && user.parceiro_id) {
      loadContasCorrentes();
    }
  }, [user, empresa_id, loadContasCorrentes]);

  if (inLoad) return null;

  return (
    <Container data-tooltip-id="caixa-btn">
      <ReactTooltip
        id="caixa-btn"
        content={`${
          conta_corrente
            ? `${conta_corrente.descricao}`
            : 'Nenhuma conta corrente do tipo caixa associada a esta empresa, clique para abrir!'
        }`}
      />
      <MenuItem
        disabled={!!conta_corrente || inLoad}
        onClick={abrirContaCorrente}
      >
        <BsGraphUp size={45} color={colors.label} />
        <span>Caixa</span>
        {conta_corrente ? (
          <Label $status={conta_corrente.status}>ABERTO</Label>
        ) : null}
      </MenuItem>
    </Container>
  );
};

export default Caixa;
