import React from 'react';
import { ImNewTab } from 'react-icons/im';
import { ReactTooltip } from '~/components';

import { Container } from './styles';

const NovaTab: React.FC = () => {
  return (
    <Container data-tooltip-id="novaaba" href="#/novaaba" target="_blank">
      <ReactTooltip id="novaaba" content="Abrir nova aba" />
      <ImNewTab size={24} color="#f5f5f5" />
    </Container>
  );
};

export default NovaTab;
