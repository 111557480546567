import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  border-bottom: 0.1rem solid #abaebf;
  padding: 0.8rem;
  cursor: pointer;
  transition: all 0.5s ease;
  justify-content: space-between;

  > div {
    display: flex;
    float: left;
    flex: 1;
    flex-direction: column;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: none;
    margin-bottom: none;
  }

  h1 {
    color: #373636;
    font-size: 1.6rem;
    font-weight: 700;
  }

  small {
    color: #090909;
    font-size: 1.2rem;
  }

  button {
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

export const Clear = styled.button.attrs({
  type: 'button',
})`
  background: #d82e2e;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 1rem;
  transition: all 0.5s ease;

  &:hover {
    background: ${darken(0.05, '#d82e2e')};
  }
`;

export const Edit = styled.button.attrs({
  type: 'button',
})`
  background: #cfa921;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    background: ${darken(0.05, '#cfa921')};
  }
`;
