import styled, { css } from 'styled-components';
import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;

export const Information = styled.div`
  ${({ theme }) => css`
    border-radius: 0.6rem;
    padding: 2rem;
    color: ${theme.colors.label};
    display: flex;
    flex-direction: column;
    float: left;
    font-size: ${theme.fontSizes.big};
    width: 100%;

    h1 {
      font-size: ${theme.fontSizes.bigger};
      font-weight: 700;
      justify-content: space-between;
      display: flex;
      align-items: flex-end;

      @media (max-width: 1024px) {
        font-size: ${theme.fontSizes.tiny};
      }
    }

    i {
      width: 100%;
      background: ${theme.colors.contrast};
      height: 2px;
      float: left;
      margin: 1rem 0 2rem 0;
    }

    > div {
      float: left;
      width: 100%;
      margin-bottom: 2rem;
    }

    p {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-transform: uppercase;
      }
    }

    h1 button {
      width: 22rem;
    }

    span {
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      float: left;
      display: flex;
      align-items: center;
      margin: 0 0 0rem 0;
      font-size: ${theme.fontSizes.bigger};
      color: ${theme.colors.label};

      @media (max-width: 1024px) {
        font-size: ${theme.fontSizes.small};
        flex-direction: column;
        margin: 0 0 2rem 0;
      }
    }

    b {
      font-size: ${theme.fontSizes.large};
      margin-right: 1rem;
      color: ${theme.colors.grey};
      font-weight: normal;
      text-transform: uppercase;
    }
  `}
`;

export const ComoPagouControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
`;

export const ComoPagouControlIc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  padding: 1rem;
  border-radius: 100%;
  transition: all 0.5s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHighlight};
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;
