import styled, { css } from 'styled-components';

import { TagDragContainerProps } from './interface';

export const TagDragContainer = styled.div<TagDragContainerProps>`
  ${({ theme, $active }) => css`
    height: auto;
    border: 3px dashed;
    border-color: ${$active ? theme.colors.white : 'rgba(0,0,0,0)'};
    float: left;
    border-radius: 10px;
  `}
`;
