import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { AiFillDelete } from 'react-icons/ai';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { TiDocumentText, TiLocation } from 'react-icons/ti';
import { getValidationErrors } from '~/utils';
import { formatTheDateYall } from '~/utils/formatDate';

import Header from '~/components/Header';
import Loader from '~/components/Loader';
import Modal from '~/components/Modal';
import Tags from '~/components/Tags';

import { Container, Content, Tag, TagsContent } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  DatePicker,
  ErrorMessage,
  FindParceiro,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputMask,
  InputNumber,
  InputsContainer,
  Plate,
  Select,
  Switch,
  Textarea,
  NoPermission,
} from '~/components';

import { useToast } from '~/hooks/toast';

import ParceirosTipos from '~/schemas/parceiros-tipo.json';

import api from '~/services/api';

import { useAuth } from '~/hooks/auth';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

import ModalAlterarSenha from '~/components/ModalAlterarSenha';

import Endereco from './Endereco';
import Enderecos from './Enderecos';

import Telefone from './Telefone';
import Telefones from './Telefones';

import {
  EnderecoType,
  GrupotagsType,
  PapelType,
  ParceiroType,
  TagType,
  TelefoneType,
} from '~/types';

import {
  ParamsProps,
  TypePartnerProps,
  currentAddressType,
  currentAddressesType,
  currentTelefoneType,
  currentTelefonesType,
} from './interface';
import { validarPermissao } from '~/utils/permissions';

interface FindProps {
  id: string;
  descricao: string;
}

const Partner: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();
  const { user } = useAuth();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [nomeFantasiaUpdated, setNomeFantasiaUpdated] = useState(false);
  const [typePartner, setTypePartner] = useState<string[]>([]);
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [CpfCnpjMask, setCpfCnpjMask] = useState('99.999.999/9999-99');
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState({
    codigo: id ? id : '0',
    cliente: { limite_credito: 0 },
  });
  const [partnerId, setPartnerId] = useState('0');
  const [vendedor, setVendedor] = useState<FindProps | null>(null);

  const [passwordUpdateModalVisible, setPasswordUpdateModalVisible] =
    useState(false);

  // TAGS
  const [grupoTags, setGruposTags] = useState<GrupotagsType[]>([]);
  const [tagsModalVisible, setTagsModalVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>([]);

  // ENDEREÇOS
  const [addressModalVisible, setaddressModalVisible] = useState(false);
  const [currentAddress, setCurrentAddress] =
    useState<currentAddressType>(undefined);
  const [addresses, setAddresses] = useState<currentAddressesType>(undefined);

  const [papeis, setPapeis] = useState<PapelType[]>([]);
  const formRef = useRef<FormHandles>(null);

  // TELEFONES
  const [telefoneModalVisible, setTelefoneModalVisible] = useState(false);
  const [currentTelefone, setCurrentTelefone] =
    useState<currentTelefoneType>(undefined);
  const [telefones, setTelefones] = useState<currentTelefonesType>(undefined);

  // ENDEREÇOS
  const handlerRequestClose = (): void => {
    setaddressModalVisible(false);
  };

  const handlerRequestOpen = (): void => {
    setaddressModalVisible(true);
  };

  const handlerCreateAddress = (): void => {
    setCurrentAddress(undefined);
    handlerRequestOpen();
  };

  const handlerRequestSave = (address: EnderecoType): void => {
    if (address) {
      setAddresses(
        addresses
          ? [...addresses.filter(item => item.id !== address.id), address]
          : [address],
      );
      handlerRequestClose();
    }
  };

  const handlerEditAddress = (address: EnderecoType): void => {
    setCurrentAddress(address);
    handlerRequestOpen();
  };

  const handlerRemoveAddress = (address: EnderecoType): void => {
    if (address && addresses) {
      swal
        .fire({
          title: 'Deseja realmente remover este endereço?',
          text: `Ao confirmar, o endereço será removido!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            setAddresses(addresses.filter(item => item.id !== address.id));
          }
        });
    }
  };

  // TELEFONES
  const fecharTelefone = (): void => {
    setTelefoneModalVisible(false);
  };

  const abrirTelefone = (): void => {
    setTelefoneModalVisible(true);
  };

  const addTelefone = (): void => {
    setCurrentTelefone(undefined);
    abrirTelefone();
  };

  const salvarTelefone = (pTelefone: TelefoneType): void => {
    if (pTelefone) {
      if (pTelefone.id && telefones) {
        setTelefones(
          telefones.map((cTelefone: TelefoneType) => {
            if (cTelefone.id === pTelefone.id) {
              return pTelefone;
            }
            return {
              ...cTelefone,
              principal: pTelefone.principal ? false : cTelefone.principal,
            };
          }),
        );
      } else {
        const _id = telefones && telefones.length ? telefones.length + 1 : 1;
        setTelefones(
          telefones
            ? [
                ...telefones.map(cTelefone => ({
                  ...cTelefone,
                  principal: pTelefone.principal ? false : cTelefone.principal,
                })),
                { ...pTelefone, id: _id },
              ]
            : [{ ...pTelefone, id: _id }],
        );
      }

      fecharTelefone();
    }
  };

  const editarTelefone = (pTelefone: TelefoneType): void => {
    setCurrentTelefone(pTelefone);
    abrirTelefone();
  };

  const removerTelefone = (pTelefone: TelefoneType): void => {
    if (pTelefone && telefones) {
      swal
        .fire({
          title: 'Deseja realmente remover este telefone?',
          text: `Ao confirmar, o telefone será removido!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            setTelefones(telefones.filter(item => item.id !== pTelefone.id));
          }
        });
    }
  };

  // TAGS
  const handlerOpenTags = (): void => {
    setTagsModalVisible(true);
  };

  const handlerCloseTags = (): void => {
    setTagsModalVisible(false);
  };

  const handlerRemoveTag = (tagId: string): void => {
    setSelectedTags(
      selectedTags.length
        ? [...selectedTags.filter(tag => tag.id !== tagId)]
        : [],
    );
  };

  const handlerExportTags = (tags: Array<TagType>): void => {
    handlerCloseTags();
    setSelectedTags(tags);
    swal.fire({
      title: 'Concluido!',
      text: `As tags foram salvas com sucesso!`,
      icon: 'success',
      confirmButtonColor: '#3085d6',
    });
  };

  // TIPOS
  const handleChangeTypes = useCallback((items: TypePartnerProps[]) => {
    if (items) {
      const array = items.map((item: TypePartnerProps) => item.slug);
      setTypePartner(array);
    } else {
      setTypePartner([]);
    }
  }, []);

  // CPF / CNPJ
  const handleCpfCnpjChange = useCallback(
    (value: string, isChange: boolean = false) => {
      const valueFormated = value.replace(/[^0-9]/g, '');

      if (String(valueFormated).length <= 11 || Number.isNaN(valueFormated)) {
        if (isChange) {
          setCpfCnpjMask('99.999.999/9999-99');
        } else {
          setCpfCnpjMask('999.999.999-99');
        }
      }

      setCpfCnpj(value);
    },
    [],
  );

  const handleRazaoSocialChange = useCallback(
    (value: string, cloneToNomeFantasiaField: boolean = false) => {
      if (cloneToNomeFantasiaField) {
        formRef.current?.setFieldValue('nome_fantasia', value);
      }
    },
    [],
  );

  const handleNomeFantasiaKeyUp = useCallback(() => {
    const isUpdated = !!formRef.current?.getFieldValue('nome_fantasia');
    setNomeFantasiaUpdated(isUpdated);
    if (!isUpdated) {
      handleRazaoSocialChange(
        formRef.current?.getFieldValue('nome_razao'),
        true,
      );
    }
  }, [handleRazaoSocialChange]);

  function formatCpfCnpj(v: string) {
    // Remove tudo o que não é dígito
    let value = v.replace(/\D/g, '');

    if (value.length <= 14) {
      // CPF

      // Coloca um ponto entre o terceiro e o quarto dígitos
      value = value.replace(/(\d{3})(\d)/, '$1.$2');

      // Coloca um ponto entre o terceiro e o quarto dígitos
      // de novalueo (para o segundo bloco de números)
      value = value.replace(/(\d{3})(\d)/, '$1.$2');

      // Coloca um hífen entre o terceiro e o quarto dígitos
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ

      // Coloca ponto entre o segundo e o terceiro dígitos
      value = value.replace(/^(\d{2})(\d)/, '$1.$2');

      // Coloca ponto entre o quinto e o sexto dígitos
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

      // Coloca uma barra entre o oitavalueo e o nono dígitos
      value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');

      // Coloca um hífen depois do bloco de quatro dígitos
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return value;
  }

  const handleSubmit = useCallback(
    async (data: ParceiroType) => {
      try {
        setFormError('');
        setFormLoading(true);

        data.tipo = typePartner;

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome_razao: Yup.string().required('Razão social obrigatório'),
          ie_rg: Yup.string().required('Documento obrigatório'),
          nome_fantasia: Yup.string().required('Nome fantasia obrigatório'),
          tipo: Yup.array().min(1).required('Informe pelo menos um tipo'),
          cnpj_cpf: Yup.string().required('Informe um cpf ou cnpj'),
          data_nascimento: Yup.date().required(
            'Informe uma data de nascimento',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        if (typePartner.includes('FUN')) {
          const schemaFUN = Yup.object().shape({
            email: Yup.string()
              .email('Informe um email válido')
              .required('Informe um email'),
          });

          await schemaFUN.validate(data.funcionario, { abortEarly: false });
        }

        try {
          const tags: Array<string> = [];
          selectedTags.forEach(item => {
            tags.push(item.id);
          });

          let apiData = {
            ...data,

            enderecos:
              addresses && addresses.length
                ? [
                    ...addresses?.map(cAddress => {
                      if (partnerId !== '0') {
                        return {
                          ...cAddress,
                          parceiro_id: `${partnerId}`,
                        };
                      }
                      return cAddress;
                    }),
                  ]
                : [],

            telefones:
              telefones && telefones.length
                ? [
                    ...telefones.map(cTelefone => {
                      const { id, parceiro_id, ...prTelefone } = cTelefone;
                      return { ...prTelefone };
                    }),
                  ]
                : [],
            tags,
          };

          let clienteData = null;
          if (apiData.cliente) {
            clienteData = {
              ...apiData.cliente,
              vendedor_id: vendedor ? vendedor.id : undefined,
              limite_credito: apiData.cliente?.limite_credito
                ? handlerToNumber(`${apiData.cliente?.limite_credito}`)
                : 0,
            };
          }

          apiData = {
            ...apiData,
            indicador_ie: handlerToNumber(`${apiData.indicador_ie}`),
            cliente: clienteData,
            cnpj_cpf: formatCpfCnpj(`${apiData.cnpj_cpf}`),
            ie_rg: apiData.ie_rg.length > 0 ? apiData.ie_rg : 'ISENTO',
          };

          delete apiData.tipo;
          delete apiData.data_cadastro;

          if (partnerId !== '0' && apiData.funcionario) {
            delete apiData.funcionario.senha;
          }

          // console.log(apiData);

          const response = await api[partnerId !== '0' ? 'put' : 'post'](
            `parceiros${partnerId !== '0' ? `/${partnerId}` : ''}`,
            apiData,
          );

          const { data: dadosParceiroApi } = response;

          if (dadosParceiroApi) {
            history(`/parceiro/${dadosParceiroApi.id}`);
            setPartnerId(dadosParceiroApi.id);

            const dataNascimento = new Date(dadosParceiroApi.data_nascimento);
            const dataNascimentoTMZ =
              dataNascimento.valueOf() +
              dataNascimento.getTimezoneOffset() * 60 * 1000;

            const partnerDataProps = {
              ...dadosParceiroApi,
              data_nascimento: format(
                dataNascimentoTMZ,

                'dd/MM/yyyy',
              ),
            };

            if (dadosParceiroApi.cliente) {
              Object.assign(partnerDataProps, {
                cliente: {
                  ...dadosParceiroApi.cliente,
                  limite_credito: handlerNumberToString(
                    dadosParceiroApi.cliente.limite_credito,
                  ),
                },
              });
            }

            setPartnerData(partnerDataProps);
          }

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Parceiro salvo com sucesso!',
          });
        } catch (e) {
          setFormLoading(false);
          const error = `${(e as Error).message}`;
          setFormError(`${error}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [
      addToast,
      addresses,
      partnerId,
      history,
      selectedTags,
      vendedor,
      typePartner,
      telefones,
    ],
  );

  const onClosePasswordUpdateModal = useCallback(() => {
    setPasswordUpdateModalVisible(false);
  }, []);

  const onStartPasswordUpdateModal = useCallback(() => {
    setPasswordUpdateModalVisible(true);
  }, []);

  function clearForm() {
    setLoading(true);
    setTimeout(() => {
      setPartnerData({ codigo: '0', cliente: { limite_credito: 0 } });
      setTypePartner([]);
      setAddresses([]);
      setTelefones([]);
      setCpfCnpj('');
      setVendedor(null);
      formRef.current?.reset();
      setLoading(false);
    }, 200);
  }

  useEffect(() => {
    async function loadPartner() {
      // PAPEIS
      const response_papeis = await api.get(`acl/papeis`);
      const { data: data_papeis } = response_papeis;
      if (data_papeis && data_papeis.status !== 'error') {
        setPapeis(data_papeis);
      }

      // ID
      if (id) {
        setPartnerId(id);
      }

      // PARCEIRO
      if (id !== '0') {
        const response = await api.get(`parceiros/${id}`);
        let parceiroData = response.data;

        // TIPOS
        const tipo = [];

        if (parceiroData.funcionario) {
          tipo.push(ParceirosTipos[2]);

          // PAPEIS DO PARCEIRO
          let papeis = [];

          const workspace = parceiroData.workspaces.filter(
            (cWorkspace: { workspace_id: string }) =>
              String(cWorkspace.workspace_id) === String(user.workspace.id),
          )[0];

          if (workspace && workspace.papel_workspace_parceiro?.length > 0) {
            papeis = workspace.papel_workspace_parceiro.map(
              (cPapel: { papeis: PapelType }) => ({
                value: cPapel.papeis.id,
                label: cPapel.papeis.nome,
                slug: cPapel.papeis.slug,
                color: '',
              }),
            );
          }

          // const papeis = [
          //   ...parceiroData.workspaces
          //     .filter(
          //       (cWorkspace: { id: string }) =>
          //         cWorkspace.id === user.workspace.id,
          //     )[0]
          //     .papel_workspace_parceiro.map(
          //       (cPapel: { papeis: PapelType }) => ({
          //         value: cPapel.papeis.id,
          //         label: cPapel.papeis.nome,
          //         slug: cPapel.papeis.slug,
          //         color: '',
          //       }),
          //     ),
          // ];

          parceiroData = {
            ...parceiroData,
            funcionario: {
              ...parceiroData.funcionario,
              data_demissao: formatTheDateYall(
                parceiroData.funcionario.data_demissao,
              ),
              data_admissao: formatTheDateYall(
                parceiroData.funcionario.data_admissao,
              ),
              papeis,
            },
          };
        }

        if (parceiroData.cliente) {
          tipo.push(ParceirosTipos[0]);
          if (parceiroData.cliente.vendedor_id) {
            setVendedor({
              id: parceiroData.cliente.vendedor_id,
              descricao: parceiroData.cliente.vendedor.nome_fantasia,
            });
          }
        }

        if (parceiroData.fornecedor) {
          tipo.push(ParceirosTipos[1]);
        }

        if (parceiroData.contador) {
          tipo.push(ParceirosTipos[3]);
        }

        // DATA NASCIMENTO
        const dataNascimento = new Date(parceiroData.data_nascimento);
        const dataNascimentoTMZ =
          dataNascimento.valueOf() +
          dataNascimento.getTimezoneOffset() * 60 * 1000;

        // DADOS PADRÃO
        parceiroData = {
          ...parceiroData,
          data_cadastro: format(
            new Date(parceiroData.created_at),
            'dd/MM/yyyy HH:mm',
          ),
          data_nascimento: format(dataNascimentoTMZ, 'dd/MM/yyyy'),
          cnpj_cpf: formatCpfCnpj(parceiroData.cnpj_cpf),
          tipo,
        };

        if (parceiroData.cliente) {
          Object.assign(parceiroData, {
            cliente: {
              ...parceiroData.cliente,
              limite_credito: handlerNumberToString(
                parceiroData.cliente.limite_credito,
              ),
            },
          });
        }

        // CPF / CNPJ
        handleCpfCnpjChange(formatCpfCnpj(parceiroData.cnpj_cpf));

        // TIPOS
        handleChangeTypes(tipo);

        // DADOS PADRÕES
        setPartnerData(parceiroData);

        // ENDEREÇOS
        setAddresses(parceiroData.enderecos);

        // TELEFONES
        setTelefones([
          ...parceiroData.telefones.map(
            (cTelefone: TelefoneType, index: number) => ({
              ...cTelefone,
              id: index + 1,
            }),
          ),
        ]);

        // TAGS
        setSelectedTags(parceiroData.tags);

        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    loadPartner();
  }, [id, user, handleCpfCnpjChange, handleChangeTypes]);

  useEffect(() => {
    async function loadGrupoTags() {
      try {
        const response = await api.get(`grupo-tags/?tipo=parceiro`);
        const { data } = response;
        setGruposTags(data);
      } catch (e) {
        // console.log(e);
      }
    }
    loadGrupoTags();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'cadastrar-editar-parceiros')) {
    return <NoPermission />;
  }

  return (
    <Container>
      {passwordUpdateModalVisible && (
        <ModalAlterarSenha
          title="Alterar senha"
          visible={passwordUpdateModalVisible}
          onRequestClose={onClosePasswordUpdateModal}
          parceiro_id={Number(id)}
        />
      )}

      <Header />

      {addressModalVisible && (
        <Modal
          title="Cadastro de Endereço"
          visible={addressModalVisible}
          onRequestClose={handlerRequestClose}
          width="1000px"
        >
          <Endereco
            address={currentAddress}
            onRequestSave={handlerRequestSave}
            onRequestClose={handlerRequestClose}
          />
        </Modal>
      )}

      {telefoneModalVisible && (
        <Modal
          title="Cadastro de Telefone"
          visible={telefoneModalVisible}
          onRequestClose={fecharTelefone}
          width="1000px"
        >
          <Telefone
            telefone={currentTelefone}
            onRequestSave={salvarTelefone}
            onRequestClose={fecharTelefone}
          />
        </Modal>
      )}

      {grupoTags.length && tagsModalVisible ? (
        <Modal
          title="Cadastro de Tags"
          visible={tagsModalVisible}
          onRequestClose={handlerCloseTags}
        >
          <Tags
            groupsTags={grupoTags}
            onRequestClose={handlerCloseTags}
            defaultTags={selectedTags}
            onRequestExport={handlerExportTags}
          />
        </Modal>
      ) : null}

      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
            <AnchorLink anchor="enderecos">Endereços</AnchorLink>
            <AnchorLink anchor="telefones">Telefones</AnchorLink>
            <AnchorLink anchor="tags">Tags</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={partnerData}
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="enderecos[]" />

              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Dados Cadastrais"
                      subtitle="Adicione os dados cadastrais do parceiro"
                      icon={() => (
                        <BsFillPersonLinesFill size={32} color={colors.label} />
                      )}
                    />
                    <InputsContainer>
                      {Number(partnerData.codigo) >= 1 && (
                        <>
                          <InputContainer disabled={formLoading}>
                            <span>Código</span>
                            <div>
                              <Input
                                name="codigo"
                                textAlign="center"
                                placeholder="Informe o código"
                                disabled
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Ativo?</span>
                            <div className="switch">
                              <Switch name="ativo" />
                            </div>
                          </InputContainer>
                        </>
                      )}
                      <InputContainer disabled={formLoading} required>
                        <span>CPF / CNPJ *</span>
                        <div>
                          <InputMask
                            mask={CpfCnpjMask}
                            name="cnpj_cpf"
                            placeholder="Informe o CPF/CNPJ"
                            onBlur={() => handleCpfCnpjChange(CpfCnpj)}
                            disabled={Number(partnerData.codigo) >= 1}
                            onChange={e => {
                              handleCpfCnpjChange(e.target.value, true);
                            }}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>RG / I.E *</span>
                        <div>
                          <Input name="ie_rg" placeholder="Informe o RG/IE" />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>Tipo *</span>
                        <div>
                          <Select
                            name="tipo"
                            loading={loading}
                            isMulti
                            options={ParceirosTipos}
                            onSelectedOption={handleChangeTypes}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Data de nascimento</span>
                        <div>
                          <DatePicker
                            name="data_nascimento"
                            mask="99/99/9999"
                            placeholder="Informe a Data de Nascimento"
                          />
                        </div>
                      </InputContainer>
                      {Number(partnerData.codigo) >= 1 && (
                        <InputContainer disabled={formLoading}>
                          <span>Data do Cadastro</span>
                          <div>
                            <Input
                              name="data_cadastro"
                              disabled
                              placeholder="Informe a Data do Cadastro"
                            />
                          </div>
                        </InputContainer>
                      )}
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>Nome Razão *</span>
                        <div>
                          <Input
                            name="nome_razao"
                            placeholder="Informe a razão social"
                            onChange={e => {
                              handleRazaoSocialChange(
                                e.target.value,
                                !nomeFantasiaUpdated,
                              );
                            }}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Nome fantasia</span>
                        <div>
                          <Input
                            name="nome_fantasia"
                            placeholder="Informe o nome fantasia"
                            onKeyUp={handleNomeFantasiaKeyUp}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Contribuinte de ICMS?</span>
                        <div className="switch">
                          <Switch name="contribuinte_icms" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Indicador de IE</span>
                        <div>
                          <Input
                            name="indicador_ie"
                            placeholder="Informe o número"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Observação</span>
                        <div>
                          <Textarea
                            name="observacao"
                            placeholder="Informe a observação"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                    {typePartner.includes('FUN') && (
                      <Scope path="funcionario">
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Email</span>
                            <div>
                              <Input
                                name="email"
                                placeholder="exemplo@email.com.br"
                                normalCase
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Data Admissão</span>
                            <div>
                              <DatePicker
                                name="data_admissao"
                                mask="99/99/9999"
                                placeholder="Informe a data de admissão"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Data de Demissão</span>
                            <div>
                              <DatePicker
                                name="data_demissao"
                                mask="99/99/9999"
                                placeholder="Informe a data de demissão"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Funções</span>
                            <div>
                              <Select
                                name="papeis"
                                loading={loading}
                                isMulti
                                options={papeis.map(papel => ({
                                  value: papel.id,
                                  label: papel.nome,
                                  color: '',
                                  slug: papel.nome,
                                }))}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Nome da mãe</span>
                            <div>
                              <Input
                                name="nome_mae"
                                placeholder="Informe o nome da mãe"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Nome do pai</span>
                            <div>
                              <Input
                                name="nome_pai"
                                placeholder="Informe o nome do pai"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer
                            disabled={formLoading || partnerId !== '0'}
                          >
                            <span>Senha de acesso</span>
                            <div>
                              <Input
                                name="senha"
                                placeholder="Digite sua senha secreta"
                                type="password"
                              />
                            </div>
                          </InputContainer>

                          {partnerId !== '0' && (
                            // (getAuthUser().isAdmin ||
                            //   getAuthUser().parceiro_id === id) && (

                            <InputContainer>
                              <Button
                                onClick={onStartPasswordUpdateModal}
                                type="button"
                                background={colors.darkGreen}
                              >
                                Alterar senha
                              </Button>
                            </InputContainer>
                          )}
                        </InputsContainer>
                      </Scope>
                    )}
                    {typePartner.includes('FOR') && (
                      <Scope path="fornecedor">
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Email de compra</span>
                            <div>
                              <Input
                                name="email_compra"
                                placeholder="exemplo@email.com.br"
                                normalCase
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Scope>
                    )}
                    {typePartner.includes('CLI') && (
                      <Scope path="cliente">
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Email de nota fiscal</span>
                            <div>
                              <Input
                                name="email_nfe"
                                placeholder="exemplo@email.com.br"
                                normalCase
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Vendedor</span>
                            <div>
                              <FindParceiro
                                placeholderInput="Informe o nome do vendedor"
                                placeholderValue="Selecione um vendedor"
                                tipos="funcionario"
                                defaultValue={
                                  vendedor
                                    ? {
                                        selecionados: [
                                          {
                                            id: vendedor.id,
                                            descricao: vendedor.descricao,
                                          },
                                        ],
                                        descricao: vendedor.descricao,
                                      }
                                    : null
                                }
                                onSelectValue={(selecionados, descricao) => {
                                  if (selecionados[0]) {
                                    setVendedor({
                                      id: selecionados[0].id,
                                      descricao,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Emprego atual</span>
                            <div>
                              <Input
                                name="emprego_atual"
                                placeholder="Informe o seu atual emprego"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Referência Comercial (1)</span>
                            <div>
                              <Input
                                name="referencia_comercial1"
                                placeholder="Informe a primeira refêrencia comercial"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Referência Comercial (2)</span>
                            <div>
                              <Input
                                name="referencia_comercial2"
                                placeholder="Informe a segunda refêrencia comercial"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Permitir venda a prazo?</span>
                            <div className="switch">
                              <Switch name="permitir_venda_prazo" />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Limite de crédito</span>
                            <div>
                              <InputNumber
                                name="limite_credito"
                                placeholder="Informe o valor"
                                defaultValue={`${partnerData?.cliente?.limite_credito}`}
                                selectAllInFocus
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Scope>
                    )}
                    {typePartner.includes('CON') && (
                      <Scope path="contador">
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>CRC</span>
                            <div>
                              <Input
                                name="crc"
                                placeholder="Informe o número"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Scope>
                    )}
                  </FormBlock>
                </AnchorRef>

                <AnchorRef anchor="enderecos">
                  <FormBlock>
                    <Plate
                      title="Endereços"
                      subtitle="Adicione os endereços do parceiro"
                      icon={() => <TiLocation size={32} color={colors.label} />}
                    >
                      <Button
                        type="button"
                        background={colors.darkGreen}
                        onClick={handlerCreateAddress}
                      >
                        <FiPlus size={24} />
                      </Button>
                    </Plate>

                    <Enderecos
                      data={addresses}
                      handlerEditAddress={handlerEditAddress}
                      handlerRemoveAddress={handlerRemoveAddress}
                    />
                  </FormBlock>
                </AnchorRef>

                <AnchorRef anchor="telefones">
                  <FormBlock>
                    <Plate
                      title="Telefones"
                      subtitle="Adicione os telefones do parceiro"
                      icon={() => <TiLocation size={32} color={colors.label} />}
                    >
                      <Button
                        type="button"
                        background={colors.darkGreen}
                        onClick={addTelefone}
                      >
                        <FiPlus size={24} />
                      </Button>
                    </Plate>

                    <Telefones
                      data={telefones}
                      handlerEdit={editarTelefone}
                      handlerRemove={removerTelefone}
                    />
                  </FormBlock>
                </AnchorRef>

                <AnchorRef anchor="tags">
                  <FormBlock>
                    <Plate
                      title="Tags"
                      subtitle="Adicione as tags"
                      icon={() => (
                        <TiDocumentText size={32} color={colors.label} />
                      )}
                    >
                      {grupoTags.length ? (
                        <Button
                          type="button"
                          background={colors.darkGreen}
                          onClick={handlerOpenTags}
                        >
                          <FiPlus size={24} />
                        </Button>
                      ) : null}
                    </Plate>
                    {grupoTags.length ? (
                      <TagsContent>
                        {selectedTags && selectedTags.length ? (
                          selectedTags.map(tag => (
                            <Tag $cor={tag.cor} key={tag.id}>
                              {tag.nome}
                              <button
                                type="button"
                                onClick={() => handlerRemoveTag(tag.id)}
                              >
                                <AiFillDelete color={colors.label} size="26" />
                              </button>
                            </Tag>
                          ))
                        ) : (
                          <h4>Nenhuma tag adicionada ainda!</h4>
                        )}
                      </TagsContent>
                    ) : (
                      <TagsContent>
                        <h4>Nenhum grupo de tags disponível</h4>
                      </TagsContent>
                    )}
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/parceiros">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                {/* <Link to="/parceiro/0"> */}
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                {/* </Link> */}
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default Partner;
