import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0rem;
    top: 0rem;
    background: ${theme.colors.overlay};
    z-index: 6000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    opacity: 1;
    visibility: visible;

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 60rem;
    height: auto;
    border-radius: 1rem;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: column;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    margin: -12rem 0 0 0;

    @media (max-width: 1024px) {
      margin: -4rem 2rem 2rem 2rem;
      width: 100%;
    }
  `}
`;

export const Information = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    h2 {
      font-size: 1.4rem;
      text-align: center;
      color: ${theme.colors.grey};
    }

    @media (max-width: 1024px) {
      h1 {
        font-size: 1.6rem;
      }

      h2 {
        font-size: 1rem;
      }
    }
  `}
`;

export const IC = styled.div`
  ${({ theme }) => css`
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
    background: ${theme.colors.green};
    border: 0.4rem solid ${theme.colors.white};
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    margin: -4rem 0 0 0;
  `}
`;

export const Opts = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  padding: 0rem 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Opt = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    background: ${theme.colors.green};
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    align-items: center;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    flex: 1;
    padding: 1.5rem 1rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 0.8rem;

    &:hover {
      background: ${theme.colors.contrast} !important;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 0 1rem;

      span {
        font-size: 1.2rem;
        color: ${theme.colors.white};
        text-align: left;
        font-weight: 700;
      }

      small {
        font-size: 1rem;
        color: ${theme.colors.white};
        opacity: 0.8;
        text-decoration: underline;
        text-align: left;
      }
    }

    @media (max-width: 1024px) {
      margin: 1rem 0 0 0;
      width: 100%;
    }
  `}
`;

export const Or = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem;
    align-items: center;

    span {
      font-size: 2rem;
      padding: 0 2rem;
      color: ${theme.colors.defaultHighlight};
    }

    ins {
      width: 100%;
      flex: 1;
      display: flex;
      height: 0.2rem;
      background-color: ${theme.colors.defaultHighlight};
    }
  `}
`;

export const Footer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  padding: 0rem 1rem 2rem 1rem;
  justify-content: center;
`;
