import React, { useEffect, useState } from 'react';

import { Container, List, Item } from './styles';

import { CheckoutProps } from './interface';

import { Modal } from '~/components';

import { CondicaoPagamentoType } from '~/types';

const CondicaoDePagamento: React.FC<CheckoutProps> = ({
  condicoesPagamento,
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  const [condicoesDePagamentos, setCondicoesDePagamentos] = useState<
    Array<CondicaoPagamentoType>
  >([]);

  const handlerOnRequestClose = () => {
    onRequestClose();
  };

  const handlerOnRequestSave = (condicaoDePagamento: CondicaoPagamentoType) => {
    onRequestSave(condicaoDePagamento);
    onRequestClose();
  };

  useEffect(() => {
    setCondicoesDePagamentos(condicoesPagamento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condicoesPagamento]);

  return (
    <Modal
      title="Selecione uma Condição de Pagamento"
      width="90rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <List>
          {condicoesDePagamentos
            .sort((a, b) =>
              a.descricao === b.descricao
                ? 0
                : a.descricao > b.descricao
                ? 1
                : -1,
            )
            .map(condicaoDePagamento => (
              <Item
                key={condicaoDePagamento.id}
                onClick={() => handlerOnRequestSave(condicaoDePagamento)}
              >
                {condicaoDePagamento.descricao}
              </Item>
            ))}
        </List>
      </Container>
    </Modal>
  );
};

export default CondicaoDePagamento;
