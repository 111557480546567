import styled, { css } from 'styled-components';

import { TitleProps } from './interface';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    flex-direction: row;
    padding: 5px 0;
  `}
`;

export const Title = styled.div<TitleProps>`
  ${({ theme, $active }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    float: left;
    transition: all 0.5s ease;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;

    /* text-decoration: ${$active ? 'underline' : 'none'}; */
    background: ${$active ? '#0175a7' : 'none'};
    /* &:hover {
      text-decoration: underline;
    } */

    span {
      margin: 0 1rem;
      font-size: ${theme.fontSizes.default};
    }
  `}
`;
