import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    height: 8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    left: 0rem;
    top: 0rem;
    z-index: 2000;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
  `}
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`;

export const HeaderWorkspace = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 5rem;
    padding: 0 4rem 0 1rem;
    border-radius: ${theme.radio.default};
    justify-content: center;
    /* opacity: 0.6; */
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.orange};
    border-bottom: 0.4rem solid ${theme.colors.white};
    box-shadow: ${theme.shadows.default};

    h4 {
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.default};
      /* line-height: ${theme.fontSizes.default}; */
      margin-top: 0.2rem;
    }

    h2 {
      color: rgba(255, 255, 255, 0.6);
      font-size: ${theme.fontSizes.tiny};
      /* line-height: ${theme.fontSizes.small}; */
    }
  `}
`;

export const HeaderCenter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`;

export const HeaderRightOpt = styled.div`
  margin-right: 1rem;

  @media (max-width: 1024px) {
    margin-right: 0rem;

    &:nth-child(0) {
      display: none;
    }

    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      display: none;
    }

    &:nth-child(3) {
      display: none;
    }

    &:nth-child(5) {
      display: none;
    }
  }
`;
