import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: center;
  width: 100%;
  flex: 1;
`;

export const Search = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.background};
    padding: 2rem 2rem 2rem 1rem;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: ${theme.radio.default};
    margin: 2rem 0;
  `}
`;

export const SearchContent = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  form {
    display: flex;
    flex: 1;
  }
`;

export const ContentSearchAdd = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 17rem !important;
    background-color: ${theme.colors.orange};
    border-radius: ${theme.radio.default};
    transition: all 0.5s ease;
    height: 5rem;
    &:hover {
      background: ${darken(0.1, theme.colors.orange)};
    }
  `}
`;

export const SearchList = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    float: left;
    display: flex;
    flex-direction: row;
    height: 5rem;
    padding: 0 2rem;
    background: ${theme.colors.defaultHighlight};
    margin-left: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    &:hover {
      background: ${darken(0.1, theme.colors.defaultHighlight)};
    }
  `}
`;

export const Empty = styled.div`
  ${({ theme }) => css`
    text-align: center;
    span {
      color: ${theme.colors.error};
      font-size: ${theme.fontSizes.big};
      font-weight: 500;
    }
  `}
`;
