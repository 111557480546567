import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
`;

export const Mode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
`;

export const Opt = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    flex: 1;
    border: 0.1rem solid;
    flex: 1;
    border-radius: 1rem;
    padding: 2rem 1rem;
    border-color: ${theme.colors.defaultHighlight};

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }

    span.rotate svg {
      transform: rotate(90deg);
    }
  `};
`;

export const Label = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    float: left;
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    margin-top: 1rem;
    font-weight: 700;
  `};
`;

export const IC = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
  position: absolute;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
  width: 100%;
  padding: 2rem 0;
`;
