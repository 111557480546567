import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    float: left;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 0 0 0 2rem;
    border: 1px solid ${theme.colors.placeholder};
    border-radius: 0.5rem;
    margin: 2rem 2rem 0 0;
    box-shadow: ${theme.shadows.highlight};

    &.success {
      border: 1px solid ${theme.colors.green} !important;
    }

    &.error {
      border: 1px solid ${theme.colors.error} !important;
    }
  `};
`;

export const Content = styled.div`
  ${({ theme }) => css`
    float: left;
    flex-direction: column;
    display: flex;
    border-radius: 0 0.5rem 0.5rem 0;
    align-items: flex-end;
    padding: 1.5rem 2rem 1.5rem 2rem;
    margin: 0 0 0 1.5rem;
    border-left: 1px solid ${theme.colors.placeholder};
    background: ${theme.colors.contrast};
    box-shadow: ${theme.shadows.highlight};

    &.success {
      border-left: 1px solid ${theme.colors.green} !important;
    }

    &.error {
      border-left: 1px solid ${theme.colors.error} !important;
    }

    strong {
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.label};
    }

    small {
      margin-bottom: 1rem;
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.placeholder};
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      p {
        float: left;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        span {
          display: flex;
          flex-direction: column;
          padding-left: 5px;
        }

        h1 {
          color: ${theme.colors.error};
          font-size: 14px;
        }

        a {
          font-size: 12 px;
          text-decoration: underline;
        }
      }

      button {
        background: none;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: 0.5rem;
        border: 1px solid ${theme.colors.controlFocus};
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          background: ${theme.colors.defaultHighlight};
        }
      }

      button.save {
        margin-left: 20px;
        width: 4.6rem;
        height: 4.6rem;
        background: ${theme.colors.defaultHighlight};

        &:hover {
          background: ${theme.colors.green};
        }
      }
    }
  `};
`;

const spinAnimation = keyframes`
  	0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
`;

export const Loading = styled.p`
  ${({ theme }) => css`
    background: none;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colors.controlFocus};
    cursor: pointer;
    transition: all 0.5s ease;

    span {
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
      animation: ${spinAnimation} 2s linear infinite;
    }
  `};
`;

export const IC = styled.div`
  position: absolute;
  margin: -2.5rem -3.5rem 0 0;
`;
