import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    float: left;
    background: ${theme.colors.contrast};
    box-shadow: ${theme.shadows.highlight};
    border-bottom: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
  `};
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
`;

export const SummaryLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const SummaryRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Config = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
`;

export const IC = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: ${theme.colors.defaultHighlight};
    box-shadow: ${theme.shadows.flat};
    border: 0.1rem solid;
    border-radius: 1rem;
    padding: 2rem;
    border-color: ${theme.colors.greyLowerOpacity};
  `};
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: ${theme.fontSizes.bigger};
    padding: 0 0 0 2rem;
    font-weight: 700;
  `};
`;

export const Control = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.defaultHighlight};
    box-shadow: ${theme.shadows.flat};
    border: 0.1rem solid;
    border-radius: 1rem;
    padding: 2rem;
    border-color: ${theme.colors.greyLowerOpacity};
    width: 22rem;
    margin: 0 2rem 2rem 0;

    &:hover {
      background: ${theme.colors.houver};
    }
  `};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Name = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: ${theme.fontSizes.big};
    color: ${theme.colors.label};
    margin-bottom: 1rem;
  `};
`;

export const Description = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: ${theme.colors.grey};
    text-align: center;
  `};
`;

export const Generate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: none;
  margin: 2rem 0 0 0;
`;
