import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 2rem;
  padding: 0 1rem;
  align-items: flex-end;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;
