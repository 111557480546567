import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { AiFillDelete, AiOutlineDatabase } from 'react-icons/ai';
import { BsFillPuzzleFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { TiDocumentText } from 'react-icons/ti';
import swal from 'sweetalert2';

import api from '~/services/api';

import { useAuth } from '~/hooks/auth';

import { Header, Loader, Logs, ReactTooltip } from '~/components';

import Estoques from './Estoques';
import InformacoesComerciais from './InformacoesComerciais';
import VariacoesProduto from './VariacoesProduto';
import Fotos from './Fotos';

import {
  Container,
  Content,
  LogsToogleControl,
  Tag,
  TagsContent,
} from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
  Plate,
  Select,
  Switch,
  Tags,
  Textarea,
  NoPermission,
} from '~/components';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';
import { handlerErrors } from '~/utils/error';
import { validarPermissao } from '~/utils/permissions';

import {
  GrupotagsType,
  InformacaoComercialType,
  SelectType,
  TagType,
  TipoProdutosType,
  UnidadeType,
  VariacaoProdutoType,
} from '~/types';

import { ParamsProps, ProdutoFormData } from './interface';

const Produto: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();
  const { user } = useAuth();

  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalLogsVisible, setModalLogsVisible] = useState(false);
  const [produtoData, setProdutoData] = useState<ProdutoFormData>({
    codigo: id ? id : '0',
    descricao: '',
    grade: false,
    informacoes_comerciais: [],
    variacoes: [],
    estoques: [],
  });
  const [produtoId, setProdutoId] = useState('0');
  const [unidades, setUnidades] = useState<SelectType[]>([]);
  const [tiposProduto, setTiposProduto] = useState<SelectType[]>([]);
  const [grupoTags, setGruposTags] = useState<GrupotagsType[]>([]);

  const [tagsModalVisible, setTagsModalVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>([]);
  const [grade, setGrade] = useState(false);

  const handlerOnChangeGDV = (checked: boolean): void => {
    setGrade(checked);
  };

  const formRef = useRef<FormHandles>(null);

  // Tags
  const handlerOpenTags = (): void => {
    setTagsModalVisible(true);
  };

  const handlerCloseTags = (): void => {
    setTagsModalVisible(false);
  };

  const handlerRemoveTag = (tagId: string): void => {
    setSelectedTags(
      selectedTags.length
        ? [...selectedTags.filter(tag => tag.id !== tagId)]
        : [],
    );
  };

  const handlerExportTags = (tags: Array<TagType>): void => {
    handlerCloseTags();
    setSelectedTags(tags);
    swal.fire({
      title: 'Concluido!',
      text: `As tags foram salvas com sucesso!`,
      icon: 'success',
      confirmButtonColor: '#3085d6',
    });
  };

  function clearForm() {
    setLoading(true);
    setTimeout(() => {
      setProdutoData({
        codigo: '0',
        descricao: '',
        grade: false,
        informacoes_comerciais: [],
        variacoes: [],
        estoques: [],
      });
      setProdutoId('0');
      setGrade(false);
      history(`/produto/0`);
      formRef.current?.reset();
      setLoading(false);
    }, 200);
  }

  const handleSubmit = useCallback(
    async (data: ProdutoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Nome obrigatório'),
          tipo_id: Yup.string().min(1).required('Informe o tipo do produto'),
          unidade_id: Yup.string()
            .min(1)
            .required('Informe a unidade do produto'),
          peso_bruto: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          peso_liquido: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          largura: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          altura: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          profundidade: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
        });

        await schema.validate(data, { abortEarly: false });

        const tags: Array<string> = [];
        selectedTags.forEach(item => {
          tags.push(item.id);
        });

        const apiData = {
          ...data,
          descricao_fiscal: data.descricao,
          tags,
          peso_bruto: data.peso_bruto ? data.peso_bruto : 0,
          peso_liquido: data.peso_liquido ? data.peso_liquido : 0,
          largura: data.largura ? data.largura : 0,
          altura: data.altura ? data.altura : 0,
          profundidade: data.profundidade ? data.profundidade : 0,
          comprimento: data.comprimento ? data.comprimento : 0,
          garantia: data.garantia ? data.garantia : 0,
          grade,
        };

        delete apiData.tipo;
        delete apiData.data_cadastro;

        try {
          const response = await api[produtoId !== '0' ? 'put' : 'post'](
            `produtos${produtoId !== '0' ? `/${produtoId}` : ''}`,
            apiData,
          );

          history(`/produto/${response.data.id}`);
          setProdutoId(response.data.id);

          setProdutoData({
            ...response.data,
            informacoes_comerciais: [],
            variacoes: [],
            estoques: [],
          });

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Produto salvo com sucesso!',
          });
        } catch (e) {
          setFormLoading(false);
          const message = handlerErrors(e);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, produtoId, grade, history, selectedTags],
  );

  const setChangedInformacoesComerciais = async (
    data: InformacaoComercialType[],
  ) => {
    setProdutoData({
      ...produtoData,
      informacoes_comerciais: data,
    });
  };

  const setChangedVariacoes = async (data: VariacaoProdutoType[]) => {
    setProdutoData({
      ...produtoData,
      variacoes: data,
    });
  };

  useEffect(() => {
    const load = async () => {
      try {
        if (id) {
          setProdutoId(id);
        }

        if (id !== '0') {
          const response = await api.get(`produtos/${id}`);
          const dataFetched = response.data;

          setProdutoData({
            ...dataFetched,
            tipo_id: {
              label: dataFetched.tipo.descricao,
              value: dataFetched.tipo_id,
            },
            unidade_id: {
              label: dataFetched.unidade.sigla,
              value: dataFetched.unidade_id,
            },
            data_cadastro: format(
              new Date(dataFetched.created_at),
              'dd/MM/yyyy HH:mm',
            ),
          });
          setSelectedTags(dataFetched.tags);
          setGrade(dataFetched.grade);
          setLoading(false);
        } else {
          clearForm();
          setLoading(false);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const loadUnidades = async () => {
      try {
        const unidadesFetch = await api.get('produtos/unidades');
        const unidadesFetched = [] as SelectType[];

        unidadesFetch.data.forEach((item: UnidadeType) => {
          if (item.ativo === true) {
            unidadesFetched.push({
              value: item.id,
              label: item.sigla,
              color: '',
            });
          }
        });

        setUnidades(unidadesFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const loadTiposProduto = async () => {
      try {
        const tiposProdutoFetch = await api.get('produtos/tipos');
        const tiposProdutoFetched = [] as SelectType[];

        tiposProdutoFetch.data.forEach((item: TipoProdutosType) => {
          tiposProdutoFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });

        setTiposProduto(tiposProdutoFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const loadGrupoTags = async () => {
      try {
        const response = await api.get(`grupo-tags/?tipo=produto`);
        const { data } = response;
        setGruposTags(data);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const initialize = async () => {
      try {
        await loadGrupoTags();
        await loadUnidades();
        await loadTiposProduto();
        await load();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    initialize();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'cadastrar-editar-produtos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      {modalLogsVisible && (
        <Logs
          module="produtos"
          label="Logs de produtos"
          onCloseModal={() => setModalLogsVisible(false)}
        />
      )}
      {grupoTags.length && tagsModalVisible ? (
        <Modal
          title="Cadastro de Tags"
          visible={tagsModalVisible}
          onRequestClose={handlerCloseTags}
        >
          <Tags
            groupsTags={grupoTags}
            onRequestClose={handlerCloseTags}
            defaultTags={selectedTags}
            onRequestExport={handlerExportTags}
          />
        </Modal>
      ) : null}

      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
            <AnchorLink anchor="tags">Tags</AnchorLink>
            {produtoId !== '0' && produtoData.grade && (
              <>
                <AnchorLink anchor="variacoes_grade">
                  Variações de Grade
                </AnchorLink>
              </>
            )}
            {produtoId !== '0' && (
              <>
                <AnchorLink anchor="informacoes-comerciais">
                  Informações Comerciais
                </AnchorLink>
                <AnchorLink anchor="estoques">Estoques</AnchorLink>
                <AnchorLink anchor="fotos">Fotos</AnchorLink>
              </>
            )}
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={produtoData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Dados Cadastrais"
                      subtitle="Adicione os dados cadastrais do produto. Campos com (*) são obrigatórios."
                      icon={() => (
                        <BsFillPuzzleFill size={32} color={colors.label} />
                      )}
                    >
                      <ReactTooltip id="logs" content="Logs" />
                      <LogsToogleControl
                        type="button"
                        data-tooltip-id="logs"
                        onClick={() => setModalLogsVisible(true)}
                      >
                        <AiOutlineDatabase color={colors.white} size={22} />
                      </LogsToogleControl>
                    </Plate>
                    <InputsContainer>
                      {Number(produtoData.codigo) >= 1 && (
                        <>
                          <InputContainer disabled={formLoading}>
                            <span>Código</span>
                            <div>
                              <Input
                                name="codigo"
                                textAlign="center"
                                placeholder="Informe o código"
                                disabled
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Ativo?</span>
                            <div className="switch">
                              <Switch name="ativo" />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Data do Cadastro</span>
                            <div>
                              <Input
                                name="data_cadastro"
                                disabled
                                placeholder="Informe a Data do Cadastro"
                              />
                            </div>
                          </InputContainer>
                        </>
                      )}
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>Nome *</span>
                        <div>
                          <Input
                            name="descricao"
                            required
                            placeholder="Informe a nome do produto"
                            maxLength={200}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Possui grade de variações (tamanho e cor)?</span>
                        <div className="switch" data-tooltip-id="grade">
                          <ReactTooltip
                            id="grade"
                            content={
                              produtoData &&
                              produtoData.informacoes_comerciais &&
                              produtoData.informacoes_comerciais.length > 0
                                ? 'Não é possível alterar essa configuração se houver informação comercial cadastrada!'
                                : ''
                            }
                          />
                          <Switch
                            disabled={
                              produtoData &&
                              produtoData.informacoes_comerciais &&
                              produtoData.informacoes_comerciais.length > 0
                            }
                            onChange={handlerOnChangeGDV}
                            name="grade"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>Tipo *</span>
                        <div>
                          <Select
                            name="tipo_id"
                            loading={loading}
                            options={tiposProduto}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Referencia</span>
                        <div>
                          <Input
                            name="referencia"
                            placeholder="Informe a referencia do produto"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading} required>
                        <span>Unidade *</span>
                        <div>
                          <Select
                            name="unidade_id"
                            loading={loading}
                            options={unidades}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Peso Bruto</span>
                        <div>
                          <Input
                            name="peso_bruto"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Peso Líquido</span>
                        <div>
                          <Input
                            name="peso_liquido"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Garantia (Meses)</span>
                        <div>
                          <Input
                            name="garantia"
                            placeholder="0"
                            maxLength={3}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Altura</span>
                        <div>
                          <Input
                            name="altura"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Largura</span>
                        <div>
                          <Input
                            name="largura"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Comprimento</span>
                        <div>
                          <Input
                            name="comprimento"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Profundidade</span>
                        <div>
                          <Input
                            name="profundidade"
                            placeholder="0,00"
                            maxLength={8}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao_completa"
                            placeholder="Informe a descrição do produto"
                            maxLength={200}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Observação</span>
                        <div>
                          <Textarea
                            name="observacao"
                            placeholder="Informe a observação"
                            maxLength={250}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Aplicação</span>
                        <div>
                          <Textarea
                            name="aplicacao"
                            placeholder="Informe a aplicação do produto"
                            maxLength={250}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
                <AnchorRef anchor="tags">
                  <FormBlock>
                    <Plate
                      title="Tags"
                      subtitle="Adicione as tags"
                      icon={() => (
                        <TiDocumentText size={32} color={colors.label} />
                      )}
                    >
                      {grupoTags.length ? (
                        <Button
                          type="button"
                          background={colors.darkGreen}
                          onClick={handlerOpenTags}
                        >
                          <FiPlus size={24} />
                        </Button>
                      ) : null}
                    </Plate>
                    {grupoTags.length ? (
                      <TagsContent>
                        {selectedTags && selectedTags.length ? (
                          selectedTags.map(tag => (
                            <Tag cor={tag.cor} key={tag.id}>
                              {tag.nome}
                              <button
                                type="button"
                                onClick={() => handlerRemoveTag(tag.id)}
                              >
                                <AiFillDelete color={colors.label} size="26" />
                              </button>
                            </Tag>
                          ))
                        ) : (
                          <h4>Nenhuma tag adicionada ainda!</h4>
                        )}
                      </TagsContent>
                    ) : (
                      <TagsContent>
                        <h4>Nenhum grupo de tags disponível</h4>
                      </TagsContent>
                    )}
                  </FormBlock>
                </AnchorRef>

                {produtoId !== '0' && produtoData.grade && (
                  <AnchorRef anchor="variacoes-grade">
                    <FormBlock>
                      <Plate
                        title="Variação de grade (Tamanho / Cor)"
                        subtitle="Gerencie as variações desse produto"
                        icon={() => (
                          <TiDocumentText size={32} color={colors.label} />
                        )}
                      />
                      <VariacoesProduto
                        produto_id={produtoId}
                        produto_nome={produtoData.descricao}
                        setChangedVariacoes={setChangedVariacoes}
                        data={produtoData.variacoes}
                      />
                    </FormBlock>
                  </AnchorRef>
                )}

                {produtoId !== '0' && (
                  <AnchorRef anchor="informacoes-comerciais">
                    <FormBlock>
                      <Plate
                        title="Informações Comerciais"
                        subtitle="Gerencie as informações comerciais deste produto"
                        icon={() => (
                          <TiDocumentText size={32} color={colors.label} />
                        )}
                      />
                      <InformacoesComerciais
                        produto_id={produtoId}
                        produto_nome={produtoData.descricao}
                        produto_grade={grade}
                        setChangedInformacoesComerciais={
                          setChangedInformacoesComerciais
                        }
                        data={produtoData.informacoes_comerciais}
                      />
                    </FormBlock>
                  </AnchorRef>
                )}

                {produtoId !== '0' && (
                  <AnchorRef anchor="estoques">
                    <FormBlock>
                      <Plate
                        title="Estoques"
                        subtitle="Veja os detalhes de estoque do produto"
                        icon={() => (
                          <TiDocumentText size={32} color={colors.label} />
                        )}
                      />
                      <Estoques data={produtoData.estoques} />
                    </FormBlock>
                  </AnchorRef>
                )}

                {produtoId !== '0' && (
                  <AnchorRef anchor="fotos">
                    <FormBlock>
                      <Plate
                        title="Fotos"
                        subtitle="Tamanho máximo: 512x512 - Formato: JPEG"
                        icon={() => (
                          <TiDocumentText size={32} color={colors.label} />
                        )}
                      />
                      <Fotos produto_id={produtoId} />
                    </FormBlock>
                  </AnchorRef>
                )}
              </FormContent>
              <FormFooter>
                <Link to="/produtos">
                  <Button type="button" background={colors.contrast}>
                    Cancelar
                  </Button>
                </Link>
                {/* <Link to="/produto/0"> */}
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                {/* </Link> */}
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default Produto;
