import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import {
  filtrarAgrupadoresPorAgrupamento,
  filtrarContasPorAgrupador,
  ordenarContas,
} from '../engine';

import { Sticker, Button } from '~/components';

import Conta from './Conta';
import Totalizadores from './Totalizadores';
import SelecaoDeItens from './SelecaoDeItens';

import { Container, Content, List, Pagination } from './styles';

import { ContaType } from '~/types';

import { ListagemProps } from './interface';

const Listagem: React.FC<ListagemProps> = ({
  contas,
  configuracao,
  totalizadores,
  empresaSelecionada,
  paginaAtual,
  totalPaginas,
  totalRegistros,
  alterarPagina,
  recarregarPaginaAtual,
}) => {
  const { colors } = useTheme();

  const [contasSelecionadas, setContasSelecionadas] = useState<string[]>([]);

  const [totalizadoresVisibilidiade, setTotalizadoresVisibilidade] =
    useState(false);

  const selecionarConta = (id: string) => {
    setContasSelecionadas(
      contasSelecionadas.includes(id)
        ? [...contasSelecionadas.filter(contaId => id !== contaId)]
        : [...contasSelecionadas, id],
    );
  };

  const checarContaSelecionada = (id: string) => {
    return contasSelecionadas.includes(id);
  };

  const renderizarContas = (pContas: Array<ContaType>) => {
    return (
      <List>
        {pContas.map((conta, index) => (
          <Conta
            key={conta.id}
            eImpar={index % 2 === 0}
            conta={conta}
            selecionada={checarContaSelecionada(conta.id)}
            selecionarConta={selecionarConta}
          />
        ))}
        {!pContas.length ? <h4>Nenhum item</h4> : null}
      </List>
    );
  };

  const renderizarListagem = (
    pContas: Array<ContaType>,
    pAgrupamentos: Array<string>,
  ) => {
    if (!pAgrupamentos.length) {
      return renderizarContas(pContas);
    }

    const currentAgrupamento = pAgrupamentos[0];

    const agrupadores = filtrarAgrupadoresPorAgrupamento(
      contas,
      currentAgrupamento,
    );

    return agrupadores.map((agrupador, idx) => (
      <React.Fragment key={agrupador[idx]}>
        <Sticker title={agrupador}>
          {renderizarListagem(
            filtrarContasPorAgrupador(pContas, currentAgrupamento, agrupador),
            [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
          )}
        </Sticker>
      </React.Fragment>
    ));
  };

  const fecharTotalizadores = () => {
    setTotalizadoresVisibilidade(false);
  };

  const abrirTotalizadores = () => {
    setTotalizadoresVisibilidade(true);
  };

  const pAgrupamentos = configuracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);

  return (
    <Container>
      {totalizadores.length ? (
        <Totalizadores
          aoFechar={fecharTotalizadores}
          visibilidade={totalizadoresVisibilidiade}
          totalizadores={totalizadores}
        />
      ) : null}

      {contas.length ? (
        <SelecaoDeItens
          contas={contas}
          contasSelecionadas={contasSelecionadas}
          setContasSelecionadas={setContasSelecionadas}
          empresaSelecionada={empresaSelecionada}
          recarregarPaginaAtual={recarregarPaginaAtual}
        />
      ) : null}

      <Content>
        {renderizarListagem(ordenarContas(contas, configuracao), pAgrupamentos)}
      </Content>

      {paginaAtual && totalPaginas && totalRegistros ? (
        <Pagination>
          {totalPaginas > 1 ? (
            <div className="pages">
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(1)}
                >
                  &laquo;
                </Button>
              ) : null}
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual - 1)}
                >
                  {paginaAtual - 1}
                </Button>
              ) : null}
              <Button
                background={colors.green}
                onClick={() => alterarPagina(1)}
              >
                {paginaAtual}
              </Button>
              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  {paginaAtual + 1}
                </Button>
              ) : null}

              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  &raquo;
                </Button>
              ) : null}

              {totalPaginas > paginaAtual ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(totalPaginas)}
                >
                  {totalPaginas}
                </Button>
              ) : null}
            </div>
          ) : null}
          {/* <span>
            {`${totalRegistros} ${
              totalRegistros > 1 ? 'Registros' : 'Registro'
            }`}
          </span> */}
          <div className="totalizadores">
            <Button onClick={abrirTotalizadores} background={colors.grey}>
              Totalizadores
            </Button>
          </div>
        </Pagination>
      ) : null}
    </Container>
  );
};

export default Listagem;
