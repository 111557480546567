import styled, { css } from 'styled-components';

import { LabelProps } from './interface';

export const Container = styled.div``;

export const MenuItem = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    background: none;
    display: flex;
    flex-direction: column;
    float: left;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 2rem;

    &:hover {
      background: ${theme.colors.defaultHighlight};
    }

    span {
      font-size: 1.6rem;
      color: ${theme.colors.label};
      margin-top: 1rem;
    }

    @media (max-width: 1024px) {
      flex-direction: row;
      flex: 1;
      width: auto;
      justify-content: flex-start;
      padding: 0rem 2rem;

      span {
        font-size: 1.6rem;
        padding: 2rem;
      }

      svg {
        width: 3.2rem;
      }
    }
  `}
`;

export const Label = styled.div<LabelProps>`
  ${({ theme, $status }) => css`
    font-size: 1rem;
    color: ${$status === 'ABERTA' ? theme.colors.green : theme.colors.darkRed};
    font-weight: bold;
  `}
`;
