import React from 'react';
import { useTheme } from 'styled-components';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { HeaderSearchLoadingContent } from './styles';

const HeaderSearchLoading: React.FC = () => {
  const { colors } = useTheme();
  return (
    <HeaderSearchLoadingContent>
      <span>
        <AiOutlineLoading3Quarters size={26} color={colors.label} />
      </span>
    </HeaderSearchLoadingContent>
  );
};

export default HeaderSearchLoading;
