import styled, { css, keyframes } from 'styled-components';

import { LabelProps, MenuItemProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
`;

export const Label = styled.button.attrs<LabelProps>({
  type: 'button',
})`
  ${({ theme, $active }) => css`
    display: flex;
    flex-direction: row;
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    height: 5rem;
    padding: 0.2rem;

    span {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      padding: 0.5rem 1rem 0 1rem;
      flex-direction: column;

      strong {
        font-size: 1.2rem;
        color: ${$active ? '#fff' : '#666360'};
      }

      small {
        font-size: 0.8rem;
        color: ${$active ? '#fff' : '#666360'};
        opacity: 0.6;
      }
    }

    i {
      align-items: center;
      justify-content: center;
      display: flex;
      border-left: 1px solid #fff;
      padding: 0 0.5rem;
      height: 100%;
      float: left;
    }
  `}
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  margin: 0rem 0 4rem 0;
`;

export const MenuItem = styled.button.attrs<MenuItemProps>({
  type: 'button',
})`
  ${({ theme, $active }) => css`
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    float: left;
    background: none;
    height: 5rem;

    span {
      background: ${theme.colors.controlNotFocus};
      border-radius: 0.5rem;
      border: 0.2rem solid ${theme.colors.defaultHighlight};
      height: 5rem;
      padding: 2rem;
      font-size: 1.6rem;
      font-weight: 700;
      height: 5rem;
      display: flex;

      align-items: center;
      color: ${$active ? '#fff' : '#666360'};
    }

    i {
      opacity: ${$active ? '1' : '0'};
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
      position: relative;
      margin-top: -2rem;
      margin-bottom: -2rem;
    }
  `}
`;

export const Footer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin-top: 3rem;

  button {
    max-width: 35%;
    margin-left: 0.5rem;
  }

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

export const Clear = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0 0 0;
  `}
`;

export const ClearBtn = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    span {
      color: ${theme.colors.white};
      font-size: 1.4rem;
      margin: 0 0 0 5px;
    }

    &:hover {
      span {
        text-decoration: underline;
        color: ${theme.colors.primary};
      }
      svg {
        fill: ${theme.colors.primary};
      }
    }
  `}
`;

const loadAnimation = keyframes`
  	0% {
			width: 0px;
		}
    30% {
      width: 65%;
    }
    65% {
      width: 85%;
    }
		100% {
			width: 100%;
		}
`;

export const Empty = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    height: 38rem;
    font-size: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.default};

    span {
      width: 100%;
      height: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      overflow: hidden;

      i {
        background: rgba(255, 255, 255, 0.1);
        animation: ${loadAnimation} 0.15s linear forwards;
        float: left;
        height: 20px;
      }
    }
  `}
`;
