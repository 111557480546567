import React, { useState } from 'react';

import { AreaOnVisibility, AreaOnContainer, DropDownArea } from './styles';

import TagItem from '../TagItem';
import TagDrag from '../TagDrag';
import TagDrop from '../TagDrop';

import { AreaOnProps, currentType } from './interface';

import { LugItemProps } from '../interface';

const AreaOn: React.FC<AreaOnProps> = ({ tags, onRemove }) => {
  const [On, setOn] = useState<currentType>([...tags]);

  const onDropItemOff = (tag: LugItemProps): void => {
    if (On) {
      setOn([
        ...On.map(cItem => {
          if (cItem.value === tag.value) {
            return {
              ...cItem,
              active: false,
            };
          }
          return cItem;
        }),
      ]);
      onRemove(tag);
    }
  };

  const onRemoveItem = (tag: LugItemProps): void => {
    if (On) {
      setOn([
        ...On.map(cItem => {
          if (cItem.value === tag.value) {
            return {
              ...cItem,
              active: false,
            };
          }
          return cItem;
        }),
      ]);
      onRemove(tag);
    }
  };

  return (
    <AreaOnVisibility>
      <AreaOnContainer>
        <h3>Tags selecionadas</h3>

        <TagDrop name="-in" accept="tag-in">
          <DropDownArea>
            {On &&
              On.filter(cTag => cTag.active).map(tag => (
                <TagDrag
                  key={tag.value}
                  type={`tag-off-${tag.group}`}
                  tag={tag}
                  onDropItem={onDropItemOff}
                >
                  <TagItem
                    tag={tag}
                    cor={tag.color}
                    onRemoveItem={onRemoveItem}
                  />
                </TagDrag>
              ))}

            {!On ||
              (On && !On.filter(cTag => cTag.active).length && (
                <h4>Nenhuma tag foi adicionada</h4>
              ))}
          </DropDownArea>
        </TagDrop>
      </AreaOnContainer>
    </AreaOnVisibility>
  );
};

export default AreaOn;
