import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main``;

export const List = styled.main`
  ${({ theme }) => css`
    border: 0.1rem solid ${theme.colors.grey};
    background-color: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    overflow: hidden;
    float: left;
    width: 100%;
    min-height: 5rem;
    margin-bottom: 2rem;

    h4 {
      width: 100%;
      font-size: ${theme.fontSizes.large};
      float: left;
      padding: 2rem;
      text-align: center;
    }
  `}
`;

export const Pagination = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */

  .totalizadores {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button {
      width: 30%;
      margin: 0 0.2rem;
    }
  }

  .pages {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    button {
      width: 4rem;
      margin: 0 0.2rem;
    }
  }

  span {
    font-size: 1.4rem;
    flex: 1;
    text-align: center;
  }
`;
