import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { WrapperProps } from './interface';

export const Container = styled(motion.article)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    border-radius: ${theme.radio.default};
    display: flex;
    flex-direction: column;
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.radio.default};
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    div:first-of-type {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  `}
`;
