import React from 'react';

import { useTheme } from 'styled-components';

import { BiSolidErrorAlt } from 'react-icons/bi';

import { useToast } from '~/hooks/toast';

import { Container } from './styles';

import { Button, Modal } from '~/components';

import { ErrosXMLProps } from './interface';

const ErrosXML: React.FC<ErrosXMLProps> = ({
  nfe,
  visible,
  onRequestClose,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  return (
    <Modal
      title={`NFE: ${nfe.nome}`}
      width="80rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <main>
          <h1>
            <BiSolidErrorAlt size={42} color={colors.error} />
            <span>
              {nfe.erros.length
                ? nfe.erros.length > 1
                  ? `${nfe.erros.length} ERROS ENCONTRADOS`
                  : '1 ERRO ENCONTRADO'
                : 'NENHUM ERRO ENCONTRADO'}
            </span>
          </h1>

          {nfe.erros.map((cErro, ckey) => (
            <div key={cErro.codigo}>
              <span>{ckey + 1}</span>
              <div>
                <h2>Código: {cErro.codigo}</h2>
                <p>{cErro.mensagem}</p>
              </div>
            </div>
          ))}
        </main>
        <footer>
          <div>
            <Button
              type="button"
              background={colors.contrast}
              onClick={onRequestClose}
            >
              Fechar
            </Button>
          </div>
        </footer>
      </Container>
    </Modal>
  );
};

export default ErrosXML;
