import React from 'react';

import List from '~/components/List';

import { Container } from './styles';

import { TelefonesProps } from './interface';

const Telefones: React.FC<TelefonesProps> = ({
  data,
  handlerRemove,
  handlerEdit,
}) => {
  return (
    <Container>
      {data && data.length ? (
        <List
          header={[
            // {
            //   column: 'codigo_pais',
            //   label: 'Código País',
            // },
            {
              column: 'ddd',
              label: 'DDD',
            },
            {
              column: 'telefone',
              label: 'Telefone',
            },
            {
              column: 'descricao',
              label: 'Descrição',
            },
            {
              column: 'eprincipal',
              label: 'Principal?',
            },
          ]}
          data={[
            ...data.map(item => ({
              ...item,
              eprincipal: item.principal ? 'SIM' : 'NÃO',
            })),
          ]}
          options={[
            {
              label: 'Editar',
              onPress: item => {
                handlerEdit(item);
              },
            },
            {
              label: 'Remover',
              onPress: item => {
                handlerRemove(item);
              },
            },
          ]}
        />
      ) : (
        <h1>Nenhum telefone cadastrado!</h1>
      )}
    </Container>
  );
};

export default Telefones;
