import styled from 'styled-components';

export const Resume = styled.div`
  width: 100%;

  margin-bottom: 0.75rem;
`;

export const ResumeHeader = styled.div`
  strong {
    font-weight: bold;
    font-size: 14px;

    color: ${({ theme }) => theme.colors.label};
  }
`;

export const ResumeContent = styled.div`
  width: 100%;

  border: 2px solid ${({ theme }) => theme.colors.defaultHighlight};
  border-radius: 0.75rem;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;

  padding: 0.5rem 0.75rem;
  margin: 0.75rem 0;

  strong,
  span {
    display: block;

    text-align: center;
  }

  strong {
    font-weight: bold;
    font-size: 24px;
  }

  span {
    font-size: 14px;
  }
`;
