import styled from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0rem 0 -1rem 2rem;
  position: relative;
  padding: 0 2rem;

  @media (max-width: 1024px) {
    overflow-x: scroll;
    padding: 0rem;
    margin: 0rem 0 2rem 0;
  }
`;
