import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3rem 0 0 0;

  div {
    margin: 0 0.5rem;

    button {
      width: 15rem;
    }
  }
`;
