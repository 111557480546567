import * as Yup from 'yup';

const schemaNFE = Yup.object().shape({
  IDE_cUF: Yup.string().required('Propriedade IDE_cUF não informada'),
  IDE_cNF: Yup.string().required('Propriedade IDE_cNF não informada'),
  IDE_natOp: Yup.string().required('Propriedade IDE_natOp não informada'),
  IDE_mod: Yup.string().required('Propriedade IDE_mod não informada'),
  IDE_serie: Yup.string().required('Propriedade IDE_serie não informada'),
  IDE_nNF: Yup.string().required('Propriedade IDE_nNF não informada'),
  IDE_dhEmi: Yup.string().required('Propriedade IDE_dhEmi não informada'),
  IDE_dhSaiEnt: Yup.string().required('Propriedade IDE_dhSaiEnt não informada'),
  IDE_tpNF: Yup.string().required('Propriedade IDE_tpNF não informada'),
  IDE_idDest: Yup.string().required('Propriedade IDE_idDest não informada'),
  IDE_cMunFG: Yup.string().required('Propriedade IDE_cMunFG não informada'),
  IDE_tpImp: Yup.string().required('Propriedade IDE_tpImp não informada'),
  IDE_tpEmis: Yup.string().required('Propriedade IDE_tpEmis não informada'),
  IDE_cDV: Yup.string().required('Propriedade IDE_cDV não informada'),
  IDE_tpAmb: Yup.string().required('Propriedade IDE_tpAmb não informada'),
  IDE_finNFe: Yup.string().required('Propriedade IDE_finNFe não informada'),
  IDE_indFinal: Yup.string().required('Propriedade IDE_indFinal não informada'),
  IDE_indPres: Yup.string().required('Propriedade IDE_indPres não informada'),
  IDE_procEmi: Yup.string().required('Propriedade IDE_procEmi não informada'),
  IDE_verProc: Yup.string().required('Propriedade IDE_verProc não informada'),
  EMIT_CNPJ: Yup.string().required('Propriedade EMIT_CNPJ não informada'),
  EMIT_xNome: Yup.string().required('Propriedade EMIT_xNome não informada'),
  EMIT_xFant: Yup.string().required('Propriedade EMIT_xFant não informada'),
  EMIT_xLgr: Yup.string().required('Propriedade EMIT_xLgr não informada'),
  EMIT_nro: Yup.string().required('Propriedade EMIT_nro não informada'),
  EMIT_xBairro: Yup.string().required('Propriedade EMIT_xBairro não informada'),
  EMIT_cMun: Yup.string().required('Propriedade EMIT_cMun não informada'),
  EMIT_xMun: Yup.string().required('Propriedade EMIT_xMun não informada'),
  EMIT_UF: Yup.string().required('Propriedade EMIT_UF não informada'),
  EMIT_CEP: Yup.string().required('Propriedade EMIT_CEP não informada'),
  EMIT_cPais: Yup.string().required('Propriedade EMIT_cPais não informada'),
  EMIT_xPais: Yup.string().required('Propriedade EMIT_xPais não informada'),
  EMIT_fone: Yup.string().required('Propriedade EMIT_fone não informada'),
  EMIT_IE: Yup.string().required('Propriedade EMIT_IE não informada'),
  EMIT_IEST: Yup.string().optional(),
  EMIT_CRT: Yup.string().required('Propriedade EMIT_CRT não informada'),
  DEST_CNPJ: Yup.string().required('Propriedade DEST_CNPJ não informada'),
  DEST_xNome: Yup.string().required('Propriedade DEST_xNome não informada'),
  DEST_xLgr: Yup.string().required('Propriedade DEST_xLgr não informada'),
  DEST_nro: Yup.string().required('Propriedade DEST_nro não informada'),
  DEST_xBairro: Yup.string().required('Propriedade DEST_xBairro não informada'),
  DEST_cMun: Yup.string().required('Propriedade DEST_cMun não informada'),
  DEST_xMun: Yup.string().required('Propriedade DEST_xMun não informada'),
  DEST_UF: Yup.string().required('Propriedade DEST_UF não informada'),
  DEST_CEP: Yup.string().required('Propriedade DEST_CEP não informada'),
  DEST_cPais: Yup.string().required('Propriedade DEST_cPais não informada'),
  DEST_xPais: Yup.string().required('Propriedade DEST_xPais não informada'),
  DEST_fone: Yup.string().required('Propriedade DEST_fone não informada'),
  DEST_indIEDest: Yup.string().required(
    'Propriedade DEST_indIEDest não informada',
  ),
  DEST_IE: Yup.string().required('Propriedade DEST_IE não informada'),
  DEST_email: Yup.string().optional(),
  TOTAL_vBC: Yup.string().required('Propriedade TOTAL_vBC não informada'),
  TOTAL_vICMS: Yup.string().required('Propriedade TOTAL_vICMS não informada'),
  TOTAL_vICMSDeson: Yup.string().required(
    'Propriedade TOTAL_vICMSDeson não informada',
  ),
  TOTAL_vFCP: Yup.string().required('Propriedade TOTAL_vFCP não informada'),
  TOTAL_vBCST: Yup.string().required('Propriedade TOTAL_vBCST não informada'),
  TOTAL_vST: Yup.string().required('Propriedade TOTAL_vST não informada'),
  TOTAL_vFCPST: Yup.string().required('Propriedade TOTAL_vFCPST não informada'),
  TOTAL_vFCPSTRet: Yup.string().required(
    'Propriedade TOTAL_vFCPSTRet não informada',
  ),
  TOTAL_vProd: Yup.string().required('Propriedade TOTAL_vProd não informada'),
  TOTAL_vFrete: Yup.string().required('Propriedade TOTAL_vFrete não informada'),
  TOTAL_vSeg: Yup.string().required('Propriedade TOTAL_vSeg não informada'),
  TOTAL_vDesc: Yup.string().required('Propriedade TOTAL_vDesc não informada'),
  TOTAL_vII: Yup.string().required('Propriedade TOTAL_vII não informada'),
  TOTAL_vIPI: Yup.string().required('Propriedade TOTAL_vIPI não informada'),
  TOTAL_vIPIDevol: Yup.string().required(
    'Propriedade TOTAL_vIPIDevol não informada',
  ),
  TOTAL_vPIS: Yup.string().required('Propriedade TOTAL_vPIS não informada'),
  TOTAL_vCOFINS: Yup.string().required(
    'Propriedade TOTAL_vCOFINS não informada',
  ),
  TOTAL_vOutro: Yup.string().required('Propriedade TOTAL_vOutro não informada'),
  TOTAL_vNF: Yup.string().required('Propriedade TOTAL_vNF não informada'),
  TOTAL_vTotTrib: Yup.string().required(
    'Propriedade TOTAL_vTotTrib não informada',
  ),
  TRANSP_modFrete: Yup.string().required(
    'Propriedade TRANSP_modFrete não informada',
  ),
  TRANSP_CNPJ: Yup.string().required('Propriedade TRANSP_CNPJ não informada'),
  TRANSP_xNome: Yup.string().required('Propriedade TRANSP_xNome não informada'),
  TRANSP_IE: Yup.string().required('Propriedade TRANSP_IE não informada'),
  TRANSP_xEnder: Yup.string().required(
    'Propriedade TRANSP_xEnder não informada',
  ),
  TRANSP_xMun: Yup.string().required('Propriedade TRANSP_xMun não informada'),
  TRANSP_UF: Yup.string().required('Propriedade TRANSP_UF não informada'),
  TRANSP_qVol: Yup.string().required('Propriedade TRANSP_qVol não informada'),
  TRANSP_esp: Yup.string().optional(),
  TRANSP_marca: Yup.string().optional(),
  TRANSP_pesoL: Yup.string().optional(),
  TRANSP_pesoB: Yup.string().optional(),
  INFADIC_infCpl: Yup.string().required(
    'Propriedade INFADIC_infCpl não informada',
  ),
  PROTNFE_tpAmb: Yup.string().required(
    'Propriedade PROTNFE_tpAmb não informada',
  ),
  PROTNFE_verAplic: Yup.string().required(
    'Propriedade PROTNFE_verAplic não informada',
  ),
  PROTNFE_chNFe: Yup.string().required(
    'Propriedade PROTNFE_chNFe não informada',
  ),
  PROTNFE_dhRecbto: Yup.string().required(
    'Propriedade PROTNFE_dhRecbto não informada',
  ),
  PROTNFE_nProt: Yup.string().required(
    'Propriedade PROTNFE_nProt não informada',
  ),
  PROTNFE_digVal: Yup.string().required(
    'Propriedade PROTNFE_digVal não informada',
  ),
  PROTNFE_cStat: Yup.string().required(
    'Propriedade PROTNFE_cStat não informada',
  ),
  PROTNFE_xMotivo: Yup.string().required(
    'Propriedade PROTNFE_xMotivo não informada',
  ),
});

export { schemaNFE };
