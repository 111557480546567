import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Loader } from '~/components';

import { Container } from './styles';

import Foto from './Foto';

import { FotosProps } from './interface';

const Fotos: React.FC<FotosProps> = ({ produto_id }) => {
  const [inLoad, setInLoad] = useState(false);

  const [cache, setCache] = useState<
    Array<{ id: string; url: string; index: number }>
  >([]);

  useEffect(() => {
    const carrregarFotos = async () => {
      try {
        setInLoad(true);
        const response = await api.get(
          `/produtos/imagens?produto_id=${produto_id}`,
        );

        const { data } = response;
        setCache(data);

        setInLoad(false);
      } catch (err) {
        setInLoad(false);
      }
    };

    carrregarFotos();
  }, [produto_id]);

  const cacheId = (pIndex: number) => {
    let output;
    cache.forEach(item => {
      if (item.index === pIndex) {
        output = `${item.id}`;
      }
    });
    return output;
  };

  const cacheUrl = (pIndex: number) => {
    let output;
    cache.forEach(item => {
      if (item.index === pIndex) {
        output = `${item.url}`;
      }
    });
    return output;
  };

  if (inLoad) {
    return <Loader />;
  }

  return (
    <Container>
      <Foto
        produto_id={produto_id}
        posicao="1"
        cacheId={cacheId(1)}
        cacheUrl={cacheUrl(1)}
      />
      <Foto
        produto_id={produto_id}
        posicao="2"
        cacheId={cacheId(2)}
        cacheUrl={cacheUrl(2)}
      />
      <Foto
        produto_id={produto_id}
        posicao="3"
        cacheId={cacheId(3)}
        cacheUrl={cacheUrl(3)}
      />
      <Foto
        produto_id={produto_id}
        posicao="4"
        cacheId={cacheId(4)}
        cacheUrl={cacheUrl(4)}
      />
      <Foto
        produto_id={produto_id}
        posicao="5"
        cacheId={cacheId(5)}
        cacheUrl={cacheUrl(5)}
      />
    </Container>
  );
};

export default Fotos;
