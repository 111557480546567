import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useAuth } from '~/hooks/auth';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Area, Container, Content, Evidence, Info, Normal } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
  Tab,
  TabLink,
  TabMenu,
  Textarea,
  NoPermission,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { handlerErrors } from '~/utils/error';
import { ParamsProps, TributacaoFormData } from './interface';

import {
  CFOPType,
  CSTIPIType,
  CSTPISType,
  ClasseFiscalType,
  CstType,
  GrupoTributacaoType,
  ModeloCalculoICMSSubstitutoAnteriorType,
  ModeloCalculoSTAnteriorType,
  NaturezaOperacaoType,
  SelectType,
  UfType,
} from '~/types';
import MotivoDesonerarICMSType from '~/types/motivoDesonerarICMS';
import { validarPermissao } from '~/utils/permissions';

const Tributacao: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id });
  const [registerId, setRegisterId] = useState('0');

  const [activeTab, setActiveTab] = useState('ICMS');

  // SELECTS
  const [csts, setCsts] = useState<SelectType[]>([]);
  const [cfops, setCfops] = useState<SelectType[]>([]);
  const [gruposTributacoes, setGruposTributacoes] = useState<SelectType[]>([]);
  const [classesFiscais, setClassesFiscais] = useState<SelectType[]>([]);
  const [ufsOrigem, setUfsOrigem] = useState<SelectType[]>([]);
  const [ufsDestino, setUfsDestino] = useState<SelectType[]>([]);
  const [naturezasOperacoes, setNaturezaOperacoes] = useState<SelectType[]>([]);
  const [cstspis, setCstspis] = useState<SelectType[]>([]);
  const [cstsipi, setCstsipi] = useState<SelectType[]>([]);
  const [motivoDesonerarICMS, setMotivoDesonerarICMS] = useState<SelectType[]>(
    [],
  );
  const [modeloCalculoSTAnterior, setModeloCalculoSTAnterior] = useState<
    SelectType[]
  >([]);
  const [
    modeloCalculoICMSSubstitutoAnterior,
    setModeloCalculoICMSSubstitutoAnterior,
  ] = useState<SelectType[]>([]);

  const formRef = useRef<FormHandles>(null);

  const onSelectTab = (name: string) => {
    setActiveTab(name);
  };

  const enviarFormulario = useCallback(
    async (data: TributacaoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        const TributacaoData = data;

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `tributacao${registerId !== '0' ? `/${registerId}` : ''}`,
            TributacaoData,
          );

          history(`/tributacao/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          setFormLoading(false);
          setFormError(`${handlerErrors(e)}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function limparFormulario() {
    setRegisterData({ id: '0' });
    formRef.current?.reset();
  }

  useEffect(() => {
    const carregarCsts = async () => {
      try {
        const cstsFetch = await api.get('/cst');
        const cstsFetched = [] as SelectType[];

        cstsFetch.data.forEach((item: CstType) => {
          cstsFetched.push({
            value: item.id,
            label: item.dsDescricao,
            color: '',
          });
        });

        setCsts(cstsFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarCfops = async () => {
      try {
        const cfopsFetch = await api.get('/cfop');
        const cfopsFetched = [] as SelectType[];

        cfopsFetch.data.forEach((item: CFOPType) => {
          cfopsFetched.push({
            value: item.id,
            label: `${item.descricao}`,
            color: '',
          });
        });

        setCfops(cfopsFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarGruposTributacoes = async () => {
      try {
        const gruposTributacoesFetch = await api.get('/grupo-tributacao');
        const gruposTributacoesFetched = [] as SelectType[];

        gruposTributacoesFetch.data.forEach((item: GrupoTributacaoType) => {
          gruposTributacoesFetched.push({
            value: item.id,
            label: item.nome,
            color: '',
          });
        });

        setGruposTributacoes(gruposTributacoesFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarClassesFiscais = async () => {
      try {
        const classesFiscaisFetch = await api.get('/classe-fiscal');
        const classesFiscaisFetched = [] as SelectType[];

        classesFiscaisFetch.data.forEach((item: ClasseFiscalType) => {
          classesFiscaisFetched.push({
            value: item.id,
            label: `${item.dsClasseFiscal}`,
            color: '',
          });
        });

        setClassesFiscais(classesFiscaisFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarUfsOrigem = async () => {
      try {
        const ufsOrigensFetch = await api.get('/estado-uf');
        const ufsOrigensFetched = [] as SelectType[];

        ufsOrigensFetch.data.forEach((item: UfType) => {
          ufsOrigensFetched.push({
            value: item.id,
            label: `${item.sigla}`,
            color: '',
          });
        });

        setUfsOrigem(ufsOrigensFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarUfsDestino = async () => {
      try {
        const ufsDestinoFetch = await api.get('/estado-uf');
        const ufsDestinoFetched = [] as SelectType[];

        ufsDestinoFetch.data.forEach((item: UfType) => {
          ufsDestinoFetched.push({
            value: item.id,
            label: `${item.sigla}`,
            color: '',
          });
        });

        setUfsDestino(ufsDestinoFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarNaturezaOperacoes = async () => {
      try {
        const naturezaOperacoesFetch = await api.get(
          '/pedido/natureza-operacao',
        );
        const naturezaOperacoesFetched = [] as SelectType[];

        naturezaOperacoesFetch.data.forEach((item: NaturezaOperacaoType) => {
          naturezaOperacoesFetched.push({
            value: item.id,
            label: `${item.descricao}`,
            color: '',
          });
        });

        setNaturezaOperacoes(naturezaOperacoesFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarCSTsPIS = async () => {
      try {
        const cstspisFetch = await api.get('/cst-pis');
        const cstspisFetched = [] as SelectType[];

        cstspisFetch.data.forEach((item: CSTPISType) => {
          cstspisFetched.push({
            value: item.id,
            label: `${item.dsCST}`,
            color: '',
          });
        });

        setCstspis(cstspisFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarCSTsIPI = async () => {
      try {
        const cstsipiFetch = await api.get('/cst-ipi');
        const cstsipiFetched = [] as SelectType[];

        cstsipiFetch.data.forEach((item: CSTIPIType) => {
          cstsipiFetched.push({
            value: item.id,
            label: `${item.dsCST_IPI}`,
            color: '',
          });
        });

        setCstsipi(cstsipiFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarTributacao = async () => {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response = await api.get(`tributacao/${id}`);
        const { data } = response;
        setRegisterData({
          ...data,
        });
        setLoading(false);
      } else {
        limparFormulario();
        setLoading(false);
      }
    };

    const carregarMotivoDesconeracaoICMS = async () => {
      try {
        const motivoDesonerarICMSFetch = await api.get(
          '/motivo-desonerar-icms',
        );
        const motivoDesonerarICMSFetched = [] as SelectType[];

        motivoDesonerarICMSFetch.data.forEach(
          (item: MotivoDesonerarICMSType) => {
            motivoDesonerarICMSFetched.push({
              value: item.id,
              label: `${item.dsMotDesICMS}`,
              color: '',
            });
          },
        );

        setMotivoDesonerarICMS(motivoDesonerarICMSFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarModeloCalculoSTAnterior = async () => {
      try {
        const modeloCalculoSTAnteriorFetch = await api.get(
          '/modelo-calculo-st-anterior',
        );
        const modeloCalculoSTAnteriorFetched = [] as SelectType[];

        modeloCalculoSTAnteriorFetch.data.forEach(
          (item: ModeloCalculoSTAnteriorType) => {
            modeloCalculoSTAnteriorFetched.push({
              value: item.id,
              label: `${item.dsModCalculoSTAnterior}`,
              color: '',
            });
          },
        );

        setModeloCalculoSTAnterior(modeloCalculoSTAnteriorFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarModeloCalculoICMSSubstitutoAnterior = async () => {
      try {
        const modeloCalculoICMSSubstitutoAnteriorFetch = await api.get(
          '/modelo-calculo-icms-substituto-anterior',
        );
        const modeloCalculoICMSSubstitutoAnteriorFetched = [] as SelectType[];

        modeloCalculoICMSSubstitutoAnteriorFetch.data.forEach(
          (item: ModeloCalculoICMSSubstitutoAnteriorType) => {
            modeloCalculoICMSSubstitutoAnteriorFetched.push({
              value: item.id,
              label: `${item.dsModCalcICMSSubstitutoAnt}`,
              color: '',
            });
          },
        );

        setModeloCalculoICMSSubstitutoAnterior(
          modeloCalculoICMSSubstitutoAnteriorFetched,
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    carregarTributacao();

    carregarCsts();
    carregarCfops();
    carregarGruposTributacoes();
    carregarClassesFiscais();
    carregarUfsOrigem();
    carregarUfsDestino();
    carregarNaturezaOperacoes();
    carregarCSTsPIS();
    carregarCSTsIPI();
    carregarModeloCalculoSTAnterior();
    carregarModeloCalculoICMSSubstitutoAnterior();
    carregarMotivoDesconeracaoICMS();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'incluir-tributacao')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={enviarFormulario}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Tributação"
                      subtitle="Configurações de tributação"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <Area>
                      <Normal>
                        <InputsContainer>
                          <InputContainer disabled={formLoading} width={200}>
                            <span>Código</span>
                            <div>
                              <Input
                                name="empresa_id"
                                placeholder="Código"
                                maxLength={30}
                              />
                            </div>
                          </InputContainer>
                          <Button
                            type="button"
                            background={colors.grey}
                            style={{ width: '20rem' }}
                          >
                            Duplicar Regra
                          </Button>
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer>
                            <span>CST/CSOSN</span>
                            <div>
                              <Select
                                name="cdCST"
                                loading={false}
                                options={csts}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer>
                            <span>CFOP</span>
                            <div>
                              <Select
                                name="cdCFOP"
                                loading={false}
                                options={cfops}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Normal>

                      <Evidence>
                        <Info>
                          <strong>Campos chave para criação de regra</strong>
                          <small>Não é aceito duplicidade nesses campos</small>
                        </Info>

                        <InputsContainer>
                          <InputContainer>
                            <span>Grupo de Tributação</span>
                            <div>
                              <Select
                                name="cdGrupoTributacao"
                                loading={false}
                                options={gruposTributacoes}
                              />
                            </div>
                          </InputContainer>
                          <InputContainer disabled={formLoading} width={200}>
                            <span>Produto Exceção</span>
                            <div>
                              <Input
                                name="cdProduto"
                                placeholder=""
                                maxLength={30}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Classificação Fiscal</span>
                            <div>
                              <Select
                                name="cdClasseFiscal"
                                loading={false}
                                options={classesFiscais}
                              />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>UF Origem</span>
                            <div>
                              <Select
                                name="cdUFOrigem"
                                loading={false}
                                options={ufsOrigem}
                              />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>UF Destino</span>
                            <div>
                              <Select
                                name="cdUFDestino"
                                loading={false}
                                options={ufsDestino}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Natureza da Operação</span>
                            <div>
                              <Select
                                name="cdNaturezaOperacao"
                                loading={false}
                                options={naturezasOperacoes}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Evidence>
                    </Area>

                    <TabMenu>
                      <TabLink
                        name="ICMS"
                        active={activeTab === 'ICMS'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="ICMS ST"
                        active={activeTab === 'ICMS ST'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="PIS/COFINS"
                        active={activeTab === 'PIS/COFINS'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="ICMS ST ANTERIOR"
                        active={activeTab === 'ICMS ST ANTERIOR'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="IPI"
                        active={activeTab === 'IPI'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="PARTILHA ICMS"
                        active={activeTab === 'PARTILHA ICMS'}
                        onSelectTab={onSelectTab}
                      />
                    </TabMenu>

                    <Tab name="ICMS" active={activeTab === 'ICMS'}>
                      <Area>
                        <Evidence>
                          <Info>
                            <strong>ICMS NORMAL</strong>
                          </Info>

                          <InputsContainer>
                            <InputContainer>
                              <span>MODALIDADE</span>
                              <div>
                                <Select
                                  name="nuModalidadeICMS"
                                  loading={false}
                                  defaultValue={{
                                    value: '',
                                    label: '',
                                    cor: '',
                                  }}
                                  options={[
                                    {
                                      value: '0',
                                      label: '0 - MARGEM VALOR AGREGADO %',
                                      cor: '',
                                    },
                                    {
                                      value: '1',
                                      label: '1 - PAUTA (VALOR)',
                                      cor: '',
                                    },
                                    {
                                      value: '2',
                                      label: '2 - PREÇO TABELADO MÁX. (VALOR)',
                                      cor: '',
                                    },
                                    {
                                      value: '3',
                                      label: '3 - VALOR DA OPERAÇÃO',
                                      cor: '',
                                    },
                                  ]}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={200}>
                              <span>ICMS %</span>
                              <div>
                                <Input
                                  name="vlAliquotaICMS"
                                  placeholder="ICMS"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={200}>
                              <span>Redução %</span>
                              <div>
                                <Input
                                  name="vlReducaoICMS"
                                  placeholder="Redução"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={200}>
                              <span>FCP %</span>
                              <div>
                                <Input
                                  name="pFCP"
                                  placeholder="FCP"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={200}>
                              <span>Diferimento %</span>
                              <div>
                                <Input
                                  name="pDif"
                                  placeholder="Diferimento"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Destacar o ICMS na NFE?</span>
                              <div className="switch">
                                <Switch name="idDestacarICMS" />
                              </div>
                            </InputContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Somar o ICMS ao total da NFE?</span>
                              <div className="switch">
                                <Switch name="idSomarICMS" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Evidence>
                        <Evidence>
                          <Info>
                            <strong>ICMS DESONERADO</strong>
                          </Info>

                          <InputsContainer>
                            <InputContainer>
                              <span>Motivo da desoneração</span>
                              <div>
                                <Select
                                  name="cdMotDesICMS"
                                  loading={false}
                                  options={motivoDesonerarICMS}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={200}>
                              <span>ICMS %</span>
                              <div>
                                <Input
                                  name="vlAliqICMSDesonerado"
                                  placeholder="ICMS"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer>
                              <span>Forma de Cálculo</span>
                              <div>
                                <Select
                                  name="nuICMSDesCalculo"
                                  loading={false}
                                  defaultValue={{
                                    value: '',
                                    label: '',
                                    cor: '',
                                  }}
                                  options={[
                                    {
                                      value: '1',
                                      label: 'Base Simples - BCICMS * Alíquota',
                                      cor: '',
                                    },
                                    {
                                      value: '2',
                                      label:
                                        'Base por Dentro - RESOLUÇÃO SEFAZ Nº 13 DE 14 DE FEVEREIRO DE 2019',
                                      cor: '',
                                    },
                                  ]}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Evidence>
                      </Area>
                    </Tab>

                    <Tab name="ICMS ST" active={activeTab === 'ICMS ST'}>
                      <Area>
                        <Normal>
                          <Normal>
                            <InputsContainer>
                              <InputContainer>
                                <span>Modalidade</span>
                                <div>
                                  <Select
                                    name="nuModalidadeST"
                                    loading={false}
                                    defaultValue={{
                                      value: '',
                                      label: '',
                                      cor: '',
                                    }}
                                    options={[
                                      {
                                        value: '0',
                                        label:
                                          '0 - PREÇO TABELADO OU MÁXIMO SUGERIDO',
                                        cor: '',
                                      },
                                      {
                                        value: '1',
                                        label: '1 - LISTA NEGATIVA (VALOR)',
                                        cor: '',
                                      },
                                      {
                                        value: '2',
                                        label: '2 - LISTA POSITIVA (VALOR)',
                                        cor: '',
                                      },
                                      {
                                        value: '3',
                                        label: '3 - LISTA NEUTRA (VALOR)',
                                        cor: '',
                                      },
                                      {
                                        value: '4',
                                        label: '4 - MARGEM VALOR AGREGADO %)',
                                        cor: '',
                                      },
                                      {
                                        value: '5',
                                        label: '5 - PAUTA (VALOR)',
                                        cor: '',
                                      },
                                    ]}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>

                            <InputsContainer>
                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>Aliquota %</span>
                                <div>
                                  <Input
                                    name="vlAliquotaST"
                                    placeholder="Aliquota"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>

                            <InputsContainer>
                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>Redução %</span>
                                <div>
                                  <Input
                                    name="vlReducaoSTOrigem"
                                    placeholder="Redução"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>

                            <InputsContainer>
                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>FCP ST %</span>
                                <div>
                                  <Input
                                    name="pFCPST"
                                    placeholder="FCP ST"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>
                          </Normal>

                          <Evidence>
                            <Info>
                              <strong>DESTINO</strong>
                            </Info>

                            <InputsContainer>
                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>Aliquota %</span>
                                <div>
                                  <Input
                                    name="vlAliquotaST"
                                    placeholder="Aliquota"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>

                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>Redução %</span>
                                <div>
                                  <Input
                                    name="vlReducaoST"
                                    placeholder="Redução"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>
                          </Evidence>
                        </Normal>

                        <Normal>
                          <Evidence>
                            <Info>
                              <strong>FIXAR MVA</strong>
                            </Info>

                            <InputsContainer>
                              <InputContainer disabled={formLoading}>
                                <span>
                                  Valor fixo de MVA para aplicação da regra?
                                </span>
                                <div className="switch">
                                  <Switch name="idFixarMVA" />
                                </div>
                              </InputContainer>
                            </InputsContainer>

                            <InputsContainer>
                              <InputContainer
                                disabled={formLoading}
                                width={200}
                              >
                                <span>MVA %</span>
                                <div>
                                  <Input
                                    name="vlMVA_Fixo"
                                    placeholder="MVA"
                                    maxLength={30}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>
                          </Evidence>

                          <Evidence>
                            <Info>
                              <strong>ORIGEM / INTERNA</strong>
                            </Info>
                          </Evidence>
                        </Normal>

                        <Normal>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Ajustar o valor do percentual MVA pela difereça
                                de alíquota?
                              </span>
                              <div className="switch">
                                <Switch name="idAjustarMVA" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Destacar o ICMS ST na NFE?</span>
                              <div className="switch">
                                <Switch name="idDestacarICMSST" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Somar o IXMS ST ao total da NFE?</span>
                              <div className="switch">
                                <Switch name="idSomarICMSST" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Somar o ICMS ST as despesas acessórias?
                              </span>
                              <div className="switch">
                                <Switch name="idSomarICMSST_Despesas" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Usar o valor do item sem dedução no cálculo
                                valor do ICMS ST?
                              </span>
                              <div className="switch">
                                <Switch name="idUsarValorSemDeducaoCalcST" />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Deduzir o valor do ICMS ST no valor do Item?
                              </span>
                              <div className="switch">
                                <Switch name="idDeduzirSTItem" />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Deduzir o valor do ICMS ST no valor do Item?
                              </span>
                              <div className="switch">
                                <Switch name="idDeduzirSTItem" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Normal>
                      </Area>
                    </Tab>

                    <Tab name="PIS/COFINS" active={activeTab === 'PIS/COFINS'}>
                      <InputsContainer>
                        <InputContainer disabled={formLoading}>
                          <span>
                            Usar as configurações de PIS e COFINS do cadastro do
                            produto?
                          </span>
                          <div className="switch">
                            <Switch name="idPisCofinsProduto" />
                          </div>
                        </InputContainer>
                      </InputsContainer>

                      <Area>
                        <Evidence>
                          <Info>
                            <strong>Configuração do PIS e COFINS</strong>
                          </Info>

                          <Normal>
                            <InputsContainer>
                              <InputContainer>
                                <span>CST</span>
                                <div>
                                  <Select
                                    name="cdPIS_CST"
                                    loading={false}
                                    options={cstspis}
                                  />
                                </div>
                              </InputContainer>
                            </InputsContainer>
                          </Normal>

                          <Area>
                            <Evidence>
                              <Info>
                                <strong>PIS</strong>
                              </Info>

                              <InputsContainer>
                                <InputContainer
                                  disabled={formLoading}
                                  width={150}
                                >
                                  <span>Alíquota %</span>
                                  <div>
                                    <Input
                                      name="vlPIS_pPIS"
                                      placeholder="Alíquota %"
                                      maxLength={30}
                                    />
                                  </div>
                                </InputContainer>

                                <InputContainer
                                  disabled={formLoading}
                                  width={150}
                                >
                                  <span>Alíquota R$</span>
                                  <div>
                                    <Input
                                      name="vlPIS_vAliqProd"
                                      placeholder="Alíquota R$"
                                      maxLength={30}
                                    />
                                  </div>
                                </InputContainer>
                              </InputsContainer>
                            </Evidence>

                            <Evidence>
                              <Info>
                                <strong>COFINS</strong>
                              </Info>

                              <InputsContainer>
                                <InputContainer
                                  disabled={formLoading}
                                  width={150}
                                >
                                  <span>Alíquota %</span>
                                  <div>
                                    <Input
                                      name="vlCOFINS_pCOFINS"
                                      placeholder="Alíquota %"
                                      maxLength={30}
                                    />
                                  </div>
                                </InputContainer>

                                <InputContainer
                                  disabled={formLoading}
                                  width={150}
                                >
                                  <span>Alíquota R$</span>
                                  <div>
                                    <Input
                                      name="vlCOFINS_vAliqProd"
                                      placeholder="Alíquota R$"
                                      maxLength={30}
                                    />
                                  </div>
                                </InputContainer>
                              </InputsContainer>
                            </Evidence>

                            <Evidence>
                              <Info>
                                <strong>COD. NATUREZA RECEITA</strong>
                                <small>(CSTS 04,06,07,08,09)</small>
                              </Info>

                              <InputsContainer>
                                <InputContainer disabled={formLoading}>
                                  <div>
                                    <Input
                                      name="cdPISCOFINSCodNaturezaReceita"
                                      placeholder="Cód. Natureza"
                                      maxLength={30}
                                    />
                                  </div>
                                </InputContainer>
                              </InputsContainer>
                            </Evidence>
                          </Area>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={300}>
                              <span>Código Conta Analitica Contábil</span>
                              <div>
                                <Input
                                  name="cdPlanoContaContabil"
                                  placeholder="Cód. Analitica Contábil"
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Evidence>
                      </Area>
                    </Tab>
                    <Tab
                      name="ICMS ST ANTERIOR"
                      active={activeTab === 'ICMS ST ANTERIOR'}
                    >
                      <InputsContainer>
                        <InputContainer>
                          <span>
                            Modalidade Cálculo ST Retirdo Anteriormente
                          </span>
                          <div>
                            <Select
                              name="nuModCalculoSTAnterior"
                              loading={false}
                              options={modeloCalculoSTAnterior}
                            />
                          </div>
                        </InputContainer>
                      </InputsContainer>

                      <InputsContainer>
                        <InputContainer>
                          <span>
                            Modalidade Cálculo do ICMS Próprio do Subistituto
                            cobrado em operação anterior
                          </span>
                          <div>
                            <Select
                              name="nuModCalcICMSSubstitutoAnt"
                              loading={false}
                              options={modeloCalculoICMSSubstitutoAnterior}
                            />
                          </div>
                        </InputContainer>
                      </InputsContainer>

                      <InputsContainer>
                        <InputContainer disabled={formLoading} width={600}>
                          <span>Alíquota suportada pelo consumidor final</span>
                          <div>
                            <Input name="vlpST" placeholder="" maxLength={30} />
                          </div>
                        </InputContainer>
                      </InputsContainer>
                    </Tab>
                    <Tab name="IPI" active={activeTab === 'IPI'}>
                      <Area>
                        <Normal>
                          <InputsContainer>
                            <InputContainer>
                              <span>CST</span>
                              <div>
                                <Select
                                  name="cdIPI_CST"
                                  loading={false}
                                  options={cstsipi}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={300}>
                              <span>IPI %</span>
                              <div>
                                <Input
                                  name="vlAliquotaPIPI"
                                  placeholder=""
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading} width={300}>
                              <span>IPI R$</span>
                              <div>
                                <Input
                                  name="vlAliquotaVIPI"
                                  placeholder=""
                                  maxLength={30}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Normal>
                        <Normal>
                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Destacar o IPI na NFE?</span>
                              <div className="switch">
                                <Switch name="idDestacarIPI" />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>Somar o IPI ao total da NFE?</span>
                              <div className="switch">
                                <Switch name="idSomarIPI" />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <InputsContainer>
                            <InputContainer disabled={formLoading}>
                              <span>
                                Deduzir o valor do IPI no valor do Item?
                              </span>
                              <div className="switch">
                                <Switch name="idDeduzirIPIItem" />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Normal>
                      </Area>
                    </Tab>
                    <Tab
                      name="PARTILHA ICMS"
                      active={activeTab === 'PARTILHA ICMS'}
                    >
                      <InputsContainer>
                        <InputContainer disabled={formLoading} width={600}>
                          <span>ICMS % - Alíquota interna no destino</span>
                          <div>
                            <Input
                              name="vlPICMSUFDest"
                              placeholder=""
                              maxLength={30}
                            />
                          </div>
                        </InputContainer>
                      </InputsContainer>

                      <InputsContainer>
                        <InputContainer disabled={formLoading} width={600}>
                          <span>
                            % adicional Fundo de Combate a Pobreza no destino (
                            máximo 2% )
                          </span>
                          <div>
                            <Input
                              name="vlPFCPUFDest"
                              placeholder=""
                              maxLength={30}
                            />
                          </div>
                        </InputContainer>
                      </InputsContainer>

                      <InputsContainer>
                        <InputContainer disabled={formLoading}>
                          <span>
                            Ajustar a BC do ICMS com a Alíquota interna no
                            destino?
                          </span>
                          <div className="switch">
                            <Switch name="idDIFALAjustarBCICMS" />
                          </div>
                        </InputContainer>
                      </InputsContainer>
                    </Tab>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>
                          Informações Complementares para a nota Fiscal
                        </span>
                        <div>
                          <Textarea
                            name="dsObs"
                            placeholder="Informações Complementares"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/tributacoes">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={limparFormulario}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default Tributacao;
