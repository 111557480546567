import React, { useState, useRef, useEffect, useCallback } from 'react';
import { RiMenuUnfoldLine, RiMenuFoldLine } from 'react-icons/ri';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';

import api from '~/services/api';

import { useAuth } from '~/hooks/auth';

import {
  HeaderMenuContainer,
  HeaderMenuContent,
  HeaderMenuControl,
  HeaderMenuLogo,
  HeaderMenuControlIc,
  HeaderMenuLink,
  HeaderMenuLinkTitle,
  Scroll,
  NameLogo,
} from './styles';

import logoImg from '~/assets/logo.png';
import menuConfig from '~/schemas/menu.js';

const HeaderMenu: React.FC = () => {
  const [showContent, setShowContent] = useState(false);
  const history = useNavigate();
  const { user } = useAuth();

  const handlePage = useCallback(
    async (item: { id: string; label: string; page: string; tag: string }) => {
      try {
        await api.post('ferramentas', {
          parceiro_id: user.parceiro_id,
          ...item,
        });
        // eslint-disable-next-line no-empty
      } catch {}
      history(item.page);
    },
    [history, user],
  );

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setShowContent(false);
    }
  };

  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowContent(false);
    }
  };

  const handlerVisibility = (): void => {
    setShowContent(!showContent);
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <HeaderMenuContainer ref={ref}>
      <HeaderMenuLogo $visible={showContent}>
        <button type="button" onClick={handlerVisibility}>
          <img src={logoImg} alt="OnStackERP" />
        </button>
        {showContent && (
          <NameLogo>
            <span>OnStack</span>
            <span>ERP</span>
          </NameLogo>
        )}
        <HeaderMenuControl onClick={handlerVisibility}>
          <HeaderMenuControlIc>
            {showContent ? (
              <RiMenuFoldLine size={18} color="#f5f5f5" />
            ) : (
              <RiMenuUnfoldLine size={18} color="#f5f5f5" />
            )}
          </HeaderMenuControlIc>
        </HeaderMenuControl>
      </HeaderMenuLogo>
      <HeaderMenuContent $visible={showContent}>
        <Scroll>
          {menuConfig.map(menuItem => (
            <HeaderMenuLink key={menuItem.id}>
              {menuItem.submenu.length > 0 ? (
                <HeaderMenuLinkTitle className="header-menu-link-title">
                  <div>
                    <menuItem.icon size={18} color="#f5f5f5" />
                    <span>{menuItem.name}</span>
                  </div>
                  <IoIosArrowForward size={18} color="#f5f5f5" />
                </HeaderMenuLinkTitle>
              ) : (
                <Link to={menuItem.page}>
                  <HeaderMenuLinkTitle className="header-menu-link-title">
                    <div>
                      <menuItem.icon size={18} color="#f5f5f5" />
                      <span>{menuItem.name}</span>
                    </div>
                  </HeaderMenuLinkTitle>
                </Link>
              )}
              {menuItem.submenu.length > 0 && (
                <menu>
                  <Scroll>
                    {menuItem.submenu.map(submenuItem => (
                      <ul key={submenuItem.id}>
                        <span>{submenuItem.name}</span>
                        {submenuItem.options.map(submenuOption => (
                          <li key={submenuOption.id}>
                            <button
                              type="button"
                              onClick={() => handlePage(submenuOption)}
                            >
                              <span>{submenuOption.label}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </Scroll>
                </menu>
              )}
            </HeaderMenuLink>
          ))}
        </Scroll>
      </HeaderMenuContent>
    </HeaderMenuContainer>
  );
};

export default HeaderMenu;
