import React, { useEffect, useState } from 'react';

import { useCallback } from 'react';
import api from '~/services/api';

import { Container, List, Item } from './styles';

import { CheckoutProps } from './interface';

import { Modal, Loader } from '~/components';

import { TipoPagamentoType } from '~/types';

const TipoDePagamento: React.FC<CheckoutProps> = ({
  visible,
  onRequestClose,
  onRequestSave,
  origem,
}) => {
  const [loading, setLoading] = useState(true);

  const [tiposDePagamentos, setTiposDePagamentos] = useState<
    Array<TipoPagamentoType>
  >([]);

  const handlerOnRequestClose = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handlerOnRequestSave = useCallback(
    (tipoPagamento: TipoPagamentoType) => {
      onRequestSave(tipoPagamento);
      onRequestClose();
    },
    [onRequestSave, onRequestClose],
  );

  useEffect(() => {
    async function loadTiposDePagamentos() {
      try {
        setLoading(true);

        let resource = `financeiros/tipos-pagamento?order_by=asc-descricao`;

        if (origem === 'BAIXA') {
          resource = `${resource}&situacao_lancamento=PAGA`;
        }

        const response = await api.get(resource);

        setTiposDePagamentos(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    loadTiposDePagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (event.code === 'Digit1') {
      event.preventDefault();
      if (tiposDePagamentos[0]) {
        handlerOnRequestSave(tiposDePagamentos[0]);
      }
    } else if (event.code === 'Digit2') {
      event.preventDefault();
      if (tiposDePagamentos[1]) {
        handlerOnRequestSave(tiposDePagamentos[1]);
      }
    } else if (event.code === 'Digit3') {
      event.preventDefault();
      if (tiposDePagamentos[2]) {
        handlerOnRequestSave(tiposDePagamentos[2]);
      }
    } else if (event.code === 'Digit4') {
      event.preventDefault();
      if (tiposDePagamentos[3]) {
        handlerOnRequestSave(tiposDePagamentos[3]);
      }
    } else if (event.code === 'Digit5') {
      event.preventDefault();
      if (tiposDePagamentos[4]) {
        handlerOnRequestSave(tiposDePagamentos[4]);
      }
    } else if (event.code === 'Digit6') {
      event.preventDefault();
      if (tiposDePagamentos[5]) {
        handlerOnRequestSave(tiposDePagamentos[5]);
      }
    } else if (event.code === 'Digit7') {
      event.preventDefault();
      if (tiposDePagamentos[6]) {
        handlerOnRequestSave(tiposDePagamentos[6]);
      }
    } else if (event.code === 'Digit8') {
      event.preventDefault();
      if (tiposDePagamentos[7]) {
        handlerOnRequestSave(tiposDePagamentos[7]);
      }
    } else if (event.code === 'Digit9') {
      event.preventDefault();
      if (tiposDePagamentos[8]) {
        handlerOnRequestSave(tiposDePagamentos[8]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
    };
  });

  if (loading) return <Loader visible />;

  return (
    <Modal
      title="Selecione um Tipo de Pagamento"
      width="90rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <List>
          {tiposDePagamentos.map((tipoDePagamento, idx) => (
            <Item
              key={tipoDePagamento.id}
              onClick={() => handlerOnRequestSave(tipoDePagamento)}
            >
              {tipoDePagamento.descricao}
              <div>
                <span>{idx + 1}</span>
              </div>
            </Item>
          ))}
        </List>
      </Container>
    </Modal>
  );
};

export default TipoDePagamento;
