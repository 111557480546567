import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';

import { ListLineContainer, ListLineMarkdownControl } from './styles';

import useLists from '~/hooks/lists';

import { ListLineProps } from './interface';

const ListLine: React.FC<ListLineProps> = ({
  id,
  markdown,
  toggleMarkDown,
  children,
}) => {
  const { activeLine, setActiveLine } = useLists();
  const { colors } = useTheme();

  const [markdownActive, setMarkdownActive] = useState(false);

  const toggleMarkDownEnabled = () => {
    setMarkdownActive(!markdownActive);
    if (toggleMarkDown) {
      toggleMarkDown(`${id}`);
    }
  };

  const handlerOnMouseEnter = (): void => {
    setActiveLine(`${id}`);
  };

  const handlerOnMouseLeave = (): void => {
    setActiveLine('0');
  };

  return (
    <>
      {markdown ? (
        <ListLineMarkdownControl $active={markdownActive}>
          <button type="button" onClick={toggleMarkDownEnabled}>
            {markdownActive ? (
              <BiRadioCircleMarked size={32} color={colors.white} />
            ) : (
              <BiRadioCircle size={32} color={colors.white} />
            )}
          </button>
        </ListLineMarkdownControl>
      ) : null}

      <ListLineContainer
        onMouseEnter={handlerOnMouseEnter}
        onMouseLeave={handlerOnMouseLeave}
        $active={id === activeLine}
        $markdownenabled={markdown}
        $markdownactive={markdownActive}
      >
        {children}
      </ListLineContainer>
    </>
  );
};

export default ListLine;
