import React, { useState } from 'react';

import { formatTheDateYall } from '~/utils/formatDate';
import { handlerNumberToString } from '~/utils/money';

import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from 'react-icons/md';

import { Container, Content, Control } from './styles';

import { ResumoDoPedidoProps } from './interface';

const ResumoDoPedido: React.FC<ResumoDoPedidoProps> = ({ pedido }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Container>
      <Content visible={visible}>
        <h1>RESUMO DO PEDIDO</h1>
        <div className="summary-infos">
          <p>
            <small>Código:</small>
            <strong>{pedido.codigo}</strong>
          </p>
          <p>
            <small>Empresa:</small>
            <strong>{pedido.empresa.fantasia}</strong>
          </p>
          <p>
            <small>Cliente:</small>
            <strong>{pedido.cliente_nome}</strong>
          </p>
          <p>
            <small>Data:</small>
            <strong>{formatTheDateYall(pedido.created_at)}</strong>
          </p>
          <p>
            <small>Natureza da Operação:</small>
            <strong>{pedido.natureza_operacao.descricao}</strong>
          </p>
          <p>
            <small>Tabela de Preço:</small>
            <strong>{pedido.tabela_preco.descricao}</strong>
          </p>
          <p>
            <small>Digitador:</small>
            <strong>{pedido.digitador.nome_fantasia}</strong>
          </p>
          <p>
            <small>Vendedor:</small>
            <strong>{pedido.vendedor.nome_fantasia}</strong>
          </p>
        </div>
        <div className="summary-vals">
          {pedido.itens.map(item => (
            <p key={item.id}>
              <small>
                {item.quantidade}x - {item.produto_descricao} (
                {`R$ ${handlerNumberToString(item.preco)}`})
              </small>
              <strong>
                {`R$ ${handlerNumberToString(item.quantidade * item.preco)}`}
              </strong>
            </p>
          ))}
        </div>
        <div className="summary-vals">
          <p>
            <small>Produtos:</small>
            <strong>
              {`R$ ${handlerNumberToString(pedido.valor_produtos)}`}
            </strong>
          </p>
          <p>
            <small>Desconto:</small>
            <strong>{`R$ ${handlerNumberToString(pedido.desconto)}`}</strong>
          </p>
          <p>
            <small>Total:</small>
            <strong>{`R$ ${handlerNumberToString(pedido.valor_total)}`}</strong>
          </p>
        </div>
      </Content>
      <Control onClick={() => setVisible(!visible)}>
        {visible ? (
          <MdKeyboardDoubleArrowUp size={32} color="#fff" />
        ) : (
          <MdKeyboardDoubleArrowDown size={32} color="#fff" />
        )}
      </Control>
    </Container>
  );
};

export default ResumoDoPedido;
