import React from 'react';

import { AnchorContainerContainer } from './styles';

import { AnchorProvider } from '~/hooks/anchor';

const AnchorContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <AnchorProvider>
      <AnchorContainerContainer>{children}</AnchorContainerContainer>
    </AnchorProvider>
  );
};

export default AnchorContainer;
