import styled, { css } from 'styled-components';

import { ListLineContainerProps, LLMDControlProps } from './interface';

export const ListLineContainer = styled.tr<ListLineContainerProps>`
  ${({ theme, $active, $markdownenabled, $markdownactive }) => css`
    height: 100%;
    td {
      background: ${$markdownactive
        ? '#20C05C !important'
        : `${$active ? `${theme.colors.liHouver} !important` : 'inherit'}`};
      color: ${$markdownactive
        ? '#000 !important'
        : `${$active ? `${theme.colors.label} !important` : 'inherit'}`};
      cursor: pointer;
    }

    td:first-child {
      padding-left: ${$markdownenabled ? '4rem !important' : '1rem'};
    }
    div {
      opacity: 1 !important;
    }
  `}
`;

export const ListLineMarkdownControl = styled.div<LLMDControlProps>`
  ${({ theme, $active }) => css`
    border-right: 0.1rem solid ${theme.colors.default};
    border-left: 0.1rem solid ${theme.colors.default};

    height: 4rem;
    width: 3.5rem;
    position: absolute;
    display: flex;

    opacity: ${$active ? '1' : '0.1'};
    background: none;

    &:hover {
      opacity: 1 !important;
      background-color: ${$active ? 'inherit' : theme.colors.liHouver};
    }

    button {
      background: none;
      cursor: pointer;
      float: left;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      opacity: 1 !important;
    }
  `}
`;
