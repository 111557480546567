import React, { useCallback, useRef } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import logoImg from '~/assets/logo.png';

import { Input, Button } from '~/components';
import {
  Container,
  Content,
  AnimationContainer,
  Background,
  NameLogo,
} from './styles';

import { SignInFormData } from './interface';

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useNavigate();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });
        await schema.validate(data, { abortEarly: false });
        await signIn({
          email: data.email,
          password: data.password,
        });

        history('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="OnStackERP" />
          <NameLogo>
            <span>OnStack</span>
            <span>ERP</span>
          </NameLogo>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input name="email" icon={FiMail} placeholder="E-mail" normalCase />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
              normalCase
            />

            <Button type="submit">Entrar</Button>
            <a href="forgot-password">Esqueci minha senha</a>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
