import React, { useState } from 'react';

import Splitter, { SplitDirection } from '@devbookhq/splitter';

import { useTheme } from 'styled-components';

import { Button, Modal } from '~/components';

import {
  Container,
  Content,
  Information,
  Panels,
  Tile,
  Footer,
} from './styles';

import { RedimensionamentoProps } from './interface';

const Redimensionamento: React.FC<RedimensionamentoProps> = ({
  aoAplicarConfiguracao,
  configuracao,
  aoFecharRedimensionamento,
}) => {
  const { colors } = useTheme();

  const ordenadores = configuracao.ordenar.filter(
    ordenador => ordenador.enable,
  );

  const [tamanhos, setTamanhos] = useState<number[]>([
    ...ordenadores.map((ordenador, key) => {
      // TAMANHO PADRÃO
      let cWidth: number = ordenador.width;

      // ULTIMA COLUNA (GULOSA, PEGA TODO O RESTANTE KKK)
      if (key === ordenadores.length - 1) {
        let total = 0;
        ordenadores.forEach(cOrdenador => {
          total += cOrdenador.width;
        });
        if (total < 100) {
          total -= cWidth;
          cWidth = 100 - total;
        }
      }
      return cWidth;
    }),
  ]);

  const aoFinalizarRedimensionamento = (
    gutterIdx: number,
    newSizes: number[],
  ) => {
    setTamanhos(newSizes);
  };

  const aoAplicarRedimensionamento = () => {
    aoAplicarConfiguracao(
      {
        ...configuracao,
        ordenar: [
          ...configuracao.ordenar.map(ordenador => ({
            ...ordenador,
            width: ordenadores.reduce((acc: number, item, key) => {
              if (ordenador.field === item.field) {
                if (tamanhos[key]) {
                  // eslint-disable-next-line no-param-reassign
                  acc = tamanhos[key];
                }
              }
              return acc;
            }, ordenador.width),
          })),
        ],
      },
      true,
    );
    aoFecharRedimensionamento();
  };

  return (
    <Modal
      title="REDIMENSIONAMENTO"
      width="120rem"
      visible
      scrollable
      onRequestClose={aoFecharRedimensionamento}
    >
      <Container>
        <Content>
          <Information>
            Ajuste o tamanho de cada coluna abaixo na proporção que você
            desejar, assim essa definição sera aplicada na configuração do seu
            relatório no processo de exportação.
          </Information>
          <Panels>
            <Splitter
              direction={SplitDirection.Horizontal}
              onResizeFinished={aoFinalizarRedimensionamento}
              initialSizes={tamanhos}
            >
              {ordenadores.map(ordenador => (
                <Tile key={ordenador.field}>{ordenador.label}</Tile>
              ))}
            </Splitter>
          </Panels>

          <Footer>
            <div>
              <Button
                onClick={aoFecharRedimensionamento}
                type="button"
                background={colors.grey}
              >
                Fechar
              </Button>
            </div>
            <div>
              <Button
                onClick={aoAplicarRedimensionamento}
                type="button"
                background={colors.green}
              >
                Aplicar dimenções
              </Button>
            </div>
          </Footer>
        </Content>
      </Container>
    </Modal>
  );
};

export default Redimensionamento;
