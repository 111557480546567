import React from 'react';

import { Container } from './styles';

import { Modal } from '~/components';

import { OpcoesProps } from './interface';

const Opcoes: React.FC<OpcoesProps> = ({ visible, onRequestClose }) => {
  return (
    <Modal
      title="OPÇÕES"
      visible={visible}
      onRequestClose={onRequestClose}
      width="100rem"
      scrollable
    >
      <Container>Opções da venda!</Container>
    </Modal>
  );
};

export default Opcoes;
