import styled, { css } from 'styled-components';

import { AnchorLinkContainerProps } from './interface';

export const AnchorLinkContainer = styled.span<AnchorLinkContainerProps>`
  ${({ theme, $active }) => css`
    float: left;
    background: ${$active
      ? theme.colors.defaultHighlight
      : theme.colors.background};
    cursor: pointer;
    border-radius: 0 0.6rem 0.6rem 0;
    margin-bottom: 1rem;
    padding: 1.5rem;
    // border: 0.1rem solid ${theme.colors.defaultHighlight};
    box-shadow: ${theme.shadows.highlight};
    /* border-left: ${$active
      ? `0.5rem solid ${theme.colors.default}`
      : `0.5rem solid ${theme.colors.default}`}; */
    transition: all 0.5s ease;
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.label};
    text-align: right;
    border-left: 0.5rem solid;
    border-color: ${$active ? theme.colors.primaryDark : 'rgba(0,0,0,0.1)'};

    &:hover {
      background: ${theme.colors.defaultHighlight};
      border-left: 0.5rem solid ${theme.colors.primaryDark};
    }
  `}
`;
