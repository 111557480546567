import React from 'react';

import { AiFillDelete } from 'react-icons/ai';
import { useTheme } from 'styled-components';

import { Container, List, Footer, Clear } from './styles';

import Item from './Item';

import { useCart } from '~/hooks/cart';

const Itens: React.FC = () => {
  const { colors } = useTheme();
  const { items, resetarCarrinho } = useCart();

  return (
    <Container>
      <List>
        {items.map(item => (
          <Item key={item.produto.id} item={item} />
        ))}
      </List>
      <Footer>
        {items.length ? (
          <h1>{`${items.length} ${items.length > 1 ? 'itens' : 'item'}`}</h1>
        ) : (
          <h1>Nenhum item</h1>
        )}
        {items.length ? (
          <div>
            <Clear onClick={resetarCarrinho}>
              <AiFillDelete size={18} color={colors.white} />
            </Clear>
          </div>
        ) : null}
      </Footer>
    </Container>
  );
};

export default Itens;
