import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { ParamsProps, EtiquetaFormData } from './interface';
import { EmpresaType, SelectType } from '~/types';

import padroesZPL from '~/schemas/zpl-padroes.json';
import fontesZPL from '~/schemas/zpl-fontes.json';

const Etiqueta: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({
    id,
    empresa_id: '',
    padrao: '1',
    margem_superior: '0',
    margem_lateral: '0',
    densidade_vertical: '0',
    densidade_horizontal: '0',
    fonte: '1',
    tamanho: '11',
    limite_carateres_produto: '20',
    exibir_codigo_barras: true,
    exibir_numero_codigo_barras: true,
    exibir_valor_produto: true,
  });
  const [registerId, setRegisterId] = useState('0');

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: EtiquetaFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Informe um nome válido.'),
          padrao: Yup.string().required('Informe um padrão'),
          fonte: Yup.string().required('Informe uma fonte'),

          margem_superior: Yup.number()
            .typeError('Informe um margem superior')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          margem_lateral: Yup.number()
            .typeError('Informe um margem lateral')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          densidade_vertical: Yup.number()
            .typeError('Informe um densidade vertical')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          densidade_horizontal: Yup.number()
            .typeError('Informe uma densidade horizontal')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          altura: Yup.number()
            .typeError('Informe uma altura')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
          largura: Yup.number()
            .typeError('Informe uma largura')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),

          tamanho: Yup.number()
            .typeError('Informe um tamanho')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),

          limite_carateres_produto: Yup.number()
            .typeError('Informe um limite de caracteres')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),

          colunas: Yup.number()
            .typeError('Informe o número de colunas')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),

          linhas: Yup.number()
            .typeError('Informe o número de linhas')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),

          tamanho_pagina: Yup.number()
            .typeError('Informe o tamanho da página')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          empresa_id,
          padrao,
          nome,
          margem_superior,
          margem_lateral,
          densidade_vertical,
          densidade_horizontal,
          altura,
          largura,
          fonte,
          tamanho,
          limite_carateres_produto,
          colunas,
          linhas,
          tamanho_pagina,
          descricao_topo,
          exibir_codigo_barras,
          exibir_numero_codigo_barras,
          exibir_valor_produto,
        } = data;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let etiquetaData: any = {
          padrao,
          nome,
          descricao_topo,
          margem_superior: Number(margem_superior),
          margem_lateral: Number(margem_lateral),
          densidade_vertical: Number(densidade_vertical),
          densidade_horizontal: Number(densidade_horizontal),
          altura: Number(altura),
          largura: Number(largura),
          fonte: Number(fonte),
          tamanho: Number(tamanho),
          limite_carateres_produto: Number(limite_carateres_produto),
          colunas: Number(colunas),
          linhas: Number(linhas),
          tamanho_pagina: Number(tamanho_pagina),
          exibir_codigo_barras: Number(exibir_codigo_barras),
          exibir_numero_codigo_barras: Number(exibir_numero_codigo_barras),
          exibir_valor_produto: Number(exibir_valor_produto),
        };

        if (registerId === '0') {
          etiquetaData = {
            ...etiquetaData,
            empresa_id: Number(empresa_id),
          };
        }

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `produtos/etiquetas${registerId !== '0' ? `/${registerId}` : ''}`,
            etiquetaData,
          );

          history(`/etiqueta/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          setFormLoading(false);
          setFormError(`${(e as Error).message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    setRegisterData({
      id: '0',
      empresa_id: '',
      padrao: '1',
      margem_superior: '0',
      margem_lateral: '0',
      densidade_vertical: '0',
      densidade_horizontal: '0',
      fonte: '1',
      tamanho: '11',
      limite_carateres_produto: '20',
      exibir_codigo_barras: true,
      exibir_numero_codigo_barras: true,
      exibir_valor_produto: true,
    });
    formRef.current?.reset();
  }

  useEffect(() => {
    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response = await api.get(`produtos/etiquetas/${id}`);

        const { data } = response;

        const { fonte, padrao } = data;

        const padraoAttr = padroesZPL.filter(
          cPadrao => `${cPadrao.id}` === `${padrao}`,
        )[0];

        const fonteAttr = fontesZPL.filter(
          cFonte => `${cFonte.id}` === `${fonte}`,
        )[0];

        setRegisterData({
          ...response.data,

          padrao: {
            value: padraoAttr ? padraoAttr.id : '',
            label: padraoAttr ? padraoAttr.nome : '',
          },
          fonte: {
            value: fonteAttr ? fonteAttr.id : '',
            label: fonteAttr ? fonteAttr.nome : '',
          },

          data_cadastro: format(
            new Date(response.data.created_at),
            'dd/MM/yyyy HH:mm',
          ),
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        loadRegister();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    loadEmpresas();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Etiquetas (Modelo de Impressão)"
                      subtitle="Adicione o padrão de impressão para as etiquetas"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      {registerData.id !== '0' && (
                        <>
                          <InputContainer disabled={formLoading}>
                            <span>Data do Cadastro</span>
                            <div>
                              <Input
                                name="data_cadastro"
                                disabled
                                placeholder="Data do Cadastro"
                              />
                            </div>
                          </InputContainer>
                        </>
                      )}
                    </InputsContainer>

                    <InputsContainer>
                      {registerData.id === '0' ? (
                        <InputContainer disabled={formLoading}>
                          <span>Empresa</span>
                          <div>
                            <Select
                              name="empresa_id"
                              loading={loading}
                              options={empresas}
                            />
                          </div>
                        </InputContainer>
                      ) : null}

                      <InputContainer>
                        <span>Padrão de Impressão</span>
                        <div>
                          <Select
                            name="padrao"
                            options={[
                              ...padroesZPL.map(padraoZPL => ({
                                cor: '',
                                value: `${padraoZPL.id}`,
                                label: `${padraoZPL.nome}`,
                              })),
                            ]}
                            loading={false}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Nome</span>
                        <div>
                          <Input
                            name="nome"
                            placeholder="Informe um nome para o modelo"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Margem Superior (mm)</span>
                        <div>
                          <Input
                            name="margem_superior"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Margem Lateral (mm)</span>
                        <div>
                          <Input
                            name="margem_lateral"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Densidade Vertical (mm)</span>
                        <div>
                          <Input
                            name="densidade_vertical"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Densidade Horizontal (mm)</span>
                        <div>
                          <Input
                            name="densidade_horizontal"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Altura (mm)</span>
                        <div>
                          <Input name="altura" placeholder="0" maxLength={4} />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Largura (mm)</span>
                        <div>
                          <Input name="largura" placeholder="0" maxLength={4} />
                        </div>
                      </InputContainer>

                      <InputContainer>
                        <span>Fonte</span>
                        <div>
                          <Select
                            name="fonte"
                            options={[
                              ...fontesZPL.map(fonteZPL => ({
                                cor: '',
                                value: `${fonteZPL.id}`,
                                label: `${fonteZPL.nome}`,
                              })),
                            ]}
                            loading={false}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Tamanho</span>
                        <div>
                          <Input name="tamanho" placeholder="0" maxLength={4} />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Limite (Carecteres Produto)</span>
                        <div>
                          <Input
                            name="limite_carateres_produto"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Colunas</span>
                        <div>
                          <Input name="colunas" placeholder="0" maxLength={4} />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Linhas</span>
                        <div>
                          <Input name="linhas" placeholder="0" maxLength={4} />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Tamanho da Página (mm)</span>
                        <div>
                          <Input
                            name="tamanho_pagina"
                            placeholder="0"
                            maxLength={4}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição no topo</span>
                        <div>
                          <Input
                            name="descricao_topo"
                            placeholder="Informe a descrição do topo"
                            maxLength={20}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Exibir código de Barras?</span>
                        <div className="switch">
                          <Switch name="exibir_codigo_barras" />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Exibir número código de Barras?</span>
                        <div className="switch">
                          <Switch name="exibir_numero_codigo_barras" />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Exibir valor do Produto?</span>
                        <div className="switch">
                          <Switch name="exibir_valor_produto" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/etiquetas">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default Etiqueta;
