import React, { useRef, useCallback, useState, useEffect } from 'react';

import { BsFillTagFill } from 'react-icons/bs';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import api from '~/services/api';

import { getValidationErrors } from '~/utils';
import { handlerNumberToString, handlerToNumber } from '~/utils/money';

import {
  Button,
  Select,
  InputContainer,
  Input,
  Loader,
  FindProduto,
  ErrorMessage,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  EtiquetaImpressaoType,
  ProdutoType,
  SelectType,
  TabelaPrecoType,
} from '~/types';

import { FiltrosProps, FormData } from './interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState('');

  const [tabelas, setTabelasPreco] = useState<SelectType[]>([]);
  const [findProdutoVisibilidade, setFindProdutoVisibilidade] = useState(false);
  const [produto, setProduto] = useState<ProdutoType | null>(null);
  const [tabela, setTabela] = useState<SelectType | null>(null);

  const [informacaoComercial, setInformacaoComercial] =
    useState<SelectType | null>(null);

  const [quantidadePorImpressao, setQuantidadePorImpressao] = useState('1');

  const atribuirProduto = async (selecionados: ProdutoType[]) => {
    if (selecionados.length > 0) {
      const itemSelecionado = selecionados[0];
      if (itemSelecionado) {
        setProduto(itemSelecionado);
      }
    }
    setFindProdutoVisibilidade(false);
  };

  const atribuirTabela = async (itemSelecionado: SelectType) => {
    setTabela(itemSelecionado);
  };

  const atribuirInformacaoComercial = async (itemSelecionado: SelectType) => {
    setInformacaoComercial(itemSelecionado);
  };

  const checkInformacoesComerciais = (pProduto: ProdutoType | null) => {
    return (
      pProduto &&
      pProduto.informacoes_comerciais &&
      pProduto.informacoes_comerciais.length
    );
  };

  const carregarInformacoesComerciais = (
    pProduto: ProdutoType | null,
    pTabelaId: SelectType | null,
  ) => {
    const informacoesComerciaisFetched = [] as SelectType[];
    if (
      pProduto &&
      pProduto.informacoes_comerciais &&
      pProduto.informacoes_comerciais.length &&
      pTabelaId
    ) {
      pProduto.informacoes_comerciais
        .filter(
          cInformacaoComercial =>
            `${cInformacaoComercial.tabela_preco_id}` === `${pTabelaId.value}`,
        )
        .forEach(cInformacaoComercial => {
          informacoesComerciaisFetched.push({
            color: '',
            label: `COD.BAR: ${cInformacaoComercial.codigo_barra} - R$ ${cInformacaoComercial.preco}`,
            value: `${cInformacaoComercial.id}`,
          });
        });
    }
    return informacoesComerciaisFetched;
  };

  const buscarPrecoInformacaoComercial = (
    pProduto: ProdutoType,
    pInformacaoComercial: SelectType,
  ) => {
    let preco = 0;

    if (
      pProduto &&
      pProduto.informacoes_comerciais &&
      pProduto.informacoes_comerciais.length
    ) {
      pProduto.informacoes_comerciais.forEach(cInformacaoComercial => {
        if (`${cInformacaoComercial.id}` === `${pInformacaoComercial.value}`) {
          preco = cInformacaoComercial.preco;
        }
      });
    }
    return `R$ ${handlerNumberToString(preco)}`;
  };

  const submeterFormulario = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          produto_id: Yup.string().required('Produto obrigatório'),
          tabela_preco_id: Yup.string().required(
            'Tabela comercial obrigatória',
          ),
          informacao_comercial_id: Yup.string().required(
            'Informação Comercial obrigatória',
          ),
          quantidade_por_impressao: Yup.number()
            .typeError('Informe um número válido')
            .nullable()
            .transform((value: string, originalValue: string) =>
              originalValue.trim() === ''
                ? null
                : parseFloat(originalValue.replace(/,/g, '')),
            ),
        });

        await schema.validate(data, { abortEarly: false });

        if (informacaoComercial && produto) {
          const id = `${
            Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000
          }${new Date().getTime()}`;

          const etiqueta: EtiquetaImpressaoType = {
            id: `etq-${id}`,
            codigo_barras: informacaoComercial.label,
            descricao: produto.descricao,
            preco: buscarPrecoInformacaoComercial(produto, informacaoComercial),
            qtd: handlerToNumber(quantidadePorImpressao),
          };
          aoAplicarFiltros(etiqueta);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError(`${(err as Error).message}`);
      }
    },
    [aoAplicarFiltros, informacaoComercial, produto, quantidadePorImpressao],
  );

  useEffect(() => {
    const loadTabelasPreco = async () => {
      try {
        setLoading(true);
        const tabelasFetch = await api.get('tabelas-preco');
        const tabelasFetched = [] as SelectType[];
        tabelasFetch.data.forEach((item: TabelaPrecoType) => {
          tabelasFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });
        setTabelasPreco(tabelasFetched);
        setLoading(false);
      } catch (e) {
        setTabelasPreco([]);
        setLoading(false);
      }
    };

    loadTabelasPreco();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            produto_id: '',
            tabela_preco_id: '',
            informacao_comercial_id: '',
            quantidade_por_impressao: '1',
          }}
        >
          <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

          <InputContainer>
            <span>Produto</span>
            <div>
              <Input
                value={produto?.descricao}
                name="produto_id"
                placeholder="Produto"
                disabled
              />
              <FindProduto
                openned={findProdutoVisibilidade}
                onlySearch
                onSelectValue={atribuirProduto}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tabela de Preço</span>
            <div>
              <Select
                name="tabela_preco_id"
                options={tabelas}
                loading={false}
                onSelectedOption={atribuirTabela}
              />
            </div>
          </InputContainer>

          {checkInformacoesComerciais(produto) ? (
            <InputContainer>
              <span>Informação Comercial</span>
              <div>
                <Select
                  name="informacao_comercial_id"
                  options={carregarInformacoesComerciais(produto, tabela)}
                  loading={false}
                  onSelectedOption={atribuirInformacaoComercial}
                />
              </div>
            </InputContainer>
          ) : null}

          <InputContainer>
            <span>Qtde por Impressão</span>
            <div>
              <Input
                name="quantidade_por_impressao"
                placeholder="Ex. 1"
                required
                maxLength={3}
                type="number"
                value={quantidadePorImpressao}
                onChange={e => setQuantidadePorImpressao(`${e.target.value}`)}
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button type="submit" background={colors.green}>
                <BsFillTagFill size={32} color={colors.label} />
                <span style={{ marginLeft: 10 }}>Adicionar Etiqueta</span>
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
