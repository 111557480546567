import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { BsFillPlusCircleFill } from 'react-icons/bs';

import { getValidationErrors } from '~/utils';

import api from '~/services/api';

import Loader from '~/components/Loader';

import {
  Modal,
  FormContainer,
  FormContent,
  InputsContainer,
  InputContainer,
  Button,
  Input,
  ErrorMessage,
  Select,
} from '~/components';

import List from '~/components/List';

import { Container, Area, Progress, Add, Footer, Empty } from './styles';

import { handlerToNumber } from '~/utils/money';

import {
  SelectType,
  VariacaoProdutoType,
  NfeManualItemVariacaoType,
} from '~/types';

import { GradeProps, GradeFormData } from './interface';

const Grade: React.FC<GradeProps> = ({
  onRequestClose,
  onRequestSave,
  item,
}) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [inLoad, setInLoad] = useState(true);
  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [variacoesProduto, setVariacoesProduto] = useState<SelectType[]>([]);

  const [variacoesItem, setVariacoesItem] = useState<
    NfeManualItemVariacaoType[]
  >([]);

  const carregarVariacoesDoProduto = useCallback(async () => {
    try {
      setInLoad(true);

      const variacoesFetch = await api.get(
        `/produtos/${item.produto_id}/variacoes`,
      );

      const variacoesFetched = [] as SelectType[];

      variacoesFetch.data.forEach((cItem: VariacaoProdutoType) => {
        variacoesFetched.push({
          value: cItem.id,
          label: `${cItem.tamanho.descricao} - ${cItem.cor.descricao}`,
          color: '',
        });
      });

      setVariacoesProduto(variacoesFetched);
      setInLoad(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setInLoad(false);
    }
  }, [item]);

  const pegarDescricaoVariacaoProduto = (pId: string) => {
    const variacao = variacoesProduto.filter(
      cVariacao => cVariacao.value === pId,
    )[0];
    return variacao ? variacao.label : '';
  };

  const gerarTituloVariacaoProduto = (pId: string) => {
    return `O que deseja fazer na variação informada: "${pegarDescricaoVariacaoProduto(
      pId,
    )}"?`;
  };

  const adicionarVariacaoItem = useCallback(
    async (data: GradeFormData) => {
      try {
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          variacao_produto_id: Yup.string().required('Informe a variação'),
          quantidade: Yup.string().required('Informe a quantidade'),
        });

        await schema.validate(data, { abortEarly: false });

        const { variacao_produto_id, quantidade } = data;

        setVariacoesItem([
          ...variacoesItem,
          {
            variacao_produto_id: handlerToNumber(variacao_produto_id),
            quantidade: handlerToNumber(quantidade),
          },
        ]);

        setFormLoading(false);
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro adicionar variação');
      }
    },
    [variacoesItem],
  );

  const removerVariacaoItem = (id: string) => {
    setVariacoesItem([
      ...variacoesItem.filter(cItem => `${cItem.variacao_produto_id}` !== id),
    ]);
  };

  const calcularQuantidadeInformada = (): number => {
    return variacoesItem.reduce((acc: number, cItem) => {
      // eslint-disable-next-line no-param-reassign
      acc = cItem.quantidade;
      return acc;
    }, 0);
  };

  const calcularPorcentagemInformada = (): number => {
    return Math.floor(
      (handlerToNumber(`${item.quantidade}`) * 100) /
        calcularQuantidadeInformada(),
    );
  };

  const gerarTituloPorcentagemInformada = () => {
    return `${calcularQuantidadeInformada()}/${item.quantidade} informados!`;
  };

  const salvarVariacoesItem = () => {
    setFormError('');

    if (!variacoesItem || !variacoesItem.length) {
      setFormError('Nenhuma variação informada');
      return false;
    }

    let quantidadeInformada = 0;

    variacoesItem.forEach(cItem => {
      quantidadeInformada += cItem.quantidade;
    });

    if (!item.quantidade) {
      setFormError('Não foi possível determinar a quantidade');
      return false;
    }

    if (quantidadeInformada < Number.parseInt(`${item.quantidade}`, 10)) {
      setFormError(
        `Você informou apenas ${quantidadeInformada} de ${item.quantidade}`,
      );
      return false;
    }

    if (!variacoesItem || !variacoesItem.length) {
      setFormError('Quantidade  variação informada');
      return false;
    }

    onRequestSave(item);
  };

  useEffect(() => {
    carregarVariacoesDoProduto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  if (inLoad) {
    return <Loader />;
  }

  return (
    <Modal
      title={`${item.produto_descricao}`}
      width="120rem"
      visible
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      {variacoesProduto && variacoesProduto.length ? (
        <Container>
          <FormContainer>
            <Form ref={formRef} onSubmit={adicionarVariacaoItem}>
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <InputsContainer>
                  <InputContainer disabled={inLoad}>
                    <span>Variação de Grade (Tamanho/Cor)</span>
                    <div>
                      <Select
                        name="variacao_produto_id"
                        loading={inLoad}
                        options={variacoesItem}
                      />
                    </div>
                  </InputContainer>

                  <InputContainer disabled={formLoading}>
                    <span>Quantidade:</span>
                    <div>
                      <Input name="quantidade" placeholder="" />
                    </div>
                  </InputContainer>

                  <Add>
                    <Button type="submit" background={colors.success}>
                      <BsFillPlusCircleFill size={24} color="#fff" />
                    </Button>
                  </Add>
                </InputsContainer>

                <Progress $percent={calcularPorcentagemInformada()}>
                  <h2>{gerarTituloPorcentagemInformada()}</h2>
                  <div>
                    <span />
                  </div>
                </Progress>

                <Area>
                  <List
                    inModal
                    hideFooter
                    header={[
                      {
                        column: 'variacao',
                        label: 'Variação',
                      },
                      {
                        column: 'quantidade',
                        label: 'Quantidade',
                      },
                    ]}
                    data={[
                      ...variacoesItem.map(cItem => ({
                        id: cItem.variacao_produto_id,
                        variacao: pegarDescricaoVariacaoProduto(
                          `${cItem.variacao_produto_id}`,
                        ),
                        quantidade: cItem.quantidade,
                        list_line_title: gerarTituloVariacaoProduto(
                          `${cItem.variacao_produto_id}`,
                        ),
                      })),
                    ]}
                    options={[
                      {
                        label: '+ Remover',
                        onPress: pItem => {
                          removerVariacaoItem(pItem.id);
                        },
                      },
                    ]}
                  />
                </Area>
              </FormContent>

              <Footer>
                <div>
                  <Button
                    type="button"
                    background={colors.grey}
                    onClick={onRequestClose}
                  >
                    Fechar
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    onClick={salvarVariacoesItem}
                    background={colors.primary}
                  >
                    Finalizar
                  </Button>
                </div>
              </Footer>
            </Form>
          </FormContainer>
        </Container>
      ) : (
        <Empty>{`O item ${item.produto_descricao} não possui variações de grade!`}</Empty>
      )}
    </Modal>
  );
};

export default Grade;
