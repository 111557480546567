import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $noradius }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    width: 100%;
    align-items: center;
    overflow: hidden;
    border-radius: ${$noradius === true ? '0rem' : '0.6rem 0.6rem 0 0'};
    padding: 1rem 1.5rem;
    background: ${theme.colors.controlNotFocus};
    border-bottom: 0.1rem solid ${theme.colors.defaultHighlight};

    @media (max-width: 1024px) {
      padding: 2rem;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 1024px) {
        flex-direction: row;
        width: 100%;
      }
    }

    main {
      margin: 0 0 0 1.5rem;

      @media (max-width: 1024px) {
        margin: 0rem;
      }
    }

    h1 {
      font-size: ${theme.fontSizes.big};
      font-weight: 700;
      color: ${theme.colors.label};
      margin: 0rem;
      padding: 0rem;

      @media (max-width: 1024px) {
        font-size: ${theme.fontSizes.small};
      }
    }

    h2 {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.placeholder};
      margin: 0rem;
      padding: 0rem;

      @media (max-width: 1024px) {
        font-size: ${theme.fontSizes.tiny};
      }
    }

    a {
      button {
        margin: 0;
      }
    }

    menu {
      display: flex;
      flex-direction: row;

      button {
        width: 12rem;
      }

      > button {
        margin-right: 1rem;
      }
    }
  `}
`;
