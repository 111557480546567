import JsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';

import { PedidoType, RelatorioType, TotalizadorType } from '~/types';
import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatTheDateYall } from '~/utils/formatDate';

import { handlerNumberToString } from '~/utils/money';

const ordenarPedidos = (
  pPedidos: PedidoType[],
  configuracao: RelatorioType,
): PedidoType[] => {
  const ordenadores = configuracao.ordenar.filter(
    ordenador => ordenador.enable,
  );

  if (!ordenadores.length) {
    return pPedidos;
  }

  ordenadores.forEach(ordenador => {
    switch (ordenador.field) {
      case 'valor_total':
        if (ordenador.order === 'ASC') {
          pPedidos.sort((a, b) => (a.valor_total > b.valor_total && 1) || -1);
        } else {
          pPedidos.sort((a, b) => (b.valor_total > a.valor_total && 1) || -1);
        }
        break;
      case 'empresa.fantasia':
        if (ordenador.order === 'ASC') {
          pPedidos.sort(
            (a, b) => (a.empresa.fantasia > b.empresa.fantasia && 1) || -1,
          );
        } else {
          pPedidos.sort(
            (a, b) => (b.empresa.fantasia > a.empresa.fantasia && 1) || -1,
          );
        }
        break;

      case 'tabela_preco.descricao':
        if (ordenador.order === 'ASC') {
          pPedidos.sort(
            (a, b) =>
              (a.tabela_preco.descricao > b.tabela_preco.descricao && 1) || -1,
          );
        } else {
          pPedidos.sort(
            (a, b) =>
              (b.tabela_preco.descricao > a.tabela_preco.descricao && 1) || -1,
          );
        }
        break;

      case 'natureza_operacao.descricao':
        if (ordenador.order === 'ASC') {
          pPedidos.sort(
            (a, b) =>
              (a.natureza_operacao.descricao > b.natureza_operacao.descricao &&
                1) ||
              -1,
          );
        } else {
          pPedidos.sort(
            (a, b) =>
              (b.natureza_operacao.descricao > a.natureza_operacao.descricao &&
                1) ||
              -1,
          );
        }
        break;

      case 'cliente_nome':
        if (ordenador.order === 'ASC') {
          pPedidos.sort((a, b) => (a.cliente_nome > b.cliente_nome && 1) || -1);
        } else {
          pPedidos.sort((a, b) => (b.cliente_nome > a.cliente_nome && 1) || -1);
        }
        break;

      case 'data':
        if (ordenador.order === 'ASC') {
          pPedidos.sort((a, b) => (a.created_at > b.created_at && 1) || -1);
        } else {
          pPedidos.sort((a, b) => (b.created_at > a.created_at && 1) || -1);
        }
        break;

      default:
        break;
    }
  });

  return pPedidos;
};

const filtrarAgrupadoresPorAgrupamento = (
  pPedidos: PedidoType[],
  pAgrupamento: string,
): string[] => {
  switch (pAgrupamento) {
    case 'cliente_nome':
      return pPedidos.reduce((acc: string[], pedido: PedidoType) => {
        if (!acc.includes(pedido.cliente_nome)) {
          acc.push(pedido.cliente_nome);
        }
        return acc;
      }, []);
    case 'empresa.fantasia':
      return pPedidos.reduce((acc: string[], pedido: PedidoType) => {
        if (!acc.includes(pedido.empresa.fantasia)) {
          acc.push(pedido.empresa.fantasia);
        }
        return acc;
      }, []);
    case 'natureza_operacao.descricao':
      return pPedidos.reduce((acc: string[], pedido: PedidoType) => {
        if (!acc.includes(pedido.natureza_operacao.descricao)) {
          acc.push(pedido.natureza_operacao.descricao);
        }
        return acc;
      }, []);
    case 'tabela_preco.descricao':
      return pPedidos.reduce((acc: string[], pedido: PedidoType) => {
        if (!acc.includes(pedido.tabela_preco.descricao)) {
          acc.push(pedido.tabela_preco.descricao);
        }
        return acc;
      }, []);
    default:
      return [];
  }
};

const filtrarPedidosPorAgrupador = (
  pPedidos: PedidoType[],
  pAgrupamento: string,
  pAgrupador: string,
): PedidoType[] => {
  switch (pAgrupamento) {
    case 'cliente_nome':
      return pPedidos.filter(pedido => pedido.cliente_nome === pAgrupador);
    case 'empresa.fantasia':
      return pPedidos.filter(pedido => pedido.empresa.fantasia === pAgrupador);
    case 'natureza_operacao.descricao':
      return pPedidos.filter(
        pedido => pedido.natureza_operacao.descricao === pAgrupador,
      );
    case 'tabela_preco.descricao':
      return pPedidos.filter(
        pedido => pedido.tabela_preco.descricao === pAgrupador,
      );
    default:
      return [];
  }
};

// const gerarLogoTipoPDF = () => {
//   return '';
// };

const extrairPropriedadeLinhaPDF = (cPedido: PedidoType, field: string) => {
  let prop = '';

  switch (field) {
    case 'natureza_operacao.descricao':
      prop = `${cPedido.natureza_operacao.descricao}`;
      break;
    case 'tabela_preco.descricao':
      prop = `${cPedido.tabela_preco.descricao}`;
      break;
    case 'empresa.fantasia':
      prop = `${cPedido.empresa.fantasia}`;
      break;
    case 'valor_total':
      prop = `${handlerNumberToString(cPedido.valor_total)}`;
      break;
    case 'cliente_nome':
      prop = `${cPedido.cliente_nome}`;
      break;
    case 'created_at':
      prop = `${formatTheDateYall(cPedido.created_at)}`;
      break;
    default:
      prop = '';
      break;
  }

  return prop;
};

const calcularLargura = (tamanhoEmPorcentagem: number, largura: number) => {
  const larguraDespedidoda = largura - 30;
  const tamanhoCelula = larguraDespedidoda * (tamanhoEmPorcentagem / 100);
  return tamanhoCelula;
};

const gerarLinhasPDF = (
  pPedidos: PedidoType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const output: RowInput[] = [];
  pPedidos.forEach(cPedido => {
    const line: RowInput = [];

    const ordenadores = pConfiguracao.ordenar.filter(
      ordenador => ordenador.enable,
    );

    let cacheTotalLarguras = 0;
    ordenadores.forEach((ordenador, key) => {
      let larguraColuna = ordenador.width;
      cacheTotalLarguras += larguraColuna;

      const ultimaColuna = ordenadores.length === key + 1;
      if (ultimaColuna) {
        const larguraPreenchivel = 100 - (cacheTotalLarguras - larguraColuna);
        if (larguraPreenchivel > 0) {
          larguraColuna = larguraPreenchivel;
        }
      }

      line.push({
        content: `${extrairPropriedadeLinhaPDF(cPedido, ordenador.field)}`,
        styles: {
          cellWidth: calcularLargura(larguraColuna, largura),
        },
      });
    });

    output.push(line);
  });

  return output;
};

const renderizarListagemPDF = (
  pPedidos: Array<PedidoType>,
  pAgrupamentos: Array<string>,
  pConfiguracao: RelatorioType,
  largura: number,
) => {
  let output: RowInput[] = [];

  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  if (!pAgrupamentos.length) {
    output = [...gerarLinhasPDF(pPedidos, pConfiguracao, largura)];
    return output;
  }

  const currentAgrupamento = pAgrupamentos[0];

  const agrupadores = filtrarAgrupadoresPorAgrupamento(
    pPedidos,
    currentAgrupamento,
  );

  agrupadores.forEach(agrupador => {
    const pedidosNoAgrupador = renderizarListagemPDF(
      filtrarPedidosPorAgrupador(pPedidos, currentAgrupamento, agrupador),
      [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
      pConfiguracao,
      largura,
    );

    output = [
      ...output,
      [
        {
          content: `${agrupador}`,
          colSpan: totalColunas,
          styles: { halign: 'center', fillColor: '#ccc' },
        },
      ],
      ...pedidosNoAgrupador,
    ];
  });

  return output;
};

const gerarTabelaPDF = (
  pPedidos: PedidoType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const cAgrupadores = pConfiguracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);
  return renderizarListagemPDF(pPedidos, cAgrupadores, pConfiguracao, largura);
};

const gerarTotalizadoresPDF = (
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
) => {
  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  const totalizadoresPDF: RowInput[] = [];

  pTotalizadores.forEach(totalizador => {
    let enable = false;

    pConfiguracao.totalizar.forEach(cfgTotalizador => {
      if (cfgTotalizador.field === totalizador.chave && cfgTotalizador.enable) {
        enable = true;
      }
    });

    if (enable) {
      totalizadoresPDF.push([
        {
          content: `${totalizador.chave}`,
          colSpan: totalColunas - 1,
          styles: { halign: 'right' },
        },
        {
          content: `${handlerNumberToString(Number(totalizador.valor))}`,
          styles: { halign: 'center' },
        },
      ]);
    }
  });

  return totalizadoresPDF;
};

const exportarPDF = (
  pCabecalho: RelatorioCabecalhoType,
  pPedidos: PedidoType[],
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
): void => {
  const handler = async () => {
    const doc = new JsPDF({
      orientation: pConfiguracao.modo === 'RETRATO' ? 'portrait' : 'landscape',
    });

    let cMarginTop = 34;
    let h = 26;
    if (
      !pCabecalho.subtitle1 &&
      !pCabecalho.subtitle2 &&
      !pCabecalho.subtitle3
    ) {
      h = 13;
      cMarginTop = 21;
    } else if (!pCabecalho.subtitle2 && !pCabecalho.subtitle3) {
      h = 18;
      cMarginTop = 26;
    } else if (!pCabecalho.subtitle3) {
      h = 22;
      cMarginTop = 30;
    }

    autoTable(doc, {
      headStyles: {
        lineColor: '#000',
        overflow: 'ellipsize',
      },

      bodyStyles: {
        overflow: 'ellipsize',
      },

      didDrawPage: data => {
        // LOGOTIPO
        // const base64Img = gerarLogoTipoPDF();

        doc.setFillColor(255, 250, 250);

        // NOME DA EMPRESA
        doc.setFillColor(255, 250, 250);
        doc.setDrawColor(140, 143, 157);

        doc.roundedRect(
          data.settings.margin.left,
          5,
          doc.internal.pageSize.getWidth() -
            data.settings.margin.left -
            data.settings.margin.right -
            2,
          h,
          1,
          1,
          'FD',
        );
        doc.setTextColor(40);
        doc.setFontSize(14);
        doc.setFont('Courier-Bold', 'bold');
        doc.text(pCabecalho.title, data.settings.margin.left + 4, 13);

        doc.setFontSize(10);
        doc.setFont('Courier', 'bold');

        if (pCabecalho.subtitle1) {
          doc.text(pCabecalho.subtitle1, data.settings.margin.left + 4, 18);
        }

        doc.setFont('Courier', 'normal');

        if (pCabecalho.subtitle2) {
          doc.text(pCabecalho.subtitle2, data.settings.margin.left + 4, 22);
        }

        if (pCabecalho.subtitle3) {
          doc.text(pCabecalho.subtitle3, data.settings.margin.left + 4, 26);
        }
      },

      margin: { top: cMarginTop },

      // CABEÇALHO
      head: [
        [
          ...pConfiguracao.ordenar
            .filter(ordenador => ordenador.enable)
            .map(ordenador => ({
              content: ordenador.label,
            })),
        ],
      ],

      // CORPO
      body: [
        ...gerarTabelaPDF(
          pPedidos,
          pConfiguracao,
          doc.internal.pageSize.getWidth(),
        ),
        ...gerarTotalizadoresPDF(pTotalizadores, pConfiguracao),
      ],
    });

    doc.output('dataurlnewwindow');
    // doc.save('relatório.pdf');
  };

  handler();
};

export {
  ordenarPedidos,
  filtrarAgrupadoresPorAgrupamento,
  filtrarPedidosPorAgrupador,
  exportarPDF,
};
