import styled, { css } from 'styled-components';

import { TagsDropDownAreaItemProps } from './interface';

export const TagsDropDownAreaItem = styled.div<TagsDropDownAreaItemProps>`
  ${({ theme, $cor }) => css`
    float: left;
    border: 0.1rem solid ${theme.colors.contrast};
    color: ${theme.colors.label};
    padding: 0.8rem;
    text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    background: ${$cor};
    border-radius: ${theme.radio.default};
    font-size: ${theme.fontSizes.default};
    font-weight: 600;
    cursor: move;
    text-decoration: none;
    outline: none;
    min-width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      button {
        opacity: 1;
      }
    }

    button {
      opacity: 0;
      cursor: pointer;
      background: none;
      transition: all 0.5s ease;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;
