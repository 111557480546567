import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    padding: 2rem 2rem 0 2rem;
    font-weight: 700;
    margin-top: 2rem;
  `};
`;
