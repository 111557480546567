import React, { useState, useEffect } from 'react';

import { useTheme } from 'styled-components';

import swal from 'sweetalert2';

import { AiOutlineLoading3Quarters, AiFillEye } from 'react-icons/ai';
import { CgFileDocument } from 'react-icons/cg';
import { FaTrash, FaCheck, FaFileUpload } from 'react-icons/fa';
import { BiSolidErrorAlt } from 'react-icons/bi';

import { BiError } from 'react-icons/bi';

import api from '~/services/api';

import { Container, Content, Loading, IC } from './styles';

import { XMLProps } from './interface';

import { useNFE } from '~/hooks/nfe';

import { formatBytesNFE } from '~/utils/nfe';

import EdicaoXML from './EdicaoXML';
import ErrosXML from './ErrosXML';

import { handlerErrors } from '~/utils/error';

const XML: React.FC<XMLProps> = ({ nfe }) => {
  const { colors } = useTheme();

  const { removerNFE, validarNFE, editarNFE, existeErroNaNFE } = useNFE();

  const [edicaoXMLvisible, setEdicaoXMLVisible] = useState(false);
  const [errosXMLVisible, setErrosXMLVisible] = useState(false);

  const abrirEdicaoXML = () => {
    setEdicaoXMLVisible(true);
  };

  const abrirErrosXML = () => {
    setErrosXMLVisible(true);
  };

  const fecharEdicaoXML = () => {
    setEdicaoXMLVisible(false);
  };

  const fecharErrosXML = () => {
    setErrosXMLVisible(false);
  };

  const removerXML = () => {
    swal
      .fire({
        title: `Deseja realmente remover o arquivo "${nfe.nome}"?`,
        text: `Ao confirmar, o arquivo será removido da lista de envio e as alterações realizadas nele serão perdidas!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        width: 'auto',
      })
      .then(result => {
        if (result.isConfirmed) {
          removerNFE(nfe);
        }
      });
  };

  const getClassNameByStatus = (statusLoad: string, isError: boolean) => {
    if (statusLoad === 'in-load') {
      return 'in-load';
    }
    if (isError) {
      return 'error';
    }
    return 'success';
  };

  const saveNFE = () => {
    swal
      .fire({
        title: `Deseja realmente salvar o arquivo "${nfe.nome}"?`,
        text: `Ao confirmar, o arquivo será processado no sistema!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        width: 'auto',
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            editarNFE({
              ...nfe,
              status: 'in-load',
            });

            const response = await api.post(`nfe`, {
              ...nfe.arquivo,
              conta_corrente_id: nfe.conta_corrente_id,
              formas: [
                ...nfe.formas.map(cForma => ({
                  condicao_pagamento_id: cForma.condicao_pagamento_id,
                  valor: cForma.valor,
                  cheques: [],
                })),
              ],
            });

            editarNFE({
              ...nfe,
              status: 'loaded',
            });

            const { data } = response;

            if (data && data.status !== 'error') {
              if (nfe) {
                swal.fire({
                  title: `${
                    data.message || 'Entrada fiscal salva com sucesso!'
                  }`,
                  icon: 'success',
                });
                removerNFE(nfe);
              }
            } else {
              swal.fire({
                title: `${data.message || 'Erro não identifiado'}`,
                icon: 'error',
              });
            }
          } catch (e) {
            editarNFE({
              ...nfe,
              status: 'loaded',
            });

            const message = handlerErrors(e);
            swal.fire({
              title: `${message || 'Erro não identifiado'}`,
              icon: 'error',
            });
          }
        }
      });
  };

  useEffect(() => {
    validarNFE(nfe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className={getClassNameByStatus(nfe.status, existeErroNaNFE(nfe))}
    >
      <CgFileDocument size={46} color={colors.label} />
      <Content
        className={getClassNameByStatus(nfe.status, existeErroNaNFE(nfe))}
      >
        <strong>{nfe.nome}</strong>
        <small>{formatBytesNFE(nfe.tamanho)}</small>

        {nfe.status === 'in-load' ? (
          <Loading>
            <span>
              <AiOutlineLoading3Quarters size={22} color={colors.label} />
            </span>
          </Loading>
        ) : (
          <div>
            {nfe.status === 'loaded' && existeErroNaNFE(nfe) === true ? (
              <p>
                <BiSolidErrorAlt size={32} color={colors.error} />
                <span>
                  <h1>
                    {nfe && nfe.erros && nfe.erros.length > 0
                      ? `${
                          nfe.erros.length > 1
                            ? `${nfe.erros.length} ERROS ENCONTRADOS`
                            : '1 ERRO ENCONTRADO'
                        }`
                      : 'Nenhum erro encontrado'}
                  </h1>
                  <a onClick={() => abrirErrosXML()}>VER DETALHES</a>
                </span>
              </p>
            ) : null}

            <button type="button" onClick={abrirEdicaoXML}>
              <AiFillEye size={22} color={colors.label} />
            </button>
            <button type="button" onClick={removerXML}>
              <FaTrash size={18} color={colors.label} />
            </button>

            {nfe.status === 'loaded' && existeErroNaNFE(nfe) === false ? (
              <button className="save" type="button" onClick={saveNFE}>
                <FaFileUpload size={30} color={colors.label} />
              </button>
            ) : null}
          </div>
        )}

        {nfe.status === 'loaded' && existeErroNaNFE(nfe) === false ? (
          <IC>
            <FaCheck size={32} color={colors.green} />
          </IC>
        ) : null}

        {nfe.status === 'loaded' && existeErroNaNFE(nfe) === true ? (
          <IC>
            <BiError size={32} color={colors.error} />
          </IC>
        ) : null}
      </Content>

      {edicaoXMLvisible ? (
        <EdicaoXML
          nfe={nfe}
          onRequestClose={fecharEdicaoXML}
          visible={edicaoXMLvisible}
        />
      ) : null}

      {errosXMLVisible ? (
        <ErrosXML
          nfe={nfe}
          onRequestClose={fecharErrosXML}
          visible={errosXMLVisible}
        />
      ) : null}
    </Container>
  );
};

export default XML;
