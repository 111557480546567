import React, { useState, useCallback, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { FiUsers } from 'react-icons/fi';

import qs from 'qs';

import swal from 'sweetalert2';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import { handlerToNumber } from '~/utils/money';

import Header from '~/components/Header';
import List from '~/components/List';

import { Loader, BarraLateral, Plate } from '~/components';

import Filtros from './Filtros';

import { Container, Content, Area, Left, Right } from './styles';

import { RelatorioType, FiltroType, RelatorioPrecoType } from '~/types';

import { RelatorioCabecalhoType } from '~/types/relatorio';

import configuracaoInicial from '~/schemas/relatorio-precos.json';
import { formatCustomBR } from '~/utils/formatDate';

import { exportarPDF } from './engine';

const Precos: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const [configuracao, setConfiguracao] =
    useState<RelatorioType>(configuracaoInicial);

  const [filtros, setFiltros] = useState<FiltroType[]>([]);

  const [precos, setPrecos] = useState<RelatorioPrecoType[]>([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [loading, setLoading] = useState(false);

  const voltarAoTopo = () => {
    window.scrollTo(0, 0);
  };

  const sintetizarParametros = (pFiltros: FiltroType[]): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parametros: any = {};

    pFiltros.forEach((cFiltro: FiltroType) => {
      const { valor, nome } = cFiltro;
      const cValor = valor;
      if (valor) {
        parametros[nome] = cValor;
      }
    });

    const qsParametros = qs.stringify(parametros);

    return qsParametros;
  };

  const carregarPrecos = useCallback(async (pFiltros: FiltroType[]) => {
    try {
      setLoading(true);

      const response = await api.get(
        `produtos/informacoes/relPreco?${sintetizarParametros([
          ...pFiltros,
          { nome: 'perPage', valor: '50' },
        ])}`,
      );

      const { data } = response;

      if (data && data.status !== 'error') {
        const { data: cPrecos, lastPage } = data;

        setPrecos(cPrecos);

        if (lastPage) {
          setTotalPaginas(handlerToNumber(`${lastPage}`));
        }
      }

      setLoading(false);
      voltarAoTopo();
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const reCarregarPrecos = useCallback(
    async (
      page?: string,
      order?: Array<{ direction: string; column: string }>,
      filter?: Array<{ value: string; column: string }>,
    ) => {
      try {
        setLoading(true);

        const cFiltros: FiltroType[] = [...filtros];

        if (page) {
          setPaginaAtual(handlerToNumber(page));
          cFiltros.push({
            nome: 'page',
            valor: page ? `${page}` : paginaAtual,
          });
        }

        if (order && order.length) {
          const outputOrder: Array<string> = [];
          order.forEach(orderCurrent => {
            outputOrder.push(
              `${orderCurrent.direction === 'asc' ? 'asc-' : 'desc-'}${
                orderCurrent.column
              }`,
            );
          });
          cFiltros.push({
            nome: 'order_by',
            valor: `${outputOrder.join(',')}`,
          });
        }

        if (filter && filter.length) {
          filter.forEach(filterCurrent => {
            cFiltros.push({
              nome: filterCurrent.column,
              valor: `${filterCurrent.value}`,
            });
          });
        }

        carregarPrecos(cFiltros);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [carregarPrecos, filtros, paginaAtual],
  );

  const aoAplicarFiltros = async (pFiltros: FiltroType[]) => {
    setFiltros(pFiltros);
    setPaginaAtual(1);
    carregarPrecos([...pFiltros, { nome: 'page', valor: 1 }]);
  };

  const aoAplicarConfiguracao = (
    pConfiguracao: RelatorioType,
    preloader: boolean,
  ) => {
    const cFiltros: FiltroType[] = [];

    // ORDENAÇÃO
    if (pConfiguracao && pConfiguracao.ordenar.length) {
      cFiltros.push({
        nome: 'order_by',
        valor: pConfiguracao.ordenar
          .reduce((acc: string[], item) => {
            if (item.enable) {
              acc.push(
                `${item.order === 'ASC' ? 'asc-' : 'desc-'}${item.field}`,
              );
            }
            return acc;
          }, [])
          .join(','),
      });
    }

    if (preloader) {
      setLoading(true);
      setConfiguracao(pConfiguracao);
      setTimeout(() => {
        setLoading(false);
        aoAplicarFiltros(cFiltros);
      }, 1000);
    } else {
      setConfiguracao(pConfiguracao);
      aoAplicarFiltros(cFiltros);
    }
  };

  const aoExportarRelatorio = async (pConfiguracao: RelatorioType) => {
    try {
      const cabecalho: RelatorioCabecalhoType = {
        title: user.workspace.nome,
        subtitle1: `Relatório de Preços`,
        subtitle2: `Data do relatório: ${formatCustomBR(new Date())}`,
        subtitle3: '',
      };

      await exportarPDF(cabecalho, filtros, pConfiguracao);

      swal.fire({
        title: 'Relatório gerado com sucesso!',
        text: `Confira na aba ao lado!`,
        icon: 'success',
      });
    } catch (e) {
      swal.fire({
        title: 'Oops...',
        text: `${(e as Error).message}`,
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  useEffect(() => {
    carregarPrecos([]);
  }, [carregarPrecos]);

  return (
    <Container>
      <Header />

      <Content>
        <Area>
          <Left>
            <BarraLateral
              aoAplicarConfiguracao={aoAplicarConfiguracao}
              configuracao={configuracao}
              aoExportarRelatorio={aoExportarRelatorio}
            >
              <Filtros aoAplicarFiltros={aoAplicarFiltros} />
            </BarraLateral>
          </Left>
          <Right>
            <Plate
              title="Preços"
              subtitle="Gere relatórios personalizados de precificações"
              icon={() => <FiUsers size={32} color={colors.label} />}
              noRadius
            />

            {precos && !loading ? (
              <List
                onFetch={reCarregarPrecos}
                header={[
                  {
                    column: 'produtos_id',
                    label: 'ID',
                    width: 130,
                    search: true,
                    isNumeric: true,
                  },
                  {
                    column: 'produto_descricao',
                    label: 'PRODUTO',
                    search: true,
                  },
                  {
                    column: 'produto_unidade',
                    label: 'UN',
                    search: true,
                    width: 150,
                  },
                  {
                    column: 'tabela_preco_id',
                    label: 'TABELA',
                    search: true,
                    width: 180,
                  },

                  {
                    column: 'empresa_razao',
                    label: 'EMPRESA',
                    search: true,
                    width: 200,
                  },
                  {
                    column: 'produto_preco',
                    label: 'PREÇO',
                    search: true,
                    width: 180,
                  },
                  {
                    column: 'produto_tamanho',
                    label: 'TAM',
                    search: true,
                    width: 130,
                  },
                  {
                    column: 'produto_cor',
                    label: 'COR',
                    search: true,
                    width: 130,
                  },
                ]}
                data={[
                  ...precos.map((preco: RelatorioPrecoType) => ({
                    ...preco,
                  })),
                ]}
                options={[]}
                pagination
                activePage={paginaAtual}
                totalPages={totalPaginas}
              />
            ) : null}

            {loading ? <Loader /> : null}
          </Right>
        </Area>
      </Content>
    </Container>
  );
};

export default Precos;
