import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BsFillPlusCircleFill, BsFillPuzzleFill } from 'react-icons/bs';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { getValidationErrors } from '~/utils';

import { useNFE } from '~/hooks/nfe';

import api from '~/services/api';

import {
  Add,
  AssocBtn,
  Container,
  InfoHelp,
  ListArea,
  Progress,
} from './styles';

import Loader from '~/components/Loader';

import {
  Button,
  ErrorMessage,
  FindProduto,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
  Plate,
  Select,
} from '~/components';

import List from '~/components/List';

import {
  OcorrenciaFormData,
  OcorrenciaProps,
  VariacaoFormData,
} from './interface';

import {
  ProdutoType,
  ProdutoVariacaoNfeType,
  SelectType,
  VariacaoProdutoType,
} from '~/types';

import { handlerToNumber } from '~/utils/money';
import { handlerErrors } from '~/utils/error';

const Ocorrencia: React.FC<OcorrenciaProps> = ({
  onRequestClose,
  produto,
  nfe,
}) => {
  const { validarNFE, editarNFE } = useNFE();

  const { colors } = useTheme();

  const [inLoad, setInLoad] = useState(true);
  const [variacoes, setVariacoes] = useState<SelectType[]>([]);

  const [referencia, setReferencia] = useState<ProdutoType | null>(null);
  const [distribuicao, setDistribuicao] = useState<
    Array<ProdutoVariacaoNfeType>
  >(produto && produto.variacoes ? [...produto.variacoes] : []);

  const [formErrorAssoc, setFormErrorAssoc] = useState('');
  const [formErrorItens, setFormErrorItens] = useState('');
  const [formErrorUN, setFormErrorUN] = useState('');

  const [conversao_qtd, set_conversao_qtd] = useState('');

  const [conversao_operacao, set_conversao_operacao] = useState<SelectType>({
    label: 'Multiplicar',
    value: 'multiplicar',
    color: '',
  });

  const [qtd_entrada, set_qtd_entrada] = useState('');

  const [formLoading, setFormLoading] = useState(false);

  const formAssocRef = useRef<FormHandles>(null);
  const formItensRef = useRef<FormHandles>(null);
  const formUnRef = useRef<FormHandles>(null);

  const handlerSubmitAssoc = useCallback(async () => {
    try {
      setFormErrorAssoc('');
      setFormLoading(true);

      formItensRef.current?.setErrors({});

      setFormLoading(false);

      if (!referencia) {
        return setFormErrorAssoc(`Selecione um produto primeiramente.`);
      }

      try {
        const response = await api.post(`nfe/vinculo-produto`, {
          DEST_CNPJ: `${nfe.arquivo.DEST_CNPJ}`,
          cnpj_parceiro: `${nfe.arquivo.EMIT_CNPJ}`,
          produto_id_parceiro: `${produto.PROD_cProd}`,
          ean_parceiro: '',
          produto_id: referencia.id,
        });

        const { data } = response;

        if (data && data.status !== 'error') {
          if (nfe) {
            validarNFE(nfe);
            onRequestClose();
          }
        } else {
          setFormErrorAssoc(`${data.message}`);
        }
      } catch (e) {
        setFormLoading(false);
        const message = handlerErrors(e);
        setFormErrorAssoc(`${message}`);
      }
    } catch (err) {
      setFormLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formItensRef.current?.setErrors(errors);
        return;
      }
      setFormErrorAssoc('Ocorreu ao tentar associar o item');
    }
  }, [validarNFE, onRequestClose, nfe, produto, referencia]);

  const handlerSubmitFormAssoc = () => {
    formAssocRef.current?.submitForm();
  };

  const handleSubmitItens = useCallback(
    async (data: VariacaoFormData) => {
      try {
        setFormErrorItens('');
        setFormLoading(true);

        formItensRef.current?.setErrors({});

        const schema = Yup.object().shape({
          variacao_produto_id: Yup.string().required('Informe a variação'),
          quantidade: Yup.string().required('Informe a quantidade'),
        });

        await schema.validate(data, { abortEarly: false });

        const { variacao_produto_id, quantidade } = data;

        setDistribuicao([
          ...distribuicao,
          {
            variacao_produto_id,
            quantidade,
          },
        ]);

        setFormLoading(false);
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formItensRef.current?.setErrors(errors);
          return;
        }
        setFormErrorItens('Ocorreu um erro adicionar variação');
      }
    },
    [distribuicao],
  );

  const getItemVariacao = (id: string) => {
    const variacao = variacoes.filter(cVariacao => cVariacao.value === id)[0];
    return variacao ? variacao.label : '';
  };

  const removerItemVariacao = (id: string) => {
    setDistribuicao([
      ...distribuicao.filter(cItem => cItem.variacao_produto_id !== id),
    ]);
  };

  const totalQtdInformado = (): number => {
    return distribuicao.reduce((acc: number, cItem) => {
      // eslint-disable-next-line no-param-reassign
      acc = handlerToNumber(cItem.quantidade);
      return acc;
    }, 0);
  };

  const porcentagemQtdInformado = (): number => {
    if (!produto.PROD_qCom) {
      return 0;
    }
    return Math.floor(
      (handlerToNumber(`${produto.PROD_qCom}`) * 100) / totalQtdInformado(),
    );
  };

  const handlerSaveVariacoes = () => {
    if (!distribuicao || !distribuicao.length) {
      setFormErrorItens('Nenhuma variação informada');
      return false;
    }

    let quantidadeInformada = 0;

    distribuicao.forEach(cItem => {
      quantidadeInformada += Number.parseInt(cItem.quantidade, 10);
    });

    if (!produto.PROD_qCom) {
      setFormErrorItens('Não foi possível determinar a quantidade');
      return false;
    }

    if (quantidadeInformada < Number.parseInt(produto.PROD_qCom, 10)) {
      setFormErrorItens(
        `Você informou apenas ${quantidadeInformada} de ${produto.PROD_qCom}`,
      );
      return false;
    }

    if (!distribuicao || !distribuicao.length) {
      setFormErrorItens('Quantidade  variação informada');
      return false;
    }

    if (nfe && nfe.arquivo && nfe.arquivo.itens) {
      editarNFE({
        ...nfe,
        arquivo: {
          ...nfe.arquivo,
          itens: [
            ...nfe.arquivo.itens.filter(
              cItem => cItem.produto_id !== produto.produto_id,
            ),
            {
              ...produto,
              variacoes: [...distribuicao],
            },
          ],
        },
      });
      onRequestClose();
    }
  };

  const handlerSubmitFormUN = () => {
    formUnRef.current?.submitForm();
  };

  const handleSubmitUN = useCallback(
    async (data: OcorrenciaFormData) => {
      try {
        setFormErrorUN('');
        setFormLoading(true);

        formUnRef.current?.setErrors({});

        const schema = Yup.object().shape({
          qtd_compra: Yup.string().required('Informe a Quantidade da compra'),
          un_compra: Yup.string().required('Informe a Unidade da Compra'),
          un_cadastro: Yup.string().required('Informe a Unidade do Cadastro'),
          conversao_qtd: Yup.string().required(
            'Informe a Quantidade Conversão',
          ),
          conversao_operacao: Yup.string().required(
            'Informe a Operação Conversão',
          ),
          qtd_entrada: Yup.string().required('Informe a Quantidade de Entrada'),
        });

        await schema.validate(data, { abortEarly: false });

        if (nfe && nfe.arquivo && nfe.arquivo.itens) {
          const nfeUpdatedItens = {
            ...nfe,
            arquivo: {
              ...nfe.arquivo,
              itens: [
                ...nfe.arquivo.itens.map(cItem => {
                  if (cItem.produto_id === produto.produto_id) {
                    return {
                      ...cItem,
                      conversao: Number(qtd_entrada),
                    };
                  }
                  return cItem;
                }),
              ],
            },
          };

          editarNFE(nfeUpdatedItens);
          validarNFE(nfeUpdatedItens);
          onRequestClose();
        }

        setFormLoading(false);
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formUnRef.current?.setErrors(errors);
          return;
        }
        setFormErrorUN('Ocorreu um erro ao corrigir a conversão de unidade.');
      }
    },
    [
      editarNFE,
      nfe,
      onRequestClose,
      produto.produto_id,
      qtd_entrada,
      validarNFE,
    ],
  );

  const loadVariacoes = useCallback(async () => {
    try {
      setInLoad(true);

      if (!produto || !produto.produto_id) {
        setInLoad(false);
        return false;
      }

      const variacoesFetch = await api.get(
        `/produtos/${produto.produto_id}/variacoes`,
      );

      const variacoesFetched = [] as SelectType[];

      variacoesFetch.data.forEach((item: VariacaoProdutoType) => {
        variacoesFetched.push({
          value: item.id,
          label: `${item.tamanho.descricao} - ${item.cor.descricao}`,
          color: '',
        });
      });

      setVariacoes(variacoesFetched);
      setInLoad(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setInLoad(false);
    }
  }, [produto]);

  useEffect(() => {
    loadVariacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produto]);

  useEffect(() => {
    if (produto && produto.PROD_qCom && conversao_qtd) {
      if (conversao_operacao && conversao_operacao.value === 'multiplicar') {
        set_qtd_entrada(
          `${
            handlerToNumber(produto.PROD_qCom) * handlerToNumber(conversao_qtd)
          }`,
        );
      }
      if (conversao_operacao && conversao_operacao.value === 'dividir') {
        set_qtd_entrada(
          `${
            handlerToNumber(produto.PROD_qCom) / handlerToNumber(conversao_qtd)
          }`,
        );
      }
    }
  }, [produto, conversao_qtd, conversao_operacao]);

  if (inLoad) {
    return <Loader />;
  }

  const verificarFalhaPorCodigo = (codigo: string) => {
    return produto && produto.erros && produto.erros.length
      ? produto.erros.reduce((acc, item) => {
          if (item.codigo === `${codigo}`) {
            acc = true;
          }
          return acc;
        }, false)
      : false;
  };

  return (
    <Modal
      title={`${produto.PROD_xProd}`}
      width="120rem"
      visible
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        {verificarFalhaPorCodigo('01') ? (
          <FormContainer>
            <Form ref={formAssocRef} onSubmit={handlerSubmitAssoc}>
              <FormContent>
                <ErrorMessage
                  error={formErrorAssoc}
                  onDismiss={() => setFormErrorAssoc('')}
                />

                <FormBlock>
                  <Plate
                    title="Associar Produto"
                    subtitle="Selecione o produto referente a este item"
                    icon={() => (
                      <BsFillPuzzleFill size={32} color={colors.label} />
                    )}
                  />

                  <InputsContainer>
                    <InputContainer disabled={inLoad}>
                      <span>Produto:</span>
                      <div>
                        <Input
                          name="produto"
                          value={
                            referencia
                              ? `${referencia?.id}: ${referencia?.descricao}`
                              : ''
                          }
                          disabled
                          placeholder="Selecione o produto referente"
                        />
                        <AssocBtn>
                          <FindProduto
                            onlySearch
                            onSelectValue={selecionados => {
                              if (selecionados.length) {
                                setReferencia(selecionados[0]);
                              }
                            }}
                          />
                        </AssocBtn>
                      </div>
                    </InputContainer>
                  </InputsContainer>
                </FormBlock>
              </FormContent>
              <FormFooter>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
                <Button
                  type="button"
                  onClick={handlerSubmitFormAssoc}
                  background={colors.primary}
                >
                  Associar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        ) : null}

        {verificarFalhaPorCodigo('02') ? (
          <FormContainer>
            <Form ref={formItensRef} onSubmit={handleSubmitItens}>
              <FormContent>
                <ErrorMessage
                  error={formErrorItens}
                  onDismiss={() => setFormErrorItens('')}
                />

                <FormBlock>
                  <Plate
                    title="Informar Variações"
                    subtitle="Tamanhos a cores"
                    icon={() => (
                      <BsFillPuzzleFill size={32} color={colors.label} />
                    )}
                  />
                  <InputsContainer>
                    <InputContainer disabled={inLoad}>
                      <span>Variação de Grade (Tamanho/Cor)</span>
                      <div>
                        <Select
                          name="variacao_produto_id"
                          loading={inLoad}
                          options={variacoes}
                        />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Quantidade:</span>
                      <div>
                        <Input name="quantidade" placeholder="" />
                      </div>
                    </InputContainer>

                    <Add>
                      <Button type="submit" background={colors.success}>
                        <BsFillPlusCircleFill size={24} color="#fff" />
                      </Button>
                    </Add>
                  </InputsContainer>

                  {produto.PROD_qCom &&
                  handlerToNumber(produto.PROD_qCom) > 1 ? (
                    <Progress $percent={porcentagemQtdInformado()}>
                      <h2>
                        {`${totalQtdInformado()} de ${handlerToNumber(
                          produto.PROD_qCom,
                        )} informados!`}
                      </h2>
                      <div>
                        <span />
                      </div>
                    </Progress>
                  ) : null}

                  <ListArea>
                    <List
                      inModal
                      header={[
                        {
                          column: 'variacao',
                          label: 'Variação',
                        },
                        {
                          column: 'quantidade',
                          label: 'Quantidade',
                        },
                      ]}
                      data={[
                        ...distribuicao.map(cItem => ({
                          id: cItem.variacao_produto_id,
                          variacao: getItemVariacao(cItem.variacao_produto_id),
                          quantidade: cItem.quantidade,
                          list_line_title: `O que deseja fazer na variação informada: "${getItemVariacao(
                            cItem.variacao_produto_id,
                          )}"?`,
                        })),
                      ]}
                      options={[
                        {
                          label: '+ Remover',
                          onPress: item => {
                            removerItemVariacao(item.id);
                          },
                        },
                      ]}
                    />
                  </ListArea>
                </FormBlock>
              </FormContent>

              <FormFooter>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
                <Button
                  type="button"
                  onClick={handlerSaveVariacoes}
                  background={colors.primary}
                >
                  Associar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        ) : null}

        {verificarFalhaPorCodigo('03') ? (
          <FormContainer>
            <Form
              ref={formUnRef}
              initialData={{
                qtd_compra: produto.PROD_qCom,
                un_compra: produto.PROD_uCom,
                un_cadastro: produto.unidade,
                qtd_entrada: produto.conversao,
              }}
              onSubmit={handleSubmitUN}
            >
              <FormContent>
                <ErrorMessage
                  error={formErrorUN}
                  onDismiss={() => setFormErrorUN('')}
                />

                <FormBlock>
                  <Plate
                    title="Conversão de Unidade"
                    subtitle="Quantidade de entrada x conversão"
                    icon={() => (
                      <BsFillPuzzleFill size={32} color={colors.label} />
                    )}
                  />
                  <InputsContainer>
                    <InputContainer disabled={formLoading} width={250}>
                      <span>Quantidade da compra</span>
                      <div>
                        <Input name="qtd_compra" placeholder="" disabled />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={250}>
                      <span>Unidade da compra</span>
                      <div>
                        <Input name="un_compra" placeholder="" disabled />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={250}>
                      <span>Unidade do cadastro</span>
                      <div>
                        <Input name="un_cadastro" placeholder="" disabled />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Operação</span>
                      <div>
                        <Select
                          name="conversao_operacao"
                          loading={inLoad}
                          value={conversao_operacao}
                          onSelectedOption={item =>
                            set_conversao_operacao(item)
                          }
                          options={[
                            {
                              label: 'Multiplicar',
                              value: 'multiplicar',
                              color: '',
                            },
                            {
                              label: 'Dividir',
                              value: 'dividir',
                              color: '',
                            },
                          ]}
                        />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={250}>
                      <span>Conversão</span>
                      <div>
                        <Input
                          name="conversao_qtd"
                          value={conversao_qtd}
                          onChange={e => set_conversao_qtd(e.target.value)}
                          placeholder=""
                        />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading} width={250}>
                      <span>Quantidade de entrada</span>
                      <div>
                        <Input
                          name="qtd_entrada"
                          placeholder=""
                          disabled
                          value={qtd_entrada}
                        />
                      </div>
                    </InputContainer>
                    {produto &&
                    qtd_entrada &&
                    conversao_qtd &&
                    conversao_operacao ? (
                      <InfoHelp>
                        {`${produto.PROD_qCom} ${produto.PROD_uCom} = ${qtd_entrada} ${produto.unidade}`}
                      </InfoHelp>
                    ) : null}
                  </InputsContainer>
                </FormBlock>

                <FormFooter>
                  <Button
                    type="button"
                    background={colors.grey}
                    onClick={onRequestClose}
                  >
                    Fechar
                  </Button>
                  <Button
                    type="button"
                    onClick={handlerSubmitFormUN}
                    background={colors.primary}
                  >
                    Converter
                  </Button>
                </FormFooter>
              </FormContent>
            </Form>
          </FormContainer>
        ) : null}
      </Container>
    </Modal>
  );
};

export default Ocorrencia;
