import React, { useEffect, useRef } from 'react';

import { useTheme } from 'styled-components';

import { BsFillDashCircleFill, BsFillPlusCircleFill } from 'react-icons/bs';
import { AiFillFileImage } from 'react-icons/ai';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useCart } from '~/hooks/cart';

import { getValidationErrors } from '~/utils';

import {
  Modal,
  InputContainer,
  InputNumber,
  Input,
  Button,
} from '~/components';

import { Container, Image, Helper, Footer } from './styles';

import { EditarItemProps, EditarItemFormData } from './interface';

const EditarItem: React.FC<EditarItemProps> = ({
  item,
  visible,
  onRequestClose,
}) => {
  const { colors } = useTheme();

  const { adicionarItem } = useCart();

  const formRef = useRef<FormHandles>(null);

  const focusInInputQtd = () => {
    setTimeout(() => {
      const inputQtdNode = document.querySelector<HTMLInputElement>(
        'input[name="edt_quantidade"]',
      );
      if (inputQtdNode) {
        inputQtdNode.focus();
      }
    }, 300);
  };

  const handlerPlusQtd = () => {
    if (formRef) {
      const qtd = formRef?.current?.getFieldValue('edt_quantidade');
      const newAddedQtd = parseInt(`${qtd}`, 10) + 1;
      formRef?.current?.setFieldValue('edt_quantidade', newAddedQtd);
      focusInInputQtd();
    }
  };

  const handlerLessQtd = () => {
    if (formRef) {
      const qtd = formRef?.current?.getFieldValue('edt_quantidade');
      let newSubtractedQtd = parseInt(`${qtd}`, 10) - 1;
      if (newSubtractedQtd <= 0) {
        newSubtractedQtd = 1;
      }
      formRef?.current?.setFieldValue('edt_quantidade', newSubtractedQtd);
      focusInInputQtd();
    }
  };

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (visible) {
      if (event.code === 'NumpadAdd') {
        handlerPlusQtd();
        event.preventDefault();
      }
      if (event.code === 'NumpadSubtract') {
        handlerLessQtd();
        event.preventDefault();
      }
    }
  };

  const handlerSubmit = async (data: EditarItemFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        edt_quantidade: Yup.number()
          .required()
          .typeError('Informe o quantidade do produto'),
        edt_preco: Yup.number()
          .required()
          .typeError('Informe o preço do produto'),
      });

      await schema.validate(data, { abortEarly: false });

      const { edt_preco, edt_quantidade } = data;

      if (edt_preco && edt_quantidade) {
        adicionarItem({
          id: item.id,
          produto: item.produto,
          qtd: edt_quantidade,
          preco: edt_preco,
        });
      }

      onRequestClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return null;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
    };
  });

  useEffect(() => {
    focusInInputQtd();
  }, [item.produto]);

  if (!item.produto || !visible) return null;

  return (
    <Modal
      title={`${item.produto.codigo} - ${item.produto.descricao}`}
      visible={visible}
      onRequestClose={onRequestClose}
      width="45rem"
      scrollable
    >
      <Container>
        <Image>
          {item.produto && item.produto.imagem ? (
            <img
              alt="Maior do Brasil"
              src="https://472841-1484254-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2021/03/086019400.jpg"
            />
          ) : (
            <AiFillFileImage size={120} color="#fff" />
          )}
        </Image>

        <Form
          ref={formRef}
          onSubmit={handlerSubmit}
          initialData={{
            edt_quantidade: item.qtd,
            edt_preco: item.preco,
          }}
        >
          <InputContainer>
            <span>Quantidade</span>
            <div>
              <Helper>
                <Button
                  onClick={handlerLessQtd}
                  type="button"
                  background="#ef4d4d"
                >
                  <BsFillDashCircleFill size={32} color="#fff" />
                </Button>
              </Helper>
              <Input
                name="edt_quantidade"
                type="number"
                selectAllInFocus
                textAlign="center"
              />
              <Helper>
                <Button
                  onClick={handlerPlusQtd}
                  type="button"
                  background="#eb714a"
                >
                  <BsFillPlusCircleFill size={32} color="#fff" />
                </Button>
              </Helper>
            </div>
          </InputContainer>
          <InputContainer>
            <span>Preço</span>
            <div>
              <InputNumber name="edt_preco" />
            </div>
          </InputContainer>
          <Footer>
            <Button
              onClick={onRequestClose}
              type="button"
              background={colors.grey}
            >
              CANCELAR
            </Button>

            <Button type="submit" background={colors.green}>
              SALVAR
            </Button>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default EditarItem;
