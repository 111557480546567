import React, { useEffect, useRef, useState, useCallback } from 'react';

import ReactInputMask from 'react-input-mask';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';
import { InputProps } from './interface';

const Input: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  onBlur,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const valueRef = useRef(defaultValue || '');

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!valueRef.current);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
      if (e) {
        if (onChange) {
          onChange(e);
        }

        valueRef.current = (e.target as HTMLInputElement).value;
      }
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: valueRef.current,
      getValue() {
        return valueRef.current;
      },
      clearValue: () => {
        if (valueRef.current) {
          valueRef.current = '';
        }
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    valueRef.current = defaultValue;
  }, [defaultValue]);

  return (
    <Container
      style={containerStyle}
      $iserrored={!!error}
      $isfilled={isFilled}
      $isfocused={isFocused}
    >
      {Icon && <Icon size={18} />}
      <ReactInputMask
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        value={defaultValue}
        name={name}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
