import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { MdAttachMoney } from 'react-icons/md';
import { useToast } from '~/hooks/toast';

import Loader from '~/components/Loader';

import {
  FormContainer,
  FormContent,
  FormBlock,
  FormFooter,
  InputsContainer,
  InputContainer,
  Button,
  ErrorMessage,
} from '~/components';

import {
  Content,
  InputCurrency,
  InputPercent,
  IFCostShow,
  IFCostMedioShow,
  IFCostsContainer,
} from './styles';

import { ParamsProps } from './interface';

import api from '~/services/api';

import { handlerToNumber, handlerNumberToString } from '~/utils/money';
import { handlerErrors } from '~/utils/error';

const IFC: React.FC<ParamsProps> = ({ onSave, id_empresa, id_produto }) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(false);
  const [formError, setFormError] = useState('');

  const [id_custo, set_id_custo] = useState('0');
  const [custo_unitario, set_custo_unitario] = useState('');
  const [ipi_porcentagem, set_ipi_porcentagem] = useState('');
  const [ipi_valor, set_ipi_valor] = useState('');
  const [st_porcentagem, set_st_porcentagem] = useState('');
  const [st_valor, set_st_valor] = useState('');
  const [frete_porcentagem, set_frete_porcentagem] = useState('');
  const [frete_valor, set_frete_valor] = useState('');
  const [seguro_porcentagem, set_seguro_porcentagem] = useState('');
  const [seguro_valor, set_seguro_valor] = useState('');
  const [outros_porcentagem, set_outros_porcentagem] = useState('');
  const [outros_valor, set_outros_valor] = useState('');
  const [despesas_porcentagem, set_despesas_porcentagem] = useState('');
  const [despesas_valor, set_despesas_valor] = useState('');
  const [custo_final, set_custo_final] = useState('');
  const [custo_medio, set_custo_medio] = useState('');

  const handlerReflectValues = (name: string, value: string): void => {
    const currentValue = handlerToNumber(value);
    const currentCustoUnitario = handlerToNumber(custo_unitario);

    switch (name) {
      case 'custo_unitario':
        set_custo_unitario(value);
        break;

      case 'ipi_porcentagem':
        set_ipi_porcentagem(value);
        set_ipi_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'ipi_valor':
        set_ipi_valor(value);
        set_ipi_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      case 'st_porcentagem':
        set_st_porcentagem(value);
        set_st_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'st_valor':
        set_st_valor(value);
        set_st_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      case 'frete_porcentagem':
        set_frete_porcentagem(value);
        set_frete_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'frete_valor':
        set_frete_valor(value);
        set_frete_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      case 'seguro_porcentagem':
        set_seguro_porcentagem(value);
        set_seguro_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'seguro_valor':
        set_seguro_valor(value);
        set_seguro_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      case 'outros_porcentagem':
        set_outros_porcentagem(value);
        set_outros_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'outros_valor':
        set_outros_valor(value);
        set_outros_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      case 'despesas_porcentagem':
        set_despesas_porcentagem(value);
        set_despesas_valor(
          handlerNumberToString(currentCustoUnitario * (currentValue / 100)),
        );
        break;

      case 'despesas_valor':
        set_despesas_valor(value);
        set_despesas_porcentagem(
          handlerNumberToString((currentValue * 100) / currentCustoUnitario),
        );
        break;

      default:
        break;
    }
  };

  const handleronValueChange = (
    value: string | undefined,
    name: string | undefined,
  ): void => {
    if (name) {
      const currentValue = value || '0';
      handlerReflectValues(name, currentValue);
    }
  };

  const handlerCalcFinalPrice = (): void => {
    let priceFinal = 0;
    priceFinal += handlerToNumber(custo_unitario);
    priceFinal += handlerToNumber(ipi_valor);
    priceFinal += handlerToNumber(st_valor);
    priceFinal += handlerToNumber(frete_valor);
    priceFinal += handlerToNumber(seguro_valor);
    priceFinal += handlerToNumber(outros_valor);
    priceFinal += handlerToNumber(despesas_valor);
    set_custo_final(handlerNumberToString(priceFinal));
  };

  const handlerPropagateChanges = (): void => {
    handlerReflectValues('ipi_porcentagem', `${ipi_porcentagem}`);
    handlerReflectValues('st_porcentagem', `${st_porcentagem}`);
    handlerReflectValues('frete_porcentagem', `${frete_porcentagem}`);
    handlerReflectValues('seguro_porcentagem', `${seguro_porcentagem}`);
    handlerReflectValues('outros_porcentagem', `${outros_porcentagem}`);
    handlerReflectValues('despesas_porcentagem', `${despesas_porcentagem}`);
  };

  const handlerOnSave = async () => {
    try {
      setFormError('');
      setInLoad(true);
      await api[id_custo.length > 1 ? 'put' : 'post'](
        `produtos/custos${id_custo.length > 1 ? `/${id_produto}` : ''}`,
        {
          empresa_id: id_empresa,
          custo_unitario: handlerToNumber(custo_unitario),
          ipi_porcentagem: handlerToNumber(ipi_porcentagem),
          ipi_valor: handlerToNumber(ipi_valor),
          st_porcentagem: handlerToNumber(st_porcentagem),
          st_valor: handlerToNumber(st_valor),
          frete_porcentagem: handlerToNumber(frete_porcentagem),
          frete_valor: handlerToNumber(frete_valor),
          seguro_porcentagem: handlerToNumber(seguro_porcentagem),
          seguro_valor: handlerToNumber(seguro_valor),
          outros_porcentagem: handlerToNumber(outros_porcentagem),
          outros_valor: handlerToNumber(outros_valor),
          despesas_porcentagem: handlerToNumber(despesas_porcentagem),
          despesas_valor: handlerToNumber(despesas_valor),
          custo_final: handlerToNumber(custo_final),
          custo_medio: handlerToNumber(custo_medio),
        },
      );

      addToast({
        type: 'success',
        title: 'Concluido',
        description: 'Custo salvo com sucesso!',
      });

      onSave();

      setInLoad(false);
    } catch (e) {
      const message = handlerErrors(e);
      setInLoad(false);
      setFormError(`${message}`);
    }
  };

  useEffect(() => {
    handlerPropagateChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custo_unitario]);

  useEffect(() => {
    handlerCalcFinalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    custo_unitario,
    ipi_valor,
    st_valor,
    frete_valor,
    seguro_valor,
    outros_valor,
    despesas_valor,
  ]);

  useEffect(() => {
    async function loadCustos() {
      try {
        setInLoad(true);
        const custosFetch = await api.get(
          `/produtos/custos/${id_produto}?empresa_id: ${id_empresa}`,
        );
        const custo = custosFetch.data;
        if (custo) {
          set_id_custo(custo.id);
          set_ipi_porcentagem(`${custo.ipi_porcentagem}`);
          set_st_porcentagem(`${custo.st_porcentagem}`);
          set_frete_porcentagem(`${custo.frete_porcentagem}`);
          set_seguro_porcentagem(`${custo.seguro_porcentagem}`);
          set_outros_porcentagem(`${custo.outros_porcentagem}`);
          set_despesas_porcentagem(`${custo.despesas_porcentagem}`);
          set_custo_medio(`${custo.custo_medio}`);
          setTimeout(() => {
            set_custo_unitario(handlerNumberToString(custo.custo_unitario));
          }, 100);
        } else {
          set_ipi_porcentagem('0');
          set_st_porcentagem('0');
          set_frete_porcentagem('0');
          set_seguro_porcentagem('0');
          set_outros_porcentagem('0');
          set_despesas_porcentagem('0');
          set_custo_medio('0');
          setTimeout(() => {
            set_custo_unitario('0,00 ');
          }, 100);
        }

        setInLoad(false);
        // eslint-disable-next-line no-empty
      } catch (e) {
        setInLoad(false);
        // setFormError(`${error}`);
      }
    }

    loadCustos();
  }, [id_empresa, id_produto]);

  return (
    <Content>
      <FormContainer>
        <Loader visible={inLoad} />
        <form>
          <FormContent>
            <ErrorMessage
              error={formError}
              onDismiss={() => setFormError('')}
            />

            <FormBlock>
              <InputsContainer>
                <InputContainer width={400}>
                  <span>Custo Unitário R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={custo_unitario}
                      name="custo_unitario"
                      placeholder="Custo Unitário"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsContainer>
                <InputContainer>
                  <span>IPI %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={ipi_porcentagem}
                      name="ipi_porcentagem"
                      placeholder="IPI"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>IPI R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={ipi_valor}
                      name="ipi_valor"
                      placeholder="IPI"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>ST %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={st_porcentagem}
                      name="st_porcentagem"
                      placeholder="ST"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>ST R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={st_valor}
                      name="st_valor"
                      placeholder="ST"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsContainer>
                <InputContainer>
                  <span>Frete %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={frete_porcentagem}
                      name="frete_porcentagem"
                      placeholder="Frete"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Frete R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={frete_valor}
                      name="frete_valor"
                      placeholder="Frete"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Seguro %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={seguro_porcentagem}
                      name="seguro_porcentagem"
                      placeholder="Seguro"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Seguro R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={seguro_valor}
                      name="seguro_valor"
                      placeholder="Seguro"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsContainer>
                <InputContainer>
                  <span>Outros %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={outros_porcentagem}
                      name="outros_porcentagem"
                      placeholder="Outros"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Outros R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={outros_valor}
                      name="outros_valor"
                      placeholder="Outros"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Despesas %</span>
                  <div>
                    <InputPercent
                      onValueChange={handleronValueChange}
                      value={despesas_porcentagem}
                      name="despesas_porcentagem"
                      placeholder="Despesas"
                    />
                  </div>
                </InputContainer>
                <InputContainer>
                  <span>Despesas R$</span>
                  <div>
                    <InputCurrency
                      onValueChange={handleronValueChange}
                      value={despesas_valor}
                      name="despesas_valor"
                      placeholder="Despesas"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <IFCostsContainer>
                <IFCostMedioShow>
                  <div>
                    <MdAttachMoney size={32} color="#fff" />
                    <p>
                      <small>Custo médio</small>
                      <strong>{`R$ ${custo_final.replace('.', ',')}`}</strong>
                    </p>
                  </div>
                </IFCostMedioShow>

                <IFCostShow>
                  <div>
                    <MdAttachMoney size={32} color="#fff" />
                    <p>
                      <small>Custo final</small>
                      <strong>{`R$ ${custo_final.replace('.', ',')}`}</strong>
                    </p>
                  </div>
                </IFCostShow>
              </IFCostsContainer>
            </FormBlock>
          </FormContent>

          <FormFooter>
            <Button
              type="button"
              onClick={handlerOnSave}
              background={colors.green}
            >
              Salvar
            </Button>
          </FormFooter>
        </form>
      </FormContainer>
    </Content>
  );
};

export default IFC;
