import { darken } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Tooltip from '~/components/Tooltip';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({
    theme,
    $iserrored,
    $isfocused,
    $isfilled,
    $textalign,
    $disabled,
    $normalcase,
  }) => css`
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    padding: 1rem;
    width: 100%;

    border: 0.2rem solid ${theme.colors.defaultHighlight};
    color: #666360;

    display: flex;
    align-items: center;

    & + div {
      margin-top: 0.8rem;
    }

    ${$iserrored &&
    css`
      border-color: #c53030;
    `}

    ${$isfocused &&
    css`
      color: ${theme.colors.controlFocus};
      border-color: ${theme.colors.primary};
      background: ${theme.colors.controlFocus};
    `}

    ${$isfilled &&
    css`
      color: ${theme.colors.primary};
    `}

    input {
      flex: 1;
      background: transparent;
      border: 0;
      color: ${$disabled
        ? darken(theme.colors.label, 0.5)
        : theme.colors.label};
      font-size: ${theme.fontSizes.large};
      text-align: ${$textalign};
      text-transform: ${$normalcase ? 'none' : 'uppercase'};

      &::placeholder {
        color: ${theme.colors.placeholder};
      }

      ::-webkit-input-placeholder {
        /* WebKit browsers */
        text-transform: none;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        text-transform: none;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        text-transform: none;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        text-transform: none;
      }
      ::placeholder {
        /* Recent browsers */
        text-transform: none;
      }

      &:-webkit-autofill {
        border: none;
        -webkit-box-shadow: transparent !important;
        -webkit-text-fill-color: ${theme.colors.label};
        transition: background-color 5000s ease-in-out 0s;
      }

      &::-ms-reveal {
        background-color: ${theme.colors.white} !important;
        border-radius: 0.3rem;
        padding: 0.1rem;
        margin-top: 0.1rem;
        /* display: none; */
      }

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      -moz-appearance: textfield; /* Firefox */
    }

    svg {
      margin-right: 1.6rem;

      ${$isfocused &&
      css`
        stroke: ${theme.colors.primary};
      `}
    }
  `}
`;

export const Error = styled(Tooltip)`
  height: 1.9rem;
  margin-left: 1.3rem;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.white};

    &::before {
      border-color: ${({ theme }) => theme.colors.error} transparent;
    }
  }
`;
