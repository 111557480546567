import React from 'react';

import LogsItem from './LogsItem';

import { LogsListContainer, LogsListContent } from './styles';

import { LogsListProps } from './interface';

const LogsList: React.FC<LogsListProps> = ({ itens }) => {
  return (
    <LogsListContainer>
      <LogsListContent>
        {itens.map(item => (
          <React.Fragment key={item}>
            <LogsItem item={item} />
          </React.Fragment>
        ))}
      </LogsListContent>
    </LogsListContainer>
  );
};

export default LogsList;
