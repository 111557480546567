import React, { useState } from 'react';

import ListBody from './ListBody';
import ListContainer from './ListContainer';
import ListHeader from './ListHeader';
import ListItem from './ListItem';
import ListItemMenu from './ListItemMenu';
import ListLine from './ListLine';
import ListPagination from './ListPagination';
import ListScroll from './ListScroll';
import ListSearch from './ListSearch';
import ListsContainer from './ListsContainer';

import { ListType } from './interface';
import { Footer, ListArea, ListAreaEmptyInfo } from './styles';

const List: React.FC<ListType> = ({
  header,
  data,
  options,
  onFetch,
  pagination,
  activePage,
  totalPages,
  markdown,
  toggleMarkDown,
  hideFooter,
  inModal,
  emptyLabel = 'Nenhum registro encontrado.',
}) => {
  const [invisible, setInvisible] = useState(false);
  const [filters, setFilters] = useState<Array<any>>([]);
  const [orders, setOrders] = useState<Array<any>>([]);

  const onRequestSearch = (searchField: string, searchQuery: string): void => {
    const newFilters = [
      {
        column: searchField,
        value: searchQuery,
      },
    ];
    setFilters(newFilters);
    if (onFetch) {
      onFetch('1', orders, newFilters);
    }
  };

  const onRequestOrderProps = (
    searchField: string,
    currentOrder: string,
  ): void => {
    let newOrders;
    let newDirection = '';

    if (!currentOrder) {
      newDirection = 'desc';
    }
    newDirection = currentOrder === 'asc' ? 'desc' : 'asc';

    const isColumnExists = !!orders.filter(
      cacheOrder => cacheOrder.column === searchField,
    ).length;

    const isDeleteColumn = !!(
      orders.filter(
        cacheOrder =>
          cacheOrder.column === searchField && cacheOrder.direction === 'desc',
      ).length && currentOrder === 'desc'
    );

    if (isDeleteColumn) {
      newOrders = [
        ...orders.filter(cacheOrder => cacheOrder.column !== searchField),
      ];
    } else {
      newOrders = isColumnExists
        ? [
            ...orders.map(cacheOrder => {
              if (cacheOrder.column === searchField) {
                return {
                  ...cacheOrder,
                  direction: newDirection,
                };
              }
              return cacheOrder;
            }),
          ]
        : [
            ...orders.filter(cacheOrder => cacheOrder.column !== searchField),
            {
              column: searchField,
              direction: newDirection,
            },
          ];
    }

    setOrders(newOrders);
    if (onFetch) {
      onFetch('1', newOrders, filters);
    }
  };

  const onRequestChangePage = (page: string): void => {
    if (onFetch) {
      onFetch(page, orders, filters);
    }
  };

  const handlerOrderPosition = (column: string): string => {
    let output = '';
    if (orders && orders.length) {
      orders.forEach((item, index) => {
        if (item.column === column) {
          output = `${index + 1}`;
        }
      });
    }
    return output;
  };

  const handlerOrderEnabled = (column: string): boolean => {
    return !!(orders && orders.filter(item => item.column === column).length);
  };

  const handlerOrderQuery = (column: string): string => {
    let output = '';
    if (orders && orders.length) {
      orders.forEach(item => {
        if (item.column === column) {
          output = item.direction;
        }
      });
    }
    return output;
  };

  return (
    <ListArea>
      <ListsContainer>
        <ListScroll>
          <ListContainer>
            <ListHeader>
              <ListLine>
                {header.map((item, keyId) =>
                  item.search ? (
                    <React.Fragment key={`${item}${keyId}`}>
                      <ListItem width={item.width}>
                        <ListSearch
                          width={item.width}
                          searchField={item.column}
                          onRequestSearch={onRequestSearch}
                          onRequestOrderProps={onRequestOrderProps}
                          orderEnabled={handlerOrderEnabled(item.column)}
                          orderPosition={handlerOrderPosition(item.column)}
                          orderQuery={handlerOrderQuery(item.column)}
                          label={item.label}
                          isNumeric={item.isNumeric}
                        >
                          {item.label}
                        </ListSearch>
                      </ListItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`${item}${keyId}`}>
                      <ListItem headerType width={item.width}>
                        {item.label}
                      </ListItem>
                    </React.Fragment>
                  ),
                )}
              </ListLine>
            </ListHeader>
            <ListBody>
              {data.map((d_item, keyId) => (
                <ListLine
                  key={`${d_item.id}${keyId}`}
                  id={d_item.id}
                  markdown={markdown}
                  toggleMarkDown={toggleMarkDown}
                >
                  {header.map(h_item => (
                    <React.Fragment key={h_item.label}>
                      <ListItem cor={d_item.list_line_color || null}>
                        {d_item[h_item.column]}
                      </ListItem>
                    </React.Fragment>
                  ))}
                </ListLine>
              ))}
            </ListBody>
          </ListContainer>
        </ListScroll>
        {options && options.length ? (
          <ListScroll width={120} fixed>
            <ListContainer>
              <ListHeader>
                <ListLine>
                  <ListItem>
                    <span style={{ paddingRight: 10 }}>Opções</span>
                  </ListItem>
                </ListLine>
              </ListHeader>
              <ListBody>
                {data.map((d_item, keyId) => (
                  <ListLine key={`${d_item.id}${keyId}`} id={d_item.id}>
                    <ListItem>
                      <main>
                        <ListItemMenu
                          inModal={inModal}
                          title={d_item.list_line_title || ''}
                          setInvisible={() => setInvisible(false)}
                          invisible={invisible}
                        >
                          {options
                            .filter(
                              item =>
                                !d_item.list_line_options ||
                                !item.id ||
                                d_item.list_line_options.includes(item.id),
                            )
                            .map((o_item, keyId) => (
                              <React.Fragment key={`${o_item.id}${keyId}`}>
                                <button
                                  type="button"
                                  onClick={() => {
                                    o_item.onPress(d_item);
                                    setInvisible(true);
                                  }}
                                >
                                  {o_item.label}
                                </button>
                              </React.Fragment>
                            ))}
                        </ListItemMenu>
                      </main>
                    </ListItem>
                  </ListLine>
                ))}
              </ListBody>
            </ListContainer>
          </ListScroll>
        ) : null}
      </ListsContainer>

      {!data.length ? (
        <ListAreaEmptyInfo>{emptyLabel}</ListAreaEmptyInfo>
      ) : null}

      {data.length && pagination && activePage && totalPages ? (
        <ListPagination
          activePage={activePage}
          totalPages={totalPages}
          onRequestChangePage={onRequestChangePage}
        />
      ) : (
        (!hideFooter && <Footer />) || null
      )}
    </ListArea>
  );
};

export default List;
