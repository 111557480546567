import styled from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;

  form {
    > div {
      margin: 0rem 0 2rem 0;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
  width: 100%;
  padding: 2rem 0;
`;
