import styled, { css } from 'styled-components';

import { DragDropProps } from './interface';

export const Container = styled.div`
  float: left;
  width: 20rem;
  height: 20rem;
  padding: 0px;
  margin: 1rem;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0 0 2rem 0;
  }
`;

export const DragDrop = styled.div<DragDropProps>`
  ${({ theme, $active }) => css`
    float: left;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 0.3rem dashed rgba(255, 255, 255, 0.4);
    border-radius: 1rem;
    transition: all 0.5s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  `};
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    justify-content: center;

    div {
      float: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: ${theme.fontSizes.big};
        margin: 1rem 0 0 0;
      }

      p {
        float: left;
        margin: 0;
        padding: 0;
        font-size: ${theme.fontSizes.small};
        text-align: center;
        opacity: 0.6;

        ins {
          cursor: pointer;
        }
      }

      small {
        font-size: 1.2rem;
        opacity: 0.6;
      }
    }
  `};
`;

export const Preview = styled.div`
  ${({ theme }) => css`
    float: left;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 0.3rem solid;
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 1rem;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    div {
      float: left;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 2rem;
      align-items: center;
      overflow: hidden;
      flex: 1;

      img {
        float: left;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0.6rem;
      }
    }

    button {
      background: ${theme.colors.darkRed};
      width: 46px;
      border: 0.3rem solid;
      border-color: rgba(255, 255, 255, 0.3);
      border-radius: 100%;
      height: 46px;
      position: absolute;
      align-self: flex-end;
      margin: 0 1rem 1rem 0;
      transition: all 0.5s ease;

      &:hover {
        transform: rotate(-360deg);
      }
    }
  `};
`;
