import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    float: left;
    border-bottom: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    div {
      button {
        width: 15rem;
      }
    }
  `}
`;

export const Content = styled.div`
  float: left;
  width: 100%;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  float: left;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    border: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
    border-radius: ${theme.radio.default};
    background: ${theme.colors.defaultHighlight};
  `}
`;

export const IC = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 1.2rem;
    border-right: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
  `}
`;
export const InfoContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;

    span {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.grey};
    }

    strong {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      font-weight: bold;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-top: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
    padding-top: 2rem;
    margin-top: 2rem;
    div {
      margin: 0 0.5rem;
      width: 20rem;
    }
  `}
`;
