import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';

import swal from 'sweetalert2';

import { ImRadioChecked } from 'react-icons/im';

import {
  FaCaretSquareDown,
  FaCaretSquareUp,
  FaCheckSquare,
} from 'react-icons/fa';

import { HiDownload } from 'react-icons/hi';

import api from '~/services/api';

import { useAuth } from '~/hooks/auth';

import { handlerErrors } from '~/utils/error';
import { handlerNumberToString } from '~/utils/money';

import { Checkout, Loader } from '~/components';

import {
  Container,
  Content,
  ControlVisibility,
  Options,
  SlctBtn,
  SlctsOptions,
  Submit,
  SubmitOpt,
} from './styles';

import { ContaType, FormaPagamentoType } from '~/types';

import { SelecaodeItensProps } from './interface';

const SelecaodeItens: React.FC<SelecaodeItensProps> = ({
  empresaSelecionada,
  contas,
  contasSelecionadas,
  setContasSelecionadas,
  recarregarPaginaAtual,
}) => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const [contaCorrenteId, setContaCorrenteId] = useState(0);

  const [Visible, setVisibilidade] = useState(true);
  const [selecionadosVisibilidade, setSelecionadosVisibilidade] =
    useState(false);
  const [checkoutVisibilidade, setCheckoutVisibilidade] = useState(false);

  const inverterSelecao = () => {
    const contasIds: Array<string> = [];
    contas.forEach((conta: ContaType) => {
      if (!contasSelecionadas.includes(conta.id)) {
        contasIds.push(conta.id);
      }
    });
    setContasSelecionadas(contasIds);
  };

  const selecionarTudo = () => {
    const contasIds: Array<string> = [];
    contas.forEach((conta: ContaType) => {
      contasIds.push(conta.id);
    });
    setContasSelecionadas(contasIds);
  };

  const selecionarNada = () => {
    setContasSelecionadas([]);
  };

  const calculaValorSelecionadas = () => {
    return contas.reduce((acc, conta: ContaType) => {
      if (contasSelecionadas.includes(conta.id)) {
        // eslint-disable-next-line no-param-reassign
        acc += conta.valor - conta.valor_pago;
      }
      return acc;
    }, 0);
  };

  const exibirSelecionados = () => {
    const totalSelecionados = contasSelecionadas.length;

    if (!totalSelecionados) {
      return 'Nenhuma conta selecionada';
    }

    if (totalSelecionados === 1) {
      return `Uma conta selecionada: R$ ${handlerNumberToString(
        calculaValorSelecionadas(),
      )}`;
    }

    return `${totalSelecionados} contas selecionadas: R$ ${handlerNumberToString(
      calculaValorSelecionadas(),
    )}`;
  };

  const exibirInversaoSelecao = () => {
    const check =
      contasSelecionadas.length && contasSelecionadas.length !== contas.length;

    if (check) {
      return <SlctBtn onClick={inverterSelecao}>Inverter seleção</SlctBtn>;
    }

    return null;
  };

  const inverterVisibilidade = () => {
    setVisibilidade(!Visible);
  };

  const inverterVisibilidadeSelecionados = () => {
    setSelecionadosVisibilidade(!selecionadosVisibilidade);
  };

  const abrirCheckout = () => {
    if (!contasSelecionadas.length) {
      return swal.fire({
        title: `Você não selecionou nenhuma conta. Selecione ao menos uma conta.`,
        icon: 'error',
      });
    }
    setCheckoutVisibilidade(true);
  };

  const fecharCheckout = () => {
    setCheckoutVisibilidade(false);
  };

  const realizarBaixa = async (
    p_valor_sub_total: number,
    p_valor_total: number,
    p_desconto: number,
    p_origem: string,
    p_fpgs: Array<FormaPagamentoType>,
  ) => {
    fecharCheckout();

    swal
      .fire({
        title: `Deseja realmente baixar ${
          contasSelecionadas.length > 1
            ? `as ${contasSelecionadas.length} contas selecionadas`
            : 'a conta selecionada'
        }?`,
        text: `Ao confirmar, a liquidação será processada`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          if (!contaCorrenteId) {
            return swal.fire({
              title: `Você não possui uma conta corrente aberta.`,
              icon: 'error',
            });
          }

          try {
            const liquidacao_data = {
              empresa_id: `${empresaSelecionada}`,
              contas: [...contasSelecionadas.map(cConta => `${cConta}`)],
              pagamento: {
                desconto: 0,
                conta_corrente_id: contaCorrenteId,
                formas: [
                  ...p_fpgs.map(p_fpg => ({
                    condicao_pagamento_id: `${p_fpg.condicao_pagamento.id}`,
                    cheques: null,
                    valor: p_fpg.valor,
                  })),
                ],
              },
            };

            setLoading(true);
            await api.post(`financeiros/baixa-contas`, liquidacao_data);
            setLoading(false);

            swal
              .fire({
                title: `Liquidação processada com sucesso!`,
                icon: 'success',
              })
              .then(() => {
                setContasSelecionadas([]);
                recarregarPaginaAtual();
              });
          } catch (e) {
            setLoading(false);
            const message = handlerErrors(e);
            swal.fire({
              title: `${message || 'Falha na liquidação'}`,
              icon: 'error',
            });
          }
        }
      });
  };

  useEffect(() => {
    const loadContasCorrentes = async () => {
      try {
        const response = await api.get(
          `/financeiros/contas-correntes?empresa_id=${empresaSelecionada}&usuario_id=${user.parceiro_id}&tipo=CAIXA&status=ABERTA`,
        );
        const { data } = response;
        if (data) {
          const { data: results } = data;
          if (results && results.length) {
            setContaCorrenteId(results[0].id);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    if (empresaSelecionada && user.parceiro_id && checkoutVisibilidade) {
      loadContasCorrentes();
    }
  }, [user, empresaSelecionada, setContaCorrenteId, checkoutVisibilidade]);

  if (loading) return <Loader visible />;

  return (
    <Container>
      <Checkout
        visible={checkoutVisibilidade}
        onRequestClose={fecharCheckout}
        onRequestSave={realizarBaixa}
        desconto={0}
        valor_total={calculaValorSelecionadas()}
        origem="BAIXA"
      />

      <ControlVisibility>
        {Visible ? (
          <FaCaretSquareDown
            color={colors.label}
            size={36}
            onClick={inverterVisibilidade}
          />
        ) : (
          <FaCaretSquareUp
            color={colors.label}
            size={36}
            onClick={inverterVisibilidade}
          />
        )}
      </ControlVisibility>

      {Visible ? (
        <Content>
          {contas.length ? (
            <Options>
              <div>
                <button
                  type="button"
                  onClick={inverterVisibilidadeSelecionados}
                >
                  {selecionadosVisibilidade ? (
                    <FaCaretSquareUp size={24} color={colors.label} />
                  ) : (
                    <FaCheckSquare size={24} color={colors.label} />
                  )}
                  <span>
                    {selecionadosVisibilidade
                      ? 'Fechar opções de seleção'
                      : 'Selecionar'}
                  </span>
                </button>
                <span>{exibirSelecionados()}</span>
              </div>
              <div>
                <p>
                  <ImRadioChecked size={22} color="#27AE60" />
                  <span>Em aberto</span>
                </p>
                <p>
                  <ImRadioChecked size={22} color="#F2C94C" />
                  <span>Parcial</span>
                </p>
                <p>
                  <ImRadioChecked size={22} color="#EB5757" />
                  <span>Pago</span>
                </p>
              </div>
            </Options>
          ) : null}
          {selecionadosVisibilidade ? (
            <SlctsOptions>
              <SlctBtn onClick={selecionarTudo}>
                <span>Selecionar tudo</span>
              </SlctBtn>
              <SlctBtn onClick={selecionarNada}>
                <span>Selecionar nada</span>
              </SlctBtn>
              {exibirInversaoSelecao()}
            </SlctsOptions>
          ) : null}

          {contasSelecionadas.length ? (
            <Submit>
              <>
                <SubmitOpt onClick={abrirCheckout}>
                  <span>Liquidar</span>
                  <HiDownload size={24} color={colors.label} />
                </SubmitOpt>
              </>
            </Submit>
          ) : null}
        </Content>
      ) : null}
    </Container>
  );
};

export default SelecaodeItens;
