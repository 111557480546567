import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
`;

export const Table = styled.table`
  ${({ theme }) => css`
    border-spacing: 0rem;
    border-collapse: separate;
    margin: 0rem 0rem 0rem 0;
    float: left;
    width: 100%;

    td {
      border-right: 0.1rem solid ${theme.colors.default};
      border-bottom: 0.1rem solid ${theme.colors.default};

      padding: 1.5rem;
      font-size: ${theme.fontSizes.default};
      text-transform: uppercase;
      color: ${theme.colors.label};
    }

    thead {
      td {
        position: sticky;
        top: 7.8rem;
        font-weight: 700;
        padding: 2rem 1.5rem;
        background: ${theme.colors.background};
        text-align: center;
      }
    }

    tbody {
      tr:nth-child(odd) td {
        background: ${theme.colors.defaultHighlight};
      }
    }

    td.totalizador {
      text-align: right;
    }
  `};
`;

export const Cards = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 0.2rem solid ${theme.colors.default};
    border-radius: 0.6rem;
    margin-bottom: 2rem;
    padding: 1rem;
    flex-wrap: wrap;
    background: ${theme.colors.defaultHighlight};
  `};
`;
export const CardLine = styled.div`
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

export const CardProp = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.orange};
    padding-right: 0.5rem;
    font-size: ${theme.fontSizes.small};
  `};
`;

export const CardVal = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.large};
    font-weight: 700;
  `};
`;
