const validarPermissao = (user: any, permissao: string): boolean => {
  if (user) {
    const { permissoes } = user;
    if (
      permissoes &&
      permissoes.length &&
      Array.isArray(user.permissoes) &&
      !user.permissoes.includes(permissao)
    ) {
      return true;
    }
  }

  return false;
};

export { validarPermissao };
