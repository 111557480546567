import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

import { InputProps } from './interface';

const Input: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  textAlign,
  disabled,
  normalCase,
  selectAllInFocus,
  onKeyDownEnter,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (onKeyDownEnter) {
        onKeyDownEnter();
        event.preventDefault();
      }
    }
  };

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    if (selectAllInFocus && inputRef) {
      inputRef.current?.select();
    }
  }, [selectAllInFocus]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue: () => {
        return normalCase
          ? String(inputRef.current?.value)
          : String(inputRef.current?.value).toUpperCase();
      },
      setValue(ref, value) {
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      },
      clearValue: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    });
  }, [fieldName, normalCase, registerField]);

  return (
    <Container
      style={containerStyle}
      $iserrored={error && error != '' ? true : false}
      $isfilled={isFilled ? true : false}
      $isfocused={isFocused ? true : false}
      $textalign={textAlign}
      $disabled={disabled ? true : false}
      $normalcase={normalCase ? true : false}
    >
      {Icon && <Icon size={18} />}
      <input
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        onKeyDown={handlerKeyDown}
        disabled={disabled}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
