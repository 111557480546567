import React from 'react';

import List from '~/components/List';

import { PartnerAddressesContainer } from './styles';

import { PartnerAddressesProps } from './interface';

const PartnerAddresses: React.FC<PartnerAddressesProps> = ({
  data,
  handlerRemoveAddress,
  handlerEditAddress,
}) => {
  return (
    <PartnerAddressesContainer>
      {data && data.length ? (
        <List
          header={[
            {
              column: 'cep',
              label: 'CEP',
            },
            {
              column: 'logradouro',
              label: 'Logradouro',
            },
            {
              column: 'numero',
              label: 'Número',
            },
            {
              column: 'complemento',
              label: 'Complemento',
            },
            {
              column: 'bairro',
              label: 'Bairro',
            },
            {
              column: 'cidade',
              label: 'Cidade',
            },
            {
              column: 'uf',
              label: 'UF',
            },
          ]}
          data={data}
          options={[
            {
              label: 'Editar',
              onPress: item => {
                handlerEditAddress(item);
              },
            },
            {
              label: 'Remover',
              onPress: item => {
                handlerRemoveAddress(item);
              },
            },
          ]}
        />
      ) : (
        <h1>Nenhum endereço cadastrado!</h1>
      )}
    </PartnerAddressesContainer>
  );
};

export default PartnerAddresses;
