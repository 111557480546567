import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useTheme } from 'styled-components';

import { IoIosTrash } from 'react-icons/io';

import { HeaderSearchInputContainer } from './styles';

import { HeaderSearchInputProps } from './interface';

const HeaderSearchInput: React.FC<HeaderSearchInputProps> = ({
  searchQuery,
  handlerSearch,
}) => {
  const { colors } = useTheme();

  const [searchFocus, setSeachFocus] = useState(false);

  return (
    <HeaderSearchInputContainer $focused={searchFocus}>
      <div>
        <span>Qual ferramenta você procura?</span>
        <p>
          <BiSearch size={22} color={colors.label} />
          <input
            placeholder="Busque a ferramente que você deseja"
            onFocus={() => setSeachFocus(true)}
            onBlur={() => setSeachFocus(false)}
            value={searchQuery}
            onChange={e => handlerSearch(e.target.value)}
          />
          {searchQuery && (
            <IoIosTrash
              onClick={() => handlerSearch('')}
              size={22}
              color={colors.darkRed}
            />
          )}
        </p>
      </div>
    </HeaderSearchInputContainer>
  );
};

export default HeaderSearchInput;
