import React, { useState, useRef, useCallback } from 'react';

import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import {
  Button,
  FormFooter,
  InputsContainer,
  InputContainer,
  InputPercent,
  FindTabelaPreco,
  ErrorMessage,
} from '~/components';

import { ConfiguracaoDoParceiroProps, FormData } from './interface';

import { TabelaPrecoType } from '~/types';
import { handlerToNumber } from '~/utils/money';

const ConfiguracaoDoParceiro: React.FC<ConfiguracaoDoParceiroProps> = ({
  pParceiro,
  pConfiguracaoParceiro,
  onRequestClose,
}) => {
  const { colors } = useTheme();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [tabelaPreco, setTabelaPreco] = useState<TabelaPrecoType | null>(
    pConfiguracaoParceiro !== null
      ? {
          ...pConfiguracaoParceiro.tabela_preco,
          empresa_id: pConfiguracaoParceiro.empresa_id,
        }
      : null,
  );

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const submeterFormulario = useCallback(
    async (data: FormData) => {
      try {
        setFormError('');

        if (!tabelaPreco) {
          return setFormError('Selecione uma tabela de preço');
        }

        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          porcentagem_desconto_maximo: Yup.string().required(
            'Desconto máximo obrigatório',
          ),
          porcentagem_acrescimo_maximo: Yup.string().required(
            'Acréscimo obrigatório',
          ),
          porcentagem_comissao: Yup.string().required('Comissão obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        setFormLoading(false);

        try {
          const dados = {
            porcentagem_desconto_maximo: handlerToNumber(
              data.porcentagem_desconto_maximo,
            ),
            porcentagem_acrescimo_maximo: handlerToNumber(
              data.porcentagem_acrescimo_maximo,
            ),
            porcentagem_comissao: handlerToNumber(data.porcentagem_comissao),
            empresa_id: tabelaPreco.empresa_id,
            tabela_preco_id: tabelaPreco.id,
            parceiro_id: pParceiro.id,
          };

          await api[pConfiguracaoParceiro !== null ? 'put' : 'post'](
            `/parceiros/config${
              pConfiguracaoParceiro !== null
                ? `/${pConfiguracaoParceiro.id}`
                : ''
            }`,
            dados,
          );

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Configuração armazenada com sucesso!',
          });
          onRequestClose();
        } catch (e) {
          setFormLoading(false);
          const error = (e as Error).message;
          setFormError(`${error}`);
        }
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Falha no envio!',
          description: 'Ocorreu um erro ao salvar a configuração',
        });
      }
    },
    [
      addToast,
      pConfiguracaoParceiro,
      pParceiro.id,
      tabelaPreco,
      onRequestClose,
    ],
  );

  let initialData = {};
  if (pConfiguracaoParceiro) {
    initialData = {
      porcentagem_desconto_maximo:
        pConfiguracaoParceiro.porcentagem_desconto_maximo,
      porcentagem_acrescimo_maximo:
        pConfiguracaoParceiro.porcentagem_acrescimo_maximo,
      porcentagem_comissao: pConfiguracaoParceiro.porcentagem_comissao,
    };
  }

  return (
    <Form ref={formRef} initialData={initialData} onSubmit={submeterFormulario}>
      <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

      <InputsContainer>
        <InputContainer>
          <span>Tabela de Preço</span>
          <FindTabelaPreco
            placeholderInput="Informe a tabela de preço"
            placeholderValue="Selecione uma tabela de preço"
            onSelectValue={selecionados => {
              if (selecionados[0]) {
                const cTabelaPreco = selecionados[0];
                setTabelaPreco(cTabelaPreco);
              }
            }}
            defaultValue={{
              selecionados: [],
              descricao: `${
                pConfiguracaoParceiro?.tabela_preco?.descricao || ''
              }`,
            }}
          />
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading} width={250}>
          <span>Desconto Máximo %</span>
          <div>
            <InputPercent
              selectAllInFocus
              name="porcentagem_desconto_maximo"
              placeholder="D. Máximo"
              defaultValue={`${pConfiguracaoParceiro?.porcentagem_desconto_maximo}`}
            />
          </div>
        </InputContainer>

        <InputContainer disabled={formLoading} width={250}>
          <span>Acréscimo Máximo %</span>
          <div>
            <InputPercent
              selectAllInFocus
              name="porcentagem_acrescimo_maximo"
              placeholder="A. Máximo"
              defaultValue={`${pConfiguracaoParceiro?.porcentagem_acrescimo_maximo}`}
            />
          </div>
        </InputContainer>

        <InputContainer disabled={formLoading} width={250}>
          <span>Comissão %</span>
          <div>
            <InputPercent
              selectAllInFocus
              name="porcentagem_comissao"
              placeholder="Comissão"
              defaultValue={`${pConfiguracaoParceiro?.porcentagem_comissao}`}
            />
          </div>
        </InputContainer>
      </InputsContainer>

      <FormFooter>
        <Button
          type="button"
          background={colors.grey}
          onClick={onRequestClose}
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          background={colors.green}
          loading={formLoading}
          style={{ marginTop: 50, marginRight: 30, marginBottom: 20 }}
        >
          Salvar
        </Button>
      </FormFooter>
    </Form>
  );
};

export default ConfiguracaoDoParceiro;
