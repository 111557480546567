import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  padding-right: 15px;
  padding: 0px;

  > button {
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease;
    background: none;
    margin: 0px 0 0 0 !important;

    &:hover {
      opacity: 0.5;
    }

    img {
      width: 32px;
      height: 32px;
      float: left;
      border-radius: 16px;
      border: 2px solid rgba(255, 255, 255, 0.8);
    }

    span {
      float: left;
      width: 32px;
      height: 32px;
      border: 1px solid rgba(255, 255, 255, 0.8);
      margin: 0 0 0 5px;
    }
  }

  > div {
    position: absolute;
    margin: -62px 0px 0 80px;
    transform: translate(0, 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    svg {
      margin-bottom: 10px;
      cursor: pointer;
      align-self: flex-start;
    }
  }
`;
