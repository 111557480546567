import styled, { css, keyframes } from 'styled-components';

import { AreaProps, OptProps } from './interface';

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    /* background: linear-gradient(
      180deg,
      ${theme.colors.contrast},
      ${theme.colors.overlay}
    ); */
  `}
`;

export const Head = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    background: ${theme.colors.houver};
    border-bottom: 0.1rem solid;
    box-shadow: inset 0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
    background: ${theme.colors.contrast};
    border-color: ${theme.colors.overlay};
    justify-content: space-between;
    padding: 12rem 5rem 6rem 5rem;
    align-items: center;
    background: linear-gradient(
      180deg,
      ${theme.colors.background},
      ${theme.colors.primaryDark},
      ${theme.colors.primaryDark}
    );
    box-shadow: inset 1rem 0 1rem -0.8rem rgba(115, 115, 115, 0.55);

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `}
`;

const welcomeAnimation = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const welcomeBlinkCaretAnimation = keyframes`
  from, to { border-color: transparent }
  50% { border-color: orange; }
`;

export const Welcome = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3.8rem;
      color: ${theme.colors.white};

      overflow: hidden;
      border-right: 0.15em solid orange;
      white-space: nowrap;
      margin: 0 auto;
      letter-spacing: 0.1em;
      animation:
        ${welcomeAnimation} 3.5s steps(40, end),
        ${welcomeBlinkCaretAnimation} 0.75s step-end infinite;

      strong {
        color: ${theme.colors.orange};
      }
    }

    h2 {
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.small};
    }

    @media (max-width: 1024px) {
      h1 {
        font-size: 2rem;
      }
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -4rem;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  caret-color: transparent;

  img {
    align-self: center;
    width: 50%;
    opacity: 0.8;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 4rem;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const Blocks = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;

const AreaAnimation = keyframes`
	0% {
    opacity: 0.6;
    transform: scale(.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.01);
  }
  70% { transform: scale(.98); }
  100% { transform: scale(1); }
`;

export const Area = styled.div<AreaProps>`
  ${({ theme, $delay }) => css`
    display: flex;
    flex-direction: column;
    border: 0.1rem solid;
    box-shadow: inset 0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
    background: ${theme.colors.controlFocus};
    border-color: ${theme.colors.overlay};
    flex: 1;
    margin: 0 1rem;
    overflow: hidden;
    border-radius: 0.8rem;
    animation: ${AreaAnimation} ${$delay || '0.4s'} linear;

    @media (max-width: 1024px) {
      margin: 0 0 2rem 0;
    }
  `}
`;

const TitleIcAnimation = keyframes`
  from { opacity: 0.6; transform: rotate(-300deg);  }
  to {opacity: 1; transform: rotate(0deg); }
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    height: 6rem;
    color: ${theme.colors.label};
    line-height: 6rem;
    padding: 0 1.5rem;
    width: 100%;
    float: left;
    font-size: ${theme.fontSizes.big};
    align-items: center;
    justify-content: space-between;

    span {
      border-radius: 100%;
      background: linear-gradient(
        180deg,
        ${theme.colors.primary},
        ${theme.colors.primaryDark}
      );
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 4rem;
      height: 4rem;
      border-color: rgba(0, 0, 0, 0.05);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

      animation: ${TitleIcAnimation} 3s linear;
    }

    div {
      display: flex;
      flex-direction: row;
    }

    button {
      background: none;
      margin-left: 1.5rem;
    }
  `}
`;

export const Information = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 28rem;
    padding: 0 2rem;
    margin: -1rem 0 2rem 0;

    h1 {
      font-size: 3.4rem;
    }

    h2 {
      font-size: 1.2rem;
      margin-top: 1rem;

      strong {
        color: ${theme.colors.green};
        font-weight: 700;
      }
    }
  `}
`;

export const Opts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  float: left;
  margin-top: 0px;
  padding: 0 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const OptAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-10rem);
  }
  80% {
    opacity: 1;
    transform: translateY(1.5rem);
  }
  90% {
    opacity: 1;
    transform: translateY(-1.2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const OptIcAnimation = keyframes`
  from { opacity: 0.6; transform: rotate(-220deg);  }
  to {opacity: 1; transform: rotate(0deg); }
`;

export const Opt = styled.a<OptProps>`
  ${({ $delay }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    float: left;
    width: 100%;
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 1rem;
    overflow: hidden;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.08) 0%,
      rgba(0, 0, 0, 0.02) 100%
    );
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    margin: 0rem 2rem 1rem 0;
    width: 14rem;
    height: 14rem;
    border-radius: 0.8rem;
    transition: all 0.2s ease;
    overflow: hidden;

    animation: ${OptAnimation} ${$delay || '0.8s'} linear;

    &:last-child {
      margin-right: 0rem;
    }

    p {
      margin: 0px;
      padding: 0px;
      float: left;
      opacity: 0;
      visibility: hidden;
      svg {
        width: 13.8rem;
        height: 13.8rem;
        left: 0;
        position: absolute;
        top: 0;
      }
      rect {
        fill: none;
        stroke: ${({ theme }) => theme.colors.white};
        stroke-width: 4;
        stroke-dasharray: 422, 0;
        transition: all 0.35s linear;
      }
    }
    &:after {
      background: rgba(255, 255, 255, 0.2);
      content: '';
      height: 36rem;
      left: -10rem;
      position: absolute;
      top: 0px;
      transform: rotate(35deg);
      transition: all 0.8ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 36rem;
      z-index: -10;
      visibility: hidden;
    }
    &:hover {
      transform: translateX(-0px);

      &:after {
        visibility: visible;
        left: 130%;
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      }

      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

      p {
        visibility: visible;
        opacity: 1;
        rect {
          stroke-width: 10;
          stroke-dasharray: 15, 310;
          stroke-dashoffset: 48;
          transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }

    i {
      border-radius: 100%;
      background: #ccc;
      background: ${({ theme }) =>
        `linear-gradient(0deg, ${theme.colors.primaryDark},${theme.colors.primary})`};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border: 0.5rem solid;
      width: 8rem;
      height: 8rem;
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);

      animation: ${OptIcAnimation} 1.2s linear;
    }

    span {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.4rem;
      margin: 0;
      margin-top: 1.4rem;
      text-align: center;
      float: left;
    }
  `}
`;

export const Grid = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border: 0.1rem solid ${theme.colors.contrast};
    border-bottom: none;
    border-collapse: collapse;

    thead td {
      border-bottom: 1px solid ${theme.colors.overlay};
      font-size: 1.6rem;
    }

    td {
      border-right: 0.1rem solid ${theme.colors.contrast};
      border-bottom: 0.1rem solid ${theme.colors.contrast};
      padding: 1.5rem;
      color: ${theme.colors.label};
      font-size: 1.4rem;
    }

    tbody tr:nth-child(odd) {
      background: ${theme.colors.defaultHighlight};
    }

    td:last-child {
      border-right: none;
    }
  `}
`;
