import dark from './dark';

const light: typeof dark = {
  ...dark,
  colors: {
    ...dark.colors,
    default: '#d4d4d8',
    defaultHighlight: '#e4e4e7',
    contrast: '#a1a1aa',
    background: '#f4f4f5',
    label: '#27272a',
    secondary: '#fafafa',
    controlFocus: '#eaeaed',
    controlNotFocus: '#e4e4e7',
    overlay: 'rgba(255, 255, 255, 0.6)',
    houver: '#e3e3e3',
    footer: '#f4f4f5',
  },
  shadows: {
    ...dark.shadows,
    highlight: 'inset 0 -1px 12px -8px rgba(0, 0, 0, 0.3)',
    default: '0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1)',
    left: 'inset 0.7rem 0rem 2rem  rgba(0, 0, 0, 0.2)',
  },
};

export default light;
