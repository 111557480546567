import React, { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { Container, Footer, InputContainer, InputsContainer } from './styles';

import { FiltragemFormData, FiltragemProps } from './interface';

import { Button, DatePicker, Modal } from '~/components';
import { format } from 'date-fns';

const Filtragem: React.FC<FiltragemProps> = ({
  visible,
  onRequestClose,
  onRequestSave,
  haveRegisters,
}) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data: FiltragemFormData) => {
      const { data_inicial, data_final } = data;
      onRequestSave(data_inicial, data_final);
      onRequestClose();
    },
    [onRequestClose, onRequestSave],
  );

  const onSubmitForm = () => {
    formRef.current?.submitForm();
  };

  return (
    <Modal
      title="Filtros de Log's de estoque"
      width="100rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputsContainer>
            <InputContainer>
              <span>Data inicial</span>
              <div>
                <DatePicker
                  name="data_inicial"
                  mask="99/99/9999"
                  placeholder="Informe a Data Inicial"
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                />
              </div>
            </InputContainer>

            <InputContainer>
              <span>Data final</span>
              <div>
                <DatePicker
                  name="data_final"
                  mask="99/99/9999"
                  placeholder="Informe a Data Final"
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                />
              </div>
            </InputContainer>
          </InputsContainer>

          <Footer>
            {haveRegisters && (
              <div>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
              </div>
            )}
            <div>
              <Button
                type="button"
                onClick={onSubmitForm}
                background={colors.green}
              >
                Consultar
              </Button>
            </div>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default Filtragem;
