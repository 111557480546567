import React, { useRef, useEffect } from 'react';

import { AnchorRefContainer } from './styles';

import useAnchors from '~/hooks/anchor';

import { AnchorRefProps } from './interface';

const AnchorRef: React.FC<AnchorRefProps> = ({ anchor, children }) => {
  const { activeAnchor } = useAnchors();

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activeAnchor === anchor && ref && ref.current) {
      const spaceHeader = 95 + 30;
      window.scrollTo({
        behavior: 'smooth',
        top: ref.current.offsetTop - spaceHeader,
      });
    }
  }, [activeAnchor, ref, anchor]);

  return <AnchorRefContainer ref={ref}>{children}</AnchorRefContainer>;
};

export default AnchorRef;
