import React from 'react';
import { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import Card from '../Card';

import { Container } from './styles';

import { CARDS_ANIMATION } from '../animations';

import { DEFAULT_TRANSITION } from '~/constants';

import { Graph1Props } from './interface';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Preloader = () => {
  return (
    <ContentLoader viewBox="0 0 800 200" height={200} width="100%">
      <rect x="100" y="5" rx="0" ry="0" width="200" height="15" />
      <circle cx="140" cy="110" r="70" />
      <rect x="230" y="50" rx="0" ry="0" width="7" height="7" />
      <rect x="250" y="50" rx="0" ry="0" width="30" height="7" />
      <rect x="230" y="64" rx="0" ry="0" width="7" height="7" />
      <rect x="250" y="64" rx="0" ry="0" width="30" height="7" />
      <rect x="230" y="78" rx="0" ry="0" width="7" height="7" />
      <rect x="250" y="78" rx="0" ry="0" width="30" height="7" />
      <rect x="230" y="92" rx="0" ry="0" width="7" height="7" />
      <rect x="250" y="92" rx="0" ry="0" width="30" height="7" />
    </ContentLoader>
  );
};

const Graph1: React.FC<Graph1Props> = ({ title, loading, data }) => {
  const { colors } = useTheme();

  return (
    <Card
      title={title}
      key="graph-1"
      variant={CARDS_ANIMATION}
      transition={DEFAULT_TRANSITION}
    >
      <Container>
        <ResponsiveContainer>
          {loading ? (
            <Preloader />
          ) : (
            <LineChart
              data={data}
              margin={{
                top: 12,
                right: 40,
                left: -5,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="data"
                stroke={colors.label}
                angle={-40}
                textAnchor="end"
              />
              <YAxis stroke={colors.grey} />
              <Tooltip
                contentStyle={{
                  borderRadius: 5,
                  borderColor: colors.defaultHighlight,
                }}
                labelStyle={{ color: '#3385ff' }}
                formatter={(value: number) =>
                  `${handlerNumberToString(handlerToNumber(`${value}`))}`
                }
              />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#ffa366"
                activeDot={{ r: 10 }}
              />
            </LineChart>
          )}
        </ResponsiveContainer>
      </Container>
    </Card>
  );
};

export default Graph1;
