import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  height: 14rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.contrast};
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    flex: 1;
    padding: 0rem 1.5rem;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
    }
  `}
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;

    button {
      margin-left: 1.5rem;
      transition: all 0.5s ease;
      background: none;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    span {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
    }

    h1 {
      font-size: ${theme.colors.label};
      font-size: ${theme.fontSizes.big};
    }
  `}
`;

export const Total = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    span {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
    }

    h1 {
      font-size: ${theme.colors.label};
      font-size: ${theme.fontSizes.big};
    }
  `}
`;

export const Right = styled.div`
  @media (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

export const CheckoutBtn = styled.div`
  background: #00b432;
  height: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 0.5rem;

  &:hover {
    background: ${darken(0.1, '#00b432')};
  }

  span {
    margin-top: 1rem;
    font-size: 2.6rem;
  }

  small {
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    margin: 0;
  }
`;
