import React, { useState, useCallback } from 'react';

import { FiPlus } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import List from '~/components/List';
import { Modal, Button } from '~/components';

import Loader from '~/components/Loader';

import InformacaoComercial from './InformacaoComercial';

import { IFCContainer, IFCContainerHeader } from './styles';

import { InformacaoComercialType } from '~/types';
import { IFCsProps } from './interface';

import { handlerNumberToString } from '~/utils/money';

const IFC: React.FC<IFCsProps> = ({
  setChangedInformacoesComerciais,
  produto_id,
  produto_nome,
  produto_grade,
  data,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(false);

  const [ifcModalVisible, setIfcModalVisible] = useState(false);
  const [currentIFC, setCurrentIFC] = useState('0');
  // const [ifcs, setIfcs] = useState<Array<InformacaoComercialType>>([]);

  const fetchIfcs = useCallback(async () => {
    try {
      setInLoad(true);
      const url = `/produtos/${produto_id}/informacoes`;
      const response = await api.get(url);
      setChangedInformacoesComerciais(response.data);
      setInLoad(false);
    } catch (err) {
      setInLoad(false);
    }
  }, [produto_id, setChangedInformacoesComerciais]);

  const handlerCloseIFC = () => {
    setIfcModalVisible(false);
  };

  const handlerCreateIFC = (): void => {
    setCurrentIFC('0');
    setIfcModalVisible(true);
  };

  const handlerEditIFC = (item: InformacaoComercialType): void => {
    setCurrentIFC(item.id);
    setIfcModalVisible(true);
  };

  const handlerOnSaveIFC = (): void => {
    fetchIfcs();
    handlerCloseIFC();
  };

  const makeHeaderTable = (eGrade: boolean) => {
    let headerConfig = [
      {
        column: 'empresa_nome',
        label: 'Empresa',
      },
      {
        column: 'preco_produto_descricao',
        label: 'Tabela de Preço',
      },
      {
        column: 'preco',
        label: 'Preço',
      },
      {
        column: 'codigo_barra',
        label: 'Código de Barras',
      },
      {
        column: 'embalagem',
        label: 'Embalagem',
      },
    ];

    if (eGrade) {
      headerConfig = [
        ...headerConfig,
        {
          column: 'variacao',
          label: 'Variação',
        },
      ];
    }

    return headerConfig;
  };

  const makeBodyTable = (
    currentIfcs: Array<InformacaoComercialType>,
    eGrade: boolean,
  ) => {
    if (eGrade) {
      return [
        ...currentIfcs.map(item => ({
          ...item,
          preco_produto_descricao: item.tabela_preco.descricao,
          empresa_nome: item.empresa.fantasia,
          preco: `R$ ${handlerNumberToString(item.preco)}`,
          variacao: `${
            item.variacao_produto_id
              ? `${item.variacao_produto.tamanho.descricao} - ${item.variacao_produto.cor.descricao}`
              : 'NÃO INFORMADO'
          }`,
        })),
      ];
    }
    return [
      ...currentIfcs.map(item => ({
        ...item,
        preco_produto_descricao: item.tabela_preco.descricao,
        empresa_nome: item.empresa.fantasia,
        preco: `R$ ${handlerNumberToString(item.preco)} `,
      })),
    ];
  };

  const handlerRemoveIFC = (item: InformacaoComercialType): void => {
    swal
      .fire({
        title: `Deseja realmente remover a informação comercial? `,
        text: `Ao confirmar, a informação será removida!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.delete(`produtos/informacoes/${item.id}`);
            await fetchIfcs();
            addToast({
              type: 'success',
              title: 'Concluido',
              description: `Informação Comercial deletada com sucesso!`,
            });
          } catch (err) {
            addToast({
              type: 'error',
              title: 'Falha ao remover',
              description: `Informação Comercial não foi removida!`,
            });
          }
        }
      });
  };

  return (
    <IFCContainer>
      <Loader visible={inLoad} />
      <IFCContainerHeader>
        <div>
          <Button
            onClick={handlerCreateIFC}
            type="button"
            background={colors.darkGreen}
          >
            <FiPlus size={24} />
          </Button>
        </div>
      </IFCContainerHeader>

      {ifcModalVisible && (
        <Modal
          title={`${produto_nome} `}
          visible={ifcModalVisible}
          onRequestClose={handlerCloseIFC}
          width="100rem"
          scrollable
        >
          <InformacaoComercial
            id={currentIFC}
            id_produto={produto_id}
            produto_grade={produto_grade}
            onRequestClose={handlerCloseIFC}
            onSave={handlerOnSaveIFC}
          />
        </Modal>
      )}

      {data.length ? (
        <List
          header={makeHeaderTable(produto_grade)}
          data={makeBodyTable(data, produto_grade)}
          options={[
            {
              label: 'Editar',
              onPress: item => {
                handlerEditIFC(item);
              },
            },
            {
              label: 'Remover',
              onPress: item => {
                handlerRemoveIFC(item);
              },
            },
          ]}
        />
      ) : (
        <h1>Nenhuma informação comercial cadastrada!</h1>
      )}
    </IFCContainer>
  );
};

export default IFC;
