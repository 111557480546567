import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im';

import { Container, Content, Info, Left } from './styles';

import { ContaProps } from './interface';

import { formatTheDateYall } from '~/utils/formatDate';
import { handlerNumberToString } from '~/utils/money';

const Conta: React.FC<ContaProps> = ({
  conta,
  eImpar,
  selecionada,
  selecionarConta,
}) => {
  const [, setMenuVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const definirStatusCor = (situacao: string, valor_pago: number) => {
    if (situacao === 'ABERTA' && valor_pago === 0) {
      return '#27AE60';
    }
    if (situacao === 'ABERTA' && valor_pago > 0) {
      return '#F2C94C';
    }
    if (situacao === 'PAGA') {
      return '#EB5757';
    }
    return '#6e6e6e';
  };

  const handlerHideContent = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeMenu();
    }
  }, []);

  const handleClickOutside = useCallback((event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  function totalCalculado(contaItem: any) {
    let total = 0;
    contaItem.transacoes_conta_financeira.forEach((contaTransacao: any) => {
      total += contaTransacao.valor_pago;
    });
    return total;
  }

  return (
    <Container $selecionada={selecionada} $eimpar={eImpar}>
      <Left onClick={() => selecionarConta(conta.id)}>
        {selecionada ? (
          <ImRadioChecked
            size={24}
            color={definirStatusCor(conta.situacao, conta.valor_pago)}
          />
        ) : (
          <ImRadioUnchecked
            size={24}
            color={definirStatusCor(conta.situacao, conta.valor_pago)}
          />
        )}
      </Left>
      <Content>
        <div>
          <Info>
            <small>Empresa:</small>
            {conta.empresa.fantasia}
          </Info>
          <Info>
            <small>Origem:</small>
            {conta.operacao}
          </Info>
          <Info $cor="#ff4d4d">
            <small>Lançamento:</small>
            {formatTheDateYall(conta.created_at)}
          </Info>
          <Info $cor="#00AAE0">{conta.descricao}</Info>
        </div>
        <div>
          <Info $cor="#ff4d4d">
            <small>Vencimento:</small>
            {formatTheDateYall(conta.vencimento)}
          </Info>
          <Info>
            <small>Parcela:</small>
            {conta.parcela}
          </Info>
          <Info>
            <small>Parceiro:</small>
            {conta.parceiro.nome_fantasia}
          </Info>
        </div>
        <div>
          <Info>
            <small>Valor:</small>
            {`R$ ${handlerNumberToString(conta.valor)}`}
          </Info>
          <Info>
            <small>Pago calculado:</small>
            {`R$ ${handlerNumberToString(totalCalculado(conta))}`}
          </Info>
          <Info>
            <small>Em aberto:</small>
            {`R$ ${handlerNumberToString(conta.valor - conta.valor_pago)}`}
          </Info>
          <Info>
            <small>Juros:</small>
            R$ 0,00
          </Info>
          <Info $cor="#2fc43c">
            <small>Total a baixar:</small>
            {`R$ ${handlerNumberToString(conta.valor - conta.valor_pago)}`}
          </Info>
        </div>
      </Content>
      {/* <Right>
        {menuVisible ? (
          <CgClose size={24} color={colors.label} onClick={closeMenu} />
        ) : (
          <FiMenu size={24} color={colors.label} onClick={openMenu} />
        )}
        <Menu ref={ref} visible={menuVisible}>
          <button type="button">OPÇÃO 1</button>
          <button type="button">OPÇÃO 2</button>
          <button type="button">OPÇÃO 3</button>
        </Menu>
      </Right> */}
    </Container>
  );
};

export default Conta;
