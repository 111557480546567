import React from 'react';
import { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

// import {
//   XAxis,
//   CartesianGrid,
//   Tooltip,
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   YAxis,
// } from 'recharts';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import Card from '../Card';

import { Container } from './styles';

import { CARDS_ANIMATION } from '../animations';

import { DEFAULT_TRANSITION } from '~/constants';

import { Graph2Props } from './interface';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Preloader = () => {
  return (
    <ContentLoader
      height={200}
      width="100%"
      viewBox="0 0 400 200"
      backgroundColor="#f5f5f5"
      foregroundColor="#7e7e7e"
    >
      <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
      <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
      <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
      <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
      <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
      <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
    </ContentLoader>
  );
};

const Graph1: React.FC<Graph2Props> = ({ title, loading, data }) => {
  const { colors } = useTheme();

  return (
    <Card
      title={title}
      key="graph-2"
      variant={CARDS_ANIMATION}
      transition={DEFAULT_TRANSITION}
    >
      <Container>
        <ResponsiveContainer>
          {loading ? (
            <Preloader />
          ) : (
            // <AreaChart
            //   height={400}
            //   data={data}
            //   margin={{
            //     top: 0,
            //     right: 10,
            //     left: -15,
            //     bottom: 15,
            //   }}
            // >
            //   <CartesianGrid strokeDasharray="3 3" />
            //   <XAxis
            //     dataKey="data"
            //     stroke={colors.label}
            //     angle={-40}
            //     textAnchor="end"
            //   />
            //   <YAxis stroke={colors.grey} />
            //   <Tooltip
            //     contentStyle={{
            //       backgroundColor: colors.contrast,
            //       borderRadius: 5,
            //       borderColor: colors.defaultHighlight,
            //     }}
            //     formatter={(value: number) =>
            //       `${handlerNumberToString(handlerToNumber(`${value}`))}`
            //     }
            //   />
            //   <Area
            //     type="monotone"
            //     dataKey="total"
            //     stroke="#dfd54a"
            //     fill="#dfd54a"
            //   />
            // </AreaChart>
            <BarChart
              // width={500}
              height={500}
              data={data}
              margin={{
                top: 12,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey="name" /> */}
              <XAxis
                dataKey="data"
                stroke={colors.label}
                angle={-40}
                textAnchor="end"
              />
              <YAxis stroke={colors.grey} />
              <Tooltip
                contentStyle={{
                  backgroundColor: colors.contrast,
                  borderRadius: 5,
                  borderColor: colors.defaultHighlight,
                }}
                formatter={(value: number) =>
                  `${handlerNumberToString(handlerToNumber(`${value}`))}`
                }
              />
              {/* <Legend /> */}
              <Bar dataKey="en" fill="#19a347" />
              <Bar dataKey="sa" fill="#ba3838" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </Container>
    </Card>
  );
};

export default Graph1;
