import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Container } from './styles';

const MainScroll: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <Container>{children}</Container>;
};

export default MainScroll;
