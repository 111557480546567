import React, { useState, useCallback, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';

import { BsFillPuzzleFill } from 'react-icons/bs';

import qs from 'qs';

import swal from 'sweetalert2';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import { handlerToNumber } from '~/utils/money';

import Header from '~/components/Header';
import List from '~/components/List';

import {
  Loader,
  BarraLateral,
  Plate,
  Button,
  NoPermission,
} from '~/components';

import Filtros from './Filtros';

import { Container, Content, Area, Left, Right } from './styles';

import { RelatorioType, FiltroType, ProdutoType } from '~/types';

import configuracaoInicial from '~/schemas/relatorio-produtos.json';
import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatCustomBR } from '~/utils/formatDate';

import { exportarPDF } from './engine';
import { validarPermissao } from '~/utils/permissions';

const Contas: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();
  const { user } = useAuth();

  const [configuracao, setConfiguracao] =
    useState<RelatorioType>(configuracaoInicial);

  const [filtros, setFiltros] = useState<FiltroType[]>([]);

  const [produtos, setProdutos] = useState<ProdutoType[]>([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [loading, setLoading] = useState(false);

  const voltarAoTopo = () => {
    window.scrollTo(0, 0);
  };

  const sintetizarParametros = (pFiltros: FiltroType[]): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parametros: any = {};

    pFiltros.forEach((cFiltro: FiltroType) => {
      const { valor, nome } = cFiltro;
      const cValor = valor;
      if (valor) {
        parametros[nome] = cValor;
      }

      if (nome === 'ativo') {
        parametros[nome] = cValor === '1';
      }
    });

    const qsParametros = qs.stringify(parametros);

    return qsParametros;
  };

  const carregarProdutos = useCallback(async (pFiltros: FiltroType[]) => {
    try {
      setLoading(true);

      const response = await api.get(
        `produtos?${sintetizarParametros([
          ...pFiltros,
          { nome: 'perPage', valor: '50' },
        ])}`,
      );

      const { data } = response;

      if (data && data.status !== 'error') {
        const { data: cProdutos, lastPage } = data;

        setProdutos(cProdutos);

        if (lastPage) {
          setTotalPaginas(handlerToNumber(`${lastPage}`));
        }
      }

      setLoading(false);
      voltarAoTopo();
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const reCarregarProdutos = useCallback(
    async (
      page?: string,
      order?: Array<{ direction: string; column: string }>,
      filter?: Array<{ value: string; column: string }>,
    ) => {
      try {
        setLoading(true);

        const cFiltros: FiltroType[] = [...filtros];

        if (page) {
          setPaginaAtual(handlerToNumber(page));
          cFiltros.push({
            nome: 'page',
            valor: page ? `${page}` : paginaAtual,
          });
        }

        if (order && order.length) {
          const outputOrder: Array<string> = [];
          order.forEach(orderCurrent => {
            outputOrder.push(
              `${orderCurrent.direction === 'asc' ? '+' : '-'}${
                orderCurrent.column
              }`,
            );
          });
          cFiltros.push({
            nome: 'order_by',
            valor: `${outputOrder.join(',')}`,
          });
        }

        if (filter && filter.length) {
          filter.forEach(filterCurrent => {
            cFiltros.push({
              nome: filterCurrent.column,
              valor: `${filterCurrent.value}`,
            });
          });
        }

        carregarProdutos(cFiltros);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [carregarProdutos, filtros, paginaAtual],
  );

  const aoAplicarFiltros = async (pFiltros: FiltroType[]) => {
    setFiltros(pFiltros);
    setPaginaAtual(1);
    carregarProdutos([...pFiltros]);
  };

  const aoAplicarConfiguracao = (
    pConfiguracao: RelatorioType,
    preloader: boolean,
  ) => {
    const cFiltros: FiltroType[] = [];

    // ORDENAÇÃO
    if (pConfiguracao && pConfiguracao.ordenar.length) {
      cFiltros.push({
        nome: 'order_by',
        valor: pConfiguracao.ordenar
          .reduce((acc: string[], item) => {
            if (item.enable) {
              acc.push(`${item.order === 'ASC' ? '' : '-'}${item.field}`);
            }
            return acc;
          }, [])
          .join(','),
      });
    }

    if (preloader) {
      setLoading(true);
      setConfiguracao(pConfiguracao);
      setTimeout(() => {
        setLoading(false);
        aoAplicarFiltros(cFiltros);
      }, 1000);
    } else {
      setConfiguracao(pConfiguracao);
      aoAplicarFiltros(cFiltros);
    }
  };

  const aoExportarRelatorio = async (pConfiguracao: RelatorioType) => {
    try {
      const cabecalho: RelatorioCabecalhoType = {
        title: user.workspace.nome,
        subtitle1: `Relatório de Produtos`,
        subtitle2: `Data do relatório: ${formatCustomBR(new Date())}`,
        subtitle3: '',
      };

      await exportarPDF(cabecalho, filtros, pConfiguracao);

      swal.fire({
        title: 'Relatório gerado com sucesso!',
        text: `Confira na aba ao lado!`,
        icon: 'success',
      });
    } catch (e) {
      swal.fire({
        title: 'Oops...',
        text: `${(e as Error).message}`,
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  const editarProduto = useCallback(
    (produto: ProdutoType) => {
      history(`/produto/${produto.id}`);
    },
    [history],
  );

  useEffect(() => {
    carregarProdutos([]);
  }, [carregarProdutos]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'listar-produtos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />

      <Content>
        <Area>
          <Left>
            <BarraLateral
              aoAplicarConfiguracao={aoAplicarConfiguracao}
              configuracao={configuracao}
              aoExportarRelatorio={aoExportarRelatorio}
            >
              <Filtros aoAplicarFiltros={aoAplicarFiltros} />
            </BarraLateral>
          </Left>
          <Right>
            <Plate
              title="Produtos"
              subtitle="Administre seus produtos, gerencie estoque, preco, etc"
              icon={() => <BsFillPuzzleFill size={32} color={colors.label} />}
            >
              <Link to="/produto/0">
                <Button type="button" background={colors.green}>
                  Novo
                </Button>
              </Link>
            </Plate>

            {produtos && !loading ? (
              <List
                onFetch={reCarregarProdutos}
                header={[
                  {
                    column: 'codigo',
                    label: 'CÓDIGO',
                    width: 50,
                    search: true,
                    isNumeric: true,
                  },
                  {
                    column: 'descricao',
                    label: 'DESCRIÇÃO',
                    search: true,
                  },
                  {
                    column: 'referencia',
                    label: 'REFERÊNCIA',
                    search: true,
                  },
                ]}
                data={produtos}
                options={[
                  {
                    id: 1,
                    label: 'Editar',
                    onPress: item => {
                      editarProduto(item);
                    },
                  },
                ]}
                pagination
                activePage={paginaAtual}
                totalPages={totalPaginas}
              />
            ) : null}

            {loading ? <Loader /> : null}
          </Right>
        </Area>
      </Content>
    </Container>
  );
};

export default Contas;
