import styled, { css } from 'styled-components';

export const HeaderSearchResultsContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    float: left;
    padding: 4rem 0;

    h3 {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.big};
      font-weight: 700;
    }

    h4 {
      color: ${theme.colors.placeholder};
      font-size: ${theme.fontSizes.default};
      margin-bottom: 2rem;

      b {
        font-weight: 700;
        color: ${theme.colors.label};
      }
    }

    div {
      width: 100%;
      float: left;
      max-width: 102.4rem;
    }

    button {
      float: left;
      height: 6rem;
      line-height: 6rem;
      align-items: center;
      display: flex;
      transition: all 0.5s ease;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      border: 0.1rem solid ${theme.colors.defaultHighlight};
      background: ${theme.colors.secondary};
      margin-right: 2rem;
      margin-bottom: 1rem;
      color: ${theme.colors.label};

      &:hover {
        background: ${theme.colors.defaultHighlight};
      }

      svg {
        margin-left: -0.5rem;
      }
    }

    span {
      font-size: ${theme.fontSizes.default};
      font-weight: 500;
      margin-left: 1rem;
    }
  `}
`;
