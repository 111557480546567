import styled, { keyframes, css } from 'styled-components';

import { HNCProps } from './interface';

export const HeaderNotificationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderNotificationsControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
`;

export const HeaderNotificationsControlIc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  padding: 0.8rem;
  border-radius: 100%;
  transition: all 0.5s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHighlight};
  }
`;

export const HeaderNotificationsCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 700;
  float: left;
  background: #f4772e;
  width: 2rem;
  line-height: 2rem;
  border-radius: 100%;
  height: 2rem;
  text-align: center;
  margin: -2rem 0 0 -1.5rem;
`;

const $visibleContentAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const in$visibleContentAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const HeaderNotificationsContent = styled.div<HNCProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 55rem;
    z-index: 3000;
    height: 30rem;
    background: ${theme.colors.background};
    border: 0.1rem solid ${theme.colors.default};
    top: 7rem;
    border-radius: 0.5rem;
    box-shadow: ${theme.shadows.default};
    transform: scale(1) translate(-50%, 0);
    visibility: ${$visible ? '$visible' : 'hidden'};

    animation: ${$visible
        ? $visibleContentAnimation
        : in$visibleContentAnimation}
      0.5s forwards;

    @media (max-width: 1024px) {
      width: 100%;
      left: 0rem;
      top: 8rem;
      transform: scale(1) translate(0, 0);
    }
  `}
`;
