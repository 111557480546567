import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from 'styled-components';
import { BsFillTriangleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader } from '~/components';

import { InstituicaoFinanceiraType } from '~/types';

import api from '~/services/api';

const InstituicaoFinanceiras: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);
  const [instituicoesFinanceiras, setInstituicoesFinanceiras] = useState<
    Array<InstituicaoFinanceiraType>
  >([]);

  const fetchInstituicaoFinanceiras = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('financeiros/instituicoes-financeiras');
      const { data } = response;
      if (data && data.status !== 'error') {
        setInstituicoesFinanceiras(data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const handlerEditInstituicaoFinanceira = useCallback(
    (instituicaoFinanceira: InstituicaoFinanceiraType) => {
      history(`/instituicao-financeira/${instituicaoFinanceira.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchInstituicaoFinanceiras();
  }, [fetchInstituicaoFinanceiras]);

  return (
    <Container>
      <Loader visible={loading} />
      <Header />
      <Content>
        <Plate
          title="Instituições Financeiras"
          subtitle="Cadastre as Instituições Financeiras utilizadas"
          icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
        >
          <Link to="/instituicao-financeira/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {instituicoesFinanceiras ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'tipo',
                label: 'Tipo',
                search: false,
              },
              {
                column: 'empresa',
                label: 'Empresa',
                search: false,
              },
            ]}
            data={[
              ...instituicoesFinanceiras.map(instituicaoFinanceira => ({
                ...instituicaoFinanceira,
                empresa: instituicaoFinanceira.empresa.fantasia,
              })),
            ]}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditInstituicaoFinanceira(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default InstituicaoFinanceiras;
