import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { BsFillTriangleFill } from 'react-icons/bs';

import api from '~/services/api';

import { getValidationErrors } from '~/utils';

import {
  FormContainer,
  FormContent,
  FormBlock,
  InputsContainer,
  InputContainer,
  Input,
  InputNumber,
  Plate,
  Button,
  FindProduto,
  Loader,
} from '~/components';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

import { Add, Search } from './styles';

import { ProdutoType, UnidadeType } from '~/types';

import { ProdutoProps, ProdutoFormData } from './interface';

const Produto: React.FC<ProdutoProps> = ({ aoAdicionarItem }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [inLoad, setInLoad] = useState(false);

  const [findProdutoVisibilidade, setFindProdutoVisibilidade] = useState(false);
  const [produto, setProduto] = useState<ProdutoType | null>(null);
  const [unidade, setUnidade] = useState<UnidadeType | null>(null);
  const [quantidade, setQuantidade] = useState('0');
  const [unitario, setUnitario] = useState('');

  const buscarDadosDaUnidade = (pUnidadeId: string) => {
    return new Promise<UnidadeType | null>(resolve => {
      const handler = async () => {
        try {
          let unidades: UnidadeType[] = [];
          const response = await api.get('produtos/unidades');
          const { data } = response;
          if (data && data.status !== 'error') {
            unidades = [...data];
            let contador = 0;
            for (contador = 0; contador < unidades.length; contador += 1) {
              const unidadeRecorrente = unidades[contador];
              if (`${unidadeRecorrente.id}` === `${pUnidadeId}`) {
                return resolve(unidadeRecorrente);
              }
            }
          }
          resolve(null);
        } catch (e) {
          resolve(null);
        }
      };
      handler();
    });
  };

  const focarNaQuantidade = () => {
    const inputQtdNode = document.querySelector<HTMLInputElement>(
      'input[name="quantidade"]',
    );
    if (inputQtdNode) {
      inputQtdNode.focus();
      setTimeout(() => {
        inputQtdNode.select();
      }, 100);
    }
  };

  const enterNaQuantidade = () => {
    const inputValorUnitarioNode = document.querySelector<HTMLInputElement>(
      'input[name="valor_unitario"]',
    );
    if (inputValorUnitarioNode) {
      inputValorUnitarioNode.focus();
      setTimeout(() => {
        inputValorUnitarioNode.select();
      }, 100);
    }
  };

  const enterNoValorUnitario = () => {
    if (formRef) {
      formRef?.current?.submitForm();
    }
  };

  const atribuirProduto = async (selecionados: ProdutoType[]) => {
    if (selecionados.length > 0) {
      const itemSelecionado = selecionados[0];
      if (itemSelecionado) {
        const { unidade_id } = itemSelecionado;
        const unidadeDoItem = await buscarDadosDaUnidade(unidade_id);
        setProduto(itemSelecionado);
        if (unidadeDoItem) {
          setUnidade(unidadeDoItem);
        }
        focarNaQuantidade();
      }
    }
    setFindProdutoVisibilidade(false);
  };

  const calcularTotal = () => {
    return `${handlerNumberToString(
      handlerToNumber(quantidade) * handlerToNumber(unitario),
    )}`;
  };

  const submeterFormulario = () => {
    if (formRef) {
      formRef?.current?.submitForm();
    }
  };

  const limparFormulario = () => {
    setUnidade(null);
    setProduto(null);
    setUnitario('');
    setQuantidade('');
    formRef.current?.reset();
  };

  const enviarFormuilario = useCallback(
    async (data: ProdutoFormData) => {
      try {
        if (!produto) {
          return new Error('Selecione um produto!');
        }

        if (!unidade) {
          return new Error('Unidade inválida!');
        }

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          unidade: Yup.string().required('Informe uma unidade válida.'),
          quantidade: Yup.string().required('Informe uma quantidade válida.'),
          valor_unitario: Yup.string().required(
            'Informe um valor unitário válido.',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        setInLoad(true);

        aoAdicionarItem({
          nItem: Math.random() * 10000,
          produto_id: handlerToNumber(`${produto.id}`),
          produto_descricao: produto.descricao,
          conversao: 0,
          unidade: data.unidade,
          quantidade: handlerToNumber(data.quantidade),
          valor_unitario: handlerToNumber(data.valor_unitario),
          variacoes: [],
        });

        limparFormulario();

        setTimeout(() => {
          setInLoad(false);
        }, 500);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [aoAdicionarItem, produto, unidade],
  );

  if (inLoad) {
    return <Loader />;
  }

  return (
    <FormContainer>
      <Form ref={formRef} onSubmit={enviarFormuilario}>
        <FormContent>
          <Plate
            title="Adicionar"
            subtitle="Adicione itens a sua compra manual"
            icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
          />

          <FormBlock>
            <InputsContainer>
              <InputContainer>
                <span>Produto</span>
                <div>
                  <Input
                    value={produto?.id}
                    name="produto_id"
                    placeholder="Produto"
                    disabled
                  />
                  <Search>
                    <FindProduto
                      openned={findProdutoVisibilidade}
                      onlySearch
                      onSelectValue={atribuirProduto}
                    />
                  </Search>
                </div>
              </InputContainer>
              <InputContainer>
                <span>Descrição</span>
                <div>
                  <Input
                    name="produto_descricao"
                    placeholder="Descrição"
                    value={produto?.descricao}
                    disabled
                  />
                </div>
              </InputContainer>
              <InputContainer width={250}>
                <span>Unidade</span>
                <div>
                  <Input
                    name="unidade"
                    placeholder="Unidade"
                    value={unidade?.sigla}
                    disabled
                  />
                </div>
              </InputContainer>
            </InputsContainer>

            <InputsContainer>
              <InputContainer width={250}>
                <span>Quantidade</span>
                <div>
                  <Input
                    disabled={!produto}
                    name="quantidade"
                    placeholder="Quantidade"
                    value={quantidade}
                    onKeyDownEnter={enterNaQuantidade}
                    onChange={e => setQuantidade(e.target.value)}
                  />
                </div>
              </InputContainer>
              <InputContainer width={250}>
                <span>Unitário R$ </span>
                <div>
                  <InputNumber
                    disabled={!produto}
                    name="valor_unitario"
                    placeholder="Unitário"
                    onChange={value => setUnitario(value)}
                    onKeyDownEnter={enterNoValorUnitario}
                  />
                </div>
              </InputContainer>
              <InputContainer width={250}>
                <span>Total R$ </span>
                <div>
                  <InputNumber
                    name="total"
                    placeholder="Total"
                    value={`${calcularTotal()}`}
                    disabled
                  />
                </div>
              </InputContainer>
              <Add>
                <Button
                  disabled={!produto}
                  type="button"
                  onClick={submeterFormulario}
                  background={colors.green}
                >
                  <BsFillPlusCircleFill size={24} color="#fff" />
                </Button>
              </Add>
            </InputsContainer>
          </FormBlock>
        </FormContent>
      </Form>
    </FormContainer>
  );
};

export default Produto;
