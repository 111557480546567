import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;

  form {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0;
    }

    button {
      margin-top: 2rem;
      span {
        margin-right: 1rem;
      }
    }
  }
`;
