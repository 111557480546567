import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiPlus } from 'react-icons/fi';
import { GiPriceTag } from 'react-icons/gi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addDash } from 'string-sanitizer';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';

import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import Header from '~/components/Header';
import List from '~/components/List';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import {
  CondicaoPagamentoType,
  InstituicaoFinanceiraType,
  SelectType,
} from '~/types';

import { ParamsProps, TipoDePagamentoFormData } from './interface';

import CondicaoDePagamento from './CondicaoDePagamento';
import { handlerErrors } from '~/utils/error';

const TipoDePagamento: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({
    id,
    tipo_value: '',
    ativo: true,
  });
  const [registerId, setRegisterId] = useState('0');

  const [cpCurrent, setCPCurrent] = useState('0');
  const [cpVisible, setCpVisible] = useState(false);
  const [condicoesPagamento, setCondicoesPagamento] = useState<
    Array<CondicaoPagamentoType>
  >([]);
  const [instituicoesFinanceiras, setInstituicoesFinanceiras] = useState<
    SelectType[]
  >([]);
  const [tipo, setTipo] = useState('');

  const formRef = useRef<FormHandles>(null);

  const carregarCondicoesDePagamento = useCallback(async () => {
    if (id && id !== '0') {
      setLoading(true);
      const response = await api.get(
        `financeiros/tipos-pagamento/${id}/condicoes`,
      );
      setCondicoesPagamento(response.data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [id]);

  const handlerOpenCP = () => {
    setCpVisible(true);
  };

  const handlerCloseCP = () => {
    setCpVisible(false);
  };

  const handlerSaveCP = () => {
    carregarCondicoesDePagamento();
  };

  const handlerEditCP = (item: CondicaoPagamentoType) => {
    setCPCurrent(`${item.id}`);
    handlerOpenCP();
  };

  const handlerRemoveCP = (item: CondicaoPagamentoType) => {
    if (item) {
      swal
        .fire({
          title: 'Deseja realmente remover este condição de pagamento?',
          text: `Ao confirmar, a condição será removida!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            setLoading(true);
            await api.delete(
              `financeiros/tipos-pagamento/${id}/condicoes/${item.id}`,
            );
            setLoading(false);
            carregarCondicoesDePagamento();
          }
        });
    }
  };

  const handleSubmit = useCallback(
    async (data: TipoDePagamentoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const slug = addDash(data.descricao);

          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `financeiros/tipos-pagamento/${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            {
              descricao: data.descricao,
              ativo: data.ativo,
              situacao_lancamento: data.situacao_lancamento,
              tipo: data.tipo,
              instituicao_financeira_id: data.instituicao_financeira_id || null,
              slug,
              mobile: false,
              emite_boleto: false,
            },
          );

          history(`/tipo-de-pagamento/${response.data.id}`);
          setRegisterId(response.data.id);

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    setRegisterData({ id: '0', tipo_value: '', ativo: true });
    formRef.current?.reset();
  }

  useEffect(() => {
    const loadInstituicoesFinanceiras = async () => {
      try {
        const instituicoesFinanceirasFetch = await api.get(
          'financeiros/instituicoes-financeiras',
        );
        const instituicoesFinanceirasFetched = [] as SelectType[];
        instituicoesFinanceirasFetch.data.data.forEach(
          (item: InstituicaoFinanceiraType) => {
            instituicoesFinanceirasFetched.push({
              value: `${item.id}`,
              label: item.descricao,
              color: '',
            });
          },
        );
        setInstituicoesFinanceiras(instituicoesFinanceirasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id && id !== '0') {
        const response = await api.get(`financeiros/tipos-pagamento/${id}`);
        setRegisterData({
          ...response.data,
          situacao_lancamento: {
            value: response.data.situacao_lancamento,
            label: response.data.situacao_lancamento,
          },
          tipo: {
            value: response.data.tipo,
            label: response.data.tipo,
          },
          tipo_value: response.data.tipo,
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    const initialize = async () => {
      try {
        await loadInstituicoesFinanceiras();
        await loadRegister();
        await carregarCondicoesDePagamento();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    initialize();
  }, [id, carregarCondicoesDePagamento]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      {cpVisible ? (
        <CondicaoDePagamento
          id={cpCurrent}
          tipo_pagamento_id={id}
          tipo_value={registerData.tipo_value}
          onRequestClose={handlerCloseCP}
          onRequestSave={handlerSaveCP}
        />
      ) : null}

      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Tipo de pagamento</AnchorLink>
            <AnchorLink anchor="condicoes-pagamento">
              Condições de pagamento
            </AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Tipo de pagamento"
                      subtitle="Adicione tipo de pagamento"
                      icon={() => <GiPriceTag size={32} color={colors.label} />}
                    />
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe a descricação do tipo de pagamento"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Situação de lançamento</span>
                        <div>
                          <Select
                            name="situacao_lancamento"
                            loading={loading}
                            options={[
                              { value: 'ABERTA', label: 'ABERTA', cor: '' },
                              { value: 'PAGA', label: 'PAGA', cor: '' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Controle financeiro</span>
                        <div>
                          <Select
                            name="tipo"
                            onSelectedOption={item => setTipo(item.value)}
                            loading={loading}
                            options={[
                              { value: 'BANCO', label: 'BANCO', cor: '' },
                              { value: 'CARTAO', label: 'CARTAO', cor: '' },
                              { value: 'PRAZO', label: 'PRAZO', cor: '' },
                              { value: 'NENHUM', label: 'NENHUM', cor: '' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                      {(tipo === 'BANCO' || tipo === 'CARTAO') && (
                        <InputContainer disabled={formLoading}>
                          <span>Instituição Financeira</span>
                          <div>
                            <Select
                              name="instituicao_financeira_id"
                              loading={loading}
                              options={instituicoesFinanceiras}
                            />
                          </div>
                        </InputContainer>
                      )}
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>

              {registerId !== '0' ? (
                <AnchorRef anchor="condicoes-pagamento">
                  <FormBlock>
                    <Plate
                      title="Condições de pagamento"
                      subtitle="Gerencie as condições de pagamento"
                      icon={() => <GiPriceTag size={32} color={colors.label} />}
                    >
                      <Button
                        type="button"
                        background={colors.darkGreen}
                        onClick={handlerOpenCP}
                      >
                        <FiPlus size={24} />
                      </Button>
                    </Plate>
                    <List
                      header={[
                        {
                          column: 'descricao',
                          label: 'Descrição',
                        },
                        {
                          column: 'ativo',
                          label: 'Ativo',
                        },
                        {
                          column: 'compra',
                          label: 'Compra',
                        },
                        {
                          column: 'venda',
                          label: 'Venda',
                        },
                      ]}
                      data={[
                        ...condicoesPagamento.map(condicaoDePagamento => ({
                          ...condicaoDePagamento,
                          ativo: condicaoDePagamento.ativo ? 'SIM' : 'NAO',
                          compra: condicaoDePagamento.compra ? 'SIM' : 'NAO',
                          venda: condicaoDePagamento.venda ? 'SIM' : 'NAO',
                        })),
                      ]}
                      options={[
                        {
                          label: 'Editar',
                          onPress: item => {
                            handlerEditCP(item);
                          },
                        },
                        {
                          label: 'Remover',
                          onPress: item => {
                            handlerRemoveCP(item);
                          },
                        },
                      ]}
                    />
                  </FormBlock>
                </AnchorRef>
              ) : null}

              <FormFooter>
                <Link to="/tipos-de-pagamentos">
                  <Button type="button" background={colors.contrast}>
                    Cancelar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default TipoDePagamento;
