import styled, { css } from 'styled-components';

import { AiOutlineCloseCircle } from 'react-icons/ai';

import { ListSearchContainerProps } from './interface';

export const ListSearchContainer = styled.div<ListSearchContainerProps>`
  ${({ theme, $isfocused, $isactive, $isorder, $width }) => css`
    float: left;
    width: ${$width ? `${$width}px !important` : '100%'};
    border-bottom: 0.2rem solid;
    border-color: ${$isfocused ? `${theme.colors.white}` : 'rgba(0,0,0,0)'};
    height: 3.9rem;
    border-right: 0.1rem solid ${theme.colors.default};
    background: ${$isactive
      ? `${theme.colors.primary}`
      : `${theme.colors.primaryDark}`} !important;
    /* padding: 1.8rem 0.8rem 1.6rem 1.5rem; */
    padding: 0rem;
    padding-right: 0.5rem;
    flex: ${$width ? 'auto' : '1'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .list-search-order {
      opacity: ${$isorder ? '1' : '0'};
    }

    &:hover {
      .list-search-order {
        opacity: 1;
      }
    }

    input {
      background: none;
      border: none;
      display: flex;
      flex: 1;
      float: left;
      margin: 0rem;
      padding: 0rem;
      color: ${theme.colors.white};
      font-weight: 500;
      font-size: ${theme.fontSizes.default};
      min-width: 0px;

      &::placeholder {
        color: ${theme.colors.greyLowerOpacity};
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    td {
      background: ${theme.colors.default};
      font-weight: 700;
      font-size: ${theme.fontSizes.small};
      padding: 1.5rem;
    }
  `}
`;

export const ListSearchOrder = styled.span`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      stroke: ${theme.colors.white};

      &:hover {
        opacity: 0.5 !important;
      }

      path {
        stroke: ${theme.colors.white};
      }
    }

    span {
      background: #fff;
      $width: 1.8rem;
      height: 1.8rem;
      border-radius: 0.9rem;
      color: #333;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      margin-left: 10px;
    }
  `}
`;

export const ListSearchCloseCircleIcon = styled(AiOutlineCloseCircle)`
  svg {
    size: 22;
  }

  &:hover {
    opacity: 0.5 !important;
  }
`;
