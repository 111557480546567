import { darken, shade } from 'polished';
import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.button<ContainerProps>`
  ${({ theme, $background, disabled, currentTheme = 'dark' }) => css`
    font-size: ${theme.fontSizes.large};
    background: ${$background || theme.colors.primaryDark};
    height: 4.6rem;
    border-radius: ${theme.radio.default};
    border: 0;
    padding: 0 1.6rem;
    color: #f5f5f5;
    width: 100%;
    font-weight: 500;
    /* margin-top: 1.6rem; */
    transition: $background-color 0.2s;
    box-shadow: ${theme.shadows.default};
    border: 3px solid rgba(0, 0, 0, 0);

    ${disabled
      ? css`
          $background: ${darken(0.2, $background || theme.colors.primaryDark)};
        `
      : css`
          &:hover {
            $background: ${shade(
              0.2,
              `${$background || theme.colors.primaryDark}`,
            )};
          }
        `}

    &:focus {
      border-color: ${theme.colors.primary};
    }
  `}
`;
