import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputMask,
  InputsContainer,
  Plate,
  Select,
  Switch,
  Textarea,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { InstituicaoFinanceiraFormData, ParamsProps } from './interface';

import { EmpresaType, SelectType } from '~/types';
import { handlerErrors } from '~/utils/error';

const InstituicaoFinanceira: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id, ativo: true });
  const [registerId, setRegisterId] = useState('0');
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: InstituicaoFinanceiraFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        const instituicaoFinanceiraData = {
          ...data,
          juros: 0,
          multa: 0,
          taxa: 0,
        };

        delete instituicaoFinanceiraData.data_cadastro;

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `financeiros/instituicoes-financeiras${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            instituicaoFinanceiraData,
          );

          history(`/instituicao-financeira/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    setLoading(true);
    formRef.current?.reset();
    setRegisterData({ id: '0', ativo: true });
    setLoading(false);
  }

  useEffect(() => {
    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response = await api.get(
          `financeiros/instituicoes-financeiras/${id}`,
        );
        const { data } = response;
        setRegisterData({
          ...data,
          tipo: {
            label: data.tipo === `conta-corrente` ? 'CORRENTE' : 'POUPANÇA',
            value: data.tipo,
          },
          empresa_id: {
            label: data.empresa.fantasia,
            value: data.empresa.id,
          },
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    const initialize = async () => {
      await loadEmpresas();
      await loadRegister();
    };

    initialize();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Instituição Financeira"
                      subtitle="Adicione ou gerencie uma instituição financeira"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Empresa</span>
                        <div>
                          <Select
                            name="empresa_id"
                            loading={loading}
                            options={empresas}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe o nome da instituição"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Agência</span>
                        <div>
                          <Input name="agencia" placeholder="Ex: 0104" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Conta</span>
                        <div>
                          <Input name="conta" placeholder="Ex: 00000-2" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Titular</span>
                        <div>
                          <Input name="titular" placeholder="Nome do titular" />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Gerente</span>
                        <div>
                          <Input name="gerente" placeholder="Nome do gerente" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Carteira</span>
                        <div>
                          <Input name="carteira" placeholder="Ex: 1324" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Convênio</span>
                        <div>
                          <Input
                            name="convenio"
                            placeholder="Informe o convenio"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Cedente</span>
                        <div>
                          <Input name="cedente" placeholder="Nome do cedente" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Dias para protesto</span>
                        <div>
                          <Input
                            name="dias_protesto"
                            placeholder="Ex. 1, 5 ... 30"
                            type="number"
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Aceite</span>
                        <div>
                          <Input name="aceite" placeholder="S ou N" />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Código do banco</span>
                        <div>
                          <Input
                            name="codigo_banco"
                            placeholder="Código do banco"
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Limite do cheque especial R$</span>
                        <div>
                          <Input name="limite" placeholder="Ex: 1000,00 R$" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Tipo</span>
                        <div>
                          <Select
                            name="tipo"
                            loading={loading}
                            options={[
                              {
                                value: 'conta-corrente',
                                label: 'CORRENTE',
                                cor: '',
                              },
                              { value: 'poupanca', label: 'POUPANÇA', cor: '' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>CNPJ do Beneficiário</span>
                        <div>
                          <InputMask
                            mask="999.999.9999-99"
                            name="cnpj_beneficiario"
                            placeholder="Informe o CNPJ do beneficiário"
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Endereço de Beneficiário</span>
                        <div>
                          <Input
                            name="endereco_beneficiario"
                            placeholder="Ex: Rua teste, 123"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Emite boleto?</span>
                        <div className="switch">
                          <Switch name="emite_boleto" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Registrado?</span>
                        <div className="switch">
                          <Switch name="registrado" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Informações</span>
                        <div>
                          <Textarea
                            name="informacoes"
                            placeholder="Informações no rodapé dos boletos"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/instituicoes-financeiras">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default InstituicaoFinanceira;
