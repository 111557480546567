import styled, { css } from 'styled-components';

import { DragDropProps } from './interface';

import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;

export const DragDrop = styled.div<DragDropProps>`
  ${({ theme, $active }) => css`
    float: left;
    margin: 2rem 2rem 0 2rem;
    border: 0.3rem dashed ${$active ? theme.colors.green : theme.colors.label};
    border-radius: 1rem;
  `};
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6rem;

    div {
      float: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      p {
        float: left;
        margin: 0;
        padding: 0;
        font-size: ${theme.fontSizes.big};

        ins {
          cursor: pointer;
        }
      }

      small {
        font-size: 1.2rem;
        opacity: 0.6;
      }
    }
  `};
`;
