import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 10rem 10rem;
  grid-gap: 1rem;
`;

export const Item = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 1rem;
    height: 8rem;
    float: left;
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.large};
    border: 2px solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    cursor: pointer;
    transition: all 0.4s ease;
    // padding: 0 2rem;

    &:hover {
      background: ${theme.colors.controlFocus};
    }
  `}
`;
