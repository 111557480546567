import styled, { css } from 'styled-components';

export const ErrorMessageContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    margin: 0px;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  `}
`;

export const ErrorMessageContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 1rem;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    border: 0.5rem solid #ef524f;
    border-radius: 0.5rem;
    padding: 1rem 2rem 1rem 2rem;
    align-items: center;

    div {
      display: flex;
      flex: 1;
      flex-direction: row;
      color: #ef524f;
      font-size: 1.6rem;
      padding-left: 2rem;
    }

    span {
      color: ${theme.colors.label};
    }

    button {
      transition: all 0.5s ease;
      background: none;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;
