import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  float: left;
  padding-left: 2rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    float: left;
    flex-direction: row;
    border: 0.1rem solid ${theme.colors.grey};
    border-radius: ${theme.radio.default};
    align-self: flex-start;
    align-items: center;
    margin: 0 0 2rem 0;
    background-color: ${theme.colors.default};

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      float: left;
      border-right: 0.1rem solid ${theme.colors.grey};
      padding: 1rem;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.small};
    }

    button {
      background: none;
      padding: 0.5rem;
    }
  `}
`;
