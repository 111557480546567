import styled from 'styled-components';

export const HeaderSearchEmptyContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;

  h3 {
    color: #f4772e;
    font-size: ${({ theme }) => theme.fontSizes.big};
    font-weight: 700;
  }

  h4 {
    color: ${({ theme }) => theme.colors.default};
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
`;
