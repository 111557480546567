import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';

import { BsFillPuzzleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Button, Loader, Plate, NoPermission } from '~/components';

import { TributacaoType } from '~/types';

import api from '~/services/api';
import { makeParams } from '~/utils/parametrizer';

import Filtragem from './Filtragem';
import { validarPermissao } from '~/utils/permissions';

const Tributacoes: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const history = useNavigate();

  const [loading, setLoading] = useState(true);
  const [tributacoes, setTributações] = useState<TributacaoType[]>([]);

  const [visibilidadeFiltragem, setFiltragemModalVisible] = useState(false);

  const fecharFiltragem = () => {
    setFiltragemModalVisible(false);
  };

  const handlerFiltragemModalOpen = () => {
    setFiltragemModalVisible(true);
  };

  const buscarTributacoes = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (page?: string, order?: Array<any>, filters?: Array<any>) => {
      try {
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const params = await makeParams(page, order, filters);
        // const url = `tributacao?${params}`;
        const url = `tributacao`;
        const response = await api.get(url);
        const { data } = response;
        if (data && data.length) {
          setTributações(data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [],
  );

  const salvarFiltagem = (
    uf_origem: string,
    cst_csosn: string,
    mod_cal_st_retido: string,
    uf_destino: string,
    cst_pis_cofins: string,
    mod_cal_icms_substituto: string,
    classificacao_fiscal: string,
    cfop: string,
    natureza_operacao: string,
    origem: string,
    grupo_tributacao: string,
    modbc_icms: string,
    produto: string,
    modbc_icms_st: string,
  ) => {
    buscarTributacoes(
      '',
      [{ column: 'descricao', direction: 'asc' }],
      [
        { column: 'uf_origem', value: uf_origem },
        { column: 'cst_csosn', value: cst_csosn },
        { column: 'mod_cal_st_retido', value: mod_cal_st_retido },
        { column: 'uf_destino', value: uf_destino },
        { column: 'cst_pis_cofins', value: cst_pis_cofins },
        { column: 'mod_cal_icms_substituto', value: mod_cal_icms_substituto },
        { column: 'classificacao_fiscal', value: classificacao_fiscal },
        { column: 'cfop', value: cfop },
        { column: 'natureza_operacao', value: natureza_operacao },
        { column: 'origem', value: origem },
        { column: 'grupo_tributacao', value: grupo_tributacao },
        { column: 'modbc_icms', value: modbc_icms },
        { column: 'produto', value: produto },
        { column: 'modbc_icms_st', value: modbc_icms_st },
      ],
    );
  };

  const editarTributacao = useCallback(
    (tributacao: TributacaoType) => {
      history(`/tributacao/${tributacao.id}`);
    },
    [history],
  );

  useEffect(() => {
    buscarTributacoes('', [{ column: 'descricao', direction: 'asc' }], []);
  }, [buscarTributacoes]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'listar-tributacao')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />

      <Filtragem
        onRequestClose={fecharFiltragem}
        visible={visibilidadeFiltragem}
        onRequestSave={salvarFiltagem}
        haveRegisters={tributacoes && tributacoes.length > 0}
      />

      <Content>
        <Plate
          title="Tributações"
          subtitle="Configure as suas tributações"
          icon={() => <BsFillPuzzleFill size={32} color={colors.label} />}
        >
          <Button
            type="button"
            background={colors.contrast}
            onClick={handlerFiltragemModalOpen}
          >
            Filtrar
          </Button>
          <Link to="/tributacao/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {tributacoes ? (
          <List
            onFetch={buscarTributacoes}
            header={[
              {
                column: 'codigo',
                label: 'CÓDIGO',
                width: 50,
                search: false,
                isNumeric: true,
              },
              {
                column: 'cdClasseFiscal',
                label: 'CLASSE FISCAL',
                search: false,
              },
              {
                column: 'cdNaturezaOperacao',
                label: 'OPERAÇÃO',
                search: false,
              },
              {
                column: 'cdUFOrigem',
                label: 'ORIGEM',
                search: false,
              },
              {
                column: 'cdUFDestino',
                label: 'DESTINO',
                search: false,
              },
              {
                column: 'cdCST',
                label: 'CST',
                search: false,
              },
              {
                column: 'cdCFOP',
                label: 'CFOP',
                search: false,
              },
              {
                column: 'vlAliquotaICMS',
                label: 'ICMS',
                search: false,
              },
              {
                column: 'idDestacarICMS',
                label: 'DESTACAR ICMS',
                search: false,
              },
              {
                column: 'cdPIS_CST',
                label: 'CST PIS',
                search: false,
              },
              {
                column: 'vlPIS_vAliqProd',
                label: 'ALIQ PIS',
                search: false,
              },
              {
                column: 'cdCOFINS_CST',
                label: 'CST COFINS',
                search: false,
              },
              {
                column: 'vlCOFINS_vAliqProd',
                label: 'ALIQ COFINS',
                search: false,
              },
              {
                column: 'cdIPI_CST',
                label: 'CST IPI',
                search: false,
              },
              {
                column: 'vlAliquotaVIPI',
                label: 'ALIQ IPI',
                search: false,
              },
              {
                column: 'cdGrupoTributacao',
                label: 'GRUPO TRIBUTAÇÃO',
                search: false,
              },
            ]}
            data={tributacoes}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  editarTributacao(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default Tributacoes;
