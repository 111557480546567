import styled, { css } from 'styled-components';

import { ProgressProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    padding: 2rem 2rem 0 2rem;
    font-weight: 700;
    margin-top: 2rem;
  `};
`;

export const Area = styled.div`
  float: left;
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const Add = styled.div`
  float: left;
  margin-left: 1rem;
  width: 12rem;
`;

export const Progress = styled.div<ProgressProps>`
  ${({ $percent, theme }) => css`
    float: left;
    width: 100%;
    margin: 4rem 0 2rem 0;
    padding: 0 2rem;

    h2 {
      float: left;
      font-size: ${theme.fontSizes.large};
      color: ${theme.colors.label};
      margin-bottom: 1rem;
    }

    div {
      float: left;
      width: 100%;
      border-radius: 0.5rem;
      height: 3rem;
      overflow: hidden;
      background: ${theme.colors.contrast};

      span {
        float: left;
        width: ${`${$percent || 0}%`};
        height: 3rem;
        border-radius: 0.5rem 0 0 0.5rem;
        background: ${theme.colors.green};
      }
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 0.5rem;

    button {
      width: 15rem;
    }
  }
`;

export const Empty = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    flex: 1;
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
  `};
`;
