import React, { useRef, useCallback, useState, useEffect } from 'react';

import { BsFillTagFill, BsFillGearFill } from 'react-icons/bs';
import { AiFillPrinter } from 'react-icons/ai';
import { FaSyncAlt } from 'react-icons/fa';
import { MdHelp } from 'react-icons/md';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';

import api from '~/services/api';

import { useToast } from '~/hooks/toast';

import {
  gerarZPL,
  imprimirZPL,
  buscarImpressoras,
  setarImpressora,
} from './engine.js';

import { getValidationErrors } from '~/utils';

import {
  Button,
  Select,
  InputContainer,
  Loader,
  ErrorMessage,
} from '~/components';

import { Container, Content, Footer, Count, Help } from './styles';

import { EtiquetaType, SelectType } from '~/types';

import { ImpressaoProps, FormData } from './interface';

import Ajuda from './Ajuda';

const Impressao: React.FC<ImpressaoProps> = ({
  etiquetasImpressao,
  limparFilaImpressao,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState('');

  const [etiquetas, setetiquetas] = useState<SelectType[]>([]);
  const [impressoras, setImpressoras] = useState<SelectType[]>([]);

  const [ajuda, setAjuda] = useState(false);

  const abrirAjuda = () => {
    setAjuda(true);
  };

  const fecharAjuda = () => {
    setAjuda(false);
  };

  const adicionarNovoModelo = () => {
    history(`/etiquetas`);
  };

  const fetchEtiquetas = async () => {
    try {
      setLoading(true);
      const etiquetasFetch = await api.get('produtos/etiquetas');
      const etiquetasFetched = [] as SelectType[];
      etiquetasFetch.data.forEach((item: EtiquetaType) => {
        etiquetasFetched.push({
          value: `${item.id}`,
          label: item.nome,
          color: '',
        });
      });
      setetiquetas(etiquetasFetched);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchImpressoras = async () => {
    const impressorasFetched = [] as SelectType[];

    try {
      const impressorasZPLs: Array<{
        name: string;
        deviceType: string;
        connection: string;
        uid: string;
        provider: string;
        manufacturer: string;
        version: number;
      }> = await buscarImpressoras();

      impressorasZPLs.forEach(impressoraZPL => {
        impressorasFetched.push({
          value: impressoraZPL.uid,
          label: impressoraZPL.name,
          color: '',
        });
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    setImpressoras(impressorasFetched);
  };

  const fetchEtiqueta = async (etiquetaId: string) => {
    return new Promise<EtiquetaType>((resolve, reject) => {
      const handler = async () => {
        try {
          const response = await api.get(`produtos/etiquetas/${etiquetaId}`);
          const { data } = response;
          resolve(data);
        } catch (err) {
          reject(err);
        }
      };
      handler();
    });
  };

  const submeterFormulario = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          etiqueta_id: Yup.string().required('Modelo de impressão obrigatório'),
          impressora: Yup.string().required('Impressora obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { etiqueta_id, impressora } = data;

        const etiqueta = await fetchEtiqueta(etiqueta_id);

        // 1. GERANDO ZPL
        const zpl = await gerarZPL(etiquetasImpressao, etiqueta);

        // 2.SETANDO IMPRESSORA PADRÃO
        await setarImpressora(impressora);

        // 3. IMPRIMINDO ZPL
        await imprimirZPL(zpl);

        // 4. LIMPANDO FILA
        limparFilaImpressao();

        addToast({
          type: 'success',
          title: 'Imprimindo',
          description: `Imprimindo ${
            etiquetasImpressao.length > 1
              ? `${etiquetasImpressao.length} etiquetas`
              : 'etiqueta'
          } na impressora "${impressora}" usando o modelo de configuraçao "${
            etiqueta.nome
          }"`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError(`${(err as Error).message}`);
      }
    },
    [addToast, etiquetasImpressao, limparFilaImpressao],
  );

  useEffect(() => {
    fetchEtiquetas();
    fetchImpressoras();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!etiquetasImpressao.length) return null;

  return (
    <Container>
      <Ajuda visible={ajuda} onRequestClose={fecharAjuda} />

      <Content>
        <Count>
          <BsFillTagFill size={52} color={colors.label} />
          <div>
            {etiquetasImpressao.length > 1
              ? `${etiquetasImpressao.length} etiquetas preparadas para impressão`
              : 'Uma variação preparadas para impressão.'}
          </div>
        </Count>

        <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            etiqueta_id: '',
            impressora: '',
          }}
        >
          <InputContainer>
            <span>Padrão de Impressão</span>
            <div>
              <Select name="etiqueta_id" options={etiquetas} loading={false} />
              <Button
                style={{
                  marginLeft: 15,
                  width: 80,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                type="button"
                onClick={adicionarNovoModelo}
                background={colors.grey}
              >
                <BsFillGearFill size={24} color={colors.white} />
              </Button>
            </div>
          </InputContainer>

          <InputContainer>
            <span>Impressora</span>
            <div>
              <Select name="impressora" options={impressoras} loading={false} />

              <Button
                style={{
                  marginLeft: 15,
                  width: 80,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                type="button"
                onClick={fetchImpressoras}
                background={colors.grey}
              >
                <FaSyncAlt size={24} color={colors.white} />
              </Button>
            </div>
            <Help onClick={abrirAjuda}>
              <MdHelp size={32} color={colors.white} />
              <div>Sua impressora não apareceu?</div>
            </Help>
          </InputContainer>

          <Footer>
            <div>
              <Button type="submit" background={colors.green}>
                <AiFillPrinter size={32} color={colors.label} />
                <span style={{ marginLeft: 10 }}>Imprimir Etiquetas</span>
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Impressao;
