import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FiUsers } from 'react-icons/fi';

import qs from 'qs';

import swal from 'sweetalert2';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import { handlerToNumber } from '~/utils/money';

import Header from '~/components/Header';
import List from '~/components/List';

import {
  BarraLateral,
  Button,
  Loader,
  Plate,
  NoPermission,
} from '~/components';

import Filtros from './Filtros';

import { Area, Container, Content, Left, Right } from './styles';

import { FiltroType, ParceiroType, RelatorioType } from '~/types';

import { RelatorioCabecalhoType } from '~/types/relatorio';

import configuracaoInicial from '~/schemas/relatorio-parceiros.json';
import { formatCustomBR } from '~/utils/formatDate';

import ModalAlterarSenha from '~/components/ModalAlterarSenha';
import { exportarPDF } from './engine';
import { validarPermissao } from '~/utils/permissions';

const Contas: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();
  const { user, getAuthUser } = useAuth();
  const [modalAlterarSenhaIsVisible, setModalAlterarSenhaIsVisible] =
    useState(false);

  const [parceiroId, setParceiroId] = useState(0);

  const [configuracao, setConfiguracao] =
    useState<RelatorioType>(configuracaoInicial);

  const [filtros, setFiltros] = useState<FiltroType[]>([]);

  const [parceiros, setParceiros] = useState<ParceiroType[]>([]);

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [loading, setLoading] = useState(false);

  const voltarAoTopo = () => {
    window.scrollTo(0, 0);
  };

  const sintetizarParametros = (pFiltros: FiltroType[]): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parametros: any = {};

    pFiltros.forEach((cFiltro: FiltroType) => {
      const { valor, nome } = cFiltro;
      const cValor = valor;
      if (valor) {
        parametros[nome] = cValor;
      }
    });

    const qsParametros = qs.stringify(parametros);

    return qsParametros;
  };

  const carregarParceiros = useCallback(async (pFiltros: FiltroType[]) => {
    try {
      setLoading(true);

      const response = await api.get(
        `parceiros?${sintetizarParametros([
          ...pFiltros,
          { nome: 'perPage', valor: '50' },
        ])}`,
      );

      const { data } = response;

      if (data && data.status !== 'error') {
        const { data: cParceiros, lastPage } = data;
        setParceiros(cParceiros);

        if (lastPage) {
          setTotalPaginas(handlerToNumber(`${lastPage}`));
        }
      }

      setLoading(false);
      voltarAoTopo();
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const reCarregarParceiros = useCallback(
    async (
      page?: string,
      order?: Array<{ direction: string; column: string }>,
      filter?: Array<{ value: string; column: string }>,
    ) => {
      try {
        setLoading(true);

        const cFiltros: FiltroType[] = [...filtros];

        if (page) {
          setPaginaAtual(handlerToNumber(page));
          cFiltros.push({
            nome: 'page',
            valor: page ? `${page}` : paginaAtual,
          });
        }

        if (order && order.length) {
          const outputOrder: Array<string> = [];
          order.forEach(orderCurrent => {
            outputOrder.push(
              `${orderCurrent.direction === 'asc' ? '+' : '-'}${
                orderCurrent.column
              }`,
            );
          });
          cFiltros.push({
            nome: 'order_by',
            valor: `${outputOrder.join(',')}`,
          });
        }

        if (filter && filter.length) {
          filter.forEach(filterCurrent => {
            cFiltros.push({
              nome: filterCurrent.column,
              valor: `${filterCurrent.value}`,
            });
          });
        }

        carregarParceiros(cFiltros);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [carregarParceiros, filtros, paginaAtual],
  );

  const aoAplicarFiltros = async (pFiltros: FiltroType[]) => {
    setFiltros(pFiltros);
    setPaginaAtual(1);
    carregarParceiros([...pFiltros, { nome: 'page', valor: 1 }]);
  };

  const aoAplicarConfiguracao = (
    pConfiguracao: RelatorioType,
    preloader: boolean,
  ) => {
    const cFiltros: FiltroType[] = [];

    // ORDENAÇÃO
    if (pConfiguracao && pConfiguracao.ordenar.length) {
      cFiltros.push({
        nome: 'order_by',
        valor: pConfiguracao.ordenar
          .reduce((acc: string[], item) => {
            if (item.enable) {
              acc.push(`${item.order === 'ASC' ? '' : '-'}${item.field}`);
            }
            return acc;
          }, [])
          .join(','),
      });
    }

    if (preloader) {
      setLoading(true);
      setConfiguracao(pConfiguracao);
      setTimeout(() => {
        setLoading(false);
        aoAplicarFiltros(cFiltros);
      }, 1000);
    } else {
      setConfiguracao(pConfiguracao);
      aoAplicarFiltros(cFiltros);
    }
  };

  const editarParceiro = useCallback(
    (parceiro: ParceiroType) => {
      history(`/parceiro/${parceiro.id}`);
    },
    [history],
  );

  const configuracoesDoParceiro = useCallback(
    (parceiro: ParceiroType) => {
      history(`/configuracoes-do-parceiro/${parceiro.id}`);
    },
    [history],
  );

  const alterarOuResetarSenhaParceiro = useCallback(
    (parceiro: ParceiroType) => {
      if (parceiro?.id) setParceiroId(Number(parceiro?.id));
      setModalAlterarSenhaIsVisible(true);
    },
    [],
  );

  const aoExportarRelatorio = async (pConfiguracao: RelatorioType) => {
    try {
      const cabecalho: RelatorioCabecalhoType = {
        title: user.workspace.nome,
        subtitle1: `Relatório de Parceiros`,
        subtitle2: `Data do relatório: ${formatCustomBR(new Date())}`,
        subtitle3: '',
      };

      await exportarPDF(cabecalho, filtros, pConfiguracao);

      swal.fire({
        title: 'Relatório gerado com sucesso!',
        text: `Confira na aba ao lado!`,
        icon: 'success',
      });
    } catch (e) {
      swal.fire({
        title: 'Oops...',
        text: `${(e as Error).message}`,
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  useEffect(() => {
    carregarParceiros([]);
  }, [carregarParceiros]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'listar-parceiros')) {
    return <NoPermission />;
  }

  return (
    <Container>
      {modalAlterarSenhaIsVisible && (
        <ModalAlterarSenha
          title="Alterar senha"
          visible={modalAlterarSenhaIsVisible}
          onRequestClose={() => setModalAlterarSenhaIsVisible(false)}
          parceiro_id={parceiroId}
        />
      )}
      <Header />

      <Content>
        <Area>
          <Left>
            <BarraLateral
              aoAplicarConfiguracao={aoAplicarConfiguracao}
              configuracao={configuracao}
              aoExportarRelatorio={aoExportarRelatorio}
            >
              <Filtros aoAplicarFiltros={aoAplicarFiltros} />
            </BarraLateral>
          </Left>
          <Right>
            <Plate
              title="Parceiros"
              subtitle="Administre seus funcionarios, fornecedores, clientes em um unico lugar"
              icon={() => <FiUsers size={32} color={colors.label} />}
              noRadius
            >
              <Link to="/parceiro/0">
                <Button type="button" background={colors.green}>
                  Novo
                </Button>
              </Link>
            </Plate>

            {parceiros && !loading ? (
              <List
                onFetch={reCarregarParceiros}
                header={[
                  {
                    column: 'codigo',
                    label: 'CÓDIGO',
                    width: 140,
                    search: true,
                    isNumeric: true,
                  },
                  {
                    column: 'nome_fantasia',
                    label: 'FANTASIA/APELIDO',
                    search: true,
                  },
                  {
                    column: 'cnpj_cpf',
                    label: 'CNPJ/CPF',
                    search: true,
                  },
                  {
                    column: 'observacao',
                    label: 'OBSERVAÇÃO',
                    search: true,
                  },
                ]}
                data={[
                  ...parceiros.map((parceiro: ParceiroType) => ({
                    ...parceiro,
                    list_line_options:
                      parceiro.funcionario !== null
                        ? [
                            1,
                            2,
                            (getAuthUser().isAdmin ||
                              getAuthUser().parceiro_id === parceiro.id) &&
                              3,
                          ]
                        : [1],
                  })),
                ]}
                options={[
                  {
                    id: 1,
                    label: 'Editar',
                    onPress: item => {
                      editarParceiro(item);
                    },
                  },
                  {
                    id: 2,
                    label: 'Configurações',
                    onPress: item => {
                      configuracoesDoParceiro(item);
                    },
                  },
                  {
                    id: 3,
                    label: `Alterar senha`,
                    onPress: item => {
                      alterarOuResetarSenhaParceiro(item);
                    },
                  },
                ]}
                pagination
                activePage={paginaAtual}
                totalPages={totalPaginas}
              />
            ) : null}

            {loading ? <Loader /> : null}
          </Right>
        </Area>
      </Content>
    </Container>
  );
};

export default Contas;
