const {
  Label,
  PrintDensity,
  PrintDensityName,
  Grid,
  Spacing,
  Text,
  FontFamily,
  FontFamilyName,
  Size,
  SizeType,
  Barcode,
  BarcodeType,
  BarcodeTypeName,
} = require('jszpl');

const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper');

/*
### DEVELOPER MODE ###
***************************************************
SE ATIVADO, FAZ UM MOCK DA CONEXÃO DE IMPRESSÃO COM 
PLUGIN ZPL E ABRE UM POP-UP NO LABELARY (PREVIEW)
***************************************************
*/
const ZPL_DEVELOPER_MODE = true;

const buscarImpressoras = () => {
  return new Promise((resolve, reject) => {
    try {
      console.log('-- ZPL :: BUSCAR IMPRESSORAS --');

      if (ZPL_DEVELOPER_MODE) {
        return resolve([
          {
            name: 'TEST-01',
            deviceType: '',
            connection: '',
            uid: '666',
            provider: '',
            manufacturer: '',
            version: 1,
          },
        ]);
      }

      const browserPrint = new ZebraBrowserPrintWrapper.default();
      const impressorasDisponiveis = browserPrint.getAvailablePrinters();
      resolve(impressorasDisponiveis);
    } catch (e) {
      reject(e);
    }
  });
};

const setarImpressora = impressoraPadraoUID => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(
        `-- ZPL :: SETAR IMPRESSORA (UID: ${impressoraPadraoUID}) --`,
      );

      if (ZPL_DEVELOPER_MODE) {
        return resolve(true);
      }

      const impressorasDisponiveis = await buscarImpressoras();

      const browserPrint = new ZebraBrowserPrintWrapper.default();

      impressorasDisponiveis.forEach(impressoraDisponivel => {
        if (impressoraDisponivel.uid === impressoraPadraoUID) {
          browserPrint.setPrinter(impressoraDisponivel);
        }
      });

      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
};

const gerarZPL = (etiquetasImpressao, modeloEtiqueta) => {
  return new Promise((resolve, reject) => {
    try {
      console.log(`-- ZPL :: GERAR ZPL --`);

      // PAGINA
      const PAGINA_LARGURA = modeloEtiqueta.largura;
      const PAGINA_ALTURA = modeloEtiqueta.altura;
      const PAGINA_LINHAS = modeloEtiqueta.linhas;
      const PAGINA_COLUNAS = modeloEtiqueta.colunas;
      const PAGINA_DESCRICAO_TOPO = `${modeloEtiqueta.descricao_topo}`;
      const PAGINA_MARGEM_VERTICAL = modeloEtiqueta.margem_vertical;
      const PAGINA_MARGEM_LATERAL = modeloEtiqueta.margem_lateral;
      const PAGINA_DENSIDADE_VERTICAL = modeloEtiqueta.densidade_vertical;
      const PAGINA_DENSIDADE_HORIZONTAL = modeloEtiqueta.densidade_horizontal;

      // CODIGO DE BARRA
      const CODIGOBARRAS_ALTURA = 40;
      const CODIGOBARRAS_EXIBIR =
        modeloEtiqueta.exibir_codigo_barras === 1 ? true : false;
      const CODIGOBARRAS_EXIBIR_LEGENDA =
        modeloEtiqueta.exibir_numero_codigo_barras === 1 ? true : false;

      const label = new Label();
      label.printDensity = new PrintDensity(PrintDensityName['8dpmm']);
      label.width = PAGINA_LARGURA;
      label.height = PAGINA_ALTURA;
      label.padding = new Spacing(
        PAGINA_MARGEM_LATERAL,
        PAGINA_MARGEM_VERTICAL,
        PAGINA_MARGEM_LATERAL,
        PAGINA_MARGEM_VERTICAL,
      );

      const grid = new Grid();
      label.content.push(grid);

      grid.columnSpacing = PAGINA_DENSIDADE_HORIZONTAL;
      grid.rowSpacing = PAGINA_DENSIDADE_VERTICAL;
      grid.border = 1;
      grid.padding = new Spacing(10);

      let c, l;

      for (c = 0; c < PAGINA_COLUNAS; c++) {
        grid.columns.push(new Size(1, SizeType.Relative));
      }

      for (l = 0; l < PAGINA_LINHAS; l++) {
        grid.rows.push(new Size(1, SizeType.Relative));
      }

      let p,
        pColuna = 0,
        pLinha = 0;
      for (p = 0; p < etiquetasImpressao.length; p++) {
        //PRODUTO
        const produto = etiquetasImpressao[p];

        //QUANTIDADE POR ITEM
        let q;
        for (q = 1; q <= produto.qtd; q++) {
          let spaceY = 15;

          //DESCRIÇÃO TOPO
          if (PAGINA_DESCRICAO_TOPO !== '') {
            const descricaoTopo = new Text();
            grid.content.push(descricaoTopo);
            descricaoTopo.text = PAGINA_DESCRICAO_TOPO;
            descricaoTopo.fontFamily = new FontFamily(FontFamilyName.D);
            descricaoTopo.grid.column = pColuna;
            descricaoTopo.grid.row = pLinha;
            descricaoTopo.margin = new Spacing(10, spaceY, 0, 0);
            spaceY += 35;
          }

          //NOME DO PRODUTO
          const nomeProduto = new Text();
          grid.content.push(nomeProduto);
          nomeProduto.text = produto.descricao;
          nomeProduto.fontFamily = new FontFamily(FontFamilyName.D);
          nomeProduto.grid.column = pColuna;
          nomeProduto.grid.row = pLinha;
          nomeProduto.margin = new Spacing(10, spaceY, 0, 0);
          spaceY += 35;

          //CODIGO DE BARRAS
          if (CODIGOBARRAS_EXIBIR) {
            const CODIGOBARRAS_LARGURA =
              Math.ceil(PAGINA_LARGURA / PAGINA_COLUNAS) - 40;
            const codigoDeBarras = new Barcode();
            grid.content.push(codigoDeBarras);
            codigoDeBarras.data = produto.codigo_barras;
            codigoDeBarras.width = CODIGOBARRAS_LARGURA;
            codigoDeBarras.height = CODIGOBARRAS_ALTURA;
            codigoDeBarras.margin = new Spacing(10, spaceY, 0, 0);
            codigoDeBarras.grid.column = pColuna;
            codigoDeBarras.grid.row = pLinha;
            codigoDeBarras.type = new BarcodeType(BarcodeTypeName.Code11);
            codigoDeBarras.interpretationLine = CODIGOBARRAS_EXIBIR_LEGENDA;
            spaceY += CODIGOBARRAS_EXIBIR_LEGENDA
              ? CODIGOBARRAS_ALTURA + 40
              : CODIGOBARRAS_ALTURA + 20;
          }

          //PREÇO DO PRODUTO
          const precoDoProduto = new Text();
          grid.content.push(precoDoProduto);
          precoDoProduto.text = produto.preco;
          precoDoProduto.fontFamily = new FontFamily(FontFamilyName.D);
          precoDoProduto.grid.column = pColuna;
          precoDoProduto.grid.row = pLinha;
          precoDoProduto.margin = new Spacing(10, spaceY, 0, 0);

          //COLUNA & LINHA
          pColuna += 1;
          if (pColuna >= PAGINA_COLUNAS) {
            pColuna = 0;
            pLinha += 1;
          }
        }
      }

      const zpl = label.generateZPL();

      if (ZPL_DEVELOPER_MODE) {
        const width = Math.round(PAGINA_LARGURA / 10, 1);
        const height = Math.round(PAGINA_ALTURA / 10, 1);
        const zplEncoded = encodeURIComponent(zpl);
        const PREVIEW_URL_ZPL = `http://labelary.com/viewer.html?density=8&quality=grayscale&width=${width}&height=${height}&units=cm&index=0&rotation=0&zpl=${zplEncoded}`;
        window.open(PREVIEW_URL_ZPL, '_blank', 'width=1000,height=1000');
      }

      resolve(zpl);
    } catch (e) {
      reject(e);
    }
  });
};

const imprimirZPL = zpl => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(`-- ZPL :: IMPRIMIR ZPL --`);

      if (ZPL_DEVELOPER_MODE) {
        return resolve(true);
      }

      const browserPrint = new ZebraBrowserPrintWrapper.default();
      const printerStatus = await browserPrint.checkPrinterStatus();

      if (printerStatus.isReadyToPrint) {
        browserPrint.print(zpl);
        resolve(true);
      } else {
        reject(printerStatus.errors);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export { buscarImpressoras, setarImpressora, gerarZPL, imprimirZPL };
