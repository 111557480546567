import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { IoMdClose } from 'react-icons/io';
import { FaChevronDown } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import Button from '../form/Button';
import Modal from '../Modal';

import AreaOff from './AreaOff';
import AreaOn from './AreaOn';

import {
  Container,
  Label,
  Menu,
  MenuItem,
  Empty,
  Footer,
  Clear,
  ClearBtn,
} from './styles';

import { LugProps, LugItemProps } from './interface';

const Lug: React.FC<LugProps> = ({
  title,
  itens,
  onSelectItens,
  placeholder,
}) => {
  const { colors } = useTheme();

  const [visible, setVisible] = useState(false);
  const [cache, setCache] = useState<LugItemProps[]>([...itens]);

  const [inRenderizer, setInRenderizer] = useState(false);

  const [currentGroup, setCurrentGroup] = useState(
    `${itens.reduce((acc: string, cItem, currentIndex) => {
      if (currentIndex === 0) {
        acc = cItem.group;
      }
      return acc;
    }, '')}`,
  );

  const setGroup = (pGroup: string) => {
    setCurrentGroup(pGroup);
  };

  const cancelItens = () => {
    const allClean = [
      ...cache.map(cItem => {
        return {
          ...cItem,
          active: false,
        };
      }),
    ];
    setCache(allClean);
    onSelectItens(allClean);
    setVisible(false);
  };

  const generateCountLabel = (pItens: LugItemProps[]) => {
    const totalItens = pItens.filter(cItem => cItem.active).length;

    if (totalItens > 1) {
      return `${totalItens} tags selecionadas`;
    }

    if (totalItens === 1) {
      return `Uma tag selecionada`;
    }

    return placeholder;
  };

  const generateLabel = (pItens: LugItemProps[]) => {
    const cItens = pItens
      .filter(cItem => cItem.active)
      .map(cItem => cItem.label);
    if (cItens.length > 1) {
      const ultimo = cItens.pop();
      if (ultimo) {
        return `${cItens.join(', ')}${
          cItens.length >= 2 ? ' e ' : ' e '
        }${ultimo}`;
      }
    }
    if (cItens.length === 1) {
      return `${cItens.join('')}`;
    }
    return '';
  };

  const checkActiveLabel = (pItens: LugItemProps[]) => {
    const check = pItens.filter(cItem => cItem.active);
    return check.length ? true : false;
  };

  const fetchGroups = (pItens: LugItemProps[]) => {
    const output: string[] = [];
    pItens.forEach(cItem => {
      if (!output.includes(cItem.group)) {
        output.push(cItem.group);
      }
    });
    return output;
  };

  const onRemoveTag = (pItem: LugItemProps) => {
    setInRenderizer(true);
    setCache([
      ...cache.map(cCache => {
        if (cCache.value === pItem.value) {
          return {
            ...cCache,
            active: false,
          };
        }
        return cCache;
      }),
    ]);
    setTimeout(() => {
      setInRenderizer(false);
    }, 150);
  };

  const onSaveTag = (pItem: LugItemProps) => {
    setInRenderizer(true);
    setCache([
      ...cache.map(cCache => {
        if (cCache.value === pItem.value) {
          return {
            ...cCache,
            active: true,
          };
        }
        return cCache;
      }),
    ]);
    setTimeout(() => {
      setInRenderizer(false);
    }, 150);
  };

  const applyChanges = () => {
    setInRenderizer(true);
    onSelectItens(cache);
    setVisible(false);
    setTimeout(() => {
      setInRenderizer(false);
    }, 150);
  };

  useEffect(() => {
    setCache(itens);
    setCurrentGroup(
      `${itens.reduce((acc: string, cItem, currentIndex) => {
        if (currentIndex === 0) {
          acc = cItem.group;
        }
        return acc;
      }, '')}`,
    );
  }, [itens]);

  if (!itens.length) {
    return null;
  }

  return (
    <Container>
      <Label
        onClick={() => setVisible(!visible)}
        $active={checkActiveLabel(cache)}
      >
        <span>
          <strong>{generateCountLabel(cache)}</strong>
          <small>{generateLabel(cache)}</small>
        </span>
        <i>
          {visible ? (
            <MdKeyboardArrowUp size={26} color={colors.white} />
          ) : (
            <MdKeyboardArrowDown size={26} color={colors.white} />
          )}
        </i>
      </Label>

      {visible && cache.length ? (
        <Modal
          title={title}
          visible={visible}
          onRequestClose={() => setVisible(false)}
          width="80rem"
        >
          <Menu>
            {fetchGroups(itens).map(cGroup => (
              <MenuItem
                key={cGroup}
                $active={currentGroup === cGroup}
                onClick={() => setGroup(cGroup)}
              >
                <span>{cGroup}</span>
                <i>
                  <FaChevronDown color={colors.green} size="32" />
                </i>
              </MenuItem>
            ))}
          </Menu>

          {!inRenderizer ? (
            <DndProvider backend={HTML5Backend}>
              {fetchGroups(itens).map(group => (
                <React.Fragment key={group}>
                  <AreaOff
                    visible={currentGroup === group}
                    tags={cache}
                    groupId={group}
                    onSaveTag={onSaveTag}
                  />
                </React.Fragment>
              ))}

              <AreaOn tags={cache} onRemove={onRemoveTag} />
            </DndProvider>
          ) : (
            <Empty>
              <span>
                <i />
              </span>
            </Empty>
          )}

          <Footer>
            <Button
              type="button"
              background={colors.contrast}
              onClick={() => setVisible(false)}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              background={colors.green}
              onClick={applyChanges}
            >
              Aplicar
            </Button>
          </Footer>
        </Modal>
      ) : null}

      {checkActiveLabel(cache) ? (
        <Clear>
          <ClearBtn onClick={cancelItens}>
            <IoMdClose size={28} color={colors.white} />
            <span>Limpar seleção</span>
          </ClearBtn>
        </Clear>
      ) : null}
    </Container>
  );
};

export default Lug;
