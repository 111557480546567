import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  float: left;
  padding: 0;
  bottom: 0rem;
  left: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ControlVisibility = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.6;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    border-top: 0.1rem solid ${theme.colors.grey};
    background: ${theme.colors.contrast};
    display: flex;
    width: 100%;
    float: left;
    flex-direction: column;
  `}
`;

export const Options = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* justify-content: space-between; */
    padding: 1rem 2rem;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 2rem;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 0 2rem;
      padding: 0rem;
    }

    span {
      margin-left: 0.5rem;
      font-weight: 700;
      font-size: 1.2rem;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 2rem 0 0;
      padding: 0rem;
      transition: all 0.5s ease;
      background: none;
      color: ${theme.colors.label};
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;

export const SlctsOptions = styled.div`
  display: flex;
  width: 100%;
  padding-right: 40rem;
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0rem; */
  margin-bottom: 1.5rem;
  justify-content: flex-end;
  /* border: 0.1rem solid #f5f; */
`;

export const SlctBtn = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0 0;
    padding: 1rem;
    width: 15rem;
    font-size: ${theme.fontSizes.small};
    border-radius: ${theme.radio.default};
    transition: all 0.5s ease;
    background: ${theme.colors.label};
    color: ${theme.colors.secondary};
    cursor: pointer;
    font-weight: 600;

    &:hover {
      background: ${theme.colors.primaryDark};
      color: ${theme.colors.white};
    }
  `}
`;

export const Submit = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 0.3rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.grey};
  background: ${({ theme }) => theme.colors.secondary};
  /* justify-content: space-between; */
  justify-content: flex-end;
`;

export const SubmitOpt = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${theme.fontSizes.default};
    font-weight: 500;
    transition: all 0.5s ease;
    color: ${theme.colors.label};
    cursor: pointer;
    background: none;
    transition: all 0.5 ease;
    padding: 0.8rem 1rem;
    border-radius: ${theme.radio.default};
    margin-left: 1rem;

    span {
      margin-right: 1rem;
      font-size: ${theme.fontSizes.default};
    }
    &:hover {
      background: ${theme.colors.defaultHighlight};
      /* text-decoration: underline; */
    }
  `}
`;
