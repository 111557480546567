import styled, { css } from 'styled-components';

import { InputContainerProps, ToggleProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  float: left;
  width: 100%;
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  flex: ${props => (props.width ? 0 : 1)};
  width: ${props => (props.width ? `${props.width}px` : 'auto')} !important;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  opacity: ${props => (props.disabled ? '0.2' : '1')};

  ${({ theme }) => css`
    span {
      margin-bottom: 0.5rem;
      font-size: ${theme.fontSizes.default};
    }

    > div {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      button {
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .switch {
      margin-top: 1rem;
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8rem;

  div {
    margin: 0 0.5rem;
    width: 20rem;
  }
`;

export const Toggle = styled.button.attrs<ToggleProps>({
  type: 'button',
})`
  ${({ theme, disabled }) => css`
    width: 100%;
    height: 4.8rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    background: ${theme.colors.controlNotFocus};
    border: 2px solid ${theme.colors.defaultHighlight};
    border-radius: ${theme.radio.default};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;

    pointer-events: ${disabled ? 'none' : 'all'};
    opacity: ${disabled ? '0.2' : '1'};

    span {
      flex: 1;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      text-align: left;
      padding: 0 1rem;
      margin-bottom: 0;

      ins {
        opacity: 0.2;
        text-decoration: none;
      }
    }

    &:hover {
      background: ${theme.colors.controlFocus};
    }
  `}
`;
