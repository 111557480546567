import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import * as Yup from 'yup';

import api from '~/services/api';

import {
  NfeContextProps,
  NFEType,
  NFEItemRequest,
  NFEItemResponse,
  CAItensRequest,
} from './interface';

import { ProdutoNfeType } from '~/types';

import { schemaNFE } from './schema';

import { handlerToNumber } from '~/utils/money';
import { handlerErrors } from '~/utils/error';

const NFEContext = createContext<NfeContextProps>({} as NfeContextProps);

export const NFEProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [_NFEs, _setNFEs] = useState<Array<NFEType>>(() => {
    const cachedItems = localStorage.getItem('@SisautERP:NFES');
    if (cachedItems) {
      return [...JSON.parse(cachedItems)];
    }
    return [];
  });

  const adicionarNFE = useCallback(
    (nfe: NFEType) => {
      _setNFEs([..._NFEs.filter(_nfe => _nfe.nome !== nfe.nome), nfe]);
    },
    [_NFEs],
  );

  const removerNFE = useCallback(
    (nfe: NFEType) => {
      _setNFEs([..._NFEs.filter(_item => _item.nome !== nfe.nome)]);
    },
    [_NFEs],
  );

  const editarNFE = useCallback(
    (nfe: NFEType) => {
      _setNFEs([
        ..._NFEs.map(cNFE => {
          if (cNFE.nome === nfe.nome) {
            return nfe;
          }
          return cNFE;
        }),
      ]);
    },
    [_NFEs],
  );

  const verificarErrosNoItem = (
    cItem: ProdutoNfeType,
    rItem: NFEItemResponse,
  ) => {
    const erros: Array<{ codigo: string; mensagem: string }> = [];

    // 1. ASSOCIAÇÃO
    if (!rItem.produto_id || Number(rItem.produto_id) === 0) {
      erros.push({ codigo: '01', mensagem: 'Produto não associado' });
    }

    // 2. DISTRIBUIÇÃO DAS VARIAÇÕES X QUANTDIDADE
    if (rItem.produto_id && rItem.grade) {
      if (cItem.variacoes && cItem.PROD_qCom) {
        const qtdInformada = cItem.variacoes.reduce((acc: number, cItemVr) => {
          // eslint-disable-next-line no-param-reassign
          acc += handlerToNumber(cItemVr.quantidade);
          return acc;
        }, 0);
        if (qtdInformada < handlerToNumber(`${cItem.PROD_qCom}`)) {
          erros.push({ codigo: '02', mensagem: 'Qtde de variação' });
        }
      } else {
        erros.push({ codigo: '02', mensagem: 'Qtde de variação' });
      }
    }

    // 3. UNIDADE DIVERGENTE
    if (rItem.produto_id) {
      if (
        rItem.unidade !== cItem.PROD_uCom &&
        !handlerToNumber(`${cItem.conversao}`)
      ) {
        erros.push({ codigo: '03', mensagem: 'Unidade divergente' });
      }
    }

    return erros;
  };

  const verificarItemNFE = useCallback(
    (
      itens: Array<ProdutoNfeType>,
      cnpj_parceiro: string,
      DEST_CNPJ: string,
    ): Promise<Array<ProdutoNfeType>> => {
      return new Promise<Array<ProdutoNfeType>>((resolve, reject) => {
        const handler = async () => {
          let outputData: Array<ProdutoNfeType> = [];

          try {
            const inputData: Array<NFEItemRequest> = [];

            itens.forEach(item => {
              inputData.push({
                cnpj_parceiro,
                DEST_CNPJ,
                produto_id_parceiro: `${item.PROD_cProd}`,
              });
            });

            const response = await api.put(`nfe/vinculo-produto`, {
              itens: inputData,
            });

            const { data } = response;

            if (data && data.status !== 'error') {
              outputData = [];
              itens.forEach(item => {
                let cStatus = true;

                let cErros: Array<{
                  codigo: string;
                  mensagem: string;
                }> = [];

                const itemInApi = data.filter(
                  (cItem: NFEItemResponse) =>
                    item.PROD_cProd &&
                    `${cItem.produto_id_parceiro}` === `${item.PROD_cProd}`,
                )[0];

                if (itemInApi) {
                  const checkIsErrors: Array<{
                    codigo: string;
                    mensagem: string;
                  }> = verificarErrosNoItem(item, itemInApi);

                  if (checkIsErrors && checkIsErrors.length) {
                    cErros = [...checkIsErrors];
                    if (checkIsErrors.length) {
                      cStatus = false;
                    }
                  }
                }

                outputData.push({
                  ...item,
                  produto_id: itemInApi.produto_id,
                  produto_descricao: itemInApi.descricao,
                  unidade: `${itemInApi.unidade || ''}`,
                  conversao: 1,
                  quantidade: Number(item.PROD_qCom) || 0,
                  status: cStatus,
                  erros: cErros,
                });
              });
            }
            resolve(outputData);
          } catch (e) {
            // console.log(e);
            reject();
          }
        };
        handler();
      });
    },
    [],
  );

  const validarNFE = useCallback(
    async (nfe: NFEType) => {
      editarNFE({
        ...nfe,
        status: 'in-load',
      });

      //ERROS
      const erros: Array<{ codigo: string; mensagem: string }> = [];

      //VALIDAÇÃO LOCAL
      try {
        await schemaNFE.validate(nfe.arquivo, { abortEarly: false });
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          e.inner.forEach((element, cIdx) => {
            erros.push({
              codigo: `IN-${cIdx}`,
              mensagem: `${element.message}`,
            });
          });
        }
      }

      //FORMAS DE PAGAMENTO
      if ((nfe && !nfe.conta_corrente_id) || nfe.conta_corrente_id === '') {
        erros.push({
          codigo: `CC`,
          mensagem: `Conta corrente não informada`,
        });
      }

      //FORMAS DE PAGAMENTO
      if (!nfe.formas || nfe.formas.length === 0) {
        erros.push({
          codigo: `CC`,
          mensagem: `Nenhuma forma de pagamento informada`,
        });
      }

      //VALIDAÇÃO DOS ITENS (VIA API)
      let itensValidados: Array<ProdutoNfeType> = [];

      if (nfe.arquivo.itens) {
        itensValidados = [...nfe.arquivo.itens];

        try {
          const itensNaApi = await verificarItemNFE(
            nfe.arquivo.itens,
            `${nfe.arquivo.EMIT_CNPJ}`,
            `${nfe.arquivo.DEST_CNPJ}`,
          );

          if (itensNaApi && itensNaApi.length) {
            itensValidados = [...itensNaApi];

            itensValidados.forEach(itemValidado => {
              if (
                itemValidado &&
                itemValidado.erros &&
                itemValidado.erros.length
              ) {
                itemValidado.erros.forEach(erroDoItem => {
                  erros.push({
                    codigo: erroDoItem.codigo,
                    mensagem: `[${itemValidado.PROD_cProd}: ${itemValidado.PROD_xProd}]: ${erroDoItem.mensagem}`,
                  });
                });
              }
            });
          }
        } catch (e) {
          erros.push({
            codigo: '0',
            mensagem: 'Falha ao validar itens da NFE na api',
          });
        }
      }

      setTimeout(() => {
        editarNFE({
          ...nfe,
          arquivo: {
            ...nfe.arquivo,
            itens: itensValidados,
          },
          erros: erros,
          status: 'loaded',
        });
      }, 1500);
    },
    [editarNFE, verificarItemNFE],
  );

  const existeErroNaNFE = (nfe: NFEType): boolean => {
    if (!nfe) {
      return true;
    }

    const { arquivo, erros } = nfe;
    if (!erros || erros.length > 0) {
      return true;
    }

    if (!arquivo) {
      return true;
    }

    const { itens } = arquivo;
    if (!itens || itens.length == 0) {
      return true;
    }

    return !!itens.filter(item => !item.status).length;
  };

  const cadastrarItensAutomaticamente = (
    nfe: NFEType,
  ): Promise<{ status: boolean; message: string }> => {
    return new Promise<{ status: boolean; message: string }>(resolve => {
      const handler = async () => {
        if (!nfe || !nfe.arquivo || !nfe.arquivo.itens) {
          return resolve({
            status: false,
            message: 'Itens não encontrados.',
          });
        }

        try {
          const inputData: CAItensRequest = {
            cnpj_parceiro: `${nfe.arquivo.EMIT_CNPJ}`,
            DEST_CNPJ: `${nfe.arquivo.DEST_CNPJ}`,
            itens: [
              ...nfe.arquivo.itens
                .filter(cItem => cItem.erros && cItem.erros.length)
                .map(cItem => ({
                  PROD_cProd: handlerToNumber(`${cItem.PROD_cProd}`),
                  PROD_cEAN: `${cItem.PROD_cEAN}`,
                  PROD_xProd: `${cItem.PROD_xProd}`,
                  PROD_NCM: `${cItem.PROD_NCM}`,
                  PROD_uCom: `${cItem.PROD_uCom}`,
                })),
            ],
          };

          const response = await api.post(`nfe/cadastro-produto`, inputData);

          const { data } = response;

          if (data && data.status !== 'error') {
            resolve({
              status: true,
              message: 'Itens cadastrados',
            });
          } else {
            resolve({
              status: false,
              message: `${data.message}`,
            });
          }
        } catch (e) {
          const message = handlerErrors(e);
          resolve({
            status: false,
            message: `${message}`,
          });
        }
      };
      handler();
    });
  };

  useEffect(() => {
    localStorage.setItem('@SisautERP:NFES', JSON.stringify(_NFEs));
  }, [_NFEs]);

  const value = {
    nfes: _NFEs,
    adicionarNFE,
    removerNFE,
    editarNFE,
    validarNFE,
    existeErroNaNFE,
    cadastrarItensAutomaticamente,
  };

  return <NFEContext.Provider value={value}>{children}</NFEContext.Provider>;
};

export function useNFE(): NfeContextProps {
  const context = useContext(NFEContext);
  if (!context) {
    throw new Error('hook usada fora de contexto do nfe!');
  }
  return context;
}
