import JsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import qs from 'qs';

import api from '~/services/api';

import {
  FiltroType,
  ParceiroType,
  RelatorioType,
  TotalizadorType,
} from '~/types';

import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatTheDateYall } from '~/utils/formatDate';

const extrairPropriedadeLinhaPDF = (cParceiro: ParceiroType, field: string) => {
  let prop = '';

  switch (field) {
    case 'nome_razao':
      prop = `${cParceiro.nome_razao}`;
      break;
    case 'nome_fantasia':
      prop = `${cParceiro.nome_fantasia}`;
      break;
    case 'codigo':
      prop = `${cParceiro.codigo}`;
      break;
    case 'cnpj_cpf':
      prop = `${cParceiro.cnpj_cpf}`;
      break;
    case 'ie_rg':
      prop = `${cParceiro.ie_rg}`;
      break;
    case 'data_nascimento':
      prop = `${formatTheDateYall(cParceiro.data_nascimento)}`;
      break;
    default:
      prop = '';
      break;
  }

  return prop;
};

const calcularLargura = (tamanhoEmPorcentagem: number, largura: number) => {
  const larguraDescontada = largura - 30;
  const tamanhoCelula = larguraDescontada * (tamanhoEmPorcentagem / 100);
  return tamanhoCelula;
};

const gerarLinhasPDF = (
  pParceiros: ParceiroType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const output: RowInput[] = [];

  pParceiros.forEach(cParceiro => {
    const line: RowInput = [];

    const ordenadores = pConfiguracao.ordenar.filter(
      ordenador => ordenador.enable,
    );

    let cacheTotalLarguras = 0;

    ordenadores.forEach((ordenador, key) => {
      let larguraColuna = ordenador.width;
      cacheTotalLarguras += larguraColuna;

      const ultimaColuna = ordenadores.length === key + 1;
      if (ultimaColuna) {
        const larguraPreenchivel = 100 - (cacheTotalLarguras - larguraColuna);
        if (larguraPreenchivel > 0) {
          larguraColuna = larguraPreenchivel;
        }
      }

      line.push({
        content: `${extrairPropriedadeLinhaPDF(cParceiro, ordenador.field)}`,
        styles: {
          cellWidth: calcularLargura(larguraColuna, largura),
        },
      });
    });

    output.push(line);
  });

  return output;
};

const renderizarListagemPDF = (
  pParceiros: Array<ParceiroType>,
  pConfiguracao: RelatorioType,
  largura: number,
) => {
  let output: RowInput[] = [];

  output = [...gerarLinhasPDF(pParceiros, pConfiguracao, largura)];
  return output;
};

const gerarTotalizadoresPDF = (
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
) => {
  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  const totalizadoresPDF: RowInput[] = [];

  pTotalizadores.forEach(totalizador => {
    let enable = false;

    pConfiguracao.totalizar.forEach(cfgTotalizador => {
      if (cfgTotalizador.field === totalizador.chave && cfgTotalizador.enable) {
        enable = true;
      }
    });

    if (enable) {
      totalizadoresPDF.push([
        {
          content: `${totalizador.chave}`,
          colSpan: totalColunas - 1,
          styles: { halign: 'right' },
        },
        {
          content: `${Number(totalizador.valor)}`,
          styles: { halign: 'center' },
        },
      ]);
    }
  });

  return totalizadoresPDF;
};

const sintetizarParametros = (
  pFiltros: FiltroType[],
  pConfiguracao: RelatorioType,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parametros: any = {};

  const outputOrder: Array<string> = [];

  if (pConfiguracao && pConfiguracao.ordenar.length) {
    pConfiguracao.ordenar.forEach(orderCurrent => {
      outputOrder.push(
        `${orderCurrent.type === 'ASC' ? '+' : '-'}${orderCurrent.field}`,
      );
    });
  }

  pFiltros.push({
    nome: 'order_by',
    valor: `${outputOrder.join(',')}`,
  });

  pFiltros.forEach((cFiltro: FiltroType) => {
    const { valor, nome } = cFiltro;
    const cValor = valor;
    if (valor) {
      parametros[nome] = cValor;
    }
  });

  const qsParametros = qs.stringify(parametros);

  return qsParametros;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extrairTotalizadores = (total: any) => {
  if (!Array.isArray(total)) {
    return [
      {
        chave: 'Registros',
        valor: total,
      },
    ];
  }

  return Object.entries(total).map(([label, value]) => ({
    chave: label === 'total' ? 'Registros' : label,
    valor: value,
  })) as TotalizadorType[];
};

const buscarDados = (pFiltros: FiltroType[], pConfiguracao: RelatorioType) => {
  return new Promise<{
    pTotalizadores: TotalizadorType[];
    pParceiros: ParceiroType[];
  }>((resolve, reject) => {
    const handler = async () => {
      let pTotalizadores: TotalizadorType[] = [];
      let pParceiros: ParceiroType[] = [];

      let pagina = 1;
      let inFetch = true;

      while (inFetch) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await api.get(
            `parceiros?${sintetizarParametros(
              [...pFiltros, { nome: 'perPage', valor: '50' }],
              pConfiguracao,
            )}`,
          );

          const { data } = response;
          if (data) {
            const { data: rParceiros, total, lastPage } = data;
            if (!lastPage || lastPage >= pagina) {
              inFetch = false;
            }

            pParceiros = [...pParceiros, ...rParceiros];

            if (total && pagina === 1) {
              pTotalizadores = [...extrairTotalizadores(total)];
            }
          }

          pagina += 1;
        } catch (e) {
          inFetch = false;
          reject(e);
        }
      }

      resolve({ pTotalizadores, pParceiros });
    };
    handler();
  });
};

const exportarPDF = (
  pCabecalho: RelatorioCabecalhoType,
  pFiltros: FiltroType[],
  pConfiguracao: RelatorioType,
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const handler = async () => {
      // DADOS DO RELATORIO
      const { pTotalizadores, pParceiros } = await buscarDados(
        pFiltros,
        pConfiguracao,
      );

      // ORDENADORES
      const ordenadoresAtivos = pConfiguracao.ordenar
        .filter(ordenador => ordenador.enable)
        .map(ordenador => ({
          content: ordenador.label,
        }));

      if (!ordenadoresAtivos.length) {
        return reject(
          new Error(
            'Nenhum ordenador selecionado, selecione ao menos um ordenador',
          ),
        );
      }

      // PDF
      const doc = new JsPDF({
        orientation:
          pConfiguracao.modo === 'RETRATO' ? 'portrait' : 'landscape',
      });

      let cMarginTop = 34;
      let h = 26;
      if (
        !pCabecalho.subtitle1 &&
        !pCabecalho.subtitle2 &&
        !pCabecalho.subtitle3
      ) {
        h = 13;
        cMarginTop = 21;
      } else if (!pCabecalho.subtitle2 && !pCabecalho.subtitle3) {
        h = 18;
        cMarginTop = 26;
      } else if (!pCabecalho.subtitle3) {
        h = 22;
        cMarginTop = 30;
      }

      autoTable(doc, {
        headStyles: {
          lineColor: '#000',
          overflow: 'ellipsize',
        },

        bodyStyles: {
          overflow: 'ellipsize',
        },

        didDrawPage: data => {
          // LOGOTIPO
          // const base64Img = gerarLogoTipoPDF();

          doc.setFillColor(255, 250, 250);

          // NOME DA EMPRESA
          doc.setFillColor(255, 250, 250);
          doc.setDrawColor(140, 143, 157);

          doc.roundedRect(
            data.settings.margin.left,
            5,
            doc.internal.pageSize.getWidth() -
              data.settings.margin.left -
              data.settings.margin.right -
              2,
            h,
            1,
            1,
            'FD',
          );
          doc.setTextColor(40);
          doc.setFontSize(14);
          doc.setFont('Courier-Bold', 'bold');
          doc.text(pCabecalho.title, data.settings.margin.left + 4, 13);

          doc.setFontSize(10);
          doc.setFont('Courier', 'bold');

          if (pCabecalho.subtitle1) {
            doc.text(pCabecalho.subtitle1, data.settings.margin.left + 4, 18);
          }

          doc.setFont('Courier', 'normal');

          if (pCabecalho.subtitle2) {
            doc.text(pCabecalho.subtitle2, data.settings.margin.left + 4, 22);
          }

          if (pCabecalho.subtitle3) {
            doc.text(pCabecalho.subtitle3, data.settings.margin.left + 4, 26);
          }
        },

        margin: { top: cMarginTop },

        // CABEÇALHO
        head: [[...ordenadoresAtivos]],

        // CORPO
        body: [
          ...renderizarListagemPDF(
            pParceiros,
            pConfiguracao,
            doc.internal.pageSize.getWidth(),
          ),
          ...gerarTotalizadoresPDF(pTotalizadores, pConfiguracao),
        ],
      });

      doc.output('dataurlnewwindow');
      // doc.save('relatório.pdf');
      resolve(true);
    };

    handler();
  });
};

export { exportarPDF };
