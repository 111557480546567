import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { List, arrayMove } from 'react-movable';

import { Container, Content, Area, Item, Control } from './styles';

import { RelatorioTotalizadorType } from '~/types';

import { TotalizadoresProps } from './interface';

const Totalizadores: React.FC<TotalizadoresProps> = ({
  configuracao,
  aoAplicarConfiguracao,
}) => {
  const { colors } = useTheme();

  const [itens, setItens] = useState<string[]>([
    ...configuracao.totalizar.map(totalizador => totalizador.field),
  ]);

  const verificarHabilitado = (pItem: string): boolean => {
    return configuracao.totalizar.reduce((acc: boolean, item) => {
      if (item.field === pItem && item.enable === true) {
        // eslint-disable-next-line no-param-reassign
        acc = true;
      }
      return acc;
    }, false);
  };

  const inverterInatividade = (pItem: string) => {
    if (verificarHabilitado(pItem)) {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          totalizar: [
            ...configuracao.totalizar.map(totalizador => ({
              ...totalizador,
              enable: totalizador.field === pItem ? false : totalizador.enable,
            })),
          ],
        },
        false,
      );
    } else {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          totalizar: [
            ...configuracao.totalizar.map(totalizador => ({
              ...totalizador,
              enable: totalizador.field === pItem ? true : totalizador.enable,
            })),
          ],
        },
        false,
      );
    }
  };

  const extrairLabel = (campo: string) => {
    return configuracao.totalizar.reduce(
      (acc: string, item: RelatorioTotalizadorType) => {
        if (item.field === campo) {
          // eslint-disable-next-line no-param-reassign
          acc = item.label;
        }
        return acc;
      },
      '',
    );
  };

  const salvarPosicoes = (pItens: string[]) => {
    const totalizadoresOrdenados: RelatorioTotalizadorType[] = [];

    pItens.forEach(item => {
      configuracao.totalizar.forEach(totalizador => {
        if (totalizador.field === item) {
          totalizadoresOrdenados.push(totalizador);
        }
      });
    });

    aoAplicarConfiguracao(
      {
        ...configuracao,
        totalizar: totalizadoresOrdenados,
      },
      false,
    );
  };

  return (
    <Container>
      <Content>
        <List
          values={itens}
          onChange={({ oldIndex, newIndex }) => {
            setItens(arrayMove(itens, oldIndex, newIndex));
            salvarPosicoes(arrayMove(itens, oldIndex, newIndex));
          }}
          renderList={({ children, props }) => (
            <Area {...props}>{children}</Area>
          )}
          renderItem={({ value, props }) => (
            <Item $inativo={!verificarHabilitado(value)} {...props}>
              <Control onClick={() => inverterInatividade(value)}>
                {verificarHabilitado(value) ? (
                  <ImCheckboxChecked size={42} color={colors.label} />
                ) : (
                  <ImCheckboxUnchecked
                    size={42}
                    color={colors.defaultHighlight}
                  />
                )}
              </Control>
              {extrairLabel(value)}
            </Item>
          )}
        />
      </Content>
    </Container>
  );
};

export default Totalizadores;
