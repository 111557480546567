import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $active }) => css`
    float: left;
    flex: 1;
    margin: 0 2rem;
    border-radius: 0.6rem;
    display: ${$active ? 'flex' : 'none'};
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    border-color: rgba(0, 0, 0, 0.1);
    padding: 2rem 0;
    border: 0.1rem solid;
    flex-direction: column;
    border-color: ${theme.colors.controlFocus};
  `}
`;
