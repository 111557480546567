import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '~/hooks/auth';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Area, Container, Content, Divisor, Space } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  DatePicker,
  ErrorMessage,
  FindProduto,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputMask,
  InputsContainer,
  NoPermission,
  Plate,
  Select,
  Tab,
  TabLink,
  TabMenu,
  Textarea,
} from '~/components';

import List from '~/components/List';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { handlerErrors } from '~/utils/error';
import { NFeFormData, ParamsProps } from './interface';

import ambienteNFeSchema from '~/schemas/NFe/ambiente.json';
import finalidadeEmissaoNFeSchema from '~/schemas/NFe/finalidade-emissao.json';
import indicadorDestinoNFeSchema from '~/schemas/NFe/indicador-destino.json';
import operacaoConsumidorFinalNFeSchema from '~/schemas/NFe/operacao-consumidor-final.json';
import tipoEmissaoNFeSchema from '~/schemas/NFe/tipo-emissao.json';
import tipoOperacaoNFeSchema from '~/schemas/NFe/tipo-operacao.json';

import NaturezaOperacaoType from '~/types/natureza_operacao';

const NFe: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id });
  const [registerId, setRegisterId] = useState('0');
  const [naturezasOperacao, setNaturezasOperacao] = useState<
    NaturezaOperacaoType[]
  >([]);

  const [activeTab, setActiveTab] = useState('Emitente');
  const [activeTabTransp, setActiveTabTransp] = useState('Veículo e Reboque');

  const formRef = useRef<FormHandles>(null);

  const onSelectTab = (name: string) => {
    setActiveTab(name);
  };

  const onSelectTabTransp = (name: string) => {
    setActiveTabTransp(name);
  };

  const enviarFormulario = useCallback(
    async (data: NFeFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        const nfeData = data;

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `nfe${registerId !== '0' ? `/${registerId}` : ''}`,
            nfeData,
          );

          history(`/nfe/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          setFormLoading(false);
          setFormError(`${handlerErrors(e)}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function limparFormulario() {
    setRegisterData({ id: '0' });
    formRef.current?.reset();
  }

  useEffect(() => {
    const carregarNFe = async () => {
      setLoading(false);

      // if (id) {
      //   setRegisterId(id);
      // }
      // if (id !== '0') {
      //   const response = await api.get(`nfe/${id}`);
      //   const { data } = response;
      //   setRegisterData({
      //     ...data,
      //   });
      //   setLoading(false);
      // } else {
      //   limparFormulario();
      //   setLoading(false);
      // }
    };

    carregarNFe();
  }, [id]);

  useEffect(() => {
    const carregarNaturezasOperacao = async () => {
      setLoading(false);

      const response = await api.get(`pedido/natureza-operacao`);

      const { data } = response;

      if (data) {
        setNaturezasOperacao(data);
      }

      // if (id) {
      //   setRegisterId(id);
      // }
      // if (id !== '0') {
      //   const response = await api.get(`nfe/${id}`);
      //   const { data } = response;
      //   setRegisterData({
      //     ...data,
      //   });
      //   setLoading(false);
      // } else {
      //   limparFormulario();
      //   setLoading(false);
      // }
    };

    carregarNaturezasOperacao();
  }, []);

  if (loading) {
    return <Loader />;
  } else if (!user?.permissoes.includes('incluir-documentos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={enviarFormulario}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Dados do Documento Fiscal"
                      subtitle="Preencha os dados da NFe"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer width={200}>
                        <span>Ambiente</span>
                        <div>
                          <Select
                            name="IDE_tpAmb"
                            width={200}
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={ambienteNFeSchema}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={150}>
                        <span>Modelo</span>
                        <div>
                          <Input name="IDE_mod" placeholder="" />
                        </div>
                      </InputContainer>

                      <InputContainer width={400}>
                        <span>Natureza da Operação</span>
                        <div>
                          <Select
                            name="IDE_natOp"
                            width={400}
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={naturezasOperacao.map(item => {
                              return {
                                label: item.apelido,
                                value: item.id,
                              };
                            })}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={150}>
                        <span>Série do Doc. Fiscal</span>
                        <div>
                          <Input name="IDE_serie" placeholder="" />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={200}>
                        <span>Número do Doc. Fiscal</span>
                        <div>
                          <Input name="IDE_nNF" placeholder="" />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={300}>
                        <span>Data Emissão</span>
                        <div>
                          <DatePicker
                            mask="99/99/9999"
                            name="IDE_dhEmi"
                            placeholder="__/__/____"
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={100}>
                        <span>Hora Emissão</span>
                        <div>
                          <InputMask
                            mask="99:99"
                            name="IDE_dhEmi_hora"
                            placeholder="__:__"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer>
                        <span>Tipo de Operação</span>
                        <div>
                          <Select
                            name="IDE_tpNF"
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={tipoOperacaoNFeSchema}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading} width={300}>
                        <span>Data Saída/Entrada Mercadoria</span>
                        <div>
                          <DatePicker
                            mask="99/99/9999"
                            name="IDE_dhSaiEnt"
                            placeholder="__/__/____"
                          />
                        </div>
                      </InputContainer>

                      <InputContainer>
                        <span>Forma de emissão da NFe</span>
                        <div>
                          <Select
                            name="IDE_tpEmis"
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={tipoEmissaoNFeSchema}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer>
                        <span>Finalidade da Operação</span>
                        <div>
                          <Select
                            name="IDE_finNFe"
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={finalidadeEmissaoNFeSchema}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer>
                        <span>Operação com Consumidor Final</span>
                        <div>
                          <Select
                            name="IDE_indFinal"
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={operacaoConsumidorFinalNFeSchema}
                          />
                        </div>
                      </InputContainer>

                      <InputContainer>
                        <span>Indicador de destino</span>
                        <div>
                          <Select
                            name="IDE_idDest"
                            loading={false}
                            defaultValue={{
                              value: '',
                              label: '',
                              cor: '',
                            }}
                            options={indicadorDestinoNFeSchema}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <Space />

                    <TabMenu>
                      <TabLink
                        name="Emitente"
                        active={activeTab === 'Emitente'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Destinatário/Remetente"
                        active={activeTab === 'Destinatário/Remetente'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Produtos"
                        active={activeTab === 'Produtos'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Transp./Frete"
                        active={activeTab === 'Transp./Frete'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Faturas"
                        active={activeTab === 'Faturas'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Docs. refêrenciados"
                        active={activeTab === 'Docs. refêrenciados'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Infos. Complementares"
                        active={activeTab === 'Infos. Complementares'}
                        onSelectTab={onSelectTab}
                      />
                      <TabLink
                        name="Totais"
                        active={activeTab === 'Totais'}
                        onSelectTab={onSelectTab}
                      />
                    </TabMenu>

                    <Tab name="Emitente" active={activeTab === 'Emitente'}>
                      <Area>
                        <InputsContainer>
                          <InputContainer width={300}>
                            <span>CNPJ</span>
                            <div>
                              <InputMask
                                mask="00.000.000/0000-00"
                                name="EMIT_CNPJ"
                                placeholder=""
                              />
                            </div>
                          </InputContainer>
                          <InputContainer width={300}>
                            <span>IE</span>
                            <div>
                              <Input name="EMIT_IE" placeholder="" />
                            </div>
                          </InputContainer>

                          <InputContainer>
                            <span>Razão Social</span>
                            <div>
                              <Input name="EMIT_xFant" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Fantasia</span>
                            <div>
                              <Input name="EMIT_xNome" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Fone</span>
                            <div>
                              <Input name="EMIT_fone" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>CRT - Código de Regime Tributário</span>
                            <div>
                              <Input name="EMIT_CRT" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>IE Substituto Tributário</span>
                            <div>
                              <Input name="EMIT_IEST" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>Endereço:</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Logradouro</span>
                            <div>
                              <Input name="EMIT_xLgr" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Número</span>
                            <div>
                              <Input name="EMIT_nro" placeholder="" />
                            </div>
                          </InputContainer>
                          {/* <InputContainer>
                            <span>Complemento</span>
                            <div>
                              <Input
                                name="emitenteComplemento"
                                placeholder=""
                              />
                            </div>
                          </InputContainer> */}
                          <InputContainer>
                            <span>Bairro</span>
                            <div>
                              <Input name="EMIT_xBairro" placeholder="Bairro" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>CEP</span>
                            <div>
                              <InputMask
                                mask="99.999-999"
                                name="EMIT_CEP"
                                placeholder=""
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Cidade</span>
                            <div>
                              <Input name="EMIT_xMun" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Código da Cidade (IBGE)</span>
                            <div>
                              <Input name="EMIT_cMun" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>UF</span>
                            <div>
                              <Select
                                name="EMIT_UF"
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value: '1',
                                    label: '-- OPÇÃO --',
                                    cor: '',
                                  },
                                  {
                                    value: '2',
                                    label: '-- OPÇÃO --',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Area>
                    </Tab>

                    <Tab
                      name="Destinatário/Remetente"
                      active={activeTab === 'Destinatário/Remetente'}
                    >
                      <Area>
                        <InputsContainer>
                          <InputContainer>
                            <span>Nome Fantasia</span>
                            <div>
                              <Input name="DEST_xNome" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>CPF/CNPJ</span>
                            <div>
                              <Input name="DEST_CNPJ" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>IE</span>
                            <div>
                              <Input name="DEST_IE" placeholder="" />
                            </div>
                          </InputContainer>
                          {/* <InputContainer>
                            <span>Produtor Rural</span>
                            <div className="switch">
                              <Switch name="destRemProdutorRural" />
                            </div>
                          </InputContainer> */}
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer>
                            <span>E-mail</span>
                            <div>
                              <Input name="DEST_email" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Telefone</span>
                            <div>
                              <InputMask
                                mask="(99) 9 9999-9999"
                                name="DEST_fone"
                                placeholder=""
                              />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Indicador da IE</span>
                            <div>
                              <Select
                                name="DEST_indIEDest"
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value: '1',
                                    label: '-- OPÇÃO --',
                                    cor: '',
                                  },
                                  {
                                    value: '2',
                                    label: '-- OPÇÃO --',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>Endereço:</Divisor>

                        <InputsContainer>
                          <InputContainer width={150}>
                            <span>CEP</span>
                            <div>
                              <InputMask
                                mask="99.999-999"
                                name="DEST_CEP"
                                placeholder=""
                              />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Logradouro</span>
                            <div>
                              <Input name="DEST_xLgr" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer width={150}>
                            <span>Número</span>
                            <div>
                              <Input name="DEST_nro" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Complemento</span>
                            <div>
                              <Input name="destRemComplemento" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Cidade</span>
                            <div>
                              <Input name="DEST_xMun" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Código da Cidade (IBGE)</span>
                            <div>
                              <Input name="DEST_cMun" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Bairro</span>
                            <div>
                              <Input name="DEST_xBairro" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer width={150}>
                            <span>UF</span>
                            <div>
                              <Select
                                name="DEST_UF"
                                width={150}
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value: '1',
                                    label: '-- OPÇÃO --',
                                    cor: '',
                                  },
                                  {
                                    value: '2',
                                    label: '-- OPÇÃO --',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>

                          {/* <InputContainer>
                            <span>Destinatário/Remetente do Exterior</span>
                            <div className="switch">
                              <Switch name="destRemExterior" />
                            </div>
                          </InputContainer> */}
                        </InputsContainer>

                        {/* <Divisor>Endereço de entrega</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>
                              Local de entrega difere do endereço do
                              destinatário?
                            </span>
                            <div className="switch">
                              <Switch name="destRemEndDifDest" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>Endereço de Retirada</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>
                              Local de entrega difere do endereço do remetente?
                            </span>
                            <div className="switch">
                              <Switch name="destRemEndDifRem" />
                            </div>
                          </InputContainer>
                        </InputsContainer> */}
                      </Area>
                    </Tab>

                    <Tab name="Produtos" active={activeTab === 'Produtos'}>
                      <Area>
                        <List
                          header={[
                            {
                              column: 'codigo',
                              label: 'COD.',
                            },
                            {
                              column: 'produto',
                              label: 'Produto',
                            },
                            {
                              column: 'unitario',
                              label: 'Vlr. Unitário',
                            },
                            {
                              column: 'qtd',
                              label: 'QTD',
                            },
                            {
                              column: 'seguro',
                              label: 'Seguro',
                            },
                            {
                              column: 'frete',
                              label: 'Frete',
                            },
                            {
                              column: 'outrasdesp',
                              label: 'Outras Desp.',
                            },
                            {
                              column: 'vlrtotal',
                              label: 'Vlr Total',
                            },
                            {
                              column: 'csticms',
                              label: 'CST ICMS',
                            },
                            {
                              column: 'cstpis',
                              label: 'CST PIS',
                            },
                            {
                              column: 'cstcofins',
                              label: 'CST COFINS',
                            },
                            {
                              column: 'cstipi',
                              label: 'CST IPI',
                            },
                            {
                              column: 'cfop',
                              label: 'CFOP',
                            },
                            {
                              column: 'ncm',
                              label: 'NCM',
                            },
                          ]}
                          data={[
                            {
                              codigo: '1',
                              produto: 'Produto Exemplo',
                              unitario: 'R$ 1,00',
                              qtd: '1.000',
                              seguro: 'R$ 0,00',
                              frete: 'R$ 0,00',
                              outrasdesp: 'R$ 0,00',
                              vlrtotal: 'R$ 1,00',
                              csticms: '666',
                              cstpis: '66',
                              cstcofins: '66',
                              cstipi: '',
                              cfop: '6666',
                              ncm: '666',
                            },
                            {
                              codigo: '2',
                              produto: 'Produto Exemplo',
                              unitario: 'R$ 1,00',
                              qtd: '1.000',
                              seguro: 'R$ 0,00',
                              frete: 'R$ 0,00',
                              outrasdesp: 'R$ 0,00',
                              vlrtotal: 'R$ 1,00',
                              csticms: '666',
                              cstpis: '66',
                              cstcofins: '66',
                              cstipi: '',
                              cfop: '6666',
                              ncm: '666',
                            },
                            {
                              codigo: '3',
                              produto: 'Produto Exemplo',
                              unitario: 'R$ 1,00',
                              qtd: '1.000',
                              seguro: 'R$ 0,00',
                              frete: 'R$ 0,00',
                              outrasdesp: 'R$ 0,00',
                              vlrtotal: 'R$ 1,00',
                              csticms: '666',
                              cstpis: '66',
                              cstcofins: '66',
                              cstipi: '',
                              cfop: '6666',
                              ncm: '666',
                            },
                            {
                              codigo: '4',
                              produto: 'Produto Exemplo',
                              unitario: 'R$ 1,00',
                              qtd: '1.000',
                              seguro: 'R$ 0,00',
                              frete: 'R$ 0,00',
                              outrasdesp: 'R$ 0,00',
                              vlrtotal: 'R$ 1,00',
                              csticms: '666',
                              cstpis: '66',
                              cstcofins: '66',
                              cstipi: '',
                              cfop: '6666',
                              ncm: '666',
                            },
                          ]}
                          options={[
                            {
                              label: 'Remover',
                              onPress: item => {
                                // REMOVER ITEM
                              },
                            },
                          ]}
                        />

                        <InputsContainer>
                          <InputContainer>
                            <span>Produto</span>
                            <div>
                              <Input
                                name="prd"
                                placeholder="Pesquise por Código ou Nome do Produto"
                                disabled
                              />
                              <p style={{ marginLeft: 15 }}>
                                <FindProduto
                                  onlySearch
                                  onSelectValue={selecionados => {
                                    if (selecionados.length) {
                                      console.log('selecionados', selecionados);
                                    }
                                  }}
                                />
                              </p>
                            </div>
                          </InputContainer>

                          <InputContainer width={200}>
                            <span>Quantidade</span>
                            <div>
                              <Input
                                name="quantidade"
                                placeholder="Quantidade"
                              />
                            </div>
                          </InputContainer>
                          <div>
                            <Button type="submit" background={colors.green}>
                              Adicionar
                            </Button>
                          </div>
                        </InputsContainer>
                      </Area>
                    </Tab>

                    <Tab
                      name="Transp./Frete"
                      active={activeTab === 'Transp./Frete'}
                    >
                      <Area>
                        <InputsContainer>
                          <InputContainer>
                            <span>Modalide de Frete</span>
                            <div>
                              <Select
                                name="TRANSP_modFrete"
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value:
                                      'Por Conta do Emitente/Remetente (CIF)',
                                    label:
                                      'Por Conta do Emitente/Remetente (CIF)',
                                    cor: '',
                                  },
                                  {
                                    value: 'Por Conta do Destinatário (FOB)',
                                    label: 'Por Conta do Destinatário (FOB)',

                                    cor: '',
                                  },
                                  {
                                    value: 'Por Conta de Terceiros',
                                    label: 'Por Conta de Terceiros',

                                    cor: '',
                                  },
                                  {
                                    value:
                                      'Transporte Própio por Conta do Remetente',
                                    label:
                                      'Transporte Própio por Conta do Remetente',

                                    cor: '',
                                  },
                                  {
                                    value:
                                      'Transporte Própio por Conta do Destinatário',
                                    label:
                                      'Transporte Própio por Conta do Destinatário',

                                    cor: '',
                                  },
                                  {
                                    value: 'Sem frete',
                                    label: 'Sem frete',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                        <Divisor>Dados do transportador</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Nome Transportador</span>
                            <div>
                              <Input name="TRANSP_xNome" placeholder="" />
                            </div>
                          </InputContainer>
                          {/* <InputContainer>
                            <span>Razão Social Transportador</span>
                            <div>
                              <Input
                                name="TRANSP_xNome"
                                placeholder="Razão Social Transportador"
                              />
                            </div>
                          </InputContainer> */}
                          {/* <InputContainer>
                            <span>Complemento para o Nome</span>
                            <div>
                              <Input
                                name="transpComplementoNome"
                                placeholder="Complemento para o Nome"
                              />
                            </div>
                          </InputContainer> */}
                          <InputContainer>
                            <span>CPF/CNPJ</span>
                            <div>
                              <Input name="TRANSP_CNPJ" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>IE</span>
                            <div>
                              <Input name="TRANSP_IE" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Marca</span>
                            <div>
                              <Input name="TRANSP_marca" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Logradouro</span>
                            <div>
                              <Input name="TRANSP_xEnder" placeholder="" />
                            </div>
                          </InputContainer>
                          {/* <InputContainer>
                            <span>Número</span>
                            <div>
                              <Input name="transpNumero" placeholder="Número" />
                            </div>
                          </InputContainer> */}

                          <InputContainer>
                            <span>Cidade</span>
                            <div>
                              <Input name="TRANSP_xMun" placeholder="" />
                            </div>
                          </InputContainer>

                          <InputContainer>
                            <span>UF</span>
                            <div>
                              <Select
                                name="TRANSP_UF"
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value: '1',
                                    label: '-- OPÇÃO --',
                                    cor: '',
                                  },
                                  {
                                    value: '2',
                                    label: '-- OPÇÃO --',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        {/* <Space /> */}

                        <InputsContainer>
                          <InputContainer>
                            <span>Quantidade Volume</span>
                            <div>
                              <Input name="TRANSP_qVol" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Espécie volume</span>
                            <div>
                              <Input name="TRANSP_esp" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Peso Líquido</span>
                            <div>
                              <Input name="TRANSP_pesoL" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Peso Bruto</span>
                            <div>
                              <Input name="TRANSP_pesoB" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        {/* <TabMenu>
                          <TabLink
                            name="Veículo e Reboque"
                            active={activeTabTransp === 'Veículo e Reboque'}
                            onSelectTab={onSelectTabTransp}
                          />
                          <TabLink
                            name="Volumes Transportados"
                            active={activeTabTransp === 'Volumes Transportados'}
                            onSelectTab={onSelectTabTransp}
                          />
                        </TabMenu> */}

                        {/* <Tab
                          name="Veículo e Reboque"
                          active={activeTabTransp === 'Veículo e Reboque'}
                        >
                          <Divisor>Dados do Veículo </Divisor>

                          <InputsContainer>
                            <InputContainer>
                              <span>Placa Veículo</span>
                              <div>
                                <Input
                                  name="transpVolPlacaVeiculo"
                                  placeholder="Placa Veículo"
                                />
                              </div>
                            </InputContainer>

                            <InputContainer>
                              <span>RNTC</span>
                              <div>
                                <Input
                                  name="transpVolRNTC"
                                  placeholder="RNTC"
                                />
                              </div>
                            </InputContainer>

                            <InputContainer>
                              <span>UF</span>
                              <div>
                                <Select
                                  name="transpVolUf"
                                  loading={false}
                                  defaultValue={{
                                    value: '',
                                    label: '',
                                    cor: '',
                                  }}
                                  options={[
                                    {
                                      value: '1',
                                      label: '-- OPÇÃO --',
                                      cor: '',
                                    },
                                    {
                                      value: '2',
                                      label: '-- OPÇÃO --',

                                      cor: '',
                                    },
                                  ]}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>

                          <Divisor>Reboques do Veículo </Divisor>

                          <InputsContainer>
                            <InputContainer>
                              <span>Placa Veículo</span>
                              <div>
                                <Input
                                  name="transpRbqPlacaVeiculo"
                                  placeholder="Placa Veículo"
                                />
                              </div>
                            </InputContainer>

                            <InputContainer>
                              <span>RNTC</span>
                              <div>
                                <Input
                                  name="transpRbqRNTC"
                                  placeholder="RNTC"
                                />
                              </div>
                            </InputContainer>

                            <InputContainer>
                              <span>UF</span>
                              <div>
                                <Select
                                  name="transpRbqUf"
                                  loading={false}
                                  defaultValue={{
                                    value: '',
                                    label: '',
                                    cor: '',
                                  }}
                                  options={[
                                    {
                                      value: '1',
                                      label: '-- OPÇÃO --',
                                      cor: '',
                                    },
                                    {
                                      value: '2',
                                      label: '-- OPÇÃO --',

                                      cor: '',
                                    },
                                  ]}
                                />
                              </div>
                            </InputContainer>
                          </InputsContainer>
                        </Tab> */}

                        {/* <Tab
                          name="Volumes Transportados"
                          active={activeTabTransp === 'Volumes Transportados'}
                        >
                          <h1>--- Volumes Transportados ---</h1>
                        </Tab> */}
                      </Area>
                    </Tab>

                    <Tab name="Faturas" active={activeTab === 'Faturas'}>
                      <Area>
                        <InputsContainer>
                          <InputContainer>
                            <span>Nº da Fatura</span>
                            <div>
                              <Input name="FAT_nFat" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Total da Fatura R$</span>
                            <div>
                              <Input name="FAT_vOrig" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor do desconto R$</span>
                            <div>
                              <Input name="FAT_vDesc" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor liquido R$</span>
                            <div>
                              <Input name="FAT_vLiq" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Tipo pagamento</span>
                            <div>
                              <Input name="PAG_tPag" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Nome do tipo do pagamento</span>
                            <div>
                              <Input name="PAG_xPag" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor pago R$</span>
                            <div>
                              <Input name="PAG_vPag" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Tipo integração</span>
                            <div>
                              <Input name="PAG_tpIntegra" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Nº da duplicata</span>
                            <div>
                              <Input name="DUP_nDup" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Data Vencimento duplicata</span>
                            <div>
                              <Input name="DUP_dVenc" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor duplicata</span>
                            <div>
                              <Input name="DUP_vDup" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Area>
                    </Tab>

                    <Tab
                      name="Docs. refêrenciados"
                      active={activeTab === 'Docs. refêrenciados'}
                    >
                      <Area>
                        <InputsContainer>
                          <InputContainer>
                            <span>Tipo de Documento Referenciado</span>
                            <div>
                              <Select
                                name="docsRefTipoDocumento"
                                loading={false}
                                defaultValue={{
                                  value: '',
                                  label: '',
                                  cor: '',
                                }}
                                options={[
                                  {
                                    value: '1',
                                    label: '-- OPÇÃO --',
                                    cor: '',
                                  },
                                  {
                                    value: '2',
                                    label: '-- OPÇÃO --',

                                    cor: '',
                                  },
                                ]}
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                        <InputsContainer>
                          <InputContainer>
                            <span>Chave de Acesso da NFe Referências</span>
                            <div>
                              <Input
                                name="docsRefChaveAcessoNfe"
                                placeholder="Chave de Acesso da NFe Referências"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Area>
                    </Tab>

                    <Tab
                      name="Infos. Complementares"
                      active={activeTab === 'Infos. Complementares'}
                    >
                      <Area>
                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>Informações Complementares</span>
                            <div>
                              <Textarea name="INFADIC_infCpl" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        {/* <InputsContainer>
                          <InputContainer>
                            <span>
                              Não exibir informações de Impostos Automaticamente
                            </span>
                            <div className="switch">
                              <Switch name="infosComplExibirAuto" />
                            </div>
                          </InputContainer>
                        </InputsContainer> */}

                        <InputsContainer>
                          <InputContainer disabled={formLoading}>
                            <span>
                              Informações Complementares de Interesse do Fisco
                            </span>
                            <div>
                              <Textarea name="INFADIC_infCpl" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </Area>
                    </Tab>

                    <Tab name="Totais" active={activeTab === 'Totais'}>
                      <Area>
                        <InputsContainer>
                          <InputContainer width={300}>
                            <span>Total da Nota R$</span>
                            <div>
                              <Input name="TOTAL_vNF" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>Produtos</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Total Produtos R$</span>
                            <div>
                              <Input name="TOTAL_vProd" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Desconto Produtos R$</span>
                            <div>
                              <Input name="TOTAL_vDesc" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor total tributado R$</span>
                            <div>
                              <Input name="TOTAL_vTotTrib" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor outros R$</span>
                            <div>
                              <Input name="TOTAL_vOutro" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor Frete R$</span>
                            <div>
                              <Input name="TOTAL_vFrete" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor Seguro R$</span>
                            <div>
                              <Input name="TOTAL_vSeg" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>ICMS / ICMS ST</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Base Cálculo ICMS</span>
                            <div>
                              <Input name="TOTAL_vBC" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor ICMS</span>
                            <div>
                              <Input name="TOTAL_vICMS" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Base Cálculo ICMS ST</span>
                            <div>
                              <Input name="TOTAL_vBCST" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor ICMS ST</span>
                            <div>
                              <Input name="TOTAL_vST" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor ICMS ST Desonerada</span>
                            <div>
                              <Input
                                name="TOTAL_vICMSDesonsonerado"
                                placeholder=""
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>PIS / COFINS / IPI / II</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Valor PIS</span>
                            <div>
                              <Input name="TOTAL_vPIS" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor COFINS</span>
                            <div>
                              <Input name="TOTAL_vCOFINS" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor IPI</span>
                            <div>
                              <Input name="TOTAL_vIPI" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor IPI Devolvido</span>
                            <div>
                              <Input name="TOTAL_vIPIDevol" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor II</span>
                            <div>
                              <Input name="TOTAL_vII" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <Divisor>Fundo Combate a Pobreza (FCP)</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Valor FCP</span>
                            <div>
                              <Input name="TOTAL_vFCP" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor FCP ST</span>
                            <div>
                              <Input name="TOTAL_vFCPST" placeholder="" />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Valor FCP ST (Retido anteriormente)</span>
                            <div>
                              <Input name="TOTAL_vFCPSTRet" placeholder="" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        {/* <Divisor>Pagamentos</Divisor>

                        <InputsContainer>
                          <InputContainer>
                            <span>Pagamentos</span>
                            <div>
                              <Input
                                name="vlPagamentos"
                                placeholder="Pagamentos"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer>
                            <span>Troco</span>
                            <div>
                              <Input name="vlTroco" placeholder="Troco" />
                            </div>
                          </InputContainer>
                        </InputsContainer>

                        <InputsContainer>
                          <InputContainer>
                            <span>Nota para Orgão Publico Federal</span>
                            <div className="switch">
                              <Switch name="vlNotaOrgaoFederal" />
                            </div>
                          </InputContainer>
                        </InputsContainer> */}
                      </Area>
                    </Tab>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/nfes">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={limparFormulario}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default NFe;
