import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ImCheckmark } from 'react-icons/im';
import { FaTablet } from 'react-icons/fa';
import { RiTableAltLine } from 'react-icons/ri';

import { Button } from '~/components';

import {
  Container,
  Content,
  Mode,
  Opt,
  Label,
  IC,
  ColumnSize,
  Footer,
} from './styles';

import Redimensionamento from './Redimensionamento';

import { PdfProps } from './interface';

const PDF: React.FC<PdfProps> = ({
  aoAplicarConfiguracao,
  configuracao,
  aoExportarRelatorio,
}) => {
  const { colors } = useTheme();

  const colunas = configuracao.ordenar
    .filter(ordem => ordem.enable)
    .map(ordem => ordem.label);

  const [redimensionamentoVisibilidade, setRedimensionamentoVisibilidade] =
    useState(false);

  const alterarModo = (modo: string) => {
    aoAplicarConfiguracao(
      {
        ...configuracao,
        modo,
      },
      false,
    );
  };

  const abrirRedimensionamento = () => {
    setRedimensionamentoVisibilidade(true);
  };

  const fecharRedimensionamento = () => {
    setRedimensionamentoVisibilidade(false);
  };

  const gerarMensagemRedimensionamento = () => {
    const colunasEmTexto = colunas.join(',');

    return `Colunas selecionadas: ${colunasEmTexto}. Redimensione-as de acordo com sua preferência e otimize seu relatório`;
  };

  return (
    <Container>
      <Content>
        {redimensionamentoVisibilidade ? (
          <Redimensionamento
            configuracao={configuracao}
            aoFecharRedimensionamento={fecharRedimensionamento}
            aoAplicarConfiguracao={aoAplicarConfiguracao}
          />
        ) : null}

        <Mode>
          <Opt onClick={() => alterarModo('RETRATO')}>
            <span>
              <FaTablet size={64} color={colors.label} />
            </span>
            <Label>Retrato</Label>
            <IC>
              {configuracao.modo === 'RETRATO' ? (
                <ImCheckmark size={42} color={colors.green} />
              ) : null}
            </IC>
          </Opt>
          <Opt onClick={() => alterarModo('PAISAGEM')}>
            <span className="rotate">
              <FaTablet size={64} color={colors.label} />
            </span>
            <Label>Paisagem</Label>
            <IC>
              {configuracao.modo === 'PAISAGEM' ? (
                <ImCheckmark size={42} color={colors.green} />
              ) : null}
            </IC>
          </Opt>
        </Mode>

        {colunas.length ? (
          <ColumnSize>
            <RiTableAltLine size={72} color={colors.label} />
            <div>
              <p>{gerarMensagemRedimensionamento()}</p>
              <Button
                onClick={abrirRedimensionamento}
                type="button"
                background={colors.grey}
              >
                Redimencionar
              </Button>
            </div>
          </ColumnSize>
        ) : null}

        <Footer>
          <div>
            <Button
              style={{ width: 150 }}
              onClick={() => aoExportarRelatorio(configuracao)}
              type="button"
              background={colors.green}
            >
              Exportar PDF
            </Button>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default PDF;
