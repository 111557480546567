import React from 'react';

import { Container } from './styles';

import { PlateProps } from './interface';

const FormHeader: React.FC<PlateProps> = ({
  title,
  subtitle,
  icon,
  noRadius,
  children,
}) => {
  return (
    <Container $noradius={noRadius}>
      <div>
        <main>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </main>
      </div>
      <menu>{children ? children : null}</menu>
    </Container>
  );
};

export default FormHeader;
