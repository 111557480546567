import styled, { css } from 'styled-components';

export const ACLContainer = styled.div`
  /* float: left; */
  padding: 2rem;
  width: 100%;
  float: left;
  // width: 100%;
  background: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 1024px) {
    padding: 0rem;
  }
`;

export const ACLContent = styled.main`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8rem;
    // padding: 2rem 3.5rem;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    border-radius: ${theme.radio.default};
  `}
`;

export const ACLContentHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    width: 100%;
    align-items: center;
    overflow: hidden;
    border-radius: 0.6rem 0.6rem 0 0;
    padding: 1rem 1.5rem;
    background: ${theme.colors.controlNotFocus};
    border-bottom: 0.1rem solid ${theme.colors.defaultHighlight};

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    main {
      margin: 0 0 0 1.5rem;
    }

    h1 {
      font-size: ${theme.fontSizes.big};
      font-weight: 700;
      color: ${theme.colors.label};
      margin: 0rem;
      padding: 0rem;
    }

    h2 {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.placeholder};
      margin: 0rem;
      padding: 0rem;
    }

    a {
      width: 25%;
      button {
        margin: 0;
      }
    }
  `}
`;

export const ACLPermissions = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
  `}
`;

export const ACLPermissionsBlock = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    margin: 1rem;
    flex-direction: column;
  `}
`;

export const ACLPermissionsTitle = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 1.5rem;
    height: 4.5rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    font-size: 1.5rem;
  `}
`;

export const ACLPermissionsFolders = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
  `}
`;

export const ACLPermissionsEmpty = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 1.1rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
  `}
`;
