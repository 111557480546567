import React from 'react';
import { useDrop } from 'react-dnd';

import { TagDropContainer } from './styles';

import { TagDropProps } from './interface';

const TagDrop: React.FC<TagDropProps> = ({ accept, name, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    drop: () => ({ name }),
    accept,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <TagDropContainer ref={drop} $active={isActive}>
      {children}
    </TagDropContainer>
  );
};

export default TagDrop;
