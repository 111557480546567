import React from 'react';

import { ListScrollContainer, Scroll } from './styles';

import { ListScrollProps } from './interface';

const ListScroll: React.FC<ListScrollProps> = ({ children, width, fixed }) => {
  return (
    <ListScrollContainer $width={width} $fixed={fixed}>
      {fixed ? children : <Scroll>{children}</Scroll>}
    </ListScrollContainer>
  );
};

export default ListScroll;
