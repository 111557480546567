import React from 'react';

import {
  Container,
  Content,
  Table,
  Cards,
  Card,
  CardLine,
  CardProp,
  CardVal,
} from './styles';

import {
  PreviewProps,
  CabecalhoPreviewProps,
  ItemPreviewProps,
} from './interface';

const Preview: React.FC<PreviewProps> = ({
  cabecalhos,
  linhas,
  totalizadores,
  tabelabilidade,
}) => {
  const exibirValorLinha = (
    pCabecalho: CabecalhoPreviewProps,
    pLinha: ItemPreviewProps[],
  ) => {
    let valor = '';
    let contador = 0;
    for (contador = 0; contador <= pLinha.length; contador += 1) {
      const item = pLinha[contador];
      if (item && item.propriedade === pCabecalho.identificador) {
        valor = item.valor;
      }
    }
    return valor;
  };

  if (tabelabilidade) {
    return (
      <Container>
        <Content>
          <Cards>
            {linhas.map((linha, idx) => (
              <React.Fragment key={linha[idx].valor}>
                <Card>
                  {cabecalhos.map(cabecalho => (
                    <React.Fragment key={cabecalho.identificador}>
                      <CardLine>
                        <CardProp>{`${cabecalho.label}:`}</CardProp>
                        <CardVal>{exibirValorLinha(cabecalho, linha)}</CardVal>
                      </CardLine>
                    </React.Fragment>
                  ))}
                </Card>
              </React.Fragment>
            ))}
          </Cards>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Table>
          <thead>
            <tr>
              {cabecalhos.map(cabecalho => (
                <React.Fragment key={cabecalho.identificador}>
                  <td>{cabecalho.label}</td>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {linhas.map((linha, idx) => (
              <React.Fragment key={linha[idx].valor}>
                <tr>
                  {cabecalhos.map(cabecalho => (
                    <React.Fragment key={cabecalho.identificador}>
                      <td>{exibirValorLinha(cabecalho, linha)}</td>
                    </React.Fragment>
                  ))}
                </tr>
              </React.Fragment>
            ))}

            {totalizadores &&
              totalizadores.length &&
              totalizadores.map(totalizador => (
                <React.Fragment key={totalizador.propriedade}>
                  <tr>
                    <td className="totalizador" colSpan={cabecalhos.length - 1}>
                      {totalizador.propriedade}
                    </td>
                    <td>{totalizador.valor}</td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </Content>
    </Container>
  );
};

export default Preview;
