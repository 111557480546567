import React, { useState } from 'react';

import { AiFillDelete } from 'react-icons/ai';

import { useTheme } from 'styled-components';

import { BsFillPuzzleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import List from '~/components/List';

import { BarraLateral, Button, Plate } from '~/components';

import { Container, Content, Area, Left, Right } from './styles';

import Filtros from './Filtros';
import Impressao from './Impressao';

import { EtiquetaImpressaoType } from '~/types';

const Contas: React.FC = () => {
  const { colors } = useTheme();

  const [etiquetasImpressao, setEtiquetasImpressao] = useState<
    EtiquetaImpressaoType[]
  >([]);

  const voltarAoTopo = () => {
    window.scrollTo(0, 0);
  };

  const aoAplicarFiltros = async (pEtiqueta: EtiquetaImpressaoType) => {
    setEtiquetasImpressao([...etiquetasImpressao, pEtiqueta]);

    voltarAoTopo();
  };

  const removerEtiqueta = (pEtiqueta: EtiquetaImpressaoType) => {
    setEtiquetasImpressao([
      ...etiquetasImpressao.filter(etiqueta => etiqueta.id !== pEtiqueta.id),
    ]);
  };

  const limparFilaImpressao = () => {
    setEtiquetasImpressao([]);
  };

  return (
    <Container>
      <Header />

      <Content>
        <Area>
          <Left>
            <BarraLateral barraSimples>
              <Filtros aoAplicarFiltros={aoAplicarFiltros} />

              <Impressao
                etiquetasImpressao={etiquetasImpressao}
                limparFilaImpressao={limparFilaImpressao}
              />
            </BarraLateral>
          </Left>
          <Right>
            <Plate
              title="Impressão de Etiquetas"
              subtitle={
                etiquetasImpressao.length > 1
                  ? `${etiquetasImpressao.length} etiquetas preparadas para impressão`
                  : 'Selecione as etiquetas para impressão'
              }
              icon={() => <BsFillPuzzleFill size={32} color={colors.label} />}
              noRadius
            >
              {etiquetasImpressao.length ? (
                <div>
                  <Button
                    type="button"
                    background={colors.green}
                    style={{ width: 220 }}
                    onClick={limparFilaImpressao}
                  >
                    <AiFillDelete size={32} color={colors.label} />
                    <span style={{ marginLeft: 10 }}>Limpar Tudo</span>
                  </Button>
                </div>
              ) : null}
            </Plate>

            {etiquetasImpressao ? (
              <List
                header={[
                  {
                    column: 'qtd',
                    label: 'QTD.',
                    width: 120,
                  },
                  {
                    column: 'codigo_barras',
                    label: 'CÓDIGO DE BARRAS',
                    width: 300,
                  },
                  {
                    column: 'descricao',
                    label: 'DESCRIÇÃO',
                  },
                  {
                    column: 'preco',
                    label: 'PREÇO',
                    width: 200,
                  },
                ]}
                data={[
                  ...etiquetasImpressao.map(etiqueta => ({
                    ...etiqueta,
                    qtd: `${etiqueta.qtd}X`,
                  })),
                ]}
                options={[
                  {
                    label: 'REMOVER',
                    id: 1,
                    onPress: item => {
                      removerEtiqueta(item);
                    },
                  },
                ]}
              />
            ) : null}
          </Right>
        </Area>
      </Content>
    </Container>
  );
};

export default Contas;
