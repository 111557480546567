import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { RiFilterFill, RiFilterOffFill } from 'react-icons/ri';
import { VscSplitVertical, VscSplitHorizontal } from 'react-icons/vsc';

import { Container, Content, Nav, Control } from './styles';

import Accordion from './Accordion';

import Filtragem1 from './Filtragem/Tipo1';

import Ordenadores from './Ordenadores';
import Agrupadores from './Agrupadores';
import Somatizadores from './Somatizadores';
import Totalizadores from './Totalizadores';

import PDF from './PDF';

import { BarraLateralProps } from './interface';

const BarraLateral: React.FC<BarraLateralProps> = ({
  tipo,
  verticalidade,
  controleDeVerticalidade,
}) => {
  const { colors } = useTheme();

  const [barraVisivel, setBarraVisivel] = useState(true);

  const inverterVisibilidade = () => {
    setBarraVisivel(!barraVisivel);
  };

  return (
    <Container>
      <Nav>
        {barraVisivel ? (
          <Control onClick={controleDeVerticalidade}>
            {verticalidade ? (
              <VscSplitHorizontal size={32} color={colors.label} />
            ) : (
              <VscSplitVertical size={32} color={colors.label} />
            )}
          </Control>
        ) : null}

        <Control onClick={inverterVisibilidade}>
          {barraVisivel ? (
            <RiFilterFill size={32} color={colors.label} />
          ) : (
            <RiFilterOffFill size={32} color={colors.label} />
          )}
        </Control>
      </Nav>
      {barraVisivel ? (
        <Content verticalidade={verticalidade}>
          <Accordion
            nome="FILTROS"
            descricao="Configure os filtros para refinar os resultados"
            modalSize="50rem"
          >
            <>
              {tipo === 'tipo-1' ? <Filtragem1 /> : null}
              {tipo === 'tipo-2' ? <Filtragem1 /> : null}
              {tipo === 'tipo-3' ? <Filtragem1 /> : null}
              {tipo === 'tipo-4' ? <Filtragem1 /> : null}
            </>
          </Accordion>
          <Accordion
            nome="ORDENAÇÃO"
            descricao="Selecione e arraste os campos de ordenação"
            modalSize="50rem"
          >
            <Ordenadores />
          </Accordion>
          <Accordion
            nome="AGRUPAMENTO"
            descricao="Selecione e arraste as opções de agrupamento"
            modalSize="50rem"
          >
            <Agrupadores />
          </Accordion>
          <Accordion
            nome="SOMATIZADORES"
            descricao="Selecione as colunas a serem somatizadas"
            modalSize="50rem"
          >
            <Somatizadores />
          </Accordion>
          <Accordion
            nome="TOTALIZADORES"
            descricao="Selecione e ordene as opções de totalizadores"
            modalSize="50rem"
          >
            <Totalizadores />
          </Accordion>
          <Accordion
            nome="EXPORTAÇÃO"
            descricao="Configure as opções de exportação de gere um PDF do seu relatório"
            modalSize="50rem"
          >
            <PDF />
          </Accordion>
        </Content>
      ) : null}
    </Container>
  );
};

export default BarraLateral;
