import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding: 0 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
`;

export const Control = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  float: left;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    float: left;
    padding-right: 1.5rem;

    h1 {
      font-size: ${theme.fontSizes.big};
    }

    p {
      color: ${theme.colors.grey};
      font-size: ${theme.fontSizes.small};
    }
  `};
`;

export const Opt = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
`;
