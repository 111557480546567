import styled from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  a {
    button {
      width: 100%;
    }
    width: 15%;
    margin-right: 1rem;
  }

  button {
    margin-left: 1rem;
    width: 15%;
  }

  @media (max-width: 1024px) {
    padding: 2rem;

    button,
    a {
      width: auto;
    }
  }
`;
