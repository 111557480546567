import styled, { css } from 'styled-components';

import ScrollArea from 'react-scrollbar';

import { ContainerProps, ContentProps, ScrollProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $visibilidade }) => css`
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${$visibilidade ? '36rem' : '6rem'};
    background: ${theme.colors.background};
    flex: 1;

    @media (max-width: 1024px) {
      width: 100% !important;
      flex: auto !important;
    }
  `};
`;

export const Content = styled.div<ContentProps>`
  ${({ $visibilidade }) => css`
    width: ${$visibilidade ? '36rem' : '6rem'};
    left: 0px;
    top: 8rem;
    position: fixed;
    height: calc(100% - 8rem);
    display: flex;
    flex-direction: column;
    float: left;
    z-index: 1800;

    @media (max-width: 1024px) {
      position: inherit;
      height: auto;
      width: 100% !important;
      z-index: inherit;
    }
  `};
`;

export const Nav = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    float: left;
    width: 100%;
    padding: 0.5rem;
    background: ${theme.colors.overlay};
    border: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};

    @media (max-width: 1024px) {
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      border-radius: 100%;
      width: 6rem;
      height: 6rem;
      z-index: 1800;
    }
  `};
`;

export const Control = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
  padding: 1rem;
`;

export const Scroll = styled.div<ScrollProps>`
  ${({ theme, $visibilidade }) => css`
    display: ${$visibilidade ? 'flex' : 'none'};
    float: left;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex: 1;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    border-top: 0.1rem solid;
    border-right: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};

    .main-scroll-scrollarea {
      float: left;
      height: 100%;
      width: 100%;
    }
    .main-scroll-scrollarea-content {
      width: 100%;
      float: left;
    }
  `}
`;

export const ScrollContent = styled(ScrollArea).attrs({
  className: 'main-scroll-scrollarea',
  contentClassName: 'main-scroll-scrollarea-content',
  contentStyle: {
    paddingBottom: 0,
  },
})``;
