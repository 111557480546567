import React from 'react';

import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';
import { MdAttachMoney } from 'react-icons/md';
import { ResumeCardProps } from './interface';
import { Container } from './styles';

const ICONS = {
  entrada: <FaArrowCircleUp size={28} />,
  saida: <FaArrowCircleDown size={28} />,
  saldo: <MdAttachMoney size={28} />,
};

const Card: React.FC<ResumeCardProps> = ({ title, value, type }) => {
  return (
    <Container type={type}>
      {ICONS[type]}
      <div>
        <strong>{title}</strong>
        <span>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value)}
        </span>
      </div>
    </Container>
  );
};

export default Card;
