import styled, { keyframes, css } from 'styled-components';

import { HeaderSummaryContentProps } from './interface';

export const HeaderSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderSummaryControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
`;

export const HeaderSummaryControlIc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  padding: 1rem;
  border-radius: 100%;
  transition: all 0.5s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHighlight};
  }
`;

const visibleContentAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const invisibleContentAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const HeaderSummaryContent = styled.div<HeaderSummaryContentProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    position: fixed;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45rem;
    height: 100%;
    background: ${theme.colors.background};
    border-left: 0.1rem solid ${theme.colors.default};
    top: 0rem;
    z-index: 3000;
    right: 0rem;
    box-shadow: ${theme.shadows.default};
    transform: scale(1);
    visibility: ${$visible ? '$visible' : 'hidden'};

    animation: ${$visible ? visibleContentAnimation : invisibleContentAnimation}
      0.5s forwards;

    span {
      font-size: 1.5rem;
    }
  `}
`;
