import styled, { css } from 'styled-components';
import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 30rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;

export const Area = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  padding: 0rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Normal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;
`;

export const Evidence = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem 0;
    border: 0.2rem solid ${theme.colors.controlNotFocus};
    border-radius: 0.6rem;
    margin: 2rem 1rem;
    margin-bottom: 2rem;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.background};
    border: 0.2rem solid ${theme.colors.controlNotFocus};
    border-radius: 0.6rem;
    align-self: center;
    padding: 1rem 1.5rem;
    margin: -3rem 0 0 0;
  `}
`;
