import React from 'react';

import { Container } from './styles';

import { ButtonProps } from './interface';

const Button: React.FC<ButtonProps> = ({
  children,
  background,
  loading,
  ...rest
}) => {
  return (
    <Container
      type="button"
      $background={background}
      {...rest}
      disabled={rest.disabled}
    >
      {loading ? 'Aguarde...' : children}
    </Container>
  );
};

export default Button;
