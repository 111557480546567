import React from 'react';

import { Container, Wrapper } from './styles';

import { CardProps } from './interface';

const Card: React.FC<CardProps> = ({ transition, variant, children }) => {
  return (
    <Container variants={variant} transition={transition}>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default Card;
