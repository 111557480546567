import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { IoMdClose } from 'react-icons/io';

import { HiSearch } from 'react-icons/hi';

import { CgRadioCheck, CgRadioChecked } from 'react-icons/cg';

import api from '~/services/api';

import {
  ContentButtons,
  FinderContainer,
  FinderContent,
  FinderEmpty,
  FinderItem,
  FinderLabel,
  FinderList,
  FinderLoading,
  FinderScroll,
} from './styles';

import { Button } from '~/components';
import { FinderProps } from './interface';

const Finder: React.FC<FinderProps> = ({
  placeholderValue,
  placeholderInput,
  onSelectValue,
  defaultValue,
  keyRef,
  labelRef,
  endpointRef,
  columnSearchRef,
}) => {
  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Array<any> | null>(null);
  const [value, setValue] = useState('');
  const [key, setKey] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue.value);
      setKey(defaultValue.key);
    }
  }, [defaultValue]);

  const onRequestOpen = useCallback(() => {
    setVisible(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
    // inputRef.current?.focus();
  }, []);

  const onRequestClose = useCallback(() => {
    onSelectValue(key, value);
    setVisible(false);
  }, [onSelectValue, setVisible, key, value]);

  const clear = useCallback(() => {
    setValue('');
    setKey('');
    onSelectValue('', '');
    setResults(null);
  }, [onSelectValue]);

  const onRequestClear = () => {
    clear();
  };

  const onRequestCancel = () => {
    clear();
    setVisible(false);
  };

  const onRequestSearch = useCallback(
    async (currentSearchQuery: string) => {
      try {
        setLoading(true);
        const response = await api.get(
          `${endpointRef}?${columnSearchRef}=${currentSearchQuery}`,
        );
        if (response.data) {
          const { data } = response.data;
          setResults(data);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [endpointRef, columnSearchRef],
  );

  const onRequestItem = (currentKey: string, currentValue: string) => {
    setValue(currentValue);
    setKey(currentKey);
  };

  const onRequestUnsearch = () => {
    setSearchQuery('');
  };

  const handlerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      if (searchQuery.length >= 2) {
        onRequestSearch(searchQuery);
      }
      e.preventDefault();
    }
  };

  const handlerOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e && e.target) {
        setSearchQuery(e.target.value);
      }
    },
    [],
  );

  const handlerHideContent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  // useEffect(() => {
  //   onRequestSearch('');
  // }, [onRequestSearch]);

  return (
    <>
      <FinderContainer $visible={visible}>
        <FinderContent ref={ref}>
          <header>
            <div>
              <input
                ref={inputRef}
                placeholder={placeholderInput}
                value={searchQuery}
                onChange={handlerOnChange}
                onKeyDown={handlerKeyDown}
              />

              {searchQuery ? (
                <button type="button" onClick={onRequestUnsearch}>
                  <IoMdClose
                    size={30}
                    color={colors.white}
                    onClick={onRequestClear}
                  />
                </button>
              ) : null}
              <button
                type="button"
                onClick={() => onRequestSearch(searchQuery)}
              >
                <HiSearch
                  size={24}
                  color={colors.white}
                  onClick={onRequestOpen}
                />
              </button>
            </div>
            {/*
            <button
              className="finder-close"
              type="button"
              onClick={onRequestClose}
            >
              <AiOutlineCloseCircle size={32} color={colors.white} />
            </button> */}
          </header>
          <main>
            {loading ? (
              <FinderLoading>
                <span>
                  <AiOutlineLoading3Quarters size={32} color={colors.white} />
                </span>
              </FinderLoading>
            ) : null}

            {!loading &&
            !results?.length &&
            searchQuery.length > 0 &&
            results !== null ? (
              <FinderEmpty>Nenhum resultado encontrado.</FinderEmpty>
            ) : null}

            {!loading && results === null ? (
              <FinderEmpty>Pressione enter para buscar.</FinderEmpty>
            ) : null}

            {results?.length ? (
              <FinderList>
                <FinderScroll>
                  {results?.map(item => (
                    <React.Fragment key={item}>
                      <FinderItem
                        onClick={() =>
                          onRequestItem(`${item[keyRef]}`, `${item[labelRef]}`)
                        }
                        $active={key === `${item[keyRef]}`}
                      >
                        {key === `${item[keyRef]}` ? (
                          <CgRadioChecked size={26} color={colors.primary} />
                        ) : (
                          <CgRadioCheck size={26} color={colors.white} />
                        )}
                        <span>{item[labelRef]}</span>
                      </FinderItem>
                    </React.Fragment>
                  ))}
                </FinderScroll>
              </FinderList>
            ) : null}
          </main>
          <ContentButtons>
            <Button
              type="button"
              background={colors.grey}
              onClick={onRequestCancel}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              background={colors.green}
              onClick={onRequestClose}
            >
              OK
            </Button>
          </ContentButtons>
        </FinderContent>
      </FinderContainer>
      <FinderLabel>
        <div>
          {!visible ? (
            <span className={value ? 'isok' : 'empty'}>
              {value || placeholderValue}
            </span>
          ) : (
            <span className="empty" />
          )}
          {value ? (
            <IoMdClose
              size={32}
              color={colors.label}
              onClick={onRequestClear}
            />
          ) : null}
          <HiSearch size={24} color={colors.label} onClick={onRequestOpen} />
        </div>
      </FinderLabel>
    </>
  );
};

export default Finder;
