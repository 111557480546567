import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { GiPriceTag } from 'react-icons/gi';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { EmpresaType, SelectType } from '~/types';

import { ParamsProps, TabelaDePrecoFormData } from './interface';
import { handlerErrors } from '~/utils/error';

const TabelaDePreco: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id, ativo: true });
  const [registerId, setRegisterId] = useState('0');

  const [empresas, setEmpresas] = useState<Array<SelectType>>([]);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: TabelaDePrecoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
          empresa_id: Yup.string().required('Selecione uma empresa.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `tabelas-preco${registerId !== '0' ? `/${registerId}` : ''}`,
            {
              empresa_id: data.empresa_id,
              descricao: data.descricao,
              ativo: data.ativo,
            },
          );

          history(`/tabela-de-preco/${response.data.id}`);
          setRegisterId(response.data.id);

          setFormLoading(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    formRef.current?.reset();
    setRegisterData({ id: '0', ativo: true });
  }

  useEffect(() => {
    async function fetchEmpresas() {
      try {
        const response = await api.get(`empresas`);
        const empresasFetched: Array<SelectType> = [];

        response.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }

      if (id && id !== '0') {
        const response = await api.get(`tabelas-preco/${id}`);
        setRegisterData({
          ...response.data,
          empresa_id: {
            value: response.data.empresa_id,
            label: response.data.empresa.fantasia,
          },
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    const initialize = async () => {
      try {
        await fetchEmpresas();
        await loadRegister();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    initialize();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Tabela de Preço"
                      subtitle="Adicione tabelas de preços nas empresas"
                      icon={() => <GiPriceTag size={32} color={colors.label} />}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Empresas</span>
                        <div>
                          <Select
                            name="empresa_id"
                            loading={loading}
                            options={empresas}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe o nome da tabela de preço"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <>
                        <InputContainer disabled={formLoading}>
                          <span>Ativo?</span>
                          <div className="switch">
                            <Switch name="ativo" />
                          </div>
                        </InputContainer>
                      </>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/tabelas-de-preco">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default TabelaDePreco;
