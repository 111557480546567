import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import swal from 'sweetalert2';

import { useTheme } from 'styled-components';
import { FiUsers } from 'react-icons/fi';

import api from '~/services/api';

import Header from '~/components/Header';
import List from '~/components/List';
import Modal from '~/components/Modal';

import { Plate, Button, Loader } from '~/components';

import ConfiguracaoDoParceiro from './ConfiguracaoDoParceiro';

import { Container, Content } from './styles';

import { useToast } from '~/hooks/toast';

import { ParceiroType, ConfiguracaoParceiroType } from '~/types';

import { ParamsProps } from './interface';

const Partners: React.FC = () => {
  const { colors } = useTheme();

  const { id } = useParams<ParamsProps>();

  const { addToast } = useToast();

  const [parceiroConfiguracao, setParceiroConfiguracao] =
    useState<ParceiroType | null>(null);

  const [cfgModalVisibilidade, setCfgModalVisibilidade] = useState(false);
  const [configuracaoParceiro, setConfiguracaoParceiro] =
    useState<ConfiguracaoParceiroType | null>(null);

  const [loading, setLoading] = useState(true);

  const [configuracoesDeParceiros, setConfiguracoesDeParceiros] = useState<
    ConfiguracaoParceiroType[]
  >([]);

  const abrirConfiguracaoParceiro = (
    pConfiguracaoParceiro: ConfiguracaoParceiroType | null,
  ) => {
    setConfiguracaoParceiro(pConfiguracaoParceiro);
    setCfgModalVisibilidade(true);
  };

  const buscarConfiguracoesDoParceiro = useCallback(async () => {
    try {
      if (parceiroConfiguracao) {
        setLoading(true);
        const response = await api.get(
          `parceiros/config?parceiro_id=${parceiroConfiguracao.id}`,
        );

        const dadosRetornados = response.data;
        if (dadosRetornados && dadosRetornados.data) {
          const { data } = dadosRetornados;
          setConfiguracoesDeParceiros(data);
        }

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  }, [parceiroConfiguracao]);

  const fecharConfiguracaoParceiro = () => {
    setCfgModalVisibilidade(false);
    setConfiguracaoParceiro(null);
    buscarConfiguracoesDoParceiro();
  };

  const removerConfiguracaoDoParceiro = useCallback(
    (pConfiguracaoDoParceiro: ConfiguracaoParceiroType) => {
      swal
        .fire({
          title: `Deseja realmente remover a configuração do parceiro, ID: "${pConfiguracaoDoParceiro.id}"?`,
          text: `Ao confirmar, o a configuração do parceiro será removida!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              await api.delete(
                `parceiros/config/${pConfiguracaoDoParceiro.id}`,
              );
              await buscarConfiguracoesDoParceiro();
            } catch (e) {
              // console.log(e);
            }
            addToast({
              type: 'success',
              title: 'Concluido',
              description: `Configuração do parceiro "${pConfiguracaoDoParceiro.id}" deletada com sucesso!`,
            });
          }
        });
    },
    [buscarConfiguracoesDoParceiro, addToast],
  );

  useEffect(() => {
    async function carregarParceiro() {
      try {
        const response = await api.get(`parceiros/${id}`);
        const { data } = response;
        setParceiroConfiguracao(data);
      } catch (e) {
        setParceiroConfiguracao(null);
      }
    }
    carregarParceiro();
  }, [id]);

  useEffect(() => {
    buscarConfiguracoesDoParceiro();
  }, [buscarConfiguracoesDoParceiro]);

  if (!parceiroConfiguracao) {
    return <Loader visible={loading} />;
  }

  return (
    <Container>
      <Loader visible={loading} />
      <Header />
      <Content>
        <Plate
          title={`${parceiroConfiguracao.nome_fantasia}`}
          subtitle="Gerencie as configurações deste parceiro!"
          icon={() => <FiUsers size={32} color={colors.label} />}
        >
          <Link to="/parceiros">
            <Button
              type="button"
              background={colors.grey}
              style={{ marginRight: 10 }}
            >
              Voltar
            </Button>
          </Link>
          <Button
            onClick={() => abrirConfiguracaoParceiro(null)}
            type="button"
            background={colors.green}
          >
            Adicionar
          </Button>
        </Plate>

        {cfgModalVisibilidade && (
          <Modal
            title={`${parceiroConfiguracao.nome_fantasia}`}
            visible
            onRequestClose={fecharConfiguracaoParceiro}
            width="960px"
          >
            <ConfiguracaoDoParceiro
              pParceiro={parceiroConfiguracao}
              pConfiguracaoParceiro={configuracaoParceiro}
              onRequestClose={fecharConfiguracaoParceiro}
            />
          </Modal>
        )}

        {configuracoesDeParceiros ? (
          <List
            onFetch={buscarConfiguracoesDoParceiro}
            header={[
              {
                column: 'id',
                label: 'CÓDIGO',
                width: 50,
                isNumeric: true,
              },
              {
                column: 'empresa',
                label: 'EMPRESA',
              },
              {
                column: 'tabela',
                label: 'TABELA DE PREÇO',
              },
              {
                column: 'porcentagem_desconto_maximo',
                label: 'DESCONTO MÁXIMO',
              },
              {
                column: 'porcentagem_acrescimo_maximo',
                label: 'ACRÉSCIMO MÁXIMO',
              },
              {
                column: 'porcentagem_comissao',
                label: 'COMISSÃO',
              },
            ]}
            data={[
              ...configuracoesDeParceiros.map(cConfiguracaoParceiro => ({
                ...cConfiguracaoParceiro,
                empresa: `${cConfiguracaoParceiro.empresa?.fantasia || ''}`,
                tabela: `${
                  cConfiguracaoParceiro.tabela_preco?.descricao || ''
                }`,
                porcentagem_desconto_maximo: `${cConfiguracaoParceiro.porcentagem_desconto_maximo}%`,
                porcentagem_acrescimo_maximo: `${cConfiguracaoParceiro.porcentagem_acrescimo_maximo}%`,
                porcentagem_comissao: `${cConfiguracaoParceiro.porcentagem_comissao}%`,
              })),
            ]}
            options={[
              {
                id: 1,
                label: 'Editar',
                onPress: item => {
                  abrirConfiguracaoParceiro(item);
                },
              },
              {
                id: 2,
                label: 'Remover',
                onPress: item => {
                  removerConfiguracaoDoParceiro(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default Partners;
