import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { IoMdClose } from 'react-icons/io';

import { HeaderSearchCacheContent, Container } from './styles';
import api from '~/services/api';
import { useAuth } from '~/hooks/auth';

import { HeaderSearchCacheProps, TagProps } from './interface';

const HeaderSearchCache: React.FC<HeaderSearchCacheProps> = ({
  showCacheTools,
}) => {
  const [tools, setTools] = useState<TagProps[]>([]);
  const history = useNavigate();
  const { colors } = useTheme();
  const { user } = useAuth();

  const loadTools = useCallback(async () => {
    try {
      const response = await api.get('/ferramentas', {
        headers: {
          parceiro_id: user.parceiro_id,
        },
      });
      setTools(response.data);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [user]);

  useEffect(() => {
    if (showCacheTools) {
      loadTools();
    }
  }, [loadTools, showCacheTools]);

  const handleToolRemove = useCallback(
    async (tool: TagProps) => {
      try {
        await api.delete(`/ferramentas/${tool.id}`);
        loadTools();
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [loadTools],
  );

  const handlePage = useCallback(
    async (item: TagProps) => {
      try {
        await api.post('ferramentas', {
          parceiro_id: user.parceiro_id,
          ...item,
        });
        // eslint-disable-next-line no-empty
      } catch {}
      history(item.page);
    },
    [history, user],
  );

  return (
    <HeaderSearchCacheContent>
      <ul>
        <h3>Últimas ferramentas acessadas por você</h3>
        {/* <h4>Confira as útlimas ferramentas acessadas por você</h4> */}
        {tools.map(tool => {
          return (
            <React.Fragment key={tool.id}>
              <Container>
                <button
                  className="btSearch"
                  type="button"
                  key={tool.id}
                  onClick={() => handlePage(tool)}
                >
                  <span>{tool.tag}</span>
                </button>
                <button
                  className="btRemove"
                  type="button"
                  onClick={() => handleToolRemove(tool)}
                >
                  <IoMdClose size={22} color={colors.white} />
                </button>
              </Container>
            </React.Fragment>
          );
        })}
        {tools.length === 0 && <span className="toolsEmpty">Nenhuma!</span>}
      </ul>
    </HeaderSearchCacheContent>
  );
};

export default HeaderSearchCache;
