import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { List, arrayMove } from 'react-movable';

import { Button } from '~/components';

import { Container, Content, Area, Item, Control, Footer } from './styles';

import { OrdenadoresProps } from './interface';

const Ordenadores: React.FC<OrdenadoresProps> = () => {
  const { colors } = useTheme();

  const [itens, setItens] = useState<string[]>([
    'Coluna 1',
    'Coluna 2',
    'Coluna 3',
    'Coluna 4',
  ]);

  const [inativos, setInativos] = useState<string[]>([]);

  const verificarInatividade = (pItem: string) => {
    return inativos.includes(pItem);
  };

  const inverterInatividade = (pItem: string) => {
    if (verificarInatividade(pItem)) {
      setInativos([...inativos.filter(cItem => cItem !== pItem)]);
    } else {
      setInativos([...inativos, pItem]);
    }
  };

  return (
    <Container>
      <Content>
        <List
          values={itens}
          onChange={({ oldIndex, newIndex }) =>
            setItens(arrayMove(itens, oldIndex, newIndex))
          }
          renderList={({ children, props }) => (
            <Area {...props}>{children}</Area>
          )}
          renderItem={({ value, props }) => (
            <Item inativo={verificarInatividade(value)} {...props}>
              <Control onClick={() => inverterInatividade(value)}>
                {verificarInatividade(value) ? (
                  <ImCheckboxUnchecked
                    size={42}
                    color={colors.defaultHighlight}
                  />
                ) : (
                  <ImCheckboxChecked size={42} color={colors.label} />
                )}
              </Control>
              {value}
            </Item>
          )}
        />
        <Footer>
          <div>
            <Button type="button" background={colors.green}>
              ORDENAR
            </Button>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Ordenadores;
