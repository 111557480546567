import styled from 'styled-components';

export const ListContainerContainer = styled.table`
  float: left;
  border-spacing: 0rem;
  border-collapse: separate;
  margin: 0rem 0rem 0rem 0;
  min-width: 100%;
  min-height: 100%;
`;
