import styled, { css } from 'styled-components';
import { AnimatedContainer } from '~/components';

import { TagProps } from './interface';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`;

export const TagsContent = styled.div`
  ${({ theme }) => css`
    min-height: 12rem;
    float: left;
    border: solid 0.1rem ${theme.colors.default};
    padding: 1.5rem;
    border-radius: 10px;
    background: ${theme.colors.secondary};
    margin: 1.5rem;
    h4 {
      float: left;
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.placeholder};
      margin: 0;
      font-weight: 500;
      opacity: 0.6;
    }
  `}
`;

export const Tag = styled.div<TagProps>`
  ${({ theme, cor }) => css`
    float: left;
    /* border: 3px solid ${cor || '#333'}; */
    color: ${theme.colors.label};
    padding: 0.8rem;
    text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    background: ${cor};
    border-radius: ${theme.radio.default};
    font-size: 1.4rem;
    font-weight: 700;
    cursor: move;
    text-decoration: none;
    outline: none;
    min-width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      button {
        opacity: 1;
      }
    }

    button {
      opacity: 0;
      cursor: pointer;
      background: none;
      transition: all 0.5s ease;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;

export const LogsToogleControl = styled.button`
  ${({ theme }) => css`
    background: ${theme.colors.defaultHighlight};
    width: 4rem !important;
    height: 4rem;
    border-radius: ${theme.radio.default};
    border: 0.1rem solid ${theme.colors.default};
    box-shadow: 0.1rem 0.1rem 0.5rem ${theme.shadows.default};
    transition: all 0.5 ease;

    &:hover {
      background: ${theme.colors.default};
    }

    svg {
      fill: ${theme.colors.label};
    }
  `}
`;
