import styled, { css } from 'styled-components';

export const LogsListContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    float: left;
  `}
`;

export const LogsListContent = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
  `}
`;
