import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { Container, Footer, InputContainer, InputsContainer } from './styles';

import { FiltragemFormData, FiltragemProps } from './interface';

import { Button, FindParceiro, Loader, Modal, Select } from '~/components';

import api from '~/services/api';

import { EmpresaType, SelectType } from '~/types';

const Filtragem: React.FC<FiltragemProps> = ({
  visible,
  onRequestClose,
  onRequestSave,
  haveRegisters,
}) => {
  const { colors } = useTheme();

  const [loading, setLoading] = useState(true);

  const [initialData, setInitialData] = useState<any | null>(null);
  const [parceiro, setParceiro] = useState({ id: '', descricao: '' });
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data: FiltragemFormData) => {
      const { empresa_id, tipo, status } = data;
      const { id: parceiro_id } = parceiro;
      onRequestSave(empresa_id, parceiro_id || '0', tipo, status);
      onRequestClose();
    },
    [onRequestClose, onRequestSave, parceiro],
  );

  useEffect(() => {
    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        return empresasFetched;
        // eslint-disable-next-line no-empty
      } catch (e) {
        setEmpresas([]);
      }
    };

    const initialize = async () => {
      if (!initialData) {
        const empresasFetched = await loadEmpresas();

        setInitialData({
          empresa_id: {
            value: empresasFetched ? empresasFetched[0]?.value : '',
            label: empresasFetched ? empresasFetched[0]?.label : '',
            color: '',
          },
        });

        setLoading(false);
      }
    };
    initialize();
  }, [initialData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title="Filtros de Contas Correntes"
      width="100rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
          <InputsContainer>
            <InputContainer>
              <span>Empresa</span>
              <div>
                <Select
                  name="empresa_id"
                  loading={loading}
                  options={empresas}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>Parceiro</span>
              <FindParceiro
                placeholderInput="Informe o nome do parceiro"
                placeholderValue="Selecione um parceiro"
                tipos="cliente,fornecedor"
                onSelectValue={(selecionados, descricao) => {
                  if (selecionados[0]) {
                    setParceiro({
                      id: selecionados[0].id,
                      descricao,
                    });
                  }
                }}
              />
            </InputContainer>
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>Tipo</span>
              <div>
                <Select
                  name="tipo"
                  loading={loading}
                  defaultValue={{ value: 'BANCO', label: 'BANCO', cor: '' }}
                  options={[
                    { value: 'BANCO', label: 'BANCO', cor: '' },
                    { value: 'CAIXA', label: 'CAIXA', cor: '' },
                    { value: 'COFRE', label: 'COFRE', cor: '' },
                  ]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>Status</span>
              <div>
                <Select
                  name="status"
                  loading={loading}
                  defaultValue={{
                    value: 'ABERTA',
                    label: 'ABERTA',
                    cor: '#27AE60',
                  }}
                  options={[
                    { value: 'ABERTA', label: 'ABERTA', cor: '#27AE60' },
                    { value: 'FECHADA', label: 'FECHADA', cor: '#EB5757' },
                  ]}
                />
              </div>
            </InputContainer>
          </InputsContainer>

          <Footer>
            {haveRegisters && (
              <div>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
              </div>
            )}
            <div>
              <Button type="submit" background={colors.green}>
                Consultar
              </Button>
            </div>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default Filtragem;
