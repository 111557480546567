import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  AiFillFilter,
  AiOutlineCloseCircle,
  AiOutlineDatabase,
} from 'react-icons/ai';
import { BiArrowToTop } from 'react-icons/bi';
import { FaSyncAlt } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { format } from 'date-fns';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { DatePicker, ErrorMessage, Select } from '~/components';

import LogsList from './LogsList';

// import { formatTheBRDate } from '~/utils/formatDate';

import {
  InputContainer,
  InputsContainer,
  LogsCloseButton,
  LogsContainer,
  LogsContent,
  // LogsToogleControl,
  LogsContentFilters,
  LogsContentFiltersContent,
  LogsContentFiltersFormSubmit,
  Scroll,
  ScrollContent,
} from './styles';

import { ReactTooltip } from '~/components';

import { LogsProps } from './interface';
import { handlerErrors } from '~/utils/error';

const Logs: React.FC<LogsProps> = ({ module, label, onCloseModal }) => {
  const { colors } = useTheme();

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [inLoad, setInLoad] = useState(false);
  const [results, setResults] = useState<Array<any>>([]);
  const [formError, setFormError] = useState('');

  const ref = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const fetchLogs = async (data: any) => {
    try {
      setInLoad(true);
      try {
        const response = await api.get(`logs`, {
          params: data,
        });
        const { data: data_logs } = response;

        if (data_logs.status === 'error') {
          return setFormError(`${data_logs.message}`);
        }
        setResults(data_logs);
        setInLoad(false);
      } catch (e) {
        setInLoad(false);
        const message = handlerErrors(e);
        setFormError(`${message}`);
      }
    } catch (err) {
      setInLoad(false);
      setFormError('Ocorreu um erro ao buscar os logs');
    }
  };

  const refreshLogs = () => {
    formRef.current?.submitForm();
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        inicio: Yup.date().typeError('Informe uma data inicial'),
        fim: Yup.date().typeError('Informe uma data final'),
      });

      await schema.validate(data, { abortEarly: false });
      fetchLogs(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setFormError('Ocorreu um erro salvar o registro, tente novamente...');
    }
  }, []);

  const handlerHideContent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onCloseModal();
      }
    },
    [onCloseModal],
  );

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onCloseModal();
      }
    },
    [onCloseModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  useEffect(() => {
    const date = new Date();
    const primeiroDia = format(
      new Date(date.getFullYear(), date.getMonth(), 1),
      'yyyy-MM-dd 00:00:00',
    );
    const ultimoDia = format(
      new Date(date.getFullYear(), date.getMonth() + 1, 0),
      'yyyy-MM-dd 23:59:59',
    );

    const initialSearch = {
      inicio: primeiroDia,
      fim: ultimoDia,
    };

    fetchLogs(initialSearch);
  }, [module]);

  return (
    <>
      <LogsContainer>
        <Scroll>
          <ScrollContent>
            <LogsContent ref={ref}>
              <header>
                <div>
                  <AiOutlineDatabase color={colors.white} size={26} />
                  <h1>{label}</h1>
                </div>
                <div>
                  <button
                    type="button"
                    data-tooltip-id="search-button"
                    onClick={refreshLogs}
                  >
                    <ReactTooltip
                      id="search-button"
                      content="Pesquisar navamente"
                    />
                    <FaSyncAlt size={18} color={colors.white} />
                  </button>
                  <button
                    type="button"
                    data-tooltip-id="filter-button"
                    onClick={toggleFiltersVisibility}
                  >
                    <ReactTooltip id="filter-button" content="Filtrar" />
                    {filtersVisible ? (
                      <MdClose size={20} color={colors.white} />
                    ) : (
                      <AiFillFilter size={20} color={colors.white} />
                    )}
                  </button>
                </div>
              </header>
              <main>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                {filtersVisible ? (
                  <LogsContentFilters>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <LogsContentFiltersContent>
                        <InputsContainer>
                          <InputContainer $disabled={inLoad}>
                            <span>Usuário</span>
                            <div>
                              <Select
                                name="usuario_id"
                                loading={inLoad}
                                options={[]}
                              />
                            </div>
                          </InputContainer>

                          <InputContainer $disabled={inLoad}>
                            <span>Empresa</span>
                            <div>
                              <Select
                                name="empresa_id"
                                loading={inLoad}
                                options={[]}
                              />
                            </div>
                          </InputContainer>

                          <InputContainer $disabled={inLoad}>
                            <span>Data Inicial</span>
                            <div>
                              <DatePicker
                                name="inicio"
                                mask="99/99/9999"
                                placeholder="Informe a Data Inicial"
                              />
                            </div>
                          </InputContainer>
                          <InputContainer $disabled={inLoad}>
                            <span>Data Final</span>
                            <div>
                              <DatePicker
                                name="fim"
                                mask="99/99/9999"
                                placeholder="Informe a Data Final"
                              />
                            </div>
                          </InputContainer>
                        </InputsContainer>
                      </LogsContentFiltersContent>
                      <LogsContentFiltersFormSubmit>
                        <button type="submit">
                          <AiFillFilter size={22} color={colors.label} />
                          <span>Filtrar</span>
                        </button>
                      </LogsContentFiltersFormSubmit>
                    </Form>

                    <footer>
                      <button type="button" onClick={toggleFiltersVisibility}>
                        <BiArrowToTop size={32} color={colors.label} />
                      </button>
                    </footer>
                  </LogsContentFilters>
                ) : null}

                {!inLoad && <LogsList itens={results} />}
              </main>
            </LogsContent>
            <LogsCloseButton onClick={onCloseModal}>
              <AiOutlineCloseCircle size={30} color={colors.white} />
            </LogsCloseButton>
          </ScrollContent>
        </Scroll>
      </LogsContainer>
    </>
  );
};

export default Logs;
