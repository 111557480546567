// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors: {
    primary: '#0098da',
    primaryDark: '#0175a7',
    secondary: '#131212',
    default: 'rgba(255, 255, 255, 0.3)',
    defaultHighlight: 'rgba(255, 255, 255, 0.1)',
    greyLowerOpacity: '#abaebf',
    grey: '#878787',
    contrast: '#343233',
    success: '#20C05C',
    error: '#FD4F48',
    label: '#f4ede8',
    background: '#1a1919',
    placeholder: '#666360',
    houver: '#222',
    controlFocus: ' #2d2d30',
    controlNotFocus: '#212123',
    overlay: 'rgba(0, 0, 0, 0.7)',
    footer: '#1a1919',
    liHouver: 'rgb(255, 235, 100, 0.2)',
    // default traditional colors
    orange: '#f9a22a',
    darkOrange: '#ea7a09',
    darkRed: '#9f4040',
    yellow: '#FFED00',
    white: '#f5f5f5',
    green: '#20C05C',
    darkGreen: '#028304',
  },
  fontSizes: {
    default: '1.4rem',
    bigger: '2.5rem',
    big: '1.8rem',
    large: '1.6rem',
    small: '1.2rem',
    tiny: '1rem',
  },
  spacing: {
    tiny: '1rem',
    small: '1.2rem',
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radio: {
    default: '0.6rem',
    small: '0.3rem',
    smallTop: '0.4rem 0.4rem 0 0',
    smallBottom: '0 0 0.4rem 0.4rem',
    tiny: '0.1rem',
  },
  shadows: {
    default: '0.1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1)',
    flat: '0 0.2rem 0.2rem rgba(255, 255, 255, 0.1)',
    highlight: 'inset 0 -3px 12px -6px rgba(255, 255, 255, 0.2)',
    left: 'inset 0.5rem 0rem 1rem  rgba(255, 255, 255, 0.2)',
  },
};
