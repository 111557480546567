import React from 'react';

import { Container } from './styles';

import { TabProps } from './interface';

const Tab: React.FC<TabProps> = ({ active, children }) => {
  return <Container $active={active}>{children}</Container>;
};

export default Tab;
