import styled from 'styled-components';

export const AnchorMenuContainer = styled.div`
  width: 25rem;
  float: left;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 8.5rem;
  left: 0rem;
  padding-right: 1.5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const AnchorMenuHelpers = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  button {
    cursor: pointer;
    background: none;
    transition: all 0.5s ease;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
`;
