/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import chroma from 'chroma-js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactSelect, { components, OptionProps } from 'react-select';
import { useTheme } from 'styled-components';

import { useField } from '@unform/core';
import { Container } from './styles';

import { SelectProps } from './interface';
import { ReactTooltip } from '~/components';

const Select: React.FC<SelectProps> = ({
  name,
  EmptyOptionsMessage,
  onSelectedOption,
  width,
  tooltip,
  ...rest
}) => {
  const { colors, radio } = useTheme();
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'white',
      fontSize: 15,
    }),
    // menu: (base: any) => ({
    //   ...base,
    // }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      backgroundColor: colors.controlFocus,
      padding: 0,
      borderRadius: radio.default,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.grey,
      fontSize: 15,
    }),
    option: (
      styles: any,
      { data, isDisabled, isFocused: isFocusedStyle, isSelected }: any,
    ) => {
      const color = chroma(data.color || colors.label);
      const dataColor = data.color || colors.label;
      return {
        ...styles,
        fontSize: 15,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color || colors.primaryDark
          : isFocusedStyle
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 999
            ? dataColor
            : colors.label
          : dataColor,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          color: colors.label,
          backgroundColor:
            !isDisabled && (isSelected ? dataColor : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles: any, { data }: any) => {
      const color = chroma(data.color || colors.label);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        fontSize: 15,
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color || colors.label,
      fontSize: 15,
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color || colors.label,
      fontSize: 15,
      ':hover': {
        backgroundColor: data.color || colors.label,
        color: colors.label,
      },
    }),
    singleValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        color: colors.label,
        fontSize: 15,
      };
    },
  };

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.selectValue.length) {
            return [];
          }

          const values = ref.state.selectValue.map(
            (option: any) => option.value,
          );

          return values;
        }

        if (!ref.state.selectValue[0]?.value) {
          return '';
        }
        return ref.state.selectValue[0].value;
      },
      clearValue: (ref: any) => {
        if (rest.isMulti) {
          // eslint-disable-next-line no-param-reassign
          ref.state.selectValue = [];
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.state.selectValue = [];
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {EmptyOptionsMessage?.length ? EmptyOptionsMessage : 'Sem opções'}
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Container
      $iserrored={!!error}
      $isfilled={isFilled}
      $isfocused={isFocused}
      width={width}
      data-tooltip-id={`select-${rest.placeholder}`}
    >
      {tooltip && rest.placeholder && (
        <ReactTooltip
          id={`select-${rest.placeholder}`}
          content={String(rest.placeholder) || ''}
        />
      )}
      <ReactSelect
        className="select-component"
        ref={selectRef}
        components={{ NoOptionsMessage }}
        classNamePrefix="filter"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder="Selecione..."
        styles={colourStyles}
        defaultValue={defaultValue}
        onChange={items => (onSelectedOption ? onSelectedOption(items) : null)}
        {...rest}
      />
    </Container>
  );
};

export default Select;
