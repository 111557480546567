import styled, { css } from 'styled-components';

import { LabelProps, TagProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
`;

export const Label = styled.button.attrs<LabelProps>({
  type: 'button',
})`
  ${({ theme, $active }) => css`
    display: flex;
    flex-direction: row;
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    height: 5rem;
    padding: 0.2rem;

    span {
      flex: 1;
      text-align: left;
      padding: 0 1rem;
      font-size: 1.2rem;
      color: ${$active ? '#fff' : '#666360'};
    }

    i {
      align-items: center;
      justify-content: center;
      display: flex;
      border-left: 1px solid #fff;
      padding: 0 0.5rem;
      height: 100%;
    }
  `}
`;

export const Tags = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.controlNotFocus};
    border-radius: 6px 6px 0 0;
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    border-bottom: none;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
  `}
`;

export const Tag = styled.div<TagProps>`
  ${({ theme, $active }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 0.2rem solid ${theme.colors.defaultHighlight};
    align-items: center;
    cursor: pointer;
    padding: 0 1.5rem 0 0;

    i {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-top: -5px;
      }
    }

    span {
      float: left;
      flex: 1;
      height: 60px;
      line-height: 60px;
      color: ${$active ? theme.colors.primary : theme.colors.white};
      text-decoration: ${$active ? 'underline' : 'none'};
    }

    &:hover {
      background: ${theme.colors.defaultHighlight};

      span {
        text-decoration: underline;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    ins {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.controlNotFocus};
    border-radius: 0 0 6px 6px;
    padding: 2rem 1.5rem;
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  `}
`;

export const Clear = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    span {
      color: ${theme.colors.white};
      font-size: 1.4rem;
      margin: 0 0 0 5px;
    }

    &:hover {
      span {
        text-decoration: underline;
        color: ${theme.colors.primary};
      }
      svg {
        fill: ${theme.colors.primary};
      }
    }
  `}
`;
