export const CONTAINER_ANIMATION = {
  unMounted: { y: -30, opacity: 0.5 },
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
};

export const CARDS_ANIMATION = {
  unMounted: { y: -35, opacity: 0 },
  mounted: { y: -0.5, opacity: 1 },
};

export const CONTENT_ANIMATION = {
  unMounted: { y: 30, opacity: 0.5 },
  mounted: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
};

export const ITEM_ANIMATION = {
  unMounted: { y: 25, opacity: 0 },
  mounted: { y: -10, opacity: 1 },
};
