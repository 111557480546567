import React from 'react';
import { TotalizadorPorFormaDePagamentoProps } from './interface';
import {
  TotalizerByPaymentMethodSection,
  TotalizerByPaymentMethodSectionContent,
  TotalizerByPaymentMethodSectionContentItem,
  TotalizerByPaymentMethodSectionHeader,
} from './styles';

const TotalizadorPorFormaDePagamento: React.FC<
  TotalizadorPorFormaDePagamentoProps
> = ({ tipos_pagamentos }) => {
  return (
    <TotalizerByPaymentMethodSection>
      <TotalizerByPaymentMethodSectionHeader>
        <strong>Total por forma de pagamento</strong>
      </TotalizerByPaymentMethodSectionHeader>

      <TotalizerByPaymentMethodSectionContent>
        {tipos_pagamentos?.map(tipo => {
          return (
            <React.Fragment key={tipo.id}>
              <TotalizerByPaymentMethodSectionContentItem>
                <span>{tipo.descricao}</span>
                <strong>
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(tipo.valor)}
                </strong>
              </TotalizerByPaymentMethodSectionContentItem>
            </React.Fragment>
          );
        })}
      </TotalizerByPaymentMethodSectionContent>
    </TotalizerByPaymentMethodSection>
  );
};

export default TotalizadorPorFormaDePagamento;
