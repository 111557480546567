import React, { useState, useRef, useCallback, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import {
  Button,
  FormFooter,
  Input,
  InputsContainer,
  InputContainer,
  InputMask,
  Switch,
} from '~/components';

import { TelefoneProps } from './interface';

import { TelefoneType } from '~/types';

const Telefone: React.FC<TelefoneProps> = ({
  telefone,
  onRequestSave,
  onRequestClose,
}) => {
  const { colors } = useTheme();
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  useEffect(() => {
    setTimeout(() => {
      const inputNode =
        document.querySelector<HTMLInputElement>(`input[name='ddd']`);
      if (inputNode) {
        inputNode.focus();
      }
    }, 300);
  }, []);

  const handleSubmit = useCallback(
    async (data: TelefoneType) => {
      try {
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          ddd: Yup.string().required('DDD obrigatório'),
          // codigo_pais: Yup.string().required('Cõdigo país obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
          descricao: Yup.string().required('Descrição obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        onRequestSave({
          ...data,
          codigo_pais: '55',
          id: telefone ? telefone.id : 0,
        });

        if (!telefone) {
          formRef.current?.reset();
        }

        setFormLoading(false);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'Telefone armazenado com sucesso!',
        });
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [addToast, onRequestSave, telefone],
  );

  return (
    <Form ref={formRef} initialData={telefone} onSubmit={handleSubmit}>
      <InputsContainer>
        {/* <InputContainer width={120} disabled={formLoading}>
          <span>Código País</span>
          <div>
            <Input name="codigo_pais" placeholder="Ex: +55" />
          </div>
        </InputContainer> */}
        <InputContainer width={100} disabled={formLoading}>
          <span>DDD</span>
          <div>
            <InputMask mask="99" name="ddd" placeholder="Ex: 35" />
          </div>
        </InputContainer>
        <InputContainer disabled={formLoading}>
          <span>Telefone</span>
          <div>
            <InputMask
              mask="9 9999-9999"
              name="telefone"
              placeholder="Informe o Número"
            />
          </div>
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading}>
          <span>Descrição</span>
          <div>
            <Input name="descricao" placeholder="Informe a Descrição" />
          </div>
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading}>
          <span>Principal?</span>
          <div className="switch">
            <Switch name="principal" />
          </div>
        </InputContainer>
      </InputsContainer>

      <FormFooter>
        <Button
          type="button"
          background={colors.grey}
          onClick={onRequestClose}
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          background={colors.green}
          loading={formLoading}
          style={{ marginTop: 50, marginRight: 30, marginBottom: 20 }}
        >
          Salvar
        </Button>
      </FormFooter>
    </Form>
  );
};

export default Telefone;
