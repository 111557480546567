import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import { TagDragContainer } from './styles';

import { TagItemProp } from './interface';

const TagDrag: React.FC<TagItemProp> = ({
  tag,
  type,
  onDropItem,
  children,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'tag-in',
    item: () => {
      return { tag };
    },
    end: (
      item: { name: string } | undefined,
      monitor: DragSourceMonitor<TagItemProp>,
    ) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDropItem(tag);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <TagDragContainer ref={drag} $active={isDragging}>
      {children}
    </TagDragContainer>
  );
};

export default TagDrag;
