import React, { useCallback, useEffect, useState } from 'react';

import { BsFillTriangleFill } from 'react-icons/bs';

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Button, Loader, Plate } from '~/components';

import { ContaCorrenteType } from '~/types';

import api from '~/services/api';

import Filtragem from './Filtragem';

const ContaCorrentes: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [contasCorrentes, setContasCorrentes] = useState<
    Array<ContaCorrenteType>
  >([]);

  const [filtragemModalVisible, setFiltragemModalVisible] = useState(false);

  const handlerFiltragemModalClose = () => {
    setFiltragemModalVisible(false);
  };

  const handlerFiltragemModalOpen = () => {
    setFiltragemModalVisible(true);
  };

  const fetchContaCorrentes = useCallback(
    async (
      p_empresa_id: string,
      p_parceiro_id: string,
      p_tipo: string,
      p_status: string,
    ) => {
      try {
        setLoading(true);

        const params = new URLSearchParams({
          status: p_status,
        });

        if (p_empresa_id) {
          params.append('empresa_id', p_empresa_id);
        }

        if (p_parceiro_id) {
          params.append('usuario_id', p_parceiro_id);
        }

        if (p_tipo) {
          params.append('tipo', p_tipo);
        }

        const response = await api.get(
          `financeiros/contas-correntes?${params.toString()}`,
        );
        const { data } = response;
        if (data && data.status !== 'error') {
          setContasCorrentes(data.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [],
  );

  const handlerEditarContaCorrente = useCallback(
    (contaCorrente: ContaCorrenteType) => {
      history(`/conta-corrente/${contaCorrente.id}`);
    },
    [history],
  );

  const handlerVisualizarContaCorrente = useCallback(
    (contaCorrente: ContaCorrenteType) => {
      history(`/caixa/${contaCorrente.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchContaCorrentes('', '', '', 'ABERTA');
  }, [fetchContaCorrentes]);

  return (
    <Container>
      <Loader visible={loading} />
      <Header />

      <Filtragem
        onRequestClose={handlerFiltragemModalClose}
        visible={filtragemModalVisible}
        onRequestSave={fetchContaCorrentes}
        haveRegisters={contasCorrentes.length > 0}
      />

      <Content>
        <Plate
          title="Contas Correntes"
          subtitle="Cadastre as Contas Correntes utilizadas"
          icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
        >
          <Button
            type="button"
            background={colors.contrast}
            onClick={handlerFiltragemModalOpen}
          >
            Filtrar
          </Button>
          <Link to="/conta-corrente/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {contasCorrentes ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'tipo',
                label: 'Tipo',
                search: false,
              },
              {
                column: 'status',
                label: 'Status',
                search: false,
              },
              {
                column: 'usuario',
                label: 'Usuário',
                search: false,
              },
              {
                column: 'compartilhada',
                label: 'Compartilhada',
                search: false,
              },
            ]}
            data={contasCorrentes.map(contaCorrente => ({
              ...contaCorrente,
              empresa: contaCorrente.empresa.fantasia,
              usuario: contaCorrente.usuario.nome_fantasia,
              compartilhada: contaCorrente.compartilhada ? 'SIM' : 'NÃO',
            }))}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditarContaCorrente(item);
                },
              },
              {
                label: 'Visualizar',
                onPress: item => {
                  handlerVisualizarContaCorrente(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default ContaCorrentes;
