import React, { useState, useEffect, useCallback } from 'react';

import swal from 'sweetalert2';
import { MdAccountBalance } from 'react-icons/md';
import { useTheme } from 'styled-components';

import api from '~/services/api';

import { useNFE } from '~/hooks/nfe';
import { useAuth } from '~/hooks/auth';

import { Select } from '~/components';

import { Container, Info, Message, Label } from './styles';

import { ContaCorrenteProps } from './interface';

import { EmpresaType, ContaCorrenteType, SelectType } from '~/types';

const ContaCorrente: React.FC<ContaCorrenteProps> = ({ nfe }) => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const { editarNFE, validarNFE } = useNFE();

  const [inLoad, setInLoad] = useState(true);

  const [conta_corrente, set_conta_corrente] =
    useState<ContaCorrenteType | null>(null);

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [empresa_id, setEmpresaId] = useState<string | null>(null);

  const loadContasCorrentes = useCallback(async () => {
    try {
      setInLoad(true);
      const response = await api.get(
        `/financeiros/contas-correntes?empresa_id=${user.parceiro_id}&usuario_id=${user.parceiro_id}&tipo=ContaCorrente&status=ABERTA`,
      );
      const { data } = response;
      if (data) {
        const { data: results } = data;
        if (results && results.length) {
          const cContaCorrente: ContaCorrenteType = results[0];

          set_conta_corrente(cContaCorrente);

          editarNFE({
            ...nfe,
            conta_corrente_id: cContaCorrente.id,
          });

          validarNFE({
            ...nfe,
            conta_corrente_id: cContaCorrente.id,
          });
        }
      }
      setInLoad(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setInLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abrirContaCorrente = async () => {
    if (empresa_id) {
      swal
        .fire({
          title: 'Confirma a abertura de ContaCorrente?',
          text: `A conta corrente será aberta ao usuário logado!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              setInLoad(true);
              const response = await api.post(`financeiros/contas-correntes`, {
                empresa_id,
                ativo: true,
                descricao: 'ContaCorrente-FRENTE',
                tipo: 'ContaCorrente',
                instituicao_financeira_id: null,
                status: 'ABERTA',
                compartilhada: false,
              });
              const { data } = response;
              if (data) {
                loadContasCorrentes();
              }
              setInLoad(false);
              // eslint-disable-next-line no-empty
            } catch (e) {
              setInLoad(false);
            }
          }
        });
    } else {
      swal.fire({
        title: 'Atenção!',
        text: `Nenhuma empresa associada ao seu login. tente novamente!`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  const atribuirEmpresa = useCallback((item: SelectType) => {
    if (item) {
      setEmpresaId(item.value);
    }
  }, []);

  useEffect(() => {
    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    loadEmpresas();
  }, []);

  useEffect(() => {
    if (empresa_id && user.parceiro_id) {
      loadContasCorrentes();
    }
  }, [user, empresa_id, loadContasCorrentes]);

  return (
    <Container>
      <Info>
        <span>Empresa</span>
        <div>
          <Select
            name="empresa_id"
            placeholder="SELECIONE A EMPRESA"
            options={empresas}
            loading={false}
            onSelectedOption={atribuirEmpresa}
          />
        </div>
      </Info>

      {empresa_id ? (
        <>
          {inLoad ? (
            <Message>
              <MdAccountBalance size={45} color={colors.label} />
              <div>
                <h1>CONTA CORRENTE</h1>
                <p>Carregando informações da sua conta corrente...</p>
              </div>
            </Message>
          ) : (
            <>
              {conta_corrente ? (
                <Message>
                  <MdAccountBalance size={45} color={colors.label} />
                  <div>
                    <h1>CONTA CORRENTE</h1>
                    <p>{conta_corrente.descricao}</p>
                    <Label $status={conta_corrente.status}>
                      {conta_corrente.status}
                    </Label>
                  </div>
                </Message>
              ) : (
                <Message className="error">
                  <MdAccountBalance size={45} color={colors.label} />
                  <div>
                    <h1>CONTA CORRENTE</h1>
                    <p>
                      Nenhuma conta corrente do tipo ContaCorrente associada a
                      esta empresa, clique para abrir!
                    </p>
                    <button onClick={abrirContaCorrente} type="button">
                      ABRIR CONTA CORRENTE
                    </button>
                  </div>
                </Message>
              )}
            </>
          )}
        </>
      ) : null}
    </Container>
  );
};

export default ContaCorrente;
