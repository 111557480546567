import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `}
`;
