import React from 'react';

import { Container } from './styles';

import { InputContainerProps } from './interface';

const InputContainer: React.FC<InputContainerProps> = ({
  width,
  disabled,
  children,
  required = false,
}) => {
  return (
    <Container $width={width} $disabled={disabled} $required={required}>
      {children}
    </Container>
  );
};

export default InputContainer;
