import React, { useEffect, useCallback } from 'react';

import api from '~/services/api';

import { Session } from '~/components';

import { useAuth } from '~/hooks/auth';

interface RouteProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RouteProps> = ({ children }) => {
  const { user, signOut } = useAuth();

  const checarExpiracao = useCallback(() => {
    const token = localStorage.getItem('@SisautERP:token');
    if (!token || token == null) {
      localStorage.removeItem('@SisautERP:token');
      localStorage.removeItem('@SisautERP:user');
      signOut();
    }
  }, [signOut]);

  const validarSessaoNaApi = useCallback(async () => {
    try {
      // FORÇAR UM POSSIVEL 401
      await api.get(`/empresas`);
      checarExpiracao();
    } catch (e) {
      checarExpiracao();
    }
  }, [checarExpiracao]);

  useEffect(() => {
    setTimeout(() => {
      checarExpiracao();
      validarSessaoNaApi();
      window.addEventListener('storage', checarExpiracao, false);
    }, 3000);
  }, [checarExpiracao, validarSessaoNaApi]);

  if (!user) {
    return <Session />;
  }

  return <>{children}</>;
};

export default RequireAuth;
