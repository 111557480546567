import React from 'react';
import { useTheme } from 'styled-components';

import { AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { TagsDropDownAreaItem } from './styles';

import { TagItemProp } from './interface';

const TagItem: React.FC<TagItemProp> = ({
  tag,
  cor,
  onAddItem,
  onRemoveItem,
}) => {
  const { colors } = useTheme();
  return (
    <TagsDropDownAreaItem $cor={cor}>
      {tag.label}
      {onRemoveItem ? (
        <button type="button" onClick={() => onRemoveItem(tag)}>
          <AiFillDelete color={colors.label} size="22" />
        </button>
      ) : null}
      {onAddItem ? (
        <button type="button" onClick={() => onAddItem(tag)}>
          <AiOutlinePlus color={colors.label} size="22" />
        </button>
      ) : null}
    </TagsDropDownAreaItem>
  );
};

export default TagItem;
