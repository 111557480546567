import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import {
  filtrarAgrupadoresPorAgrupamento,
  filtrarDocumentoFiscalsPorAgrupador,
  ordenarDocumentoFiscals,
} from '../engine';

import { Sticker, Button } from '~/components';

import DocumentoFiscal from './DocumentoFiscal';
import Totalizadores from './Totalizadores';
// import SelecaoDeItens from './SelecaoDeItens';

import { Container, Content, List, Pagination } from './styles';

import { DocumentoFiscalType } from '~/types';

import { ListagemProps } from './interface';

const Listagem: React.FC<ListagemProps> = ({
  documentosFiscais,
  configuracao,
  totalizadores,
  empresaSelecionada,
  paginaAtual,
  totalPaginas,
  totalRegistros,
  alterarPagina,
  recarregarPaginaAtual,
}) => {
  const { colors } = useTheme();

  const [documentosFiscaisSelecionadas, setDocumentoFiscalsSelecionadas] =
    useState<string[]>([]);

  const [totalizadoresVisibilidiade, setTotalizadoresVisibilidade] =
    useState(false);

  const selecionarDocumentoFiscal = (id: string) => {
    setDocumentoFiscalsSelecionadas(
      documentosFiscaisSelecionadas.includes(id)
        ? [
            ...documentosFiscaisSelecionadas.filter(
              documentoFiscalId => id !== documentoFiscalId,
            ),
          ]
        : [...documentosFiscaisSelecionadas, id],
    );
  };

  const checarDocumentoFiscalSelecionada = (id: string) => {
    return documentosFiscaisSelecionadas.includes(id);
  };

  const renderizarDocumentoFiscals = (
    pDocumentoFiscal: Array<DocumentoFiscalType>,
  ) => {
    return (
      <List>
        {pDocumentoFiscal.map((documentoFiscal, index) => (
          <DocumentoFiscal
            key={documentoFiscal.id}
            eImpar={index % 2 === 0}
            documentoFiscal={documentoFiscal}
            selecionada={checarDocumentoFiscalSelecionada(
              `${documentoFiscal.id}`,
            )}
            selecionarDocumentoFiscal={selecionarDocumentoFiscal}
          />
        ))}
        {!pDocumentoFiscal.length ? <h4>Nenhum item</h4> : null}
      </List>
    );
  };

  const renderizarListagem = (
    pDocumentoFiscal: Array<DocumentoFiscalType>,
    pAgrupamentos: Array<string>,
  ) => {
    if (!pAgrupamentos.length) {
      return renderizarDocumentoFiscals(pDocumentoFiscal);
    }

    const currentAgrupamento = pAgrupamentos[0];

    const agrupadores = filtrarAgrupadoresPorAgrupamento(
      documentosFiscais,
      currentAgrupamento,
    );

    return agrupadores.map((agrupador, idx) => (
      <React.Fragment key={agrupador[idx]}>
        <Sticker title={agrupador}>
          {renderizarListagem(
            filtrarDocumentoFiscalsPorAgrupador(
              pDocumentoFiscal,
              currentAgrupamento,
              agrupador,
            ),
            [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
          )}
        </Sticker>
      </React.Fragment>
    ));
  };

  const fecharTotalizadores = () => {
    setTotalizadoresVisibilidade(false);
  };

  const abrirTotalizadores = () => {
    setTotalizadoresVisibilidade(true);
  };

  const pAgrupamentos = configuracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);

  return (
    <Container>
      {totalizadores.length ? (
        <Totalizadores
          aoFechar={fecharTotalizadores}
          visibilidade={totalizadoresVisibilidiade}
          totalizadores={totalizadores}
        />
      ) : null}

      {/* {documentosFiscais.length ? (
        <SelecaoDeItens
          documentosFiscais={documentosFiscais}
          documentosFiscaisSelecionadas={documentosFiscaisSelecionadas}
          setDocumentoFiscalsSelecionadas={setDocumentoFiscalsSelecionadas}
          empresaSelecionada={empresaSelecionada}
          recarregarPaginaAtual={recarregarPaginaAtual}
        />
      ) : null} */}

      <Content>
        {renderizarListagem(
          ordenarDocumentoFiscals(documentosFiscais, configuracao),
          pAgrupamentos,
        )}
      </Content>

      {paginaAtual && totalPaginas && totalRegistros ? (
        <Pagination>
          {totalPaginas > 1 ? (
            <div className="pages">
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(1)}
                >
                  &laquo;
                </Button>
              ) : null}
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual - 1)}
                >
                  {paginaAtual - 1}
                </Button>
              ) : null}
              <Button
                background={colors.orange}
                onClick={() => alterarPagina(1)}
              >
                {paginaAtual}
              </Button>
              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  {paginaAtual + 1}
                </Button>
              ) : null}

              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  &raquo;
                </Button>
              ) : null}

              {totalPaginas > paginaAtual ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(totalPaginas)}
                >
                  {totalPaginas}
                </Button>
              ) : null}
            </div>
          ) : null}
          {/* <span>
            {`${totalRegistros} ${
              totalRegistros > 1 ? 'Registros' : 'Registro'
            }`}
          </span> */}
          <div className="totalizadores">
            <Button onClick={abrirTotalizadores} background={colors.grey}>
              Totalizadores
            </Button>
          </div>
        </Pagination>
      ) : null}
    </Container>
  );
};

export default Listagem;
