import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const EditContainer = styled.div`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.label};

  div {
    margin: 0 !important;
  }

  strong {
    font-weight: bold;
    font-size: 14px;

    margin-bottom: 0.75rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  div {
    margin: 0 0.5rem;
    width: 20rem;
  }
`;
