import React, { useState, useCallback } from 'react';

import { FiPlus } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import List from '~/components/List';
import { Modal, Button } from '~/components';

import Loader from '~/components/Loader';

import VariacaoProduto from './VariacaoProduto';

import { Container, ContainerHeader } from './styles';

import { VariacoesProdutoProps } from './interface';

const VariacoesProduto: React.FC<VariacoesProdutoProps> = ({
  setChangedVariacoes,
  produto_id,
  produto_nome,
  data,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(false);

  const [ModalVisible, setModalVisible] = useState(false);
  const [current, setCurrent] = useState('0');

  const fetchs = useCallback(async () => {
    try {
      setInLoad(true);
      const response = await api.get(`/produtos/${produto_id}/variacoes`);

      setChangedVariacoes(response.data);
      setInLoad(false);
    } catch (err) {
      setInLoad(false);
    }
  }, [produto_id, setChangedVariacoes]);

  const handlerClose = () => {
    setModalVisible(false);
  };

  const handlerCreate = (): void => {
    setCurrent('0');
    setModalVisible(true);
  };

  const handlerEdit = (item: any): void => {
    setCurrent(item.id);
    setModalVisible(true);
  };

  const handlerOnSave = (): void => {
    fetchs();
    handlerClose();
  };

  const handlerRemove = (item: any): void => {
    swal
      .fire({
        title: `Deseja realmente remover a variação?`,
        text: `Ao confirmar, a informação será removido!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.delete(`produtos/${produto_id}/variacoes/${item.id}`);
            await fetchs();
            addToast({
              type: 'success',
              title: 'Concluido',
              description: `Variação deletada com sucesso!`,
            });
          } catch {
            addToast({
              type: 'error',
              title: 'Falha ao remover',
              description: `Variação não foi removida!`,
            });
          }
        }
      });
  };

  return (
    <Container>
      <Loader visible={inLoad} />
      <ContainerHeader>
        <div>
          <Button
            onClick={handlerCreate}
            type="button"
            background={colors.darkGreen}
          >
            <FiPlus size={24} />
          </Button>
        </div>
      </ContainerHeader>

      {ModalVisible && (
        <Modal
          title={`${produto_nome}`}
          visible={ModalVisible}
          onRequestClose={handlerClose}
          width="100rem"
          scrollable
        >
          <VariacaoProduto
            id={current}
            id_produto={produto_id}
            onRequestClose={handlerClose}
            onSave={handlerOnSave}
          />
        </Modal>
      )}

      {data.length ? (
        <List
          header={[
            {
              column: 'tamanho_descricao',
              label: 'Tamanho',
            },
            {
              column: 'cor_descricao',
              label: 'Cor',
            },
            {
              column: 'ativo',
              label: 'Ativo',
            },
          ]}
          data={[
            ...data.map(item => ({
              ...item,
              cor_descricao: item.cor.descricao,
              tamanho_descricao: item.tamanho.descricao,
              ativo: item.ativo ? 'SIM' : 'NAO',
            })),
          ]}
          options={[
            {
              label: 'Editar',
              onPress: item => {
                handlerEdit(item);
              },
            },
            {
              label: 'Remover',
              onPress: item => {
                handlerRemove(item);
              },
            },
          ]}
        />
      ) : (
        <h1>Nenhuma variação de grade cadastrada!</h1>
      )}
    </Container>
  );
};

export default VariacoesProduto;
