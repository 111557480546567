import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '~/hooks/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addDash } from 'string-sanitizer';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
  NoPermission,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import MultiplicadoresTypes from '~/schemas/multiplicadores.json';
import { NaturezaDeOperacaoFormData, ParamsProps } from './interface';
import { handlerErrors } from '~/utils/error';
import { validarPermissao } from '~/utils/permissions';

import finalidadeEmissaoNFeSchema from '~/schemas/NFe/finalidade-emissao.json';
import indicadorDestinoNFeSchema from '~/schemas/NFe/indicador-destino.json';
import tipoOperacaoNFeSchema from '~/schemas/NFe/tipo-operacao.json';
import modeloDocumentoFiscalSchema from '~/schemas/NFe/modelo-documento-fiscal.json';
import { getObjectFromValue } from '~/utils/getObjectFromValue';

const NaturezaDeOperacao: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id, ativo: true });
  const [registerId, setRegisterId] = useState('0');
  const [emiteDocFiscal, setEmiteDocFiscal] = useState(true);

  const formRef = useRef<FormHandles>(null);

  const handleChangeEmiteDocFiscal = (checked: boolean): void => {
    setEmiteDocFiscal(checked);
  };

  const handleSubmit = useCallback(
    async (data: NaturezaDeOperacaoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const slug = addDash(data.descricao);

          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `pedido/natureza-operacao${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            {
              ...data,
              apelido: data.descricao,
              slug,
              reflete_estoque_crossdocking: false,
              mobile: false,
            },
          );

          history(`/natureza-de-operacao/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    setLoading(true);
    setTimeout(() => {
      setEmiteDocFiscal(false);
      setRegisterData({
        id: '0',
        ativo: true,
      });
      history(`/natureza-de-operacao/0`);
      formRef.current?.reset();
      setRegisterId('0');
      setLoading(false);
    }, 200);
  }

  useEffect(() => {
    async function loadRegister() {
      try {
        if (id) {
          setRegisterId(id);
        }
        if (id !== '0') {
          const response = await api.get(`pedido/natureza-operacao/${id}`);
          const data = {
            ...response.data,
            multiplicador: {
              value: response.data.multiplicador,
              label: response.data.multiplicador,
              slug: response.data.multiplicador,
            },
            IDE_finNFe: getObjectFromValue(
              response.data.IDE_finNFe,
              finalidadeEmissaoNFeSchema,
            ),
            IDE_idDest: getObjectFromValue(
              response.data.IDE_idDest,
              indicadorDestinoNFeSchema,
            ),
            IDE_tpNF: getObjectFromValue(
              response.data.IDE_tpNF,
              tipoOperacaoNFeSchema,
            ),
            IDE_mod: getObjectFromValue(
              response.data.IDE_mod,
              modeloDocumentoFiscalSchema,
            ),
          };
          setEmiteDocFiscal(data.emite_documento_fiscal);
          setRegisterData(data);
          setLoading(false);
        } else {
          clearForm();
        }
      } catch (err) {
        setLoading(false);
      }
    }

    loadRegister();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  // if (validarPermissao(user, 'incluir-editar-natureza-operacao')) {
  //   return <NoPermission />;
  // }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Natureza de Operação"
                      subtitle="Adicione as natureza de operação"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe a descrição"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      {/* <InputContainer disabled={formLoading}>
                        <span>Apelido</span>
                        <div>
                          <Input
                            name="apelido"
                            placeholder="Ex: Ursinho"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer> */}
                      <InputContainer disabled={formLoading}>
                        <span>Multiplicador</span>
                        <div>
                          <Select
                            name="multiplicador"
                            loading={loading}
                            options={MultiplicadoresTypes}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Emite documento fiscal?</span>
                        <div className="switch">
                          <Switch
                            name="emite_documento_fiscal"
                            onChange={handleChangeEmiteDocFiscal}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                    {emiteDocFiscal && (
                      <InputsContainer>
                        <InputContainer>
                          <span>Modelo</span>
                          <div>
                            <Select
                              name="IDE_mod"
                              loading={false}
                              options={modeloDocumentoFiscalSchema}
                            />
                          </div>
                        </InputContainer>
                        <InputContainer disabled={formLoading}>
                          <span>Finalidade da Operação</span>
                          <div>
                            <Select
                              name="IDE_finNFe"
                              loading={false}
                              options={finalidadeEmissaoNFeSchema}
                            />
                          </div>
                        </InputContainer>
                        <InputContainer>
                          <span>Indicador de destino</span>
                          <div>
                            <Select
                              name="IDE_idDest"
                              loading={false}
                              options={indicadorDestinoNFeSchema}
                            />
                          </div>
                        </InputContainer>
                        <InputContainer>
                          <span>Tipo de Operação</span>
                          <div>
                            <Select
                              name="IDE_tpNF"
                              loading={false}
                              options={tipoOperacaoNFeSchema}
                            />
                          </div>
                        </InputContainer>
                      </InputsContainer>
                    )}
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Movimenta estoque físico?</span>
                        <div className="switch">
                          <Switch name="reflete_estoque_fisico" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Movimenta estoque contábil?</span>
                        <div className="switch">
                          <Switch name="reflete_estoque_contabil" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Movimenta estoque virtual?</span>
                        <div className="switch">
                          <Switch name="reflete_estoque_virtual" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Operação de compra?</span>
                        <div className="switch">
                          <Switch name="compra" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/naturezas-de-operacoes">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default NaturezaDeOperacao;
