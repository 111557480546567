import styled, { css } from 'styled-components';

export const IFCContainer = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    margin-bottom: -2rem;

    h1 {
      margin: 0px;
      width: 100%;
      /* text-align: center; */
      font-size: ${theme.fontSizes.default};
      padding: 20px;
      color: ${theme.colors.placeholder};
    }
  `}
`;

export const IFCContainerHeader = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    div {
      position: relative;
      width: 10%;
      z-index: 300;
      margin: -5.9rem 1.5rem 0 0;
    }
  `}
`;
