import styled, { keyframes, css } from 'styled-components';
import Button from '~/components/form/Button';

import { HeaderPerfilContentProps, HeaderPerfilStatusProps } from './interface';

export const HeaderPerfilContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderPerfilControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  transition: all 0.5s ease;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHighlight};
  }
`;

export const HeaderPerfilUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    border: 0.1rem solid ${({ theme }) => theme.colors.default};
  }
`;

export const HeaderPerfilStatus = styled.div<HeaderPerfilStatusProps>`
  background: ${props => (props.$online ? '#1bc943' : '#f83245')};
  width: 1.4rem;
  height: 1.4rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.defaultHighlight};
  margin: -2.2rem 0 0 -1.6rem;
  border-radius: 100%;
  display: flex;
  align-self: flex-end;
`;

export const HeaderPerfilControlIc = styled.div`
  margin: 1rem 0 0 0.5rem;
`;

const visibleContentAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const invisibleContentAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const NavButton = styled(Button)`
  ${({ theme }) => css`
    width: 90%;
    /* padding: 1.2rem 1.6rem; */
    justify-content: space-between;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.secondary};
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    color: ${theme.colors.label};
    margin: 2rem 0.1rem 0;

    :hover {
      background: ${theme.colors.defaultHighlight};
    }

    :first-child {
      border-radius: ${theme.radio.smallTop};
    }

    :last-child {
      border-radius: ${theme.radio.smallBottom};
    }
  `}
`;

export const HeaderPerfilContent = styled.div<HeaderPerfilContentProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    z-index: 3000;
    justify-content: center;
    width: 22rem;
    height: auto;
    padding: 2rem 0;
    background: ${theme.colors.background};
    border: 0.1rem solid ${theme.colors.default};
    top: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: ${theme.shadows.default}
    transform: scale(1) translate(-50%, 0);
    margin-left: -7rem;
    visibility: ${$visible ? '$visible' : 'hidden'};

    animation: ${$visible ? visibleContentAnimation : invisibleContentAnimation}
      0.5s forwards;

    img {
      width: 6rem;
      height: 6rem;
      border-radius: 100%;
      border: 0.1rem solid ${theme.colors.default};
    }

    h2 {
      margin: 2rem 0 0 0;
      padding: 0rem;
      font-size: ${theme.fontSizes.default};
      font-weight: 700;
    }

    h3 {
      margin: 0rem;
      padding: 0rem;
      font-size: ${theme.fontSizes.default};
    }

    div {
      width: 100%;
      border-top: 0.1rem solid ${theme.colors.default};
      /* border-bottom: 0.1rem solid ${theme.colors.default}; */
      flex-direction: column;
      display: flex;
      margin-top: 2rem;
      padding-top: 2rem;
    }

    a {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.label};
      padding: 0.5rem 2rem;
      transition: all 0.5s ease;
      &:hover {
        color: ${theme.colors.primary};
      }
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
      }

      strong {
        font-size: ${theme.fontSizes.default};
        color: ${theme.colors.label};
        font-weight: 700;
      }

      small {
        font-size: ${theme.fontSizes.small};
        color: ${theme.colors.label};
      }
    }


    @media (max-width: 1024px) {
      width: 100%;
      left: 0rem;
      top: 8rem;
      transform: scale(1) translate(0, 0);
      margin: 0px;
    }
    
  `}
`;
