import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Container, List, Item } from './styles';

import { CheckoutProps } from './interface';

import { Modal, Loader } from '~/components';

import { CondicaoPagamentoType } from '~/types';

const CondicaoDePagamento: React.FC<CheckoutProps> = ({
  tipo_pagamento_id,
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  const [loading, setLoading] = useState(true);

  const [condicoesDePagamentos, setCondicoesDePagamentos] = useState<
    Array<CondicaoPagamentoType>
  >([]);

  const handlerOnRequestClose = () => {
    onRequestClose();
  };

  const handlerOnRequestSave = (condicaoDePagamento: CondicaoPagamentoType) => {
    onRequestSave(condicaoDePagamento);
    onRequestClose();
  };

  useEffect(() => {
    async function loadCondicoesDePagamentos() {
      try {
        setLoading(true);
        const response = await api.get(
          `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes?primeira_parcela=0&parcelas=1`,
        );
        setCondicoesDePagamentos(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    loadCondicoesDePagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (loading) return <Loader visible />;

  return (
    <Modal
      title="Selecione uma Condição de Pagamento"
      width="90rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <List>
          {condicoesDePagamentos.map(condicaoDePagamento => (
            <Item
              key={condicaoDePagamento.id}
              onClick={() => handlerOnRequestSave(condicaoDePagamento)}
            >
              {condicaoDePagamento.descricao}
            </Item>
          ))}
        </List>
      </Container>
    </Modal>
  );
};

export default CondicaoDePagamento;
