import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Container, List, Item } from './styles';

import { CheckoutProps } from './interface';

import { Modal, Loader } from '~/components';

import { TipoPagamentoType } from '~/types';

const TipoDePagamento: React.FC<CheckoutProps> = ({
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  const [loading, setLoading] = useState(true);

  const [tiposDePagamentos, setTiposDePagamentos] = useState<
    Array<TipoPagamentoType>
  >([]);

  const handlerOnRequestClose = () => {
    onRequestClose();
  };

  const handlerOnRequestSave = (tipoPagamento: TipoPagamentoType) => {
    onRequestSave(tipoPagamento);
    onRequestClose();
  };

  useEffect(() => {
    async function loadTiposDePagamentos() {
      try {
        setLoading(true);
        const response = await api.get(
          `financeiros/tipos-pagamento?situacao_lancamento=PAGA`,
        );
        setTiposDePagamentos(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    loadTiposDePagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader visible />;

  return (
    <Modal
      title="Selecione uma Tipo de Pagamento"
      width="90rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <List>
          {tiposDePagamentos.map(tipoDePagamento => (
            <Item
              key={tipoDePagamento.id}
              onClick={() => handlerOnRequestSave(tipoDePagamento)}
            >
              {tipoDePagamento.descricao}
            </Item>
          ))}
        </List>
      </Container>
    </Modal>
  );
};

export default TipoDePagamento;
