import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import Loader from '~/components/Loader';

import {
  FormContainer,
  FormContent,
  FormBlock,
  FormFooter,
  InputsContainer,
  InputContainer,
  Button,
  Switch,
  Select,
  ErrorMessage,
} from '~/components';

import { Content } from './styles';

import { SelectType, VariacaoGradeType } from '~/types';

import { VariacaoProdutoFormData, ParamsProps } from './interface';
import { handlerErrors } from '~/utils/error';

const VariacaoProduto: React.FC<ParamsProps> = ({
  id_produto,
  id,
  onRequestClose,
  onSave,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');

  const [inLoad, setInLoad] = useState(true);
  const [retroLoad, setRetroLoad] = useState(true);

  const [registerData, setRegisterData] = useState({ ativo: true });

  const [variacao, setVariacao] = useState('0');
  const [cores, setCores] = useState<SelectType[]>([]);
  const [tamanhos, setTamanhos] = useState<SelectType[]>([]);

  const formRef = useRef<FormHandles>(null);

  const handlerSubmitForm = () => {
    formRef.current?.submitForm();
  };

  const loadRegister = async () => {
    try {
      setVariacao(id);
      if (id !== '0' && id_produto.length > 1) {
        setRetroLoad(true);
        const response = await api.get(
          `produtos/${id_produto}/variacoes/${id}`,
        );

        const dataFetched = response.data;

        setRegisterData({
          ...dataFetched,
          cor_id: {
            value: dataFetched.cor_id,
            label: dataFetched.cor.descricao,
          },
          tamanho_id: {
            value: dataFetched.tamanho_id,
            label: dataFetched.tamanho.descricao,
          },
        });

        setTimeout(() => setRetroLoad(false), 300);
      } else {
        setRetroLoad(false);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const loadCores = async () => {
    try {
      const coresFetch = await api.get('produtos/variacao-grades?tipo=cor');
      const coresFetched: Array<SelectType> = [];

      coresFetch.data.forEach((item: VariacaoGradeType) => {
        if (item.ativo === true) {
          coresFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        }
      });

      setCores(coresFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const loadTamanhos = async () => {
    try {
      const tamanhosFetch = await api.get(
        'produtos/variacao-grades?tipo=tamanho',
      );
      const tamanhosFetched: Array<SelectType> = [];

      tamanhosFetch.data.forEach((item: VariacaoGradeType) => {
        if (item.ativo === true) {
          tamanhosFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        }
      });

      setTamanhos(tamanhosFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleSubmit = useCallback(
    async (data: VariacaoProdutoFormData) => {
      try {
        setFormError('');
        setInLoad(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          tamanho_id: Yup.string().required(
            'Informe um tamanho para essa variação.',
          ),
          cor_id: Yup.string().required('Informe uma cor para essa variação.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const response = await api[variacao.length > 1 ? 'put' : 'post'](
            `produtos/${id_produto}/variacoes${
              variacao.length > 1 ? `/${variacao}` : ''
            }`,
            data,
          );

          setVariacao(response.data.id);
          setInLoad(false);
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });

          onSave();
        } catch (e) {
          const message = handlerErrors(e);
          setInLoad(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setInLoad(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, id_produto, variacao, onSave],
  );

  useEffect(() => {
    loadCores();
    loadTamanhos();
    loadRegister();
    setInLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (retroLoad || inLoad) {
    return <Loader />;
  }

  return (
    <Content>
      <FormContainer>
        <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

        <Form ref={formRef} initialData={registerData} onSubmit={handleSubmit}>
          <FormContent>
            <FormBlock>
              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Tamanho</span>
                  <Select
                    name="tamanho_id"
                    loading={inLoad}
                    options={tamanhos}
                  />
                </InputContainer>

                <InputContainer disabled={inLoad}>
                  <span>Cor</span>
                  <div>
                    <Select name="cor_id" loading={inLoad} options={cores} />
                  </div>
                </InputContainer>
                <InputContainer disabled={inLoad}>
                  <span>Ativo?</span>
                  <div className="switch">
                    <Switch name="ativo" />
                  </div>
                </InputContainer>
              </InputsContainer>
            </FormBlock>
          </FormContent>

          <FormFooter>
            <Button
              type="button"
              background={colors.grey}
              onClick={onRequestClose}
            >
              Cancelar
            </Button>

            <Button
              type="button"
              onClick={handlerSubmitForm}
              background={colors.green}
              loading={inLoad}
            >
              Adicionar
            </Button>
          </FormFooter>
        </Form>
      </FormContainer>
    </Content>
  );
};

export default VariacaoProduto;
