import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaTags, FaHandPointUp } from 'react-icons/fa';

import * as Yup from 'yup';
import { getValidationErrors } from '~/utils';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import {
  AnchorContainer,
  AnchorMenu,
  AnchorLink,
  AnchorRef,
} from '~/components/Anchor';

import {
  GroupTagContainer,
  GroupTagContent,
  GroupFormsContent,
  GroupTagsEmptyTags,
} from './styles';

import {
  Header,
  FormContent,
  FormBlock,
  InputsContainer,
  InputContainer,
  Plate,
  FormFooter,
  Input,
  Button,
  Loader,
  ErrorMessage,
} from '~/components';

import Tags from './Tags';

import { GrupotagsType } from '~/types';

import { ParamsProps, currentGroupType } from './interface';
import { handlerErrors } from '~/utils/error';

const GroupTag: React.FC = () => {
  const { tipo, id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [grupoTags, setGrupoTags] = useState<currentGroupType>(undefined);

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const handlerSubmitGroupTags = async (data: GrupotagsType) => {
    try {
      setFormError('');
      setFormLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
      });

      const groupData = {
        nome: data.nome,
        tipo,
      };

      await schema.validate(groupData, { abortEarly: false });

      try {
        const response = await api[grupoTags ? 'put' : 'post'](
          `grupo-tags${grupoTags ? `/${grupoTags.id}` : ''}`,
          groupData,
        );

        history(`/grupo-de-tags/${tipo}/${response.data.id}`);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'Grupo de tags armazenado com sucesso!',
        });

        setFormLoading(false);
      } catch (e) {
        const message = handlerErrors(e);
        setFormLoading(false);
        setFormError(`${message}`);
      }
    } catch (err) {
      setFormLoading(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setFormError('Ocorreu um erro salvar o registro, tente novamente...');
    }
  };

  const handlerDispatchSubmitGroupTags = () => {
    formRef.current?.submitForm();
  };

  useEffect(() => {
    async function fetchGrupoTags() {
      if (id !== '0') {
        try {
          const response = await api.get(`grupo-tags/${id}`);
          const { data } = response;
          setGrupoTags(data);
        } catch (e) {
          // console.log(e);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchGrupoTags();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <GroupTagContainer>
      <Header />

      <AnchorContainer>
        <GroupTagContent>
          <AnchorMenu>
            <AnchorLink anchor="grupo-de-tags">Grupo de tags</AnchorLink>
            <AnchorLink anchor="tags-do-grupo">Tags do grupo</AnchorLink>
          </AnchorMenu>

          <GroupFormsContent>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <AnchorRef anchor="grupo-de-tags">
                <FormBlock>
                  <Plate
                    title="Grupo de tags"
                    subtitle="Adicione ou altere um grupo de tags"
                    icon={() => <FaTags size={32} color={colors.label} />}
                  />
                  <InputsContainer>
                    <Form
                      ref={formRef}
                      initialData={grupoTags}
                      onSubmit={handlerSubmitGroupTags}
                    >
                      <InputContainer width={400} disabled={formLoading}>
                        <span>Nome</span>
                        <div>
                          <Input name="nome" placeholder="Nome do Grupo" />
                        </div>
                      </InputContainer>
                    </Form>
                  </InputsContainer>
                  <InputsContainer>
                    {id !== '0' ? (
                      <AnchorRef anchor="tags-do-grupo">
                        <Tags id={id} />
                      </AnchorRef>
                    ) : (
                      <GroupTagsEmptyTags>
                        <FaHandPointUp size={32} color="#333" />
                        Você esta cadastrando um novo grupo.
                        <br />
                        Primeiramente dê um nome a ele e
                        <br />
                        em seguida clique em salvar.
                        <br />
                        Na sequência basta adicionar as tags!
                      </GroupTagsEmptyTags>
                    )}
                  </InputsContainer>
                </FormBlock>
              </AnchorRef>

              <FormFooter>
                <Link to={`/grupos-de-tags/${tipo}`}>
                  <Button background={colors.grey} type="button">
                    Voltar
                  </Button>
                </Link>
                <Button
                  type="button"
                  background={colors.green}
                  loading={formLoading}
                  onClick={handlerDispatchSubmitGroupTags}
                >
                  Salvar
                </Button>
              </FormFooter>
            </FormContent>
          </GroupFormsContent>
        </GroupTagContent>
      </AnchorContainer>
    </GroupTagContainer>
  );
};

export default GroupTag;
