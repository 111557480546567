import styled, { css } from 'styled-components';

import { ItemProps } from './interface';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
`;

export const Area = styled.div``;

export const Item = styled.div<ItemProps>`
  ${({ theme, $inativo }) => css`
    text-decoration: ${$inativo ? 'line-through' : 'inherit'};
    z-index: 99999;
    border: 0.2rem solid;
    border-color: ${theme.colors.defaultHighlight};
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${theme.fontSizes.large};
    margin-bottom: 1rem;
    color: ${$inativo ? theme.colors.defaultHighlight : theme.colors.label};
    cursor: move;

    &:last-child {
      margin-bottom: 0rem;
    }

    svg {
      margin-right: 1.5rem;
      cursor: pointer;
    }
  `};
`;

export const Control = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
  width: 100%;
  padding: 2rem 0;
`;
