import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useAuth } from '~/hooks/auth';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert2';

import { BsFillTriangleFill } from 'react-icons/bs';
import { TiDocumentText } from 'react-icons/ti';
import { FiPlus } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';

import {
  Header,
  Loader,
  Tags,
  Modal,
  FormContainer,
  FormContent,
  Plate,
  FormBlock,
  FormFooter,
  InputsContainer,
  InputContainer,
  NoPermission,
} from '~/components';

import {
  AnchorContainer,
  AnchorMenu,
  AnchorLink,
  AnchorRef,
} from '~/components/Anchor';

import { Input, InputNumber, Button, Select, ErrorMessage } from '~/components';

import Finder from '~/components/Finder';

import { getValidationErrors } from '~/utils';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import { handlerToNumber } from '~/utils/money';

import { GrupotagsType, TagType } from '~/types';

import {
  EmpresaType,
  TipoPagamentoType,
  CondicaoPagamentoType,
  SelectType,
} from '~/types';

import { ContaAvulsaFormData, ParamsProps } from './interface';

import { Container, Content, TagsContent, Tag } from './styles';
import { validarPermissao } from '~/utils/permissions';

const ContaAvulsa: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [tiposPagamentos, setTiposPagamentos] = useState<SelectType[]>([]);
  const [grupoTags, setGruposTags] = useState<GrupotagsType[]>([]);
  const [tagsModalVisible, setTagsModalVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>([]);

  const [condicoesPagamentosLoading, setCondicoesPagamentosLoading] =
    useState(false);
  const [condicoesPagamentos, setCondicoesPagamentos] = useState<SelectType[]>(
    [],
  );

  const [vendedor, setVendedor] = useState({ key: '', value: '' });
  const [parceiro, setParceiro] = useState({ key: '', value: '' });

  const formRef = useRef<FormHandles>(null);

  function clearForm() {
    setLoading(true);
    setVendedor({ key: '', value: '' });
    setParceiro({ key: '', value: '' });
    formRef.current?.reset();
    setLoading(false);
  }

  const handleSubmit = useCallback(
    async (data: ContaAvulsaFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          empresa_id: Yup.string().required('Selecione uma empresa válida.'),
          tipo: Yup.string().required('Selecione um tipo válido.'),
          tipo_pagamento_id: Yup.string().required(
            'Selecione um tipo de pagamento.',
          ),
          condicao_pagamento_id: Yup.string().required(
            'Selecione uma condição de pagamento.',
          ),
          valor: Yup.string().required('Informe um Valor'),
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        const contaData = data;
        delete contaData.tipo_pagamento_id;

        try {
          const tags: Array<string> = [];
          selectedTags.forEach(item => {
            tags.push(item.id);
          });

          await api.post('financeiros/contas', {
            ...contaData,
            parceiro_id: parceiro.key,
            vendedor_id: vendedor.key || null,
            valor: handlerToNumber(contaData.valor),
            tags,
          });

          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });

          clearForm();
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, parceiro, vendedor, selectedTags],
  );

  const loadCondicoesPagamentos = async (tipo_pagamento_id: string) => {
    try {
      setCondicoesPagamentosLoading(true);
      const condicoespagamentoFetch = await api.get(
        `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes`,
      );
      const condicoespagamentoFetched = [] as SelectType[];
      condicoespagamentoFetch.data.data.forEach(
        (item: CondicaoPagamentoType) => {
          condicoespagamentoFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        },
      );
      setCondicoesPagamentos(condicoespagamentoFetched);
      setCondicoesPagamentosLoading(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setCondicoesPagamentosLoading(false);
    }
  };

  useEffect(() => {
    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const loadTiposPagamentos = async () => {
      try {
        const tiposPagamentosFetch = await api.get(
          'financeiros/tipos-pagamento',
        );
        const tiposPagamentosFetched = [] as SelectType[];
        tiposPagamentosFetch.data.data.forEach((item: TipoPagamentoType) => {
          tiposPagamentosFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });
        setTiposPagamentos(tiposPagamentosFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const initialize = async () => {
      await loadEmpresas();
      await loadTiposPagamentos();
      setLoading(false);
    };

    initialize();
  }, [id]);

  const handlerOpenTags = (): void => {
    setTagsModalVisible(true);
  };

  const handlerCloseTags = (): void => {
    setTagsModalVisible(false);
  };

  const handlerRemoveTag = (tagId: string): void => {
    setSelectedTags(
      selectedTags.length
        ? [...selectedTags.filter(tag => tag.id !== tagId)]
        : [],
    );
  };

  const handlerExportTags = (tags: Array<TagType>): void => {
    setSelectedTags(tags);
    handlerCloseTags();
    swal.fire({
      title: 'Concluido!',
      text: `As tags foram salvas com sucesso!`,
      icon: 'success',
      confirmButtonColor: '#3085d6',
    });
  };

  useEffect(() => {
    async function loadGrupoTags() {
      try {
        const response = await api.get(`grupo-tags/?tipo=conta`);
        const { data } = response;
        setGruposTags(data);
      } catch (e) {
        // console.log(e);
      }
    }
    loadGrupoTags();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'incluir-compras')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      {grupoTags.length && tagsModalVisible ? (
        <Modal
          title="Cadastro de Tags"
          visible={tagsModalVisible}
          onRequestClose={handlerCloseTags}
        >
          <Tags
            groupsTags={grupoTags}
            onRequestClose={handlerCloseTags}
            defaultTags={selectedTags}
            onRequestExport={handlerExportTags}
          />
        </Modal>
      ) : null}
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
            <AnchorLink anchor="tags">Tags</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Conta Avulsa"
                      subtitle="Adicione uma conta avulsa no sistema"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Empresa</span>
                        <div>
                          <Select
                            name="empresa_id"
                            loading={loading}
                            options={empresas}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Parceiro</span>
                        <div>
                          <Finder
                            placeholderInput="Informe o nome ou código"
                            placeholderValue="Selecione um parceiro"
                            endpointRef="parceiros"
                            columnSearchRef="nome_fantasia"
                            keyRef="id"
                            labelRef="nome_fantasia"
                            defaultValue={parceiro}
                            onSelectValue={(key, value) => {
                              setParceiro({ key, value });
                            }}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Tipo</span>
                        <div>
                          <Select
                            name="tipo"
                            loading={loading}
                            options={[
                              { value: 'RECEBER', label: 'RECEBER', cor: '' },
                              { value: 'PAGAR', label: 'PAGAR', cor: '' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Tipo de Pagamento</span>
                        <div>
                          <Select
                            name="tipo_pagamento_id"
                            loading={loading}
                            options={tiposPagamentos}
                            onSelectedOption={item =>
                              loadCondicoesPagamentos(item.value)
                            }
                          />
                        </div>
                      </InputContainer>
                      {!condicoesPagamentosLoading ? (
                        <InputContainer disabled={formLoading}>
                          <span>Condição de Pagamento</span>
                          <div>
                            <Select
                              name="condicao_pagamento_id"
                              EmptyOptionsMessage="Selecione o tipo de pagamento"
                              loading={loading}
                              options={condicoesPagamentos}
                            />
                          </div>
                        </InputContainer>
                      ) : (
                        <Loader />
                      )}
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe uma descrição para a conta"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Valor</span>
                        <div>
                          <InputNumber
                            name="valor"
                            placeholder="Informe o valor"
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Vendedor</span>
                        <div>
                          <Finder
                            placeholderInput="Informe o nome ou código"
                            placeholderValue="Selecione um parceiro"
                            endpointRef="parceiros"
                            columnSearchRef="nome_fantasia"
                            keyRef="id"
                            labelRef="nome_fantasia"
                            defaultValue={vendedor}
                            onSelectValue={(key, value) => {
                              setVendedor({ key, value });
                            }}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
                <AnchorRef anchor="tags">
                  <FormBlock>
                    <Plate
                      title="Tags"
                      subtitle="Adicione as tags"
                      icon={() => (
                        <TiDocumentText size={32} color={colors.label} />
                      )}
                    >
                      {grupoTags.length ? (
                        <Button
                          type="button"
                          background={colors.darkGreen}
                          onClick={handlerOpenTags}
                        >
                          <FiPlus size={24} />
                        </Button>
                      ) : null}
                    </Plate>

                    {grupoTags.length ? (
                      <TagsContent>
                        {selectedTags && selectedTags.length ? (
                          selectedTags.map(tag => (
                            <Tag $cor={tag.cor} key={tag.id}>
                              {tag.nome}
                              <button
                                type="button"
                                onClick={() => handlerRemoveTag(tag.id)}
                              >
                                <AiFillDelete color={colors.label} size="26" />
                              </button>
                            </Tag>
                          ))
                        ) : (
                          <h4>Nenhuma tag adicionada ainda!</h4>
                        )}
                      </TagsContent>
                    ) : (
                      <TagsContent>
                        <h4>Nenhum grupo de tags disponível</h4>
                      </TagsContent>
                    )}
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default ContaAvulsa;
function handlerErrors(e: unknown) {
  throw new Error('Function not implemented.');
}
