import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import {
  ListItemMenuContainerProps,
  ListItemMenuContentProps,
} from './interface';

export const ListItemMenuContainer = styled.div<ListItemMenuContainerProps>`
  ${({ theme }) => css`
    margin: 0px;
    padding: 0px;

    button {
      color: ${theme.colors.label};
      text-decoration: underline;
      transition: all 0.5s ease;
      background: none;
      height: 2rem;

      &:hover {
        color: ${lighten(0.2, theme.colors.label)};
      }
    }
  `}
`;

export const ListItemMenuContent = styled.div<ListItemMenuContentProps>`
  ${({ theme, $visible, $inmodal }) => css`
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: ${$inmodal ? `${theme.colors.overlay}` : 'rgba(0,0,0,0)'};

    width: ${$inmodal ? '100%' : 'auto'};
    min-width: 20rem;
    height: ${$inmodal ? '100%' : 'auto'};
    position: ${$inmodal ? 'fixed' : 'absolute'};
    left: ${$inmodal ? '0rem' : 'auto'};
    top: ${$inmodal ? '0rem' : 'auto'};

    transform: ${$inmodal ? 'inherit' : 'translate(calc(-60% - 4rem), -50%)'};
    visibility: ${$visible ? '$visible' : 'hidden'};
    opacity: ${$visible ? '1' : '0'};

    main {
      max-width: ${$inmodal ? '45rem' : '80rem'};
      margin-left: ${$inmodal ? '15rem' : '0'};

      border: ${
        $inmodal
          ? `0.1rem solid ${theme.colors.default}`
          : `0.1rem solid ${theme.colors.default}`
      };
      box-shadow: ${$inmodal ? theme.shadows.highlight : 'inherit'};
      padding: ${$inmodal ? '0' : '2rem'};
      float: left;
      display: flex;
      border-radius: 0.5rem;
      flex-direction: column;
      background: ${theme.colors.background};
      border-radius: 0.5rem;

      h1 {
        display: ${$inmodal ? 'block' : 'none'};
        margin: 0rem;
        padding: 2rem;
        font-size: ${theme.fontSizes.default};
        text-align: right;
        font-weight: 700;
        letter-spacing: 0.08rem;
      }

      div {

        border-top: ${
          $inmodal ? `0.1rem solid ${theme.colors.default}` : `none`
        };

        border-bottom: ${
          $inmodal ? `0.1rem solid ${theme.colors.default}` : `none`
        };

        padding: ${$inmodal ? '2rem 2rem 1.5rem 2rem' : '0rem'};

        display: flex;
        flex-direction: ${$inmodal ? 'column' : 'column'};
        align-items: ${$inmodal ? 'flex-end' : 'flex-start'};
        justify-content: ${$inmodal ? 'flex-end' : 'flex-start'};
        float: left;
        width: ${$inmodal ? '100%' : 'auto'}
        margin: ${$inmodal ? '2rem 0' : '0'};

        button {
          text-decoration: none;
          margin: 0;
          float: left;
          background: ${$inmodal ? theme.colors.secondary : 'none'};
          font-size: ${$inmodal ? '1.3rem' : theme.fontSizes.large};
          text-transform: ${$inmodal ? 'uppercase' : 'inherit'};
          transition: all 0.5s ease;
          width: ${$inmodal ? '100%' : 'auto'};
          color: ${theme.colors.label};
          font-weight: ${$inmodal ? '700' : '500'};
          align-self: ${$inmodal ? 'flex-start' : 'flex-start'};
          border: ${$inmodal ? `.2rem solid ${theme.colors.default}` : 'none'};
          padding: ${$inmodal ? '3rem 2rem' : ' 0.5rem 0'};
          border-radius: ${$inmodal ? '0.8rem' : 'none'};
          margin-bottom: ${$inmodal ? '0.5rem' : '0.5rem'};
          margin-left: ${$inmodal ? '0' : '0'};
          letter-spacing: ${$inmodal ? '0.08rem' : ''};

          &:hover {
            opacity: ${$inmodal ? '1' : '0.5'};
            // text-decoration: ${$inmodal ? 'underline' : 'none'};
            background: ${$inmodal ? theme.colors.defaultHighlight : 'none'};
            // color: ${theme.colors.contrast};
          }
        }
      }
    }

    .btclose {
      margin-top: ${$inmodal ? '0rem' : '1rem'};
      align-self: center;
      cursor: pointer;
      transition: all 0.5s ease;
      text-decoration: none;
      background-color: ${theme.colors.primaryDark};
      padding: ${$inmodal ? '1.5rem' : '1rem 2rem'};
      height: ${$inmodal ? 'auto' : '3rem !important'};
      border-radius: ${$inmodal ? `0 0 0.3rem 0.3rem` : `0.3rem`};
      font-size: ${$inmodal ? theme.fontSizes.large : theme.fontSizes.default};
      text-transform: ${$inmodal ? 'uppercase' : 'inherit'};
      font-weight: ${$inmodal ? '700' : '500'};

      color: ${theme.colors.white};

      width: ${$inmodal ? '100%' : '100%'};
      position: ${$inmodal ? 'none' : 'none'};
      bottom: ${$inmodal ? '2rem' : 'inherit'};
      left: ${$inmodal ? '50%' : 'inherit'};
      transform: ${$inmodal ? '0' : '0'};

      &:hover {
        opacity: 0.8;
        color: ${lighten(0.2, theme.colors.white)};
      }
    }
  `}
`;
