import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { AiFillDelete, AiOutlineCloseCircle } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';

import * as Yup from 'yup';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { GroupsInfo, GroupsList, Tag, TagsInGroupColor } from './styles';

import {
  Button,
  Input,
  InputContainer,
  InputsContainer,
  Loader,
} from '~/components';

import spectrumImg from '~/assets/spectrum.png';

import { TagType } from '~/types';

import { ParamsProps, currentGroupType } from './interface';

const TagsInGroup: React.FC<ParamsProps> = ({ id }) => {
  const { colors } = useTheme();
  const { addToast } = useToast();
  const [grupoTags, setGrupoTags] = useState<currentGroupType>(undefined);

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [currentColor, setCurrentColor] = useState('');
  const [showColorPallet, setShowColorPallet] = useState(false);

  const fetchGrupoTags = useCallback(async () => {
    setLoading(true);
    try {
      const response_grupo = await api.get(`grupo-tags/${id}`);
      const { data: grupo } = response_grupo;
      const response_tags = await api.get(`grupo-tags/${id}/tags`);
      const { data: tags } = response_tags;

      setGrupoTags({ ...grupo, tags: [...tags] });
      setLoading(false);
    } catch (e) {
      // console.log(e);
      setLoading(false);
    }
  }, [id]);

  const handlerRemoveTag = (tag: TagType) => {
    swal
      .fire({
        title: `Deseja realmente remover a tag "${tag.nome}"?`,
        text: `Ao confirmar, a tag será removida do grupo!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          if (!grupoTags) return false;
          try {
            await api.delete(`grupo-tags/${id}/tags/${tag.id}`);
            fetchGrupoTags();
          } catch (e) {
            // console.log(e);
          }
          addToast({
            type: 'success',
            title: 'Concluido',
            description: `Tag "${tag.nome}" deletada com sucesso!`,
          });
        }
      });
  };

  const handleChangeCompleteColor = (color: ColorResult): void => {
    setCurrentColor(`${color.hex}`);
  };

  const handlerSubmitTagInGroup = async (data: TagType) => {
    try {
      if (!grupoTags) return false;

      setFormLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
      });

      const tagData = {
        nome: data.nome,
        cor: `${currentColor || 'rgba(0,0,0,0)'}`,
      };

      await schema.validate(tagData, { abortEarly: false });
      await api.post(`grupo-tags/${grupoTags.id}/tags`, tagData);

      fetchGrupoTags();

      addToast({
        type: 'success',
        title: 'Concluido',
        description: `Tag adicionada com sucesso no grupo ${grupoTags.nome}`,
      });

      setFormLoading(false);
    } catch (err) {
      setFormLoading(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Ocorreu um erro salvar o registro, tente novamente...',
      });
    }
  };

  useEffect(() => {
    if (id !== '0') {
      fetchGrupoTags();
    }
  }, [id, fetchGrupoTags]);

  if (loading || !grupoTags) {
    return <Loader />;
  }

  return (
    <Form ref={formRef} onSubmit={handlerSubmitTagInGroup}>
      <InputsContainer>
        <InputContainer width={400} disabled={formLoading}>
          <span>Adicionar Tags no grupo</span>
          <div>
            <TagsInGroupColor>
              <button
                onClick={() => {
                  setShowColorPallet(!showColorPallet);
                }}
                type="button"
              >
                <img src={spectrumImg} alt="OnStackERP" />
                {currentColor ? (
                  <span
                    style={{
                      backgroundColor: `${currentColor || 'inherit'}`,
                    }}
                  />
                ) : null}
              </button>
              {showColorPallet ? (
                <div>
                  <AiOutlineCloseCircle
                    onClick={() => setShowColorPallet(false)}
                    size={32}
                    color={colors.label}
                  />
                  <ChromePicker
                    color={currentColor}
                    onChangeComplete={handleChangeCompleteColor}
                    disableAlpha
                  />
                </div>
              ) : null}
            </TagsInGroupColor>
            <Input name="nome" placeholder="Tag" />
          </div>
        </InputContainer>
        <InputContainer width={200} disabled={formLoading}>
          <Button type="submit" background={colors.green} loading={formLoading}>
            Adicionar
          </Button>
        </InputContainer>
      </InputsContainer>

      {grupoTags.tags && grupoTags.tags.length ? (
        <>
          <GroupsList>
            {grupoTags.tags.map(tag => (
              <Tag $cor={tag.cor} key={tag.id}>
                {tag.nome}
                <button type="button" onClick={() => handlerRemoveTag(tag)}>
                  <AiFillDelete color={colors.label} size="26" />
                </button>
              </Tag>
            ))}
          </GroupsList>
        </>
      ) : (
        <GroupsInfo>{`Nenhuma tag no grupo "${grupoTags.nome}"`}</GroupsInfo>
      )}
    </Form>
  );
};

export default TagsInGroup;
