import styled, { css } from 'styled-components';

export const HeaderDescription = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.label};

  display: flex;
  align-items: top;
  justify-content: space-between;

  padding: 0.75rem;
  margin-bottom: 0.75rem;

  strong {
    font-weight: bold;
    font-size: 14px;
    display: flex;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    display: flex;
  }
`;

export const HeaderDescriptionInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderDescriptionInfoRight = styled.div`
  align-items: center;
  justify-content: flex-end;
`;

interface StatusTagProsp {
  status: string;
}
export const StatusTag = styled.span<StatusTagProsp>`
  display: inline-block;
  width: auto;

  min-width: min-content;
  max-width: max-content;

  padding: 0 0.5rem;

  font-weight: 500;
  font-size: 12px;

  ${({ theme, status }) =>
    status === 'ABERTA'
      ? css`
          background: ${theme.colors.green};
        `
      : css`
          background: ${theme.colors.error};
        `}

  color: ${({ theme }) => theme.colors.white};

  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 0.5rem;
`;
