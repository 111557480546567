import { AiOutlineHome, AiOutlineCodepen } from 'react-icons/ai';
import { MdAttachMoney } from 'react-icons/md';
import { FiUsers, FiShoppingCart, FiFlag } from 'react-icons/fi';
// import { GoGraph } from 'react-icons/go';

const menu = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    icon: AiOutlineHome,
    page: '/dashboard',
    submenu: [],
  },
  {
    id: 'produtos',
    name: 'Produtos',
    icon: AiOutlineCodepen,
    page: '',
    submenu: [
      {
        id: 'cadastro',
        name: 'Cadastro',
        options: [
          {
            id: 'listar-produtos',
            label: 'Lista',
            page: '/produtos',
            tag: 'Listar produtos',
          },
          {
            id: 'casdastrar-alterar-produtos',
            label: 'Incluir/Alterar',
            page: '/produto/0',
            tag: 'Cadastrar/alterar produtos',
          },
          {
            id: 'cadastrar-tags-produtos',
            label: 'Tags',
            page: '/grupos-de-tags/produto',
            tag: 'Cadastrar tags de produtos',
          },
          {
            id: 'unidades',
            label: 'Unidades',
            page: '/unidades',
            tag: 'Cadastrar unidades de produtos',
          },
          {
            id: 'variacao-grade-produtos',
            label: 'Variações de grade',
            page: '/variacoes-grade',
            tag: 'Cadastrar variações de grade de produtos',
          },
          {
            id: 'tabela-precos',
            label: 'Tabelas de Preço',
            page: '/tabelas-de-preco/0',
            tag: 'Cadastrar tabela de preço',
          },
        ],
      },
      {
        id: 'etiquetas',
        name: 'Etiquetas',
        options: [
          {
            id: 'imprimir-etiqueta-produto',
            label: 'Imprimir Etiquetas',
            page: '/impressao-de-etiquetas',
            tag: 'Imprimir etiqueta de produto',
          },
          {
            id: 'modelos-de-etiquetas',
            label: 'Modelos de Impressão',
            page: '/etiquetas',
            tag: 'Modelos etiqueta de produto',
          },
        ],
      },
      {
        id: 'relatorios-produtos',
        name: 'Relatórios',
        options: [
          {
            id: 'lista-produtos',
            label: 'Listagem geral',
            page: '#/',
            tag: 'Lista de produtos',
          },
          {
            id: 'estoques',
            label: 'Estoques',
            page: '/estoques',
            tag: 'Estoques',
          },
          {
            id: 'precos',
            label: 'Preços',
            page: '/precos',
            tag: 'Preços',
          },
        ],
      },
    ],
  },
  {
    id: 'financeiro',
    name: 'Financeiro',
    icon: MdAttachMoney,
    page: '',
    submenu: [
      {
        id: 'b.1',
        name: 'Contas',
        options: [
          {
            id: 'incluir-conta-avulsa',
            label: 'Incluir',
            page: '/conta-avulsa',
            tag: 'Incluir',
          },
          {
            id: 'consultar-conta-avulsa',
            label: 'Consultar',
            page: '/contas',
            tag: 'Consultar contas',
          },
        ],
      },
      {
        id: 'cadastro-financeiro',
        name: 'Cadastro',
        options: [
          {
            id: 'contas-correntes',
            label: 'Contas Correntes',
            page: '/contas-correntes',
            tag: 'Contas Correntes',
          },
          {
            id: 'instituicao-financeira',
            label: 'Instituição Financeira',
            page: '/instituicoes-financeiras',
            tag: 'Instituição Financeira',
          },
          {
            id: 'tags-financeiro',
            label: 'Tags',
            page: '/grupos-de-tags/conta',
            tag: 'Tags de Conta',
          },
          {
            id: 'tipo-de-pagamento',
            label: 'Tipo de Pagamento',
            page: '/tipos-de-pagamentos',
            tag: 'Tipo de Pagamento',
          },
        ],
      },
    ],
  },
  {
    id: 'parceiros',
    name: 'Parceiros',
    icon: FiUsers,
    page: '',
    submenu: [
      {
        id: 'cadastro-parceiros',
        name: 'Cadastro',
        options: [
          {
            id: 'lista-parceiros',
            label: 'Lista',
            page: '/parceiros',
            tag: 'Listar parceiros',
          },
          {
            id: 'incluir-alterar-parceiros',
            label: 'Incluir/Alterar',
            page: '/parceiro/0',
            tag: 'Cadastrar/alterar parceiros',
          },
          {
            id: 'tags-de-parceiros',
            label: 'Tags',
            page: '/grupos-de-tags/parceiro',
            tag: 'Cadastrar tags de parceiros',
          },
          {
            id: 'parmissoes',
            label: 'Permissões',
            page: '/acl',
            tag: 'Permissões',
          },
        ],
      },
    ],
  },
  {
    id: 'movimentacao',
    name: 'Movimentação',
    icon: FiShoppingCart,
    page: '',
    submenu: [
      {
        id: 'operacoes-movimentacao',
        name: 'Operações',
        options: [
          {
            id: 'pedido',
            label: 'Pedido',
            page: '/pedido/0',
            tag: 'Pedido',
          },
          {
            id: 'compra',
            label: 'Compra',
            page: '/importacao-nfe',
            tag: 'Compra',
          },
          {
            id: 'compra-manual',
            label: 'Compra Manual',
            page: '/compra-manual/0',
            tag: 'Compra Manual',
          },
        ],
      },
      {
        id: 'cadastro-movimentacao',
        name: 'Cadastro',
        options: [
          {
            id: 'naturezas-operacao',
            label: 'Natureza de Operação',
            page: '/naturezas-de-operacoes',
            tag: 'Natureza de Operações',
          },
          {
            id: 'status-pedido',
            label: 'Status de Pedido',
            page: '/status-de-pedidos',
            tag: 'Status de Pedido',
          },
        ],
      },
      {
        id: 'consulta-movimentacao',
        name: 'Consulta',
        options: [
          {
            id: 'consulta-pedidos',
            label: 'Pedidos',
            page: '/pedidos',
            tag: 'Consulta de Pedidos',
          },
          {
            id: 'consulta-compras',
            label: 'Compras',
            page: '/compras',
            tag: 'Consulta de Compras',
          },
        ],
      },
    ],
  },
  {
    id: 'fiscal',
    name: 'Fiscal',
    icon: FiFlag,
    page: '',
    submenu: [
      {
        id: 'tributacao',
        name: 'Tributação',
        options: [
          {
            id: 'lista-tributacoes',
            label: 'Lista',
            page: '/tributacoes',
            tag: 'Listar Tributações',
          },
          {
            id: 'fiscal-tributacao-1',
            label: 'Incluir/Alterar',
            page: '/tributacao/0',
            tag: 'Tributação',
          },
        ],
      },
      {
        id: 'documentos',
        name: 'Documentos',
        options: [
          {
            id: 'nfe',
            label: 'Novo',
            page: '/nfe/0',
            tag: 'NFE',
          },
          {
            id: 'documentos-fiscais',
            label: 'Consulta',
            page: '/documentos-fiscais',
            tag: 'Documentos Fiscais',
          },
        ],
      },
    ],
  },
];

export default menu;
