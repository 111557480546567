import React from 'react';
import { FiArrowRightCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { Container, Title } from './styles';

import { ACLPermissionProps } from './interface';

const ACLPermission: React.FC<ACLPermissionProps> = ({
  permission,
  active,
  onConfigPermission,
}) => {
  const { colors } = useTheme();

  return (
    <Container>
      <Title $active={active} onClick={() => onConfigPermission(permission)}>
        <FiArrowRightCircle size={22} color={colors.label} />
        <span>{permission.nome}</span>
      </Title>
    </Container>
  );
};

export default ACLPermission;
