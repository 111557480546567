const handlerToNumber = (value: string): number => {
  if (!value || !value.length) return 0;
  let currentNumber = value.replaceAll('R$', '');
  currentNumber = value.replaceAll(' ', '');
  currentNumber = value.replaceAll(',', '.');
  const nCurrentNumber = parseFloat(currentNumber);
  if (Number.isNaN(nCurrentNumber)) {
    return 0;
  }
  return nCurrentNumber;
};

const handlerNumberToString = (value: number): string => {
  const currentPrice = handlerToNumber(`${value}`).toFixed(2);
  if (Number.isNaN(currentPrice)) {
    return '0,00';
  }
  return `${currentPrice.replace('.', ',')}`;
};

export { handlerToNumber, handlerNumberToString };
