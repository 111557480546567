import styled, { css } from 'styled-components';

import { ValProp, InformationProp } from './interface';

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.big};
    margin: 2rem 0;
    font-weight: 700;
  `}
`;

export const Informations = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.defaultHighlight};
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    border-radius: 0.6rem;
    margin-bottom: 2rem;
  `}
`;

export const Information = styled.div<InformationProp>`
  ${({ theme, $isodd }) => css`
    background: ${$isodd ? theme.colors.defaultHighlight : 'rgba(0,0,0,0)'};
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
  `}
`;

export const Space = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.defaultHighlight};
    width: 100%;
    float: left;
    height: 0.2rem;
    margin: 1rem 0 0 0;
  `}
`;

export const Line = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  margin: 0 0 0.5rem 0;
`;

export const Block = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    margin-right: 0.5rem;
    font-size: ${theme.fontSizes.default};
    font-weight: 700;
  `}
`;

export const Val = styled.span<ValProp>`
  ${({ theme, $color }) => css`
    color: ${$color || theme.colors.label};
    font-size: ${theme.fontSizes.default};
    font-weight: 700;
  `}
`;

export const Footer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
