import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import swal from 'sweetalert2';

import { BiSearch } from 'react-icons/bi';
import { BsFillPersonLinesFill } from 'react-icons/bs';

import api from '~/services/api';

import Header from '~/components/Header';
import List from '~/components/List';
import Loader from '~/components/Loader';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  FindParceiro,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Plate,
  ReactTooltip,
  TransacoesPagamento,
} from '~/components';

import {
  ComoPagouControl,
  ComoPagouControlIc,
  Container,
  Content,
  ContentRow,
  Information,
} from './styles';

import { formatCustomBR } from '~/utils/formatDate';
import { handlerNumberToString } from '~/utils/money';
// import { virtualID } from '~/utils/id';

import { ParamsProps } from './interface';

import { ParceiroType, PedidoType } from '~/types';

const ResumoDoPedido: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();

  const [loading, setLoading] = useState(true);

  const [pedido, setPedido] = useState<PedidoType | null>(null);
  const [vendedor, setVendedor] = useState<ParceiroType | null>(null);
  const [cliente, setCliente] = useState<ParceiroType | null>(null);

  const [selecionarVendedor, setSelecionarVendedor] = useState(false);
  const [selecionarCliente, setSelecionarCliente] = useState(false);

  const [transacaoModalConta, setTransacaoModalConta] = useState<any | null>(
    null,
  );

  const alterarVendedor = () => {
    setSelecionarVendedor(true);
  };

  const alterarCliente = () => {
    setSelecionarCliente(true);
  };

  const abrirModalTransacoesFinanceiras = (filters: any) => {
    setTransacaoModalConta(filters);
  };

  const fecharModalTransacoesFinanceiras = () => {
    setTransacaoModalConta(null);
  };

  const carregarVendedor = async (pId: string) => {
    try {
      setLoading(true);
      const response = await api.get(`parceiros/${pId}`);
      const { data } = response;
      setLoading(false);
      if (data && data.status !== 'error') {
        setVendedor(data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const carregarCliente = async (pId: string) => {
    try {
      setLoading(true);
      const response = await api.get(`parceiros/${pId}`);
      const { data } = response;
      setLoading(false);
      if (data && data.status !== 'error') {
        setCliente(data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const carregarPedido = async (pId: string) => {
    try {
      setLoading(true);
      const response = await api.get(`pedido/${pId}`);
      const { data } = response;
      setLoading(false);
      if (data && data.status !== 'error') {
        setPedido(data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const calcularTotalContas = (contas: Array<{ valor: number }>) => {
    return contas.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc += item.valor;
      return acc;
    }, 0);
  };

  const calcularTotalContasPagas = (contas: Array<{ totalPago: number }>) => {
    return contas.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc += item.totalPago;
      return acc;
    }, 0);
  };

  const salvarPedido = () => {
    swal
      .fire({
        title: `Deseja realmente salvar as alterações no pedido?`,
        text: `Ao confirmar, as atualizaçẽos serão processado!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          // SALVAR ALTERAÇÃO PEDIDO (CLIENTE E VENDEDOR)
          swal.fire({
            title: `Pedido atualizado com sucesso!`,
          });
        }
      });
  };

  useEffect(() => {
    if (id) {
      carregarPedido(id);
    }
  }, [id]);

  useEffect(() => {
    if (pedido) {
      carregarVendedor(`${pedido.vendedor_id}`);
      carregarCliente(`${pedido.cliente_id}`);
    }
  }, [pedido]);

  if (loading || pedido == null) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />

      {transacaoModalConta ? (
        <TransacoesPagamento
          visible
          filters={transacaoModalConta}
          onRequestClose={fecharModalTransacoesFinanceiras}
        />
      ) : null}

      {selecionarCliente ? (
        <FindParceiro
          onlySearch
          ignoreLabel
          openned
          tipos="cliente"
          placeholderInput="Digite o nome do cliente"
          onSelectValue={selecionados => {
            setSelecionarCliente(false);
            if (selecionados && selecionados.length && selecionados[0].id) {
              carregarCliente(selecionados[0].id);
            }
          }}
        />
      ) : null}

      {selecionarVendedor ? (
        <FindParceiro
          onlySearch
          ignoreLabel
          openned
          tipos="funcionario"
          placeholderInput="Digite o nome do vendedor"
          onSelectValue={selecionados => {
            setSelecionarVendedor(false);
            if (selecionados && selecionados.length && selecionados[0].id) {
              carregarVendedor(selecionados[0].id);
            }
          }}
        />
      ) : null}

      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="detalhes-do-pedido">
              Detalhes do Pedido
            </AnchorLink>
            <AnchorLink anchor="vendedor">Vendedor</AnchorLink>
            <AnchorLink anchor="cliente">Cliente</AnchorLink>
            <AnchorLink anchor="produtos">Produtos</AnchorLink>
            <AnchorLink anchor="resumo">Resumo</AnchorLink>
            <AnchorLink anchor="lancamentos-no-caixa">
              Lançamentos no Caixa
            </AnchorLink>
            <AnchorLink anchor="contas">Contas</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <FormContent>
              <AnchorRef anchor="detalhes-do-pedido">
                <FormBlock>
                  <Plate
                    title={`Pedido: ${id}`}
                    subtitle={`${formatCustomBR(new Date(pedido?.created_at))}`}
                    icon={() => (
                      <BsFillPersonLinesFill size={32} color={colors.label} />
                    )}
                  />

                  <Information>
                    <h1 id="detalhes-do-pedido">DETALHES DO PEDIDO</h1>
                    <i />
                    <div>
                      <p>
                        <span>
                          <b>Código:</b>
                          {pedido.codigo}
                        </span>

                        <span>
                          <b>Data de criação:</b>
                          {`${formatCustomBR(new Date(pedido.created_at))}`}
                        </span>

                        <span>
                          <b>Data de modificação:</b>
                          {`${formatCustomBR(new Date(pedido.updated_at))}`}
                        </span>
                      </p>
                      <p>
                        <span>
                          <b>Natureza da Operação:</b>
                          {pedido.natureza_operacao.descricao}
                        </span>

                        <span>
                          <b>Tabela de preço:</b>
                          {pedido.tabela_preco.descricao}
                        </span>

                        <span>
                          <b>Digitador:</b>
                          {pedido.digitador.nome_razao}
                        </span>
                      </p>

                      <p>
                        <span>
                          <b>Status do Pedido:</b>
                          {pedido.status_pedido || 'INDEFINIDO'}
                        </span>
                      </p>
                    </div>
                    <h1 id="cliente">
                      CLIENTE
                      <Button onClick={alterarCliente}>ALTERAR</Button>
                    </h1>
                    <i />

                    <div>
                      {cliente ? (
                        <p>
                          <span>
                            <b>Código:</b>
                            {cliente.id}
                          </span>
                          <span>
                            <b>Nome:</b>
                            {cliente.nome_fantasia}
                          </span>
                          <span>
                            <b>Documento:</b>
                            {cliente.cnpj_cpf || 'NÃO POSSUI'}
                          </span>
                        </p>
                      ) : null}
                    </div>
                    <h1 id="vendedor">
                      VENDEDOR
                      <Button onClick={alterarVendedor}>ALTERAR</Button>
                    </h1>
                    <i />

                    <div>
                      {vendedor ? (
                        <p>
                          <span>
                            <b>Código:</b>
                            {vendedor.id}
                          </span>
                          <span>
                            <b>Nome:</b>
                            {vendedor.nome_fantasia}
                          </span>
                          <span>
                            <b>Documento:</b>
                            {vendedor.cnpj_cpf || 'NÃO POSSUI'}
                          </span>
                        </p>
                      ) : null}
                    </div>

                    <h1 id="produtos">PRODUTOS</h1>
                    <i />
                    <div>
                      <List
                        header={[
                          {
                            column: 'codigo',
                            label: 'CÓDIGO',
                            width: 200,
                            search: false,
                          },
                          {
                            column: 'descricao',
                            label: 'DESCRIÇÃO',
                            search: false,
                          },
                          {
                            column: 'preco',
                            label: 'PREÇO',
                            search: false,
                          },
                          {
                            column: 'unidade',
                            label: 'UNIDADE',
                            search: false,
                          },
                          {
                            column: 'total',
                            label: 'TOTAL',
                            search: false,
                          },
                        ]}
                        data={[
                          ...pedido.itens.map(item => ({
                            ...item,
                            descricao: item.produto_descricao,
                            codigo: item.produto_id,
                          })),
                        ]}
                        options={[]}
                        hideFooter
                      />
                    </div>
                    <h1 id="resumo">RESUMO</h1>
                    <i />
                    <div>
                      <span>
                        <b>SUBTOTAL:</b>
                        {`R$ ${handlerNumberToString(pedido.valor_produtos)}`}
                      </span>
                      <span>
                        <b>FRETE:</b>
                        {`R$ ${handlerNumberToString(pedido.frete)}`}
                      </span>
                      <span>
                        <b>DESCONTO:</b>
                        {`- R$ ${handlerNumberToString(pedido.desconto)}`}
                      </span>
                      <i />
                      <span>
                        <b>TOTAL:</b>
                        {`R$ ${handlerNumberToString(pedido.valor_total)}`}
                      </span>
                    </div>

                    <h1 id="lancamentos-no-caixa">LANÇAMENTOS NO CAIXA</h1>
                    <i />
                    <div>
                      <List
                        header={[
                          {
                            column: 'operacao',
                            label: 'OPERAÇÃO',
                            search: false,
                          },
                          {
                            column: 'descricao',
                            label: 'DESCRIÇÃO',
                            search: false,
                          },
                          {
                            column: 'data',
                            label: 'DATA',
                            search: false,
                          },
                          {
                            column: 'conta_corrente',
                            label: 'CONTA CORRENTE',
                            search: false,
                          },
                          {
                            column: 'valor',
                            label: 'VALOR',
                            search: false,
                          },
                        ]}
                        data={[
                          ...pedido.lancamentos_caixa.map(lancamento => ({
                            ...lancamento,
                            conta_corrente: lancamento.conta_corrente.descricao,
                            data: `${formatCustomBR(
                              new Date(lancamento.created_at),
                            )}`,
                            valor: `R$ ${handlerNumberToString(
                              lancamento.valor,
                            )}`,
                          })),
                        ]}
                        options={[]}
                        hideFooter
                      />
                    </div>

                    <h1 id="contas">CONTAS</h1>
                    <i />
                    <div>
                      <List
                        header={[
                          {
                            column: 'id',
                            label: 'ID',
                            search: false,
                          },
                          {
                            column: 'descricao',
                            label: 'DESCRIÇÃO',
                            search: false,
                          },
                          {
                            column: 'data',
                            label: 'DATA',
                            search: false,
                          },
                          {
                            column: 'valor',
                            label: 'VALOR',
                            search: false,
                          },
                          {
                            column: 'pago',
                            label: 'PAGO',
                            search: false,
                          },
                        ]}
                        data={[
                          ...pedido.pagamento.contas.map(conta => ({
                            ...conta,
                            conta_id: conta.id,
                            id: conta.id,
                            descricao: conta.condicao_pagamento.descricao,
                            data: `${formatCustomBR(
                              new Date(conta.condicao_pagamento.created_at),
                            )}`,
                            valor: `R$ ${handlerNumberToString(conta.valor)}`,
                            pago: `R$ ${handlerNumberToString(
                              conta.totalPago,
                            )}`,
                          })),
                        ]}
                        options={[
                          {
                            label: 'Como foi paga',
                            onPress: item => {
                              abrirModalTransacoesFinanceiras({
                                contas: item.conta_id,
                              });
                            },
                          },
                        ]}
                        hideFooter
                      />
                    </div>

                    <span style={{ color: '#00AAE0' }}>
                      <b>TOTAL:</b>
                      {`R$ ${handlerNumberToString(
                        calcularTotalContas(pedido.pagamento.contas),
                      )}`}
                    </span>
                    <span style={{ color: '#ff4d4d' }}>
                      <b>EM ABERTO:</b>
                      {`R$ ${handlerNumberToString(
                        calcularTotalContas(pedido.pagamento.contas) -
                          calcularTotalContasPagas(pedido.pagamento.contas),
                      )}`}
                    </span>
                    <i />
                    <ContentRow>
                      <span style={{ color: '#2fc43c' }}>
                        <b>PAGO:</b>
                        {`R$ ${handlerNumberToString(
                          calcularTotalContasPagas(pedido.pagamento.contas),
                        )}`}
                      </span>
                      <div>
                        <ComoPagouControl
                          data-tooltip-id="search-button"
                          onClick={() =>
                            abrirModalTransacoesFinanceiras({
                              operacao: 'PEDIDO',
                              operacao_id: pedido.id,
                            })
                          }
                        >
                          <ReactTooltip
                            id="search-button"
                            content="Como pagou?"
                          />
                          <ComoPagouControlIc>
                            <BiSearch size={22} color={colors.label} />
                          </ComoPagouControlIc>
                        </ComoPagouControl>
                      </div>
                    </ContentRow>
                  </Information>
                </FormBlock>
              </AnchorRef>
              <FormFooter>
                <Link to="/pedidos">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Link to="/pedido">
                  <Button type="button" background={colors.contrast}>
                    Novo
                  </Button>
                </Link>
                <Button
                  type="submit"
                  onClick={salvarPedido}
                  background={colors.green}
                >
                  Salvar
                </Button>
              </FormFooter>
            </FormContent>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default ResumoDoPedido;
