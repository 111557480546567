import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

import { IfCostProps } from './interface';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  width: 100%;
`;

export const InputsDivisor = styled.div`
  display: flex;
  width: 100%;
  float: left;
  padding: 20px 10px 0 10px;

  span {
    float: left;
    height: 0.1rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const IFCostShow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  padding: 0 1rem;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #313030;
    padding: 2rem 4rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
  }

  p {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    float: left;
    margin: 0 0 0 1rem;
    padding: 0px;

    small {
      font-size: 1.2rem;
    }

    strong {
      font-size: 2.2rem;
      text-align: left;
      font-weight: 700;
    }
  }

  button {
    background: #313030;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.8rem;
    margin: 0 1rem;

    svg {
      transition: all 0.4s ease;
    }

    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }
`;

export const IFCostHide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    background: #313030;
    padding: 10px 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0 0 1rem 1rem;
    margin-top: -1px;
    border-top: none;
    transition: all 0.4s ease;

    svg {
      transition: all 0.4s ease;
    }

    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }
`;

export const IfCost = styled.div<IfCostProps>`
  ${({ $disabled }) => css`
    pointer-events: ${$disabled ? 'none' : 'all'};
    opacity: ${$disabled ? '0.5' : '1'};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const InputCurrency = styled(CurrencyInput).attrs({
  prefix: 'R$ ',
  decimalSeparator: ',',
  groupSeparator: '.',
  decimalsLimit: 3,
})`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    min-width: 20rem;
    float: left;
    padding: 2rem 1rem 0 1rem;
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    padding: 1rem;
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    font-weight: 500;
  `}
`;

export const InputPercent = styled(CurrencyInput).attrs({
  prefix: '',
  suffix: '%',
  decimalSeparator: ',',
  groupSeparator: '.',
  decimalsLimit: 2,
})`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    min-width: 20rem;
    float: left;
    padding: 2rem 1rem 0 1rem;
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    padding: 1rem;
    width: 100%;
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.large};
    font-weight: 500;
  `}
`;
