import styled, { css } from 'styled-components';
import ScrollArea from 'react-scrollbar';

import { LogsContainerProps, InputContainerProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogsContainer = styled.div<LogsContainerProps>`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 20px;
    bottom: 20px;
    left: 0rem;
    top: 0rem;
    background: ${theme.colors.overlay};
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all 0.5s ease;
    padding: 0rem;

    .logs-scroll-scrollarea-content {
      float: left;
      width: 100%;
      padding-right: 2rem;
    }
  `}
`;

export const Scroll = styled(ScrollArea).attrs({
  className: 'logs-scroll-scrollarea',
  contentClassName: 'logs-scroll-scrollarea-content',
  contentStyle: {
    paddingBottom: 0,
  },
  verticalScrollbarStyle: {
    background: '#0175a7',
    opacity: '1',
  },
  verticalContainerStyle: {
    background: 'rgba(255,255,255,0.2)',
    opacity: '1',
  },
})`
  width: 100%;
  float: left;
  height: 100%;
`;

export const ScrollContent = styled.div`
  width: 100%;
  float: left;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 6rem 6rem 6rem 8rem;
`;

export const LogsContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 0.6rem;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: column;
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    padding: 2rem 2rem;
    margin-right: 2rem;

    header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding-bottom: 2rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 2rem;

      div {
        flex-direction: row;
        display: flex;
        align-items: center;
      }

      h1 {
        font-size: ${theme.fontSizes.large};
        margin: 0px 0px 0px 10px;
        padding: 0px;
      }

      button {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
        width: 40px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.5 ease;
        transition: all 0.4s ease;
        margin-left: 1rem;
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    main {
      float: left;
      width: 100%;
    }
  `}
`;

// export const LogsToogleControl = styled.button`
//   ${({ theme }) => css`
//     float: left;
//     background: ${theme.colors.defaultHighlight};
//     /* position: fixed;
//     left: 20px;
//     bottom: 20px; */
//     width: 4rem;
//     height: 4rem;
//     border-radius: ${theme.radio.default};
//     border: 0.1rem solid ${theme.colors.default};
//     box-shadow: 0.1rem 0.1rem 0.5rem ${theme.shadows.default};
//     transition: all 0.5 ease;

//     &:hover {
//       background: ${theme.colors.default};
//     }

//     svg {
//       fill: ${theme.colors.white};
//     }
//   `}
// `;

export const LogsContentFilters = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    float: left;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 8rem;

    footer {
      width: 100%;
      height: 60px;
      display: flex;
      float: left;
      align-items: center;
      justify-content: center;
      margin-bottom: -60px;

      button {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.1);
        width: 60px;
        height: 60px;
        border-radius: 0 0 5px 5px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-top: none;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.5 ease;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  `}
`;

export const LogsContentFiltersContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    padding: 0rem 1rem 2rem 1rem;
  `}
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding: 0rem;
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

  flex: ${props => (props.$width ? 0 : 1)};
  width: ${props => (props.$width ? `${props.$width}px` : 'auto')} !important;
  pointer-events: ${props => (props.$disabled ? 'none' : 'all')};
  opacity: ${props => (props.$disabled ? '0.2' : '1')};

  ${({ theme }) => css`
    span {
      margin-bottom: 0.5rem;
      font-size: ${theme.fontSizes.default};
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease;
        background: none;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .switch {
      margin-top: 1rem;
    }
  `}
`;

export const LogsContentFiltersFormSubmit = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 2rem;
    padding: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    button {
      background: #20c05c;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      transition: all 0.5 ease;

      span {
        color: #fff;
        margin-left: 1rem;
        font-weight: 600;
        font-size: ${theme.fontSizes.default};
      }

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  `}
`;

export const LogsCloseButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    transition: all 0.5s ease;
    background: none;
    align-self: flex-start;

    &:hover {
      opacity: 0.4;
    }
  `}
`;
