import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { ProgressProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.big};
    padding: 2rem 2rem 0 2rem;
    font-weight: 700;
    margin-top: 2rem;
  `};
`;

export const InfoHelp = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.large};
    align-self: flex-end;
    height: 3.5rem;
  `};
`;

export const ListArea = styled.div`
  padding: 2rem 2rem 0 2rem;
  float: left;
  display: flex;
  flex-direction: column;
`;

export const Add = styled.div`
  float: left;
  margin-left: 1rem;
  width: 12rem;
`;

export const AssocBtn = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 17rem !important;
    background-color: ${theme.colors.orange};
    border-radius: ${theme.radio.default};
    transition: all 0.5s ease;
    height: 4.5rem;
    float: left;
    margin: 0 0 0 1.5rem !important;

    &:hover {
      background: ${darken(0.1, theme.colors.orange)};
    }
  `}
`;

export const Progress = styled.div<ProgressProps>`
  ${({ $percent, theme }) => css`
    float: left;
    width: 100%;
    margin: 4rem 0 0 0;
    padding: 0 2rem;

    h2 {
      float: left;
      font-size: ${theme.fontSizes.large};
      color: ${theme.colors.label};
      margin-bottom: 1rem;
    }

    div {
      float: left;
      width: 100%;
      border-radius: 0.5rem;
      height: 3rem;
      overflow: hidden;
      background: ${theme.colors.contrast};

      span {
        float: left;
        width: ${`${$percent || 0}%`};
        height: 3rem;
        border-radius: 0.5rem 0 0 0.5rem;
        background: ${theme.colors.green};
      }
    }
  `}
`;
