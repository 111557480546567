import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    float: left;
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  p {
    float: left;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.default};
    opacity: 0.8;
    margin: 1rem 0 2rem 0;
  }

  a {
    border: 0.2rem solid ${({ theme }) => theme.colors.defaultHighlight};
    background: ${({ theme }) => theme.colors.contrast};
    font-size: ${({ theme }) => theme.fontSizes.default};
    color: ${({ theme }) => theme.colors.white};
    padding: 2rem;
    float: left;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: underline;

    &:hover {
      background: ${({ theme }) => theme.colors.green};
    }
  }
`;
