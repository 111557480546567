import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils';
import Modal from '../Modal';
import Button from '../form/Button';
import ErrorMessage from '../form/ErrorMessage';
import Input from '../form/Input';
import InputContainer from '../form/InputContainer';

import { ButtonsGroup, Container, FormContainer } from './styles';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import Loader from '../Loader';
import { AlterarSenhaProps, ModalAlterarSenhaProps } from './interface';

const ModalAlterarSenha: React.FC<ModalAlterarSenhaProps> = ({
  visible,
  title,
  onRequestClose,
  parceiro_id,
}) => {
  const { colors } = useTheme();
  const { getAuthUser } = useAuth();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const formRef = useRef<FormHandles>(null);

  const schemaValidationPassword = Yup.object().shape({
    // senha_anterior: Yup.string().when('$exist', {
    //   is: (exist: any) => !!exist,
    //   then: Yup.string().required('Obrigatório informar a senha anterior.'),
    //   otherwise: Yup.string(),
    // }),
    senha_anterior: Yup.string().when({
      is: (exists: string) => !!exists,
      then: rule =>
        rule.test(
          'validacao_senha_anterior',
          'Obrigatório informar a senha anterior.',
          senha_anterior =>
            senha_anterior ? senha_anterior.length > 0 : undefined,
        ),
    }),
    nova_senha: Yup.string()
      .min(6, 'Digite no mínimo 6 caracteres.')
      .required('Nova senha obrigatória.'),
    nova_senha_confirm: Yup.string().oneOf(
      [Yup.ref('nova_senha')],
      'Senhas não conferem.',
    ),
  });

  const clearForm = useCallback(async () => {
    await setTimeout(() => {
      formRef?.current?.reset();
    }, 100);

    const formIsClear =
      !formRef?.current?.getFieldValue('senha_anterior') ||
      formRef?.current?.getFieldValue('senha_anterior').length === 0;

    setIsLoading(!formIsClear);
  }, []);

  const handleSubmit = useCallback(
    async (data: AlterarSenhaProps) => {
      try {
        if (parceiro_id === 0) return;

        setFormError('');

        formRef?.current?.setErrors({});

        await schemaValidationPassword.validate(data, {
          abortEarly: false,
          context: { exist: !getAuthUser().isAdmin },
        });

        const apiData = {
          senha_nova: data.nova_senha,
          senha_antiga: !getAuthUser().isAdmin ? data.senha_anterior : null,
        };

        const response = await api.put(
          `/parceiros/alterarSenha/${parceiro_id}`,
          apiData,
        );
        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Senha alterada com sucesso!',
          });

          clearForm();
          onRequestClose();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro ao alterar a senha, tente novamente...');
      }
    },
    [
      addToast,
      schemaValidationPassword,
      parceiro_id,
      clearForm,
      getAuthUser,
      onRequestClose,
    ],
  );

  useEffect(() => {
    if (formRef) {
      clearForm();
    }
  }, [formRef, clearForm]);

  useEffect(() => {
    setTimeout(() => {
      let name = 'nova_senha';
      if (!getAuthUser().isAdmin) {
        name = 'senha_anterior';
      }
      const inputValorNode = document.querySelector<HTMLInputElement>(
        `input[name=${name}]`,
      );
      if (inputValorNode) {
        inputValorNode.focus();
      }
    }, 300);
  }, [visible, getAuthUser]);

  return (
    <Modal
      title={title}
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <ErrorMessage error={formError} onDismiss={() => setFormError('')} />
          <FormContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              {!getAuthUser().isAdmin && (
                <InputContainer>
                  <span>Senha anterior</span>
                  <Input
                    name="senha_anterior"
                    placeholder="Digite sua senha anterior"
                    type="password"
                    defaultValue=" "
                  />
                </InputContainer>
              )}

              <InputContainer>
                <span>Nova senha</span>
                <div>
                  <Input
                    name="nova_senha"
                    placeholder="Digite a sua nova senha"
                    type="password"
                    defaultValue=""
                  />
                </div>
              </InputContainer>

              <InputContainer>
                <span>Confirmação da sua nova senha</span>
                <div>
                  <Input
                    name="nova_senha_confirm"
                    placeholder="Digite a confirmação da sua nova senha"
                    type="password"
                    defaultValue=""
                  />
                </div>
              </InputContainer>

              <ButtonsGroup>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Voltar
                </Button>
                <Button type="submit" background={colors.green}>
                  Confirmar
                </Button>
              </ButtonsGroup>
            </Form>
          </FormContainer>
        </Container>
      )}
    </Modal>
  );
};

export default ModalAlterarSenha;
