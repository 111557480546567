import React, { useCallback, useState } from 'react';
import { useAuth } from '~/hooks/auth';
import { useDropzone } from 'react-dropzone';
import convert from 'xml-js';
import utf8 from 'utf8';

import { BsFillPersonLinesFill } from 'react-icons/bs';
import { RiDragDropLine } from 'react-icons/ri';
import { HiOutlineDocumentDownload } from 'react-icons/hi';

import { useTheme } from 'styled-components';

// import api from '~/services/api';

import { Container, Content, DragDrop, FileList, Info } from './styles';

import {
  AnchorContainer,
  AnchorMenu,
  AnchorLink,
  AnchorRef,
} from '~/components/Anchor';

import {
  FormContainer,
  FormContent,
  FormBlock,
  Plate,
  NoPermission,
  ErrorMessage,
  Loader,
} from '~/components';

import Header from '~/components/Header';

import XML from './XML';

import { xmlJSONtoNFE } from '~/utils/nfe';

import { NFEProvider, useNFE } from '~/hooks/nfe';
import { validarPermissao } from '~/utils/permissions';
import { NFEType } from '~/hooks/nfe/interface';

const NFE: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();

  const { nfes, adicionarNFE } = useNFE();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      acceptedFiles.forEach((cFile: File) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            let readerData = reader.result;
            readerData = utf8.decode(String(readerData));

            const jsonInXML = convert.xml2json(`${readerData}`, {
              compact: true,
              spaces: 4,
            });

            const cNfe: NFEType = {
              nome: cFile.name,
              tamanho: cFile.size,
              status: 'in-load',
              arquivo: xmlJSONtoNFE(jsonInXML),
              erros: [],
              formas: [],
              conta_corrente_id: '',
            };

            if (adicionarNFE) {
              adicionarNFE(cNfe);
            }
          } catch (e) {
            setFormError(`Falha na leitura da XML: ${(e as Error).message}`);
          }
        };
        reader.readAsBinaryString(cFile);
      });
    },
    [adicionarNFE],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/xml': ['.xml'],
    },
  });

  if (validarPermissao(user, 'incluir-compras')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Loader visible={formLoading} />

      <Header />

      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="nfe">NF-e</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <AnchorRef anchor="nfe">
                <FormBlock>
                  <Plate
                    title="Entrada de mercadorias com nota fiscal eletronica"
                    subtitle="Gerencie suas NFe's de compra"
                    icon={() => (
                      <BsFillPersonLinesFill size={32} color={colors.label} />
                    )}
                  />
                  <DragDrop $active={isDragActive}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Info>
                          <RiDragDropLine size={80} color={colors.label} />
                          <div>
                            <p>Muito bem! solte os arquivos.</p>
                            <small>Agora basta você soltar os arquivos</small>
                          </div>
                        </Info>
                      ) : (
                        <Info>
                          <HiOutlineDocumentDownload
                            size={80}
                            color={colors.label}
                          />
                          <div>
                            <p>
                              <span>Arraste as NFE aqui ou </span>
                              <ins>clique aqui</ins>
                              <span> para buscá-las</span>
                            </p>
                            <small>É muito simples!</small>
                          </div>
                        </Info>
                      )}
                    </div>
                  </DragDrop>

                  <FileList>
                    {nfes
                      ? nfes.map(nfe => <XML key={nfe.nome} nfe={nfe} />)
                      : null}
                  </FileList>
                </FormBlock>
              </AnchorRef>
            </FormContent>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

const NFEProviderConnect: React.FC = () => {
  return (
    <NFEProvider>
      <NFE />
    </NFEProvider>
  );
};

export default NFEProviderConnect;
