import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.span<ContainerProps>`
  ${({ theme, $active }) => css`
    float: left;
    background: ${$active ? theme.colors.defaultHighlight : 'rgba(0, 0, 0, 0)'};
    cursor: pointer;
    border-radius: ${$active
      ? '0.6rem 0.6rem 0.5rem 0.5rem'
      : '0.6rem 0.6rem 0 0'};
    padding: 1.5rem;
    box-shadow: ${theme.shadows.highlight};
    transition: all 0.5s ease;
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.label};
    text-align: right;
    margin-right: 0.2rem;
    border-bottom: 1rem solid;
    border-color: ${theme.colors.controlFocus};
    border-bottom-color: ${$active
      ? theme.colors.primaryDark
      : 'rgba(0,0,0,0)'};

    &:hover {
      background: ${theme.colors.defaultHighlight};
      border-bottom: 1rem solid ${theme.colors.primaryDark};
      border-radius: 0.6rem 0.6rem 0.5rem 0.5rem;
    }
  `}
`;
