import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { List, arrayMove } from 'react-movable';

import { Container, Content, Area, Item, Control } from './styles';

import { RelatorioAgrupadorType } from '~/types';

import { AgrupadoresProps } from './interface';

const Agrupadores: React.FC<AgrupadoresProps> = ({
  configuracao,
  aoAplicarConfiguracao,
}) => {
  const { colors } = useTheme();

  const [itens, setItens] = useState<string[]>([
    ...configuracao.agrupar.map(agrupador => agrupador.field),
  ]);

  const verificarHabilitado = (pItem: string): boolean => {
    return configuracao.agrupar.reduce((acc: boolean, item) => {
      if (item.field === pItem && item.enable === true) {
        // eslint-disable-next-line no-param-reassign
        acc = true;
      }
      return acc;
    }, false);
  };

  const inverterInatividade = (pItem: string) => {
    if (verificarHabilitado(pItem)) {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          agrupar: [
            ...configuracao.agrupar.map(agrupador => ({
              ...agrupador,
              enable: agrupador.field === pItem ? false : agrupador.enable,
            })),
          ],
        },
        false,
      );
    } else {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          agrupar: [
            ...configuracao.agrupar.map(agrupador => ({
              ...agrupador,
              enable: agrupador.field === pItem ? true : agrupador.enable,
            })),
          ],
        },
        false,
      );
    }
  };

  const extrairLabel = (campo: string) => {
    return configuracao.agrupar.reduce(
      (acc: string, item: RelatorioAgrupadorType) => {
        if (item.field === campo) {
          // eslint-disable-next-line no-param-reassign
          acc = item.label;
        }
        return acc;
      },
      '',
    );
  };

  const salvarPosicoes = (pItens: string[]) => {
    const agrupadoresOrdenados: RelatorioAgrupadorType[] = [];

    pItens.forEach(item => {
      configuracao.agrupar.forEach(agrupador => {
        if (agrupador.field === item) {
          agrupadoresOrdenados.push(agrupador);
        }
      });
    });

    aoAplicarConfiguracao(
      {
        ...configuracao,
        agrupar: agrupadoresOrdenados,
      },
      false,
    );
  };

  return (
    <Container>
      <Content>
        <List
          values={itens}
          onChange={({ oldIndex, newIndex }) => {
            setItens(arrayMove(itens, oldIndex, newIndex));
            salvarPosicoes(arrayMove(itens, oldIndex, newIndex));
          }}
          renderList={({ children, props }) => (
            <Area {...props}>{children}</Area>
          )}
          renderItem={({ value, props }) => (
            <Item inativo={!verificarHabilitado(value)} {...props}>
              <Control onClick={() => inverterInatividade(value)}>
                {verificarHabilitado(value) ? (
                  <ImCheckboxChecked size={42} color={colors.label} />
                ) : (
                  <ImCheckboxUnchecked
                    size={42}
                    color={colors.defaultHighlight}
                  />
                )}
              </Control>
              {extrairLabel(value)}
            </Item>
          )}
        />
      </Content>
    </Container>
  );
};

export default Agrupadores;
