import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';

import { useTheme } from 'styled-components';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader, NoPermission } from '~/components';

import { VariacaoGradeType } from '~/types';

import api from '~/services/api';
import { validarPermissao } from '~/utils/permissions';

const VariacoesGrade: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [variacoes, setVariacoes] = useState<Array<VariacaoGradeType>>([]);

  const fetchVariacoes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        '/produtos/variacao-grades?order_by=-tipo',
      );
      setVariacoes(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const handlerEdit = useCallback(
    (variacaoGrade: VariacaoGradeType) => {
      history(`/variacao-grade/${variacaoGrade.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchVariacoes();
  }, [fetchVariacoes]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'variacao-grade-produtos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Plate
          title="Variações de grade"
          subtitle="Cadastre as varicações de grade utilizadas"
          icon={() => <BsGrid3X3Gap size={32} color={colors.label} />}
        >
          <Link to="/variacao-grade/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {variacoes ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'tipo',
                label: 'Tipo',
                search: false,
              },
              {
                column: 'ativo',
                label: 'Ativo',
              },
            ]}
            data={[
              ...variacoes.map(item => ({
                ...item,
                ativo: item.ativo ? 'SIM' : 'NAO',
              })),
            ]}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEdit(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default VariacoesGrade;
