import styled, { css } from 'styled-components';
import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 0rem 0rem 0.6rem 0.6rem;
    float: left;
    flex-direction: row;
    background: ${theme.colors.background};
    border: 0rem solid;
    border-color: ${theme.colors.controlFocus};
    margin-bottom: 6.5rem;
    box-shadow: ${theme.shadows.highlight};
  `}
`;

export const Empty = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.default};
    display: flex;
    flex: 1;
    text-align: center;
    width: 100%;
    padding: 2rem;
  `}
`;
