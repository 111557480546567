import React, { useState, useRef, useCallback, useEffect } from 'react';

import { ImRadioUnchecked, ImRadioChecked } from 'react-icons/im';

import { useNavigate } from 'react-router-dom';

import { useTheme } from 'styled-components';

import { FiMenu } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';

import { Container, Content, Info, Left, Right, Menu } from './styles';

import { DocumentoFiscalProps } from './interface';

import { handlerNumberToString } from '~/utils/money';
import { formatCustomBR, formatTheDateYall } from '~/utils/formatDate';
import { DocumentoFiscalType } from '~/types';

const DocumentoFiscal: React.FC<DocumentoFiscalProps> = ({
  documentoFiscal,
  eImpar,
  selecionada,
  selecionarDocumentoFiscal,
}) => {
  const { colors } = useTheme();

  const history = useNavigate();

  const [menuVisible, setMenuVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const visualizarDocumentoFiscal = (pDocumentoFiscal: DocumentoFiscalType) => {
    history(`/documento-fiscal/${pDocumentoFiscal.id}`);
  };

  const handlerHideContent = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeMenu();
    }
  }, []);

  const handleClickOutside = useCallback((event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  return (
    <Container $selecionada={selecionada} $eimpar={eImpar}>
      <Left onClick={() => selecionarDocumentoFiscal(`${documentoFiscal.id}`)}>
        {selecionada ? (
          <ImRadioChecked size={24} color="#6e6e6e" />
        ) : (
          <ImRadioUnchecked size={24} color="#6e6e6e" />
        )}
      </Left>
      <Content>
        <div>
          <Info>
            <small>Número:</small>
            {documentoFiscal.IDE_cNF}
          </Info>
          <Info>
            <small>Modelo:</small>
            {documentoFiscal.IDE_mod}
          </Info>
          <Info $cor="#00AAE0">
            <small>Destinatário:</small>
            {documentoFiscal.DEST_xNome}
          </Info>
        </div>
        <div>
          <Info>
            <small>Data:</small>
            {formatCustomBR(new Date(documentoFiscal.IDE_dhEmi))}
          </Info>
          <Info $cor="#D82E2E">
            <small>Operação:</small>
            {documentoFiscal.IDE_natOp}
          </Info>
          <Info $cor="#228B22">
            <small>Total:</small>
            {`R$ ${handlerNumberToString(documentoFiscal.TOTAL_vNF)}`}
          </Info>
        </div>

        <div>
          <Info>
            <small>Empresa:</small>
            {documentoFiscal.EMIT_xNome}
          </Info>
        </div>
      </Content>
      <Right>
        {menuVisible ? (
          <CgClose size={24} color={colors.label} onClick={closeMenu} />
        ) : (
          <FiMenu size={24} color={colors.label} onClick={openMenu} />
        )}
        <Menu ref={ref} $visible={menuVisible}>
          <button
            type="button"
            onClick={() => visualizarDocumentoFiscal(documentoFiscal)}
          >
            VISUALIZAR
          </button>
        </Menu>
      </Right>
    </Container>
  );
};

export default DocumentoFiscal;
