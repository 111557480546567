import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  FindParceiro,
  DatePicker,
  Loader,
  Lug,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  EmpresaType,
  SelectType,
  FiltroType,
  GrupotagsType,
  TagType,
} from '~/types';

import { FiltrosProps, FormData } from './interface';

import { LugItemProps } from '~/components/Lug/interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const [parceiro, setParceiro] = useState({ id: '', descricao: '' });
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  // TAGS
  const [tagsItens, setTagsItens] = useState<LugItemProps[]>([]);

  const onSelectItensTags = (cItens: LugItemProps[]) => {
    setTagsItens(cItens);
  };

  const submeterFormulario = useCallback(
    (data: FormData) => {
      const filtros: FiltroType[] = [];
      const {
        data_fim,
        data_inicio,
        empresa_id,
        situacao,
        tipo,
        data_campo_filtro,
      } = data;

      filtros.push({
        nome: 'data_fim',
        valor: data_fim,
      });

      filtros.push({
        nome: 'data_inicio',
        valor: data_inicio,
      });

      filtros.push({
        nome: 'empresa_id',
        valor: empresa_id,
      });

      filtros.push({
        nome: 'situacao',
        valor: situacao,
      });

      filtros.push({
        nome: 'tipo',
        valor: tipo,
      });

      filtros.push({
        nome: 'parceiro_id',
        valor: parceiro.id,
      });

      filtros.push({
        nome: 'data_campo_filtro',
        valor: data_campo_filtro,
      });

      filtros.push({
        nome: 'tags',
        valor: tagsItens
          .filter(cTag => cTag.active)
          .reduce((acc: string[], cItem: LugItemProps) => {
            acc.push(cItem.value);
            return acc;
          }, [])
          .join(','),
      });

      aoAplicarFiltros(filtros);
    },
    [parceiro, tagsItens, aoAplicarFiltros],
  );

  useEffect(() => {
    const carregarEmpresas = async () => {
      setLoading(true);
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });

        setEmpresas(empresasFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };
    carregarEmpresas();
  }, []);

  useEffect(() => {
    async function loadGrupoTags() {
      try {
        setLoading(true);
        const response = await api.get(`grupo-tags/?tipo=conta`);
        const { data } = response;

        const output: LugItemProps[] = [];

        if (data && data.length) {
          data.forEach((cGrupoTag: GrupotagsType) => {
            if (cGrupoTag) {
              const { tags } = cGrupoTag;
              if (tags && tags.length) {
                tags.forEach((cTag: TagType) => {
                  output.push({
                    active: false,
                    label: cTag.nome,
                    value: cTag.id,
                    color: `${cTag.cor}`,
                    group: cGrupoTag.nome,
                  });
                });
              }
            }
          });
        }

        setTagsItens(output);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (e) {
        setLoading(false);
      }
    }
    loadGrupoTags();
  }, []);

  if (loading) return <Loader visible />;

  return (
    <Container>
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            data_inicio: format(new Date(), 'yyyy-MM-dd'),
            data_fim: format(new Date(), 'yyyy-MM-dd'),
          }}
        >
          <InputContainer>
            <span>Tipo</span>
            <div>
              <Select
                name="tipo"
                loading={loading}
                defaultValue={{ value: 'RECEBER', label: 'RECEBER', cor: '' }}
                options={[
                  { value: 'RECEBER', label: 'RECEBER', cor: '' },
                  { value: 'PAGAR', label: 'PAGAR', cor: '' },
                ]}
              />
            </div>
          </InputContainer>
          <InputContainer>
            <span>Empresa</span>
            <div>
              <Select
                name="empresa_id"
                loading={loading}
                defaultValue={{
                  value: empresas[0].value,
                  label: empresas[0].label,
                }}
                options={empresas}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data inicial</span>
            <div>
              <DatePicker
                name="data_inicio"
                mask="99/99/9999"
                placeholder="Informe a Data Inicial"
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data final</span>
            <div>
              <DatePicker
                name="data_fim"
                mask="99/99/9999"
                placeholder="Informe a Data Final"
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Parceiro</span>
            <FindParceiro
              placeholderInput="Informe o nome do parceiro"
              placeholderValue="Selecione um parceiro"
              tipos="cliente,fornecedor"
              onSelectValue={(selecionados, descricao) => {
                if (selecionados[0]) {
                  setParceiro({
                    id: selecionados[0].id,
                    descricao,
                  });
                }
              }}
            />
          </InputContainer>

          <InputContainer>
            <span>Situação</span>
            <div>
              <Select
                name="situacao"
                loading={loading}
                defaultValue={{
                  value: 'ABERTA',
                  label: 'ABERTA',
                  cor: '#27AE60',
                }}
                options={[
                  { value: 'ABERTA', label: 'ABERTA', cor: '#27AE60' },
                  { value: 'PAGA', label: 'PAGA', cor: '#EB5757' },
                  { value: 'TODAS', label: 'TODAS', cor: '#878787' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Filtrar por data de:</span>
            <div>
              <Select
                name="data_campo_filtro"
                loading={loading}
                defaultValue={{ value: 'vencimento', label: 'VENCIMENTO' }}
                options={[
                  { value: 'lancamento', label: 'LANÇAMENTO' },
                  { value: 'vencimento', label: 'VENCIMENTO' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tags</span>
            <div>
              <Lug
                title="SELECIONE AS TAGS DE CONTAS"
                itens={tagsItens}
                onSelectItens={onSelectItensTags}
                placeholder="Selecione as tags..."
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button
                style={{ width: 150 }}
                type="submit"
                background={colors.green}
              >
                Filtrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
