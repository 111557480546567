import React, { useEffect, useRef, useState, useCallback } from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, InputCurrency, Error } from './styles';
import { InputProps } from './interface';

import { handlerToNumber, handlerNumberToString } from '~/utils/money';

const Input: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  onBlur,
  onChange,
  onKeyDownEnter,
  placeholder,
  value,
  disabled,
  selectAllInFocus,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const {
    fieldName,
    error,
    defaultValue: defaultV,
    registerField,
  } = useField(name);

  const valueRef = useRef(defaultValue || defaultV || 0);

  const handlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (onKeyDownEnter) {
        onKeyDownEnter();
        event.preventDefault();
      }
    }
  };

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!valueRef.current);

    setInputValue(handlerNumberToString(handlerToNumber(inputValue)));

    if (onBlur) {
      onBlur();
    }
  }, [onBlur, inputValue]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    if (selectAllInFocus && inputRef) {
      inputRef.current?.select();
    }
  }, [selectAllInFocus]);

  const handleInputChange = useCallback(
    (currentValue: string | undefined) => {
      if (onChange) {
        onChange(`${currentValue || 0}`);
      }
      setInputValue(`${currentValue || 0}`);
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: valueRef.current,
      getValue: () => {
        return valueRef.current;
      },
      setValue(ref, pValue) {
        if (inputRef.current) {
          inputRef.current.value = pValue;

          setInputValue(
            handlerNumberToString(handlerToNumber(`${pValue || 0}`)),
          );
        }
      },
      clearValue: () => {
        if (valueRef.current) {
          valueRef.current = '';
        }
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    valueRef.current = inputValue;
  }, [inputValue]);

  useEffect(() => {
    setInputValue(
      handlerNumberToString(handlerToNumber(`${defaultValue || 0}`)),
    );
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setInputValue(handlerNumberToString(handlerToNumber(`${value || 0}`)));
    }
  }, [value]);

  return (
    <Container
      style={containerStyle}
      $iserrored={!!error}
      $isfilled={isFilled}
      $isfocused={isFocused}
    >
      {Icon && <Icon size={18} />}
      <InputCurrency
        name={fieldName}
        ref={inputRef}
        onValueChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onKeyDown={handlerKeyDown}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        value={inputValue}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
