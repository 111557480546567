import styled, { keyframes, css } from 'styled-components';
import ScrollArea from 'react-scrollbar';

import { ContentProps, OverlayProps } from './interface';

export const HeaderSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderSearchControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
`;

export const HeaderSearchControlIc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  padding: 1rem;
  border-radius: 100%;
  transition: all 0.5s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHighlight};
  }
`;

const visibleContentAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const invisibleContentAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Overlay = styled.div<OverlayProps>`
  ${({ theme, $visible }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${theme.colors.overlay};
    top: 8rem;
    left: 0rem;
    box-shadow: ${theme.shadows.default};
    transform: scale(1);
    visibility: ${$visible ? '$visible' : 'hidden'};
    animation: ${$visible ? visibleContentAnimation : invisibleContentAnimation}
      0.5s forwards;
    z-index: 1000;
  `}
`;

export const Content = styled.div<ContentProps>`
  ${({ theme, $opensearch }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: ${$opensearch ? 100 : 50}%;
    max-height: ${$opensearch ? 100 : 70}%;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};

    .scrollarea {
      float: left;
      width: 100%;
    }

    .scrollarea-content {
      float: left;
      width: 100%;
    }

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const HeaderSearchArea = styled.div`
  width: 100%;
  float: left;
  padding: 2rem 0;
`;

export const HeaderSearchResponse = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Scroll = styled(ScrollArea).attrs({
  contentStyle: {
    paddingBottom: 20,
  },
})``;

export const HeaderSearchResumeContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      max-width: 102.4rem;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    p {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-size: ${theme.fontSizes.bigger};
      margin: 0rem;
      color: ${theme.colors.label};
      font-weight: 700;
      padding: 0rem;
      float: left;
    }

    h2 {
      font-size: ${theme.fontSizes.default};
      margin: 0rem;
      line-height: 2.2rem;
      color: ${theme.colors.default};
      padding: 0rem;
      float: left;
    }

    button {
      transition: all 0.5s ease;
      overflow: hidden;
      width: 4rem;
      height: 4rem;
      display: flex;
      background: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.2;
        transform: rotate(-90deg);
      }
    }
  `}
`;
