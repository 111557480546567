import React, { useState } from 'react';

import List from '~/components/List';

import { Container } from './styles';

import { EstoqueType } from '~/types';

import { EstoquesProps } from './interface';

import Timeline from './TimeLine';

const Estoques: React.FC<EstoquesProps> = ({ data }) => {
  const [estoque, setEstoque] = useState<EstoqueType | null>(null);

  const makeHeaderTable = () => {
    return [
      {
        column: 'empresa_nome',
        label: 'Empresa',
      },
      {
        column: 'fisico',
        label: 'Físico',
      },
      {
        column: 'contabil',
        label: 'Contábil',
      },
    ];
  };

  const makeBodyTable = (pData: Array<EstoqueType>) => {
    return [
      ...pData.map(item => ({
        ...item,
        empresa_nome: item.empresa.fantasia,
      })),
    ];
  };

  const handlerTimeLineRequestOpen = (pEstoque: EstoqueType): void => {
    setEstoque(pEstoque);
  };

  const handlerTimeLineRequestClose = () => {
    setEstoque(null);
  };

  return (
    <Container>
      {estoque ? (
        <Timeline
          visible
          estoque={estoque}
          onRequestClose={handlerTimeLineRequestClose}
        />
      ) : null}

      {data.length ? (
        <List
          header={makeHeaderTable()}
          data={makeBodyTable(data)}
          options={[
            {
              label: 'Visualizar',
              onPress: item => {
                handlerTimeLineRequestOpen(item);
              },
            },
          ]}
        />
      ) : (
        <h1>Nenhuma informação de estoque cadastrada!</h1>
      )}
    </Container>
  );
};

export default Estoques;
