import React, { useState } from 'react';

import qs from 'qs';

import api from '~/services/api';

import { handlerToNumber } from '~/utils/money';

import Header from '~/components/Header';

import { Loader, BarraLateral, NoPermission } from '~/components';

import Filtros from './Filtros';
import Listagem from './Listagem';

import {
  Container,
  Content,
  Area,
  Left,
  Right,
  BreadCrumb,
  Level,
} from './styles';

import { ContaType, RelatorioType, FiltroType, TotalizadorType } from '~/types';
import { useAuth } from '~/hooks/auth';

import configuracaoInicial from '~/schemas/relatorio-contas.json';

import { exportarPDF } from './engine';
import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatTheDateYall, formatCustomBR } from '~/utils/formatDate';
import { validarPermissao } from '~/utils/permissions';

const Contas: React.FC = () => {
  const { user } = useAuth();
  const [configuracao, setConfiguracao] =
    useState<RelatorioType>(configuracaoInicial);

  const agrupadores = configuracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.label);

  const ordenadores = configuracao.ordenar
    .filter(ordenador => ordenador.enable)
    .map(ordenador => ordenador.field);

  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const [filtros, setFiltros] = useState<FiltroType[]>([]);

  const [contas, setContas] = useState<Array<ContaType>>([]);
  const [totalizadores, setTotalizadores] = useState<TotalizadorType[]>([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [loading, setLoading] = useState(false);

  const voltarAoTopo = () => {
    window.scrollTo(0, 0);
  };

  const aoAplicarConfiguracao = (
    pConfiguracao: RelatorioType,
    preloader: boolean,
  ) => {
    if (preloader) {
      setLoading(true);
      setConfiguracao(pConfiguracao);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      setConfiguracao(pConfiguracao);
    }
  };

  const selecionarEmpresaParaOCheckout = (pFiltros: FiltroType[]) => {
    pFiltros.forEach((cFiltro: FiltroType) => {
      const { valor, nome } = cFiltro;
      if (nome === 'empresa_id' && valor) {
        setEmpresaSelecionada(`${valor}`);
      }
    });
  };

  const sintetizarParametros = (pFiltros: FiltroType[]): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parametros: any = {};

    pFiltros.forEach((cFiltro: FiltroType) => {
      const { valor, nome } = cFiltro;

      let cValor = valor;
      if (nome === 'p_situacao' && valor === 'TODAS') {
        cValor = null;
      }
      if (valor) {
        parametros[nome] = cValor;
      }
    });

    const qsParametros = qs.stringify(parametros);

    return qsParametros;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extrairTotalizadores = (total: any) => {
    return Object.entries(total).map(([label, value]) => ({
      chave: label,
      valor: value,
    })) as TotalizadorType[];
  };

  const carregarContas = async (pFiltros: FiltroType[]) => {
    try {
      setLoading(true);

      const response = await api.get(
        `financeiros/contas?${sintetizarParametros(pFiltros)}`,
      );

      const { data } = response;

      if (data && data.status !== 'error') {
        const { data: cContas, lastPage, total } = data;

        if (cContas && cContas.length) {
          setContas(cContas);
        }

        if (lastPage) {
          setTotalPaginas(handlerToNumber(`${lastPage}`));
        }

        if (total) {
          const { Registros } = total;

          if (Registros) {
            setTotalRegistros(handlerToNumber(`${Registros}`));
          }

          setTotalizadores(extrairTotalizadores(total));
        }
      }

      setLoading(false);
      voltarAoTopo();
    } catch (e) {
      setLoading(false);
    }
  };

  const aoAplicarFiltros = async (pFiltros: FiltroType[]) => {
    setFiltros(pFiltros);
    setPaginaAtual(1);
    selecionarEmpresaParaOCheckout(pFiltros);
    carregarContas([...pFiltros, { nome: 'page', valor: 1 }]);
  };

  const alterarPagina = (pagina: number) => {
    setPaginaAtual(pagina);
    carregarContas([...filtros, { nome: 'page', valor: pagina }]);
  };

  const recarregarPaginaAtual = () => {
    carregarContas([...filtros, { nome: 'page', valor: paginaAtual }]);
  };

  const aoExportarRelatorio = (pConfiguracao: RelatorioType) => {
    let data_inicio = '';
    let data_fim = '';
    let tipo = '';
    let situacao = '';

    filtros.forEach(el => {
      if (el.nome === 'data_inicio') {
        data_inicio = String(el.valor);
      }

      if (el.nome === 'data_fim') {
        data_fim = String(el.valor);
      }

      if (el.nome === 'tipo') {
        tipo = String(el.valor);
      }

      if (el.nome === 'situacao') {
        situacao = String(el.valor);
      }
    });

    const cabecalho: RelatorioCabecalhoType = {
      title: user.workspace.nome,
      subtitle1: `Relatório de Contas a ${tipo} - ${formatTheDateYall(
        data_inicio,
      )} à ${formatTheDateYall(data_fim)}`,
      subtitle2: `Data do relatório: ${formatCustomBR(new Date())}`,
      subtitle3: `Situação: ${situacao}`,
    };

    exportarPDF(cabecalho, contas, totalizadores, pConfiguracao);
  };

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'listar-contas')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />

      <Content>
        {agrupadores.length || ordenadores.length ? (
          <BreadCrumb>
            {agrupadores.length ? (
              <Level>{`Agrupado por: ${agrupadores.join(',')}`}</Level>
            ) : null}
            {configuracao.ordenar.length ? (
              <Level>{`Ordenado por: ${ordenadores.join(',')}`}</Level>
            ) : null}
          </BreadCrumb>
        ) : null}

        <Area>
          <Left>
            <BarraLateral
              aoExportarRelatorio={aoExportarRelatorio}
              aoAplicarConfiguracao={aoAplicarConfiguracao}
              configuracao={configuracao}
            >
              <Filtros aoAplicarFiltros={aoAplicarFiltros} />
            </BarraLateral>
          </Left>
          <Right>
            <Listagem
              alterarPagina={alterarPagina}
              recarregarPaginaAtual={recarregarPaginaAtual}
              configuracao={configuracao}
              empresaSelecionada={empresaSelecionada}
              contas={contas}
              totalizadores={totalizadores}
              paginaAtual={paginaAtual}
              totalPaginas={totalPaginas}
              totalRegistros={totalRegistros}
            />
          </Right>
        </Area>
      </Content>
    </Container>
  );
};

export default Contas;
