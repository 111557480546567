import styled, { css } from 'styled-components';

export const Space = styled.div`
  float: left;
  width: 100%;
  height: 2rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    float: left;
    padding-right: 1.5rem;
    margin: 2rem 0 2rem 0;
    border-left: 0.5rem solid;
    border-color: ${theme.colors.controlFocus};
    padding-left: 1.5rem;

    h1 {
      font-size: ${theme.fontSizes.large};
    }

    p {
      color: ${theme.colors.grey};
      font-size: ${theme.fontSizes.tiny};
    }
  `};
`;
