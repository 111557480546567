import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { FechamentoDeCaixaFormData, FechamentoDeCaixaProps } from './interface';

import {
  Button,
  ErrorMessage,
  FormContainer,
  FormContent,
  InputContainer,
  InputNumber,
  Loader,
  Modal,
} from '~/components';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import ContaCorrenteType from '~/types/conta_corrente';
import { getValidationErrors } from '~/utils';
import { handlerToNumber } from '~/utils/money';
import { DadosCaixaProps } from '../interface';
import Info from './Info';
import Resumo from './Resumo';
import TotalizadorPorFormaDePagamento from './TotalizadorPorFormaDePagamento';
import { Container, EditContainer, Footer } from './styles';
import { handlerErrors } from '~/utils/error';

const FechamentoDeCaixa: React.FC<FechamentoDeCaixaProps> = ({
  onRequestClose,
  visible = false,
  conta_corrente_id,
}) => {
  const formRef = useRef<FormHandles>(null);
  const history = useNavigate();

  const { colors } = useTheme();

  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const [contaCorrente, setContaCorrente] = useState<ContaCorrenteType>(
    {} as ContaCorrenteType,
  );
  const [dadosCaixa, setDadosCaixa] = useState<DadosCaixaProps | null>(null);
  const [entrada, setEntrada] = useState(0);
  const [saida, setSaida] = useState(0);
  const [totalDinheiro, setTotalDinheiro] = useState(0);

  const { addToast } = useToast();

  const fetchContaCorrente = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get<ContaCorrenteType>(
        `/financeiros/contas-correntes/${conta_corrente_id}`,
      );
      const { data } = response;
      if (data && data.status !== 'error') {
        const newData = data;
        newData.created_at = Intl.DateTimeFormat('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(newData.created_at));
        setContaCorrente(data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [conta_corrente_id]);

  const fetchDadosCaixa = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get(
        `/financeiros/dados-caixas/${conta_corrente_id}`,
      );
      const { data } = response;
      if (data && data.status !== 'error') {
        setDadosCaixa(data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [conta_corrente_id]);

  const handleSubmit = useCallback(
    async (data: FechamentoDeCaixaFormData) => {
      try {
        setFormLoading(true);

        const schema = Yup.object().shape({
          dinheiro_em_caixa: Yup.string().required(
            'Informar o dinheiro em caixa.',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const responseDadosCaixa = await api.post(
            `/financeiros/dados-caixas/${conta_corrente_id}`,
            {
              empresa_id: contaCorrente.empresa.id,
              data: [
                {
                  tipo_pagamento_id: 1,
                  valor_informado: handlerToNumber(
                    String(data.dinheiro_em_caixa),
                  ),
                },
              ],
            },
          );
          if (responseDadosCaixa.status === 200) {
            const responseInfoCaixaFechar = await api.put(
              `/financeiros/informacoes-caixas/fechar/${conta_corrente_id}`,
            );

            if (responseInfoCaixaFechar.status === 200) {
              onRequestClose();

              addToast({
                type: 'success',
                title: 'Concluído',
                description: 'Caixa fechado com sucesso!',
              });
            }
          }
        } catch (e) {
          setFormLoading(false);
          const message = handlerErrors(e);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro fechar o caixa, tente novamente...');
      } finally {
        setFormLoading(false);

        history('/contas-correntes');
      }
    },
    [addToast, conta_corrente_id, contaCorrente, onRequestClose, history],
  );

  useEffect(() => {
    fetchContaCorrente();
    fetchDadosCaixa();
  }, [fetchContaCorrente, fetchDadosCaixa]);

  useEffect(() => {
    const totalEntrada = dadosCaixa?.data.reduce(
      (accumulator, currentValue) => {
        return accumulator + (currentValue.valor > 0 ? currentValue.valor : 0);
      },
      0,
    );
    setEntrada(totalEntrada);

    const totalSaida = dadosCaixa?.data.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.valor < 0 ? currentValue.valor : 0);
    }, 0);
    setSaida(totalSaida);

    const dinheiro = dadosCaixa?.tipos_pagamentos.reduce(
      (accumulator, currentValue) => {
        return accumulator + (currentValue.id === 1 ? currentValue.valor : 0);
      },
      0,
    );
    if (dinheiro) setTotalDinheiro(dinheiro);
  }, [dadosCaixa]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title="Fechamento de caixa"
      visible={visible}
      onRequestClose={onRequestClose}
      scrollable
      closeButtonVisible={false}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <Info
                data_abertura={contaCorrente.created_at}
                descricao={contaCorrente.descricao}
                status={contaCorrente.status}
                compartilhada={contaCorrente.compartilhada}
              />

              <Resumo entrada={entrada} saida={saida} />

              <TotalizadorPorFormaDePagamento
                tipos_pagamentos={dadosCaixa?.tipos_pagamentos}
              />

              <EditContainer>
                <InputContainer disabled={formLoading}>
                  <strong>Dinheiro em caixa R$</strong>
                  <div>
                    <InputNumber
                      name="dinheiro_em_caixa"
                      placeholder="Informe o dinheiro em caixa"
                      defaultValue={totalDinheiro.toString()}
                      selectAllInFocus
                    />
                  </div>
                </InputContainer>
              </EditContainer>

              <Footer>
                <div>
                  <Button background={colors.contrast} onClick={onRequestClose}>
                    Cancelar
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    background={colors.green}
                    disabled={contaCorrente.status !== 'ABERTA'}
                  >
                    Confirmar
                  </Button>
                </div>
              </Footer>
            </FormContent>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default FechamentoDeCaixa;
