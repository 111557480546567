import React, { useEffect, useRef, useCallback } from 'react';

import { useTheme } from 'styled-components';

import { GiTicket } from 'react-icons/gi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils';

import { Container } from './styles';

import { Modal, InputContainer, InputNumber, Button } from '~/components';

import { DescontoFormData, DescontoProps } from './interface';

import { useCart } from '~/hooks/cart';

const Desconto: React.FC<DescontoProps> = ({ visible, onRequestClose }) => {
  const { desconto, setDesconto } = useCart();
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const handlerSubmit = async (data: DescontoFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        desconto: Yup.number().required().typeError('Informe o desconto'),
      });

      await schema.validate(data, { abortEarly: false });

      const { desconto: pDesconto } = data;

      if (pDesconto) {
        setDesconto(pDesconto);
        onRequestClose();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return null;
      }
    }
  };

  const focusInInputQtd = useCallback(() => {
    setTimeout(() => {
      const inputCLINode = document.querySelector<HTMLInputElement>(
        'input[name="desconto"]',
      );
      if (inputCLINode) {
        inputCLINode.focus();
      }
    }, 300);
  }, []);

  useEffect(() => {
    if (visible) {
      focusInInputQtd();
    }
  }, [visible, focusInInputQtd]);

  return (
    <Modal
      title="Desconto"
      visible={visible}
      onRequestClose={onRequestClose}
      width="50rem"
      scrollable
    >
      <Container>
        <Form
          onSubmit={handlerSubmit}
          initialData={{ desconto: `${desconto || 0}` }}
        >
          <InputContainer>
            <span>Desconto (R$)</span>
            <div>
              <InputNumber name="desconto" />
            </div>
          </InputContainer>
          <Button type="submit" background={colors.green}>
            <span>APLICAR</span>
            <GiTicket size={32} color="#fff" />
          </Button>
        </Form>
      </Container>
    </Modal>
  );
};

export default Desconto;
