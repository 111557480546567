import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* float: left; */
  padding: 2.5rem;
  width: 100%;
  float: left;
  background: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 1024px) {
    padding: 0rem;
  }
`;

export const Content = styled.main`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8rem;
    // padding: 2rem 3.5rem;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    border-radius: ${theme.radio.default};
  `}
`;
