import styled, { css } from 'styled-components';

export const Container = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 5rem;
    padding: 1rem 2rem;
    border-radius: ${theme.radio.default};
    justify-content: center;
    /* opacity: 0.6; */
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.green};
    box-shadow: ${theme.shadows.default};
    margin-right: 1rem;

    /* .__react_component_tooltip {
      z-index: 9999;
    } */
  `}
`;
