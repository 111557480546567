import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import useAppTheme from './hooks/theme';

import GlobalStyle from './styles/global';
import * as themes from './styles/themes';

import AppProvider from './hooks';

import Routes from './routes';

const App: React.FC = () => {
  const { currentTheme } = useAppTheme();

  return (
    <Router>
      <ThemeProvider theme={themes[currentTheme]}>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
};

export default App;
