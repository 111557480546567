import React, { useState, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';

import { AiFillDelete } from 'react-icons/ai';

import api from '~/services/api';

import { useCart } from '~/hooks/cart';
import { useAuth } from '~/hooks/auth';

import { FindParceiro, Loader } from '~/components';

import { Container, Info, Options } from './styles';

const Parceiros: React.FC = () => {
  const { colors } = useTheme();

  const { cliente, vendedor, setCliente, setVendedor } = useCart();
  const { getAuthUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [vendedorVisibility, setVendedorVisibility] = useState(false);
  const [clienteVisibility, setClienteVisibility] = useState(false);

  const handlerOpenVendedor = () => {
    setVendedorVisibility(true);
  };

  const handlerOpenCliente = () => {
    setClienteVisibility(true);
  };

  const handlerRemoveCliente = () => {
    setCliente(null);
  };

  const handlerRemoveVendedor = () => {
    setVendedor(null);
  };

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (event.ctrlKey && event.code === 'KeyK') {
      event.preventDefault();
      handlerOpenCliente();
    }
    if (event.ctrlKey && event.code === 'KeyL') {
      event.preventDefault();
      handlerOpenVendedor();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
    };
  });

  // USUARIO 1: CONSUMIDOR FINAL (CLIENTE)
  const buscarClienteInicial = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/parceiros/2`);
      if (response.data) {
        const { data } = response;
        setCliente(data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [setCliente]);

  // USUARIO LOGADO (VENDEDOR)
  const buscarVendedorInicial = useCallback(async () => {
    try {
      setLoading(true);

      const usuarioAutenticado = getAuthUser();
      if (usuarioAutenticado) {
        const response = await api.get(
          `/parceiros/${usuarioAutenticado.parceiro_id}`,
        );
        if (response.data) {
          const { data } = response;
          setVendedor(data);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [getAuthUser, setVendedor]);

  useEffect(() => {
    if (!vendedor) {
      buscarVendedorInicial();
    }

    if (!cliente) {
      buscarClienteInicial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Loader visible={loading} />
      <div>
        <Info>
          <span>Cliente (Ctrl + K)</span>
          {cliente ? (
            <h1>{`${cliente.codigo} - ${cliente.nome_fantasia}`}</h1>
          ) : (
            <h1>Selecione...</h1>
          )}
        </Info>
        <Options>
          {cliente ? (
            <button type="button" onClick={handlerRemoveCliente}>
              <AiFillDelete size={22} color={colors.label} />
            </button>
          ) : null}
          <FindParceiro
            onlySearch
            openned={clienteVisibility}
            tipos="cliente,funcionario"
            placeholderInput="Digite o nome do cliente"
            onSelectValue={selecionados => {
              setClienteVisibility(false);
              setCliente(selecionados[0]);
            }}
          />
        </Options>
      </div>
      <div>
        <Info>
          <span>Vendedor (Ctrl + L)</span>
          {vendedor ? (
            <h1>{`${vendedor.codigo} - ${vendedor.nome_fantasia}`}</h1>
          ) : (
            <h1>Selecione...</h1>
          )}
        </Info>
        <Options>
          {vendedor ? (
            <button type="button" onClick={handlerRemoveVendedor}>
              <AiFillDelete size={22} color={colors.label} />
            </button>
          ) : null}
          <FindParceiro
            onlySearch
            openned={vendedorVisibility}
            tipos="funcionario"
            placeholderInput="Digite o nome do cliente"
            onSelectValue={selecionados => {
              setVendedorVisibility(false);
              setVendedor(selecionados[0]);
            }}
          />
        </Options>
      </div>
    </Container>
  );
};

export default Parceiros;
