import styled, { css, keyframes } from 'styled-components';
import { LoaderContainerProps } from './interface';

const spinAnimation = keyframes`
  	0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
`;

export const LoaderContainer = styled.div<LoaderContainerProps>`
  ${({ theme, $visible }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3000;
    background: ${theme.colors.overlay};
    visibility: ${$visible ? 'visible' : 'hidden'};

    div {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 14rem;
      height: 14rem;
      margin: -7.5rem 0 0 -7.5rem;
      border-radius: 50%;
      border: 0.3rem solid transparent;
      border-top-color: #f5f5f5;
      animation: ${spinAnimation} 2s linear infinite;
    }

    div:before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      border-radius: 50%;
      border: 0.3rem solid transparent;
      border-top-color: #f9a22a;
      animation: ${spinAnimation} 2s linear infinite;
    }

    div:after {
      content: '';
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      right: 1.5rem;
      bottom: 1.5rem;
      border-radius: 50%;
      border: 0.3rem solid transparent;
      border-top-color: #0175a7;
      animation: ${spinAnimation} 2s linear infinite;
    }
  `}
`;
