import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';

import {
  ListsContextType,
  activeLineType,
  activeOrderFieldType,
} from './interface';

const ListsContext = createContext<ListsContextType>({} as ListsContextType);

export const ListsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [activeLine, setactiveLine] = useState<activeLineType>(null);
  const [activeOrderField, setactiveOrderField] =
    useState<activeOrderFieldType>(null);

  const setActiveLine = useCallback((currentLine: string) => {
    setactiveLine(currentLine);
  }, []);

  const setActiveOrderField = useCallback(
    (currentOrderField: activeOrderFieldType) => {
      setactiveOrderField(currentOrderField);
    },
    [],
  );

  const value = {
    activeLine,
    setActiveLine,
    activeOrderField,
    setActiveOrderField,
  };

  return (
    <ListsContext.Provider value={value}>{children}</ListsContext.Provider>
  );
};

const useLists = (): ListsContextType => {
  const context = useContext(ListsContext);

  if (!context) {
    throw new Error(`useLists must be used within an ListsProvider`);
  }

  return context;
};

export default useLists;
