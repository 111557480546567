import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  FindParceiro,
  DatePicker,
  Loader,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  EmpresaType,
  SelectType,
  FiltroType,
  NaturezaOperacaoType,
  TabelaPrecoType,
} from '~/types';

import { FiltrosProps, FormData } from './interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const [parceiro, setParceiro] = useState({ id: '', descricao: '' });
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const [tabelas_de_precos, set_tabelas_de_precos] = useState<SelectType[]>([]);
  const [naturezas_de_operacoes, set_naturezas_de_operacoes] = useState<
    SelectType[]
  >([]);

  const submeterFormulario = useCallback(
    (data: FormData) => {
      console.log(data);
      const filtros: FiltroType[] = [];
      const {
        data_fim,
        data_inicio,
        empresa_id,
        natureza_operacao_id,
        tabela_preco_id,
      } = data;

      filtros.push({
        nome: 'data_fim',
        valor: data_fim,
      });

      filtros.push({
        nome: 'data_inicio',
        valor: data_inicio,
      });

      filtros.push({
        nome: 'empresa_id',
        valor: empresa_id,
      });

      filtros.push({
        nome: 'natureza_operacao_id',
        valor: natureza_operacao_id,
      });

      filtros.push({
        nome: 'tabela_preco_id',
        valor: tabela_preco_id,
      });

      filtros.push({
        nome: 'parceiro_id',
        valor: parceiro.id,
      });

      aoAplicarFiltros(filtros);
    },
    [parceiro, aoAplicarFiltros],
  );

  useEffect(() => {
    const carregarEmpresas = async () => {
      setLoading(true);
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };

    const carregarNaturezaOperacoes = async () => {
      setLoading(true);
      try {
        const naturezaOperacoesFetch = await api.get(
          'pedido/natureza-operacao',
        );
        const naturezaOperacoesFetched = [] as SelectType[];
        naturezaOperacoesFetch.data.forEach((item: NaturezaOperacaoType) => {
          naturezaOperacoesFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });
        set_naturezas_de_operacoes(naturezaOperacoesFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };

    carregarEmpresas();
    carregarNaturezaOperacoes();
  }, []);

  useEffect(() => {
    const carregarTabelasPreco = async () => {
      try {
        const dataFetch = await api.get(
          `/tabelas-preco?empresa_id=${empresas[0].value}`,
        );
        const dataFetched = [] as SelectType[];

        dataFetch.data.forEach((item: TabelaPrecoType) => {
          if (item.ativo === true) {
            dataFetched.push({
              value: item.id,
              label: item.descricao,
              color: '',
            });
          }
        });
        set_tabelas_de_precos(dataFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    carregarTabelasPreco();
  }, [empresas]);

  return (
    <Container>
      <Loader visible={loading} />
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            data_inicio: format(new Date(), 'yyyy-MM-dd'),
            data_fim: format(new Date(), 'yyyy-MM-dd'),
          }}
        >
          <InputContainer>
            <span>Empresa</span>
            <div>
              <Select name="empresa_id" loading={loading} options={empresas} />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data inicial</span>
            <div>
              <DatePicker
                name="data_inicio"
                mask="99/99/9999"
                placeholder="Informe a Data Inicial"
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data final</span>
            <div>
              <DatePicker
                name="data_fim"
                mask="99/99/9999"
                placeholder="Informe a Data Final"
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Parceiro</span>
            <FindParceiro
              placeholderInput="Informe o nome do parceiro"
              placeholderValue="Selecione um parceiro"
              tipos="cliente,fornecedor"
              onSelectValue={(selecionados, descricao) => {
                if (selecionados[0]) {
                  setParceiro({
                    id: selecionados[0].id,
                    descricao,
                  });
                }
              }}
            />
          </InputContainer>

          <InputContainer>
            <span>Natureza Operação</span>
            <div>
              <Select
                name="natureza_operacao_id"
                loading={loading}
                options={naturezas_de_operacoes}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tabela de Preço</span>
            <div>
              <Select
                name="tabela_preco_id"
                loading={loading}
                options={tabelas_de_precos}
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button
                style={{ width: 150 }}
                type="submit"
                background={colors.green}
              >
                Filtrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
