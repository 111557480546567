import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  main {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    h1 {
      align-items: center;
      display: flex;
      margin-bottom: 40px;
      margin-left: -20px;

      span {
        font-size: 22px;
        margin-left: 10px;
      }
    }

    > div {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      border-radius: 1rem;
      flex-direction: row;
      border: 0.1rem solid #ef524f;
      border-radius: 0.5rem;
      align-items: center;

      span {
        background: #ef524f;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        font-weight: 700;
        color: #fff;
        float: left;
        width: 42px;
        height: 42px;
        border-radius: 21px;
        margin: -25px 0 0 -20px;
      }

      > div {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;

        h2 {
          color: #999;
          padding: 5px 10px;
          font-size: 14px;
          background: rgba(255, 255, 255, 0.03);
          border-radius: 0.2rem;
          font-weight: 700;
          text-transform: uppercase;
        }

        p {
          color: #fff;
          font-size: 20px;
          text-transform: uppercase;
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    justify-content: flex-end;
  }
`;
