import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Select,
  Switch,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { ContaCorrenteFormData, ParamsProps } from './interface';

import { EmpresaType, InstituicaoFinanceiraType, SelectType } from '~/types';
import { handlerErrors } from '~/utils/error';

const ContaCorrente: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({
    id,
    ativo: true,
    descricao: '',
  });
  const [registerId, setRegisterId] = useState('0');
  const [tipo, setTipo] = useState('');

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [instituicoesFinanceiras, setInstituicoesFinanceiras] = useState<
    SelectType[]
  >([]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: ContaCorrenteFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
          tipo: Yup.string().required('Informe um tipo válido.'),
          empresa_id: Yup.string().required('Informe uma empresa válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `financeiros/contas-correntes${
              registerId !== '0' ? `/${registerId}` : ''
            }`,
            {
              ...data,
              status: 'aberta',
              compartilhada: false,
            },
          );

          history(`/conta-corrente/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history],
  );

  function clearForm() {
    setLoading(true);
    formRef.current?.reset();
    setRegisterData({ id: '0', ativo: true, descricao: '' });
    setLoading(false);
  }

  const handleSetTipo = useCallback(
    (tipoSel: string) => {
      setTipo(tipoSel);
      if (registerData.descricao === '') {
        setRegisterData({
          ...registerData,
          descricao: tipoSel === 'CAIXA' ? 'CAIXA FRENTE' : '',
        });
      }
    },
    [registerData],
  );

  useEffect(() => {
    const loadInstituicoesFinanceiras = async () => {
      try {
        const instituicoesFinanceirasFetch = await api.get(
          'financeiros/instituicoes-financeiras',
        );
        const instituicoesFinanceirasFetched = [] as SelectType[];
        instituicoesFinanceirasFetch.data.data.forEach(
          (item: InstituicaoFinanceiraType) => {
            instituicoesFinanceirasFetched.push({
              value: `${item.id}`,
              label: item.descricao,
              color: '',
            });
          },
        );
        setInstituicoesFinanceiras(instituicoesFinanceirasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }

      if (id !== '0') {
        const response = await api.get(`financeiros/contas-correntes/${id}`);
        const { data } = response;

        setRegisterData({
          ...data,
          empresa_id: {
            label: data.empresa.fantasia,
            value: data.empresa.id,
          },
          instituicao_financeira_id: {
            label: data.instituicao_financeira?.descricao,
            value: data.instituicao_financeira?.id,
          },
          tipo: {
            label: data.tipo,
            value: data.tipo,
          },
        });
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    const initialize = async () => {
      await loadInstituicoesFinanceiras();
      await loadEmpresas();
      await loadRegister();
    };

    initialize();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Conta Corrente"
                      subtitle="Adicione ou gerencie uma conta corrente"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Empresa</span>
                        <div>
                          <Select
                            name="empresa_id"
                            loading={loading}
                            options={empresas}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Tipo</span>
                        <div>
                          <Select
                            name="tipo"
                            loading={loading}
                            onSelectedOption={item => handleSetTipo(item.value)}
                            options={[
                              { value: 'BANCO', label: 'BANCO', cor: '' },
                              { value: 'CAIXA', label: 'CAIXA', cor: '' },
                              { value: 'COFRE', label: 'COFRE', cor: '' },
                            ]}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe a descrição da conta corrente"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      {(tipo === 'BANCO' || tipo === 'CARTAO') && (
                        <InputContainer disabled={formLoading}>
                          <span>Instituição Financeira</span>
                          <div>
                            <Select
                              name="instituicao_financeira_id"
                              loading={loading}
                              options={instituicoesFinanceiras}
                            />
                          </div>
                        </InputContainer>
                      )}
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/contas-correntes">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default ContaCorrente;
