import styled, { css } from 'styled-components';

import { LabelProps } from './interface';

export const Container = styled.div``;

export const Info = styled.div`
  margin: 0 0 2rem 0;

  span {
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
`;

export const Message = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.darkGreen};
    border-color: ${theme.colors.grey};
    border-width: 0.1rem;
    border-radius: ${theme.radio.default};
    padding: 1.5rem;
    margin: 0 0 2rem 0;

    &.error {
      background: #ef524f;
    }

    div {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-weight: 700;
    }

    p {
      margin: 0px 0px 1rem 0;
    }

    button {
      background: none;
      display: flex;
      flex-direction: column;
      float: left;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.5s ease;
      padding: 1rem 2rem;
      border-radius: 0.4rem;
      font-size: 1.2rem;
      font-weight: 700;
      background: #fff;
      color: #333;
      text-decoration: underline;

      &:hover {
        background: #333;
        color: #fff;
      }
    }
  `}
`;

export const Label = styled.small<LabelProps>`
  ${({ theme, $status }) => css`
    font-size: 1rem;
    color: ${$status === 'ABERTA' ? theme.colors.green : theme.colors.darkRed};
    font-weight: bold;
    background: #fff;
    float: left;
    padding: 0.5rem;
    border-radius: 0.5rem;
    text-decoration: underline;
  `}
`;
