import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import {
  filtrarAgrupadoresPorAgrupamento,
  filtrarPedidosPorAgrupador,
  ordenarPedidos,
} from '../engine';

import { Sticker, Button } from '~/components';

import Pedido from './Pedido';
import Totalizadores from './Totalizadores';
// import SelecaoDeItens from './SelecaoDeItens';

import { Container, Content, List, Pagination } from './styles';

import { PedidoType } from '~/types';

import { ListagemProps } from './interface';

const Listagem: React.FC<ListagemProps> = ({
  pedidos,
  configuracao,
  totalizadores,
  empresaSelecionada,
  paginaAtual,
  totalPaginas,
  totalRegistros,
  alterarPagina,
  recarregarPaginaAtual,
}) => {
  const { colors } = useTheme();

  const [pedidosSelecionadas, setPedidosSelecionadas] = useState<string[]>([]);

  const [totalizadoresVisibilidiade, setTotalizadoresVisibilidade] =
    useState(false);

  const selecionarPedido = (id: string) => {
    setPedidosSelecionadas(
      pedidosSelecionadas.includes(id)
        ? [...pedidosSelecionadas.filter(pedidoId => id !== pedidoId)]
        : [...pedidosSelecionadas, id],
    );
  };

  const checarPedidoSelecionada = (id: string) => {
    return pedidosSelecionadas.includes(id);
  };

  const renderizarPedidos = (pPedidos: Array<PedidoType>) => {
    return (
      <List>
        {pPedidos.map((pedido, index) => (
          <Pedido
            key={pedido.id}
            eImpar={index % 2 === 0}
            pedido={pedido}
            selecionada={checarPedidoSelecionada(pedido.id)}
            selecionarPedido={selecionarPedido}
          />
        ))}
        {!pPedidos.length ? <h4>Nenhum item</h4> : null}
      </List>
    );
  };

  const renderizarListagem = (
    pPedidos: Array<PedidoType>,
    pAgrupamentos: Array<string>,
  ) => {
    if (!pAgrupamentos.length) {
      return renderizarPedidos(pPedidos);
    }

    const currentAgrupamento = pAgrupamentos[0];

    const agrupadores = filtrarAgrupadoresPorAgrupamento(
      pedidos,
      currentAgrupamento,
    );

    return agrupadores.map((agrupador, idx) => (
      <React.Fragment key={agrupador[idx]}>
        <Sticker title={agrupador}>
          {renderizarListagem(
            filtrarPedidosPorAgrupador(pPedidos, currentAgrupamento, agrupador),
            [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
          )}
        </Sticker>
      </React.Fragment>
    ));
  };

  const fecharTotalizadores = () => {
    setTotalizadoresVisibilidade(false);
  };

  const abrirTotalizadores = () => {
    setTotalizadoresVisibilidade(true);
  };

  const pAgrupamentos = configuracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);

  return (
    <Container>
      {totalizadores.length ? (
        <Totalizadores
          aoFechar={fecharTotalizadores}
          visibilidade={totalizadoresVisibilidiade}
          totalizadores={totalizadores}
        />
      ) : null}

      {/* {pedidos.length ? (
        <SelecaoDeItens
          pedidos={pedidos}
          pedidosSelecionadas={pedidosSelecionadas}
          setPedidosSelecionadas={setPedidosSelecionadas}
          empresaSelecionada={empresaSelecionada}
          recarregarPaginaAtual={recarregarPaginaAtual}
        />
      ) : null} */}

      <Content>
        {renderizarListagem(
          ordenarPedidos(pedidos, configuracao),
          pAgrupamentos,
        )}
      </Content>

      {paginaAtual && totalPaginas && totalRegistros ? (
        <Pagination>
          {totalPaginas > 1 ? (
            <div className="pages">
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(1)}
                >
                  &laquo;
                </Button>
              ) : null}
              {paginaAtual > 1 ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual - 1)}
                >
                  {paginaAtual - 1}
                </Button>
              ) : null}
              <Button
                background={colors.orange}
                onClick={() => alterarPagina(1)}
              >
                {paginaAtual}
              </Button>
              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  {paginaAtual + 1}
                </Button>
              ) : null}

              {paginaAtual < totalPaginas ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(paginaAtual + 1)}
                >
                  &raquo;
                </Button>
              ) : null}

              {totalPaginas > paginaAtual ? (
                <Button
                  background={colors.grey}
                  onClick={() => alterarPagina(totalPaginas)}
                >
                  {totalPaginas}
                </Button>
              ) : null}
            </div>
          ) : null}
          {/* <span>
            {`${totalRegistros} ${
              totalRegistros > 1 ? 'Registros' : 'Registro'
            }`}
          </span> */}
          <div className="totalizadores">
            <Button onClick={abrirTotalizadores} background={colors.grey}>
              Totalizadores
            </Button>
          </div>
        </Pagination>
      ) : null}
    </Container>
  );
};

export default Listagem;
