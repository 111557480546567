import React from 'react';
import { useTheme } from 'styled-components';

import { TooltipProps } from './interface';
import { Container } from './styles';

const NovaTab: React.FC<TooltipProps> = ({ id, content }) => {
  const { colors } = useTheme();
  return (
    <Container
      id={id}
      style={{
        backgroundColor: `${colors.label}`,
        color: `${colors.secondary}`,
      }}
      content={content || ''}
      place="bottom"
    />
  );
};

export default NovaTab;
