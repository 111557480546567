import styled, { css } from 'styled-components';

export const TagsAreaOnVisibility = styled.div`
  ${() => css``};
`;

export const TagsAreaOnContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;

    h4 {
      width: 100%;
      float: left;
      text-align: left;
      font-size: ${theme.fontSizes.default};
      color: ${theme.colors.label};
    }

    span {
      width: 100%;
      float: left;
      text-align: center;
      padding: 1.5rem;
    }

    h3 {
      font-size: 1.6rem;
      width: 100%;
      float: left;
      margin: 0 0 1rem 0;
      padding: 0;
    }

    @media (max-width: 1024px) {
      padding: 0 2rem;
    }
  `}
`;

export const TagsDropDownArea = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 12rem;
    height: auto;
    float: left;
    border: solid 0.1rem ${theme.colors.default};
    padding: 1rem;
    border-radius: ${theme.radio.default};
    background: ${theme.colors.secondary};

    h4 {
      float: left !important;
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.placeholder};
      margin: 0;
      font-weight: 500;
      opacity: 0.6;
    }
  `}
`;
