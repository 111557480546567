import styled, { css, keyframes } from 'styled-components';

import { TagsAreaFooterProps, TagsGroupsItemProps } from './interface';

export const TagsAreaFooter = styled.div<TagsAreaFooterProps>`
  ${({ $disabled }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin-top: 3rem;

    opacity: ${$disabled ? '0.2' : '1'};
    pointer-events: ${$disabled ? 'none' : 'all'};

    button {
      max-width: 35%;
      margin-left: 0.5rem;
    }

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const TagsGroups = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const TagsGroupsItem = styled.div<TagsGroupsItemProps>`
  ${({ theme, $active }) => css`
    float: left;
    cursor: pointer;
    /* background: ${theme.colors.default}; */
    background: ${$active
      ? theme.colors.controlFocus
      : theme.colors.controlNotFocus};
    min-width: 15rem;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    font-size: ${theme.fontSizes.default};
    font-weight: 600;
    margin-right: 1rem;
    color: ${$active ? theme.colors.label : theme.colors.placeholder};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;

    svg {
      margin-left: 1rem;
    }

    &:hover {
      color: ${theme.colors.label};
      background: ${theme.colors.controlFocus};
    }
  `}
`;

const loadAnimation = keyframes`
  	0% {
			width: 0px;
		}
    30% {
      width: 65%;
    }
    65% {
      width: 85%;
    }
		100% {
			width: 100%;
		}
`;

export const TagsEmpty = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    height: 38rem;
    font-size: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.default};

    span {
      width: 100%;
      height: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      overflow: hidden;

      i {
        background: rgba(255, 255, 255, 0.1);
        animation: ${loadAnimation} 0.15s linear forwards;
        float: left;
        height: 20px;
      }
    }
  `}
`;
