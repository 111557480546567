import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { GiPriceTag } from 'react-icons/gi';

import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader } from '~/components';

import api from '~/services/api';

import { TipoPagamentoType } from '~/types';

const TiposDePagamentos: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);

  const [tiposDePagamentos, setTiposDePagamentos] = useState<
    Array<TipoPagamentoType>
  >([]);

  const handlerEditTipoDePagamento = useCallback(
    (tipoDePagamento: TipoPagamentoType) => {
      history(`/tipo-de-pagamento/${tipoDePagamento.id}`);
    },
    [history],
  );

  useEffect(() => {
    async function fetchtiposDePagamentos() {
      try {
        setLoading(true);
        const response = await api.get(`financeiros/tipos-pagamento`);
        setTiposDePagamentos(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    fetchtiposDePagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Loader visible={loading} />
      <Header />
      <Content>
        <Plate
          title="Tipos de Pagamentos"
          subtitle="Cadastre os tipos de pagamentos"
          icon={() => <GiPriceTag size={32} color={colors.label} />}
        >
          <Link to="/tipo-de-pagamento/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {tiposDePagamentos ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              // {
              //   column: 'situacao_lancamento',
              //   label: 'Situação de Lançamento',
              //   search: false,
              // },
              {
                column: 'ativo',
                label: 'Ativo',
                search: false,
              },
            ]}
            data={[
              ...tiposDePagamentos.map(tipoDePagamento => ({
                ...tipoDePagamento,
                ativo: tipoDePagamento.ativo ? 'SIM' : 'NAO',
              })),
            ]}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditTipoDePagamento(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default TiposDePagamentos;
