import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { IoMdClose } from 'react-icons/io';

import { HiSearch } from 'react-icons/hi';

import { CgRadioCheck, CgRadioChecked } from 'react-icons/cg';

import api from '~/services/api';

import {
  FinderContainer,
  FinderContent,
  FinderLabel,
  FinderLoading,
  FinderEmpty,
  FinderList,
  FinderScroll,
  FinderItem,
  ContentButtons,
  Content,
} from './styles';

import { FinderProps } from './interface';
import { Button } from '~/components';

const Finder: React.FC<FinderProps> = ({
  openned = false,
  isMult = false,
  onSelectValue,
  defaultValue,
  tipos,
  onlySearch = false,
  placeholderInput,
  placeholderValue,
  ignoreLabel,
}) => {
  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Array<any> | null>(null);
  const [descricao, setDescricao] = useState('');
  const [selecionados, setSelecionados] = useState([] as any[]);
  const [searchQuery, setSearchQuery] = useState('');

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue) {
      selecionados.push({ ...defaultValue, checked: true });
      const auxProdutos: any = selecionados;

      setSelecionados(auxProdutos);
      setDescricao(defaultValue.descricao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRequestOpen = useCallback(() => {
    setVisible(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  }, []);

  const onRequestClose = useCallback(() => {
    onSelectValue(selecionados, descricao);
    setSearchQuery('');
    setVisible(false);
  }, [onSelectValue, setVisible, selecionados, descricao]);

  useEffect(() => {
    if (openned) {
      onRequestOpen();
    }
  }, [openned, onRequestOpen]);

  const clear = useCallback(() => {
    setDescricao('');
    setSelecionados([]);
    onSelectValue([], '');
    setResults(null);
  }, [onSelectValue]);

  const onRequestClear = () => {
    clear();
  };

  const onRequestCancel = () => {
    // clear();
    setVisible(false);
  };

  const onRequestSearch = useCallback(
    async (currentSearchQuery: string) => {
      try {
        setLoading(true);
        // Para passar mais de um tipo, passar uma string separada por virgula
        const response = await api.get(
          `/parceiros?nome_fantasia=${currentSearchQuery}&tipos=${tipos}`,
        );
        if (response.data) {
          const { data } = response.data;
          setResults(data);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [tipos],
  );

  const onRequestItem = (current: any, currentDescricao: string) => {
    let auxSelecionado = [];
    let checked = true;
    if (isMult) {
      if (!selecionados.includes(current)) {
        selecionados.push(current);
        auxSelecionado = selecionados;
        checked = true;
      } else {
        auxSelecionado = selecionados.filter(item => item.id !== current.id);
        checked = false;
      }
    } else {
      auxSelecionado.push(current);
      checked = true;
    }

    const auxResults: any = results?.map(item => {
      if (item.id === current.id) {
        Object.assign(item, { checked });
      }
      return item;
    });
    setResults(auxResults);
    setSelecionados(auxSelecionado);

    let auxDescricao = '';
    if (auxSelecionado.length > 1) {
      auxDescricao = 'Vários selecionados...';
    } else {
      auxDescricao = currentDescricao;
    }

    setDescricao(auxDescricao);

    if (!isMult) {
      onSelectValue(auxSelecionado, auxDescricao);
      setVisible(false);
    }
  };

  const onRequestUnsearch = () => {
    setSearchQuery('');
  };

  const handlerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      if (searchQuery.length >= 1) {
        onRequestSearch(searchQuery);
      }
      e.preventDefault();
    }
  };

  const handlerOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e && e.target) {
        setSearchQuery(e.target.value);
      }
    },
    [],
  );

  // const handlerHideContent = useCallback(
  //   (event: KeyboardEvent): void => {
  //     if (event.key === 'Escape') {
  //       setVisible(false);
  //     }
  //   },
  //   [setVisible],
  // );

  // const handleClickOutside = useCallback(
  //   (event: Event): void => {
  //     if (ref.current && !ref.current.contains(event.target as Node)) {
  //       setVisible(false);
  //     }
  //   },
  //   [setVisible],
  // );

  // useEffect(() => {
  //   document.addEventListener('keydown', handlerHideContent, true);
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('keydown', handlerHideContent, true);
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [handlerHideContent, handleClickOutside]);

  return (
    <>
      <FinderContainer $visible={visible}>
        <FinderContent ref={ref}>
          <header>
            <div>
              <input
                ref={inputRef}
                placeholder={placeholderInput}
                value={searchQuery}
                onChange={handlerOnChange}
                onKeyDown={handlerKeyDown}
              />

              {searchQuery ? (
                <button type="button" onClick={onRequestUnsearch}>
                  <IoMdClose
                    size={30}
                    color={colors.label}
                    onClick={onRequestClear}
                  />
                </button>
              ) : null}
              <button
                type="button"
                onClick={() => onRequestSearch(searchQuery)}
              >
                <HiSearch
                  size={24}
                  color={colors.label}
                  onClick={onRequestOpen}
                />
              </button>
            </div>
            {/*
            <button
              className="finder-close"
              type="button"
              onClick={onRequestClose}
            >
              <AiOutlineCloseCircle size={32} color={colors.label} />
            </button> */}
          </header>
          <main>
            {loading ? (
              <FinderLoading>
                <span>
                  <AiOutlineLoading3Quarters size={32} color={colors.label} />
                </span>
              </FinderLoading>
            ) : null}

            {!loading &&
            !results?.length &&
            searchQuery.length > 0 &&
            results !== null ? (
              <FinderEmpty>Nenhum resultado encontrado.</FinderEmpty>
            ) : null}

            {!loading && results === null ? (
              <FinderEmpty>Pressione enter para buscar.</FinderEmpty>
            ) : null}

            {results?.length ? (
              <FinderList>
                <FinderScroll>
                  {results?.map(item => (
                    <FinderItem
                      key={item.id}
                      onClick={() => onRequestItem(item, item.nome_fantasia)}
                      $active={item.checked}
                    >
                      {item.checked ? (
                        <CgRadioChecked size={26} color={colors.primary} />
                      ) : (
                        <CgRadioCheck size={26} color={colors.label} />
                      )}
                      <Content>
                        <div>
                          <span>Código:</span>
                          <strong>{item.codigo}</strong>
                          <span>CPF/CNPJ:</span>
                          <strong>{item.cnpj_cpf}</strong>
                        </div>
                        <div>
                          <span>Nome:</span>
                          <strong>{item.nome_fantasia}</strong>
                        </div>
                      </Content>
                    </FinderItem>
                  ))}
                </FinderScroll>
              </FinderList>
            ) : null}
          </main>
          <ContentButtons>
            <Button
              type="button"
              background={colors.grey}
              onClick={onRequestCancel}
            >
              Cancelar
            </Button>
            {isMult && (
              <Button
                type="button"
                background={colors.green}
                onClick={onRequestClose}
              >
                OK
              </Button>
            )}
          </ContentButtons>
        </FinderContent>
      </FinderContainer>
      {!ignoreLabel ? (
        <FinderLabel $onlysearch={onlySearch}>
          <button type="button" onClick={onRequestOpen}>
            {!onlySearch && (
              <>
                {!visible ? (
                  <span className={descricao ? 'isok' : 'empty'}>
                    {descricao || placeholderValue}
                  </span>
                ) : (
                  <span className="empty" />
                )}
                {descricao ? (
                  <IoMdClose
                    size={32}
                    color={colors.label}
                    onClick={onRequestClear}
                  />
                ) : null}
              </>
            )}
            <HiSearch size={24} color={colors.label} />
          </button>
        </FinderLabel>
      ) : null}
    </>
  );
};

export default Finder;
