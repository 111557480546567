import axios from 'axios';

interface CepResponse {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
}

const fetchCEPinfo = (cep: string): Promise<CepResponse | null> => {
  return new Promise<CepResponse | null>(resolve => {
    const handler = async () => {
      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`,
        );
        const { data } = response;
        resolve(data);
      } catch (e) {
        resolve(null);
      }
    };
    handler();
  });
};

export { fetchCEPinfo };
