import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.contrast};
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.5rem 0;
      width: 100%;
      float: left;
      justify-content: space-between;
    }
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    span {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
    }

    h1 {
      font-size: ${theme.colors.label};
      font-size: ${theme.fontSizes.big};
    }
  `}
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    background: none;
    margin-right: 1rem;
  }
`;
