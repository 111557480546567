import React from 'react';
import 'react-day-picker/dist/style.css';
import { AnimatedContainer } from '~/components';

import Header from '~/components/Header';

const NovaAba: React.FC = () => {
  return (
    <AnimatedContainer>
      <Header openSearch />
    </AnimatedContainer>
  );
};

export default NovaAba;
