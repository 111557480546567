import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';

import { Modal } from '~/components';

import { Container, Content, Control, Title, Opt } from './styles';

import { AccordionProps } from './interface';

const Accordion: React.FC<AccordionProps> = ({
  nome,
  descricao,
  modalSize,
  children,
  visibilidadeInicial,
}) => {
  const { colors } = useTheme();

  const [visibilidade, setVisibilidade] = useState(visibilidadeInicial);
  const [modalVisibilidade, setModalVisibilidade] = useState(false);

  const inverterVisibilidade = () => {
    setVisibilidade(!visibilidade);
  };

  const fecharModal = () => {
    setModalVisibilidade(false);
  };

  return (
    <Container>
      {modalVisibilidade ? (
        <Modal
          title={`${nome}`}
          width={modalSize}
          visible
          scrollable
          onRequestClose={fecharModal}
        >
          {children}
        </Modal>
      ) : null}

      <Control>
        <Title>
          <h1>{nome}</h1>
          <p>{descricao}</p>
        </Title>
        <Opt onClick={inverterVisibilidade}>
          {visibilidade ? (
            <MdArrowDropUp size={72} color={colors.label} />
          ) : (
            <MdArrowDropDown size={72} color={colors.label} />
          )}
        </Opt>
      </Control>
      {visibilidade ? <Content>{children}</Content> : null}
    </Container>
  );
};

export default Accordion;
