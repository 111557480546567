import styled, { css } from 'styled-components';

import { HSICProps } from './interface';

export const HeaderSearchInputContainer = styled.div<HSICProps>`
  ${({ theme, $focused }) => css`
    display: flex;
    width: 100%;
    padding: 1rem 0 4rem;
    margin: 0;
    flex-direction: row;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      max-width: 102.4rem;
      width: 100%;
    }

    span {
      color: ${theme.colors.label};
      background: ${theme.colors.background};
      font-size: ${theme.fontSizes.small};
      margin: 0rem 0 -1rem 1.5rem;
      padding: 0.5rem 1rem;
      float: left;
      width: auto;
      position: relative;
      z-index: 200;
      align-self: flex-start;
      border: 0.2rem solid ${theme.colors.default};
      border-radius: 0.5rem;
    }

    p {
      margin: 0rem;
      padding: 0rem;
      width: 100%;
      border: 0.2rem solid ${theme.colors.default};
      border-color: ${$focused ? theme.colors.primary : theme.colors.default};
      border-radius: 0.5rem;
      transition: all 0.5s ease;
      background: ${$focused
        ? theme.colors.controlFocus
        : theme.colors.controlNotFocus};
      padding: 1rem;
      align-items: center;
      display: flex;

      svg {
        cursor: pointer;
      }
    }

    input {
      padding: 1rem;
      flex: 1;
      height: 3.6rem;
      background: transparent;
      border: none;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};

      ::-webkit-input-placeholder {
        color: ${theme.colors.placeholder};
      }
    }
  `}
`;
