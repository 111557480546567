import { AxiosError } from 'axios';

const handlerErrors = (error: Error | AxiosError | unknown): string => {
  let message = 'Erro encontrado';

  const err = error as AxiosError;
  const dataInError = err.response?.data;

  if (dataInError) {
    message = `${JSON.stringify(dataInError)}`;
  } else {
    message = `${(error as Error).message}`;
  }

  return message;
};

export { handlerErrors };
