import React, { useCallback, useRef, useState } from 'react';

import * as Yup from 'yup';

import { BiSolidErrorAlt } from 'react-icons/bi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { getValidationErrors } from '~/utils';
import { fetchDescForIndPag, fetchDescForTPag } from '~/utils/nfe';

import { useToast } from '~/hooks/toast';

import { useNFE } from '~/hooks/nfe';

import {
  Aba,
  Abas,
  Container,
  Info,
  ListArea,
  ListOptions,
  ErrosArea,
} from './styles';

import List from '~/components/List';

import Loader from '~/components/Loader';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
} from '~/components';

import FormaPagamento from './FormaPagamento';
import Ocorrencia from './Ocorrencia';
import Produto from './Produto';
import Financeiro from './Financeiro';

import ErrosXML from '../ErrosXML';

import { EdicaoXMLFormData, EdicaoXMLProps } from './interface';

import { PagamentoNfeType, ProdutoNfeType } from '~/types';

const EdicaoXML: React.FC<EdicaoXMLProps> = ({
  nfe,
  visible,
  onRequestClose,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const { cadastrarItensAutomaticamente, validarNFE, existeErroNaNFE } =
    useNFE();

  const [activeAba, setActiveAba] = useState('aba-1');

  const [formError, setFormError] = useState('');

  const [formLoading, setFormLoading] = useState(false);

  const [produto, setProduto] = useState<ProdutoNfeType | null>(null);
  const [formaPagamento, setFormaPagamento] = useState<PagamentoNfeType | null>(
    null,
  );
  const [ocorrencia, setOcorrencia] = useState<ProdutoNfeType | null>(null);

  const [CTALoad, setCTALoad] = useState(false);
  const [CTAError, setCTAError] = useState('');

  const [errosXMLVisible, setErrosXMLVisible] = useState(false);

  const abrirErrosXML = () => {
    setErrosXMLVisible(true);
  };

  const fecharErrosXML = () => {
    setErrosXMLVisible(false);
  };

  const formRef = useRef<FormHandles>(null);

  const onRequestOpenProduto = (pProdutoXML: ProdutoNfeType) => {
    setProduto(pProdutoXML);
  };

  const onRequestCloseProduto = () => {
    setProduto(null);
  };

  const onRequestSaveProduto = () => {
    setProduto(null);
  };

  const onRequestOpenFormaPagamento = (pFormaPagamento: PagamentoNfeType) => {
    setFormaPagamento(pFormaPagamento);
  };

  const onRequestCloseFormaPagamento = () => {
    setFormaPagamento(null);
  };

  const onRequestSaveFormaPagamento = () => {
    setFormaPagamento(null);
  };

  const onRequestOpenOcorrencia = (pProdutoXML: ProdutoNfeType) => {
    setOcorrencia(pProdutoXML);
  };

  const onRequestCloseOcorrencia = () => {
    setOcorrencia(null);
  };

  const handleSubmit = useCallback(
    async (data: EdicaoXMLFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe a descrição'),
        });

        await schema.validate(data, { abortEarly: false });

        setFormLoading(false);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'XML salvo com sucesso!',
        });
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o xml, tente novamente...');
      }
    },
    [addToast],
  );

  // const submitForm = () => {
  //   formRef.current?.submitForm();
  // };

  const pagamentos = [
    {
      ind_forma_pagamento: fetchDescForIndPag(
        `${nfe.arquivo.pagamento?.PAG_indPag}`,
      ),
      meio_pagamento: fetchDescForTPag(`${nfe.arquivo.pagamento?.PAG_tPag}`),
      meio_pagamento_descricao: '',
      valor_pagamento: `${nfe.arquivo.pagamento?.PAG_vPag}`,
      pagamento: nfe.arquivo.pagamento,
    },
  ];

  const cadastrarTodosAutomaticamente = async () => {
    setCTALoad(true);
    const { status, message } = await cadastrarItensAutomaticamente(nfe);
    setCTALoad(false);
    if (!status) {
      setCTAError(message);
    } else {
      validarNFE(nfe);
    }
  };

  return (
    <Modal
      title={`NFE: ${nfe.nome}`}
      width="120rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Loader visible={CTALoad} />

      {errosXMLVisible ? (
        <ErrosXML
          nfe={nfe}
          onRequestClose={fecharErrosXML}
          visible={errosXMLVisible}
        />
      ) : null}

      {produto ? (
        <Produto
          onRequestClose={onRequestCloseProduto}
          onRequestSave={onRequestSaveProduto}
          produto={produto}
        />
      ) : null}

      {formaPagamento ? (
        <FormaPagamento
          pagamento={formaPagamento}
          onRequestClose={onRequestCloseFormaPagamento}
          onRequestSave={onRequestSaveFormaPagamento}
        />
      ) : null}

      {ocorrencia ? (
        <Ocorrencia
          nfe={nfe}
          produto={ocorrencia}
          onRequestClose={onRequestCloseOcorrencia}
        />
      ) : null}

      <Container>
        {nfe.status === 'loaded' && existeErroNaNFE(nfe) === true ? (
          <ErrosArea>
            <BiSolidErrorAlt size={32} color={colors.error} />
            <span>
              <h1>
                {nfe && nfe.erros && nfe.erros.length > 0
                  ? `${
                      nfe.erros.length > 1
                        ? `${nfe.erros.length} ERROS ENCONTRADOS`
                        : '1 ERRO ENCONTRADO'
                    }`
                  : 'Nenhum erro encontrado'}
              </h1>
              <a onClick={() => abrirErrosXML()}>VER DETALHES</a>
            </span>
          </ErrosArea>
        ) : null}

        <FormContainer>
          <Form ref={formRef} initialData={nfe.arquivo} onSubmit={handleSubmit}>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <FormBlock>
                <Info>Dados Gerais</Info>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Chave de Acesso</span>
                    <div>
                      <Input disabled name="PROTNFE_chNFe" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading} width={300}>
                    <span>Número</span>
                    <div>
                      <Input disabled name="PROTNFE_nProt" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading} width={300}>
                    <span>Versão do XML</span>
                    <div>
                      <Input disabled name="PROTNFE_verAplic" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
              </FormBlock>

              <Abas>
                <Aba
                  $active={activeAba === 'aba-1'}
                  onClick={() => setActiveAba('aba-1')}
                >
                  NFe
                </Aba>
                <Aba
                  $active={activeAba === 'aba-2'}
                  onClick={() => setActiveAba('aba-2')}
                >
                  Emitente
                </Aba>
                <Aba
                  $active={activeAba === 'aba-3'}
                  onClick={() => setActiveAba('aba-3')}
                >
                  Destinatário
                </Aba>
                <Aba
                  $active={activeAba === 'aba-4'}
                  onClick={() => setActiveAba('aba-4')}
                >
                  Produtos e Serviços
                </Aba>
                <Aba
                  $active={activeAba === 'aba-5'}
                  onClick={() => setActiveAba('aba-5')}
                >
                  Totais
                </Aba>
                <Aba
                  $active={activeAba === 'aba-6'}
                  onClick={() => setActiveAba('aba-6')}
                >
                  Transporte
                </Aba>
                <Aba
                  $active={activeAba === 'aba-7'}
                  onClick={() => setActiveAba('aba-7')}
                >
                  Cobrança
                </Aba>
                <Aba
                  $active={activeAba === 'aba-8'}
                  onClick={() => setActiveAba('aba-8')}
                >
                  Informações Adicionais
                </Aba>

                <Aba
                  $active={activeAba === 'aba-9'}
                  onClick={() => setActiveAba('aba-9')}
                >
                  Financeiro
                </Aba>
              </Abas>

              {activeAba === 'aba-1' ? (
                <FormBlock>
                  <Info>Dados da NF-e</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Modelo</span>
                      <div>
                        <Input disabled name="IDE_mod" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Série</span>
                      <div>
                        <Input disabled name="IDE_serie" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Número</span>
                      <div>
                        <Input disabled name="IDE_nNF" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Data de Emissão</span>
                      <div>
                        <Input disabled name="IDE_dhEmi" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Data/Hora de Saída ou da Entrada</span>
                      <div>
                        <Input disabled name="IDE_dhSaiEnt" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Valor Total da Nota Fiscal</span>
                      <div>
                        <Input disabled name="TOTAL_vNF" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>

                  <Info>Emitente</Info>

                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CNPJ</span>
                      <div>
                        <Input disabled name="EMIT_CNPJ" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Nome / Razão Social</span>
                      <div>
                        <Input disabled name="EMIT_xNome" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual</span>
                      <div>
                        <Input disabled name="EMIT_IE" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading} width={100}>
                      <span>UF</span>
                      <div>
                        <Input disabled name="EMIT_UF" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>

                  <Info>Destinatário</Info>

                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CNPJ/CPF</span>
                      <div>
                        <Input disabled name="DEST_CNPJ" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Nome / Razão Social</span>
                      <div>
                        <Input disabled name="DEST_xNome" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual/RG</span>
                      <div>
                        <Input disabled name="DEST_IE" placeholder="" />
                      </div>
                    </InputContainer>

                    <InputContainer disabled={formLoading} width={100}>
                      <span>UF</span>
                      <div>
                        <Input disabled name="DEST_UF" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>

                  {/* <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Destino da Operação</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Consumidor Final</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Presença de Comprador</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer> */}

                  <Info>Emissão</Info>

                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Processo</span>
                      <div>
                        <Input disabled name="IDE_procEmi" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Versão do Processo</span>
                      <div>
                        <Input disabled name="IDE_verProc" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={200}>
                      <span>Tipo de Emissão</span>
                      <div>
                        <Input disabled name="IDE_tpEmis" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={100}>
                      <span>Finalidade</span>
                      <div>
                        <Input disabled name="IDE_finNFe" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>

                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Natureza da Operação</span>
                      <div>
                        <Input disabled name="IDE_natOp" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Indicador de Intermediário/Marketplace</span>
                      <div>
                        <Input disabled name="IDE_indPres" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={200}>
                      <span>Tipo de Operacao</span>
                      <div>
                        <Input disabled name="IDE_tpNF" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading} width={300}>
                      <span>Digest Value da NF-e</span>
                      <div>
                        <Input disabled name="IDE_cDV" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>

                  {/* <Info>
                    {xmlData
                      ? `Situação Atual: ${xmlData?.PROTNFE_xMotivo}`
                      : 'Situação Atual Não informada'}
                  </Info> */}

                  {/* <ListArea>
                    <List
                      inModal
                      header={[
                        {
                          column: 'evento_nfe',
                          label: 'Eventos da NF-e',
                        },
                        {
                          column: 'protocolo',
                          label: 'Protocolo',
                        },
                        {
                          column: 'data_autorizacao',
                          label: 'Data Autorização',
                        },
                        {
                          column: 'data_inclusao_an',
                          label: 'Data Inclusão AN',
                        },
                      ]}
                      data={[
                        {
                          evento_nfe: 'Autorização de Uso',
                          protocolo: '131193250258665',
                          data_autorizacao: '03/04/2019 às 16:35:05-03:00',
                          data_inclusao_an: '03/04/2019 às 16:40:59',
                        },
                        {
                          evento_nfe: 'CT-e Autorizado (Órgão Autor: MG)',
                          protocolo: '131193250258665',
                          data_autorizacao: '03/04/2019 às 16:35:05-03:00',
                          data_inclusao_an: '03/04/2019 às 16:40:59',
                        },
                        {
                          evento_nfe:
                            'MDF-e Autorizado com CT-e (Órgão Autor: AN)',
                          protocolo: '131193250258665',
                          data_autorizacao: '03/04/2019 às 16:35:05-03:00',
                          data_inclusao_an: '03/04/2019 às 16:40:59',
                        },
                      ]}
                      options={[]}
                    />
                  </ListArea> */}
                </FormBlock>
              ) : null}

              {activeAba === 'aba-2' ? (
                <FormBlock>
                  <Info>Dados do Emitente</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Nome / Razão Social</span>
                      <div>
                        <Input disabled name="EMIT_xNome" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Nome Fantasia</span>
                      <div>
                        <Input disabled name="EMIT_xFant" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CNPJ</span>
                      <div>
                        <Input disabled name="EMIT_CNPJ" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Endereço</span>
                      <div>
                        <Input disabled name="EMIT_xLgr" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Bairro / Distrito</span>
                      <div>
                        <Input disabled name="EMIT_xBairro" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CEP</span>
                      <div>
                        <Input disabled name="EMIT_CEP" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Município</span>
                      <div>
                        <Input disabled name="EMIT_cMun" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Telefone</span>
                      <div>
                        <Input disabled name="EMIT_fone" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>UF</span>
                      <div>
                        <Input disabled name="EMIT_UF" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>País</span>
                      <div>
                        <Input disabled name="EMIT_cPais" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual</span>
                      <div>
                        <Input disabled name="EMIT_IE" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual do Substituto Tributário</span>
                      <div>
                        <Input disabled name="EMIT_IEST" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Municipal</span>
                      <div>
                        <Input disabled name="EMIT_xMun" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>
                        Município da Ocorrência do Fato Gerador do ICMS
                      </span>
                      <div>
                        <Input disabled name="IDE_cMunFG" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  {/* <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CNAE Fiscal</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Código de Regime Tributário</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer> */}
                </FormBlock>
              ) : null}

              {activeAba === 'aba-3' ? (
                <FormBlock>
                  <Info>Dados do Destinatário</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Nome / Razão Social</span>
                      <div>
                        <Input disabled name="DEST_xNome" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CPF / CNPJ</span>
                      <div>
                        <Input disabled name="DEST_CNPJ" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Endereço</span>
                      <div>
                        <Input disabled name="DEST_xLgr" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Bairro / Distrito</span>
                      <div>
                        <Input disabled name="DEST_xBairro" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CEP</span>
                      <div>
                        <Input disabled name="DEST_CEP" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Município</span>
                      <div>
                        <Input disabled name="DEST_cMun" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Telefone</span>
                      <div>
                        <Input disabled name="DEST_fone" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>UF</span>
                      <div>
                        <Input disabled name="DEST_UF" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>País</span>
                      <div>
                        <Input disabled name="DEST_cPais" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading} width={200}>
                      <span>Indicador IE</span>
                      <div>
                        <Input disabled name="DEST_indIEDest" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual</span>
                      <div>
                        <Input disabled name="DEST_IE" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>E-mail</span>
                      <div>
                        <Input disabled name="DEST_email" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  {/* <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>IM</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição SUFRAMA</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer> */}
                </FormBlock>
              ) : null}

              {activeAba === 'aba-4' ? (
                <FormBlock>
                  <Info>Dados dos Produtos e Serviços</Info>
                  <ListArea>
                    <List
                      inModal
                      header={[
                        {
                          column: 'num',
                          label: 'Num.',
                        },
                        {
                          column: 'descricao',
                          label: 'Descrição',
                        },
                        {
                          column: 'qtd',
                          label: 'Qtd.',
                        },
                        {
                          column: 'unidade_comercial',
                          label: 'Unidade Comercial',
                        },
                        {
                          column: 'valor',
                          label: 'Valor (R$)',
                        },
                      ]}
                      data={[
                        ...(nfe.arquivo.itens
                          ? nfe.arquivo.itens.map((item: ProdutoNfeType) => ({
                              num: `${
                                Number(item.produto_id) !== 0
                                  ? item.produto_id
                                  : item.nItem
                              }`,
                              descricao: `${
                                item.produto_descricao
                                  ? item.produto_descricao
                                  : item.PROD_xProd
                              }`,
                              qtd: `${item.PROD_qCom}`,
                              unidade_comercial: `${item.PROD_uCom}`,
                              valor: `${item.PROD_vProd}`,
                              list_line_color: item.status
                                ? colors.success
                                : colors.error,
                              list_line_options: item.status ? [1] : [1, 2],
                              list_line_title: `O que deseja fazer no item: "${item.PROD_xProd}"?`,
                              produto: item,
                            }))
                          : []),
                      ]}
                      options={[
                        {
                          id: 1,
                          label: 'Visualizar Detalhes',
                          onPress: item => {
                            onRequestOpenProduto(item.produto);
                          },
                        },
                        {
                          id: 2,
                          label: 'Resolver Ocorrências',
                          onPress: item => {
                            onRequestOpenOcorrencia(item.produto);
                          },
                        },
                      ]}
                    />

                    <ErrorMessage
                      error={CTAError}
                      onDismiss={() => setCTAError('')}
                    />

                    <ListOptions>
                      <div>
                        <Button
                          onClick={() => cadastrarTodosAutomaticamente()}
                          disabled={CTALoad}
                          type="button"
                          background={colors.green}
                        >
                          Cadastrar Todos Automaticamente
                        </Button>
                      </div>
                    </ListOptions>
                  </ListArea>
                </FormBlock>
              ) : null}

              {activeAba === 'aba-5' ? (
                <FormBlock>
                  <Info>ICMS</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Base de Cálculo ICMS</span>
                      <div>
                        <Input disabled name="TOTAL_vBC" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor do ICMS</span>
                      <div>
                        <Input disabled name="TOTAL_vICMS" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor do ICMS Desonerado</span>
                      <div>
                        <Input
                          disabled
                          name="TOTAL_vICMSDeson"
                          placeholder=""
                        />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total do FCP</span>
                      <div>
                        <Input disabled name="TOTAL_vFCP" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total ICMS FCP</span>
                      <div>
                        <Input disabled name="TOTAL_vBCST" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total ICMS Interestadual UF Destino</span>
                      <div>
                        <Input disabled name="TOTAL_vST" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total ICMS Interestadual UF Rem</span>
                      <div>
                        <Input disabled name="TOTAL_vFCPST" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Base de Cálculo ICMS ST</span>
                      <div>
                        <Input disabled name="TOTAL_vFCPSTRet" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor ICMS Substituição</span>
                      <div>
                        <Input disabled name="TOTAL_vFCPST" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total do FCP retido por ST</span>
                      <div>
                        <Input disabled name="TOTAL_vFCPST" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>
                        Valor Total do FCP retido anteriormente por ST
                      </span>
                      <div>
                        <Input disabled name="TOTAL_vFCPSTRet" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total dos Produtos</span>
                      <div>
                        <Input disabled name="TOTAL_vProd" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor do Frete</span>
                      <div>
                        <Input disabled name="TOTAL_vFrete" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor do Seguro</span>
                      <div>
                        <Input disabled name="TOTAL_vSeg" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total dos Descontos</span>
                      <div>
                        <Input disabled name="TOTAL_vDesc" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total do II</span>
                      <div>
                        <Input disabled name="TOTAL_vII" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total do IPI</span>
                      <div>
                        <Input disabled name="TOTAL_vIPI" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total do IPI Devolvido</span>
                      <div>
                        <Input disabled name="TOTAL_vIPIDevol" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor do PIS</span>
                      <div>
                        <Input disabled name="TOTAL_vPIS" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor da COFINS</span>
                      <div>
                        <Input disabled name="TOTAL_vCOFINS" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Outras Despesas Acessórias</span>
                      <div>
                        <Input disabled name="TOTAL_vOutro" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Total da NFe</span>
                      <div>
                        <Input disabled name="TOTAL_vNF" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Valor Aproximado dos Tributos</span>
                      <div>
                        <Input disabled name="TOTAL_vTotTrib" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                </FormBlock>
              ) : null}

              {activeAba === 'aba-6' ? (
                <FormBlock>
                  <Info>Dados do Transporte</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Modalidade do Frete</span>
                      <div>
                        <Input disabled name="TRANSP_modFrete" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <Info>Transportador</Info>

                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>CNPJ</span>
                      <div>
                        <Input disabled name="TRANSP_CNPJ" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Razão Social / Nome</span>
                      <div>
                        <Input disabled name="TRANSP_xNome" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Inscrição Estadual</span>
                      <div>
                        <Input disabled name="TRANSP_IE" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Endereço Completo</span>
                      <div>
                        <Input disabled name="TRANSP_xEnder" placeholder="" />
                      </div>
                    </InputContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Município</span>
                      <div>
                        <Input disabled name="TRANSP_xMun" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <InputContainer disabled={formLoading} width={100}>
                      <span>UF</span>
                      <div>
                        <Input disabled name="TRANSP_UF" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                </FormBlock>
              ) : null}

              {activeAba === 'aba-7' ? (
                <FormBlock>
                  <Info>Formas de Pagamento</Info>
                  <ListArea>
                    <List
                      inModal
                      header={[
                        {
                          column: 'ind_forma_pagamento',
                          label: 'Ind. Forma de Pagamento.',
                        },
                        {
                          column: 'meio_pagamento',
                          label: 'Meio de Pagamento',
                        },
                        {
                          column: 'meio_pagamento_descricao',
                          label: 'Descrição do Meio de Pagamento',
                        },
                        {
                          column: 'valor_pagamento',
                          label: 'Valor do Pagamento',
                        },
                      ]}
                      data={pagamentos}
                      options={[
                        {
                          label: '+ Detalhes',
                          onPress: item => {
                            onRequestOpenFormaPagamento(item.pagamento);
                          },
                        },
                      ]}
                    />
                  </ListArea>
                </FormBlock>
              ) : null}

              {activeAba === 'aba-8' ? (
                <FormBlock>
                  <Info>Informações Adicionais</Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Formato de Impressão DANFE</span>
                      <div>
                        <Input disabled name="tpImp" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                  <Info>
                    Informações Complementares de Interesse do Contribuinte
                  </Info>
                  <InputsContainer>
                    <InputContainer disabled={formLoading}>
                      <span>Descrição</span>
                      <div>
                        <Input disabled name="ASSOCIAR" placeholder="" />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                </FormBlock>
              ) : null}

              {activeAba === 'aba-9' ? (
                <FormBlock>
                  <Info>Financeiro</Info>
                  <Financeiro nfe={nfe} />
                </FormBlock>
              ) : null}

              <FormFooter>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
                {/* <Button
                  type="button"
                  onClick={submitForm}
                  background={colors.green}
                >
                  Salvar
                </Button> */}
              </FormFooter>
            </FormContent>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default EdicaoXML;
