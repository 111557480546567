import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import api from '~/services/api';

import { Button, Loader, Modal } from '~/components';

import {
  Block,
  Footer,
  Information,
  Informations,
  Label,
  Line,
  Space,
  Title,
  Val,
} from './styles';

import { formatCustomBR } from '~/utils/formatDate';
import { handlerNumberToString } from '~/utils/money';

import { TransacaoFinanceiraType } from '~/types';

import { TransacoesPagamentoProps } from './interface';

const TransacoesPagamento: React.FC<TransacoesPagamentoProps> = ({
  visible,
  onRequestClose,
  filters,
}) => {
  const { operacao, operacao_id, contas } = filters;
  const { colors } = useTheme();

  const [loading, setLoading] = useState(true);
  const [transacoes, setTransacoes] = useState<TransacaoFinanceiraType[]>([]);

  const carregarTransacoesFinanceiras = useCallback(
    async (pOperacao = '', pOperacaoId = '', pContas = '') => {
      try {
        setLoading(true);

        const search = pContas
          ? `contas=${pContas}`
          : `operacao=${pOperacao}&operacao_id=${pOperacaoId}`;

        const response = await api.get(
          `/financeiros/transacoes-financeiras?${search}`,
        );
        if (response.data) {
          const { data } = response;
          if (data && data.status !== 'error') {
            setTransacoes(data);
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    carregarTransacoesFinanceiras(operacao, operacao_id, contas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title="TRANSAÇÕES DE PAGAMENTO"
      subtitle={`${operacao}: #${operacao_id}`}
      onRequestClose={onRequestClose}
      visible={visible}
      width="100rem"
      scrollable
    >
      {transacoes.map(transacao => (
        <React.Fragment key={transacao.id}>
          <Informations>
            <Line>
              <Block>
                <Label>ID:</Label>
                <Val>{transacao.id}</Val>
              </Block>
              <Block>
                <Label>BAIXA:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.valor_pago)}`}</Val>
              </Block>
              <Block>
                <Label>DATA:</Label>
                <Val color="#EB4A4A">
                  {formatCustomBR(new Date(transacao.created_at))}
                </Val>
              </Block>
              <Block>
                <Val color="#00AAE0">{transacao.usuario.nome_razao}</Val>
              </Block>
            </Line>
            <Line>
              <Block>
                <Label>ACRÉSCIMENTO:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.acrescimo)}`}</Val>
              </Block>
              <Block>
                <Label>MULTA:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.multa)}`}</Val>
              </Block>
              <Block>
                <Label>TAXA BOLETO:</Label>
                <Val>{`R$ ${handlerNumberToString(
                  transacao.taxa_boleto,
                )}`}</Val>
              </Block>
              <Block>
                <Label>JUROS:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.juros)}`}</Val>
              </Block>
              <Block>
                <Label>CRÉDITO:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.credito)}`}</Val>
              </Block>
            </Line>
            <Line>
              <Block>
                <Label>DESCONTO:</Label>
                <Val>{`R$ ${handlerNumberToString(transacao.desconto)}`}</Val>
              </Block>
              <Block>
                <Label>CALCULADO:</Label>
                <Val>
                  {`R$ ${handlerNumberToString(transacao.valor_calculado)}`}
                </Val>
              </Block>
              <Block>
                <Label>PAGO:</Label>
                <Val color="#0EAB1D">
                  <Val>{`R$ ${handlerNumberToString(
                    transacao.valor_pago,
                  )}`}</Val>
                </Val>
              </Block>
              <Block>
                <Label>CONTA:</Label>
                <Val>{transacao.conta_corrente.descricao}</Val>
              </Block>
            </Line>

            <Space />

            <Title>Contas:</Title>

            {transacao.transacoes_contas_financeiras.map((conta, index) => (
              <React.Fragment key={conta.id}>
                <Information $isodd={index % 2 === 0}>
                  <Line>
                    <Block>
                      <Label>ID:</Label>
                      <Val>{conta.id}</Val>
                    </Block>
                    <Block>
                      <Label>BAIXA:</Label>
                      <Val>{`R$ ${handlerNumberToString(
                        conta.valor_pago,
                      )}`}</Val>
                    </Block>
                    <Block>
                      <Label>DESCONTO:</Label>
                      <Val>{`R$ ${handlerNumberToString(conta.desconto)}`}</Val>
                    </Block>
                    <Block>
                      <Label>ACRÉSCIMO:</Label>
                      <Val>{`R$ ${handlerNumberToString(
                        conta.acrescimo,
                      )}`}</Val>
                    </Block>
                  </Line>
                  <Line>
                    <Block>
                      <Label>MULTA:</Label>
                      <Val>{`R$ ${handlerNumberToString(conta.multa)}`}</Val>
                    </Block>
                    <Block>
                      <Label>JUROS:</Label>
                      <Val>{`R$ ${handlerNumberToString(conta.juros)}`}</Val>
                    </Block>
                    <Block>
                      <Label>CRÉDITO:</Label>
                      <Val>{`R$ ${handlerNumberToString(conta.credito)}`}</Val>
                    </Block>
                    <Block>
                      <Label>CALCULADO:</Label>
                      <Val>
                        {`R$ ${handlerNumberToString(conta.valor_calculado)}`}
                      </Val>
                    </Block>
                    <Block>
                      <Label>PAGO:</Label>
                      <Val color="#0EAB1D">
                        {`R$ ${handlerNumberToString(conta.valor_pago)}`}
                      </Val>
                    </Block>
                  </Line>
                </Information>
              </React.Fragment>
            ))}

            <Title>Pagamento:</Title>

            {transacao.transacoes_financeiras_pagamento.map(
              (pagamento, index) => (
                <React.Fragment key={pagamento.id}>
                  <Information $isodd={index % 2 === 0}>
                    <Line>
                      <Block>
                        <Label>FORMA:</Label>
                        <Val>{pagamento.tipo_pagamento.descricao}</Val>
                      </Block>
                      <Block>
                        <Label>PAGO:</Label>
                        <Val $color="#0EAB1D">
                          {`R$ ${handlerNumberToString(pagamento.valor)}`}
                        </Val>
                      </Block>
                    </Line>
                  </Information>
                </React.Fragment>
              ),
            )}
          </Informations>
        </React.Fragment>
      ))}

      <Footer>
        <div>
          <Button
            type="button"
            background={colors.contrast}
            onClick={onRequestClose}
          >
            FECHAR
          </Button>
        </div>
      </Footer>
    </Modal>
  );
};

export default TransacoesPagamento;
