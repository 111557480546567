import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { handlerNumberToString } from '~/utils/money';

import { ParceiroType, CartItemType, PedidoType } from '~/types';

import { CartContextProps } from './interface';

const CartContext = createContext<CartContextProps>({} as CartContextProps);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [_items, _setItems] = useState<Array<CartItemType>>(() => {
    const cachedItems = localStorage.getItem('@SisautERP:pdv_items');
    if (cachedItems) {
      return [...JSON.parse(cachedItems)];
    }
    return [];
  });

  const [_cliente, _setCliente] = useState<ParceiroType | null>(() => {
    const cachedClient = localStorage.getItem('@SisautERP:pdv_cliente');
    if (cachedClient) {
      return JSON.parse(cachedClient);
    }
    return null;
  });

  const [_vendedor, _setVendedor] = useState<ParceiroType | null>(() => {
    const cachedVendedor = localStorage.getItem('@SisautERP:pdv_vendedor');
    if (cachedVendedor) {
      return JSON.parse(cachedVendedor);
    }
    return null;
  });

  const [_desconto, _setDesconto] = useState<number>(() => {
    const cachedDesconto = localStorage.getItem('@SisautERP:pdv_desconto');
    if (cachedDesconto) {
      return JSON.parse(cachedDesconto);
    }
    return null;
  });

  const [_empresa_id, _set_empresa_id] = useState<string | null>(() => {
    const cachedEmpresa = localStorage.getItem('@SisautERP:pdv_empresa');
    if (cachedEmpresa) {
      return JSON.parse(cachedEmpresa);
    }
    return null;
  });

  const [_natureza_operacao_id, _set_natureza_operacao_id] = useState<
    string | null
  >(() => {
    const cachedNaturezaOperacaoId = localStorage.getItem(
      '@SisautERP:pdv_natureza_operacao',
    );
    if (cachedNaturezaOperacaoId) {
      return JSON.parse(cachedNaturezaOperacaoId);
    }
    return null;
  });

  const [_tabela_preco_id, _set_tabela_preco_id] = useState<string | null>(
    () => {
      const cachedTabelaPrecoId = localStorage.getItem(
        '@SisautERP:pdv_tabela_preco',
      );
      if (cachedTabelaPrecoId) {
        return JSON.parse(cachedTabelaPrecoId);
      }
      return null;
    },
  );

  const [_conta_corrente_id, _set_conta_corrente_id] = useState<string | null>(
    () => {
      const cachedContaCorrenteId = localStorage.getItem(
        '@SisautERP:pdv_conta_corrente',
      );
      if (cachedContaCorrenteId) {
        return JSON.parse(cachedContaCorrenteId);
      }
      return null;
    },
  );

  const setCliente = (cliente: ParceiroType | null) => {
    _setCliente(cliente);
    localStorage.setItem('@SisautERP:pdv_cliente', JSON.stringify(cliente));
  };

  const setVendedor = (vendedor: ParceiroType | null) => {
    _setVendedor(vendedor);
    localStorage.setItem('@SisautERP:pdv_vendedor', JSON.stringify(vendedor));
  };

  const setDesconto = (desconto: number) => {
    _setDesconto(desconto);
    localStorage.setItem('@SisautERP:pdv_desconto', JSON.stringify(desconto));
  };

  const set_empresa_id = (empresa_id: string | null) => {
    _set_empresa_id(empresa_id);
    localStorage.setItem('@SisautERP:pdv_empresa', JSON.stringify(empresa_id));
  };

  const set_natureza_operacao_id = (natureza_operacao_id: string | null) => {
    _set_natureza_operacao_id(natureza_operacao_id);
    localStorage.setItem(
      '@SisautERP:pdv_natureza_operacao',
      JSON.stringify(natureza_operacao_id),
    );
  };

  const set_tabela_preco_id = (tabela_preco_id: string | null) => {
    _set_tabela_preco_id(tabela_preco_id);
    localStorage.setItem(
      '@SisautERP:pdv_tabela_preco',
      JSON.stringify(tabela_preco_id),
    );
  };

  const set_conta_corrente_id = (conta_corrente_id: string | null) => {
    _set_conta_corrente_id(conta_corrente_id);
    localStorage.setItem(
      '@SisautERP:pdv_conta_corrente',
      JSON.stringify(conta_corrente_id),
    );
  };

  const adicionarItem = useCallback(
    (item: CartItemType) => {
      if (item.qtd > 0) {
        let mergeItemId: null | string = null;
        let mergeQtd = 0;

        let count = 0;
        if (_items && _items.length) {
          for (count = 0; count < _items.length; count += 1) {
            const cItem = _items[count];
            if (
              cItem &&
              cItem.produto.id === item.produto.id &&
              cItem.preco === item.preco
            ) {
              mergeItemId = cItem.id;
              mergeQtd = cItem.qtd + item.qtd;
            }
          }
        }

        if (mergeItemId) {
          _setItems([
            ..._items.map(_item => ({
              ..._item,
              qtd: _item.id === mergeItemId ? mergeQtd : _item.qtd,
            })),
          ]);
        } else {
          _setItems([..._items.filter(_item => _item.id !== item.id), item]);
        }
      } else {
        _setItems([..._items.filter(_item => _item.id !== item.id)]);
      }
    },
    [_items],
  );

  const verQuantidadeItem = useCallback(
    (id: string) => {
      let qtd = 0;
      _items.forEach(_item => {
        if (_item.id === id) {
          qtd = _item.qtd;
        }
      });
      return qtd;
    },
    [_items],
  );

  const removerTodosItems = () => {
    _setItems([]);
  };

  const removerItem = useCallback(
    (id: string) => {
      _setItems([..._items.filter(_item => _item.id !== id)]);
    },
    [_items],
  );

  const alterarQuantidadeItem = (id: string, qtd: number) => {
    _setItems([
      ..._items.map(_item => ({
        ..._item,
        qtd: _item.id === id ? qtd : _item.qtd,
      })),
    ]);
  };

  const resetarCarrinho = () => {
    _setItems([]);
    setCliente(null);
    setVendedor(null);
    setDesconto(0);
  };

  const checarItemExiste = (id: string) => {
    return !!_items.filter(item => item.id === id).length;
  };

  const exibirPrecoItem = (item: CartItemType) => {
    return `R$ ${Number(item.preco).toFixed(2).replace('.', ',')}`;
  };

  const calcularPrecoItem = (item: CartItemType) => {
    return `R$ ${handlerNumberToString(item.qtd * item.preco)}`;
  };

  const getPrecoItems = () => {
    let totalItems = 0;
    _items.forEach(currentItem => {
      totalItems += currentItem.qtd * currentItem.preco;
    });
    return totalItems;
  };

  const calcularPrecoItems = () => {
    return getPrecoItems();
  };

  const calcularPrecoDesconto = () => {
    return _desconto || 0;
  };

  const calcularPrecoCarrinho = () => {
    let totalCarrinho = 0;
    totalCarrinho += getPrecoItems();
    totalCarrinho += -_desconto;
    return totalCarrinho;
  };

  const editarPedido = (pedido: PedidoType) => {
    resetarCarrinho();

    setTimeout(() => {
      // EMPRESA, NATUREZA, TABELA DE PREÇO
      set_empresa_id(`${pedido.empresa_id}`);
      set_natureza_operacao_id(`${pedido.natureza_operacao_id}`);
      set_tabela_preco_id(`${pedido.tabela_preco_id}`);

      // DESCONTO
      setDesconto(pedido.desconto);

      // CLIENTE, VENDEDOR
      setCliente(pedido.cliente);
      setVendedor(pedido.vendedor);

      // ITENS
      pedido.itens.forEach(item => {
        adicionarItem({
          id: `${Math.floor(Math.random())}`,
          qtd: item.quantidade,
          preco: item.preco,
          produto: {
            id: `${item.produto_id}`,
            codigo: '',
            descricao: item.produto_descricao,
            unidade_id: '',
            grade: false,
          },
        });
      });

      // FORMAS DE PAGAMENTO
      // ver com fabricio?
    }, 500);
  };

  useEffect(() => {
    localStorage.setItem('@SisautERP:pdv_items', JSON.stringify(_items));
  }, [_items]);

  const value = {
    items: _items,
    cliente: _cliente,
    vendedor: _vendedor,
    desconto: _desconto,
    empresa_id: _empresa_id,
    natureza_operacao_id: _natureza_operacao_id,
    tabela_preco_id: _tabela_preco_id,
    conta_corrente_id: _conta_corrente_id,

    adicionarItem,
    verQuantidadeItem,
    removerItem,
    alterarQuantidadeItem,
    removerTodosItems,
    resetarCarrinho,
    checarItemExiste,
    calcularPrecoItem,
    exibirPrecoItem,
    calcularPrecoItems,

    calcularPrecoDesconto,
    calcularPrecoCarrinho,

    setCliente,
    setVendedor,
    setDesconto,

    editarPedido,

    set_empresa_id,
    set_natureza_operacao_id,
    set_tabela_preco_id,
    set_conta_corrente_id,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export function useCart(): CartContextProps {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('hook usada fora de contexto do carrinho!');
  }
  return context;
}
