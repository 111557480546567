import React, { useRef, useCallback, useState, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { IoMdClose } from 'react-icons/io';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  Input,
  FindProduto,
  Loader,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  FiltroType,
  ProdutoType,
  SelectType,
  EmpresaType,
  VariacaoGradeType,
  GrupotagsType,
} from '~/types';

import { FiltrosProps, FormData } from './interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [findProdutoVisibilidade, setFindProdutoVisibilidade] = useState(false);

  const [produto, setProduto] = useState<ProdutoType | null>(null);
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const [tags, setTags] = useState<SelectType[]>([]);
  const [tagsSelecionadas, setTagsSelecionadas] = useState<string[]>([]);

  const [cores, setCores] = useState<SelectType[]>([]);
  const [tamanhos, setTamanhos] = useState<SelectType[]>([]);

  const atribuirProduto = async (selecionados: ProdutoType[]) => {
    if (selecionados.length > 0) {
      const itemSelecionado = selecionados[0];
      setProduto(itemSelecionado);
    }
    setFindProdutoVisibilidade(false);
  };

  const atribuirTags = useCallback((items: SelectType[]) => {
    if (items) {
      const cTatsSelecionadas = items.map((item: SelectType) => item.value);
      setTagsSelecionadas(cTatsSelecionadas);
    } else {
      setTagsSelecionadas([]);
    }
  }, []);

  const submeterFormulario = useCallback(
    (data: FormData) => {
      const filtros: FiltroType[] = [];
      const { empresa_id, cor_id, tamanho_id } = data;

      if (produto) {
        filtros.push({
          nome: 'produto_id',
          valor: produto.id,
        });
      }

      filtros.push({
        nome: 'empresa_id',
        valor: empresa_id,
      });

      if (tagsSelecionadas && tagsSelecionadas.length) {
        filtros.push({
          nome: 'tags',
          valor: tagsSelecionadas.join(','),
        });
      }

      filtros.push({
        nome: 'cor_id',
        valor: cor_id,
      });

      filtros.push({
        nome: 'tamanho_id',
        valor: tamanho_id,
      });

      aoAplicarFiltros(filtros);
    },
    [aoAplicarFiltros, produto, tagsSelecionadas],
  );

  useEffect(() => {
    const carregarEmpresas = async () => {
      setLoading(true);
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });

        setEmpresas(empresasFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setEmpresas([]);
      }
    };

    const carregarTags = async () => {
      try {
        const tagsFetch = await api.get('grupo-tags/?tipo=produto');
        const tagsFetched: Array<SelectType> = [];

        tagsFetch.data.forEach((item: GrupotagsType) => {
          item.tags.forEach(tag => {
            tagsFetched.push({
              value: tag.id,
              label: tag.nome,
              color: tag.cor,
            });
          });
        });

        setTags(tagsFetched);

        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarCores = async () => {
      try {
        const coresFetch = await api.get('produtos/variacao-grades?tipo=cor');
        const coresFetched: Array<SelectType> = [];

        coresFetch.data.forEach((item: VariacaoGradeType) => {
          coresFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });

        setCores(coresFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const carregarTamanhos = async () => {
      try {
        const tamanhosFetch = await api.get(
          'produtos/variacao-grades?tipo=tamanho',
        );
        const tamanhosFetched: Array<SelectType> = [];

        tamanhosFetch.data.forEach((item: VariacaoGradeType) => {
          tamanhosFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });

        setTamanhos(tamanhosFetched);

        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    carregarEmpresas();
    carregarCores();
    carregarTamanhos();
    carregarTags();
  }, []);

  if (loading) return <Loader visible />;

  return (
    <Container>
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            produto_id: '',
            empresa_id: '',
            tags: '',
            cor_id: '',
            tamanho_id: '',
          }}
        >
          <InputContainer>
            <span>Produto</span>
            <div>
              <Input
                value={produto ? produto.descricao : ''}
                name="produto_id"
                placeholder="Produto"
                disabled
              />

              {produto ? (
                <div style={{ marginLeft: 15 }}>
                  <IoMdClose
                    size={30}
                    color={colors.white}
                    onClick={() => setProduto(null)}
                  />
                </div>
              ) : null}

              {!produto ? (
                <FindProduto
                  openned={findProdutoVisibilidade}
                  onlySearch
                  onSelectValue={atribuirProduto}
                />
              ) : null}
            </div>
          </InputContainer>

          <InputContainer>
            <span>Empresa</span>
            <div>
              <Select name="empresa_id" loading={loading} options={empresas} />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tags</span>
            <div>
              <Select
                name="tags"
                loading={loading}
                isMulti
                options={tags}
                onSelectedOption={atribuirTags}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Cor</span>
            <div>
              <Select name="cor_id" loading={loading} options={cores} />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tamanho</span>
            <div>
              <Select name="tamanho_id" loading={loading} options={tamanhos} />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button
                style={{ width: 150 }}
                type="submit"
                background={colors.green}
              >
                Filtrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
