import styled, { keyframes, css } from 'styled-components';
import { AnimatedContainer } from '~/components';

import { PartnerTypeProps, PartnerTagProps } from './interface';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: inherit;
    height: auto;
    min-height: auto;
    padding: 0rem;
  }
`;

export const Types = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background: ${theme.colors.controlNotFocus};
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    height: 4.7rem;
    border-radius: 0.6rem;
    padding: 0rem 1.5rem;
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
  `}
`;

export const Type = styled.span<PartnerTypeProps>`
  ${({ theme, $bg }) => css`
    height: 2.5rem;
    padding: 0 1rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${theme.fontSizes.small};
    transition: all 0.2s ease;
    background: ${$bg};
    border: 0.1rem solid ${theme.colors.defaultHighlight};

    &:hover {
      border: 0.1rem solid ${theme.colors.default};
      transform: scale(1.1);
    }
  `}
`;

export const Addresses = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    overflow: hidden;
    margin-bottom: -2rem;

    table {
      width: 100%;
      float: left;
      border-spacing: 0rem;
      border-collapse: separate;
      margin: 0rem 0rem 0rem 0;

      thead {
        td {
          background: ${theme.colors.primaryDark};
          font-weight: 700;
          font-size: ${theme.fontSizes.small};
          padding: 1.5rem;
        }
      }

      td {
        border-right: 0.1rem solid ${theme.colors.secondary};
        border-bottom: 0.1rem solid ${theme.colors.secondary};
        padding: 1.5rem;
        font-size: ${theme.fontSizes.default};
        transition: all 0.2s ease;

        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          opacity: 0;
          transition: all 0.4s ease;

          &:hover {
            button {
              opacity: 0.3;
            }
          }

          button {
            background: none;
            cursor: pointer;
            width: 3.6rem;
            height: 3.6rem;
            background: ${theme.colors.background};
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;

            &:hover {
              opacity: 1 !important;
              background: ${theme.colors.secondary};
              transform: rotate(360deg);
            }
          }
        }
      }

      tbody td:first-child {
        border-left: 0.5rem solid ${theme.colors.defaultHighlight};
      }

      tbody tr:nth-child(odd) td {
        background: ${theme.colors.primary};
      }

      tbody tr:last-child td {
        border-bottom: none;
      }

      td:last-child {
        border-right: none;
      }

      tbody tr:hover {
        td {
          background: rgba(247, 155, 62, 0.98);
          color: ${theme.colors.label};
        }

        td:first-child {
          border-left: 0.5rem solid #f5f;
        }

        div {
          opacity: 1 !important;
        }
      }
    }
  `}
`;
const LdAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LdAnim = styled.span`
  overflow: hidden;
  float: left;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  svg {
    animation: ${LdAnimation} 2s infinite;
  }
`;

export const TagsContent = styled.div`
  ${({ theme }) => css`
    min-height: 12rem;
    float: left;
    border: solid 0.1rem ${theme.colors.default};
    padding: 1.5rem;
    border-radius: 10px;
    background: ${theme.colors.secondary};
    margin: 1.5rem;
    h4 {
      float: left;
      font-size: 1.6rem;
      color: ${theme.colors.placeholder};
      margin: 0;
      font-weight: 500;
      opacity: 0.6;
    }
  `}
`;

export const Tag = styled.div<PartnerTagProps>`
  ${({ theme, $cor }) => css`
    float: left;
    border: 0.1rem solid ${theme.colors.contrast};
    color: ${theme.colors.white};
    padding: 0.8rem;
    text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    background: ${$cor};
    border-radius: ${theme.radio.default};
    font-size: 1.4rem;
    font-weight: 700;
    cursor: move;
    text-decoration: none;
    outline: none;
    min-width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      button {
        opacity: 1;
      }
    }

    button {
      opacity: 0;
      cursor: pointer;
      background: none;
      transition: all 0.5s ease;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;
