import styled, { css } from 'styled-components';

import { TitleProps } from './interface';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    flex-direction: column;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  `}
`;

export const Title = styled.div<TitleProps>`
  ${({ theme, $active }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    font-size: ${theme.fontSizes.default};

    /* text-decoration: ${$active ? 'underline' : 'none'}; */

    /* &:hover {
      text-decoration: underline;
    } */

    span {
      margin: 0 1rem;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    flex-direction: column;
    padding: 1rem;
  `}
`;
