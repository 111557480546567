import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import Header from '~/components/Header';

import { Container, Content, Main } from './styles';

import { ParamsProps } from './interface';

import Tipo from './Tipo';
import BarraLateral from './BarraLateral';
import Preview from './Prewiew';

const CompraManual: React.FC = () => {
  const { tipo } = useParams<ParamsProps>();

  const [tabelabilidade, setTabelabilidade] = useState(true);
  const [verticalidade, setVerticalidade] = useState(false);

  const controleDeVerticalidade = () => {
    setVerticalidade(!verticalidade);
  };

  const controleDeTabelabilidade = () => {
    setTabelabilidade(!tabelabilidade);
  };
  return (
    <Container>
      <Header />
      <Content>
        <Tipo
          tipo={tipo}
          tabelabilidade={tabelabilidade}
          controleDeTabelabilidade={controleDeTabelabilidade}
        />
        <Main $verticalidade={verticalidade}>
          <BarraLateral
            tipo={tipo}
            verticalidade={verticalidade}
            controleDeVerticalidade={controleDeVerticalidade}
          />
          <Preview
            tabelabilidade={tabelabilidade}
            cabecalhos={[
              {
                identificador: 'campo1',
                label: 'Campo 1',
              },
              {
                identificador: 'campo2',
                label: 'Campo 2',
              },
              {
                identificador: 'campo3',
                label: 'Campo 3',
              },
              {
                identificador: 'campo4',
                label: 'Campo 4',
              },
            ]}
            linhas={[
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Prop 1' },
                { propriedade: 'campo2', valor: 'Prop 2' },
                { propriedade: 'campo3', valor: 'Prop 3' },
                { propriedade: 'campo4', valor: 'Prop 4' },
              ],
              [
                { propriedade: 'campo1', valor: 'Total: 100,00' },
                { propriedade: 'campo2', valor: 'Total: 200,00' },
                { propriedade: 'campo3', valor: 'Total: 300,00' },
                { propriedade: 'campo4', valor: 'Total: 400,00' },
              ],
            ]}
            totalizadores={[
              { propriedade: 'Totalizador 1', valor: 'R$ 9.9999,00' },
              { propriedade: 'Totalizador 2', valor: 'R$ 9.9999,00' },
              { propriedade: 'Totalizador 3', valor: 'R$ 9.9999,00' },
              { propriedade: 'Totalizador 4', valor: 'R$ 9.9999,00' },
            ]}
          />
        </Main>
      </Content>
    </Container>
  );
};

export default CompraManual;
