import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FcExpired } from 'react-icons/fc';

import { Container, IC, Content, Message, SubMessage } from './styles';

import { Button } from '~/components';

import { NoPermissionProps } from './interface';

const NoPermission: React.FC<NoPermissionProps> = () => {
  const history = useNavigate();

  const voltar = () => {
    history(-1);
  };

  return (
    <Container>
      <Content>
        <IC>
          <FcExpired size={200} color="#f5f5f5" />
        </IC>
        <Message>Você não tem permissão para acessar essa função!</Message>
        <SubMessage>Solicite ao administrador caso necessário.</SubMessage>
        <div>
          <Button onClick={voltar}>OK</Button>
        </div>
      </Content>
    </Container>
  );
};

export default NoPermission;
