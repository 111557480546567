import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '~/services/api';

import { Select } from '~/components';

import { Container, Line } from './styles';

import {
  SelectType,
  EmpresaType,
  TabelaPrecoType,
  NaturezaOperacaoType,
} from '~/types';

import { useCart } from '~/hooks/cart';

const Empresa: React.FC = () => {
  const {
    set_empresa_id,
    set_natureza_operacao_id,
    set_tabela_preco_id,
    empresa_id,
    natureza_operacao_id,
    tabela_preco_id,
    items,
  } = useCart();

  const [loading, setLoading] = useState(true);

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [naturezas, setNaturezas] = useState<SelectType[]>([]);
  const [tabelas, setTabelas] = useState<SelectType[]>([]);

  const formRef = useRef<FormHandles>(null);

  const loadEmpresas = async () => {
    try {
      const dataFetch = await api.get('/empresas');
      const dataFetched = [] as SelectType[];

      dataFetch.data.forEach((item: EmpresaType, key: number) => {
        if (key === 0 && !empresa_id) {
          set_empresa_id(item.id);
        }
        if (item.ativo === true) {
          dataFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        }
      });

      setEmpresas(dataFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const loadNaturezasOperacao = async () => {
    try {
      const dataFetch = await api.get('/pedido/natureza-operacao');
      const dataFetched = [] as SelectType[];

      dataFetch.data.forEach((item: NaturezaOperacaoType, key: number) => {
        if (key === 0 && !natureza_operacao_id) {
          set_natureza_operacao_id(item.id);
        }
        if (item.ativo === true) {
          dataFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        }
      });

      setNaturezas(dataFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleSubmit = async () => {
    return null;
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        await loadEmpresas();
        await loadNaturezasOperacao();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadTabelasPreco = async () => {
      try {
        const dataFetch = await api.get(
          `/tabelas-preco?empresa_id=${empresas[0].value}`,
        );
        const dataFetched = [] as SelectType[];

        dataFetch.data.forEach((item: TabelaPrecoType, key: number) => {
          if (key === 0 && !tabela_preco_id) {
            set_tabela_preco_id(item.id);
          }
          if (item.ativo === true) {
            dataFetched.push({
              value: item.id,
              label: item.descricao,
              color: '',
            });
          }
        });

        setTabelas(dataFetched);
        setLoading(false);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    loadTabelasPreco();
  }, [empresas, set_tabela_preco_id, tabela_preco_id]);

  if (loading) {
    return <div />;
  }

  return (
    <Form
      ref={formRef}
      initialData={{
        empresa: empresas.length > 0 ? empresas[0] : '',
        natureza_operacao: naturezas.length > 0 ? naturezas[0] : '',
        tabela_preco: tabelas.length > 0 ? tabelas[0] : '',
      }}
      onSubmit={handleSubmit}
    >
      <Container>
        <Line>
          <Select
            name="empresa"
            placeholder="EMPRESA"
            options={empresas}
            loading={false}
            onSelectedOption={item => set_empresa_id(item.value)}
            isDisabled={!!items.length}
            tooltip
          />
        </Line>
        <Line>
          <Select
            name="natureza_operacao"
            placeholder="NATUREZA DE OPERAÇÃO"
            options={naturezas}
            loading={false}
            onSelectedOption={item => set_natureza_operacao_id(item.value)}
            isDisabled={!!items.length}
            tooltip
          />
          <Select
            name="tabela_preco"
            placeholder="TABELA DE PREÇO"
            options={tabelas}
            loading={false}
            onSelectedOption={item => set_tabela_preco_id(item.value)}
            isDisabled={!!items.length}
            tooltip
          />
        </Line>
      </Container>
    </Form>
  );
};

export default Empresa;
