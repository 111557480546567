import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { BiSlider } from 'react-icons/bi';
import { MdAttachMoney } from 'react-icons/md';
import { formatTheDateYall } from '~/utils/formatDate';

import { getValidationErrors } from '~/utils';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import Loader from '~/components/Loader';

import {
  FormContainer,
  FormContent,
  FormBlock,
  InputsContainer,
  InputContainer,
  FormFooter,
  Input,
  Button,
  Switch,
  Select,
  DatePicker,
  ErrorMessage,
  Modal,
} from '~/components';

import {
  Content,
  InputsDivisor,
  IfCost,
  IFCostShow,
  InputCurrency,
  InputPercent,
} from './styles';

import { handlerToNumber, handlerNumberToString } from '~/utils/money';

import InformacaoDeCusto from './InformacaoDeCusto';

import {
  UnidadeType,
  SelectType,
  EmpresaType,
  CustoType,
  VariacaoProdutoType,
  InformacaoComercialType,
  PrecoProdutoType,
} from '~/types';

import { ParamsProps } from './interface';
import { handlerErrors } from '~/utils/error';

const IFC: React.FC<ParamsProps> = ({
  id_produto,
  id,
  produto_grade,
  onRequestClose,
  onSave,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(true);
  const [retroLoad, setRetroLoad] = useState(true);
  const [isPromotion, setIsPromotion] = useState(false);
  const [registerData, setRegisterData] = useState({ ativo: true });

  const [ifCostVisible, setIfCostVisible] = useState(false);

  const [IFCId, setIFCId] = useState('0');
  const [empresaId, setEmpresaId] = useState('0');

  const [unidades, setUnidades] = useState<SelectType[]>([]);
  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [tabelasdepreco, setTabelasdepreco] = useState<SelectType[]>([]);
  const [custo, setCusto] = useState<CustoType | null>(null);
  const [variacoes, setVariacoes] = useState<SelectType[]>([]);

  const [lucro, setLucro] = useState('');
  const [preco, setPreco] = useState('');
  const [preco_promocao, setPrecoPromocional] = useState('');

  const [formError, setFormError] = useState('');

  const formRef = useRef<FormHandles>(null);

  const handlerCheckPromotionIsValid = (
    dtinicial: string,
    dtfinal: string,
  ): boolean => {
    if (dtinicial && dtfinal) {
      // const currentDtInicial = moment(dtinicial).format('X');
      // const currentDtFinal = moment(dtfinal).format('X');
      // const currentDtNow = moment().format('X');
      // if (currentDtNow >= currentDtInicial && currentDtNow <= currentDtFinal) {
      //   return true;
      // }
      const currentDtFinal = format(new Date(dtfinal), 'dd/MM/yyyy');
      const currentDtNow = format(new Date(), 'dd/MM/yyyy');
      if (currentDtNow <= currentDtFinal) {
        return true;
      }
    }
    return false;
  };

  const handlerCalcFinalPrice = (cr: CustoType): number => {
    let priceFinal = 0;
    priceFinal += cr.custo_unitario;
    priceFinal += cr.ipi_valor;
    priceFinal += cr.st_valor;
    priceFinal += cr.frete_valor;
    priceFinal += cr.seguro_valor;
    priceFinal += cr.outros_valor;
    priceFinal += cr.despesas_valor;
    return priceFinal;
  };

  const handlerProfit = (value: string | undefined): void => {
    setLucro(value || '0');
    if (value && custo) {
      setPreco(
        handlerNumberToString(
          handlerCalcFinalPrice(custo) +
            handlerCalcFinalPrice(custo) * (handlerToNumber(value) / 100),
        ),
      );
    }
  };

  const handlerPrice = (value: string | undefined): void => {
    setPreco(value || '');
    if (value && custo) {
      setLucro(
        handlerNumberToString(
          (100 -
            (handlerToNumber(value) * 100) / handlerCalcFinalPrice(custo)) *
            -1,
        ),
      );
    }
  };

  const handlerPricePromotion = (value: string | undefined): void => {
    setPrecoPromocional(value || '');
  };

  const toogleIFCost = () => {
    setIfCostVisible(!ifCostVisible);
  };

  const handlerSubmitForm = () => {
    formRef.current?.submitForm();
  };

  const handlerOnChangeEPRM = (checked: boolean): void => {
    setIsPromotion(checked);
  };

  const onHandlerEmpresaChange = (item: SelectType) => {
    if (!item) return false;
    const empresa_id = item.value;
    setCusto(null);
    setEmpresaId(empresa_id);
  };

  const loadRegister = async () => {
    try {
      setIFCId(id);
      if (id !== '0' && id_produto.length > 1) {
        setRetroLoad(true);
        const response = await api.get(`produtos/informacoes/${id}`);

        const dataFetched = response.data;

        const inPromocao = handlerCheckPromotionIsValid(
          dataFetched.promocao_inicial,
          dataFetched.promocao_final,
        );

        setIsPromotion(inPromocao);

        setRegisterData({
          ...dataFetched,
          tabela_preco_id: {
            value: dataFetched.tabela_preco_id,
            label: dataFetched.tabela_preco.descricao,
          },
          unidade_id: {
            value: dataFetched.unidade_id,
            label: dataFetched.unidade.sigla,
          },
          empresa_id: {
            value: dataFetched.empresa_id,
            label: dataFetched.empresa.fantasia,
          },
          promocao_inicial: formatTheDateYall(dataFetched.promocao_inicial),
          promocao_final: formatTheDateYall(dataFetched.promocao_final),
          epromocao: inPromocao,
          variacao_produto_id: {
            value: dataFetched.variacao_produto_id,
            label: `${dataFetched.variacao_produto?.tamanho.descricao} - ${dataFetched.variacao_produto?.cor.descricao}`,
            color: '',
          },
        });

        setEmpresaId(dataFetched.empresa_id);
        setPreco(handlerNumberToString(dataFetched.preco));
        setPrecoPromocional(handlerNumberToString(dataFetched.preco_promocao));

        setTimeout(() => setRetroLoad(false), 300);
      } else {
        setRetroLoad(false);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const loadUnidades = useCallback(async () => {
    // return new Promise(async (resolve, reject) => {
    try {
      const unidadesFetch = await api.get('produtos/unidades');
      const unidadesFetched = [] as SelectType[];

      unidadesFetch.data.forEach((item: UnidadeType) => {
        if (item.ativo === true) {
          unidadesFetched.push({
            value: item.id,
            label: item.sigla,
            color: '',
          });
        }
      });
      setUnidades(unidadesFetched);
      // resolve(true);
    } catch (e) {
      // reject(e);
    }
    // });
  }, []);

  const loadEmpresas = useCallback(async () => {
    try {
      const empresasFetch = await api.get('empresas');
      const empresasFetched = [] as SelectType[];

      empresasFetch.data.forEach((item: EmpresaType) => {
        if (item.ativo === true) {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        }
      });
      setEmpresas(empresasFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const loadTabelasDePreco = useCallback(async () => {
    try {
      if (!empresaId) return;

      const tabelasdeprecosFetch = await api.get(
        `tabelas-preco?empresa_id=${empresaId}`,
      );

      const tabelasdeprecosFetched = [] as SelectType[];

      tabelasdeprecosFetch.data.forEach((itemTP: PrecoProdutoType) => {
        if (itemTP.ativo === true) {
          tabelasdeprecosFetched.push({
            value: itemTP.id,
            label: itemTP.descricao,
            color: '',
          });
        }
      });

      setTabelasdepreco(tabelasdeprecosFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [empresaId]);

  const loadCustos = useCallback(async () => {
    try {
      const response = await api.get(`/produtos/${empresaId}/custos`);
      setCusto(response.data[0]);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [empresaId]);

  const loadVariacoes = useCallback(async () => {
    try {
      const variacoesFetch = await api.get(`/produtos/${id_produto}/variacoes`);

      const variacoesFetched = [] as SelectType[];

      variacoesFetch.data.forEach((item: VariacaoProdutoType) => {
        variacoesFetched.push({
          value: item.id,
          label: `${item.tamanho.descricao} - ${item.cor.descricao}`,
          color: '',
        });
      });

      setVariacoes(variacoesFetched);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [id_produto]);

  const handlerOnSaveIFCost = async () => {
    toogleIFCost();
    await loadCustos();
  };

  const handleSubmit = useCallback(
    async (data: InformacaoComercialType) => {
      try {
        setFormError('');
        formRef.current?.setErrors({});

        Object.assign(data, {
          isGrade: produto_grade,
        });

        const schema = Yup.object().shape({
          unidade_id: Yup.string().required(
            'Informe a unidade para esta informação comercial deste produto.',
          ),
          tabela_preco_id: Yup.string().required(
            'Informe o tabela de preço para esta informação comercial deste produto.',
          ),
          embalagem: Yup.string().required(
            'Informe a quantidade de itens que contém na embalagem',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        setInLoad(true);

        const ifcData = {
          ativo: data.ativo,
          codigo_barra: data.codigo_barra,
          embalagem: data.embalagem,
          tabela_preco_id: data.tabela_preco_id,
          promocao_final:
            data.promocao_final === undefined || !isPromotion
              ? null
              : data.promocao_final,
          promocao_inicial:
            data.promocao_inicial === undefined || !isPromotion
              ? null
              : data.promocao_inicial,
          unidade_id: data.unidade_id,
          empresa_id: empresaId,
          preco: handlerToNumber(preco),
          preco_promocao: !isPromotion ? 0 : handlerToNumber(preco_promocao),
          variacao_produto_id: data.variacao_produto_id || null,
        };

        try {
          const response = await api[IFCId !== '0' ? 'put' : 'post'](
            `produtos/${id_produto}/informacoes${
              IFCId !== '0' ? `/${IFCId}` : ''
            }`,

            ifcData,
          );

          setIFCId(response.data.id);

          setInLoad(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });

          onSave();
        } catch (e) {
          setInLoad(false);
          const message = handlerErrors(e);
          setFormError(`${message}`);
        }
      } catch (err) {
        setInLoad(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [
      addToast,
      setFormError,
      id_produto,
      produto_grade,
      IFCId,
      preco,
      preco_promocao,
      empresaId,
      isPromotion,
      onSave,
    ],
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        await loadUnidades();
        await loadEmpresas();
        await loadVariacoes();
        await loadRegister();
        // eslint-disable-next-line no-empty
      } catch (e) {}
      setInLoad(false);
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preco && custo) {
      handlerPrice(preco);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custo]);

  useEffect(() => {
    if (empresaId !== '0') {
      const refetch = async () => {
        await loadCustos();
        await loadTabelasDePreco();
      };
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  if (retroLoad || inLoad) {
    return <Loader />;
  }

  return (
    <Content>
      <FormContainer>
        <FormContent>
          <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

          <FormBlock>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Empresa</span>
                  <div>
                    <Select
                      name="empresa_id"
                      loading={inLoad}
                      options={empresas}
                      onSelectedOption={onHandlerEmpresaChange}
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsDivisor>
                <span />
              </InputsDivisor>

              <IfCost $disabled={empresaId.length <= 1}>
                {ifCostVisible ? (
                  <Modal
                    title="Informação de custo"
                    visible
                    onRequestClose={() => toogleIFCost()}
                    width="120rem"
                    scrollable
                  >
                    <InformacaoDeCusto
                      onSave={handlerOnSaveIFCost}
                      id_empresa={empresaId}
                      id_produto={id_produto}
                    />
                  </Modal>
                ) : (
                  <IFCostShow>
                    <div>
                      <MdAttachMoney size={32} color="#fff" />
                      {custo ? (
                        <p>
                          <small>Custo final</small>
                          <strong>
                            {`R$ ${handlerNumberToString(
                              handlerCalcFinalPrice(custo),
                            ).replace('.', ',')}`}
                          </strong>
                        </p>
                      ) : (
                        <p>
                          Nenhum custo configurado para este
                          <br />
                          produto na empresa selecionada.
                        </p>
                      )}
                    </div>
                    <button type="button" onClick={toogleIFCost}>
                      <BiSlider size={22} color="#fff" />
                    </button>
                  </IFCostShow>
                )}
              </IfCost>

              <InputsDivisor>
                <span />
              </InputsDivisor>
              {produto_grade ? (
                <>
                  <InputsContainer>
                    <InputContainer disabled={inLoad}>
                      <span>Variação de Grade (Tamanho/Cor)</span>
                      <div>
                        <Select
                          name="variacao_produto_id"
                          loading={inLoad}
                          options={variacoes}
                        />
                      </div>
                    </InputContainer>
                  </InputsContainer>
                </>
              ) : null}
              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Tabela de Preço</span>
                  <div>
                    {tabelasdepreco.length ? (
                      <Select
                        name="tabela_preco_id"
                        loading={inLoad}
                        options={tabelasdepreco}
                      />
                    ) : (
                      <span>Selecione uma empresa primeiramente</span>
                    )}
                  </div>
                </InputContainer>

                <InputContainer disabled={inLoad}>
                  <span>Unidade</span>
                  <div>
                    <Select
                      name="unidade_id"
                      loading={inLoad}
                      options={unidades}
                    />
                  </div>
                </InputContainer>

                <InputContainer disabled={inLoad}>
                  <span>Lucro</span>
                  <div>
                    <InputPercent
                      onValueChange={handlerProfit}
                      value={lucro}
                      placeholder="0,00"
                    />
                  </div>
                </InputContainer>

                <InputContainer disabled={inLoad}>
                  <span>Preço</span>
                  <div>
                    <InputCurrency
                      onValueChange={handlerPrice}
                      value={preco}
                      placeholder="0,00"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Código de barras</span>
                  <div>
                    <Input
                      name="codigo_barra"
                      placeholder="Código de barras"
                      required
                    />
                  </div>
                </InputContainer>
                <InputContainer disabled={inLoad}>
                  <span>Qtde de itens por embalagem</span>
                  <div>
                    <Input
                      name="embalagem"
                      placeholder="Ex. 1, 12"
                      required
                      maxLength={3}
                    />
                  </div>
                </InputContainer>

                <InputContainer disabled={inLoad}>
                  <span>Ativo?</span>
                  <div className="switch">
                    <Switch name="ativo" />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsDivisor>
                <span />
              </InputsDivisor>

              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Promoção?</span>
                  <div className="switch">
                    <Switch name="epromocao" onChange={handlerOnChangeEPRM} />
                  </div>
                </InputContainer>
              </InputsContainer>
              {isPromotion ? (
                <InputsContainer>
                  <InputContainer disabled={inLoad}>
                    <span>Data Inicial</span>
                    <div>
                      <DatePicker
                        name="promocao_inicial"
                        mask="99/99/9999"
                        placeholder="Informe a Data Inicial"
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={inLoad}>
                    <span>Data Final</span>
                    <div>
                      <DatePicker
                        name="promocao_final"
                        mask="99/99/9999"
                        placeholder="Informe a Data Final"
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={inLoad}>
                    <span>Preço Promocional</span>
                    <div>
                      <InputCurrency
                        onValueChange={handlerPricePromotion}
                        value={preco_promocao}
                        placeholder="Preço Promocional"
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>
              ) : null}
            </Form>
          </FormBlock>

          <FormFooter>
            <Button
              type="button"
              background={colors.grey}
              onClick={onRequestClose}
            >
              Cancelar
            </Button>

            <Button
              type="button"
              onClick={handlerSubmitForm}
              background={colors.green}
              loading={inLoad}
            >
              Salvar
            </Button>
          </FormFooter>
        </FormContent>
      </FormContainer>
    </Content>
  );
};

export default IFC;
