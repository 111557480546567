import React, { useRef, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '~/services/api';

import { Switch, Button } from '~/components';

import Loader from '~/components/Loader';

import { Container, Content, Title, Footer } from './styles';

import { useToast } from '~/hooks/toast';

import ACLPerfilAdd from './ACLPerfilAdd';

import { ACLPerfilsProps } from './interface';

import { PapelType } from '~/types';

const ACLPerfils: React.FC<ACLPerfilsProps> = ({ permission, active }) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [aclPerfilAddVisible, setAclPerfilAddVisible] = useState(false);
  const [papeis, setPapeis] = useState<Array<PapelType>>([]);
  const [selectedId, setSelectedId] = useState('');

  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (selectedId.length > 0) {
      setAclPerfilAddVisible(true);
    }
  }, [selectedId]);

  const fetchPerfils = () => {
    return new Promise((resolve, reject) => {
      api
        .get('acl/papeis')
        .then(response => {
          const { data } = response;
          if (data && data.status !== 'error') {
            setPapeis(data);
          }
          resolve(true);
        })
        .catch(error => reject(error));
    });
  };

  const handlerCloseAclPerfilAdd = () => {
    setSelectedId('');
    setAclPerfilAddVisible(false);
  };

  const handlerOpenAclPerfilAdd = (id: string) => {
    setSelectedId(id);
    setAclPerfilAddVisible(true);
  };

  const handlerOnSaveAclPerfilAdd = async () => {
    fetchPerfils();
  };

  const checkIsEnabled = (papel: any): boolean => {
    return !!papel.permissoes.filter(
      (permissao: any) => permissao.id === permission.id,
    ).length;
  };

  const handleSubmit = async (data: any) => {
    setFormLoading(true);

    try {
      const response_acl_saved = await api.get('acl');
      const { data: data_acl_saved } = response_acl_saved;

      if (!data_acl_saved || data_acl_saved.status === 'error') {
        throw new Error('Falha ao carregar estado anterior do ACL');
      }

      await fetchPerfils();

      const body = {
        papeis: [
          ...papeis.map((papel: any) => {
            return {
              id: papel.id,
              permissoes: data[papel.slug]
                ? [
                    ...papel.permissoes.reduce(
                      (acc: Array<any>, permissao: any) => {
                        if (permissao.id !== permission.id) {
                          acc.push(permissao.id);
                        }
                        return acc;
                      },
                      [],
                    ),
                    permission.id,
                  ]
                : [
                    ...papel.permissoes.reduce(
                      (acc: Array<any>, permissao: any) => {
                        if (permissao.id !== permission.id) {
                          acc.push(permissao.id);
                        }
                        return acc;
                      },
                      [],
                    ),
                  ],
            };
          }),
        ],
      };

      const response_acl_update = await api.put('acl', body);
      const { data: data_acl_update } = response_acl_update;
      if (!data_acl_update || data_acl_update.status === 'error') {
        throw new Error(
          `${
            data_acl_update.message
              ? data_acl_update.message
              : 'Falha ao salvar'
          }`,
        );
      }

      setFormLoading(false);
      addToast({
        type: 'success',
        title: `Papéis da permissão "${permission.nome}" atualizado com sucesso!`,
        description: '',
      });
    } catch (e) {
      setFormLoading(false);
      addToast({
        type: 'error',
        title: `${(e as Error).message}`,
        description: '',
      });
    }
  };

  const currentInitialData: any = {};
  papeis.forEach((papel: any) => {
    currentInitialData[papel.slug] = checkIsEnabled(papel);
  });

  useEffect(() => {
    const initialize = async () => {
      await fetchPerfils();
    };
    initialize();
  }, [active]);

  if (!active) return null;

  return (
    <Container $visible={active}>
      <ACLPerfilAdd
        onRequestClose={handlerCloseAclPerfilAdd}
        onSave={handlerOnSaveAclPerfilAdd}
        selectedId={selectedId}
        visible={aclPerfilAddVisible}
      />

      <Form
        ref={formRef}
        initialData={currentInitialData}
        onSubmit={handleSubmit}
      >
        <Loader visible={formLoading} />
        <Content>
          {papeis.map(papel => (
            <Title key={papel.id}>
              <button
                type="button"
                onClick={() => handlerOpenAclPerfilAdd(papel.id)}
              >
                {papel.nome}
              </button>
              <Switch name={papel.slug} defaultValue={checkIsEnabled(papel)} />
            </Title>
          ))}
        </Content>
        {papeis.length ? (
          <Footer>
            <div>
              <Button
                onClick={() => handlerOpenAclPerfilAdd('')}
                type="button"
                background={colors.darkOrange}
              >
                Novo Perfil
              </Button>
              <Button type="submit" background={colors.green}>
                Salvar
              </Button>
            </div>
          </Footer>
        ) : null}
      </Form>
    </Container>
  );
};

export default ACLPerfils;
