import styled, { css } from 'styled-components';

import { ListItemContainerProps } from './interface';

export const ListItemContainer = styled.td<ListItemContainerProps>`
  ${({ theme, $width, $headertype, $cor }) => css`
    border-right: 0.1rem solid ${theme.colors.default};
    border-bottom: 0.1rem solid ${theme.colors.default};

    padding: 0rem 0.5rem;
    font-size: ${theme.fontSizes.default};
    text-transform: uppercase;
    color: ${$cor ? `${$cor} !important` : theme.colors.label};
    $width: ${`${$width}px !important` || 'auto'};
    height: 4rem;
    ${$headertype &&
    css`
       {
        padding-left: 1rem !important;
      }
    `};

    main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0rem;
      padding: 0rem;
      overflow: hidden;
    }

    span {
      font-size: ${theme.fontSizes.default};
    }
  `}
`;
