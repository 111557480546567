import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import { Container, Content, Title } from './styles';

import { StickerProps } from './interface';

const Sticker: React.FC<StickerProps> = ({ title, children }) => {
  const { colors } = useTheme();

  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <Container>
      <Title>
        <span>{title}</span>
        <button type="button" onClick={toggleVisible}>
          {visible ? (
            <IoIosArrowUp size={22} color={colors.label} />
          ) : (
            <IoIosArrowDown size={22} color={colors.label} />
          )}
        </button>
      </Title>
      {visible ? <Content>{children}</Content> : null}
    </Container>
  );
};

export default Sticker;
