import React, { useCallback, useEffect, useState } from 'react';

import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';

import { Link, useParams } from 'react-router-dom';

import { useTheme } from 'styled-components';

import {
  Block,
  Container,
  Content,
  ContentEmpty,
  Footer,
  Grid,
  Info,
  Infos,
  Item,
  Label,
  Left,
  LeftInfo,
  Line,
  Opt,
  Opts,
  Prop,
  Val,
} from './styles';

import Header from '~/components/Header';

import { Button, Loader } from '~/components';

import api from '~/services/api';

import { handlerNumberToString } from '~/utils/money';

import { DadosCaixaProps, ParamsProps } from './interface';

import { ContaCorrenteType } from '~/types';
import { formatCustomBR } from '~/utils/formatDate';

import FechamentoDeCaixa from './FechamentoDeCaixa';
import LancamentoDeValor from './LancamentoDeValor';

const Caixa: React.FC = () => {
  const { colors } = useTheme();
  const { id } = useParams<ParamsProps>();

  const [loading, setLoading] = useState(true);

  const [contaCorrente, setContaCorrente] = useState<ContaCorrenteType | null>(
    null,
  );
  const [dadosCaixa, setDadosCaixa] = useState<DadosCaixaProps | null>(null);

  const [lancamentoDeValorModalVisible, setLancamentoDeValorModalVisible] =
    useState(false);

  const [fechamentoDeCaixaModalVisible, setFechamentoDeCaixaModalVisible] =
    useState(false);

  const fetchDadosCaixa = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/financeiros/dados-caixas/${id}`);
      const { data } = response;
      if (data && data.status !== 'error') {
        setDadosCaixa(data);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchContaCorrente = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`financeiros/contas-correntes/${id}`);
      const { data } = response;
      if (data && data.status !== 'error') {
        setContaCorrente(data);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handlerFecharCaixaModalClose = useCallback(() => {
    setFechamentoDeCaixaModalVisible(false);
  }, []);

  const handlerFecharCaixaModalOpen = useCallback(() => {
    setFechamentoDeCaixaModalVisible(true);
  }, []);

  const handlerLancamentoDeValorModalClose = useCallback(() => {
    setLancamentoDeValorModalVisible(false);
  }, []);

  const handlerLancamentoDeValorModalOpen = useCallback(() => {
    setLancamentoDeValorModalVisible(true);
  }, []);

  const handlerLancamentoDeValorModalOnSave = useCallback(() => {
    handlerLancamentoDeValorModalClose();
    fetchDadosCaixa();
  }, [fetchDadosCaixa, handlerLancamentoDeValorModalClose]);

  useEffect(() => {
    fetchDadosCaixa();
    fetchContaCorrente();
  }, [id, fetchDadosCaixa, fetchContaCorrente]);

  if (loading || !contaCorrente) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />

      {contaCorrente ? (
        <>
          <LancamentoDeValor
            conta_corrente_id={contaCorrente.id}
            empresa_id={Number(contaCorrente.empresa.id)}
            onRequestClose={handlerLancamentoDeValorModalClose}
            visible={lancamentoDeValorModalVisible}
            onRequestSave={handlerLancamentoDeValorModalOnSave}
          />

          <FechamentoDeCaixa
            conta_corrente_id={contaCorrente.id}
            visible={fechamentoDeCaixaModalVisible}
            onRequestClose={handlerFecharCaixaModalClose}
          />
        </>
      ) : null}

      <Content>
        {contaCorrente ? (
          <Infos>
            <Block>
              <Info>
                <Val cor={colors.yellow}>{contaCorrente.descricao}</Val>
              </Info>
              <Info>
                <Label>Operador:</Label>
                <Val>{contaCorrente.usuario.nome_fantasia}</Val>
              </Info>
            </Block>
            <Block>
              <Info>
                <Label>Empresa:</Label>
                <Val>{contaCorrente.empresa.fantasia}</Val>
              </Info>
              {contaCorrente.instituicao_financeira && (
                <Info>
                  <Label>Instituição Financeira:</Label>
                  <Val>{contaCorrente.instituicao_financeira.descricao}</Val>
                </Info>
              )}
            </Block>
            <Block>
              <Info>
                <Label>Status:</Label>
                <Val>{contaCorrente.status}</Val>
              </Info>
            </Block>
          </Infos>
        ) : null}
        {dadosCaixa?.data.length ? (
          <Left>
            {dadosCaixa.tipos_pagamentos.map(tipo_pagamento => (
              <React.Fragment key={tipo_pagamento.id}>
                <LeftInfo>
                  <span>{tipo_pagamento.descricao}</span>
                  <strong>
                    {`R$ ${handlerNumberToString(tipo_pagamento.valor)}`}
                  </strong>
                </LeftInfo>
              </React.Fragment>
            ))}
          </Left>
        ) : null}

        {dadosCaixa?.data.length ? (
          <Grid>
            {dadosCaixa.data.map((item, key) => (
              <Item key={item.id} eImpar={key % 2 === 0}>
                <Line>
                  <Prop cor={item.valor > 0 ? colors.green : colors.error}>
                    <span>R$</span>
                    <strong>{handlerNumberToString(item.valor)}</strong>
                  </Prop>
                  <Prop>
                    <strong>{item.descricao}</strong>
                  </Prop>
                  <Prop>
                    <span>Usuário:</span>
                    <strong>{item.usuario.nome_fantasia}</strong>
                  </Prop>
                </Line>
                <Line>
                  <Prop>
                    <span>Data:</span>
                    <strong>{formatCustomBR(new Date(item.created_at))}</strong>
                  </Prop>
                  <Prop />
                  <Prop>
                    <span>Operação:</span>
                    <strong>{item.operacao}</strong>
                  </Prop>
                </Line>
              </Item>
            ))}
          </Grid>
        ) : (
          <ContentEmpty>
            <span>Nenhum registro encontrado!</span>
          </ContentEmpty>
        )}

        <Footer>
          <Opts>
            <Opt onClick={fetchDadosCaixa}>
              <span>Atualizar</span>
              <BiRefresh size={32} color={colors.label} />
            </Opt>
            {contaCorrente?.status !== 'FECHADA' && (
              <>
                <Opt onClick={handlerFecharCaixaModalOpen}>
                  <span>Fechar caixa</span>
                  <AiOutlineClose size={32} color={colors.label} />
                </Opt>
                <Opt onClick={handlerLancamentoDeValorModalOpen}>
                  <span>Lançar</span>
                  <AiOutlinePlus size={32} color={colors.label} />
                </Opt>
              </>
            )}
          </Opts>
          <div>
            <Link to="/contas-correntes">
              <Button background={colors.contrast}>SAIR</Button>
            </Link>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Caixa;
