import React, { useState } from 'react';
import { AiFillFolderOpen, AiFillFolder } from 'react-icons/ai';
import { useTheme } from 'styled-components';

import { Container, Title, Content } from './styles';

import { ACLFolderProps } from './interface';

const ACLFolder: React.FC<ACLFolderProps> = ({ folderName, children }) => {
  const { colors } = useTheme();
  const [visibleContent, setVisibleContent] = useState(false);

  const toogleVisibleContent = () => {
    setVisibleContent(!visibleContent);
  };

  return (
    <Container>
      <Title $active={visibleContent} onClick={toogleVisibleContent}>
        {visibleContent ? (
          <AiFillFolderOpen size={32} color={colors.label} />
        ) : (
          <AiFillFolder size={32} color={colors.label} />
        )}
        <span>{folderName}</span>
      </Title>
      {visibleContent ? <Content>{children}</Content> : null}
    </Container>
  );
};

export default ACLFolder;
