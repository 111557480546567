import React, { useState, useRef, useCallback, useEffect } from 'react';

import { ImRadioUnchecked, ImRadioChecked } from 'react-icons/im';

import { useNavigate } from 'react-router-dom';

import { useTheme } from 'styled-components';

import { FiMenu } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';

import { Container, Content, Info, Left, Right, Menu } from './styles';

import { PedidoProps } from './interface';

import { handlerNumberToString } from '~/utils/money';
import { formatTheDateYall } from '~/utils/formatDate';
import { PedidoType } from '~/types';

const Pedido: React.FC<PedidoProps> = ({
  pedido,
  eImpar,
  selecionada,
  selecionarPedido,
}) => {
  const { colors } = useTheme();

  const history = useNavigate();

  const [menuVisible, setMenuVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const visualizarPedido = (pPedido: PedidoType) => {
    history(`/resumo-do-pedido/${pPedido.id}`);
  };

  const editarPedido = (pPedido: PedidoType) => {
    history(`/pedido/${pPedido.id}`);
  };

  const handlerHideContent = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeMenu();
    }
  }, []);

  const handleClickOutside = useCallback((event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  return (
    <Container $selecionada={selecionada} $eimpar={eImpar}>
      <Left onClick={() => selecionarPedido(pedido.id)}>
        {selecionada ? (
          <ImRadioChecked size={24} color="#6e6e6e" />
        ) : (
          <ImRadioUnchecked size={24} color="#6e6e6e" />
        )}
      </Left>
      <Content>
        <div>
          <Info>
            <small>Empresa:</small>
            {pedido.empresa.fantasia}
          </Info>
          <Info>
            <small>Código:</small>
            {pedido.codigo}
          </Info>
          <Info $cor="#00AAE0">{pedido.cliente_nome}</Info>
        </div>
        <div>
          <Info $cor="#D82E2E">
            <small>Data:</small>
            {formatTheDateYall(pedido.created_at)}
          </Info>
          <Info>
            <small>Natureza da Operação:</small>
            {pedido.natureza_operacao.descricao}
          </Info>
          <Info>
            <small>Tabela de Preço:</small>
            {pedido.tabela_preco.descricao}
          </Info>
        </div>
        <div>
          <Info>
            <small>Produtos:</small>
            {`R$ ${handlerNumberToString(pedido.valor_produtos)}`}
          </Info>
          <Info>
            <small>Desconto:</small>
            {`R$ ${handlerNumberToString(pedido.desconto)}`}
          </Info>
          <Info>
            <small>Total:</small>
            {`R$ ${handlerNumberToString(pedido.valor_total)}`}
          </Info>
        </div>
        <div>
          <Info>
            <small>Digitador:</small>
            {pedido.digitador.nome_fantasia}
          </Info>
          <Info>
            <small>Vendedor:</small>
            {pedido.vendedor.nome_fantasia}
          </Info>
          <Info />
        </div>
      </Content>
      <Right>
        {menuVisible ? (
          <CgClose size={24} color={colors.label} onClick={closeMenu} />
        ) : (
          <FiMenu size={24} color={colors.label} onClick={openMenu} />
        )}
        <Menu ref={ref} $visible={menuVisible}>
          <button type="button" onClick={() => visualizarPedido(pedido)}>
            VISUALIZAR PEDIDO
          </button>

          {!pedido.natureza_operacao.reflete_estoque_fisico &&
          !pedido.natureza_operacao.reflete_estoque_contabil ? (
            <button type="button" onClick={() => editarPedido(pedido)}>
              EDITAR PEDIDO
            </button>
          ) : null}
        </Menu>
      </Right>
    </Container>
  );
};

export default Pedido;
