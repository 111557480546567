import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/auth';

// import swal from 'sweetalert2';

import { useTheme } from 'styled-components';
import { BsFillTriangleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader, NoPermission } from '~/components';

import { UnidadeType } from '~/types';

// import { useToast } from '~/hooks/toast';

import api from '~/services/api';
import { validarPermissao } from '~/utils/permissions';

const Unidades: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();
  const { user } = useAuth();
  // const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [unidades, setunidades] = useState<Array<UnidadeType>>([]);

  const fetchUnidades = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('produtos/unidades');
      setunidades(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  }, []);

  // const handlerRemoveUnidade = useCallback(
  //   (unidade: UnidadeType) => {
  //     swal({
  //       title: `Deseja realmente remover o unidade "${unidade.descricao}"?`,
  //       text: `Ao confirmar, o unidade será removido!`,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Sim',
  //       cancelButtonText: 'Não',
  //     }).then(async result => {
  //      if (result.isConfirmed) {
  //         try {
  //           await api.delete(`unidade/${unidade.id}`);
  //           await fetchUnidades();
  //         } catch (e) {
  //           // console.log(e);
  //         }
  //         addToast({
  //           type: 'success',
  //           title: 'Concluido',
  //           description: `unidade "${unidade.descricao}" deletado com sucesso!`,
  //         });
  //       }
  //     });
  //   },
  //   [fetchUnidades, addToast],
  // );

  const handlerEditUnidade = useCallback(
    (unidade: UnidadeType) => {
      history(`/unidade/${unidade.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchUnidades();
  }, [fetchUnidades]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'unidade-produtos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Plate
          title="Unidades"
          subtitle="Cadastre as unidades utilizadas"
          icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
        >
          <Link to="/unidade/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {unidades ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'sigla',
                label: 'Sigla',
                search: false,
              },
            ]}
            data={unidades}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditUnidade(item);
                },
              },
              // {
              //   label: 'Remover',
              //   onPress: item => {
              //     handlerRemoveUnidade(item);
              //   },
              // },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default Unidades;
