import React, { useCallback, useRef, useState } from 'react';

import { GoPencil } from 'react-icons/go';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { handlerToNumber } from '~/utils/money';

import {
  Button,
  ErrorMessage,
  Input,
  InputNumber,
  Modal,
  Select,
} from '~/components';

import { CondicaoPagamentoType, TipoPagamentoType } from '~/types';

import { LancamentoDeValorFormData, LancamentoDeValorProps } from './interface';

import CondicaoDePagamento from './CondicaoDePagamento';
import TipoDePagamento from './TipoDePagamento';

import {
  Container,
  Footer,
  InputContainer,
  InputsContainer,
  Toggle,
} from './styles';
import { handlerErrors } from '~/utils/error';

const LancamentoDeValor: React.FC<LancamentoDeValorProps> = ({
  conta_corrente_id,
  empresa_id,
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const [tipoDePagamento, setTipoDePagamento] =
    useState<TipoPagamentoType | null>(null);

  const [condicaoDePagamento, setCondicaoDePagamento] =
    useState<CondicaoPagamentoType | null>(null);

  const [tipoDePagamentoVisible, setTipoDePagamentoVisible] = useState(false);
  const [condicaoDePagamentoVisible, setCondicaoDePagamentoVisible] =
    useState(false);

  const handlerOpenFormaDePagamento = useCallback(() => {
    setTipoDePagamentoVisible(true);
  }, []);

  const handlerCloseTipoDePagamento = useCallback(() => {
    setTipoDePagamentoVisible(false);
  }, []);

  const handlerOpenCondicaoDePagamento = useCallback(() => {
    setCondicaoDePagamentoVisible(true);
  }, []);

  const handlerCloseCondicaoDePagamento = useCallback(() => {
    setCondicaoDePagamentoVisible(false);
  }, []);

  const handlerSaveTipoDePagamento = useCallback(
    (pFormaDePagamento: TipoPagamentoType) => {
      setTipoDePagamento(pFormaDePagamento);
      setCondicaoDePagamento(null);
    },
    [],
  );

  const handlerSaveCondicaoDePagamento = useCallback(
    (pCondicaoDePagamento: CondicaoPagamentoType) => {
      setCondicaoDePagamento(pCondicaoDePagamento);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: LancamentoDeValorFormData) => {
      try {
        setFormLoading(true);
        setFormError('');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe a descrição do lançamento'),
          valor: Yup.string().required('Informe um Valor'),
          tipo: Yup.string().required('Informe o tipo do lançamento'),
        });

        await schema.validate(data, { abortEarly: false });

        if (!tipoDePagamento) {
          return setFormError('Selecione um tipo de pagamento!');
        }

        if (!condicaoDePagamento) {
          return setFormError('Selecione uma condição de pagamento!');
        }

        const ObjData = {
          ...data,
          condicao_pagamento_id: condicaoDePagamento.id,
          empresa_id,
          valor: handlerToNumber(data.valor) * (data.tipo === 'SAIDA' ? -1 : 1),
        };

        delete ObjData.tipo;

        try {
          await api.post(
            `financeiros/dados-caixas/suprimento-sangria/${conta_corrente_id}`,
            ObjData,
          );

          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });

          formRef.current?.reset();
          onRequestSave();
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message ? message : 'Falha no envio'}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [
      addToast,
      onRequestSave,
      conta_corrente_id,
      tipoDePagamento,
      condicaoDePagamento,
      empresa_id,
    ],
  );

  return (
    <Modal
      title="Incluir Lançamento"
      width="100rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <TipoDePagamento
          visible={tipoDePagamentoVisible}
          onRequestClose={handlerCloseTipoDePagamento}
          onRequestSave={handlerSaveTipoDePagamento}
        />

        {tipoDePagamento ? (
          <CondicaoDePagamento
            tipo_pagamento_id={tipoDePagamento.id}
            visible={condicaoDePagamentoVisible}
            onRequestClose={handlerCloseCondicaoDePagamento}
            onRequestSave={handlerSaveCondicaoDePagamento}
          />
        ) : null}

        <Form ref={formRef} onSubmit={handleSubmit}>
          <ErrorMessage error={formError} onDismiss={() => setFormError('')} />

          <InputsContainer>
            <InputContainer disabled={formLoading}>
              <span>Tipo de pagamento</span>
              <div>
                <Toggle onClick={handlerOpenFormaDePagamento}>
                  <span>
                    {tipoDePagamento ? (
                      tipoDePagamento.descricao
                    ) : (
                      <ins>TIPO DE PAGAMENTO</ins>
                    )}
                  </span>
                  <GoPencil size={22} color={colors.label} />
                </Toggle>
              </div>
            </InputContainer>
            <InputContainer disabled={formLoading}>
              <span>Condição de pagamento</span>
              <div>
                <Toggle
                  disabled={!tipoDePagamento}
                  onClick={handlerOpenCondicaoDePagamento}
                >
                  <span>
                    {condicaoDePagamento ? (
                      condicaoDePagamento.descricao
                    ) : (
                      <ins>CONDIÇÃO DE PAGAMENTO</ins>
                    )}
                  </span>
                  <GoPencil size={22} color={colors.label} />
                </Toggle>
              </div>
            </InputContainer>
          </InputsContainer>

          <InputsContainer>
            <InputContainer disabled={formLoading}>
              <span>Tipo</span>
              <div>
                <Select
                  name="tipo"
                  loading
                  defaultValue={{ value: 'ENTRADA', label: 'ENTRADA', cor: '' }}
                  options={[
                    { value: 'ENTRADA', label: 'ENTRADA', cor: '' },
                    { value: 'SAIDA', label: 'SAÍDA', cor: '' },
                  ]}
                />
              </div>
            </InputContainer>

            <InputContainer disabled={formLoading}>
              <span>Descrição</span>
              <div>
                <Input name="descricao" placeholder="Informe o descrição" />
              </div>
            </InputContainer>

            <InputContainer disabled={formLoading}>
              <span>Valor</span>
              <div>
                <InputNumber name="valor" placeholder="Informe o valor" />
              </div>
            </InputContainer>
          </InputsContainer>

          <Footer>
            <div>
              <Button
                type="button"
                background={colors.contrast}
                onClick={onRequestClose}
              >
                Fechar
              </Button>
            </div>
            <div>
              <Button type="submit" background={colors.green}>
                Cadastrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default LancamentoDeValor;
