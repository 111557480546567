import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;
`;

export const Information = styled.div`
  ${({ theme }) => css`
    width: 100%;
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.grey};
    margin: 0 0 2rem 0;

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `};
`;

export const Panels = styled.main`
  float: left;
  width: 100%;
  height: 10rem;
  border: 0.4rem dashed;
  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  padding: 1rem;
`;

export const Tile = styled.main`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: #121114;
    background: ${theme.colors.contrast};
    font-size: ${theme.fontSizes.default};
  `};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  float: left;
  width: 100%;
  padding: 2rem 0;

  div {
    margin: 0 1rem;
  }
`;
