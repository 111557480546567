const getObjectFromValue = (value: string | number, array: any[]): any => {
  const object = array.filter(obj => String(obj.value) == String(value))[0];
  if (object) {
    return object;
  }

  return null;
};

export { getObjectFromValue };
