import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import swal from 'sweetalert2';
import { useTheme } from 'styled-components';

import api from '~/services/api';

import { useToast } from '~/hooks/toast';

import { RiDragDropLine } from 'react-icons/ri';
import { BsCardImage, BsFillTrash3Fill } from 'react-icons/bs';

import { Loader } from '~/components';

import { Container, DragDrop, Info, Preview } from './styles';

import { FotoProps } from './interface';

const Foto: React.FC<FotoProps> = ({
  produto_id,
  posicao,
  cacheId,
  cacheUrl,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(false);

  const [fotoS3, setFotoS3] = useState<string | null>(
    cacheUrl ? cacheUrl : null,
  );
  const [idS3, setIdS3] = useState<string | null>(cacheId ? cacheId : null);

  const sanitizebase64 = (base64: string | ArrayBuffer | null) => {
    let output = '';
    if (base64 && typeof base64 === 'string') {
      output = `${base64}`;
      if (output.includes(',')) {
        output = output.split(',')[1];
      }
    }
    return output;
  };

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      acceptedFiles.forEach((cFile: File) => {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            setInLoad(true);

            const readerData = sanitizebase64(reader.result);

            const response = await api.post(`/produtos/imagens`, {
              imgs: [
                {
                  descricao: '',
                  index: posicao,
                  produto_id: produto_id,
                  blob: readerData,
                },
              ],
            });

            const { data } = response;

            if (data) {
              const imagem = data[0];
              if (imagem) {
                const { id, url } = imagem;
                setFotoS3(url);
                setIdS3(id);
              }
            }

            setInLoad(false);
          } catch (e) {
            setInLoad(false);

            swal.fire({
              title: `Houve um erro ao ler arquivo!`,
              text: `Informe ao suporte!`,
              icon: 'error',
            });
          }
        };
        reader.readAsDataURL(cFile);
      });
    },
    [posicao, produto_id],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/jpeg': ['.jpeg'],
    },
  });

  const removerFoto = (id: string): void => {
    swal
      .fire({
        title: `Deseja realmente remover a foto?`,
        text: `Ao confirmar, a foto será removida!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            setInLoad(true);

            await api.delete(`produtos/imagens/${id}`);

            addToast({
              type: 'success',
              title: 'Concluido',
              description: `Foto deletada com sucesso!`,
            });

            setInLoad(false);

            setFotoS3(null);
            setIdS3(null);
          } catch {
            setInLoad(false);

            setFotoS3(null);
            setIdS3(null);

            addToast({
              type: 'error',
              title: 'Falha ao remover',
              description: `Foto não foi removida!`,
            });
          }
        }
      });
  };

  if (inLoad) {
    return <Loader />;
  }

  if (fotoS3 && idS3) {
    return (
      <Container>
        <Preview>
          <div>
            <img src={fotoS3} alt={`FOTO ${posicao}`} />
          </div>
          <button
            type="button"
            title={`REMOVER FOTO ${posicao}`}
            onClick={() => removerFoto(idS3)}
          >
            <BsFillTrash3Fill size={22} color={colors.label} />
          </button>
        </Preview>
      </Container>
    );
  }

  return (
    <Container>
      <DragDrop $active={isDragActive}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Info>
              <RiDragDropLine size={60} color={colors.label} />
              <div>
                <h1>FOTO {posicao}</h1>
                <p>Muito bem! solte o imagem aqui.</p>
              </div>
            </Info>
          ) : (
            <Info>
              <BsCardImage size={60} color={colors.label} />
              <div>
                <h1>FOTO {posicao}</h1>
                <p>
                  <ins>clique aqui</ins> ou <ins>arraste-a</ins> a imagem aqui
                  em cima
                </p>
              </div>
            </Info>
          )}
        </div>
      </DragDrop>
    </Container>
  );
};

export default Foto;
