import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

import signUpBackgroundImg from '../../assets/sign-up-background.png';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100vh;

    display: flex;
    align-items: stretch;

    background: ${theme.colors.background};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 70rem;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromRight} 1s;

    img {
      width: 34rem;
    }

    form {
      margin: 8rem 0;
      width: 34rem;
      text-align: center;

      h1 {
        font-size: ${theme.fontSizes.big};
        margin-bottom: 2.4rem;
      }

      a {
        color: #f4ede8;
        display: block;
        margin-top: 2.4rem;
        text-decoration: none;
        transition: color 0.2s;

        &:hover {
          color: ${shade(0.2, '#f4ede8')};
        }
      }
    }

    > a {
      color: #0098da;
      display: block;
      margin-top: 2.4rem;
      text-decoration: none;
      transition: color 0.2s;

      display: flex;
      align-items: center;

      svg {
        margin-right: 1.6rem;
      }

      &:hover {
        color: ${shade(0.2, '#0098da')};
      }
    }
  `}
`;

export const Background = styled.div`
  ${({ theme }) => css`
    flex: 1;
    background: url(${signUpBackgroundImg}) no-repeat;
    background-size: cover;

    margin: 2rem;
    border-radius: 2rem;
    box-shadow: ${theme.shadows.default};
  `}
`;
