import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useAuth } from '~/hooks/auth';
import * as Yup from 'yup';

import { BsFillTriangleFill } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';

import api from '~/services/api';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';
import { handlerErrors } from '~/utils/error';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

import Header from '~/components/Header';
import List from '~/components/List';
import Loader from '~/components/Loader';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  DatePicker,
  ErrorMessage,
  Finder,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputNumber,
  InputsContainer,
  Plate,
  Select,
  Switch,
  NoPermission,
} from '~/components';

import FormaPagamento from './FormaPagamento';
import Grade from './Grade';
import Produto from './Produto';

import { Container, Content, Empty, Grid } from './styles';

import {
  EmpresaType,
  NfeManualFormaPagtoType,
  NfeManualItemType,
  NfeManualType,
  SelectType,
} from '~/types';

import { formatTheDateYall } from '~/utils/formatDate';
import {
  CompraManualFormData,
  ICompraManualItem,
  ParamsProps,
} from './interface';
import { validarPermissao } from '~/utils/permissions';

const CompraManual: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useNavigate();

  const formRef = useRef<FormHandles>(null);

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState<any>({
    id,
  });
  const [registerId, setRegisterId] = useState('0');

  const [empresas, setEmpresas] = useState<SelectType[]>([]);
  const [itens, setItens] = useState<NfeManualItemType[]>([]);
  const [parceiro, setParceiro] = useState({ key: '', value: '' });
  const [grade, setGrade] = useState<NfeManualItemType | null>(null);

  const [addFormaVisibilidade, setAddFormaVisibilidade] = useState(false);
  const [formas, setFormas] = useState<NfeManualFormaPagtoType[]>([]);

  const adicionarItem = (item: NfeManualItemType) => {
    setItens([...itens, item]);
  };

  const removerItem = (item: NfeManualItemType) => {
    setItens([...itens.filter(produto => produto.nItem !== item.nItem)]);
  };

  const calcularValorLancado = (pItens: NfeManualItemType[]) => {
    let total = 0;
    if (pItens.length) {
      let contador = 0;
      for (contador = 0; contador < pItens.length; contador += 1) {
        const item = pItens[contador];
        total +=
          handlerToNumber(`${item.quantidade}`) *
          handlerToNumber(`${item.valor_unitario}`);
      }
    }
    return `${handlerNumberToString(total)}`;
  };

  const calcularQuantidadeItens = (pItens: NfeManualItemType[]) => {
    let total = 0;
    if (pItens.length) {
      let contador = 0;
      for (contador = 0; contador < pItens.length; contador += 1) {
        const item = pItens[contador];
        total += handlerToNumber(`${item.quantidade}`);
      }
    }
    return `${total}`;
  };

  const abrirCorrecaoGrade = (pItem: NfeManualItemType) => {
    setGrade(pItem);
  };

  const fecharCorrecaoGrade = () => {
    setGrade(null);
  };

  const salvarCorrecaoGrade = (pItem: NfeManualItemType) => {
    setItens([...itens.filter(cItem => cItem.nItem !== pItem.nItem), pItem]);
  };

  const abrirAdicaoFormaPagamento = () => {
    setAddFormaVisibilidade(true);
  };

  const fecharAdicaoFormaPagamento = () => {
    setAddFormaVisibilidade(false);
  };

  const adicionarForma = (pForma: NfeManualFormaPagtoType) => {
    setFormas([...formas, pForma]);
    fecharAdicaoFormaPagamento();
  };

  const removerForma = (pForma: NfeManualFormaPagtoType) => {
    setFormas([...formas.filter(cForma => cForma.id !== pForma.id)]);
  };

  const validarCoerenciaValores = (
    pFormas: NfeManualFormaPagtoType[],
    pTotalNf: number,
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      const handler = async () => {
        // NENHUMA FORMA INFORMADA
        if (pFormas.length <= 0) {
          return reject(new Error('Nenhuma forma de pagamento informada!'));
        }

        // TOTAL DA NOTA
        if (pTotalNf <= 0) {
          return reject(new Error('Total da nota não informado!'));
        }

        // SOMA DAS FORMAS
        let somaFormasPagto = 0;
        let countFormas = 0;
        for (countFormas = 0; countFormas < pFormas.length; countFormas += 1) {
          const cForma = pFormas[countFormas];
          somaFormasPagto += cForma.valor;
        }

        // SOMA DAS FORMAS DE PAGAMENTO
        if (somaFormasPagto <= 0) {
          return reject(new Error('Total da nota não informado!'));
        }

        // FORMAS DE PAGAMENTO IGUAL AO TOTAL DA NOTA
        if (somaFormasPagto !== pTotalNf) {
          return reject(
            new Error(
              'Soma das formas de pagamento não bate com o total informada da nota',
            ),
          );
        }

        resolve(true);
      };
      handler();
    });
  };

  const enviarFormulario = useCallback(
    async (data: CompraManualFormData) => {
      try {
        setFormError('');

        const {
          empresa_id,
          numero_nf,
          data_entrada,
          total_nf,
          total_desconto_nf,
          total_frete_nf,
          atualizar_custo_medio,
        } = data;

        if (!itens.length) {
          throw Error('Nenhum produto adicionado');
        }

        if (!parceiro) {
          throw Error('Nenhum produto adicionado');
        }

        const totalNf = handlerToNumber(total_nf);

        await validarCoerenciaValores(formas, totalNf);

        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          empresa_id: Yup.string().required('Informe uma empresa válida.'),
          numero_nf: Yup.string().required('Informe o numero da nota válido.'),
          total_nf: Yup.string().required('Informe o valor total válido.'),
          data_entrada: Yup.string().required('Informe a data entrada válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        const nfeManual: NfeManualType = {
          empresa_id: Number(empresa_id),
          numero_nf: handlerToNumber(numero_nf),
          data_entrada,
          parceiro_id: Number(parceiro.key),
          total_nf: totalNf,
          total_desconto_nf: handlerToNumber(total_desconto_nf),
          total_frete_nf: handlerToNumber(total_frete_nf),
          itens,
          conta_corrente_id: 1, // FABRICIO?
          atualizar_custo_medio,
          formas: [
            ...formas.map(cForma => ({
              condicao_pagamento_id: cForma.condicao_pagamento_id,
              valor: cForma.valor,
            })),
          ],
        };

        // console.log(nfeManual);

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `nfe-manual/${registerId !== '0' ? `/${registerId}` : ''}`,
            nfeManual,
          );

          history(`/compra-manual/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError(`${(err as Error).message}`);
      }
    },
    [itens, parceiro, formas, registerId, history, addToast],
  );

  function limparFormulario() {
    setRegisterData({ id: '0' });
    setItens([]);
    formRef.current?.reset();
  }

  useEffect(() => {
    const loadEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }

      if (id !== '0') {
        const response = await api.get(`nfe-manual/${id}`);

        const {
          empresa,
          IDE_nNF: numero_nf,
          created_at: data_entrada,
          parceiro_id,
          TOTAL_vNF: total_nf,
          TOTAL_vDesc: total_desconto_nf,
          TOTAL_vFrete: total_frete_nf,
          atualizar_custo_medio,
          formas: formasPagamento,
        } = response.data;

        setRegisterData({
          numero_nf,
          parceiro_id,
          total_nf,
          total_desconto_nf,
          total_frete_nf,
          atualizar_custo_medio,
          empresa_id: {
            label: empresa.fantasia,
            value: empresa.id,
          },
          data_entrada: formatTheDateYall(data_entrada),
        });

        setFormas(formasPagamento);

        const itens_nfe = response.data.nfe_itens.map(
          (item: ICompraManualItem) => {
            const {
              nfe_id,
              nItem,
              PROD_xProd,
              PROD_vUnCom,
              PROD_qCom,
              PROD_uCom,
              conversao,
            } = item;

            return {
              nItem: nfe_id,
              variacoes: null,
              conversao,
              unidade: PROD_uCom,
              produto_descricao: PROD_xProd,
              quantidade: PROD_qCom,
              produto_id: nItem,
              valor_unitario: PROD_vUnCom,
            };
          },
        );

        setItens([...itens_nfe]);
        calcularValorLancado(itens_nfe);
        setLoading(false);
      } else {
        limparFormulario();
        setItens([]);
        setFormas([]);
        setLoading(false);
      }
    }

    loadEmpresas();
    loadRegister();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'incluir-compras')) {
    return <NoPermission />;
  }

  return (
    <Container>
      {grade ? (
        <Grade
          item={grade}
          onRequestClose={fecharCorrecaoGrade}
          onRequestSave={salvarCorrecaoGrade}
        />
      ) : null}

      {addFormaVisibilidade ? (
        <FormaPagamento
          aoFecharFormaPagamento={fecharAdicaoFormaPagamento}
          adicionarFormaPagamento={adicionarForma}
        />
      ) : null}

      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
            <AnchorLink anchor="add-produto">Adicionar</AnchorLink>
            <AnchorLink anchor="itens">Produtos</AnchorLink>
            <AnchorLink anchor="financeiro">Financeiro</AnchorLink>
            <AnchorLink anchor="resumo">Resumo</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={enviarFormulario}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Dados Cadastrais"
                      subtitle="Informe os dados iniciais da sua compra (empresa, parceiro, etc)"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Empresa</span>
                        <div>
                          <Select
                            name="empresa_id"
                            loading={loading}
                            options={empresas}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Parceiro</span>
                        <div>
                          <Finder
                            placeholderInput="Informe o nome ou código"
                            placeholderValue="Selecione um parceiro"
                            endpointRef="parceiros"
                            columnSearchRef="tipos=cliente,fornecedor&nome_fantasia"
                            keyRef="id"
                            labelRef="nome_fantasia"
                            defaultValue={parceiro}
                            onSelectValue={(key, value) => {
                              setParceiro({ key, value });
                            }}
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Número da nota</span>
                        <div>
                          <Input name="numero_nf" placeholder="Número Nota" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Frete R$</span>
                        <div>
                          <InputNumber
                            name="total_frete_nf"
                            placeholder="Frete"
                            defaultValue={registerData.total_frete_nf}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Desconto R$</span>
                        <div>
                          <InputNumber
                            name="total_desconto_nf"
                            placeholder="Desconto"
                            defaultValue={registerData.total_desconto_nf}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Total R$</span>
                        <div>
                          <InputNumber
                            name="total_nf"
                            placeholder="Total"
                            defaultValue={registerData.total_nf}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Data Entrada</span>
                        <div>
                          <DatePicker
                            name="data_entrada"
                            mask="99/99/9999"
                            placeholder="Data de Entrada"
                          />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>

                <AnchorRef anchor="add-produto">
                  <Produto aoAdicionarItem={adicionarItem} />
                </AnchorRef>

                <AnchorRef anchor="itens">
                  <Plate
                    title="Produtos"
                    subtitle="Confira os itens adicionais"
                    icon={() => (
                      <BsFillTriangleFill size={32} color={colors.label} />
                    )}
                  />

                  <Grid>
                    {itens && itens.length ? (
                      <List
                        hideFooter
                        header={[
                          {
                            column: 'produto_id',
                            label: 'PRODUTO',
                          },
                          {
                            column: 'produto_descricao',
                            label: 'DESCRIÇÃO',
                          },
                          {
                            column: 'unidade',
                            label: 'UNIDADE',
                            width: 200,
                          },
                          {
                            column: 'quantidade',
                            label: 'QUANTIDADE',
                            width: 200,
                          },
                          {
                            column: 'valor_unitario',
                            label: 'UNITÁRIO',
                            width: 200,
                          },
                          {
                            column: 'total',
                            label: 'TOTAL',
                            width: 200,
                          },
                        ]}
                        data={[
                          ...itens.map(cItem => ({
                            ...cItem,
                            valor_unitario: `R$ ${handlerNumberToString(
                              cItem.valor_unitario,
                            )}`,
                            total: `R$ ${handlerNumberToString(
                              cItem.quantidade * cItem.valor_unitario,
                            )}`,
                          })),
                        ]}
                        options={[
                          {
                            label: 'Variações',
                            onPress: item => {
                              abrirCorrecaoGrade(item);
                            },
                          },
                          {
                            label: 'Excluir',
                            onPress: item => {
                              removerItem(item);
                            },
                          },
                        ]}
                      />
                    ) : (
                      <Empty>
                        Adicione ao menos um produto atráves do formulário
                        acima!
                      </Empty>
                    )}
                  </Grid>
                </AnchorRef>

                <AnchorRef anchor="financeiro">
                  <Plate
                    title="Financeiro"
                    subtitle="Adicione as formas de pagamento"
                    icon={() => (
                      <BsFillTriangleFill size={32} color={colors.label} />
                    )}
                  >
                    <Button
                      type="button"
                      background={colors.darkGreen}
                      onClick={abrirAdicaoFormaPagamento}
                    >
                      <FiPlus size={24} />
                    </Button>
                  </Plate>

                  <Grid>
                    {formas && formas.length ? (
                      <List
                        hideFooter
                        header={[
                          {
                            column: 'condicao_pagamento',
                            label: 'DESCRIÇÃO',
                          },
                          {
                            column: 'valor',
                            label: 'VALOR',
                          },
                        ]}
                        data={[
                          ...formas.map(cForma => ({
                            ...cForma,
                            valor: `R$ ${handlerNumberToString(cForma.valor)}`,
                            condicao_pagamento_descricao:
                              cForma.condicao_pagamento,
                          })),
                        ]}
                        options={[
                          {
                            label: 'Excluir',
                            onPress: forma => {
                              removerForma(forma);
                            },
                          },
                        ]}
                      />
                    ) : (
                      <Empty>
                        Gerencie as formas de pagamentos da compra manual
                      </Empty>
                    )}
                  </Grid>
                </AnchorRef>

                <AnchorRef anchor="resumo">
                  <Plate
                    title="Resumo"
                    subtitle="Confira os detalhes da sua compra"
                    icon={() => (
                      <BsFillTriangleFill size={32} color={colors.label} />
                    )}
                  />
                  <FormBlock>
                    <InputsContainer>
                      <InputContainer width={300} disabled={formLoading}>
                        <span>Valor Lançado</span>
                        <div>
                          <InputNumber
                            name="valor_lancado"
                            placeholder="Valor Lançado"
                            value={`${calcularValorLancado(itens)}`}
                            disabled
                          />
                        </div>
                      </InputContainer>
                      <InputContainer width={300} disabled={formLoading}>
                        <span>Quantidade de Itens</span>
                        <div>
                          <Input
                            name="quantidade_itens"
                            placeholder="Quantidade Itens"
                            value={`${calcularQuantidadeItens(itens)}`}
                            disabled
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Atualizar custo médio?</span>
                        <div className="switch">
                          <Switch name="atualizar_custo_medio" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/compras-manuais">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={limparFormulario}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Gravar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default CompraManual;
