import React, { useRef, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { useNavigate } from 'react-router-dom';

import { FaFileCircleCheck } from 'react-icons/fa6';
import { FaPrint } from 'react-icons/fa6';
import { HiDocumentArrowDown } from 'react-icons/hi2';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';

import {
  Container,
  Content,
  Information,
  IC,
  Opts,
  Opt,
  Or,
  Footer,
} from './styles';

import { PosVendaProps } from './interface';

const PosVenda: React.FC<PosVendaProps> = ({ idpedido, onRequestClose }) => {
  const { colors } = useTheme();

  const history = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const reeimprimirPedido = () => {
    alert(' --- REEIMPRIMIR PEDIDO --- ');
  };

  const emitirDocumentoFiscal = () => {
    history(`/emissao-documento-fiscal/${idpedido}`);
  };

  const handlerHideContent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        document.body.className = '';
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (event.ctrlKey && event.code === 'KeyF') {
      event.preventDefault();
      emitirDocumentoFiscal();
    }

    if (event.ctrlKey && event.code === 'KeyR') {
      event.preventDefault();
      reeimprimirPedido();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
    };
  });

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  return (
    <Container>
      <Content>
        <IC>
          <FaFileCircleCheck size={48} color={colors.white} />
        </IC>
        <Information>
          <h1>Pedido efetuado com sucesso!</h1>
          <h2>O que gostaria de fazer agora?</h2>
        </Information>
        <Opts>
          <Opt onClick={emitirDocumentoFiscal}>
            <HiDocumentArrowDown size={42} color={colors.white} />
            <div>
              <span>EMITIR DOCUMENTO FISCAL</span>
              <small>(CTRL + F)</small>
            </div>
          </Opt>
          <Opt
            onClick={reeimprimirPedido}
            style={{ background: colors.primary }}
          >
            <FaPrint size={38} color={colors.white} />
            <div>
              <span>IMPRIMIR PEDIDO</span>
              <small>(CTRL + R)</small>
            </div>
          </Opt>
        </Opts>
        <Or>
          <ins></ins>
          <span>OU</span>
          <ins></ins>
        </Or>
        <Footer>
          <Opt
            style={{ background: colors.secondary }}
            onClick={onRequestClose}
          >
            <MdOutlineKeyboardBackspace size={52} color={colors.white} />
            <div>
              <span>REALIZAR UM NOVO PEDIDO [SAIR]</span>
              <small>(ESC)</small>
            </div>
          </Opt>
        </Footer>
      </Content>
    </Container>
  );
};

export default PosVenda;
