import React from 'react';

import { LoaderContainer } from './styles';

import { LoaderProps } from './interface';

const Loader: React.FC<LoaderProps> = ({ visible }) => {
  return (
    <LoaderContainer $visible={visible === undefined ? true : visible}>
      <div />
    </LoaderContainer>
  );
};

export default Loader;
