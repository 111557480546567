import React from 'react';
import 'react-day-picker/dist/style.css';

import { AnimatedContainer } from './styles';
import { DASHBOARD_ANIMATION } from './animations';

const Dashboard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AnimatedContainer
      variants={DASHBOARD_ANIMATION}
      initial="unMounted"
      animate="mounted"
      exit="unMounted"
      transition={{ duration: 1 }}
    >
      {children}
    </AnimatedContainer>
  );
};

export default Dashboard;
