import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';
import { ImKey2 } from 'react-icons/im';

import api from '~/services/api';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import ACLFolder from './ACLFolder';
import ACLPermission from './ACLPermission';
import ACLPerfils from './ACLPerfils';

import {
  ACLContainer,
  ACLContent,
  ACLContentHeader,
  ACLPermissions,
  ACLPermissionsBlock,
  ACLPermissionsTitle,
  ACLPermissionsFolders,
  ACLPermissionsEmpty,
} from './styles';

const ACL: React.FC = () => {
  const { colors } = useTheme();

  const [currentPermission, setCurrentPermission] = useState<any>(null);
  const [permissoes, setPermissoes] = useState<Array<any>>([]);

  const onConfigPermission = (permissionID: string) => {
    setCurrentPermission(permissionID);
  };

  const fetchAcl = async () => {
    try {
      const response = await api.get('acl');
      const { data } = response;
      if (data && data.status !== 'error') {
        setPermissoes(data.permissoes);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    fetchAcl();
  }, []);

  return (
    <ACLContainer>
      <Header />
      <Loader visible={!permissoes.length} />
      <ACLContent>
        <ACLContentHeader>
          <div>
            <ImKey2 size={32} color={colors.label} />
            <main>
              <h1>Permissões</h1>
              <h2>
                Gerencie as permissões do sistema para todos os nivéis de
                usuários
              </h2>
            </main>
          </div>
        </ACLContentHeader>

        <ACLPermissions>
          <ACLPermissionsBlock>
            <ACLPermissionsTitle>
              <span>Categorias</span>
            </ACLPermissionsTitle>
            <ACLPermissionsFolders>
              {permissoes.map((grupo: any) => (
                <ACLFolder key={grupo.id} folderName={`${grupo.nome}`}>
                  {grupo.subgrupo.length ? (
                    grupo.subgrupo.map((subgrupo: any) => (
                      <ACLFolder
                        key={subgrupo.id}
                        folderName={`${subgrupo.nome}`}
                      >
                        {subgrupo.permissoes.map((permissao: any) => (
                          <ACLPermission
                            key={permissao.id}
                            active={currentPermission?.id === permissao.id}
                            permission={permissao}
                            onConfigPermission={onConfigPermission}
                          />
                        ))}
                      </ACLFolder>
                    ))
                  ) : (
                    <>
                      {permissoes.map((permissao: any) => (
                        <ACLPermission
                          key={permissao.id}
                          active={currentPermission?.id === permissao.id}
                          permission={permissao}
                          onConfigPermission={onConfigPermission}
                        />
                      ))}
                    </>
                  )}
                </ACLFolder>
              ))}
            </ACLPermissionsFolders>
          </ACLPermissionsBlock>
          <ACLPermissionsBlock>
            {currentPermission ? (
              <>
                <ACLPermissionsTitle>
                  <span>Perfis de Usuários</span>
                  <span>Habilitar Permissão</span>
                </ACLPermissionsTitle>

                {permissoes.map((grupo: any) =>
                  grupo.subgrupo.length ? (
                    grupo.subgrupo.map((subgrupo: any) =>
                      subgrupo.permissoes.map((permissao: any) => (
                        <React.Fragment key={permissao.id}>
                          <ACLPerfils
                            permission={permissao}
                            active={currentPermission.id === permissao.id}
                          />
                        </React.Fragment>
                      )),
                    )
                  ) : (
                    <>
                      {permissoes.map((permissao: any) => (
                        <React.Fragment key={permissao.id}>
                          <ACLPerfils
                            permission={permissao}
                            active={currentPermission.id === permissao.id}
                          />
                        </React.Fragment>
                      ))}
                    </>
                  ),
                )}
              </>
            ) : (
              <ACLPermissionsEmpty>Selecione uma permissão</ACLPermissionsEmpty>
            )}
          </ACLPermissionsBlock>
        </ACLPermissions>
      </ACLContent>
    </ACLContainer>
  );
};

export default ACL;
