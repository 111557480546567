import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import api from '~/services/api';

import { getValidationErrors } from '~/utils';

import Loader from '~/components/Loader';

import {
  Button,
  ErrorMessage,
  FormContainer,
  FormContent,
  InputContainer,
  InputNumber,
  InputsContainer,
  Modal,
  Select,
} from '~/components';

import { Container, Footer } from './styles';

import { handlerToNumber } from '~/utils/money';

import { CondicaoPagamentoType, SelectType, TipoPagamentoType } from '~/types';

import { FormaPagamentoFormData, FormaPagamentoProps } from './interface';

const FormaPagamento: React.FC<FormaPagamentoProps> = ({
  aoFecharFormaPagamento,
  adicionarFormaPagamento,
}) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [inLoad, setInLoad] = useState(true);
  const [formError, setFormError] = useState('');

  const [condicoesPagamentosLoading, setCondicoesPagamentosLoading] =
    useState(false);

  const [tiposPagamentos, setTiposPagamentos] = useState<SelectType[]>([]);
  const [condicoesPagamentos, setCondicoesPagamentos] = useState<SelectType[]>(
    [],
  );

  const carregarTiposPagamento = async () => {
    try {
      setInLoad(true);
      const tiposPagamentosFetch = await api.get('financeiros/tipos-pagamento');
      const tiposPagamentosFetched = [] as SelectType[];
      tiposPagamentosFetch.data.data.forEach((item: TipoPagamentoType) => {
        tiposPagamentosFetched.push({
          value: item.id,
          label: item.descricao,
          color: '',
        });
      });
      setTiposPagamentos(tiposPagamentosFetched);
      setInLoad(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setInLoad(false);
    }
  };

  const carregarCondicoesPagamento = async (tipo_pagamento_id: string) => {
    try {
      setCondicoesPagamentosLoading(true);
      const condicoespagamentoFetch = await api.get(
        `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes`,
      );
      const condicoespagamentoFetched = [] as SelectType[];
      condicoespagamentoFetch.data.data.forEach(
        (item: CondicaoPagamentoType) => {
          condicoespagamentoFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        },
      );
      setCondicoesPagamentos(condicoespagamentoFetched);
      setCondicoesPagamentosLoading(false);
      // eslint-disable-next-line no-empty
    } catch (e) {
      setCondicoesPagamentosLoading(false);
    }
  };

  const limparFormulario = () => {
    formRef.current?.reset();
  };

  const pegarDescricaoCondicaoPagamento = useCallback(
    (id: string) => {
      let contador;
      let descricao = '';
      for (contador = 0; contador < condicoesPagamentos.length; contador += 1) {
        const item = condicoesPagamentos[contador];
        if (item.value === id) {
          descricao = item.label;
        }
      }
      return descricao;
    },
    [condicoesPagamentos],
  );

  const enviarFormulario = useCallback(
    async (data: FormaPagamentoFormData) => {
      try {
        formRef.current?.setErrors({});

        setFormError('');

        const schema = Yup.object().shape({
          condicao_pagamento_id: Yup.string().required(
            'Informe uma condição de pagamento válida.',
          ),
          valor: Yup.string().required('Informe um valor de pagamento.'),
        });

        await schema.validate(data, { abortEarly: false });

        setInLoad(true);

        const { condicao_pagamento_id, valor } = data;

        adicionarFormaPagamento({
          id: `${Math.random() * 10000}`,
          condicao_pagamento_id: Number(condicao_pagamento_id),
          condicao_pagamento: pegarDescricaoCondicaoPagamento(
            condicao_pagamento_id,
          ),
          valor: handlerToNumber(valor),
        });

        limparFormulario();

        setTimeout(() => {
          setInLoad(false);
        }, 500);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [adicionarFormaPagamento, pegarDescricaoCondicaoPagamento],
  );

  useEffect(() => {
    carregarTiposPagamento();
  }, []);

  if (inLoad) {
    return <Loader />;
  }

  return (
    <Modal
      title="Adicionar Forma Pagamento"
      width="120rem"
      visible
      scrollable
      onRequestClose={aoFecharFormaPagamento}
      closeButtonVisible={false}
      disableOverflow
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={enviarFormulario}>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Tipo de Pagamento</span>
                  <div>
                    <Select
                      name="tipo_pagamento_id"
                      loading={inLoad}
                      options={tiposPagamentos}
                      onSelectedOption={item =>
                        carregarCondicoesPagamento(item.value)
                      }
                    />
                  </div>
                </InputContainer>

                {!condicoesPagamentosLoading ? (
                  <InputContainer disabled={inLoad}>
                    <span>Condição de Pagamento</span>
                    <div>
                      <Select
                        name="condicao_pagamento_id"
                        EmptyOptionsMessage="Selecione o tipo de pagamento"
                        loading={inLoad}
                        options={condicoesPagamentos}
                      />
                    </div>
                  </InputContainer>
                ) : (
                  <Loader />
                )}

                <InputContainer width={250}>
                  <span>Valor R$ </span>
                  <div>
                    <InputNumber
                      disabled={inLoad}
                      name="valor"
                      placeholder="Valor"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>
            </FormContent>

            <Footer>
              <div>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={aoFecharFormaPagamento}
                >
                  Fechar
                </Button>
              </div>
              <div>
                <Button type="submit" background={colors.primary}>
                  Adicionar
                </Button>
              </div>
            </Footer>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default FormaPagamento;
