import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

  flex: ${props => (props.$width ? 0 : 1)};
  width: ${props => (props.$width ? `${props.$width}px` : 'auto')} !important;
  pointer-events: ${props => (props.$disabled ? 'none' : 'all')};
  opacity: ${props => (props.$disabled ? '0.2' : '1')};

  ${({ theme, $required }) => css`
    span {
      margin-bottom: 0.5rem;
      font-size: ${theme.fontSizes.default};
      color: ${!$required ? theme.colors.label : theme.colors.darkOrange};
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .switch {
      margin-top: 1rem;
    }
  `}

  @media (max-width: 1024px) {
    width: 100% !important;
    margin: 0rem;
    padding: 1rem;
  }
`;
