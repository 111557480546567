import React from 'react';

import { Container } from './styles';

import { Modal } from '~/components';

import { AjudaProps } from './interface';

const Ajuda: React.FC<AjudaProps> = ({ visible, onRequestClose }) => {
  return (
    <Modal
      title="AJUDA"
      visible={visible}
      onRequestClose={onRequestClose}
      width="100rem"
      scrollable
    >
      <Container>Opções de ajuda!</Container>
    </Modal>
  );
};

export default Ajuda;
