import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert2';

import { AiTwotoneSetting } from 'react-icons/ai';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { HiOutlineHand } from 'react-icons/hi';

import { useTheme } from 'styled-components';

import api from '~/services/api';

import {
  Container,
  Left,
  NameLogo,
  Right,
  ToolBar,
  ToolBarContent,
  ToolBtn,
  ToolLogo,
} from './styles';

import logoImg from '~/assets/logo.png';

import { Checkout, Loader, NoPermission } from '~/components';

import Empresa from './Empresa';
import EntradaDeItem from './EntradaDeItem';
import Itens from './Itens';
import Parceiros from './Parceiros';
import Resumo from './Resumo';

import Ajuda from './Ajuda';
import Caixa from './Caixa';
import Opcoes from './Opcoes';
import PosVenda from './PosVenda';

import { useAuth } from '~/hooks/auth';
import { useCart } from '~/hooks/cart';

import { handlerErrors } from '~/utils/error';

import { FormaPagamentoType } from '~/types';

import { ParamsProps } from './interface';
import { validarPermissao } from '~/utils/permissions';

const Pedido: React.FC = () => {
  const { colors } = useTheme();
  const { id } = useParams<ParamsProps>();

  const { user } = useAuth();

  const {
    items,
    cliente,
    vendedor,
    empresa_id,
    natureza_operacao_id,
    tabela_preco_id,
    conta_corrente_id,
    resetarCarrinho,
    editarPedido,
    calcularPrecoDesconto,
    calcularPrecoItems,
  } = useCart();

  const [loading, setLoading] = useState(false);

  const [opcoesVisibilidade, setOpcoesVisibilidade] = useState(false);
  const [ajudaVisibilidade, setAjudaVisibilidade] = useState(false);
  const [checkoutVisibilidade, setCheckoutVisibilidade] = useState(false);
  const [posVenda, setPosVenda] = useState<string | null>(null);

  // EDIÇÃO DO PEDIDO (ORÇAMENTO)
  const [fpgsIniciais, setFpgsIniciais] = useState<FormaPagamentoType[]>([]);

  const history = useNavigate();

  const backToERP = () => {
    history('/dashboard');
  };

  const abrirOpcoes = () => {
    setOpcoesVisibilidade(true);
  };

  const fecharOpcoes = () => {
    setOpcoesVisibilidade(false);
  };

  const abrirAjuda = () => {
    setAjudaVisibilidade(true);
  };

  const fecharAjuda = () => {
    setAjudaVisibilidade(false);
  };

  const fecharPosVenda = () => {
    setPosVenda(null);
  };

  const abrirCheckout = () => {
    if (!conta_corrente_id) {
      return swal.fire({
        title: `Você não possui nenhuma conta corrente aberta!`,
        icon: 'error',
      });
    }
    setCheckoutVisibilidade(true);
  };

  const fecharCheckout = () => {
    setCheckoutVisibilidade(false);
  };

  const salvarPedido = async (
    p_valor_sub_total: number,
    p_valor_total: number,
    p_desconto: number,
    p_origem: string,
    p_fpgs: Array<FormaPagamentoType>,
  ) => {
    try {
      const pedido_data = {
        digitador_id: `${user.parceiro_id}`,
        empresa_id: `${empresa_id}`,
        tabela_preco_id: `${tabela_preco_id}`,
        vendedor_id: `${vendedor ? vendedor.id : ''}`,
        status_id: null,
        natureza_operacao_id: `${natureza_operacao_id}`,
        cliente_id: `${cliente ? cliente.id : ''}`,
        cliente_nome: `${cliente ? cliente.nome_fantasia : ''}`,
        valor_produtos: p_valor_sub_total,
        porcentagem_desconto: 0,
        desconto: p_desconto,
        porcentagem_lucro: 0,
        lucro: 0,
        valor_total: p_valor_total,
        mesa: '',
        comanda: '',
        taxa_servico: 0,
        couver: 0,
        frete: 0,
        observacao: '',
        itens: [
          ...items.map(item => ({
            produto_id: `${item.produto.id}`,
            produto_descricao: `${item.produto.descricao}`,
            quantidade: item.qtd,
            custo: 0,
            preco: item.preco,
            total: item.preco * item.qtd,
            desconto: 0,
            porcentagem_desconto: 0,
            comissao: 0,
            porcentagem_comissao: 0,
            porcentagem_lucro: 0,
            lucro: 0,
            variacao_produto_id: null,
            variacao: '',
            unidade: 'UN',
            conversao: '',
            usuario_id: null,
            promocao: false,
            observacao: '',
          })),
        ],
        pagamento: {
          desconto: 0,
          conta_corrente_id: `${conta_corrente_id}`,
          formas: [
            ...p_fpgs.map(p_fpg => ({
              condicao_pagamento_id: `${p_fpg.condicao_pagamento.id}`,
              cheques: null,
              valor: p_fpg.valor,
            })),
          ],
        },
      };

      setLoading(true);
      fecharCheckout();

      if (id && id !== '0') {
        await api.put(`pedido/${id}`, pedido_data);

        swal.fire({
          title: `Pedido atualizado com sucesso!`,
          icon: 'success',
        });
      } else {
        const response = await api.post(`pedido`, pedido_data);
        const { data } = response;
        if (data) {
          const { id } = data;
          setPosVenda(id);
          resetarCarrinho();
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      swal.fire({
        title: `${handlerErrors(e)}`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    const carregarPedidoParaEdicao = async (pId: string) => {
      try {
        setLoading(true);
        const response = await api.get(`/pedido/${pId}`);
        const { data } = response;
        if (data && data.status !== 'error') {
          editarPedido(data);
          const { pagamento } = data;
          if (pagamento) {
            const { formas } = pagamento;
            if (formas && formas.length) {
              setFpgsIniciais([
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...formas.map((forma: any) => ({
                  ...forma,
                  taxa: forma.condicao_pagamento.taxa,
                  tipo_pagamento: {
                    tipo_pagamento_id:
                      forma.condicao_pagamento.tipo_pagamento_id,
                  },
                })),
              ]);
            }
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (id && id !== '0') {
      carregarPedidoParaEdicao(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'incluir-pedidos')) {
    return <NoPermission />;
  }

  return (
    <Container>
      {posVenda ? (
        <PosVenda idpedido={posVenda} onRequestClose={fecharPosVenda} />
      ) : null}

      <Opcoes visible={opcoesVisibilidade} onRequestClose={fecharOpcoes} />
      <Ajuda visible={ajudaVisibilidade} onRequestClose={fecharAjuda} />

      <Checkout
        visible={checkoutVisibilidade}
        onRequestClose={fecharCheckout}
        onRequestSave={salvarPedido}
        desconto={calcularPrecoDesconto()}
        valor_total={calcularPrecoItems()}
        origem="PEDIDO"
        fpgsIniciais={fpgsIniciais} // EDIÇÃO DO PEDIDO (ORÇAMENTO)
      />

      <ToolBar>
        <ToolBarContent>
          <ToolLogo onClick={backToERP}>
            <img src={logoImg} alt="Sisaut" />
            <NameLogo>
              <span>Sisaut</span>
              <span>ERP</span>
            </NameLogo>
          </ToolLogo>
          <ToolBtn onClick={abrirOpcoes}>
            <AiTwotoneSetting size={45} color={colors.label} />
            <span>Opções</span>
          </ToolBtn>

          <Caixa />

          <ToolBtn onClick={abrirAjuda}>
            <HiOutlineHand size={45} color={colors.label} />
            <span>Ajuda</span>
          </ToolBtn>
        </ToolBarContent>
        <ToolBtn onClick={backToERP}>
          <FaArrowCircleLeft size={45} color={colors.label} />
          <span>Sair</span>
        </ToolBtn>
      </ToolBar>
      <Left>
        <Empresa />
        <EntradaDeItem focar />
      </Left>
      <Right>
        <Parceiros />
        <Itens />
        <Resumo abrirCheckout={abrirCheckout} />
      </Right>
    </Container>
  );
};

export default Pedido;
