import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';
import { TextareaProps } from './interface';

const Textarea: React.FC<TextareaProps> = ({
  name,
  normalCase,
  containerStyle = {},
  icon: Icon,
  ...rest
}) => {
  const TextareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleTextareaBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!TextareaRef.current?.value);
  }, []);

  const handleTextareaFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextareaRef.current,
      // path: 'value',
      getValue: () => {
        return normalCase
          ? String(TextareaRef.current?.value)
          : String(TextareaRef.current?.value).toUpperCase();
      },
      clearValue: () => {
        if (TextareaRef.current) {
          TextareaRef.current.value = '';
        }
      },
    });
  }, [fieldName, registerField, normalCase]);

  return (
    <Container
      style={containerStyle}
      $iserrored={!!error}
      $isfilled={isFilled}
      $isfocused={isFocused}
    >
      {Icon && <Icon size={18} />}
      <textarea
        onFocus={handleTextareaFocus}
        onBlur={handleTextareaBlur}
        defaultValue={defaultValue}
        ref={TextareaRef}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Textarea;
