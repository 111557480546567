import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  float: left;
  flex-direction: row;

  form {
    display: flex;
    width: 100%;
    float: left;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.radio.default};
  }
`;
