import styled, { css } from 'styled-components';
import Tooltip from '~/components/Tooltip';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $iserrored, $isfocused, $isfilled, width }) => css`
    width: ${width ? `${width}px` : '100%'};
    min-width: 10rem;
    border-radius: ${theme.radio.default};
    border: 0.2rem solid ${theme.colors.defaultHighlight};
    display: flex;
    align-items: flex-start;

    svg {
      color: ${theme.colors.label};
    }

    & + div {
      margin-top: 0.8rem;
    }

    ${$iserrored &&
    css`
      border-color: #c53030;
    `}

    ${$isfocused &&
    css`
      color: ${theme.colors.controlFocus};
      border-color: ${theme.colors.primary};
      background: ${theme.colors.controlFocus};

      svg {
        color: ${theme.colors.default};
      }
    `}

    ${$isfilled &&
    css`
      color: ${theme.colors.primary};
    `}

    .select-component {
      width: 100%;

      > div {
        background: ${theme.colors.controlNotFocus} !important;
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        min-height: 4.6rem;
      }
    }

    .filter {
      &__indicator-separator {
        background: ${theme.colors.label} !important;
      }

      &__menu {
        /* background: ${theme.colors.secondary} !important; */
        margin-top: 0.3rem !important;
        padding: 0 !important;
        /* margin: 0.125rem auto; */
        /* border: 0.1rem solid ${theme.colors.label} !important; */
        /* box-shadow: ${theme.shadows.flat} !important; */
      }

      &__option {
        &--is-focused {
          /* background-color: ${theme.colors.houver}; */
        }
      }

      &__group {
        padding: 0;
      }
    }
  `}
`;

export const Error = styled(Tooltip)`
  height: 1.9rem;
  margin: 0 0.5rem;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.label};
    font-size: ${({ theme }) => theme.fontSizes.large};

    &::before {
      border-color: ${({ theme }) => theme.colors.error} transparent;
    }
  }
`;
