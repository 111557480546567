import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;
`;

export const Add = styled.div`
  float: left;
  margin-left: 1rem;
  width: 12rem;
`;

export const Search = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 17rem !important;
    background-color: ${theme.colors.orange};
    border-radius: ${theme.radio.default};
    transition: all 0.5s ease;
    height: 4.8rem;
    margin: 0 0 0 1rem !important;

    &:hover {
      background: ${darken(0.1, theme.colors.orange)};
    }
  `}
`;
