import React, { useRef, useEffect, useState, useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import { FiAlertCircle } from 'react-icons/fi';
import ReactInputMask from 'react-input-mask';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import { useField } from '@unform/core';
import { ptBR } from 'date-fns/locale';

import 'react-day-picker/dist/style.css';

import { InputProps } from './interface';

import { CalendarContainer, CalendarContent, Container, Error } from './styles';

const PartnerCalendar: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  defaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const refCalendar = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, registerField, error } = useField(name);
  const [date, setDate] = useState(defaultValue || null);

  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      const dateFormatted = defaultValue.split('-').reverse().join('/');
      setDate(dateFormatted);
    }
  }, [defaultValue]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      // path: 'value',
      getValue: (ref: any) => {
        if (ref.props.value === 'null') {
          return null;
        }

        if (ref.props.value.length) {
          return ref.props.value.split('/').reverse().join('-');
        }

        return '';
      },
    });
  }, [fieldName, registerField]);

  const handlerOpenCalendarModal = (): void => {
    setVisible(true);
  };

  const handlerCloseCalendarModal = (): void => {
    setVisible(false);
  };

  const handleClickOutside = (event: Event): void => {
    if (
      refCalendar.current &&
      !refCalendar.current.contains(event.target as Node)
    ) {
      handlerCloseCalendarModal();
    }
  };

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      handlerCloseCalendarModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  // const MONTHS: string[] = [
  //   'Janeiro',
  //   'Fevereiro',
  //   'Março',
  //   'Abril',
  //   'Maio',
  //   'Junho',
  //   'Julho',
  //   'Agosto',
  //   'Setembro',
  //   'Outubro',
  //   'Novembro',
  //   'Dezembro',
  // ];

  // const WEEKDAYS_LONG: string[] = [
  //   'Domingo',
  //   'Segunda',
  //   'Terça',
  //   'Quarta',
  //   'Quinta',
  //   'Sexta',
  //   'Sábado',
  // ];

  // const WEEKDAYS_SHORT: string[] = [
  //   'Dom',
  //   'Seg',
  //   'Ter',
  //   'Qua',
  //   'Qui',
  //   'Sex',
  //   'Sab',
  // ];

  const onChangeDate = useCallback((value: Date) => {
    setDate(value.toISOString().substr(0, 10).split('-').reverse().join('/'));
    handlerCloseCalendarModal();
  }, []);

  const css = `
    .rdp {   
      --rdp-cell-size: 40px; /* Size of the day cells. */   
      --rdp-caption-font-size: 18px; /* Font size for the caption labels. */   
      --rdp-accent-color: #0175a7; /* Accent color for the background of selected days. */   
      --rdp-background-color: #f9a22a; /* Background color for the hovered/focused elements. */   
      --rdp-accent-color-dark: #0175a7; /* Accent color for the background of selected days (to use in dark-mode). */   
      --rdp-background-color-dark: #0098da; /* Background color for the hovered/focused elements (to use in dark-mode). */   
      --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */   
      --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */   
      --rdp-selected-color: #fff; /* Color of selected day text */ 
    }
    .my-selected:not([disabled]) { 
      font-weight: bold; 
      border: 2px solid currentColor;
    }
    .my-selected:hover:not([disabled]) { 
      border-color: #9f4040;
      color: #9f4040;
    }
    .my-today { 
      font-weight: bold;
      font-size: 110%; 
      color: #0175a7;
    }
  `;

  return (
    <div style={{ flex: 1 }}>
      <Container
        style={containerStyle}
        $iserrored={!!error}
        $isfilled={isFilled}
        $isfocused={isFocused}
      >
        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={`${date}`}
          onChange={e => setDate(e.target.value)}
          // defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        <button type="button" onClick={handlerOpenCalendarModal}>
          <BiCalendar size={26} color={colors.label} />
        </button>
      </Container>
      <CalendarContainer $visible={visible}>
        <CalendarContent ref={refCalendar}>
          <header>
            <h1>{rest.placeholder}</h1>
            <button type="button" onClick={handlerCloseCalendarModal}>
              <AiOutlineCloseCircle size={32} color={colors.white} />
            </button>
          </header>
          <main>
            <style>{css}</style>
            <DayPicker
              mode="single"
              className="calendar"
              // months={MONTHS}
              // weekdaysLong={WEEKDAYS_LONG}
              // weekdaysShort={WEEKDAYS_SHORT}
              // firstDayOfWeek={1}
              modifiersClassNames={{
                selected: 'my-selected',
                today: 'my-today',
              }}
              locale={ptBR}
              onDayClick={onChangeDate}
              // modifiersStyles={{
              //   disabled: { fontSize: '75%' },
              // }}
            />
          </main>
        </CalendarContent>
      </CalendarContainer>
    </div>
  );
};

export default PartnerCalendar;
