import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { AiFillFileImage } from 'react-icons/ai';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';

import { useCart } from '~/hooks/cart';

import { getValidationErrors } from '~/utils';
import { InformacaoComercialType } from '~/types';
import { handlerToNumber } from '~/utils/money';

import {
  Loader,
  InputContainer,
  InputNumber,
  Input,
  Button,
} from '~/components';

import { Container, Content, Add, Image, Title } from './styles';

import { ItemProps, ItemFormData } from './interface';

const Item: React.FC<ItemProps> = ({
  pProduto,
  pQtd,
  pPreco,
  onRequestClose,
}) => {
  const { colors } = useTheme();

  const { adicionarItem, tabela_preco_id, empresa_id } = useCart();

  const formRef = useRef<FormHandles>(null);

  const [inLoad, setInLoad] = useState(true);

  const handlerFocusInQtd = () => {
    const inputQtdNode = document.querySelector<HTMLInputElement>(
      'input[name="quantidade"]',
    );
    if (inputQtdNode) {
      inputQtdNode.focus();
    }
  };

  const handlerQtdKeyDown = () => {
    if (formRef) {
      formRef?.current?.submitForm();
    }
  };

  const handlerSubmit = async (data: ItemFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        quantidade: Yup.number()
          .required()
          .typeError('Informe uma quantidade valida'),
        preco: Yup.string().required().typeError('Informe uma preço válido!'),
      });

      await schema.validate(data, { abortEarly: false });

      const { quantidade, preco } = data;

      const currentQtd = handlerToNumber(`${quantidade}`);
      const currentPrice = handlerToNumber(`${preco}`);

      if (currentQtd && currentPrice) {
        const id = `${
          Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000
        }${new Date().getTime()}`;

        adicionarItem({
          id,
          produto: pProduto,
          qtd: currentQtd,
          preco: currentPrice,
        });
        onRequestClose();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return null;
      }
    }
  };

  const fetchPrecoDoProduto = useCallback(async () => {
    try {
      if (!empresa_id || !tabela_preco_id) {
        return false;
      }

      setInLoad(true);

      const response = await api.get(
        `/produtos/${pProduto.id}/informacoes?empresa_id=${empresa_id}&tabela_preco_id=${tabela_preco_id}`,
      );

      const { data } = response;

      if (data && data.status !== 'error') {
        data.forEach((item: InformacaoComercialType) => {
          if (formRef) {
            setTimeout(() => {
              formRef.current?.setFieldValue(
                'preco',
                `${item.preco.toFixed(2).replace('.', ',')}`,
              );
            }, 500);
          }
        });
      }

      setInLoad(false);
    } catch (exception) {
      setInLoad(false);
    }
  }, [pProduto, empresa_id, tabela_preco_id]);

  useEffect(() => {
    fetchPrecoDoProduto();
  }, [fetchPrecoDoProduto]);

  useEffect(() => {
    if (inLoad) {
      setTimeout(() => {
        handlerFocusInQtd();
      }, 500);
    }
  }, [inLoad]);

  return (
    <Container>
      {inLoad ? <Loader /> : null}
      <Form
        ref={formRef}
        onSubmit={handlerSubmit}
        initialData={{
          quantidade: pQtd,
          preco: pPreco,
        }}
      >
        <Content>
          <InputContainer>
            <span>Quantidade</span>
            <div>
              <Input
                name="quantidade"
                type="number"
                placeholder="Informe a Quantidade"
                onKeyDownEnter={handlerQtdKeyDown}
                selectAllInFocus
              />
            </div>
          </InputContainer>
          <InputContainer>
            <span>Preço</span>
            <div>
              <InputNumber name="preco" placeholder="Informe o Preço" />
            </div>
          </InputContainer>
          <Add>
            <Button type="submit" background={colors.darkOrange}>
              <BsFillPlusCircleFill size={24} color="#fff" />
            </Button>
          </Add>
        </Content>
      </Form>

      <Image>
        {pProduto && pProduto.imagem ? (
          <img
            alt="Maior do Brasil"
            src="https://472841-1484254-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2021/03/086019400.jpg"
          />
        ) : (
          <AiFillFileImage size={120} color="#333" />
        )}
      </Image>
      <Title>{pProduto.descricao}</Title>
    </Container>
  );
};

export default Item;
