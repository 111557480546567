import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100vh;

    display: flex;
    align-items: stretch;

    background: ${theme.colors.background};
  `}
`;

export const Background = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* max-width: 70rem; */
`;

export const NameLogo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    span {
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.label};
      margin-top: 1rem;
      font-weight: bold;
    }
    span:nth-of-type(2n) {
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.orange};
      margin-top: 1rem;
      font-weight: bold;
    }
  `}
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    img {
      width: 12rem;
    }

    form {
      margin-top: 6rem;
      width: 34rem;
      text-align: center;

      h1 {
        font-size: ${theme.fontSizes.large};
        font-weight: bold;
        color: ${theme.colors.label};
        margin-bottom: 2.4rem;
      }

      button {
        margin-top: 2rem;
      }

      a {
        color: ${theme.colors.label};
        font-size: ${theme.fontSizes.default};
        display: block;
        margin-top: 2.8rem;
        text-decoration: underline;
        transition: color 0.2s;

        &:hover {
          color: ${shade(0.2, theme.colors.label)};
        }
      }
    }

    > a {
      color: ${theme.colors.primary};
      display: block;
      margin-top: 2.4rem;
      text-decoration: none;
      transition: color 0.2s;

      display: flex;
      align-items: center;

      svg {
        margin-right: 1.6rem;
      }

      &:hover {
        color: ${shade(0.2, theme.colors.primary)};
      }
    }
  `}
`;
