const makeParams = (
  page?: string,
  order?: Array<any>,
  filters?: Array<any>,
): Promise<string> => {
  return new Promise(resolve => {
    let params: Array<any> = [];

    if (filters && filters.length) {
      const outputFilters: Array<any> = [];
      filters.forEach(filterCurrent => {
        outputFilters.push(`${filterCurrent.column}=${filterCurrent.value}`);
      });
      params = outputFilters;
    }

    if (order && order.length) {
      const outputOrder: Array<any> = [];
      order.forEach(orderCurrent => {
        outputOrder.push(
          `${orderCurrent.direction === 'asc' ? '+' : '-'}${
            orderCurrent.column
          }`,
        );
      });
      params = [...params, `order_by=${outputOrder.join(',')}`];
    }

    if (page) {
      params = [...params, `page=${page}`];
    }

    const output = encodeURI(params.join('&'));
    resolve(output);
  });
};

export { makeParams };
