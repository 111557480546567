import React, { useEffect, useState } from 'react';

// import api from '~/services/api';

import { Container, List, Item } from './styles';

import { CheckoutProps } from './interface';

import { Modal } from '~/components';

import { CondicaoPagamentoType } from '~/types';

const CondicaoDePagamento: React.FC<CheckoutProps> = ({
  condicoesPagamento,
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  // const [loading, setLoading] = useState(true);

  const [condicoesDePagamentos, setCondicoesDePagamentos] = useState<
    Array<CondicaoPagamentoType>
  >([]);

  const handlerOnRequestClose = () => {
    onRequestClose();
  };

  const handlerOnRequestSave = (condicaoDePagamento: CondicaoPagamentoType) => {
    onRequestSave(condicaoDePagamento);
    onRequestClose();
  };

  // useEffect(() => {
  //   async function loadCondicoesDePagamentos() {
  //     try {
  //       setLoading(true);
  //       const response = await api.get(
  //         `financeiros/tipos-pagamento/${tipo_pagamento_id}/condicoes`,
  //       );
  //       setCondicoesDePagamentos(response.data.data);
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   }

  //   loadCondicoesDePagamentos();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visible]);

  useEffect(() => {
    setCondicoesDePagamentos(condicoesPagamento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condicoesPagamento]);

  // if (loading) return <Loader visible />;

  return (
    <Modal
      title="Selecione uma Condição de Pagamento"
      width="90rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <List>
          {condicoesDePagamentos
            .sort((a, b) =>
              a.descricao === b.descricao
                ? 0
                : a.descricao > b.descricao
                ? 1
                : -1,
            )
            .map(condicaoDePagamento => (
              <Item
                key={condicaoDePagamento.id}
                onClick={() => handlerOnRequestSave(condicaoDePagamento)}
              >
                {condicaoDePagamento.descricao}
              </Item>
            ))}
        </List>
      </Container>
    </Modal>
  );
};

export default CondicaoDePagamento;
