import React, { useState } from 'react';

import { CgArrowsVAlt } from 'react-icons/cg';

import {
  TagsAreaOffVisibility,
  TagsAreaOffContainer,
  TagsDropDownArea,
} from './styles';

import TagItem from '../TagItem';
import TagDrag from '../TagDrag';
import TagDrop from '../TagDrop';

import { TagsAreaOffProps, currentTagsType, TagProps } from './interface';

const TagsAreaOff: React.FC<TagsAreaOffProps> = ({
  tags,
  selectedTags,
  groupId,
  onSaveTag,
  visible,
}) => {
  const [tagsOff, setTagsOff] = useState<currentTagsType>(
    selectedTags &&
      selectedTags.filter(tag => tag.grupo_tag_id === groupId).length
      ? [
          ...tags.filter(tag => {
            if (selectedTags) {
              if (
                selectedTags.filter(selectedTag => selectedTag.id === tag.id)
                  .length
              ) {
                return false;
              }
              return true;
            }
            return true;
          }),
        ]
      : tags,
  );

  const handlerOnDropItemIn = (tag: TagProps): void => {
    if (tagsOff) {
      setTagsOff([...tagsOff.filter(currentTag => currentTag.id !== tag.id)]);
      onSaveTag(tag);
    }
  };

  const handlerAddItem = (tag: TagProps): void => {
    if (tagsOff) {
      setTagsOff([...tagsOff.filter(currentTag => currentTag.id !== tag.id)]);
      onSaveTag(tag);
    }
  };

  return (
    <TagsAreaOffVisibility $visible={visible}>
      <TagsAreaOffContainer>
        <h3>Tags</h3>
        <TagDrop name="tags-off" accept={`tag-off-${groupId}`}>
          <TagsDropDownArea>
            {tagsOff &&
              tagsOff.map(tag => (
                <TagDrag
                  key={tag.id}
                  type="tag-in"
                  tag={tag}
                  onDropItem={handlerOnDropItemIn}
                >
                  <TagItem tag={tag} cor={tag.cor} onAddItem={handlerAddItem} />
                </TagDrag>
              ))}

            {!tagsOff ||
              (tagsOff && !tagsOff.length && (
                <h4>Todas as tags foram adicionadas</h4>
              ))}
          </TagsDropDownArea>
        </TagDrop>
        <span>
          <CgArrowsVAlt size={26} color="#fff" />
        </span>
      </TagsAreaOffContainer>
    </TagsAreaOffVisibility>
  );
};

export default TagsAreaOff;
