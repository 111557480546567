import styled from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
  padding: 2rem;
  border: none;
  border-top: 0.4rem dashed ${({ theme }) => theme.colors.defaultHighlight};
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  float: left;

  form {
    > div {
      margin: 0rem 0 2rem 0;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: left;
  width: 100%;
  padding: 0rem 0;
`;

export const Count = styled.div`
  float: left;
  width: 100%;
  display: flex;
  padding: 1rem;
  border: none;
  border-radius: 0.8rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.defaultHighlight};
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.colors.darkOrange};
  font-size: ${({ theme }) => theme.fontSizes.default};
  color: ${({ theme }) => theme.colors.white};
  align-items: center;

  div {
    padding-left: 1rem;
    line-height: 1.6rem;
  }
`;

export const Help = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  float: left;
  width: 100%;
  padding: 0rem 0;
  border: 0.2rem solid ${({ theme }) => theme.colors.defaultHighlight};
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.colors.contrast};
  font-size: ${({ theme }) => theme.fontSizes.default};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  margin: 2rem 0 2rem 0;
  border-radius: 0.8rem;
  cursor: pointer;

  div {
    margin-left: 1rem;
  }
`;
