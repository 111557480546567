import React from 'react';

import { ListItemContainer } from './styles';

import { ListItemProps } from './interface';

const ListItem: React.FC<ListItemProps> = ({
  width,
  cor,
  children,
  headerType,
}) => {
  return (
    <ListItemContainer $cor={cor} $width={width} $headertype={headerType}>
      {children}
    </ListItemContainer>
  );
};

export default ListItem;
