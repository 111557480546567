import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    flex-direction: row;
    float: left;
    height: 6rem;
    line-height: 6rem;
    align-items: center;
    display: flex;
    transition: all 0.5s ease;
    padding: 0 1rem 0 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.secondary};
    margin-right: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.defaultHighlight};
    }

    .btRemove {
      float: left;
      width: 3rem;
      height: 3rem;
      background: #9f4040;
      border-radius: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      outline: none;
      transition: all 0.5s ease;

      &:hover {
        transform: rotate(90deg);
        opacity: 0.5;
      }
    }

    .btSearch {
      background: transparent;
      span {
        color: ${theme.colors.label};
        font-size: ${theme.fontSizes.default};
        font-weight: 500;
        margin-right: 4rem;
        cursor: pointer;
      }
    }
  `}
`;

export const HeaderSearchCacheContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    float: left;
    margin: 0;
    padding: 2rem 0;

    h3 {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.big};
      font-weight: 700;
      margin-bottom: 1rem;
    }

    h4 {
      color: ${theme.colors.placeholder};
      font-size: ${theme.fontSizes.default};
      margin-bottom: 2rem;
    }

    ul {
      width: 100%;
      float: left;
      max-width: 102.4rem;

      .toolsEmpty {
        color: ${theme.colors.placeholder};
        padding-top: 5.5rem !important;
      }
    }
  `}
`;
