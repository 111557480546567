import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { CartProvider } from './cart';

const AppProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <AuthProvider>
    <ToastProvider>
      <CartProvider>{children}</CartProvider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
