import React from 'react';

import { Title, Space } from './styles';

import Container from './Container';
import Accordion from './Accordion';

import Ordenadores from './Ordenadores';
import Agrupadores from './Agrupadores';
import Totalizadores from './Totalizadores';

import PDF from './PDF';

import { BarraLateralProps } from './interface';

const BarraLateral: React.FC<BarraLateralProps> = ({
  children,
  configuracao,
  aoAplicarConfiguracao,
  aoExportarRelatorio,
  barraSimples,
}) => {
  if (barraSimples) {
    return <Container>{children}</Container>;
  }

  if (configuracao && aoAplicarConfiguracao) {
    return (
      <Container>
        <Accordion
          nome="Filtros"
          descricao="Configure os filtros para refinar os resultados"
          modalSize="50rem"
          visibilidadeInicial
        >
          {children}
        </Accordion>

        {configuracao.ordenar.length ? (
          <Accordion
            nome="Ordenação"
            descricao="Selecione e arraste os campos de ordenação"
            modalSize="50rem"
            visibilidadeInicial={false}
          >
            <Ordenadores
              configuracao={configuracao}
              aoAplicarConfiguracao={aoAplicarConfiguracao}
            />
          </Accordion>
        ) : null}

        {configuracao.agrupar.length ? (
          <Accordion
            nome="Agrupadores"
            descricao="Configure os agrupadores no seu relatorio de exportação"
            modalSize="50rem"
            visibilidadeInicial={false}
          >
            <Agrupadores
              configuracao={configuracao}
              aoAplicarConfiguracao={aoAplicarConfiguracao}
            />
          </Accordion>
        ) : null}

        {configuracao.opcoesDeImpressao && aoExportarRelatorio ? (
          <Accordion
            nome="Opções de impressão"
            descricao="Configure as opções de impressão e gere um PDF do seu relatório"
            modalSize="50rem"
            visibilidadeInicial={false}
          >
            <>
              {configuracao.totalizar.length ? (
                <>
                  <Title>
                    <h1>Totalizadores</h1>
                    <p>Selecione os totalizadores que ficarão visiveis</p>
                  </Title>
                  <Totalizadores
                    configuracao={configuracao}
                    aoAplicarConfiguracao={aoAplicarConfiguracao}
                  />

                  <Space />
                </>
              ) : null}

              <Title>
                <h1>Modo</h1>
                <p>Selecione retrato ou paisagem</p>
              </Title>

              <PDF
                configuracao={configuracao}
                aoAplicarConfiguracao={aoAplicarConfiguracao}
                aoExportarRelatorio={aoExportarRelatorio}
              />
            </>
          </Accordion>
        ) : null}

        <Space />
        <Space />
        <Space />
        <Space />
      </Container>
    );
  }

  return null;
};

export default BarraLateral;
