import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTheme } from 'styled-components';

import { AiFillDelete } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { GoPencil } from 'react-icons/go';
import { MdEdit } from 'react-icons/md';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils';

import {
  Container,
  Content,
  ContentColumn,
  ContentMultiplas,
  ContentRow,
  Footer,
  FormContainer,
  IC,
  Info,
  InputContainer,
  InputsContainer,
  Item,
  Left,
  List,
  Right,
  Select,
  Summary,
} from './styles';

import {
  Button,
  InputNumber,
  Modal,
  ModalValor,
  ReactTooltip,
  Switch,
} from '~/components';

import CondicaoDePagamento from './CondicaoDePagamento';
import TipoDePagamento from './TipoDePagamento';

import { handlerNumberToString } from '~/utils/money';

import {
  CondicaoPagamentoType,
  FormaPagamentoType,
  TipoPagamentoType,
} from '~/types';

import { CheckoutProps } from './interface';

const Checkout: React.FC<CheckoutProps> = ({
  valor_total,
  desconto,
  origem,
  visible,
  onRequestClose,
  onRequestSave,
  fpgsIniciais,
}) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [valorPagar, setValorPagar] = useState(0);
  const [valorDigitado, setValorDigitado] = useState(0);
  const [troco, setTroco] = useState(0);
  const [multiplosTipos, setMultiplosTipos] = useState(false);
  const [valorPagarComAcrescimo, setValorPagarComAcrescimo] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [taxaPagamento, setTaxaPagamento] = useState(0);
  const [restante, setRestante] = useState(0);
  const [descontoPagamento, setDescontoPagamento] = useState(0);

  const [trocoVisibilidade, setTrocoVisibilidade] = useState(false);
  const [descontoPagamentoVisibilidade, setDescontoVisibilidadePagamento] =
    useState(false);
  const [tipoPagamentoVisibilidade, setTipoPagamentoVisibilidade] =
    useState(false);
  const [condicaoPagamentoVisibilidade, setCondicaoPagamentoVisibilidade] =
    useState(false);

  const [condicoesPagamento, setCondicoesPagamento] = useState<
    CondicaoPagamentoType[]
  >([]);

  const [tipoDePagamento, setTipoDePagamento] =
    useState<TipoPagamentoType | null>(null);

  const [condicaoDePagamento, setCondicaoDePagamento] =
    useState<CondicaoPagamentoType | null>(null);

  const [fpgs, setFpgs] = useState<FormaPagamentoType[]>([]);

  const abrirFormaPagamento = useCallback(() => {
    setTipoPagamentoVisibilidade(true);
  }, []);

  const fecharFormaPagamento = useCallback(() => {
    setTipoPagamentoVisibilidade(false);
  }, []);

  const abrirTroco = useCallback(() => {
    setTrocoVisibilidade(true);
  }, []);

  const fecharTroco = useCallback(() => {
    setTrocoVisibilidade(false);
  }, []);

  const abrirDescontoPagamento = useCallback(() => {
    setDescontoVisibilidadePagamento(true);
  }, []);

  const abrirCondicaoPagamento = useCallback(() => {
    setCondicaoPagamentoVisibilidade(true);
  }, []);

  const fecharCondicaoPagamento = useCallback(() => {
    setCondicaoPagamentoVisibilidade(false);
  }, []);

  const focarInputValor = useCallback(() => {
    const inputValorNode = document.querySelector<HTMLInputElement>(
      'input[name="valor"]',
    );
    if (inputValorNode) {
      inputValorNode.focus();
    }
  }, []);

  const calculaValorComAcrescimo = useCallback(
    (valor: number, taxa: number) => {
      let valor_com_taxa = valor;
      if (taxa > 0) {
        valor_com_taxa = Number(valor + valor * (taxa / 100));
      }

      setValorDigitado(valor);
      setValorPagarComAcrescimo(Number(valor_com_taxa.toFixed(2)));
    },
    [],
  );

  const salvarCondicaoPagamento = useCallback(
    (pCondicaoDePagamento: CondicaoPagamentoType) => {
      setCondicaoDePagamento(pCondicaoDePagamento);
      setValorPagar(restante);

      calculaValorComAcrescimo(restante, pCondicaoDePagamento.taxa);
    },
    [restante, calculaValorComAcrescimo],
  );

  const removerFormaPagamento = (key: number) => {
    setFpgs([...fpgs.filter((fpg, cKey) => cKey !== key)]);
  };

  const validarFormasPagamentos = () => {
    const totalFpgs = fpgs.reduce((acc, fpg) => {
      acc += fpg.valor;
      return acc;
    }, 0);

    if (
      totalFpgs >= valor_total - descontoPagamento + taxaPagamento - desconto ||
      (origem === 'BAIXA' && totalFpgs > 0)
    ) {
      return false;
    }
    return true;
  };

  const salvarTipoPagamento = useCallback(
    (pFormaDePagamento: TipoPagamentoType) => {
      setTipoDePagamento(pFormaDePagamento);
      setCondicoesPagamento(pFormaDePagamento.condicoes_pagamento);
      if (pFormaDePagamento.condicoes_pagamento.length === 1) {
        setCondicaoDePagamento(pFormaDePagamento.condicoes_pagamento[0]);
        setValorPagar(restante);

        calculaValorComAcrescimo(
          restante,
          pFormaDePagamento.condicoes_pagamento[0].taxa,
        );
      } else {
        setCondicaoPagamentoVisibilidade(true);
      }
    },
    [calculaValorComAcrescimo, restante],
  );

  const salvarTroco = useCallback(
    (valor: number) => {
      const trocoCalculado =
        valor - (valorTotal - descontoPagamento + taxaPagamento - desconto);
      setTroco(trocoCalculado);
    },
    [valorTotal, descontoPagamento, taxaPagamento, desconto],
  );

  const salvarDescontoPagamento = useCallback((valor: number) => {
    setDescontoPagamento(valor);
  }, []);

  const limpar = useCallback(() => {
    setValorPagar(0);
    setValorPagarComAcrescimo(0);
    setTroco(0);
    setTipoDePagamento(null);
    setCondicaoDePagamento(null);
  }, []);

  const adicionarFormaPagamento = useCallback(async () => {
    try {
      if (valorPagarComAcrescimo === 0) return;

      if (tipoDePagamento && condicaoDePagamento) {
        setFpgs([
          ...fpgs,
          {
            tipo_pagamento: tipoDePagamento,
            condicao_pagamento: condicaoDePagamento,
            valor: valorPagarComAcrescimo,
            taxa: Number((valorPagarComAcrescimo - valorDigitado).toFixed(2)),
          },
        ]);

        limpar();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return null;
      }
    }
  }, [
    limpar,
    condicaoDePagamento,
    tipoDePagamento,
    valorPagarComAcrescimo,
    fpgs,
    valorDigitado,
  ]);

  const gerenciarEnterNoInputValor = useCallback(() => {
    adicionarFormaPagamento();
  }, [adicionarFormaPagamento]);

  useEffect(() => {
    const totalFpgs = fpgs.reduce((acc, fpg) => {
      // eslint-disable-next-line no-param-reassign
      acc += fpg.valor;
      return acc;
    }, 0);

    const taxa_pagamento_calc = fpgs.reduce((acc, fpg) => {
      // eslint-disable-next-line no-param-reassign
      acc += fpg.taxa;
      return acc;
    }, 0);

    setTaxaPagamento(taxa_pagamento_calc);

    const troco_calc =
      totalFpgs >
      valorTotal - descontoPagamento - desconto + taxa_pagamento_calc
        ? valorTotal -
          descontoPagamento -
          desconto +
          taxa_pagamento_calc -
          totalFpgs
        : 0;

    setTroco(troco_calc);

    const restante_calc =
      totalFpgs > valorTotal - descontoPagamento - desconto
        ? 0
        : valorTotal - descontoPagamento - desconto - totalFpgs;

    setRestante(restante_calc);
  }, [desconto, fpgs, valorTotal, descontoPagamento]);

  useEffect(() => {
    if (!multiplosTipos) {
      adicionarFormaPagamento();
    } else {
      setTimeout(() => {
        focarInputValor();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorPagarComAcrescimo]);

  const gerenciarFechamentoCheckout = () => {
    formRef.current?.reset();
    setMultiplosTipos(false);
    limpar();
    onRequestClose();
  };

  const gerenciarSalvamentoCheckout = () => {
    onRequestSave(
      valor_total,
      valorTotal - descontoPagamento + taxaPagamento - desconto,
      desconto,
      origem,
      fpgs,
    );
  };

  const gerenciarAtalhosTeclado = (event: KeyboardEvent): void => {
    if (event.code === 'F3') {
      event.preventDefault();
      abrirTroco();
    } else if (event.code === 'F7') {
      event.preventDefault();
      gerenciarSalvamentoCheckout();
    } else if (event.code === 'F4') {
      event.preventDefault();
      gerenciarFechamentoCheckout();
    } else if (event.code === 'F2') {
      event.preventDefault();
      setMultiplosTipos(state => !state);
    } else if (event.code === 'F5') {
      event.preventDefault();
      abrirFormaPagamento();
    }
  };

  useEffect(() => {
    setFpgs([]);
  }, [visible]);

  useEffect(() => {
    if (fpgsIniciais && fpgsIniciais.length) {
      setFpgs([...fpgsIniciais]);
    }
  }, [fpgsIniciais]);

  useEffect(() => {
    setValorTotal(valor_total);
  }, [valor_total]);

  useEffect(() => {
    setValorPagarComAcrescimo(0);
  }, [multiplosTipos]);

  useEffect(() => {
    document.addEventListener('keydown', gerenciarAtalhosTeclado, true);
    return () => {
      document.removeEventListener('keydown', gerenciarAtalhosTeclado, true);
    };
  });

  return (
    <Modal
      title="Checkout"
      width="120rem"
      visible={visible}
      scrollable
      onRequestClose={gerenciarFechamentoCheckout}
    >
      <ModalValor
        name="desconto_pagamento"
        title="Valor de desconto no pagamento:"
        visible={descontoPagamentoVisibilidade}
        onRequestClose={() => setDescontoVisibilidadePagamento(false)}
        onRequestSave={salvarDescontoPagamento}
      />
      <ModalValor
        name="troco"
        title="Valor pago:"
        visible={trocoVisibilidade}
        onRequestClose={fecharTroco}
        onRequestSave={salvarTroco}
      />
      <TipoDePagamento
        visible={tipoPagamentoVisibilidade}
        onRequestClose={fecharFormaPagamento}
        onRequestSave={salvarTipoPagamento}
        origem={origem}
      />

      {tipoDePagamento ? (
        <CondicaoDePagamento
          condicoesPagamento={condicoesPagamento}
          // tipo_pagamento_id={tipoDePagamento.id}
          visible={condicaoPagamentoVisibilidade}
          onRequestClose={fecharCondicaoPagamento}
          onRequestSave={salvarCondicaoPagamento}
        />
      ) : null}

      <Container>
        <Summary>
          <Info>
            <small>Sub-total</small>
            <span>{`R$ ${handlerNumberToString(valor_total - desconto)}`}</span>
          </Info>
          <Info>
            <small>Taxa de pagamento</small>
            <span>{`R$ ${handlerNumberToString(taxaPagamento)}`}</span>
          </Info>
          <Info>
            <small>Desconto</small>
            <span>{`R$ ${handlerNumberToString(descontoPagamento)}`}</span>
          </Info>
          <Button
            className="checkout-btn-desconto"
            type="button"
            onClick={abrirDescontoPagamento}
            background={colors.primary}
            disabled={fpgs.length > 0}
          >
            <MdEdit size={24} color="#fff" />
          </Button>
          <Info color="#00B432">
            <small>Total</small>
            <span>
              {`R$ ${handlerNumberToString(
                valorTotal - descontoPagamento + taxaPagamento - desconto,
              )}`}
            </span>
          </Info>
        </Summary>
        <Content>
          <Left>
            <FormContainer>
              <Form ref={formRef} onSubmit={() => null}>
                <ContentColumn>
                  <ContentRow>
                    <h1>Adicionar pagamento</h1>
                    <ContentMultiplas>
                      <div className="switch" data-tooltip-id="multiplos">
                        <ReactTooltip
                          id="multiplos"
                          content="Multiplos tipos de pagamento"
                        />
                        <Switch
                          defaultValue={multiplosTipos}
                          onChange={setMultiplosTipos}
                          name="multiplos"
                        />
                      </div>
                      <span>
                        {origem === 'BAIXA'
                          ? 'Múltiplos ou parcial? [F2]'
                          : 'Múltiplos? [F2]'}
                      </span>
                    </ContentMultiplas>
                  </ContentRow>
                  <Select onClick={abrirFormaPagamento}>
                    <span>
                      {tipoDePagamento ? (
                        tipoDePagamento.descricao
                      ) : (
                        <ins>TIPO DE PAGAMENTO [F5]</ins>
                      )}
                    </span>
                    <GoPencil size={22} color={colors.label} />
                  </Select>
                  <Select
                    disabled={!tipoDePagamento}
                    onClick={abrirCondicaoPagamento}
                  >
                    <span>
                      {condicaoDePagamento ? (
                        condicaoDePagamento.descricao
                      ) : (
                        <ins>CONDIÇÃO DE PAGAMENTO</ins>
                      )}
                    </span>
                    <GoPencil size={22} color={colors.label} />
                  </Select>
                </ContentColumn>
                <ContentRow>
                  <InputsContainer>
                    <InputContainer>
                      <span>Valor</span>
                      <div>
                        <InputNumber
                          name="valor"
                          placeholder="Informe o Valor"
                          onKeyDownEnter={gerenciarEnterNoInputValor}
                          defaultValue={`${valorPagar}`}
                          onChange={value => {
                            calculaValorComAcrescimo(
                              Number(value.replace(',', '.')),
                              tipoDePagamento?.condicoes_pagamento[0].taxa || 0,
                            );
                          }}
                          selectAllInFocus
                        />
                      </div>
                    </InputContainer>
                    <InputContainer>
                      <span>Valor com Taxa</span>
                      <div>
                        <InputNumber
                          name="taxa"
                          placeholder="Taxa da condição selecionada"
                          disabled
                          value={`${
                            condicaoDePagamento ? valorPagarComAcrescimo : 0
                          }`}
                        />
                      </div>
                    </InputContainer>
                    <Button
                      name="checkout-btn-enviar"
                      type="button"
                      onClick={adicionarFormaPagamento}
                      disabled={!tipoDePagamento || !condicaoDePagamento}
                      background={colors.darkOrange}
                    >
                      Adicionar
                      <IC>
                        <BsFillPlusCircleFill size={22} color="#fff" />
                      </IC>
                    </Button>
                  </InputsContainer>
                </ContentRow>
              </Form>
            </FormContainer>

            <Summary>
              <Info color="#D82E2E">
                <small>Restante</small>
                <span>{`R$ ${handlerNumberToString(restante)}`}</span>
              </Info>
              <Info color="#00AAE0">
                <small>Troco</small>
                <span>{`R$ ${handlerNumberToString(troco)}`}</span>
              </Info>
              <Button
                className="checkout-btn-troco"
                type="button"
                onClick={abrirTroco}
                background={colors.primary}
              >
                <span>[F3]</span>
              </Button>
            </Summary>
          </Left>

          <Right>
            <h1>Adicionadas</h1>
            <List>
              {fpgs.map((fpg, key) => (
                <Item key={`${fpg}`}>
                  <div>
                    <span>{fpg.tipo_pagamento.descricao}</span>
                    <small>{fpg.condicao_pagamento.descricao}</small>
                  </div>
                  <h2>{`R$ ${handlerNumberToString(fpg.valor)}`}</h2>
                  <button
                    type="button"
                    onClick={() => removerFormaPagamento(key)}
                  >
                    <AiFillDelete size={22} color="#fff" />
                  </button>
                </Item>
              ))}
            </List>
          </Right>
        </Content>

        <Footer>
          <div>
            <Button
              type="button"
              background={colors.grey}
              onClick={gerenciarFechamentoCheckout}
            >
              Cancelar [F4]
            </Button>
          </div>
          <div>
            <Button
              type="button"
              background={colors.grey}
              onClick={() => {
                setFpgs([]);
                limpar();
              }}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              type="button"
              disabled={validarFormasPagamentos()}
              onClick={gerenciarSalvamentoCheckout}
              background={colors.green}
            >
              Salvar [F7]
            </Button>
          </div>
        </Footer>
      </Container>
    </Modal>
  );
};

export default Checkout;
