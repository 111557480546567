import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import Switch from 'react-switch';

import { SwitchProps } from './interface';

const SwitchOption: React.FC<SwitchProps> = ({
  name,
  disabled,
  defaultValue,
  onChange,
  ...rest
}) => {
  const switchRef = useRef(null);
  const { fieldName, defaultValue: defaultV, registerField } = useField(name);

  const [value, setValue] = useState(false);

  const handlerOnChange = (checked: boolean) => {
    setValue(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef.current,
      path: 'props.checked',
      clearValue: () => {
        setValue(false);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultV !== undefined) {
      setValue(defaultV);
    }
  }, [defaultV]);

  return (
    <Switch
      onChange={handlerOnChange}
      checked={value}
      ref={switchRef}
      disabled={disabled}
      {...rest}
    />
  );
};

export default SwitchOption;
