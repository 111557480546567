import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { HiMenu } from 'react-icons/hi';
import {
  HeaderSummaryContainer,
  HeaderSummaryContent,
  HeaderSummaryControl,
  HeaderSummaryControlIc,
} from './styles';

const HeaderSummary: React.FC = () => {
  const { colors } = useTheme();
  const [showContent, setShowContent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setShowContent(false);
    }
  };

  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <HeaderSummaryContainer ref={ref}>
      <HeaderSummaryControl onClick={() => setShowContent(!showContent)}>
        <HeaderSummaryControlIc>
          <HiMenu size={22} color={colors.label} />
        </HeaderSummaryControlIc>
      </HeaderSummaryControl>
      <HeaderSummaryContent $visible={showContent}>
        <span>Nenhuma novidade hoje!</span>
      </HeaderSummaryContent>
    </HeaderSummaryContainer>
  );
};

export default HeaderSummary;
