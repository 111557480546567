import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  align-items: center;
  justify-content: center;

  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  width: 100%;

  padding: 2rem 0;

  max-width: 520px;

  display: flex;
  flex-direction: column;

  div + div {
    margin-top: 1.5rem;
  }
`;

export const ButtonsGroup = styled.div`
  width: 100%;

  padding: 1.25rem 1rem;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 1em;
`;
