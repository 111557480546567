import styled from 'styled-components';

import { ContentProps } from './interface';

export const Container = styled.div`
  width: 100%;
  float: left;
  flex-direction: column;
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 5px 5px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 2rem 2rem 0 2rem;
  background: #00bea4;
  background: #333;
  margin: 2rem;
  border-radius: 1rem;
  overflow: hidden;

  height: ${props => (props.visible ? 'auto' : '7rem')};

  h1 {
    font-size: 2rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    border-color: ${props =>
      props.visible ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0)'};
    padding: 0 0 1rem 0;
    margin: 0 0 2rem 0;
  }

  p {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
  }

  .summary-infos {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 2rem 0;

    p {
      width: 50%;
      flex-direction: row;
      margin: 0 0 0.1rem 0;
      align-items: center;

      small {
        font-size: 1.4rem;

        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1)
        );

        padding: 1rem;
        width: 40%;
        margin-right: 1rem;
        text-align: right;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.8);
        border-radius: 0.5rem;
      }

      strong {
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }

  .summary-vals {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    border-top: 2px solid rgba(255, 255, 255, 0.4);
    padding: 2rem 0 2rem 0;

    p {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 1px 0;

      small {
        font-size: 1.4rem;
        margin: 0 1rem 0 0;
        color: rgba(255, 255, 255, 0.8);
      }

      strong {
        font-size: 1.4rem;
        width: 16rem;

        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1)
        );

        border-radius: 0.5rem;
        padding: 1rem;
      }
    }
  }

  @media (max-width: 1024px) {
    .summary-infos,
    .summary-vals {
      flex-direction: column;

      p {
        width: 100%;

        small {
          font-size: 1rem;
        }
        strong {
          font-size: 1rem;
        }
      }
    }
  }
`;

export const Control = styled.button.attrs({
  type: 'button',
})`
  float: right;
  display: flex;
  position: relative;
  align-self: flex-end;
  width: 5rem;
  height: 5rem;
  margin: -5rem 1rem 0 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #20c05c;
  transition: all 0.5s ease;

  &:hover {
    background: #333;
  }
`;
