import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import swal from 'sweetalert2';

import { useTheme } from 'styled-components';
import { BsFillTriangleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader } from '~/components';

import { EtiquetaType } from '~/types';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

const Etiquetas: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();

  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [etiquetas, setetiquetas] = useState<Array<EtiquetaType>>([]);

  const fetchEtiquetas = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('produtos/etiquetas');
      setetiquetas(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  }, []);

  const handlerRemoveEtiqueta = useCallback(
    (etiqueta: EtiquetaType) => {
      swal
        .fire({
          title: `Deseja realmente remover o padrão de impressão "${etiqueta.nome}"?`,
          text: `Ao confirmar, o padrão de impressão será removido do sistema e não sera mais possível recupera-lo`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          width: 'auto',
        })
        .then(async result => {
          if (result.isConfirmed) {
            if (result.isConfirmed) {
              try {
                await api.delete(`etiqueta/${etiqueta.id}`);
                await fetchEtiquetas();
              } catch (e) {
                // console.log(e);
              }
              addToast({
                type: 'success',
                title: 'Concluido',
                description: `padrão de impressão "${etiqueta.nome}" deletado com sucesso!`,
              });
            }
          }
        });
    },
    [fetchEtiquetas, addToast],
  );

  const handlerEditEtiqueta = useCallback(
    (etiqueta: EtiquetaType) => {
      history(`/etiqueta/${etiqueta.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchEtiquetas();
  }, [fetchEtiquetas]);

  return (
    <Container>
      <Loader visible={loading} />
      <Header />
      <Content>
        <Plate
          title="Etiquetas (Modelos de Impressão)"
          subtitle="Cadastre seus modelos de impressão de etiquetas"
          icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
        >
          <Link to="/etiqueta/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {etiquetas ? (
          <List
            header={[
              {
                column: 'id',
                label: 'ID',
                width: 50,
              },
              {
                column: 'nome',
                label: 'Nome',
              },
              {
                column: 'padrao',
                label: 'Padrão',
              },
            ]}
            data={etiquetas}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditEtiqueta(item);
                },
              },
              {
                label: 'Remover',
                onPress: item => {
                  handlerRemoveEtiqueta(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default Etiquetas;
