import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  button {
    width: 15rem;
  }
`;
