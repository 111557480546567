import React, { useState, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { GoPencil } from 'react-icons/go';
import { RiWallet3Line } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';

import {
  Container,
  Left,
  LeftContent,
  Info,
  Total,
  Right,
  CheckoutBtn,
} from './styles';

import Desconto from './Desconto';

import { useCart } from '~/hooks/cart';

import { handlerNumberToString } from '~/utils/money';

import { ResumoProps } from './interface';

const Resumo: React.FC<ResumoProps> = ({ abrirCheckout }) => {
  const { colors } = useTheme();

  const {
    calcularPrecoItems,
    calcularPrecoDesconto,
    calcularPrecoCarrinho,
    desconto,
    setDesconto,
  } = useCart();

  const [descontoVisibilidade, setDescontoVisibilidade] = useState(false);

  const abrirDesconto = () => {
    setDescontoVisibilidade(true);
  };

  const fecharDesconto = () => {
    setDescontoVisibilidade(false);
  };

  const removerDesconto = () => {
    setDesconto(0);
  };

  const teclasDeAtalho = (event: KeyboardEvent): void => {
    if (event.ctrlKey && event.code === 'KeyS') {
      event.preventDefault();
      abrirCheckout();
    }
    if (event.ctrlKey && event.code === 'KeyD') {
      event.preventDefault();
      abrirDesconto();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', teclasDeAtalho, true);
    return () => {
      document.removeEventListener('keydown', teclasDeAtalho, true);
    };
  });

  return (
    <Container>
      <Desconto
        visible={descontoVisibilidade}
        onRequestClose={fecharDesconto}
      />

      <Left>
        <LeftContent>
          <div>
            <Info>
              <span>Sub Total</span>
              <h1>{`R$ ${handlerNumberToString(calcularPrecoItems())}`}</h1>
            </Info>
          </div>
          <div>
            <Info>
              <span>Desconto (Ctrl + D)</span>
              <h1>{`R$ ${handlerNumberToString(calcularPrecoDesconto())}`}</h1>
            </Info>
            <button type="button" onClick={abrirDesconto}>
              <GoPencil size={22} color={colors.label} />
            </button>
            {desconto ? (
              <button type="button" onClick={removerDesconto}>
                <AiFillDelete size={22} color={colors.label} />
              </button>
            ) : null}
          </div>
        </LeftContent>
        <Total>
          <span>Total</span>
          <h1>{`R$ ${handlerNumberToString(calcularPrecoCarrinho())}`}</h1>
        </Total>
      </Left>
      <Right>
        <CheckoutBtn onClick={abrirCheckout}>
          <RiWallet3Line size={52} color={colors.label} />
          <span>Receber</span>
          <small>Ctrl + S</small>
        </CheckoutBtn>
      </Right>
    </Container>
  );
};

export default Resumo;
