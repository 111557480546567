import React, { useEffect } from 'react';

import { AiFillCloseCircle } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';

import { ErrorMessageContainer, ErrorMessageContent } from './styles';

import { ErrorMessageProps } from './interface';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, onDismiss }) => {
  useEffect(() => {
    if (error && error !== '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [error]);

  if (!error || error.length <= 0) return null;
  return (
    <ErrorMessageContainer>
      <ErrorMessageContent>
        <RiErrorWarningFill size={32} color="#ef524f" />
        <div>
          <span>{error}</span>
        </div>
        <button type="button" onClick={onDismiss}>
          <AiFillCloseCircle size={48} color="#ef524f" />
        </button>
      </ErrorMessageContent>
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
