import styled, { css } from 'styled-components';
import Tooltip from '~/components/Tooltip';

import { PCContainerProps, ContainerProps } from './interface';

export const CalendarContainer = styled.div<PCContainerProps>`
  ${({ theme, $visible }) => css`
    flex: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0rem;
    top: 0rem;
    background: ${theme.colors.overlay};
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? '$visible' : 'hidden'};

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const CalendarContent = styled.div`
  ${({ theme }) => css`
    width: 43rem;
    border-radius: 0.6rem;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    display: flex;
    overflow: hidden;
    flex-direction: column;

    header {
      width: 100%;
      float: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;
      background: ${theme.colors.primaryDark};

      h1 {
        font-size: ${theme.fontSizes.big};
        margin: 0rem;
        padding: 0rem;
        color: ${theme.colors.white};
      }

      button {
        margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease;
        background: none;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .calendar {
      font-size: 2rem;
    }

    .DayPicker-Day {
      padding: 1rem 1.5rem;
      border-radius: 10%;
      &:hover {
        color: ${theme.colors.primaryDark};
      }
    }
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${({ theme, $iserrored, $isfocused, $isfilled }) => css`
    background: ${theme.colors.controlNotFocus};
    border-radius: ${theme.radio.default};
    padding: 1rem;
    width: 100%;

    border: 0.2rem solid ${theme.colors.defaultHighlight};
    color: #666360;

    display: flex;
    align-items: center;

    & + div {
      /* margin-top: 0.8rem; */
    }

    button {
      margin-left: 1rem;
      cursor: pointer;
      transition: all 0.5s ease;
      background: none;
      margin-left: -10rem;

      &:hover {
        opacity: 0.6;
      }
    }

    ${$iserrored &&
    css`
      border-color: #c53030;
    `}

    ${$isfocused &&
    css`
      color: ${theme.colors.controlFocus};
      border-color: ${theme.colors.primary};
      background: ${theme.colors.controlFocus};
    `}

    ${$isfilled &&
    css`
      color: ${theme.colors.primary};
    `}

    input {
      flex: 1;
      background: transparent;
      border: 0;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};

      &::placeholder {
        color: ${theme.colors.placeholder};
      }

      &:-webkit-autofill {
        border: none;
        -webkit-box-shadow: 0 0 0rem 100rem ${theme.colors.secondary} inset;
        -webkit-text-fill-color: ${theme.colors.label};
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  `}
`;

export const Error = styled(Tooltip)`
  height: 1.9rem;
  margin-left: 1.3rem;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.label};

    &::before {
      border-color: ${({ theme }) => theme.colors.error} transparent;
    }
  }
`;
