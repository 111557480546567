import styled from 'styled-components';
import { AnimatedContainer } from '~/components';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;
  }
`;
