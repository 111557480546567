import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import slugify from 'slugify';
import swal from 'sweetalert2';

import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import api from '~/services/api';

import Loader from '~/components/Loader';

import {
  Button,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
} from '~/components';

import {
  Content,
  FormBlock,
  FormContainer,
  FormSubmitContainer,
  InputsDivisor,
} from './styles';

import { ParamsProps } from './interface';
import { handlerErrors } from '~/utils/error';

const IFC: React.FC<ParamsProps> = ({
  visible,
  selectedId,
  onRequestClose,
  onSave,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [inLoad, setInLoad] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [registerId, setRegisterId] = useState('0');

  const formRef = useRef<FormHandles>(null);

  function clearForm() {
    setRegisterData({});
    formRef.current?.reset();
  }

  useEffect(() => {
    async function loadRegister() {
      setRegisterId(selectedId);
      if (selectedId !== '0') {
        const response = await api.get(`acl/papeis/${selectedId}`);
        setRegisterData(response.data);
        setInLoad(false);
      } else {
        clearForm();
        setInLoad(false);
      }
    }

    loadRegister();
  }, [selectedId]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Informe a nome do perfil'),
          // descricao: Yup.string().required('Informe a descrição do perfil'),
          // slug: Yup.string().required('Informe um slug para o perfil'),
        });

        await schema.validate(data, { abortEarly: false });

        setInLoad(true);

        const perfilSlug = slugify(data.nome, '_');

        const aclData = { ...data, slug: perfilSlug };

        try {
          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `acl/papeis${registerId !== '0' ? `/${registerId}` : ''}`,
            aclData,
          );

          setRegisterId(response.data.id);

          setInLoad(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Papel salvo com sucesso!',
          });

          onSave();
          onRequestClose();
        } catch (e) {
          const message = handlerErrors(e);

          swal.fire({
            title: 'Oops...',
            text: message
              ? `${message}`
              : 'Falha não identificada no cadastro de papel',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          });
        }
      } catch (err) {
        setInLoad(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Ocorreu um erro salvar o registro, tente novamente...',
        });
      }
    },
    [addToast, onSave, registerId, onRequestClose],
  );

  if (!visible) return null;

  return (
    <Modal
      title="Perfil"
      visible={visible}
      onRequestClose={onRequestClose}
      width="70rem"
      scrollable
    >
      <Content>
        <Loader visible={inLoad} />
        <FormContainer>
          <FormBlock>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Nome para o perfil</span>
                  <div>
                    <Input
                      name="nome"
                      placeholder="Infome um nome para o Perfil"
                      required
                    />
                  </div>
                </InputContainer>
                {/* <InputContainer disabled={inLoad}>
                  <span>Slug</span>
                  <div>
                    <Input
                      name="slug"
                      placeholder="Infome um slug para o Perfil"
                      required
                    />
                  </div>
                </InputContainer> */}
              </InputsContainer>
              <InputsContainer>
                <InputContainer disabled={inLoad}>
                  <span>Descrição (Opcional)</span>
                  <div>
                    <Input
                      name="descricao"
                      placeholder="Infome uma descrição para o Perfil"
                    />
                  </div>
                </InputContainer>
              </InputsContainer>

              <InputsDivisor>
                <span />
              </InputsDivisor>

              <FormSubmitContainer>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  background={colors.green}
                  loading={inLoad}
                >
                  Salvar
                </Button>
              </FormSubmitContainer>
            </Form>
          </FormBlock>
        </FormContainer>
      </Content>
    </Modal>
  );
};

export default IFC;
