import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FaTags } from 'react-icons/fa';

import { useAuth } from '~/hooks/auth';

// import swal from 'sweetalert2';
// import { useToast } from '~/hooks/toast';

import { Container, Content, Empty } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';
import { Plate, Button, Loader, NoPermission } from '~/components';

import api from '~/services/api';

import { GrupotagsType } from '~/types';

import { ParamsProps } from './interface';
import { validarPermissao } from '~/utils/permissions';

const GroupsTags: React.FC = () => {
  const { tipo } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { user } = useAuth();
  // const { addToast } = useToast();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);
  const [gruposdetags, setGruposdetags] = useState<GrupotagsType[]>([]);

  const fetchGroupsTags = useCallback(
    // async (page?: string, order?: Array<any>, filters?: Array<any>) => {
    async () => {
      setLoading(true);
      setGruposdetags([]);
      try {
        const response = await api.get(`grupo-tags?tipo=${tipo}`);
        setGruposdetags(response.data);
      } catch (e) {
        // console.log(e);
      }
      setLoading(false);
    },
    [tipo],
  );

  // const handlerRemoveGroup = (grupo: GrupotagsType) => {
  //   swal
  //     .fire({
  //       title: `Deseja realmente remover o grupo "${grupo.nome}"?`,
  //       text: `Ao confirmar, o grupo e suas tags serão removidos!`,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Sim',
  //       cancelButtonText: 'Não',
  //     })
  //     .then(async result => {
  //       if (result.isConfirmed) {
  //         try {
  //           await api.delete(`grupo-tags/${grupo.id}`);
  //           await fetchGroupsTags();
  //         } catch (e) {
  //           // console.log(e);
  //         }
  //         addToast({
  //           type: 'success',
  //           title: 'Concluido',
  //           description: `Grupo "${grupo.nome}" deletado com sucesso!`,
  //         });
  //       }
  //     });
  // };

  const handlerEditGroup = useCallback(
    (grupotag: GrupotagsType) => {
      history(`/grupo-de-tags/${tipo}/${grupotag.id}`);
    },
    [tipo, history],
  );

  useEffect(() => {
    fetchGroupsTags();
  }, [fetchGroupsTags]);

  function permissaoNome(tipo: string | undefined) {
    if (tipo == 'produto') {
      return 'tags-produtos';
    } else if (tipo == 'parceiro') {
      return 'tags-parceiros';
    }

    return '';
  }

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, permissaoNome(tipo))) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Plate
          title="Grupos de Tags"
          subtitle="Administre seus grupos de tags"
          icon={() => <FaTags size={32} color={colors.label} />}
        >
          <Link to={`/grupo-de-tags/${tipo}/0`}>
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {gruposdetags.length ? (
          <List
            onFetch={fetchGroupsTags}
            header={[
              {
                column: 'nome',
                label: 'Nome',
              },
            ]}
            data={gruposdetags}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditGroup(item);
                },
              },
              // {
              //   label: 'Remover',
              //   onPress: item => {
              //     handlerRemoveGroup(item);
              //   },
              // },
            ]}
          />
        ) : (
          <Empty>Nenhum grupo de tags encontrado.</Empty>
        )}
      </Content>
    </Container>
  );
};

export default GroupsTags;
