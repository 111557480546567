import React, { useRef, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { AiOutlineCloseCircle } from 'react-icons/ai';

import { ModalContainer, ModalContent, Scroll, ScrollContent } from './styles';

import { ModalProps } from './interface';

const Modal: React.FC<ModalProps> = ({
  visible,
  title,
  subtitle,
  children,
  width,
  onRequestClose,
  disableOverflow,
  closeButtonVisible = true,
}) => {
  const { colors } = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        document.body.className = '';
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        document.body.className = '';
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  useEffect(() => {
    document.body.className = visible ? 'lock' : '';
  }, [visible]);

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.body.className = '';
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handlerHideContent, handleClickOutside]);

  return (
    <ModalContainer $visible={visible} $disableoverflow={!!disableOverflow}>
      <Scroll $disableoverflow={!!disableOverflow}>
        <ScrollContent>
          <ModalContent
            $width={width}
            ref={ref}
            $disableoverflow={!!disableOverflow}
          >
            <header>
              <div>
                <h1>{title || ''}</h1>
                {subtitle ? <h2>{subtitle}</h2> : null}
              </div>
              {closeButtonVisible && (
                <button type="button" onClick={onRequestClose}>
                  <AiOutlineCloseCircle size={32} color={colors.white} />
                </button>
              )}
            </header>
            <main>{children}</main>
          </ModalContent>
        </ScrollContent>
      </Scroll>
    </ModalContainer>
  );
};

export default Modal;
