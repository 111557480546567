import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  float: left;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
    width: 100%;

    button {
      margin-top: 1rem;
    }
  }
`;
