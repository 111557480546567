import React, { useEffect, useState, useRef } from 'react';
import { FiSettings } from 'react-icons/fi';

import { ListItemMenuContainer, ListItemMenuContent } from './styles';

import { ListItemMenuProps } from './interface';

const ListItemMenu: React.FC<ListItemMenuProps> = ({
  label,
  title,
  children,
  invisible,
  setInvisible,
  inModal,
}) => {
  const [showContent, setShowContent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setShowContent(false);
    }
  };

  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowContent(false);
    }
  };

  const showVisibility = (): void => {
    setShowContent(true);
    setInvisible(false);
  };

  const hideVisibility = (): void => {
    setShowContent(false);
  };

  useEffect(() => {
    invisible && hideVisibility();
  }, [invisible]);

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <ListItemMenuContainer $inmodal={inModal}>
      <button type="button" onClick={showVisibility} value={label}>
        {label || <FiSettings />}
      </button>
      {showContent ? (
        <ListItemMenuContent $inmodal={inModal} $visible={showContent}>
          <main ref={ref}>
            {title ? <h1>{title}</h1> : null}
            <div>{children}</div>
            <button className="btclose" type="button" onClick={hideVisibility}>
              Fechar
            </button>
          </main>
        </ListItemMenuContent>
      ) : null}
    </ListItemMenuContainer>
  );
};

export default ListItemMenu;
