import styled, { css } from 'styled-components';

export const ListArea = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 2rem; */
`;

export const ListAreaEmptyInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding: 2rem;
  width: 100%;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    height: 2rem;
    background-color: ${theme.colors.footer};
    border-bottom-left-radius: ${theme.radio.default};
    border-bottom-right-radius: ${theme.radio.default};
    box-shadow: ${theme.shadows.highlight};
  `}
`;
