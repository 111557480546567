import React, { useState, useEffect, useCallback } from 'react';

import { BiCoinStack } from 'react-icons/bi';
import { IoMdDocument } from 'react-icons/io';

import { useTheme } from 'styled-components';

import api from '~/services/api';

import { makeParams } from '~/utils/parametrizer';
import { formatCustomBR } from '~/utils/formatDate';

import {
  Container,
  Header,
  Content,
  Infos,
  Info,
  IC,
  InfoContent,
  Footer,
} from './styles';

import { TimeLineProps, ResponseType } from './interface';

import { Modal, Loader, Button } from '~/components';

import List from '~/components/List';

import Filtragem from './Filtragem';

const TimeLine: React.FC<TimeLineProps> = ({
  visible,
  estoque,
  onRequestClose,
}) => {
  const { colors } = useTheme();

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<ResponseType | null>(null);

  const [data_inicial, set_data_inicial] = useState<string | null>(null);
  const [data_final, set_data_final] = useState<string | null>(null);

  const [filtragemModalVisible, setFiltragemModalVisible] = useState(false);

  const handlerFiltragemModalClose = () => {
    setFiltragemModalVisible(false);
  };

  const handlerFiltragemModalOpen = () => {
    setFiltragemModalVisible(true);
  };

  const handlerFiltragemOnSave = (
    p_data_inicial: string,
    p_data_final: string,
  ) => {
    set_data_inicial(p_data_inicial);
    set_data_final(p_data_final);
  };

  const fetchLogs = useCallback(
    async (page?: string, order?: Array<any>, filters?: Array<any>) => {
      try {
        setLoading(true);

        let allFilters: Array<any> = [
          { column: 'empresa_id', value: estoque.empresa_id },
          { column: 'produto_id', value: estoque.produto_id },
        ];

        if (filters) {
          allFilters = [...allFilters, ...filters];
        }

        if (data_inicial) {
          allFilters = [
            ...allFilters,
            { column: 'inicio', value: data_inicial },
          ];
        }

        if (data_final) {
          allFilters = [...allFilters, { column: 'fim', value: data_final }];
        }

        const params = await makeParams(page, order, allFilters);
        const url = `produtos/estoques/logs?${params}`;
        const response = await api.get(url);
        setLogs(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [estoque, data_inicial, data_final],
  );

  useEffect(() => {
    fetchLogs('', [], []);
  }, [fetchLogs]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title={`${estoque.empresa.fantasia}`}
      width="100rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Filtragem
        onRequestClose={handlerFiltragemModalClose}
        visible={filtragemModalVisible}
        onRequestSave={handlerFiltragemOnSave}
        haveRegisters
      />

      <Container>
        <Header>
          <Infos>
            <Info>
              <IC>
                <BiCoinStack size={32} color={colors.label} />
              </IC>
              <InfoContent>
                <span>FÍSICO</span>
                <strong>{estoque.fisico}</strong>
              </InfoContent>
            </Info>
            <Info>
              <IC>
                <IoMdDocument size={32} color={colors.label} />
              </IC>
              <InfoContent>
                <span>CONTABIL</span>
                <strong>{estoque.contabil}</strong>
              </InfoContent>
            </Info>
          </Infos>
          <div>
            <Button
              type="button"
              background={colors.secondary}
              onClick={handlerFiltragemModalOpen}
            >
              Filtrar
            </Button>
          </div>
        </Header>
        <Content>
          {logs ? (
            <List
              onFetch={fetchLogs}
              header={[
                {
                  column: 'data',
                  label: 'DATA',
                },
                {
                  column: 'quantidade',
                  label: 'QUANTIDADE',
                },
                {
                  column: 'operacao',
                  label: 'OPERAÇÃO',
                },
                // {
                //   column: 'origem',
                //   label: 'ORIGEM',
                // },
                {
                  column: 'fisico',
                  label: 'FÍSICO',
                },
                {
                  column: 'contabil',
                  label: 'CONTÁBIL',
                },
              ]}
              data={[
                ...logs.data.map(log => ({
                  ...log,
                  data: formatCustomBR(new Date(log.created_at)),
                })),
              ]}
              options={[]}
              pagination
              activePage={logs.page}
              totalPages={logs.lastPage}
              markdown
              emptyLabel="Nenhum registro encontrado com o filtro aplicado"
            />
          ) : null}
        </Content>

        <Footer>
          <div>
            <Button
              type="button"
              background={colors.grey}
              onClick={onRequestClose}
            >
              Fechar
            </Button>
          </div>
        </Footer>
      </Container>
    </Modal>
  );
};

export default TimeLine;
