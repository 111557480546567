import styled, { css } from 'styled-components';

import { LogsItemContentProps, LogsItemControlProps } from './interface';

export const LogsItemContainer = styled.li`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    float: left;
    flex-direction: row;
    height: 6rem;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      .logs-item-control {
        opacity: 0.7;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(odd) {
      .logs-item-content {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  `}
`;

export const LogsItemContent = styled.div<LogsItemContentProps>`
  ${({ active, theme }) => css`
    display: flex;
    float: left;
    flex-direction: row;
    height: 5rem;
    flex: 1;
    background: ${active ? '#20C05C !important' : 'inherit'};
    box-shadow: ${active ? 'inset 1px 1px 5px rgba(0,0,0,0.2)' : 'inherit'};

    &:hover {
      background: ${active
        ? '#20C05C !important'
        : 'rgba(0,0,0,0.5) !important'};

      div span small {
        opacity: 1;
      }
    }

    div:nth-child(1) {
      width: 30rem;
    }
    div:nth-child(2) {
      width: 30rem;
    }
    div:nth-child(3) {
      flex: 1;
    }
    div:nth-child(4) {
      width: 32rem;
    }

    div {
      display: flex;
      float: left;
      flex-direction: row;
      height: 5rem;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      border-right: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-right: none;
      }

      span {
        display: flex;
        float: left;
        flex: 1;
        flex-direction: column;

        small {
          opacity: ${active ? '1 !important' : 0.6};
          font-size: ${theme.fontSizes.small};
          font-weight: 500;
          color: ${active ? theme.colors.placeholder : theme.colors.label};
        }

        strong {
          color: ${active ? theme.colors.contrast : theme.colors.label};
          font-size: ${theme.fontSizes.default};
        }
      }

      button {
        float: left;
        height: 3.2rem;
        width: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: ${theme.colors.white};
        border-radius: 0.5rem;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.5 ease;
        opacity: 0;

        &:hover {
          opacity: 0.5;
        }
      }

      &:hover {
        button {
          opacity: 1;
        }
      }
    }
  `}
`;

export const LogsItemControl = styled.div<LogsItemControlProps>`
  ${({ theme, active }) => css`
    float: left;
    height: 6rem;
    width: 6rem;
    margin-left: -8rem;
    position: relative;
    transition: all 0.5s ease;
    opacity: ${active ? '1 !important' : 0};

    &:hover {
      opacity: 1 !important;
    }

    button {
      float: left;
      height: 5rem;
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: ${active ? '#20C05C !important' : '#333'};
      border-radius: 0.6rem 0 0 0.6rem;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      transition: all 0.5 ease;
      transition: all 0.4s ease;
      margin-top: 0.5rem;

      &:hover {
        background: #333;
      }
    }
  `}
`;
