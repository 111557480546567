import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTheme } from 'styled-components';

import { AiFillDelete } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { GoPencil } from 'react-icons/go';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { handlerNumberToString } from '~/utils/money';

import { useNFE } from '~/hooks/nfe';

import { Button, InputNumber, ReactTooltip, Switch } from '~/components';

import CondicaoDePagamento from './CondicaoDePagamento';
import TipoDePagamento from './TipoDePagamento';
import ContaCorrente from './ContaCorrente';

import {
  Container,
  Content,
  ContentColumn,
  ContentRow,
  ContentMultiplas,
  FormContainer,
  IC,
  Info,
  InputContainer,
  InputsContainer,
  Item,
  Left,
  List,
  Right,
  Select,
  Summary,
} from './styles';

import { CondicaoPagamentoType, TipoPagamentoType } from '~/types';

import { NFEFormaType } from '~/hooks/nfe/interface';

import { FinanceiroProps } from './interface';

const Financeiro: React.FC<FinanceiroProps> = ({ nfe }) => {
  const { colors } = useTheme();

  const { editarNFE, validarNFE } = useNFE();

  const formRef = useRef<FormHandles>(null);

  const valorTotal = Number(nfe.arquivo.TOTAL_vNF);

  const [fpgs, setFpgs] = useState<NFEFormaType[]>(
    nfe && nfe.formas && nfe.formas.length ? [...nfe.formas] : [],
  );

  const [valorPagar, setValorPagar] = useState(0);
  const [valorDigitado, setValorDigitado] = useState(0);
  const [multiplosTipos, setMultiplosTipos] = useState(false);
  const [valorPagarComAcrescimo, setValorPagarComAcrescimo] = useState(0);
  const [taxaPagamento, setTaxaPagamento] = useState(0);
  const [restante, setRestante] = useState(0);

  const [tipoPagamentoVisibilidade, setTipoPagamentoVisibilidade] =
    useState(false);
  const [condicaoPagamentoVisibilidade, setCondicaoPagamentoVisibilidade] =
    useState(false);

  const [condicoesPagamento, setCondicoesPagamento] = useState<
    CondicaoPagamentoType[]
  >([]);

  const [tipoDePagamento, setTipoDePagamento] =
    useState<TipoPagamentoType | null>(null);

  const [condicaoDePagamento, setCondicaoDePagamento] =
    useState<CondicaoPagamentoType | null>(null);

  const abrirFormaPagamento = useCallback(() => {
    setTipoPagamentoVisibilidade(true);
  }, []);

  const fecharFormaPagamento = useCallback(() => {
    setTipoPagamentoVisibilidade(false);
  }, []);

  const abrirCondicaoPagamento = useCallback(() => {
    setCondicaoPagamentoVisibilidade(true);
  }, []);

  const fecharCondicaoPagamento = useCallback(() => {
    setCondicaoPagamentoVisibilidade(false);
  }, []);

  const focarInputValor = useCallback(() => {
    const inputValorNode = document.querySelector<HTMLInputElement>(
      'input[name="valor"]',
    );
    if (inputValorNode) {
      inputValorNode.focus();
    }
  }, []);

  const calculaValorComAcrescimo = useCallback(
    (valor: number, taxa: number) => {
      let valor_com_taxa = valor;
      if (taxa > 0) {
        valor_com_taxa = Number(valor + valor * (taxa / 100));
      }

      setValorDigitado(valor);
      setValorPagarComAcrescimo(Number(valor_com_taxa.toFixed(2)));
    },
    [],
  );

  const salvarCondicaoPagamento = useCallback(
    (pCondicaoDePagamento: CondicaoPagamentoType) => {
      setCondicaoDePagamento(pCondicaoDePagamento);
      setValorPagar(restante);
      calculaValorComAcrescimo(restante, pCondicaoDePagamento.taxa);
    },
    [restante, calculaValorComAcrescimo],
  );

  const removerFormaPagamento = (key: number) => {
    const cFormas: NFEFormaType[] = [
      ...nfe.formas.filter((fpg, cKey) => cKey !== key),
    ];
    setFpgs(cFormas);
    editarNFE({
      ...nfe,
      formas: cFormas,
    });

    validarNFE({
      ...nfe,
      formas: cFormas,
    });
  };

  const salvarTipoPagamento = useCallback(
    (pFormaDePagamento: TipoPagamentoType) => {
      setTipoDePagamento(pFormaDePagamento);
      setCondicoesPagamento(pFormaDePagamento.condicoes_pagamento);
      if (pFormaDePagamento.condicoes_pagamento.length === 1) {
        setCondicaoDePagamento(pFormaDePagamento.condicoes_pagamento[0]);
        setValorPagar(restante);
        calculaValorComAcrescimo(
          restante,
          pFormaDePagamento.condicoes_pagamento[0].taxa,
        );
      } else {
        setCondicaoPagamentoVisibilidade(true);
      }
    },
    [calculaValorComAcrescimo, restante],
  );

  const limpar = useCallback(() => {
    setValorPagar(0);
    setValorPagarComAcrescimo(0);
    setTipoDePagamento(null);
    setCondicaoDePagamento(null);
  }, []);

  const adicionarFormaPagamento = useCallback(async () => {
    if (valorPagarComAcrescimo === 0) return;

    if (tipoDePagamento && condicaoDePagamento) {
      const cForma: NFEFormaType = {
        tipo_pagamento_id: Number(tipoDePagamento.id),
        tipo_pagamento_desc: tipoDePagamento.descricao,
        condicao_pagamento_id: Number(condicaoDePagamento.id),
        condicao_pagamento_desc: condicaoDePagamento.descricao,
        valor: valorPagarComAcrescimo,
        taxa: Number((valorPagarComAcrescimo - valorDigitado).toFixed(2)),
        cheques: [],
      };

      const cFormas: NFEFormaType[] = [...fpgs, cForma];

      setFpgs(cFormas);
      editarNFE({
        ...nfe,
        formas: cFormas,
      });

      validarNFE({
        ...nfe,
        formas: cFormas,
      });

      limpar();
    }
  }, [
    valorPagarComAcrescimo,
    tipoDePagamento,
    condicaoDePagamento,
    valorDigitado,
    fpgs,
    editarNFE,
    nfe,
    validarNFE,
    limpar,
  ]);

  const gerenciarEnterNoInputValor = useCallback(() => {
    adicionarFormaPagamento();
  }, [adicionarFormaPagamento]);

  useEffect(() => {
    const totalFpgs = fpgs.reduce((acc, fpg) => {
      // eslint-disable-next-line no-param-reassign
      acc += fpg.valor;
      return acc;
    }, 0);

    const taxa_pagamento_calc = fpgs.reduce((acc, fpg) => {
      // eslint-disable-next-line no-param-reassign
      acc += fpg.taxa;
      return acc;
    }, 0);

    setTaxaPagamento(taxa_pagamento_calc);

    const restante_calc = totalFpgs > valorTotal ? 0 : valorTotal - totalFpgs;

    setRestante(restante_calc);
  }, [fpgs, valorTotal]);

  useEffect(() => {
    if (!multiplosTipos) {
      adicionarFormaPagamento();
    } else {
      setTimeout(() => {
        focarInputValor();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorPagarComAcrescimo]);

  const gerenciarAtalhosTeclado = (event: KeyboardEvent): void => {
    if (event.code === 'F5') {
      event.preventDefault();
      abrirFormaPagamento();
    } else if (event.code === 'F2') {
      event.preventDefault();
      setMultiplosTipos(state => !state);
    }
  };

  useEffect(() => {
    setValorPagarComAcrescimo(0);
  }, [multiplosTipos]);

  useEffect(() => {
    document.addEventListener('keydown', gerenciarAtalhosTeclado, true);
    return () => {
      document.removeEventListener('keydown', gerenciarAtalhosTeclado, true);
    };
  });

  return (
    <>
      <TipoDePagamento
        visible={tipoPagamentoVisibilidade}
        onRequestClose={fecharFormaPagamento}
        onRequestSave={salvarTipoPagamento}
        origem={'COMPRA'}
      />

      {tipoDePagamento ? (
        <CondicaoDePagamento
          condicoesPagamento={condicoesPagamento}
          visible={condicaoPagamentoVisibilidade}
          onRequestClose={fecharCondicaoPagamento}
          onRequestSave={salvarCondicaoPagamento}
        />
      ) : null}

      <Container>
        <ContaCorrente nfe={nfe} />

        <Summary>
          <Info color="#00B432">
            <small>Total da NFe</small>
            <span>{`R$ ${handlerNumberToString(valorTotal)}`}</span>
          </Info>

          <Info>
            <small>Taxa de pagamento</small>
            <span>{`R$ ${handlerNumberToString(taxaPagamento)}`}</span>
          </Info>
        </Summary>
        <Content>
          <Left>
            <FormContainer>
              <Form ref={formRef} onSubmit={() => null}>
                <ContentColumn>
                  <ContentRow>
                    <h1>Adicionar pagamento</h1>
                    <ContentMultiplas>
                      <div className="switch" data-tooltip-id="multiplos">
                        <ReactTooltip
                          id="multiplos"
                          content="Multiplos tipos de pagamento"
                        />
                        <Switch
                          defaultValue={multiplosTipos}
                          onChange={setMultiplosTipos}
                          name="multiplos"
                        />
                      </div>
                      <span>Múltiplos? [F2]</span>
                    </ContentMultiplas>
                  </ContentRow>
                  <Select onClick={abrirFormaPagamento}>
                    <span>
                      {tipoDePagamento ? (
                        tipoDePagamento.descricao
                      ) : (
                        <ins>TIPO DE PAGAMENTO [F5]</ins>
                      )}
                    </span>
                    <GoPencil size={22} color={colors.label} />
                  </Select>
                  <Select
                    disabled={!tipoDePagamento}
                    onClick={abrirCondicaoPagamento}
                  >
                    <span>
                      {condicaoDePagamento ? (
                        condicaoDePagamento.descricao
                      ) : (
                        <ins>CONDIÇÃO DE PAGAMENTO</ins>
                      )}
                    </span>
                    <GoPencil size={22} color={colors.label} />
                  </Select>
                </ContentColumn>
                <ContentRow>
                  <InputsContainer>
                    <InputContainer>
                      <span>Valor</span>
                      <div>
                        <InputNumber
                          name="valor"
                          placeholder="Informe o Valor"
                          onKeyDownEnter={gerenciarEnterNoInputValor}
                          defaultValue={`${valorPagar}`}
                          onChange={value => {
                            calculaValorComAcrescimo(
                              Number(value.replace(',', '.')),
                              tipoDePagamento?.condicoes_pagamento[0].taxa || 0,
                            );
                          }}
                          selectAllInFocus
                        />
                      </div>
                    </InputContainer>
                    <InputContainer>
                      <span>Valor com Taxa</span>
                      <div>
                        <InputNumber
                          name="taxa"
                          placeholder="Taxa da condição selecionada"
                          disabled
                          value={`${
                            condicaoDePagamento ? valorPagarComAcrescimo : 0
                          }`}
                        />
                      </div>
                    </InputContainer>
                    <Button
                      name="Financeiro-btn-enviar"
                      type="button"
                      onClick={adicionarFormaPagamento}
                      disabled={!tipoDePagamento || !condicaoDePagamento}
                      background={colors.darkOrange}
                    >
                      Adicionar
                      <IC>
                        <BsFillPlusCircleFill size={22} color="#fff" />
                      </IC>
                    </Button>
                  </InputsContainer>
                </ContentRow>
              </Form>
            </FormContainer>

            <Summary>
              <Info color="#D82E2E">
                <small>Restante</small>
                <span>{`R$ ${handlerNumberToString(restante)}`}</span>
              </Info>
            </Summary>
          </Left>

          <Right>
            <h1>Adicionadas</h1>
            <List>
              {fpgs.map((fpg, key) => (
                <Item key={`${fpg}`}>
                  <div>
                    <span>{fpg.tipo_pagamento_desc}</span>
                    <small>{fpg.condicao_pagamento_desc}</small>
                  </div>
                  <h2>{`R$ ${handlerNumberToString(fpg.valor)}`}</h2>
                  <button
                    type="button"
                    onClick={() => removerFormaPagamento(key)}
                  >
                    <AiFillDelete size={22} color="#fff" />
                  </button>
                </Item>
              ))}
            </List>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default Financeiro;
