import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20000;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const Content = styled.div`
  width: 700px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ICAnimation = keyframes`
  	0% {
			transform: scale(1);
		}
    50% {
      transform: scale(1.02);
    }
		100% {
			transform: scale(1);
		}
`;

export const IC = styled.div`
  animation: ${ICAnimation} 1s linear infinite;
  margin: 5px 0 10px 0;
`;

export const Message = styled.div`
  ${({ theme }) => css`
    font-weight: 700;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.bigger};
    text-align: center;
  `}
`;

export const SubMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.default};
    margin: 5px 0 40px 0;
    padding: 0 50px;
    text-align: center;
  `}
`;
