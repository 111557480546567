import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { FiChevronDown, FiSun, FiMoon } from 'react-icons/fi';

import {
  HeaderPerfilContainer,
  HeaderPerfilContent,
  HeaderPerfilControl,
  HeaderPerfilUser,
  HeaderPerfilControlIc,
  HeaderPerfilStatus,
  NavButton,
} from './styles';

import { useAuth } from '~/hooks/auth';
import useAppTheme from '~/hooks/theme';

import { HeaderPerfilProps } from './interface';

const HeaderPerfil: React.FC<HeaderPerfilProps> = () => {
  const { colors } = useTheme();
  const { currentTheme, toggleTheme } = useAppTheme();
  const { signOut, user } = useAuth();

  const [showContent, setShowContent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setShowContent(false);
    }
  };

  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleSingOut = () => {
    signOut();
  };

  return (
    <HeaderPerfilContainer ref={ref}>
      <HeaderPerfilControl onClick={() => setShowContent(!showContent)}>
        <HeaderPerfilUser>
          <img
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
            alt=""
          />
        </HeaderPerfilUser>
        <HeaderPerfilStatus $online={false} />
        <HeaderPerfilControlIc>
          <FiChevronDown size={18} color={colors.label} />
        </HeaderPerfilControlIc>
      </HeaderPerfilControl>
      <HeaderPerfilContent $visible={showContent}>
        <img
          src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
          alt=""
        />
        <h2>{user.nome}</h2>
        {/* <h3>Administrador</h3> */}
        <NavButton onClick={toggleTheme}>
          {currentTheme === 'light' ? <FiMoon /> : <FiSun />}
          Alterar Tema
        </NavButton>
        <div>
          {/* <a href="minha-conta">Minha conta</a>
          <a href="configuracoes">Configurações</a> */}
          <a href="login" onClick={() => handleSingOut()}>
            Sair
          </a>
        </div>
        {/* <p>
          <VscGraph size={24} color={colors.primary} />
          <span>
            <strong>R$ 4500,00</strong>
            <small>Receita</small>
          </span>
        </p> */}
      </HeaderPerfilContent>
    </HeaderPerfilContainer>
  );
};

export default HeaderPerfil;
