import styled, { keyframes } from 'styled-components';

const PartnerAddressLdAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const PartnerAddressLdAnim = styled.span`
  overflow: hidden;
  float: left;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  svg {
    animation: ${PartnerAddressLdAnimation} 2s infinite;
  }
`;
