import React, { useState } from 'react';

import { CgArrowsVAlt } from 'react-icons/cg';

import { Visibility, Container, TagsDropDownArea } from './styles';

import TagItem from '../TagItem';
import TagDrag from '../TagDrag';
import TagDrop from '../TagDrop';

import { LugItemProps } from '../interface';

import { AreaOffProps, currentTagsType } from './interface';

const AreaOff: React.FC<AreaOffProps> = ({
  tags,
  groupId,
  onSaveTag,
  visible,
}) => {
  const [tagsOff, setTagsOff] = useState<currentTagsType>([
    ...tags.filter(cTag => cTag.group === groupId),
  ]);

  const onDropItemIn = (tag: LugItemProps): void => {
    if (tagsOff) {
      setTagsOff([
        ...tagsOff.map(cItem => {
          if (cItem.value === tag.value) {
            return {
              ...cItem,
              active: true,
            };
          }
          return cItem;
        }),
      ]);
      onSaveTag(tag);
    }
  };

  const addItem = (tag: LugItemProps): void => {
    if (tagsOff) {
      setTagsOff([
        ...tagsOff.map(cItem => {
          if (cItem.value === tag.value) {
            return {
              ...cItem,
              active: true,
            };
          }
          return cItem;
        }),
      ]);
      onSaveTag(tag);
    }
  };

  return (
    <Visibility $visible={visible}>
      <Container>
        <h3>Tags</h3>
        <TagDrop name="tags-off" accept={`tag-off-${groupId}`}>
          <TagsDropDownArea>
            {tagsOff &&
              tagsOff
                .filter(cTag => !cTag.active)
                .map(tag => (
                  <TagDrag
                    key={tag.value}
                    type="tag-in"
                    tag={tag}
                    onDropItem={onDropItemIn}
                  >
                    <TagItem tag={tag} cor={tag.color} onAddItem={addItem} />
                  </TagDrag>
                ))}

            {!tagsOff ||
              (tagsOff && !tagsOff.filter(cTag => !cTag.active).length && (
                <h4>Todas as tags foram adicionadas</h4>
              ))}
          </TagsDropDownArea>
        </TagDrop>
        <span>
          <CgArrowsVAlt size={26} color="#fff" />
        </span>
      </Container>
    </Visibility>
  );
};

export default AreaOff;
