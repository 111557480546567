import React, { useEffect, useRef, useState } from 'react';

import { useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils';

import api from '~/services/api';

import { ErrorMessage, FindProduto, Input, InputContainer } from '~/components';

import {
  Container,
  ContentSearchAdd,
  Empty,
  Search,
  SearchContent,
} from './styles';

import { ProdutoType } from '~/types';

import Item from './Item';

import { CliFormData, EntradaDeItemProps } from './interface';
import { handlerErrors } from '~/utils/error';

const EntradaDeItem: React.FC<EntradaDeItemProps> = ({ focar = false }) => {
  const formRef = useRef<FormHandles>(null);

  const [produto, setProduto] = useState<ProdutoType | null>(null);
  const [qtd, setQtd] = useState('0');
  const [preco, setPreco] = useState('0');

  const [formError, setFormError] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);

  const [produtosVisibility, setProdutosVisibility] = useState(false);

  const CLIParams = (param: string) => {
    let outParams = [];
    let cliQtd = '0';
    let cliCodigo = '0';
    let cliPreco = '0';
    if (param.includes('*')) {
      outParams = param.split('*');
      if (outParams.length > 1) {
        cliQtd = `${outParams[0] || 0}`;
        cliCodigo = `${outParams[1] || 0}`;
        cliPreco = `${outParams[2] || 0}`;
      }
    } else {
      cliCodigo = `${param || 0}`;
    }
    return {
      cliQtd,
      cliCodigo,
      cliPreco,
    };
  };

  const handlerSubmit = async (data: CliFormData) => {
    try {
      setFormError('');
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        cli: Yup.string().required().typeError('Informe o código do produto'),
      });

      await schema.validate(data, { abortEarly: false });

      try {
        const { cli } = data;

        if (!cli) {
          return false;
        }

        setIsEmpty(false);

        const { cliQtd, cliCodigo, cliPreco } = CLIParams(cli);

        setPreco(cliPreco);
        setQtd(cliQtd);

        setProduto(null);
        setFormError('');

        const response = await api.get(`produtos?codigo=${cliCodigo}`);
        const { data: produtos_data } = response;
        if (produtos_data && !produtos_data.error) {
          const { data: results } = produtos_data;
          if (results && results.length) {
            const cProduto = results[0];
            setProduto(cProduto);
          } else {
            setIsEmpty(true);
          }

          formRef.current?.reset();
        }
      } catch (e) {
        const message = handlerErrors(e);
        setFormError(`${message}`);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return null;
      }
    }
  };

  const handlerFocusInCLISearch = () => {
    const inputCLINode =
      document.querySelector<HTMLInputElement>('input[name="cli"]');
    if (inputCLINode) {
      inputCLINode.focus();
    }
  };

  const handlerOnRequestCloseItem = () => {
    setProduto(null);
    setPreco('0');
    setQtd('0');

    setIsEmpty(false);

    setTimeout(() => {
      handlerFocusInCLISearch();
    }, 500);
  };

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (event.ctrlKey && event.code === 'KeyP') {
      event.preventDefault();
      setProdutosVisibility(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
    };
  });

  const searchInputChanged = useCallback((value: string) => {
    if (formRef) {
      formRef.current?.setFieldValue('cli', value);
    }
  }, []);

  return (
    <Container>
      <Search>
        <ErrorMessage error={formError} onDismiss={() => setFormError('')} />
        <SearchContent>
          <Form ref={formRef} onSubmit={handlerSubmit}>
            <InputContainer>
              <span>CTRL + P (Produtos)</span>
              <div>
                <Input
                  name="cli"
                  type="text"
                  placeholder="Buscar um produto..."
                  autoFocus={focar}
                />
              </div>
            </InputContainer>
          </Form>
          <ContentSearchAdd>
            <FindProduto
              openned={produtosVisibility}
              onlySearch
              onSelectValue={selecionados => {
                setProdutosVisibility(false);
                if (selecionados.length > 0) {
                  setProduto(selecionados[0]);
                  searchInputChanged(selecionados[0].codigo);
                }
              }}
            />
          </ContentSearchAdd>
        </SearchContent>
      </Search>

      {produto ? (
        <Item
          pProduto={produto}
          pQtd={qtd}
          pPreco={preco}
          onRequestClose={handlerOnRequestCloseItem}
        />
      ) : null}

      {isEmpty && !produto ? (
        <Empty>
          <span>
            Nenhum produto encontrado ou sem preço definido para a tabela de
            preço selecionada!
          </span>
        </Empty>
      ) : null}
    </Container>
  );
};

export default EntradaDeItem;
