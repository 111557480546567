import styled, { css } from 'styled-components';

import {
  ModalContainerProps,
  ModalContentProps,
  ScrollProps,
} from './interface';

export const ModalContainer = styled.div<ModalContainerProps>`
  ${({ theme, $visible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0rem;
    top: 0rem;
    background: ${theme.colors.overlay};
    z-index: 6000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? 'visible' : 'hidden'};
    .modal-scroll-scrollarea-content {
      float: left;
      width: 100%;
      padding-right: 2rem;
    }

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const Scroll = styled.div<ScrollProps>`
  ${({ $disableoverflow }) => css`
    width: 100%;
    float: left;
    height: 100%;
    overflow: ${$disableoverflow ? 'inherit' : 'hidden'};
    overflow-y: auto;
  `}
`;

export const ScrollContent = styled.div`
  width: 100%;
  float: left;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 10rem 0;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const ModalContent = styled.div<ModalContentProps>`
  ${({ theme, $width, $disableoverflow }) => css`
    width: ${$width || '68rem'};
    height: auto;
    border-radius: 0.6rem;
    background: ${theme.colors.background};
    display: flex;
    overflow: ${$disableoverflow ? 'inherit' : 'hidden'};
    flex-direction: column;
    border: 0.1rem solid ${theme.colors.defaultHighlight};

    > header {
      width: 100%;
      float: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;
      background: ${theme.colors.primaryDark};

      div {
        display: flex;
        flex-direction: column;
        float: left;
      }

      h1 {
        font-size: ${theme.fontSizes.big};
        margin: 0rem;
        padding: 0rem;
        color: ${theme.colors.white};
        text-transform: uppercase;
        font-weight: 700;
      }

      h2 {
        font-size: ${theme.fontSizes.small};
        margin: 0rem;
        padding: 0rem;
        color: ${theme.colors.white};
        text-transform: uppercase;
        font-weight: 700;
      }

      button {
        cursor: pointer;
        transition: all 0.5s ease;
        background: none;
        &:hover {
          transform: rotate(180deg);
          opacity: 0.2;
        }
      }
    }

    > main {
      float: left;
      width: 100%;
      padding: 2rem;
      overflow: ${$disableoverflow ? 'inherit' : 'hidden'};

      @media (max-$width: 1024px) {
        padding: 0rem;
      }
    }
  `}
`;
