import styled, { keyframes } from 'styled-components';

const searchLoadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const HeaderSearchLoadingContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 0.5rem;

  span {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    animation: ${searchLoadingAnimation} 2s infinite;
  }
`;
