import styled, { css } from 'styled-components';

import { TagProps } from './interface';

export const GroupsList = styled.div`
  float: left;
  background: rgba(0, 0, 0, 0.2);
  margin: 2rem 1rem 0 1rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px;
  min-height: 12rem;
`;

export const GroupsInfo = styled.div`
  padding: 20px;
  font-size: 12px;
`;

export const TagsInGroupColor = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  padding-right: 15px;

  > button {
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease;
    background: none;
    margin: 0px 0 0 0;

    &:hover {
      opacity: 0.5;
    }

    img {
      width: 32px;
      height: 32px;
      float: left;
      border-radius: 16px;
      border: 2px solid rgba(255, 255, 255, 0.8);
    }

    span {
      float: left;
      width: 32px;
      height: 32px;
      border: 1px solid rgba(255, 255, 255, 0.8);
      margin: 0 0 0 5px;
    }
  }

  > div {
    position: absolute;
    margin: -15px 0px 0 25px;
    transform: translate(-50%, 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    svg {
      margin-bottom: 10px;
      cursor: pointer;
      align-self: flex-start;
    }
  }
`;

export const Tag = styled.div<TagProps>`
  ${({ theme, $cor }) => css`
    float: left;
    color: ${theme.colors.label};
    padding: 0.8rem;
    text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    margin: 0.5rem;
    background: ${$cor};
    border-radius: ${theme.radio.default};
    font-size: 1.4rem;
    font-weight: 700;
    cursor: move;
    text-decoration: none;
    outline: none;
    min-width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      button {
        opacity: 1;
      }
    }

    button {
      opacity: 0;
      cursor: pointer;
      background: none;
      transition: all 0.5s ease;

      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;
