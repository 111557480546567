import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    border-radius: 0rem 0rem 0.6rem 0.6rem;
    float: left;
    background: ${theme.colors.background};
    padding: 0 0 2rem 0;
    margin-bottom: 6.5rem;
    flex-direction: column;
    box-shadow: ${theme.shadows.highlight};

    @media (max-width: 1024px) {
      margin-bottom: 0rem;
    }
  `}
`;
