import React, { useState } from 'react';

import {
  TagsAreaOnVisibility,
  TagsAreaOnContainer,
  TagsDropDownArea,
} from './styles';

import TagItem from '../TagItem';
import TagDrag from '../TagDrag';
import TagDrop from '../TagDrop';

import { TagProps, TagsAreaOnProps, currentTagsType } from './interface';

const TagsAreaOn: React.FC<TagsAreaOnProps> = ({
  selectedTags,
  onRemoveTag,
}) => {
  const [tagsOn, setTagsOn] = useState<currentTagsType>(
    selectedTags ? [...selectedTags] : undefined,
  );

  const handlerOnDropItemOff = (tag: TagProps): void => {
    if (tagsOn) {
      const newTagsOn = [
        ...tagsOn.filter(currentTag => currentTag.id !== tag.id),
      ];
      setTagsOn(newTagsOn);
      onRemoveTag(tag);
    }
  };

  const handlerRemoveItem = (tag: TagProps): void => {
    if (tagsOn) {
      const newTagsOn = [
        ...tagsOn.filter(currentTag => currentTag.id !== tag.id),
      ];
      setTagsOn(newTagsOn);
      onRemoveTag(tag);
    }
  };

  return (
    <TagsAreaOnVisibility>
      <TagsAreaOnContainer>
        <h3>Tags adicionadas </h3>

        <TagDrop name="tags-in" accept="tag-in">
          <TagsDropDownArea>
            {tagsOn &&
              tagsOn.map(tag => (
                <TagDrag
                  key={tag.id}
                  type={`tag-off-${tag.grupo_tag_id}`}
                  tag={tag}
                  onDropItem={handlerOnDropItemOff}
                >
                  <TagItem
                    tag={tag}
                    cor={tag.cor}
                    onRemoveItem={handlerRemoveItem}
                  />
                </TagDrag>
              ))}

            {!tagsOn ||
              (tagsOn && !tagsOn.length && <h4>Nenhuma tag foi adicionada</h4>)}
          </TagsDropDownArea>
        </TagDrop>
      </TagsAreaOnContainer>
    </TagsAreaOnVisibility>
  );
};

export default TagsAreaOn;
