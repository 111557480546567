import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { IoIosArrowDropup, IoIosArrowDropdown } from 'react-icons/io';
import { AnchorMenuContainer, AnchorMenuHelpers } from './styles';

const AnchorMenu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [scrollHelper, setScrollHelper] = useState('down');
  const { colors } = useTheme();

  const handlerHelper = (): void => {
    window.scrollTo({
      behavior: 'smooth',
      top: scrollHelper === 'down' ? document.body.scrollHeight : 0,
    });
  };

  const handlerScroll = (): void => {
    const inBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    setScrollHelper(inBottom ? 'up' : 'down');
  };

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, true);
    return () => {
      window.removeEventListener('scroll', handlerScroll, true);
    };
  }, []);

  return (
    <AnchorMenuContainer>
      <AnchorMenuHelpers />
      {children}
      <AnchorMenuHelpers>
        <button type="button" onClick={handlerHelper}>
          {scrollHelper === 'down' ? (
            <IoIosArrowDropdown size={32} color={colors.label} />
          ) : (
            <IoIosArrowDropup size={32} color={colors.label} />
          )}
        </button>
      </AnchorMenuHelpers>
    </AnchorMenuContainer>
  );
};

export default AnchorMenu;
