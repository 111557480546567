import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { AiOutlineCopy } from 'react-icons/ai';
import { GoCheck } from 'react-icons/go';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';
import { ReactTooltip } from '~/components';

import { LogsItemContainer, LogsItemContent, LogsItemControl } from './styles';

import { LogsItemProps } from './interface';
import { formatCustomBR } from '~/utils/formatDate';

const LogsItem: React.FC<LogsItemProps> = ({ item }) => {
  const [fixVisibility, setFixVisibility] = useState(false);
  const [copyClipboard, setCopyclipboard] = useState('');
  const [copyStatus, setCopyStatus] = useState(false);
  const { colors } = useTheme();

  const toggleFixVisibilty = () => {
    setFixVisibility(!fixVisibility);
  };

  const copyData = (data: string) => {
    setCopyStatus(true);
    setCopyclipboard(data);
    navigator.clipboard.writeText(data);
    setTimeout(() => {
      setCopyclipboard('');
      setCopyStatus(false);
    }, 1500);
  };

  const copyButton = (
    data: string,
    currentCopyClipboard: string,
    currentCopyStatus: boolean,
  ) => {
    return (
      <button
        type="button"
        data-tip={
          currentCopyClipboard === data && currentCopyStatus
            ? 'Copiado!'
            : 'Clique para copiar'
        }
        data-for={
          currentCopyClipboard === data && currentCopyStatus
            ? `log-${item.id}-1`
            : `log-${item.id}-2`
        }
        onClick={() => copyData(data)}
      >
        {currentCopyClipboard === data && currentCopyStatus ? (
          <GoCheck size={22} color="#333" />
        ) : (
          <AiOutlineCopy size={22} color="#333" />
        )}
      </button>
    );
  };

  return (
    <LogsItemContainer>
      <ReactTooltip id={`log-${item.id}-1`} />
      <ReactTooltip id={`log-${item.id}-2`} />
      <ReactTooltip id={`log-${item.id}-3`} />

      <LogsItemControl
        active={fixVisibility}
        className="logs-item-control"
        data-tip="Marcador de linha"
        data-for={`log-${item.id}-3`}
      >
        <button type="button" onClick={toggleFixVisibilty}>
          {fixVisibility ? (
            <BiRadioCircleMarked size={32} color={colors.white} />
          ) : (
            <BiRadioCircle size={32} color={colors.white} />
          )}
        </button>
      </LogsItemControl>
      <LogsItemContent active={fixVisibility} className="logs-item-content">
        <div>
          <span>
            <small>EMPRESA</small>
            <strong>{item.empresa.fantasia}</strong>
          </span>
          {copyButton('empresa', copyClipboard, copyStatus)}
        </div>
        <div>
          <span>
            <small>USUÁRIO</small>
            <strong>{item.parceiro.nome_fantasia}</strong>
          </span>
          {copyButton('usuario', copyClipboard, copyStatus)}
        </div>
        <div>
          <span>
            <small>DESCRIÇÃO</small>
            <strong>{item.descricao}</strong>
          </span>
          {copyButton('descricao', copyClipboard, copyStatus)}
        </div>
        <div>
          <span>
            <small>DATA</small>
            <strong>{formatCustomBR(new Date(item.created_at))}</strong>
          </span>
          {copyButton('id', copyClipboard, copyStatus)}
        </div>
      </LogsItemContent>
    </LogsItemContainer>
  );
};

export default LogsItem;
