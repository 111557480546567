import styled, { css } from 'styled-components';

interface TypeProps {
  type: 'entrada' | 'saida' | 'saldo';
}

export const Container = styled.div<TypeProps>`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 2.5rem;
  }

  & + div {
    border-left: 2px solid ${({ theme }) => theme.colors.defaultHighlight};
    padding-left: 0.25rem;
  }
  strong {
    font-weight: bold;
    font-size: 24px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
  }

  strong,
  span {
    ${({ theme, type }) =>
      type === 'entrada'
        ? css`
            color: ${theme.colors.green};
          `
        : type === 'saida'
        ? css`
            color: ${theme.colors.error};
          `
        : css`
            color: ${theme.colors.primary};
          `}
  }

  svg {
    ${({ theme, type }) =>
      type === 'entrada'
        ? css`
            fill: ${theme.colors.green};
          `
        : type === 'saida'
        ? css`
            fill: ${theme.colors.error};
          `
        : css`
            fill: ${theme.colors.primary};
          `}
  }
`;
