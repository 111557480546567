import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  background: ${({ theme }) => theme.colors.secondary};
`;

export const Content = styled.main`
  margin-bottom: 20rem;
  float: left;
  width: 100%;
`;

export const BreadCrumb = styled.div`
  ${({ theme }) => css`
    display: flex;
    float: left;
    flex-direction: row;
    border: 0.1rem solid ${theme.colors.grey};
    background-color: ${theme.colors.controlFocus};
    border-radius: ${theme.radio.default};
    align-self: flex-start;
    align-items: center;
    margin: 0 0 2rem 0;
  `}
`;

export const Level = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    border-right: 0.1rem solid ${theme.colors.grey};
    padding: 1rem;
    color: ${theme.colors.label};
    font-size: ${theme.fontSizes.small};
  `}
`;

export const Area = styled.div`
  margin-top: 8rem;
  margin-bottom: 20rem;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  min-height: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: inherit;
    height: auto;
    min-height: auto;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem;
  flex-direction: column;
`;
