import React from 'react';

import { useTheme } from 'styled-components';
import { Container, Footer, Line, List } from './styles';

import { TotalizadoresProps } from './interface';

import { Button, Modal } from '~/components';
import { handlerNumberToString } from '~/utils/money';

const Totalizadores: React.FC<TotalizadoresProps> = ({
  visibilidade,
  aoFechar,
  totalizadores,
}) => {
  const { colors } = useTheme();

  return (
    <Modal
      title="Totalizadores"
      width="80rem"
      visible={visibilidade}
      scrollable
      onRequestClose={aoFechar}
    >
      <Container>
        <List>
          {totalizadores.map(totalizador => (
            <Line key={totalizador.chave}>
              <span>{totalizador.chave}</span>
              <span>{handlerNumberToString(Number(totalizador.valor))}</span>
            </Line>
          ))}
        </List>
        <Footer>
          <div>
            <Button
              type="button"
              background={colors.contrast}
              onClick={aoFechar}
            >
              Fechar
            </Button>
          </div>
        </Footer>
      </Container>
    </Modal>
  );
};

export default Totalizadores;
