import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { GrAscend, GrDescend } from 'react-icons/gr';

import {
  ListSearchCloseCircleIcon,
  ListSearchContainer,
  ListSearchOrder,
} from './styles';

import { ListSearchProps } from './interface';

const ListSearch: React.FC<ListSearchProps> = ({
  onRequestSearch,
  onRequestOrderProps,
  searchField,
  children,
  orderPosition,
  orderEnabled,
  label,
  orderQuery,
  isNumeric,
  width,
}) => {
  const { colors } = useTheme();

  const [searchQuery, setSearchQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handlerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.keyCode === 13 && searchField && onRequestSearch) {
      onRequestSearch(searchField, searchQuery);
    }
  };

  const handlerOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e && e.target) {
      let currentValue = e.target.value;
      if (isNumeric && isNumeric === true) {
        currentValue = currentValue.replace(/[^\d]+/g, '');
      }
      setSearchQuery(`${currentValue}`);
    }
  };

  const handleInputFocus = (): void => {
    setIsFocused(true);
  };

  const handleInputBlur = (): void => {
    setIsFocused(false);
  };

  const handlerCancel = (): void => {
    setSearchQuery('');
    if (searchField && onRequestSearch) {
      onRequestSearch(searchField, '');
    }
  };

  const handlerToogleOrder = (): void => {
    if (searchField && onRequestOrderProps) {
      onRequestOrderProps(searchField, orderQuery);
    }
  };

  return (
    <ListSearchContainer
      $isfocused={isFocused}
      $isactive={!!searchQuery}
      $isorder={orderEnabled}
      $width={width}
    >
      <input
        autoComplete="nope"
        placeholder={`${children?.toString()}`}
        value={searchQuery}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handlerOnChange}
        onKeyDown={handlerKeyDown}
        type={isNumeric && isNumeric === true ? 'number' : 'text'}
      />
      <ListSearchCloseCircleIcon
        color={searchQuery ? colors.white : 'rgba(0,0,0,0)'}
        size={22}
        onClick={handlerCancel}
      />

      <ListSearchOrder
        title={
          orderPosition
            ? `${label} é o ${orderPosition}º campo considerado na ordenação ${
                !orderQuery || orderQuery === 'asc'
                  ? 'de forma crescente'
                  : 'de forma decrescente'
              }`
            : ''
        }
        className="list-search-order"
      >
        {!orderQuery || orderQuery === 'asc' ? (
          <GrDescend
            onClick={handlerToogleOrder}
            size={20}
            color={colors.controlFocus}
          />
        ) : (
          <GrAscend
            onClick={handlerToogleOrder}
            size={20}
            color={colors.controlFocus}
          />
        )}
        {orderPosition ? <span>{orderPosition}</span> : null}
      </ListSearchOrder>
    </ListSearchContainer>
  );
};

export default ListSearch;
