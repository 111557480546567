import { NFeType } from '~/types';

/**
 * @function formatBytesNFE - Formatar tamanho da NFE
 * @param number bytes Total de Bytes da NFE
 * @param number decimals Total de Casas decimais
 * @return string
 */

const formatBytesNFE = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const checkNFEPropIsEmpty = (
  pStr: string | null | undefined,
  dafault = '',
): string => {
  if (!pStr || pStr === null || pStr === undefined) {
    return dafault;
  }
  return `${pStr}`;
};

/**
 * @function validarReferenciaNFE - Validar N referencias a uma propriedade da NFE (Padrao Sisaut)
 * @param array<any> pStrs Possiveis referencias dentro do XML ao campo da NFE (Padrão Sisaut)
 * @param string padrao Valor padrão a ser retornado se nenhuma referencia bater
 * @return string
 */
const validarReferenciaNFE = (
  pStrs: Array<string | null | undefined>,
  padrao: string = '',
): string => {
  let pStr: string | null = null;

  pStrs.forEach(cPStr => {
    if (pStr === null) {
      if (cPStr && cPStr !== null && cPStr !== undefined) {
        pStr = cPStr;
      }
    }
  });

  if (pStr === null) {
    pStr = `${padrao}`;
  }

  return `${pStr}`;
};

/**
 * @function xmlJSONtoNFE - Conversão da XML(em JSON) para o Padrão NFE (Sisaut)
 * @param array<any> pStrs Possiveis referencias dentro do XML ao campo da NFE (Padrão Sisaut)
 * @param string pJsonInXML JSON da XML em string
 * @return NFeType
 */
const xmlJSONtoNFE = (pJsonInXML: string): NFeType => {
  try {
    const xmlData = JSON.parse(`${pJsonInXML}`);

    if (!Array.isArray(xmlData?.nfeProc?.NFe?.infNFe?.det)) {
      xmlData.nfeProc.NFe.infNFe.det = [
        {
          ...(xmlData?.nfeProc?.NFe?.infNFe?.det || ''),
        },
      ];
    }

    const sisAutNFE: NFeType = {
      // empresa_id: '0',
      IDE_cUF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.cUF?._text],
        '',
      )}`,
      IDE_cNF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.cNF?._text],
        '',
      )}`,
      IDE_natOp: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.natOp?._text],
        '',
      )}`,
      IDE_mod: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.mod?._text],
        '',
      )}`,
      IDE_serie: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.serie?._text],
        '',
      )}`,
      IDE_nNF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.nNF?._text],
        '',
      )}`,
      IDE_dhEmi: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.dhEmi?._text],
        '',
      )}`,
      IDE_dhSaiEnt: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.dhSaiEnt?._text],
        '',
      )}`,
      IDE_tpNF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.tpNF?._text],
        '',
      )}`,
      IDE_idDest: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.idDest?._text],
        '',
      )}`,
      IDE_cMunFG: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.cMunFG?._text],
        '',
      )}`,
      IDE_tpImp: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.tpImp?._text],
        '',
      )}`,
      IDE_tpEmis: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.tpEmis?._text],
        '',
      )}`,
      IDE_cDV: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.cDV?._text],
        '',
      )}`,
      IDE_tpAmb: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.tpAmb?._text],
        '',
      )}`,
      IDE_finNFe: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.finNFe?._text],
        '',
      )}`,
      IDE_indFinal: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.indFinal?._text],
        '',
      )}`,
      IDE_indPres: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.indPres?._text],
        '',
      )}`,
      IDE_procEmi: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.procEmi?._text],
        '',
      )}`,
      IDE_verProc: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.ide?.verProc?._text],
        '',
      )}`,
      EMIT_CNPJ: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.CNPJ?._text],
        '',
      )}`,
      EMIT_xNome: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.xNome?._text],
        '',
      )}`,
      EMIT_xFant: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.xFant?._text],
        '',
      )}`,
      EMIT_xLgr: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.xLgr?._text],
        '',
      )}`,
      EMIT_nro: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.nro?._text],
        '',
      )}`,
      EMIT_xBairro: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.xBairro?._text],
        '',
      )}`,
      EMIT_cMun: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.cMun?._text],
        '',
      )}`,
      EMIT_xMun: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.xMun?._text],
        '',
      )}`,
      EMIT_UF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.UF?._text],
        '',
      )}`,
      EMIT_CEP: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.CEP?._text],
        '',
      )}`,
      EMIT_cPais: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.cPais?._text],
        '',
      )}`,
      EMIT_xPais: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.xPais?._text],
        '',
      )}`,
      EMIT_fone: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.enderEmit?.fone?._text],
        '',
      )}`,
      EMIT_IE: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.IE?._text],
        '',
      )}`,
      EMIT_IEST: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.IEST?._text],
        '',
      )}`,
      EMIT_CRT: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.emit?.CRT?._text],
        '',
      )}`,
      DEST_CNPJ: `${validarReferenciaNFE(
        [
          xmlData?.nfeProc?.NFe?.infNFe?.dest?.CPF
            ? xmlData?.nfeProc?.NFe?.infNFe?.dest?.CPF?._text
            : xmlData?.nfeProc?.NFe?.infNFe?.dest?.CNPJ?._text,
        ],
        '',
      )}`,
      DEST_xNome: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.xNome?._text],
        '',
      )}`,
      DEST_xLgr: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.xLgr?._text],
        '',
      )}`,
      DEST_nro: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.nro?._text],
        '',
      )}`,
      DEST_xBairro: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.xBairro?._text],
        '',
      )}`,
      DEST_cMun: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.cMun?._text],
        '',
      )}`,
      DEST_xMun: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.xMun?._text],
        '',
      )}`,
      DEST_UF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.UF?._text],
        '',
      )}`,
      DEST_CEP: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.CEP?._text],
        '',
      )}`,
      DEST_cPais: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.cPais?._text],
        '',
      )}`,
      DEST_xPais: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.xPais?._text],
        '',
      )}`,
      DEST_fone: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.enderDest?.fone?._text],
        '',
      )}`,
      DEST_indIEDest: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.indIEDest?._text],
        '',
      )}`,
      DEST_IE: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.IE?._text],
        '',
      )}`,
      DEST_email: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.dest?.email?._text],
        '',
      )}`,
      TOTAL_vBC: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vBC?._text],
        '0.00',
      )}`,
      TOTAL_vICMS: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vICMS?._text],
        '0.00',
      )}`,
      TOTAL_vICMSDeson: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vICMSDeson?._text],
        '0.00',
      )}`,
      TOTAL_vFCP: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vFCP?._text],
        '0.00',
      )}`,
      TOTAL_vBCST: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vBCST?._text],
        '0.00',
      )}`,
      TOTAL_vST: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vST?._text],
        '0.00',
      )}`,
      TOTAL_vFCPST: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vFCPST?._text],
        '0.00',
      )}`,
      TOTAL_vFCPSTRet: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vFCPSTRet?._text],
        '0.00',
      )}`,
      TOTAL_vProd: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vProd?._text],
        '0.00',
      )}`,
      TOTAL_vFrete: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vFrete?._text],
        '0.00',
      )}`,
      TOTAL_vSeg: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vSeg?._text],
        '0.00',
      )}`,
      TOTAL_vDesc: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vDesc?._text],
        '0.00',
      )}`,
      TOTAL_vII: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vII?._text],
        '0.00',
      )}`,
      TOTAL_vIPI: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vIPI?._text],
        '0.00',
      )}`,
      TOTAL_vIPIDevol: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vIPIDevol?._text],
        '0.00',
      )}`,
      TOTAL_vPIS: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vPIS?._text],
        '0.00',
      )}`,
      TOTAL_vCOFINS: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vCOFINS?._text],
        '0.00',
      )}`,
      TOTAL_vOutro: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vOutro?._text],
        '0.00',
      )}`,
      TOTAL_vNF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vNF?._text],
        '',
      )}`,
      TOTAL_vTotTrib: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.total?.ICMSTot?.vTotTrib?._text],
        '0.00',
      )}`,
      TRANSP_modFrete: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.modFrete?._text],
        '',
      )}`,
      TRANSP_CNPJ: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.CNPJ?._text],
        '',
      )}`,
      TRANSP_xNome: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.xNome?._text],
        '',
      )}`,
      TRANSP_IE: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.IE?._text],
        '',
      )}`,
      TRANSP_xEnder: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.xEnder?._text],
        '',
      )}`,
      TRANSP_xMun: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.xMun?._text],
        '',
      )}`,
      TRANSP_UF: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.transporta?.UF?._text],
        '',
      )}`,
      TRANSP_qVol: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.vol?.qVol?._text],
        '',
      )}`,
      TRANSP_esp: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.vol?.esp?._text],
        '',
      )}`,
      TRANSP_marca: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.vol?.marca?._text],
        '',
      )}`,
      TRANSP_pesoL: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.vol?.pesoL?._text],
        '',
      )}`,
      TRANSP_pesoB: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.transp?.vol?.pesoB?._text],
        '',
      )}`,
      INFADIC_infCpl: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.NFe?.infNFe?.infAdic?.infCpl?._text],
        '',
      )}`,
      PROTNFE_tpAmb: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.tpAmb?._text],
        '',
      )}`,
      PROTNFE_verAplic: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.verAplic?._text],
        '',
      )}`,
      PROTNFE_chNFe: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.chNFe?._text],
        '',
      )}`,
      PROTNFE_dhRecbto: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.dhRecbto?._text],
        '',
      )}`,
      PROTNFE_nProt: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.nProt?._text],
        '',
      )}`,
      PROTNFE_digVal: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.digVal?._text],
        '',
      )}`,
      PROTNFE_cStat: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.cStat?._text],
        '',
      )}`,
      PROTNFE_xMotivo: `${validarReferenciaNFE(
        [xmlData?.nfeProc?.protNFe?.infProt?.xMotivo?._text],
        '',
      )}`,
      itens: [
        ...xmlData.nfeProc.NFe.infNFe.det.map((item: any, index: any) => ({
          nItem: String(index + 1),
          status: false,
          erros: [],
          variacoes: [],
          produto_id: null,
          PROD_cProd: `${validarReferenciaNFE([item?.prod?.cProd?._text], '')}`,
          PROD_cEAN: `${validarReferenciaNFE(
            [item?.prod?.cEAN?._text, 'NENHUM'],
            '',
          )}`,
          PROD_xProd: `${validarReferenciaNFE([item?.prod?.xProd?._text], '')}`,
          PROD_NCM: `${validarReferenciaNFE([item?.prod?.NCM?._text], '')}`,
          PROD_CFOP: `${validarReferenciaNFE([item?.prod?.cProd?._text], '')}`,
          PROD_uCom: `${validarReferenciaNFE([item?.prod?.uCom?._text], '')}`,
          PROD_qCom: `${validarReferenciaNFE([item?.prod?.qCom?._text], '')}`,
          PROD_vUnCom: `${validarReferenciaNFE(
            [item?.prod?.vUnCom?._text],
            '',
          )}`,
          PROD_vProd: `${validarReferenciaNFE([item?.prod?.vProd?._text], '')}`,
          PROD_cEANTrib: `${validarReferenciaNFE(
            [item?.prod?.cEANTrib?._text],
            'NENHUM',
          )}`,
          PROD_uTrib: `${validarReferenciaNFE([item?.prod?.uTrib?._text], '')}`,
          PROD_qTrib: `${validarReferenciaNFE([item?.prod?.qTrib?._text], '')}`,
          PROD_vUnTrib: `${validarReferenciaNFE(
            [item?.prod?.vUnTrib?._text],
            '',
          )}`,
          PROD_indTot: `${validarReferenciaNFE(
            [item?.prod?.indTot?._text],
            '',
          )}`,
          IMPOSTO_vTotTrib: `${validarReferenciaNFE(
            [item?.prod?.vTotTrib?._text],
            '0.00',
          )}`,
          IMPOSTO_ICMS_orig: `${validarReferenciaNFE(
            [item?.imposto?.ICMS?.ICMS10?.orig?._text],
            '0.00',
          )}`,
          IMPOSTO_ICMS_CST: `${validarReferenciaNFE(
            [item?.imposto?.ICMS?.ICMS10?.CST?._text],
            '0.00',
          )}`,
          IMPOSTO_ICMS_modBC: `${checkNFEPropIsEmpty(
            item?.imposto?.ICMS?.ICMS10?.modBC?._text,
            '3',
          )}`,
          IMPOSTO_ICMS_vBC: `${validarReferenciaNFE(
            [item?.imposto?.ICMS?.ICMS10?.vBC?._text],
            '0.00',
          )}`,
          IMPOSTO_ICMS_pICMS: `${validarReferenciaNFE(
            [item?.imposto?.ICMS?.ICMS10?.pICMS?._text],
            '0.00',
          )}`,
          IMPOSTO_ICMS_vICMS: `${validarReferenciaNFE(
            [item?.imposto?.ICMS?.ICMS10?.vICMS?._text],
            '0.00',
          )}`,
          IMPOSTO_IPI_cEnq: `${checkNFEPropIsEmpty(
            item?.imposto?.IPI?.cEnq?._text,
            '999',
          )}`,
          IMPOSTO_IPI_CST: `${checkNFEPropIsEmpty(
            item?.imposto?.IPI?.IPITrib?.CST?._text,
            '',
          )}`,
          IMPOSTO_IPI_vBC: `${validarReferenciaNFE(
            [item?.imposto?.IPI?.IPITrib?.vBC?._text],
            '0.00',
          )}`,
          IMPOSTO_IPI_pIPI: `${validarReferenciaNFE(
            [item?.imposto?.IPI?.IPITrib?.pIPI?._text],
            '0.00',
          )}`,
          IMPOSTO_IPI_vIPI: `${validarReferenciaNFE(
            [item?.imposto?.IPI?.IPITrib?.vIPI._text],
            '0.00',
          )}`,
          IMPOSTO_PIS_CST: `${validarReferenciaNFE(
            [item?.imposto?.PIS?.PISAliq?.CST?._text],
            '',
          )}`,
          IMPOSTO_PIS_vBC: `${validarReferenciaNFE(
            [item?.imposto?.PIS?.PISAliq?.vBC?._text],
            '0.00',
          )}`,
          IMPOSTO_PIS_pPIS: `${validarReferenciaNFE(
            [item?.imposto?.PIS?.PISAliq?.pPIS?._text],
            '0.00',
          )}`,
          IMPOSTO_PIS_vPIS: `${validarReferenciaNFE(
            [item?.imposto?.PIS?.PISAliq?.vPIS?._text],
            '0.00',
          )}`,
          IMPOSTO_COFINS_CST: `${validarReferenciaNFE(
            [item?.imposto?.COFINS?.COFINSAliq?.CST?._text],
            '',
          )}`,
          IMPOSTO_COFINS_vBC: `${validarReferenciaNFE(
            [item?.imposto?.COFINS?.COFINSAliq?.vBC?._text],
            '0.00',
          )}`,
          IMPOSTO_COFINS_pCOFINS: `${validarReferenciaNFE(
            [item?.imposto?.COFINS?.COFINSAliq?.pCOFINS?._text],
            '0.00',
          )}`,
          IMPOSTO_COFINS_vCOFINS: `${validarReferenciaNFE(
            [item?.imposto?.COFINS?.COFINSAliq?.vCOFINS?._text],
            '0.00',
          )}`,
          IMPOSTO_clEnq: `${validarReferenciaNFE(
            [item?.imposto?.clEnq?._text],
            '',
          )}`,
          IMPOSTO_cEnq: `${validarReferenciaNFE(
            [item?.imposto?.cEnq?._text],
            '',
          )}`,
          IMPOSTO_cSelo: `${validarReferenciaNFE(
            [item?.imposto?.cSelo?._text],
            '',
          )}`,
          IMPOSTO_qSelo: `${validarReferenciaNFE(
            [item?.imposto?.qSelo?._text],
            '',
          )}`,
          IMPOSTO_CST: `${validarReferenciaNFE(
            [item?.imposto?.CST?._text],
            '',
          )}`,
          IMPOSTO_CNPJProd: `${validarReferenciaNFE(
            [item?.imposto?.CNPJProd?._text],
            '',
          )}`,
          IMPOSTO_qUnid: `${validarReferenciaNFE(
            [item?.imposto?.qUnid?._text],
            '0',
          )}`,
          IMPOSTO_vUnid: `${validarReferenciaNFE(
            [item?.imposto?.vUnid?._text],
            '0.00',
          )}`,
          IMPOSTO_vIPI: `${validarReferenciaNFE(
            [item?.imposto?.vIPI?._text],
            '0.00',
          )}`,
          IMPOSTO_nFCI: `${validarReferenciaNFE(
            [item?.imposto?.nFCI?._text],
            '',
          )}`,
          COMPRA_xPed: `${validarReferenciaNFE(
            [item?.compra?.xPed?._text],
            '0',
          )}`,
          COMPRA_nItemPed: `${validarReferenciaNFE(
            [item?.compra?.nItemPed?._text],
            '0',
          )}`,
        })),
      ],
      pagamento: {
        FAT_nFat: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.nFat?._text],
          '',
        )}`,
        FAT_vOrig: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.vOrig?._text],
          '',
        )}`,
        FAT_vDesc: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.vDesc?._text],
          '',
        )}`,
        FAT_vLiq: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.vLiq?._text],
          '',
        )}`,
        duplicatas: [],
        PAG_indPag: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.indPag?._text],
          '',
        )}`,
        PAG_tPag: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.tPag?._text],
          '',
        )}`,
        PAG_vPag: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.vPag?._text],
          '',
        )}`,
        PAG_tpIntegra: `${validarReferenciaNFE(
          [xmlData?.nfeProc?.NFe?.infNFe?.pag?.detPag?.tpIntegra?._text],
          '',
        )}`,
      },
    };

    return sisAutNFE;
  } catch (e) {
    throw new Error(`falha na conversão, ${e}`);
  }
};

/**
 * @function fetchDescForIndPag - Tradução da IndPag
 * @param string pIndPag IndPag (0 ou 1)
 * @return string
 */
const fetchDescForIndPag = (pIndPag: string): string => {
  if (pIndPag === '0') {
    return 'Pagamento à Vista';
  }
  if (pIndPag === '1') {
    return 'Pagamento à Prazo';
  }
  return 'N/I';
};

/**
 * @function fetchDescForTPag - Tradução da DescForTPag
 * @param string pTpPag pTpPag (01,02,03,04, ...etc)
 * @return string
 */
const fetchDescForTPag = (pTpPag: string): string => {
  if (pTpPag === '01') return 'Dinheiro ';
  if (pTpPag === '02') return 'Cheque';
  if (pTpPag === '03') return 'Cartão de Crédito';
  if (pTpPag === '04') return 'Cartão de Débito ';
  if (pTpPag === '05') return 'Crédito Loja ';
  if (pTpPag === '10') return 'Vale Alimentação';
  if (pTpPag === '11') return 'Vale Refeição ';
  if (pTpPag === '12') return 'Vale Presente ';
  if (pTpPag === '13') return 'Vale Combustível ';
  if (pTpPag === '15') return 'Boleto Bancário ';
  if (pTpPag === '90') return 'Sem pagamento (apenas para NFe)';
  if (pTpPag === '99') return 'Outros';
  return 'N/I';
};

export {
  formatBytesNFE,
  validarReferenciaNFE,
  xmlJSONtoNFE,
  fetchDescForIndPag,
  fetchDescForTPag,
};
