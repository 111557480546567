import React, { useRef, useCallback, useState, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  Input,
  Loader,
  Lug,
} from '~/components';

import { Container, Content, Footer } from './styles';

import {
  FiltroType,
  SelectType,
  TipoProdutosType,
  GrupotagsType,
  TagType,
} from '~/types';

import { LugItemProps } from '~/components/Lug/interface';

import { FiltrosProps, FormData } from './interface';

const Filtros: React.FC<FiltrosProps> = ({ aoAplicarFiltros }) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const [tipos, setTipos] = useState<SelectType[]>([]);

  // TAGS
  const [tagsItens, setTagsItens] = useState<LugItemProps[]>([]);

  const onSelectItensTags = (cItens: LugItemProps[]) => {
    setTagsItens(cItens);
  };

  const submeterFormulario = useCallback(
    (data: FormData) => {
      const filtros: FiltroType[] = [];
      const { descricao, codigo, referencia, tipo_id, ativo } = data;

      filtros.push({
        nome: 'descricao',
        valor: descricao,
      });

      filtros.push({
        nome: 'codigo',
        valor: codigo,
      });

      filtros.push({
        nome: 'referencia',
        valor: referencia,
      });

      filtros.push({
        nome: 'tipo_id',
        valor: tipo_id,
      });

      filtros.push({
        nome: 'ativo',
        valor: ativo,
      });

      filtros.push({
        nome: 'tags',
        valor: tagsItens
          .filter(cTag => cTag.active)
          .reduce((acc: string[], cItem: LugItemProps) => {
            acc.push(cItem.value);
            return acc;
          }, [])
          .join(','),
      });

      aoAplicarFiltros(filtros);
    },
    [aoAplicarFiltros, tagsItens],
  );

  useEffect(() => {
    const loadTipos = async () => {
      try {
        setLoading(true);
        const tiposFetch = await api.get('produtos/tipos');
        const tiposFetched = [] as SelectType[];
        tiposFetch.data.forEach((item: TipoProdutosType) => {
          tiposFetched.push({
            value: item.id,
            label: item.descricao,
            color: '',
          });
        });
        setTipos(tiposFetched);
        setLoading(false);
      } catch (e) {
        setTipos([]);
        setLoading(false);
      }
    };

    loadTipos();
  }, []);

  useEffect(() => {
    async function loadGrupoTags() {
      try {
        setLoading(true);
        const response = await api.get(`grupo-tags/?tipo=produto`);
        const { data } = response;

        const output: LugItemProps[] = [];

        if (data && data.length) {
          data.forEach((cGrupoTag: GrupotagsType) => {
            if (cGrupoTag) {
              const { tags } = cGrupoTag;
              if (tags && tags.length) {
                tags.forEach((cTag: TagType) => {
                  output.push({
                    active: false,
                    label: cTag.nome,
                    value: cTag.id,
                    color: `${cTag.cor}`,
                    group: cGrupoTag.nome,
                  });
                });
              }
            }
          });
        }

        setTagsItens(output);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (e) {
        setLoading(false);
      }
    }
    loadGrupoTags();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Content>
        <Form
          ref={formRef}
          onSubmit={submeterFormulario}
          initialData={{
            descricao: '',
            codigo: '',
            referencia: '',
            tipo_id: '',
            ativo: '1',
          }}
        >
          <InputContainer>
            <span>Descrição</span>
            <div>
              <Input name="descricao" placeholder="Descrição..." />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Código</span>
            <div>
              <Input name="codigo" placeholder="Código..." />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Referência</span>
            <div>
              <Input name="referencia" placeholder="Referência..." />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tipo</span>
            <div>
              <Select name="tipo_id" options={tipos} loading={false} />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Tags</span>
            <div>
              <Lug
                title="SELECIONE AS TAGS DE PRODUTOS"
                itens={tagsItens}
                onSelectItens={onSelectItensTags}
                placeholder="Selecione as tags..."
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Ativo</span>
            <div>
              <Select
                loading={false}
                name="ativo"
                defaultValue={{ value: '1', label: 'SIM', cor: '' }}
                options={[
                  { value: '1', label: 'SIM', cor: '' },
                  { value: '0', label: 'NÃO', cor: '' },
                ]}
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button
                style={{ width: 150 }}
                type="submit"
                background={colors.green}
              >
                Filtrar
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default Filtros;
