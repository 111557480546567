import styled, { css } from 'styled-components';

export const ListBodyContainer = styled.tbody`
  ${({ theme }) => css`
    td:first-child {
      border-left: 0.5rem solid transparent;
    }

    tr:nth-child(odd) td {
      background: ${theme.colors.defaultHighlight};
    }

    tr:last-child td {
    }

    td:last-child {
      border-right: none;
    }
  `}
`;
