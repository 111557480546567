import React from 'react';

import {
  HeaderContainer,
  HeaderLeft,
  HeaderCenter,
  HeaderWorkspace,
  HeaderRight,
  HeaderRightOpt,
} from './styles';

import HeaderMenu from './HeaderMenu';
import HeaderSearch from './HeaderSearch';
import HeaderNotifications from './HeaderNotifications';
import HeaderPerfil from './HeaderPerfil';
import HeaderSummary from './HeaderSummary';
import NovaTab from './NovaTab';

import { useAuth } from '~/hooks/auth';

import { HeaderProps } from './interfaces';

const Header: React.FC<HeaderProps> = ({ openSearch }) => {
  const { user } = useAuth();

  return (
    <HeaderContainer>
      <HeaderLeft>
        <HeaderMenu />
        <HeaderSearch openSearch={openSearch} />
      </HeaderLeft>
      <HeaderCenter>
        <NovaTab />
        <HeaderWorkspace>
          <h2>Workspace</h2>
          <h4>{user.workspace.nome}</h4>
        </HeaderWorkspace>
      </HeaderCenter>
      <HeaderRight>
        <HeaderRightOpt>
          <HeaderNotifications />
        </HeaderRightOpt>

        <HeaderRightOpt>
          <HeaderPerfil />
        </HeaderRightOpt>
        <HeaderRightOpt>
          <HeaderSummary />
        </HeaderRightOpt>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
