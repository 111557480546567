import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '~/hooks/auth';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';

import { useTheme } from 'styled-components';
import { BsFillTriangleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { Container, Content } from './styles';

import Header from '~/components/Header';
import List from '~/components/List';

import { Plate, Button, Loader, NoPermission } from '~/components';

import api from '~/services/api';

import { useToast } from '~/hooks/toast';
import { validarPermissao } from '~/utils/permissions';

const NaturezaDeOperacoess: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useAuth();
  const history = useNavigate();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [naturezasdeoperacoes, setNaturezasdeoperacoes] = useState<Array<any>>(
    [],
  );

  const fetchNaturezasOperacoes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('pedido/natureza-operacao');
      const { data } = response;
      if (data && data.status !== 'error') {
        setNaturezasdeoperacoes(response.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const handlerRemoveNaturezaDeOperacao = useCallback(
    (naturezadeoperacao: any) => {
      swal
        .fire({
          title: `Deseja realmente remover o natureza de operação "${naturezadeoperacao.descricao}"?`,
          text: `Ao confirmar, a natureza de operação será removido!`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              await api.delete(`NaturezaDeOperacoes/${naturezadeoperacao.id}`);
              await fetchNaturezasOperacoes();
            } catch (e) {
              console.log(e);
            }
            addToast({
              type: 'success',
              title: 'Concluido',
              description: `NaturezaDeOperacoes "${naturezadeoperacao.descricao}" deletado com sucesso!`,
            });
          }
        });
    },
    [fetchNaturezasOperacoes, addToast],
  );

  const handlerEditNaturezaDeOperacao = useCallback(
    (naturezadeoperacao: any) => {
      history(`/natureza-de-operacao/${naturezadeoperacao.id}`);
    },
    [history],
  );

  useEffect(() => {
    fetchNaturezasOperacoes();
  }, [fetchNaturezasOperacoes]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'listar-natureza-operacao')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Plate
          title="Natureza de Operações"
          subtitle="Gestão das natureza de operações"
          icon={() => <BsFillTriangleFill size={32} color={colors.label} />}
        >
          <Link to="/natureza-de-operacao/0">
            <Button type="button" background={colors.green}>
              Novo
            </Button>
          </Link>
        </Plate>

        {naturezasdeoperacoes ? (
          <List
            header={[
              {
                column: 'descricao',
                label: 'Descrição',
                search: false,
              },
              {
                column: 'ativo',
                label: 'Ativo?',
                search: false,
              },
            ]}
            data={[
              ...naturezasdeoperacoes.map(naturezadeoperacao => ({
                ...naturezadeoperacao,
                ativo: naturezadeoperacao.ativo ? 'SIm' : 'Não',
              })),
            ]}
            options={[
              {
                label: 'Editar',
                onPress: item => {
                  handlerEditNaturezaDeOperacao(item);
                },
              },
              {
                label: 'Remover',
                onPress: item => {
                  handlerRemoveNaturezaDeOperacao(item);
                },
              },
            ]}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default NaturezaDeOperacoess;
