/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import 'react-day-picker/dist/style.css';
import { useTheme } from 'styled-components';

import { BiSupport } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CgTrendingDown } from 'react-icons/cg';
import { FaCartPlus, FaDatabase, FaListAlt, FaSyncAlt } from 'react-icons/fa';
import { GiShakingHands } from 'react-icons/gi';
import { ImArrowDown, ImArrowUp, ImUserTie, ImUsers } from 'react-icons/im';
import { IoMdDownload } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { Loader } from '~/components';

import Header from '~/components/Header';

import {
  Area,
  Block,
  Blocks,
  Card,
  Container,
  Content,
  Grid,
  Head,
  Image,
  Information,
  Line,
  Opt,
  Opts,
  Title,
  Welcome,
} from './styles';

import Cards from './Cards';

import Graph1 from './Graph1';
import Graph2 from './Graph2';
import Graph3 from './Graph3';

import { CONTAINER_ANIMATION } from './animations';

import { useAuth } from '~/hooks/auth';
import { DadosProps } from './interface';
import { handlerNumberToString } from '~/utils/money';

const Dashboard: React.FC = () => {
  const { colors } = useTheme();
  const history = useNavigate();
  const { user, getAuthUser } = useAuth();

  const [dados, setDados] = useState<DadosProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadDashboard = async () => {
      try {
        setLoading(true);

        const response = await api.get(`dashboard`);

        const { data } = response;

        setDados(data);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (e) {
        setLoading(true);
      }
    };

    loadDashboard();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <Head>
        <Welcome>
          <h1>
            Bem vindo <strong>{user.nome}</strong>
          </h1>
          <h2>
            Você possui <ins>0 notificações</ins> abertas!
          </h2>
        </Welcome>

        <Opts>
          <Opt onClick={() => history(`/pedido/0`)} $delay="0.4s">
            <p>
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
            </p>
            <i>
              <FaCartPlus size={32} color="#fff" />
            </i>
            <span>Novo Pedido</span>
          </Opt>
          <Opt onClick={() => history(`/parceiros`)} $delay="0.6s">
            <p>
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
            </p>
            <i>
              <ImUsers size={32} color="#fff" />
            </i>
            <span>Parceiros</span>
          </Opt>
          <Opt onClick={() => history(`/produtos`)} $delay="0.8s">
            <p>
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
            </p>
            <i>
              <FaListAlt size={32} color="#fff" />
            </i>
            <span>Produtos</span>
          </Opt>
          <Opt
            href="https://api.whatsapp.com/send?phone=553597305858"
            target="_blank"
            $delay="1s"
          >
            <p>
              <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
              </svg>
            </p>
            <i>
              <BiSupport size={32} color="#fff" />
            </i>
            <span>Suporte</span>
          </Opt>
        </Opts>
      </Head>

      {getAuthUser().isAdmin && dados != null ? (
        <Content>
          <Line>
            <Blocks>
              <Block>
                <Area>
                  <Title>
                    Pedidos
                    <div>
                      <button type="button">
                        <FaSyncAlt size={20} color={colors.label} />
                      </button>
                      <button type="button">
                        <BsThreeDotsVertical size={28} color={colors.label} />
                      </button>
                    </div>
                  </Title>
                  <Information>
                    <Cards variant={CONTAINER_ANIMATION}>
                      <Graph1
                        title="Pedidos"
                        data={
                          dados && dados.pedidos?.length ? dados.pedidos : []
                        }
                        loading={loading}
                      />
                    </Cards>
                  </Information>
                </Area>
              </Block>
            </Blocks>

            <Blocks>
              <Block>
                <Area $delay="1.2s">
                  <Title>
                    Receber
                    <span>
                      <ImArrowUp size={20} color="#fff" />
                    </span>
                  </Title>
                  <Card>
                    <h1>
                      R${' '}
                      {handlerNumberToString(
                        Number(
                          dados.totalContas.find(i => i.tipo == 'RECEBER')
                            ?.total,
                        ),
                      )}
                    </h1>
                    <h2>
                      <strong>
                        R${' '}
                        {handlerNumberToString(
                          Number(
                            dados.totalContas.find(i => i.tipo == 'RECEBER')
                              ?.liquidado,
                          ),
                        )}
                      </strong>{' '}
                      recebidos
                    </h2>
                  </Card>
                </Area>
                <Area $delay="1.4s">
                  <Title>
                    Pagar
                    <span>
                      <ImArrowDown size={20} color="#fff" />
                    </span>
                  </Title>
                  <Card>
                    <h1>
                      R${' '}
                      {handlerNumberToString(
                        Number(
                          dados.totalContas.find(i => i.tipo == 'PAGAR')?.total,
                        ),
                      )}
                    </h1>
                    <h2>
                      <strong style={{ color: colors.error }}>
                        R${' '}
                        {handlerNumberToString(
                          Number(
                            dados.totalContas.find(i => i.tipo == 'PAGAR')
                              ?.liquidado,
                          ),
                        )}
                      </strong>{' '}
                      pagos
                    </h2>
                  </Card>
                </Area>
              </Block>

              <Block>
                <Area $delay="1.6s">
                  <Title>
                    Ticket Médio
                    <span>
                      <ImUsers size={20} color="#fff" />
                    </span>
                  </Title>
                  <Card>
                    <h1>R$ {handlerNumberToString(dados.ticketMedioMes)}</h1>
                    <h2>
                      <strong>0</strong> produtos sem margem de lucro
                    </h2>
                  </Card>
                </Area>
                <Area $delay="1.8s">
                  <Title>
                    Produtos em Falta
                    <span>
                      <CgTrendingDown size={20} color="#fff" />
                    </span>
                  </Title>
                  <Card>
                    <h1>0</h1>
                    <h2>
                      <strong style={{ color: colors.error }}>
                        0 produtos
                      </strong>{' '}
                      em falta!
                    </h2>
                  </Card>
                </Area>
              </Block>
            </Blocks>
          </Line>

          <Line>
            <Blocks>
              <Block>
                <Area>
                  <Title>
                    Fluxo de Caixa
                    <div>
                      <button type="button">
                        <FaSyncAlt size={20} color={colors.label} />
                      </button>
                      <button type="button">
                        <BsThreeDotsVertical size={28} color={colors.label} />
                      </button>
                    </div>
                  </Title>
                  <Information>
                    <Cards variant={CONTAINER_ANIMATION}>
                      <Graph2
                        title="Fluxo de Caixa"
                        data={
                          dados && dados.fluxoCaixa?.length
                            ? dados.fluxoCaixa
                            : []
                        }
                        loading={loading}
                      />
                    </Cards>
                  </Information>
                </Area>
              </Block>
            </Blocks>
            <Blocks>
              <Block>
                <Area>
                  <Title>
                    Ranking de Vendedores
                    <div>
                      <button type="button">
                        <FaSyncAlt size={20} color={colors.label} />
                      </button>
                      <button type="button">
                        <BsThreeDotsVertical size={28} color={colors.label} />
                      </button>
                    </div>
                  </Title>
                  <Information>
                    <Cards variant={CONTAINER_ANIMATION}>
                      <Graph3
                        title="Ranking Vendedores Mês"
                        data={
                          dados && dados.vendasVendedores?.length
                            ? dados.vendasVendedores
                            : [{ vendedor: '', total: 0 }]
                        }
                        loading={loading}
                      />
                    </Cards>
                  </Information>
                </Area>
              </Block>
            </Blocks>
          </Line>

          <Line>
            <Area>
              <Title>
                Clientes
                <span>
                  <ImUsers size={20} color="#fff" />
                </span>
              </Title>
              <Card>
                <h1>{dados.totalClientes}</h1>
                <h2>
                  <strong>{dados.totalClientesPositivados}</strong> positivados
                </h2>
              </Card>
            </Area>
            <Area>
              <Title>
                Fornecedores
                <span>
                  <GiShakingHands size={20} color="#fff" />
                </span>
              </Title>
              <Card>
                <h1>{dados.totalFornecedores}</h1>
                <h2>
                  <strong>{dados.totalFornecedoresNovos}</strong> novo(s)
                </h2>
              </Card>
            </Area>
            <Area>
              <Title>
                Vendedores
                <span>
                  <ImUserTie size={20} color="#fff" />
                </span>
              </Title>
              <Card>
                <h1>{dados.totalVendedores}</h1>
                <h2>
                  <strong>{dados.totalVendedoresPositivados}</strong> venderam
                </h2>
              </Card>
            </Area>
            <Area>
              <Title>
                Produtos
                <span>
                  <FaDatabase size={20} color="#fff" />
                </span>
              </Title>
              <Card>
                <h1>{dados.totalProdutos}</h1>
                <h2>
                  <strong>{dados.totalProdutosNovos}</strong> novo(s)
                </h2>
              </Card>
            </Area>
          </Line>

          <Line>
            {dados && dados.rankingProdutos?.length ? (
              <Area>
                <Title>
                  Produtos Mais Vendidos
                  <div>
                    <button type="button">
                      <IoMdDownload size={26} color={colors.label} />
                    </button>
                    <button type="button">
                      <BsThreeDotsVertical size={28} color={colors.label} />
                    </button>
                  </div>
                </Title>
                <Information>
                  <Grid>
                    <thead>
                      <tr>
                        <td>Nome</td>
                        <td>Quantidade</td>
                      </tr>
                    </thead>
                    <tbody>
                      {dados.rankingProdutos.map(cProduto => (
                        <tr key={cProduto.descricao}>
                          <td>{cProduto.descricao}</td>
                          <td>{cProduto.quantidade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Grid>
                </Information>
              </Area>
            ) : null}

            {dados && dados.vendasVendedores?.length ? (
              <Area>
                <Title>
                  Vendedores
                  <div>
                    <button type="button">
                      <IoMdDownload size={26} color={colors.label} />
                    </button>
                    <button type="button">
                      <BsThreeDotsVertical size={28} color={colors.label} />
                    </button>
                  </div>
                </Title>
                <Information>
                  <Grid>
                    <thead>
                      <tr>
                        <td>Nome</td>
                        <td>Quantidade</td>
                      </tr>
                    </thead>
                    <tbody>
                      {dados.vendasVendedores.map(cVendedor => (
                        <tr key={cVendedor.vendedor}>
                          <td>{cVendedor.vendedor}</td>
                          <td>{cVendedor.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Grid>
                </Information>
              </Area>
            ) : null}
          </Line>
        </Content>
      ) : (
        <Image>
          <img
            alt="Imagem principal dashboard"
            src="https://onstack-erp.s3.us-east-2.amazonaws.com/img/main.png"
          />
        </Image>
      )}
    </Container>
  );
};

export default Dashboard;
