import React from 'react';

import { Container } from './styles';

import { TabLinkProps } from './interface';

const TabLink: React.FC<TabLinkProps> = ({ name, active, onSelectTab }) => {
  return (
    <Container $active={active} onClick={() => onSelectTab(name)}>
      {name}
    </Container>
  );
};

export default TabLink;
