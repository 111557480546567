import styled from 'styled-components';

export const TotalizerByPaymentMethodSection = styled.div`
  width: 100%;
`;

export const TotalizerByPaymentMethodSectionHeader = styled.div`
  strong {
    font-weight: bold;
    font-size: 14px;

    color: ${({ theme }) => theme.colors.label};
  }
`;

export const TotalizerByPaymentMethodSectionContent = styled.div`
  width: 100%;
  margin-top: 0.75rem;
`;

export const TotalizerByPaymentMethodSectionContentItem = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.label};
  font-size: 12px;

  strong {
    font-weight: bold;
  }

  span {
    font-weight: 500;
  }
`;
