import styled, { css } from 'styled-components';

import { TagDropContainerProps } from './interface';

export const TagDropContainer = styled.div<TagDropContainerProps>`
  ${({ theme, $active }) => css`
    width: 100%;
    height: auto;
    border: 0.1rem dashed;
    border-color: ${$active ? theme.colors.white : 'rgba(0,0,0,0)'};
    float: left;
    border-radius: 1rem;
  `}
`;
