import styled from 'styled-components';
import { AnimatedContainer } from '~/components';

import { MainProps } from './interface';

export const Container = styled(AnimatedContainer)`
  float: left;
  width: 100%;
`;

export const Content = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  margin-bottom: -5rem;
`;

export const Main = styled.div<MainProps>`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.$verticalidade ? 'column' : 'row')};
`;

export const Right = styled.div`
  float: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #b34;
`;
