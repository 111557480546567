import React, { useCallback, useRef, useState } from 'react';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import { Container } from './styles';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
} from '~/components';

import { FormaPagamentoFormData, FormaPagamentoProps } from './interface';

const FormaPagamento: React.FC<FormaPagamentoProps> = ({
  onRequestClose,
  onRequestSave,
  pagamento,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: FormaPagamentoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          FAT_nFat: Yup.string().required('Informe a FAT_nFat'),
        });

        await schema.validate(data, { abortEarly: false });

        setFormLoading(false);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'XML salvo com sucesso!',
        });

        onRequestSave();
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o xml, tente novamente...');
      }
    },
    [addToast, onRequestSave],
  );

  // const submitForm = () => {
  //   formRef.current?.submitForm();
  // };

  return (
    <Modal
      title={`${pagamento.PAG_indPag}`}
      width="100rem"
      visible
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} initialData={pagamento} onSubmit={handleSubmit}>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <FormBlock>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Ind. Forma de Pagamento</span>
                    <div>
                      <Input disabled name="PAG_indPag" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Meio de Pagamento</span>
                    <div>
                      <Input disabled name="PAG_tPag" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Descrição do Meio de Pagamento</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor Pagamento</span>
                    <div>
                      <Input disabled name="PAG_vPag" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Tipo de Integração Pagamento</span>
                    <div>
                      <Input disabled name="PAG_tpIntegra" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>CNPJ Credenciadora</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Bandeira Operadora</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Número de Autorização</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Troco</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
              </FormBlock>

              <FormFooter>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
              </FormFooter>
            </FormContent>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default FormaPagamento;
