import JsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';

import { ContaType, RelatorioType, TotalizadorType } from '~/types';
import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatTheDateYall } from '~/utils/formatDate';
import { handlerNumberToString } from '~/utils/money';

const ordenarContas = (
  pContas: ContaType[],
  configuracao: RelatorioType,
): ContaType[] => {
  const ordenadores = configuracao.ordenar.filter(
    ordenador => ordenador.enable,
  );

  if (!ordenadores.length) {
    return pContas;
  }

  ordenadores.forEach(ordenador => {
    switch (ordenador.field) {
      case 'valor':
        if (ordenador.order === 'ASC') {
          pContas.sort((a, b) => (a.valor > b.valor && 1) || -1);
        } else {
          pContas.sort((a, b) => (b.valor > a.valor && 1) || -1);
        }
        break;
      case 'valor_pago':
        if (ordenador.order === 'ASC') {
          pContas.sort((a, b) => (a.valor_pago > b.valor_pago && 1) || -1);
        } else {
          pContas.sort((a, b) => (b.valor_pago > a.valor_pago && 1) || -1);
        }
        break;

      case 'parceiro.nome_fantasia':
        if (ordenador.order === 'ASC') {
          pContas.sort(
            (a, b) =>
              (a.parceiro.nome_fantasia > b.parceiro.nome_fantasia && 1) || -1,
          );
        } else {
          pContas.sort(
            (a, b) =>
              (b.parceiro.nome_fantasia > a.parceiro.nome_fantasia && 1) || -1,
          );
        }
        break;

      case 'situacao':
        if (ordenador.order === 'ASC') {
          pContas.sort((a, b) => (a.situacao > b.situacao && 1) || -1);
        } else {
          pContas.sort((a, b) => (b.situacao > a.situacao && 1) || -1);
        }
        break;

      case 'vencimento':
        if (ordenador.order === 'ASC') {
          pContas.sort((a, b) => (a.vencimento > b.vencimento && 1) || -1);
        } else {
          pContas.sort((a, b) => (b.vencimento > a.vencimento && 1) || -1);
        }
        break;

      case 'parcela':
        if (ordenador.order === 'ASC') {
          pContas.sort((a, b) => (a.parcela > b.parcela && 1) || -1);
        } else {
          pContas.sort((a, b) => (b.parcela > a.parcela && 1) || -1);
        }
        break;

      default:
        break;
    }
  });

  return pContas;
};

const filtrarAgrupadoresPorAgrupamento = (
  pContas: ContaType[],
  pAgrupamento: string,
): string[] => {
  switch (pAgrupamento) {
    case 'parceiro':
      return pContas.reduce((acc: string[], conta: ContaType) => {
        if (!acc.includes(conta.parceiro.nome_fantasia)) {
          acc.push(conta.parceiro.nome_fantasia);
        }
        return acc;
      }, []);
    case 'situacao':
      return pContas.reduce((acc: string[], conta: ContaType) => {
        if (!acc.includes(conta.situacao)) {
          acc.push(conta.situacao);
        }
        return acc;
      }, []);
    default:
      return [];
  }
};

const filtrarContasPorAgrupador = (
  pContas: ContaType[],
  pAgrupamento: string,
  pAgrupador: string,
): ContaType[] => {
  switch (pAgrupamento) {
    case 'parceiro':
      return pContas.filter(
        conta => conta.parceiro.nome_fantasia === pAgrupador,
      );
    case 'situacao':
      return pContas.filter(conta => conta.situacao === pAgrupador);
    default:
      return [];
  }
};

// const gerarLogoTipoPDF = () => {
//   return '';
// };

const extrairPropriedadeLinhaPDF = (cConta: ContaType, field: string) => {
  let prop = '';

  switch (field) {
    case 'valor':
      prop = `${handlerNumberToString(cConta.valor)}`;
      break;
    case 'valor_pago':
      prop = `${handlerNumberToString(cConta.valor_pago)}`;
      break;
    case 'parceiro.nome_fantasia':
      prop = `${cConta.parceiro.nome_fantasia}`;
      break;
    case 'situacao':
      prop = `${cConta.situacao}`;
      break;
    case 'vencimento':
      prop = `${formatTheDateYall(cConta.vencimento)}`;
      break;
    case 'parcela':
      prop = `${cConta.parcela}`;
      break;
    case 'condicao_pagamento.descricao':
      prop = ``;
      break;
    case 'vendedor.nome_fantasia':
      prop = ``;
      break;
    default:
      prop = '';
      break;
  }

  return prop;
};

const calcularLargura = (tamanhoEmPorcentagem: number, largura: number) => {
  const larguraDescontada = largura - 30;
  const tamanhoCelula = larguraDescontada * (tamanhoEmPorcentagem / 100);
  return tamanhoCelula;
};

const gerarLinhasPDF = (
  pContas: ContaType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const output: RowInput[] = [];
  pContas.forEach(cConta => {
    const line: RowInput = [];

    const ordenadores = pConfiguracao.ordenar.filter(
      ordenador => ordenador.enable,
    );

    let cacheTotalLarguras = 0;
    ordenadores.forEach((ordenador, key) => {
      let larguraColuna = ordenador.width;
      cacheTotalLarguras += larguraColuna;

      const ultimaColuna = ordenadores.length === key + 1;
      if (ultimaColuna) {
        const larguraPreenchivel = 100 - (cacheTotalLarguras - larguraColuna);
        if (larguraPreenchivel > 0) {
          larguraColuna = larguraPreenchivel;
        }
      }

      line.push({
        content: `${extrairPropriedadeLinhaPDF(cConta, ordenador.field)}`,
        styles: {
          cellWidth: calcularLargura(larguraColuna, largura),
        },
      });
    });

    output.push(line);
  });

  return output;
};

const renderizarListagemPDF = (
  pContas: Array<ContaType>,
  pAgrupamentos: Array<string>,
  pConfiguracao: RelatorioType,
  largura: number,
) => {
  let output: RowInput[] = [];

  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  if (!pAgrupamentos.length) {
    output = [...gerarLinhasPDF(pContas, pConfiguracao, largura)];
    return output;
  }

  const currentAgrupamento = pAgrupamentos[0];

  const agrupadores = filtrarAgrupadoresPorAgrupamento(
    pContas,
    currentAgrupamento,
  );

  agrupadores.forEach(agrupador => {
    const contasNoAgrupador = renderizarListagemPDF(
      filtrarContasPorAgrupador(pContas, currentAgrupamento, agrupador),
      [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
      pConfiguracao,
      largura,
    );

    output = [
      ...output,
      [
        {
          content: `${agrupador}`,
          colSpan: totalColunas,
          styles: { halign: 'center', fillColor: '#ccc' },
        },
      ],
      ...contasNoAgrupador,
    ];
  });

  return output;
};

const gerarTabelaPDF = (
  pContas: ContaType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const cAgrupadores = pConfiguracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);
  return renderizarListagemPDF(pContas, cAgrupadores, pConfiguracao, largura);
};

const gerarTotalizadoresPDF = (
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
) => {
  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  const totalizadoresPDF: RowInput[] = [];

  pTotalizadores.forEach(totalizador => {
    let enable = false;

    pConfiguracao.totalizar.forEach(cfgTotalizador => {
      if (cfgTotalizador.field === totalizador.chave && cfgTotalizador.enable) {
        enable = true;
      }
    });

    if (enable) {
      totalizadoresPDF.push([
        {
          content: `${totalizador.chave}`,
          colSpan: totalColunas - 1,
          styles: { halign: 'right' },
        },
        {
          content: `${handlerNumberToString(Number(totalizador.valor))}`,
          styles: { halign: 'center' },
        },
      ]);
    }
  });

  return totalizadoresPDF;
};

const exportarPDF = (
  pCabecalho: RelatorioCabecalhoType,
  pContas: ContaType[],
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
): void => {
  const handler = async () => {
    const doc = new JsPDF({
      orientation: pConfiguracao.modo === 'RETRATO' ? 'portrait' : 'landscape',
    });

    let cMarginTop = 34;
    let h = 26;
    if (
      !pCabecalho.subtitle1 &&
      !pCabecalho.subtitle2 &&
      !pCabecalho.subtitle3
    ) {
      h = 13;
      cMarginTop = 21;
    } else if (!pCabecalho.subtitle2 && !pCabecalho.subtitle3) {
      h = 18;
      cMarginTop = 26;
    } else if (!pCabecalho.subtitle3) {
      h = 22;
      cMarginTop = 30;
    }

    autoTable(doc, {
      headStyles: {
        lineColor: '#000',
        overflow: 'ellipsize',
      },

      bodyStyles: {
        overflow: 'ellipsize',
      },

      didDrawPage: data => {
        // LOGOTIPO
        // const base64Img = gerarLogoTipoPDF();

        doc.setFillColor(255, 250, 250);

        // NOME DA EMPRESA
        doc.setFillColor(255, 250, 250);
        doc.setDrawColor(140, 143, 157);

        doc.roundedRect(
          data.settings.margin.left,
          5,
          doc.internal.pageSize.getWidth() -
            data.settings.margin.left -
            data.settings.margin.right -
            2,
          h,
          1,
          1,
          'FD',
        );
        doc.setTextColor(40);
        doc.setFontSize(14);
        doc.setFont('Courier-Bold', 'bold');
        doc.text(pCabecalho.title, data.settings.margin.left + 4, 13);

        doc.setFontSize(10);
        doc.setFont('Courier', 'bold');

        if (pCabecalho.subtitle1) {
          doc.text(pCabecalho.subtitle1, data.settings.margin.left + 4, 18);
        }

        doc.setFont('Courier', 'normal');

        if (pCabecalho.subtitle2) {
          doc.text(pCabecalho.subtitle2, data.settings.margin.left + 4, 22);
        }

        if (pCabecalho.subtitle3) {
          doc.text(pCabecalho.subtitle3, data.settings.margin.left + 4, 26);
        }
      },

      margin: { top: cMarginTop },

      // CABEÇALHO
      head: [
        [
          ...pConfiguracao.ordenar
            .filter(ordenador => ordenador.enable)
            .map(ordenador => ({
              content: ordenador.label,
            })),
        ],
      ],

      // CORPO
      body: [
        ...gerarTabelaPDF(
          pContas,
          pConfiguracao,
          doc.internal.pageSize.getWidth(),
        ),
        ...gerarTotalizadoresPDF(pTotalizadores, pConfiguracao),
      ],
    });

    doc.output('dataurlnewwindow');
    // doc.save('relatório.pdf');
  };

  handler();
};

export {
  ordenarContas,
  filtrarAgrupadoresPorAgrupamento,
  filtrarContasPorAgrupador,
  exportarPDF,
};
