import styled, { css } from 'styled-components';
import ScrollArea from 'react-scrollbar';

import { ListScrollContainerProps } from './interface';

export const ListScrollContainer = styled.div<ListScrollContainerProps>`
  ${({ theme, $width, $fixed }) => css`
    width: ${$width ? `${$width}px !important` : `100%`};
    box-shadow: ${$fixed ? theme.shadows.left : 'none'};
    float: left;
    overflow: hidden;

    .scrollarea {
      float: left;
      height: 100%;
      width: 100%;
    }

    .scrollarea-content {
      height: 100%;
      $width: auto;
      float: left;
      min-width: 100%;
    }

    tbody {
      td {
        border-left: ${$fixed
          ? 'none !important'
          : `0.5rem solid transparent !important`};
      }

      tr:hover {
        td:first-child {
          border-left: ${$fixed
            ? 'none !important'
            : `0.5rem solid ${theme.colors.white} !important`};
        }
      }
    }

    thead {
      td {
        border-right: ${$fixed ? 'none !important' : 'inherit'};
        text-align: ${$fixed ? 'center' : 'flex-start'};
      }
    }
  `}
`;

export const Scroll = styled(ScrollArea).attrs({
  contentStyle: {
    paddingBottom: 0,
    horizontal: true,
  },
  horizontalScrollbarStyle: {
    height: 30,
  },
})``;
