import styled, { css } from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ $visible }) => css`
    width: 100%;
    float: left;
    display: ${$visible ? 'block' : 'none'};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 1rem;
    border-radius: 0.5rem;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    float: left;
    transition: all 0.5s ease;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }

    button {
      margin: 0 1rem;
      background-color: transparent;
      color: ${theme.colors.label};
      font-size: 1.5rem;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: flex-end;

    div {
      display: flex;
      flex-direction: row;
      width: 50%;
      button {
        margin-left: 10px;
      }
    }
  `}
`;
