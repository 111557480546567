import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    margin-bottom: -2rem;

    h1 {
      margin: 0px;
      width: 100%;
      font-size: ${theme.fontSizes.default};
      padding: 20px;
      color: ${theme.colors.placeholder};
    }
  `}
`;
