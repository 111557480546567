import React from 'react';

import { Modal } from '~/components';

import { Container } from './styles';

import { AjudaProps } from './interface';

const Ajuda: React.FC<AjudaProps> = ({ visible, onRequestClose }) => {
  return (
    <Modal
      title="Ajuda com a impressora"
      visible={visible}
      onRequestClose={onRequestClose}
      width="900px"
    >
      <Container>
        <div>
          <h1>Como habilitar sua impressora ZPL no seu navegador.</h1>

          <p>
            Os usuários agora podem adicionar rapidamente suporte de impressão
            USB ou baseado em rede a seus aplicativos baseados em navegador nos
            sistemas Windows 7, Windows 10 e Mac OSX, ao usar o Internet
            Explorer v11, Chrome ou Safari. O plugin fornece a adição de
            recursos de impressão simples e direta. Essa solução simplifica
            muito a tarefa de adicionar impressão em rede ou baseada em USB.
          </p>

          <a
            href="https://www.zebra.com/us/en/software/printer-software/browser-print.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            CLIQUE AQUI PARA CONTINUAR
          </a>
        </div>
      </Container>
    </Modal>
  );
};

export default Ajuda;
