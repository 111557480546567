import JsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';

import { DocumentoFiscalType, RelatorioType, TotalizadorType } from '~/types';
import { RelatorioCabecalhoType } from '~/types/relatorio';
import { formatTheDateYall } from '~/utils/formatDate';

import { handlerNumberToString } from '~/utils/money';

const ordenarDocumentoFiscals = (
  pDocumentoFiscals: DocumentoFiscalType[],
  configuracao: RelatorioType,
): DocumentoFiscalType[] => {
  const ordenadores = configuracao.ordenar.filter(
    ordenador => ordenador.enable,
  );

  if (!ordenadores.length) {
    return pDocumentoFiscals;
  }

  ordenadores.forEach(ordenador => {
    switch (ordenador.field) {
      case 'valor_total':
        if (ordenador.order === 'ASC') {
          pDocumentoFiscals.sort(
            (a, b) => (a.TOTAL_vNF > b.TOTAL_vNF && 1) || -1,
          );
        } else {
          pDocumentoFiscals.sort(
            (a, b) => (b.TOTAL_vNF > a.TOTAL_vNF && 1) || -1,
          );
        }
        break;
      case 'empresa.fantasia':
        if (ordenador.order === 'ASC') {
          pDocumentoFiscals.sort(
            (a, b) => (a.empresa.fantasia > b.empresa.fantasia && 1) || -1,
          );
        } else {
          pDocumentoFiscals.sort(
            (a, b) => (b.empresa.fantasia > a.empresa.fantasia && 1) || -1,
          );
        }
        break;

      case 'data':
        if (ordenador.order === 'ASC') {
          pDocumentoFiscals.sort(
            (a, b) => (a.created_at > b.created_at && 1) || -1,
          );
        } else {
          pDocumentoFiscals.sort(
            (a, b) => (b.created_at > a.created_at && 1) || -1,
          );
        }
        break;

      default:
        break;
    }
  });

  return pDocumentoFiscals;
};

const filtrarAgrupadoresPorAgrupamento = (
  pDocumentoFiscals: DocumentoFiscalType[],
  pAgrupamento: string,
): string[] => {
  switch (pAgrupamento) {
    case 'empresa.fantasia':
      return pDocumentoFiscals.reduce(
        (acc: string[], documento: DocumentoFiscalType) => {
          if (!acc.includes(documento.empresa.fantasia)) {
            acc.push(documento.empresa.fantasia);
          }
          return acc;
        },
        [],
      );

    case 'DEST_xNome':
      return pDocumentoFiscals.reduce(
        (acc: string[], documento: DocumentoFiscalType) => {
          if (!acc.includes(documento.DEST_xNome)) {
            acc.push(documento.DEST_xNome);
          }
          return acc;
        },
        [],
      );

    default:
      return [];
  }
};

const filtrarDocumentoFiscalsPorAgrupador = (
  pDocumentoFiscals: DocumentoFiscalType[],
  pAgrupamento: string,
  pAgrupador: string,
): DocumentoFiscalType[] => {
  switch (pAgrupamento) {
    case 'empresa.fantasia':
      return pDocumentoFiscals.filter(
        documento => documento.empresa.fantasia === pAgrupador,
      );
    case 'DEST_xNome':
      return pDocumentoFiscals.filter(
        documento => documento.DEST_xNome === pAgrupador,
      );
    default:
      return [];
  }
};

// const gerarLogoTipoPDF = () => {
//   return '';
// };

const extrairPropriedadeLinhaPDF = (
  cDocumentoFiscal: DocumentoFiscalType,
  field: string,
) => {
  let prop = '';

  switch (field) {
    case 'empresa.fantasia':
      prop = `${cDocumentoFiscal.empresa.fantasia}`;
      break;
    case 'DEST_xNome':
      prop = `${cDocumentoFiscal.DEST_xNome}`;
      break;
    case 'TOTAL_vNF':
      prop = `${handlerNumberToString(cDocumentoFiscal.TOTAL_vNF)}`;
      break;
    case 'created_at':
      prop = `${formatTheDateYall(cDocumentoFiscal.created_at)}`;
      break;
    default:
      prop = '';
      break;
  }

  return prop;
};

const calcularLargura = (tamanhoEmPorcentagem: number, largura: number) => {
  const larguraDesdocumentoda = largura - 30;
  const tamanhoCelula = larguraDesdocumentoda * (tamanhoEmPorcentagem / 100);
  return tamanhoCelula;
};

const gerarLinhasPDF = (
  pDocumentoFiscals: DocumentoFiscalType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const output: RowInput[] = [];
  pDocumentoFiscals.forEach(cDocumentoFiscal => {
    const line: RowInput = [];

    const ordenadores = pConfiguracao.ordenar.filter(
      ordenador => ordenador.enable,
    );

    let cacheTotalLarguras = 0;
    ordenadores.forEach((ordenador, key) => {
      let larguraColuna = ordenador.width;
      cacheTotalLarguras += larguraColuna;

      const ultimaColuna = ordenadores.length === key + 1;
      if (ultimaColuna) {
        const larguraPreenchivel = 100 - (cacheTotalLarguras - larguraColuna);
        if (larguraPreenchivel > 0) {
          larguraColuna = larguraPreenchivel;
        }
      }

      line.push({
        content: `${extrairPropriedadeLinhaPDF(
          cDocumentoFiscal,
          ordenador.field,
        )}`,
        styles: {
          cellWidth: calcularLargura(larguraColuna, largura),
        },
      });
    });

    output.push(line);
  });

  return output;
};

const renderizarListagemPDF = (
  pDocumentoFiscals: Array<DocumentoFiscalType>,
  pAgrupamentos: Array<string>,
  pConfiguracao: RelatorioType,
  largura: number,
) => {
  let output: RowInput[] = [];

  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  if (!pAgrupamentos.length) {
    output = [...gerarLinhasPDF(pDocumentoFiscals, pConfiguracao, largura)];
    return output;
  }

  const currentAgrupamento = pAgrupamentos[0];

  const agrupadores = filtrarAgrupadoresPorAgrupamento(
    pDocumentoFiscals,
    currentAgrupamento,
  );

  agrupadores.forEach(agrupador => {
    const documentosNoAgrupador = renderizarListagemPDF(
      filtrarDocumentoFiscalsPorAgrupador(
        pDocumentoFiscals,
        currentAgrupamento,
        agrupador,
      ),
      [...pAgrupamentos.filter(item => item !== currentAgrupamento)],
      pConfiguracao,
      largura,
    );

    output = [
      ...output,
      [
        {
          content: `${agrupador}`,
          colSpan: totalColunas,
          styles: { halign: 'center', fillColor: '#ccc' },
        },
      ],
      ...documentosNoAgrupador,
    ];
  });

  return output;
};

const gerarTabelaPDF = (
  pDocumentoFiscals: DocumentoFiscalType[],
  pConfiguracao: RelatorioType,
  largura: number,
): RowInput[] => {
  const cAgrupadores = pConfiguracao.agrupar
    .filter(agrupador => agrupador.enable)
    .map(agrupador => agrupador.field);
  return renderizarListagemPDF(
    pDocumentoFiscals,
    cAgrupadores,
    pConfiguracao,
    largura,
  );
};

const gerarTotalizadoresPDF = (
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
) => {
  const totalColunas = pConfiguracao.ordenar.filter(
    ordenador => ordenador.enable,
  ).length;

  const totalizadoresPDF: RowInput[] = [];

  pTotalizadores.forEach(totalizador => {
    let enable = false;

    pConfiguracao.totalizar.forEach(cfgTotalizador => {
      if (cfgTotalizador.field === totalizador.chave && cfgTotalizador.enable) {
        enable = true;
      }
    });

    if (enable) {
      totalizadoresPDF.push([
        {
          content: `${totalizador.chave}`,
          colSpan: totalColunas - 1,
          styles: { halign: 'right' },
        },
        {
          content: `${handlerNumberToString(Number(totalizador.valor))}`,
          styles: { halign: 'center' },
        },
      ]);
    }
  });

  return totalizadoresPDF;
};

const exportarPDF = (
  pCabecalho: RelatorioCabecalhoType,
  pDocumentoFiscals: DocumentoFiscalType[],
  pTotalizadores: TotalizadorType[],
  pConfiguracao: RelatorioType,
): void => {
  const handler = async () => {
    const doc = new JsPDF({
      orientation: pConfiguracao.modo === 'RETRATO' ? 'portrait' : 'landscape',
    });

    let cMarginTop = 34;
    let h = 26;
    if (
      !pCabecalho.subtitle1 &&
      !pCabecalho.subtitle2 &&
      !pCabecalho.subtitle3
    ) {
      h = 13;
      cMarginTop = 21;
    } else if (!pCabecalho.subtitle2 && !pCabecalho.subtitle3) {
      h = 18;
      cMarginTop = 26;
    } else if (!pCabecalho.subtitle3) {
      h = 22;
      cMarginTop = 30;
    }

    autoTable(doc, {
      headStyles: {
        lineColor: '#000',
        overflow: 'ellipsize',
      },

      bodyStyles: {
        overflow: 'ellipsize',
      },

      didDrawPage: data => {
        // LOGOTIPO
        // const base64Img = gerarLogoTipoPDF();

        doc.setFillColor(255, 250, 250);

        // NOME DA EMPRESA
        doc.setFillColor(255, 250, 250);
        doc.setDrawColor(140, 143, 157);

        doc.roundedRect(
          data.settings.margin.left,
          5,
          doc.internal.pageSize.getWidth() -
            data.settings.margin.left -
            data.settings.margin.right -
            2,
          h,
          1,
          1,
          'FD',
        );
        doc.setTextColor(40);
        doc.setFontSize(14);
        doc.setFont('Courier-Bold', 'bold');
        doc.text(pCabecalho.title, data.settings.margin.left + 4, 13);

        doc.setFontSize(10);
        doc.setFont('Courier', 'bold');

        if (pCabecalho.subtitle1) {
          doc.text(pCabecalho.subtitle1, data.settings.margin.left + 4, 18);
        }

        doc.setFont('Courier', 'normal');

        if (pCabecalho.subtitle2) {
          doc.text(pCabecalho.subtitle2, data.settings.margin.left + 4, 22);
        }

        if (pCabecalho.subtitle3) {
          doc.text(pCabecalho.subtitle3, data.settings.margin.left + 4, 26);
        }
      },

      margin: { top: cMarginTop },

      // CABEÇALHO
      head: [
        [
          ...pConfiguracao.ordenar
            .filter(ordenador => ordenador.enable)
            .map(ordenador => ({
              content: ordenador.label,
            })),
        ],
      ],

      // CORPO
      body: [
        ...gerarTabelaPDF(
          pDocumentoFiscals,
          pConfiguracao,
          doc.internal.pageSize.getWidth(),
        ),
        ...gerarTotalizadoresPDF(pTotalizadores, pConfiguracao),
      ],
    });

    doc.output('dataurlnewwindow');
    // doc.save('relatório.pdf');
  };

  handler();
};

export {
  ordenarDocumentoFiscals,
  filtrarAgrupadoresPorAgrupamento,
  filtrarDocumentoFiscalsPorAgrupador,
  exportarPDF,
};
