import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '~/services/api';

import { AuthContextData, AuthState, User } from './interface';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@SisautERP:token');
    const user = localStorage.getItem('@SisautERP:user');

    if (token && user) {
      const userData = JSON.parse(user);

      api.defaults.headers.common.authorization = `Bearer ${token}`;
      api.defaults.headers.common.workspace_id = `${userData.workspace.id}`;
      api.defaults.headers.common.parceiro_id = `${userData.parceiro_id}`;

      return { token, user: userData };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post('sessions', {
        email,
        senha: password,
      });

      const { token, user, workspace, papeis, permissoes } = response.data;

      localStorage.setItem('@SisautERP:token', token);

      localStorage.setItem(
        '@SisautERP:user',
        JSON.stringify({
          ...user,
          workspace,
          isAdmin: !!papeis.find((item: any) => item.nome === 'ADMINISTRADOR'),
          papeis,
        }),
      );

      api.defaults.headers.common.authorization = `Bearer ${token}`;
      api.defaults.headers.common.workspace_id = `${workspace.id}`;
      api.defaults.headers.common.parceiro_id = `${user.parceiro_id}`;

      const userData = {
        ...user,
        workspace,
        papeis,
        permissoes,
      };

      setData({ token, user: userData });
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@SisautERP:token');
    localStorage.removeItem('@SisautERP:user');

    // setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        user,
      });
      localStorage.setItem('@SisautERP: user', JSON.stringify(user));
    },
    [setData, data.token],
  );

  const getAuthUser = () => {
    let userData;
    const user = localStorage.getItem('@SisautERP:user');
    if (user) {
      userData = JSON.parse(user);
    }
    return userData;
  };

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, getAuthUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
