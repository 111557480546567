import React, { createContext, useState, useCallback, useContext } from 'react';

import { activeAnchorType, AnchorContextType } from './interface';

const AnchorContext = createContext<AnchorContextType>({} as AnchorContextType);

export const AnchorProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [activeAnchor, setactiveAnchor] = useState<activeAnchorType>(null);

  const setActiveAnchor = useCallback((currentAnchor: string) => {
    setactiveAnchor(currentAnchor);
  }, []);

  const value = {
    activeAnchor,
    setActiveAnchor,
  };

  return (
    <AnchorContext.Provider value={value}>{children}</AnchorContext.Provider>
  );
};

const useAnchors = (): AnchorContextType => {
  const context = useContext(AnchorContext);

  if (!context) {
    throw new Error(`useAnchors must be used within an ListsProvider`);
  }

  return context;
};

export default useAnchors;
