import React, { useState, useRef, useCallback, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiCurrentLocation } from 'react-icons/bi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import { PartnerAddressLdAnim } from './styles';

import {
  Button,
  FormFooter,
  Input,
  InputsContainer,
  InputContainer,
  InputMask,
} from '~/components';

import { PartnerAddressProps } from './interface';

import { EnderecoType } from '~/types';

import { fetchCEPinfo } from '~/utils/cep';

const PartnerAddress: React.FC<PartnerAddressProps> = ({
  address,
  onRequestSave,
  onRequestClose,
}) => {
  const { colors } = useTheme();
  const [cepLoading, setCepLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const fetchAddressByCEP = useCallback(async () => {
    setCepLoading(true);
    const cepInputed = formRef?.current?.getFieldValue('cep');

    if (!cepInputed) {
      setCepLoading(false);
      addToast({
        type: 'error',
        title: 'CEP não informado!',
        description: 'Informe o CEP para usar esta funcionalidade!',
      });
      return false;
    }

    setCepLoading(false);

    const cepInfo = await fetchCEPinfo(cepInputed);

    if (cepInfo) {
      formRef?.current?.setFieldValue('logradouro', cepInfo.street);
      formRef?.current?.setFieldValue('bairro', cepInfo.neighborhood);
      formRef?.current?.setFieldValue('cidade', cepInfo.city);
      formRef?.current?.setFieldValue('uf', cepInfo.state);
    } else {
      addToast({
        type: 'error',
        title: 'Falha ao buscar CEP!',
      });
    }
  }, [addToast]);

  useEffect(() => {
    setTimeout(() => {
      const inputNode =
        document.querySelector<HTMLInputElement>(`input[name='cep']`);
      if (inputNode) {
        inputNode.focus();
      }
    }, 300);
  }, []);

  const handleSubmit = useCallback(
    async (data: EnderecoType) => {
      try {
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.string().required('CEP obrigatório'),
          logradouro: Yup.string().required('Logradouro obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatório'),
          uf: Yup.string().required('Estado obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        onRequestSave({ ...data, cobranca: false });

        if (!address || !address.id) {
          formRef.current?.reset();
        }

        setFormLoading(false);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'Endereço armazenado com sucesso!',
        });
      } catch (err) {
        setFormLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [addToast, onRequestSave, address],
  );

  return (
    <Form ref={formRef} initialData={address} onSubmit={handleSubmit}>
      <InputsContainer>
        <InputContainer width={300} disabled={formLoading}>
          <span>CEP</span>
          <div>
            <InputMask
              mask="99999-999"
              name="cep"
              placeholder="Informe o CEP"
            />
            <Button
              style={{ marginLeft: 15 }}
              type="button"
              onClick={fetchAddressByCEP}
              disabled={cepLoading}
            >
              {cepLoading ? (
                <PartnerAddressLdAnim>
                  <AiOutlineLoading3Quarters size={22} color={colors.white} />
                </PartnerAddressLdAnim>
              ) : (
                <BiCurrentLocation size={22} color={colors.white} />
              )}
            </Button>
          </div>
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading}>
          <span>Logradouro</span>
          <div>
            <Input name="logradouro" placeholder="Informe o Logradouro" />
          </div>
        </InputContainer>
        <InputContainer width={140} disabled={formLoading}>
          <span>Número</span>
          <div>
            <Input name="numero" placeholder="Número" />
          </div>
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading}>
          <span>Complemento</span>
          <div>
            <Input name="complemento" placeholder="Informe o Complemento" />
          </div>
        </InputContainer>
      </InputsContainer>

      <InputsContainer>
        <InputContainer disabled={formLoading}>
          <span>Bairro</span>
          <div>
            <Input name="bairro" placeholder="Informe o Bairro" />
          </div>
        </InputContainer>
        <InputContainer disabled={formLoading}>
          <span>Cidade</span>
          <div>
            <Input name="cidade" placeholder="Cidade" />
          </div>
        </InputContainer>
        <InputContainer width={140} disabled={formLoading}>
          <span>UF</span>
          <div>
            <Input name="uf" placeholder="UF" maxLength={2} />
          </div>
        </InputContainer>
      </InputsContainer>

      <FormFooter>
        <Button
          type="button"
          background={colors.grey}
          onClick={onRequestClose}
          style={{ marginTop: 50, marginBottom: 20 }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          background={colors.green}
          loading={formLoading}
          style={{ marginTop: 50, marginRight: 30, marginBottom: 20 }}
        >
          Salvar
        </Button>
      </FormFooter>
    </Form>
  );
};

export default PartnerAddress;
