import React from 'react';
import Card from './Card';
import { ResumoProps } from './interface';
import { Resume, ResumeContent, ResumeHeader } from './styles';

const Resumo: React.FC<ResumoProps> = ({ entrada, saida }) => {
  return (
    <Resume>
      <ResumeHeader>
        <strong>Resumo</strong>
      </ResumeHeader>

      <ResumeContent>
        <Card title="Entrada" value={entrada} type="entrada" />
        <Card title="Saída" value={Math.abs(saida)} type="saida" />
        <Card title="Saldo" value={entrada - Math.abs(saida)} type="saldo" />
      </ResumeContent>
    </Resume>
  );
};

export default Resumo;
