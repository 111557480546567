import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useAuth } from '~/hooks/auth';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addDash } from 'string-sanitizer';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import { BsFillTriangleFill } from 'react-icons/bs';

import Header from '~/components/Header';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

import {
  AnchorContainer,
  AnchorLink,
  AnchorMenu,
  AnchorRef,
} from '~/components/Anchor';

import {
  Button,
  ColorPicker,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Plate,
  Switch,
  NoPermission,
} from '~/components';

import { useToast } from '~/hooks/toast';
import api from '~/services/api';
import { getValidationErrors } from '~/utils';

import { ParamsProps, StatusDePedidoFormData } from './interface';
import { handlerErrors } from '~/utils/error';
import { validarPermissao } from '~/utils/permissions';

const StatusDePedido: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const history = useNavigate();
  const { user } = useAuth();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [registerData, setRegisterData] = useState({ id, ativo: true });
  const [registerId, setRegisterId] = useState('0');

  const [cor, setCor] = useState('');

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: StatusDePedidoFormData) => {
      try {
        setFormLoading(true);
        setFormError('');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Informe uma descrição válida.'),
        });

        await schema.validate(data, { abortEarly: false });

        try {
          const slug = addDash(data.descricao);

          const response = await api[registerId !== '0' ? 'put' : 'post'](
            `pedido/status-pedido${registerId !== '0' ? `/${registerId}` : ''}`,
            {
              ...data,
              slug,
              cor,
            },
          );

          history(`/status-de-pedido/${response.data.id}`);
          setRegisterId(response.data.id);
          setFormLoading(false);

          addToast({
            type: 'success',
            title: 'Concluido',
            description: 'Registro salvo com sucesso!',
          });
        } catch (e) {
          const message = handlerErrors(e);
          setFormLoading(false);
          setFormError(`${message}`);
        }
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o registro, tente novamente...');
      }
    },
    [addToast, registerId, history, cor],
  );

  function clearForm() {
    setRegisterData({ id: '0', ativo: true });
    formRef.current?.reset();
  }

  useEffect(() => {
    async function loadRegister() {
      if (id) {
        setRegisterId(id);
      }
      if (id !== '0') {
        const response = await api.get(`pedido/status-pedido/${id}`);
        setRegisterData(response.data);
        setLoading(false);
      } else {
        clearForm();
        setLoading(false);
      }
    }

    loadRegister();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (validarPermissao(user, 'incluir-editar-status-pedido')) {
    return <NoPermission />;
  }

  return (
    <Container>
      <Header />
      <AnchorContainer>
        <Content>
          <AnchorMenu>
            <AnchorLink anchor="dados-cadastrais">Dados Cadastrais</AnchorLink>
          </AnchorMenu>

          <FormContainer>
            <Form
              ref={formRef}
              initialData={registerData}
              onSubmit={handleSubmit}
            >
              <FormContent>
                <ErrorMessage
                  error={formError}
                  onDismiss={() => setFormError('')}
                />

                <AnchorRef anchor="dados-cadastrais">
                  <FormBlock>
                    <Plate
                      title="Status de Pedido"
                      subtitle="Adicione um status de pedido"
                      icon={() => (
                        <BsFillTriangleFill size={32} color={colors.label} />
                      )}
                    />

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Descrição</span>
                        <div>
                          <Input
                            name="descricao"
                            placeholder="Informe a descrição"
                            maxLength={30}
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Ativo?</span>
                        <div className="switch">
                          <Switch name="ativo" />
                        </div>
                      </InputContainer>

                      <InputContainer disabled={formLoading}>
                        <span>Cor</span>
                        <div>
                          <ColorPicker onSave={corData => setCor(corData)} />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </FormBlock>
                </AnchorRef>
              </FormContent>
              <FormFooter>
                <Link to="/status-de-pedidos">
                  <Button type="button" background={colors.contrast}>
                    Voltar
                  </Button>
                </Link>
                <Button
                  onClick={clearForm}
                  type="button"
                  background={colors.contrast}
                >
                  Novo
                </Button>
                <Button
                  type="submit"
                  background={colors.green}
                  loading={formLoading}
                >
                  Salvar
                </Button>
              </FormFooter>
            </Form>
          </FormContainer>
        </Content>
      </AnchorContainer>
    </Container>
  );
};

export default StatusDePedido;
