import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { List, arrayMove } from 'react-movable';

import { Container, Content, Area, Item, Control } from './styles';

import { RelatorioOrdenadorType } from '~/types';

import { OrdenadoresProps } from './interface';

const Ordenadores: React.FC<OrdenadoresProps> = ({
  configuracao,
  aoAplicarConfiguracao,
}) => {
  const { colors } = useTheme();

  const [itens, setItens] = useState<string[]>([
    ...configuracao.ordenar.map(ordenador => ordenador.field),
  ]);

  const verificarHabilitado = (pItem: string): boolean => {
    return configuracao.ordenar.reduce((acc: boolean, item) => {
      if (item.field === pItem && item.enable === true) {
        // eslint-disable-next-line no-param-reassign
        acc = true;
      }
      return acc;
    }, false);
  };

  const inverterInatividade = (pItem: string) => {
    if (verificarHabilitado(pItem)) {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          ordenar: [
            ...configuracao.ordenar.map(ordenador => ({
              ...ordenador,
              enable: ordenador.field === pItem ? false : ordenador.enable,
            })),
          ],
        },
        false,
      );
    } else {
      aoAplicarConfiguracao(
        {
          ...configuracao,
          ordenar: [
            ...configuracao.ordenar.map(ordenador => ({
              ...ordenador,
              enable: ordenador.field === pItem ? true : ordenador.enable,
            })),
          ],
        },
        false,
      );
    }
  };

  const extrairLabel = (campo: string) => {
    return configuracao.ordenar.reduce(
      (acc: string, item: RelatorioOrdenadorType) => {
        if (item.field === campo) {
          // eslint-disable-next-line no-param-reassign
          acc = item.label;
        }
        return acc;
      },
      '',
    );
  };

  const alterarOrdem = (campo: string) => {
    const ordenadoresOrdenados: RelatorioOrdenadorType[] = [];

    configuracao.ordenar.forEach(ordenador => {
      let cOrder = ordenador.order;

      if (ordenador.field === campo) {
        cOrder = ordenador.order === 'ASC' ? 'DESC' : 'ASC';
      }

      ordenadoresOrdenados.push({
        ...ordenador,
        order: cOrder,
      });
    });

    aoAplicarConfiguracao(
      {
        ...configuracao,
        ordenar: ordenadoresOrdenados,
      },
      false,
    );
  };

  const verificarOrdem = (campo: string) => {
    return configuracao.ordenar.reduce(
      (acc: string, item: RelatorioOrdenadorType) => {
        if (item.field === campo) {
          // eslint-disable-next-line no-param-reassign
          acc = item.order;
        }
        return acc;
      },
      '',
    );
  };

  const salvarPosicoes = (pItens: string[]) => {
    const ordenadoresOrdenados: RelatorioOrdenadorType[] = [];

    pItens.forEach(item => {
      configuracao.ordenar.forEach(ordenador => {
        if (ordenador.field === item) {
          ordenadoresOrdenados.push(ordenador);
        }
      });
    });

    aoAplicarConfiguracao(
      {
        ...configuracao,
        ordenar: ordenadoresOrdenados,
      },
      false,
    );
  };

  return (
    <Container>
      <Content>
        <List
          values={itens}
          onChange={({ oldIndex, newIndex }) => {
            setItens(arrayMove(itens, oldIndex, newIndex));
            salvarPosicoes(arrayMove(itens, oldIndex, newIndex));
          }}
          renderList={({ children, props }) => (
            <Area {...props}>{children}</Area>
          )}
          renderItem={({ value, props }) => (
            <Item $inativo={!verificarHabilitado(value)} {...props}>
              <Control onClick={() => inverterInatividade(value)}>
                {verificarHabilitado(value) ? (
                  <ImCheckboxChecked size={42} color={colors.label} />
                ) : (
                  <ImCheckboxUnchecked
                    size={42}
                    color={colors.defaultHighlight}
                  />
                )}
              </Control>
              <span>{extrairLabel(value)}</span>
              {verificarHabilitado(value) ? (
                <button
                  type="button"
                  onClick={() => alterarOrdem(value)}
                  title={
                    verificarOrdem(value) === 'ASC'
                      ? 'crescente'
                      : 'decrescente'
                  }
                >
                  {verificarOrdem(value) === 'ASC' ? (
                    <AiOutlineArrowDown size={32} color={colors.label} />
                  ) : (
                    <AiOutlineArrowUp size={32} color={colors.label} />
                  )}
                </button>
              ) : null}
            </Item>
          )}
        />
      </Content>
    </Container>
  );
};

export default Ordenadores;
