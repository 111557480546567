import React from 'react';

import { HeaderSearchEmptyContent } from './styles';

const HeaderSearchEmpty: React.FC = () => {
  return (
    <HeaderSearchEmptyContent>
      <h3>Nenhum resultado encontrado.</h3>
      <h4>Tente digitar outras palavras ai irmão</h4>
    </HeaderSearchEmptyContent>
  );
};

export default HeaderSearchEmpty;
