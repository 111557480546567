import React from 'react';

import { Container } from './styles';
import { CardsProps } from './interface';

const Cards: React.FC<CardsProps> = ({ variant, transition, children }) => {
  return (
    <Container variants={variant} transition={transition}>
      {children}
    </Container>
  );
};

export default Cards;
