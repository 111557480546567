import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTheme } from 'styled-components';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import api from '~/services/api';

import {
  Button,
  Select,
  InputContainer,
  FindParceiro,
  DatePicker,
} from '~/components';

import { Container, Content, Footer } from './styles';

import { EmpresaType, SelectType } from '~/types';

import { TP1FiltragemProps, FormData } from './interface';

const TP1Filtragem: React.FC<TP1FiltragemProps> = () => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(true);

  const [parceiro, setParceiro] = useState({ id: '', descricao: '' });
  const [empresas, setEmpresas] = useState<SelectType[]>([]);

  const submeterFormulario = useCallback(
    (data: FormData) => {
      setLoading(true);
      // eslint-disable-next-line no-console
      console.log('dados-filtragem', parceiro, data);
      setLoading(false);
    },
    [parceiro],
  );

  useEffect(() => {
    const carregarEmpresas = async () => {
      try {
        const empresasFetch = await api.get('empresas');
        const empresasFetched = [] as SelectType[];
        empresasFetch.data.forEach((item: EmpresaType) => {
          empresasFetched.push({
            value: item.id,
            label: item.fantasia,
            color: '',
          });
        });
        setEmpresas(empresasFetched);
        return empresasFetched;
        // eslint-disable-next-line no-empty
      } catch (e) {
        setEmpresas([]);
      }
    };
    carregarEmpresas();
  }, []);

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={submeterFormulario}>
          <InputContainer>
            <span>Tipo</span>
            <div>
              <Select
                name="tipo"
                loading={loading}
                defaultValue={{ value: 'RECEBER', label: 'RECEBER', cor: '' }}
                options={[
                  { value: 'RECEBER', label: 'RECEBER', cor: '' },
                  { value: 'PAGAR', label: 'PAGAR', cor: '' },
                ]}
              />
            </div>
          </InputContainer>
          <InputContainer>
            <span>Empresa</span>
            <div>
              <Select name="empresa_id" loading={loading} options={empresas} />
            </div>
          </InputContainer>
          <InputContainer>
            <span>Parceiro</span>
            <FindParceiro
              placeholderInput="Informe o nome do parceiro"
              placeholderValue="Selecione um parceiro"
              tipos="cliente,fornecedor"
              onSelectValue={(selecionados, descricao) => {
                if (selecionados[0]) {
                  setParceiro({
                    id: selecionados[0].id,
                    descricao,
                  });
                }
              }}
            />
          </InputContainer>

          <InputContainer>
            <span>Situação</span>
            <div>
              <Select
                name="situacao"
                loading={loading}
                defaultValue={{
                  value: 'ABERTA',
                  label: 'ABERTA',
                  cor: '#27AE60',
                }}
                options={[
                  { value: 'ABERTA', label: 'ABERTA', cor: '#27AE60' },
                  { value: 'PAGA', label: 'PAGA', cor: '#EB5757' },
                  { value: 'TODAS', label: 'TODAS', cor: '#878787' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Filtrar por data de:</span>
            <div>
              <Select
                name="data_campo_filtro"
                loading={loading}
                defaultValue={{ value: 'vencimento', label: 'VENCIMENTO' }}
                options={[
                  { value: 'lancamento', label: 'LANÇAMENTO' },
                  { value: 'vencimento', label: 'VENCIMENTO' },
                ]}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data inicial</span>
            <div>
              <DatePicker
                name="data_inicial"
                mask="99/99/9999"
                placeholder="Informe a Data Inicial"
              />
            </div>
          </InputContainer>

          <InputContainer>
            <span>Data final</span>
            <div>
              <DatePicker
                name="data_final"
                mask="99/99/9999"
                placeholder="Informe a Data Final"
              />
            </div>
          </InputContainer>

          <Footer>
            <div>
              <Button type="submit" background={colors.green}>
                FILTRAR
              </Button>
            </div>
          </Footer>
        </Form>
      </Content>
    </Container>
  );
};

export default TP1Filtragem;
