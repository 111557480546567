import React, { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { Container, Footer, InputContainer, InputsContainer } from './styles';

import { FiltragemFormData, FiltragemProps } from './interface';

import { Button, Modal, Select } from '~/components';

const Filtragem: React.FC<FiltragemProps> = ({
  visible,
  onRequestClose,
  onRequestSave,
  haveRegisters,
}) => {
  const { colors } = useTheme();

  const formRef = useRef<FormHandles>(null);

  const enviarFormulario = useCallback(
    (data: FiltragemFormData) => {
      const {
        uf_origem,
        cst_csosn,
        mod_cal_st_retido,
        uf_destino,
        cst_pis_cofins,
        mod_cal_icms_substituto,
        classificacao_fiscal,
        cfop,
        natureza_operacao,
        origem,
        grupo_tributacao,
        modbc_icms,
        produto,
        modbc_icms_st,
      } = data;

      onRequestSave(
        uf_origem,
        cst_csosn,
        mod_cal_st_retido,
        uf_destino,
        cst_pis_cofins,
        mod_cal_icms_substituto,
        classificacao_fiscal,
        cfop,
        natureza_operacao,
        origem,
        grupo_tributacao,
        modbc_icms,
        produto,
        modbc_icms_st,
      );
      onRequestClose();
    },
    [onRequestClose, onRequestSave],
  );

  return (
    <Modal
      title="Filtros de Tributações"
      width="100rem"
      visible={visible}
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <Form ref={formRef} onSubmit={enviarFormulario}>
          <InputsContainer>
            <InputContainer>
              <span>UF ORIGEM</span>
              <div>
                <Select
                  name="uf_origem"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: 'MG', label: 'MG', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>CST/CSOSN</span>
              <div>
                <Select
                  name="cst_csosn"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>MDOD CALC ST RETIDO</span>
              <div>
                <Select
                  name="mod_cal_st_retido"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>UF DESTINO</span>
              <div>
                <Select
                  name="uf_destino"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: 'MG', label: 'MG', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>CST PIS/COFINS</span>
              <div>
                <Select
                  name="cst_pis_cofins"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>MDOD CALC ICMS SUBSTITUTO</span>
              <div>
                <Select
                  name="mod_cal_icms_substituto"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>CLASSIFIAÇÃO FISCAL</span>
              <div>
                <Select
                  name="classificacao_fiscal"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>CFOP</span>
              <div>
                <Select
                  name="cfop"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer />
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>NATUREZA OPERAÇÃO</span>
              <div>
                <Select
                  name="natureza_operacao"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>ORIGEM PRODUTO</span>
              <div>
                <Select
                  name="origem"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer />
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>GRUPO TRIBUTAÇÃO</span>
              <div>
                <Select
                  name="grupo_tributacao"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>MODBc ICMS</span>
              <div>
                <Select
                  name="modbc_icms"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer />
          </InputsContainer>

          <InputsContainer>
            <InputContainer>
              <span>PRODUTO</span>
              <div>
                <Select
                  name="produto"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <span>MODBc ICMS ST</span>
              <div>
                <Select
                  name="modbc_icms_st"
                  loading={false}
                  defaultValue={{ value: '', label: '', cor: '' }}
                  options={[{ value: '', label: '', cor: '' }]}
                />
              </div>
            </InputContainer>
            <InputContainer />
          </InputsContainer>

          <Footer>
            {haveRegisters && (
              <div>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
              </div>
            )}
            <div>
              <Button type="submit" background={colors.green}>
                Consultar
              </Button>
            </div>
          </Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default Filtragem;
