import React from 'react';

import { AnchorLinkContainer } from './styles';

import useAnchors from '~/hooks/anchor';

import { AnchorLinkProps } from './interface';

const AnchorLink: React.FC<AnchorLinkProps> = ({ anchor, children }) => {
  const { setActiveAnchor } = useAnchors();

  const handlerSetAnchor = (currentAnchor: string) => {
    setActiveAnchor(currentAnchor);
  };

  return (
    <AnchorLinkContainer onClick={() => handlerSetAnchor(anchor)}>
      {children}
    </AnchorLinkContainer>
  );
};

export default AnchorLink;
