import React from 'react';

import { ListContainerContainer } from './styles';

const ListContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <ListContainerContainer>{children}</ListContainerContainer>;
};

export default ListContainer;
