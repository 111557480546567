import styled, { css } from 'styled-components';

import { ContainerProps, InfoProps, MenuProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $selecionada, $eimpar }) => css`
    width: 100%;
    float: left;
    background: ${$eimpar && !$selecionada
      ? theme.colors.defaultHighlight
      : ($selecionada && theme.colors.liHouver) || theme.colors.background};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border-bottom: 0.1rem solid ${theme.colors.grey};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: ${theme.colors.liHouver};
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1.5rem;

  div {
    display: flex;
    flex-direction: row;
  }
`;

export const Info = styled.span<InfoProps>`
  ${({ $cor, theme }) => css`
    color: ${$cor || theme.colors.label};
    font-size: ${theme.fontSizes.small};
    font-weight: 600;
    display: flex;
    width: calc(100% / 5);
    flex-direction: row;
    align-items: center;
    padding: 0.2rem 0;
    padding-right: 0.2rem;

    small {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.label};
      margin-right: 0.5rem;
    }
  `}
`;

export const Left = styled.div`
  cursor: pointer;
  margin-top: 0.6rem;
`;

export const Right = styled.div`
  margin-top: 0.6rem;
`;

export const Menu = styled.div<MenuProps>`
  ${({ theme, $visible }) => css`
    width: 20rem;
    margin-left: -21rem;
    margin-top: -5rem;
    height: auto;
    position: absolute;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: column;
    border: 0.1rem solid ${theme.colors.grey};
    border-radius: 0.6rem;
    overflow: hidden;

    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? '$visible' : 'hidden'};

    button {
      padding: 1rem;
      border-bottom: 1px solid ${theme.colors.grey};
      background: none;
      font-size: ${theme.fontSizes.default};
      font-weight: 600;
      text-align: left;
      float: left;
      width: 100%;
      color: ${theme.colors.white};
      transition: all 0.5s ease;

      &:hover {
        background: ${theme.colors.controlFocus};
      }

      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
