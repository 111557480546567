import styled from 'styled-components';

import { GroupItemProps } from './interface';

export const GroupTagContainer = styled.div`
  float: left;
  width: 100%;
`;

export const GroupFormsContent = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const GroupTagContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 1.5rem;
  padding-left: 25rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: inherit;
    height: auto;
    min-height: auto;
    padding: 0rem;
  }

  form {
    display: flex;
    width: 100%;
    float: left;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.radio.default};
  }
`;

export const GroupsList = styled.div`
  float: left;
  background: rgba(0, 0, 0, 0.2);
  margin: 20px 20px 0 20px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px;
`;

export const GroupItem = styled.div<GroupItemProps>`
  display: flex;
  flex-direction: row;
  background: #cecece;
  float: left;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-color: ${props =>
    props.$color ? `${props.$color}` : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  margin: 10px;

  p {
  }

  button {
    padding: 10px;
    background: none;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const GroupsInfo = styled.div`
  padding: 20px;
  font-size: 12px;
`;

export const GroupTagColor = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  padding-right: 15px;

  > button {
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease;
    background: none;
    margin: 0px 0 0 0;

    &:hover {
      opacity: 0.5;
    }

    img {
      width: 32px;
      height: 32px;
      float: left;
      border-radius: 16px;
      border: 2px solid rgba(255, 255, 255, 0.8);
    }

    span {
      float: left;
      width: 32px;
      height: 32px;
      border: 1px solid rgba(255, 255, 255, 0.8);
      margin: 0 0 0 5px;
    }
  }

  > div {
    position: absolute;
    margin: -15px 0px 0 25px;
    transform: translate(-50%, -100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    svg {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
`;

export const GroupTagsEmptyTags = styled.div`
  font-size: 1.4rem;
  background: #fff;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  color: #333;
  padding: 2rem;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 2rem;
  }
`;
