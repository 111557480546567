import React, { useCallback, useRef, useState } from 'react';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useTheme } from 'styled-components';

import { useToast } from '~/hooks/toast';
import { getValidationErrors } from '~/utils';

import { Container, Info } from './styles';

import {
  Button,
  ErrorMessage,
  FormBlock,
  FormContainer,
  FormContent,
  FormFooter,
  Input,
  InputContainer,
  InputsContainer,
  Modal,
} from '~/components';

import { ProdutoFormData, ProdutoProps } from './interface';

const Produto: React.FC<ProdutoProps> = ({
  produto,
  onRequestClose,
  onRequestSave,
}) => {
  const { colors } = useTheme();
  const { addToast } = useToast();

  const [formError, setFormError] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: ProdutoFormData) => {
      try {
        setFormError('');
        setFormLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nItem: Yup.string().required('Informe a nItem'),
        });

        await schema.validate(data, { abortEarly: false });

        setFormLoading(false);

        addToast({
          type: 'success',
          title: 'Concluido',
          description: 'XML salvo com sucesso!',
        });

        onRequestSave();
      } catch (err) {
        setFormLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setFormError('Ocorreu um erro salvar o xml, tente novamente...');
      }
    },
    [addToast, onRequestSave],
  );

  // const submitForm = () => {
  //   formRef.current?.submitForm();
  // };

  return (
    <Modal
      title={`${produto.PROD_xProd}`}
      width="100rem"
      visible
      scrollable
      onRequestClose={onRequestClose}
      closeButtonVisible={false}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} initialData={produto} onSubmit={handleSubmit}>
            <FormContent>
              <ErrorMessage
                error={formError}
                onDismiss={() => setFormError('')}
              />

              <FormBlock>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Indicador de Composição de Valor Total da NF-e</span>
                    <div>
                      <Input disabled name="PROD_indTot" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Código EAN Comercial</span>
                    <div>
                      <Input disabled name="PROD_cEAN" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Unidade Comercial</span>
                    <div>
                      <Input disabled name="PROD_uCom" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Quantidade Comercial</span>
                    <div>
                      <Input disabled name="PROD_qCom" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Código EAN Tributável</span>
                    <div>
                      <Input disabled name="PROD_cEANTrib" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Unidade Tributável</span>
                    <div>
                      <Input disabled name="PROD_uTrib" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Quantidade Tributável</span>
                    <div>
                      <Input disabled name="PROD_qTrib" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor Unitário de comercialização</span>
                    <div>
                      <Input disabled name="PROD_vUnCom" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor Unitário tributação</span>
                    <div>
                      <Input disabled name="PROD_vUnTrib" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Número do pedido de compra</span>
                    <div>
                      <Input disabled name="COMPRA_xPed" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Item do pedido de compra</span>
                    <div>
                      <Input disabled name="COMPRA_nItemPed" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor Aproximado dos Tributos</span>
                    <div>
                      <Input disabled name="IMPOSTO_vTotTrib" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Número da FCI</span>
                    <div>
                      <Input disabled name="IMPOSTO_nFCI" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <Info>ICMS Normal e ST</Info>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Origem da Mercadoria</span>
                    <div>
                      <Input disabled name="IMPOSTO_ICMS_orig" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Tributação ICMS</span>
                    <div>
                      <Input disabled name="IMPOSTO_ICMS_CST" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Modalidade Definição da BC ICMS NORMAL</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_ICMS_modBC"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Base do Cálculo do ICMS Normal</span>
                    <div>
                      <Input disabled name="IMPOSTO_ICMS_vBC" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Alíquota do ICMS Normal</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_ICMS_pICMS"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor ICMS Normal</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_ICMS_vICMS"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>

                {/* <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Percentual do Fundo de Combate à Pobreza (FCP)</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor do Fundo de Combate à Pobreza (FCP)</span>
                    <div>
                      <Input disabled name="ASSOCIAR" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer> */}
                {produto?.IMPOSTO_CNPJProd && (
                  <>
                    <Info>Imposto Sobre Produtos Industrializados</Info>
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Classe enquadramento</span>
                        <div>
                          <Input disabled name="IMPOSTO_clEnq" placeholder="" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Código Enquadramento</span>
                        <div>
                          <Input disabled name="IMPOSTO_cEnq" placeholder="" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Código do Selo</span>
                        <div>
                          <Input disabled name="IMPOSTO_cSelo" placeholder="" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>CNPJ do Produtor</span>
                        <div>
                          <Input
                            disabled
                            name="IMPOSTO_CNPJProd"
                            placeholder=""
                          />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Qtd.Selo</span>
                        <div>
                          <Input disabled name="IMPOSTO_qSelo" placeholder="" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>CST</span>
                        <div>
                          <Input disabled name="IMPOSTO_CST" placeholder="" />
                        </div>
                      </InputContainer>
                    </InputsContainer>

                    <InputsContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Qtd.Total Unidade Padrão</span>
                        <div>
                          <Input disabled name="IMPOSTO_qUnid" placeholder="" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Valor por Unidade</span>
                        <div>
                          <Input disabled name="IMPOSTO_vUnid" placeholder="" />
                        </div>
                      </InputContainer>
                      <InputContainer disabled={formLoading}>
                        <span>Valor IPI</span>
                        <div>
                          <Input disabled name="IMPOSTO_vIPI" placeholder="" />
                        </div>
                      </InputContainer>
                    </InputsContainer>
                  </>
                )}

                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Base do Cálculo</span>
                    <div>
                      <Input disabled name="IMPOSTO_IPI_vBC" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Alíquota</span>
                    <div>
                      <Input disabled name="IMPOSTO_IPI_pIPI" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <Info>PIS</Info>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>CST</span>
                    <div>
                      <Input disabled name="IMPOSTO_PIS_CST" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Base do Cálculo</span>
                    <div>
                      <Input disabled name="IMPOSTO_PIS_vBC" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Alíquota</span>
                    <div>
                      <Input disabled name="IMPOSTO_PIS_pPIS" placeholder="" />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor</span>
                    <div>
                      <Input disabled name="IMPOSTO_PIS_vPIS" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <Info>Cofins</Info>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>CST</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_COFINS_CST"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Base do Cálculo</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_COFINS_vBC"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Alíquota</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_COFINS_pCOFINS"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Valor</span>
                    <div>
                      <Input
                        disabled
                        name="IMPOSTO_COFINS_vCOFINS"
                        placeholder=""
                      />
                    </div>
                  </InputContainer>
                </InputsContainer>

                <Info>Informações adicionais do produto</Info>
                <InputsContainer>
                  <InputContainer disabled={formLoading}>
                    <span>Descrição</span>
                    <div>
                      <Input disabled name="PROD_xProd" placeholder="" />
                    </div>
                  </InputContainer>
                </InputsContainer>
              </FormBlock>

              <FormFooter>
                <Button
                  type="button"
                  background={colors.contrast}
                  onClick={onRequestClose}
                >
                  Fechar
                </Button>
              </FormFooter>
            </FormContent>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default Produto;
