import React, { useState } from 'react';

import { AiFillDelete } from 'react-icons/ai';
import { GoPencil } from 'react-icons/go';

import { useCart } from '~/hooks/cart';

import { Container, Edit, Clear } from './styles';

import { ItemProps } from './interface';

import EditarItem from './EditarItem';

const Item: React.FC<ItemProps> = ({ item }) => {
  const { removerItem, exibirPrecoItem, calcularPrecoItem } = useCart();
  const [editarItemVisibility, setEditarItemVisibility] = useState(false);

  const handlerRemoveItem = () => {
    removerItem(item.id);
  };

  const handlerOpenEditarItem = () => {
    setEditarItemVisibility(true);
  };

  const handlerCloseEditarItem = () => {
    setEditarItemVisibility(false);
  };

  return (
    <Container>
      <EditarItem
        visible={editarItemVisibility}
        onRequestClose={handlerCloseEditarItem}
        item={item}
      />

      <div>
        <h1>{`${item.produto.codigo} - ${item.produto.descricao}`}</h1>
        <small>
          {`${item.qtd} x ${exibirPrecoItem(item)} = ${calcularPrecoItem(
            item,
          )}`}
        </small>
      </div>
      <Edit onClick={handlerOpenEditarItem}>
        <GoPencil size={22} color="#fff" />
      </Edit>
      <Clear onClick={handlerRemoveItem}>
        <AiFillDelete size={22} color="#fff" />
      </Clear>
    </Container>
  );
};

export default Item;
