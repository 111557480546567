import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { InfoProps, SelectProps, InputContainerProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Summary = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    .financeiro-btn-troco {
      margin-top: 3rem;
      margin-left: -4.5rem;
      width: 5rem;
      border-radius: 50%;
      font-size: ${theme.fontSizes.large};

      span {
        display: inline-block;
        margin-top: -0.3rem;
      }
    }

    .financeiro-btn-desconto {
      margin-top: 3rem;
      margin-left: -4.5rem;
      margin-right: 2rem;
      padding: 0;
      width: 5rem;
      border-radius: 50%;
    }
  `}
`;

export const Info = styled.div<InfoProps>`
  ${({ theme, $color }) => css`
    display: flex;
    flex-direction: column;
    margin-right: 2rem;

    small {
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      margin-bottom: 0.5rem;
    }

    span {
      border: 2px solid ${theme.colors.defaultHighlight};
      background: ${theme.colors.controlNotFocus};
      width: 20rem;
      height: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${theme.radio.default};
      font-size: ${theme.fontSizes.big};
      font-weight: 700;
      color: ${$color || theme.colors.label};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${theme.colors.grey};
    padding-top: 2rem;
    margin-top: 2rem;

    h1 {
      float: left;
      width: 100%;
      margin-bottom: 1rem;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      margin-bottom: 0.5rem;
    }
  `}
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 50rem;
  margin-left: 2rem;
  display: flex;
  float: left;
  flex-direction: column;
`;

export const List = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  background: #fffdcc;
  padding: 2rem;
  flex-direction: column;
  border-radius: 0.6rem;
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #737373;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    div {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    span {
      color: #1a1919;
      font-weight: 700;
      font-size: 16px;
      margin: 0px;
      padding: 0px;
      float: left;
    }

    small {
      $color: #605c5c;
      font-weight: 700;
      font-size: 14px;
      margin: 0px;
      padding: 0px;
      float: left;
    }

    h2 {
      color: #0175a7;
      font-weight: 700;
      font-size: 16px;
      padding: 0 1rem;
      margin: 0px;
      float: left;
    }

    button {
      width: 4.2rem;
      height: 3.6rem;
      background: ${theme.colors.darkRed};
      display: flex;
      border-radius: 0.6rem;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      transition: all 0.5s ease;

      &:hover {
        background: ${darken(0.05, theme.colors.darkRed)};
      }
    }
  `}
`;

export const IC = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 0.5rem;

    button {
      width: 15rem;
    }
  }
`;

export const Select = styled.button.attrs({
  type: 'button',
})<SelectProps>`
  ${({ theme, disabled }) => css`
    width: 100%;
    height: 4.8rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    background: ${theme.colors.controlNotFocus};
    border: 2px solid ${theme.colors.defaultHighlight};
    border-radius: ${theme.radio.default};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;

    pointer-events: ${disabled ? 'none' : 'all'};
    opacity: ${disabled ? '0.2' : '1'};

    span {
      flex: 1;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.large};
      text-align: left;
      padding: 0 1rem;

      ins {
        opacity: 0.2;
        text-decoration: none;
      }
    }

    &:hover {
      background: ${theme.colors.controlFocus};
    }
  `}
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  button {
    width: 21.5rem;
    margin-top: 3rem;
  }

  h1 {
    margin-top: 1rem;
  }
`;

export const FormContainer = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    float: left;
    width: 100%;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${theme.colors.grey};

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      float: left;
      width: 100%;
    }
  `}
`;

export const ContentMultiplas = styled.div`
  display: flex;
  float: right;
  width: 24rem;
  flex-direction: row;
  margin-bottom: 0.5rem;

  span {
    margin-left: 1rem;
    margin-top: 0.3rem;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 2rem 0 0;
  flex: ${props => (props.$width ? 0 : 1)};
  width: ${props => (props.$width ? `${props.$width}px` : 'auto')} !important;
  pointer-events: ${props => (props.$disabled ? 'none' : 'all')};
  opacity: ${props => (props.$disabled ? '0.2' : '1')};

  ${({ theme }) => css`
    span {
      margin-bottom: 0.5rem;
      font-size: ${theme.fontSizes.default};
    }

    > div {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      button {
        margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease;
        background: none;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .switch {
      margin-top: 1rem;
    }
  `}
`;
