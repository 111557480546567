import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.background};
    padding: 1rem 1.5rem 2rem 1.5rem;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 ${theme.radio.default} ${theme.radio.default};
  `}
`;

export const Line = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;

  > div {
    margin: 0 0.5rem !important;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0rem;

    > div {
      margin: 1rem 0 0 0 !important;
    }
  }
`;
