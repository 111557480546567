import styled, { css } from 'styled-components';

export const ListHeaderContainer = styled.thead`
  ${({ theme }) => css`
    td {
      font-weight: 500;
      padding: 0rem 0rem;
      /* text-align: center; */
      /* height: 3.9rem; */
      padding-left: 1rem;
      font-size: ${theme.fontSizes.default};
      /* background: ${theme.colors.primaryDark}; */
      color: ${theme.colors.greyLowerOpacity} !important;
      background: ${theme.colors.primaryDark} !important;
      border-right: 0.1rem solid ${theme.colors.default} !important;
    }
  `}
`;
