import React from 'react';
import { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';

import Card from '../Card';

import { Container } from './styles';

import { CARDS_ANIMATION } from '../animations';

import { DEFAULT_TRANSITION } from '~/constants';

import { Graph3Props } from './interface';

import { handlerNumberToString, handlerToNumber } from '~/utils/money';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Preloader = () => {
  return (
    <ContentLoader
      height={200}
      width="100%"
      viewBox="-80 50 300 200"
      backgroundColor="#f5f5f5"
      foregroundColor="#7e7e7e"
    >
      <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
      <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
      <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
      <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
      <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
  );
};

const Graph1: React.FC<Graph3Props> = ({ title, loading, data }) => {
  const { colors } = useTheme();

  return (
    <Card
      title={title}
      key="graph-3"
      variant={CARDS_ANIMATION}
      transition={DEFAULT_TRANSITION}
    >
      <Container>
        <ResponsiveContainer>
          {loading ? (
            <Preloader />
          ) : (
            <ComposedChart
              width={350}
              height={400}
              data={data}
              margin={{
                top: 12,
                right: 30,
                left: -5,
                bottom: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="vendedor" stroke={colors.label} />
              <YAxis stroke={colors.grey} />
              <Tooltip
                contentStyle={{
                  backgroundColor: colors.contrast,
                  borderRadius: 5,
                  borderColor: colors.defaultHighlight,
                }}
                formatter={(value: number) =>
                  `${handlerNumberToString(handlerToNumber(`${value}`))}`
                }
              />
              <Bar dataKey="total" barSize={20} fill="#d4a826" />
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </Container>
    </Card>
  );
};

export default Graph1;
