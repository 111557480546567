import styled, { css } from 'styled-components';

export const Container = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.defaultHighlight};
    background: ${theme.colors.background};
    padding: 1rem 2rem 2rem 1rem;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: ${theme.radio.default};

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 0rem;
    }
  `}
`;

export const Add = styled.div`
  float: left;
  margin-left: 1rem;
  width: 12rem;
  @media (max-width: 1024px) {
    margin: 1.5rem;
  }
`;

export const Image = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    float: left;
    max-width: 30rem;
    max-height: 30rem;
  }
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    padding: 0 2rem;
  `}
`;
