import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FcExpired } from 'react-icons/fc';

import { Container, IC, Content, Message, SubMessage } from './styles';

import { Button } from '~/components';

import { SessionProps } from './interface';

const Session: React.FC<SessionProps> = () => {
  const history = useNavigate();

  const voltarAoLogin = () => {
    history('/login');
  };

  return (
    <Container>
      <Content>
        <IC>
          <FcExpired size={200} color="#f5f5f5" />
        </IC>
        <Message>SUA SESSÃO EXPIROU</Message>
        <SubMessage>
          Por favor, refaça seu login! Não se preocupe, nenhum dos seus filtros
          ou dados serão perdidos!
        </SubMessage>
        <div>
          <Button onClick={voltarAoLogin}>LOGAR</Button>
        </div>
      </Content>
    </Container>
  );
};

export default Session;
