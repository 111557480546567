/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { BiSearch } from 'react-icons/bi';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { ReactTooltip } from '~/components';

import {
  HeaderSearchContainer,
  Content,
  HeaderSearchControl,
  HeaderSearchControlIc,
  HeaderSearchArea,
  HeaderSearchResponse,
  HeaderSearchResumeContent,
  Scroll,
  Overlay,
} from './styles';

import HeaderSearchInput from './HeaderSearchInput';
import HeaderSearchCache from './HeaderSearchCache';
import HeaderSearchEmpty from './HeaderSearchEmpty';
import HeaderSearchLoading from './HeaderSearchLoading';
import HeaderSearchResults from './HeaderSearchResults';

import menuConfig from '~/schemas/menu.js';

import { ToolsProps, HeaderSearchProps } from './interface';

const HeaderSearch: React.FC<HeaderSearchProps> = ({ openSearch }) => {
  const { colors } = useTheme();
  const [tools, setTools] = useState<ToolsProps[]>([]);
  const [searchedTools, setSearchedTools] = useState<ToolsProps[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(false);
  const [searchEmpty, setEmpty] = useState(false);
  const [inSearch, setInSearch] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Carregando um novo array de ferramentas para uma busca mais direta na pesquisa
    const options: ToolsProps[] = [];
    menuConfig.map(item => {
      item.submenu.map(menu => {
        menu.options.map(option => {
          options.push(option);
        });
      });
    });
    setTools(options);
  }, []);

  const handlerVisibility = (): void => {
    setShowContent(!showContent);
  };

  useEffect(() => {
    if (openSearch) {
      setShowContent(true);
    }
  }, [openSearch]);

  const handlerSearch = (textValue: string): void => {
    setSearchQuery(textValue);
    if (textValue.length > 2) {
      setInSearch(true);

      // filtrando as 7 primeiras ferramentas com a descrição pesquisadas
      const options = tools
        .filter(item =>
          String(item.tag).toLowerCase().includes(textValue.toLowerCase()),
        )
        .slice(0, 7);

      setSearchedTools(options);

      if (options.length > 0) {
        setEmpty(false);
        setSearchResults(true);
      } else {
        setEmpty(true);
        setSearchResults(false);
      }

      setInSearch(false);
    } else {
      setEmpty(false);
    }
  };

  return (
    <HeaderSearchContainer>
      {!openSearch && (
        <HeaderSearchControl
          data-tooltip-id="search-button"
          onClick={handlerVisibility}
        >
          <ReactTooltip id="search-button" content="Pesquisar ferramentas" />
          <HeaderSearchControlIc>
            <BiSearch size={22} color={colors.label} />
          </HeaderSearchControlIc>
        </HeaderSearchControl>
      )}
      <Overlay $visible={showContent}>
        <Content $opensearch>
          <Scroll>
            <HeaderSearchArea>
              {/* <HeaderSearchResume handlerVisibility={handlerVisibility} /> */}
              {handlerVisibility && !openSearch && (
                <HeaderSearchResumeContent>
                  <div>
                    <div />
                    <button type="button" onClick={handlerVisibility}>
                      <IoMdCloseCircleOutline size={36} color={colors.label} />
                    </button>
                  </div>
                </HeaderSearchResumeContent>
              )}
              <HeaderSearchInput
                handlerSearch={handlerSearch}
                searchQuery={searchQuery}
              />

              <HeaderSearchResponse>
                {inSearch && <HeaderSearchLoading />}

                {!inSearch && searchQuery && searchResults && (
                  <HeaderSearchResults
                    searchQuery={searchQuery}
                    tools={searchedTools}
                  />
                )}

                {searchEmpty && searchQuery && !inSearch && (
                  <HeaderSearchEmpty />
                )}

                <HeaderSearchCache showCacheTools={showContent} />
              </HeaderSearchResponse>
            </HeaderSearchArea>
          </Scroll>
        </Content>
      </Overlay>
    </HeaderSearchContainer>
  );
};

export default HeaderSearch;
