import styled, { css } from 'styled-components';

import { ContainerProps, InfoProps, MenuProps } from './interface';

export const Container = styled.div<ContainerProps>`
  ${({ theme, $selecionada, $eimpar }) => css`
    width: 100%;
    float: left;
    background: ${$eimpar && !$selecionada
      ? theme.colors.defaultHighlight
      : ($selecionada && theme.colors.liHouver) || theme.colors.background};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border-bottom: 0.1rem solid ${theme.colors.grey};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: ${theme.colors.liHouver};
    }

    @media (max-width: 1024px) {
      align-items: flex-start;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1.5rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
`;

export const Info = styled.span<InfoProps>`
  ${({ $cor, theme }) => css`
    color: ${$cor || theme.colors.label};
    font-size: ${theme.fontSizes.small};
    font-weight: 600;
    display: flex;
    width: calc(100% / 3);
    flex-direction: row;
    align-items: center;
    padding: 0.1rem 0;
    padding-right: 0.2rem;

    small {
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.label};
      margin-right: 0.5rem;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
      padding: 0;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 1rem;
      font-size: ${theme.fontSizes.small};

      small {
        font-size: ${theme.fontSizes.tiny};
        font-weight: normal;
        opacity: 0.6;
      }
    }
  `}
`;

export const Left = styled.div`
  cursor: pointer;
  margin-top: 0.6rem;
`;

export const Right = styled.div`
  margin-top: 0.6rem;
`;

export const Menu = styled.div<MenuProps>`
  ${({ theme, $visible }) => css`
    width: 20rem;
    margin-left: -21rem;
    margin-top: -5rem;
    height: auto;
    position: absolute;
    background: ${theme.colors.background};
    display: flex;
    flex-direction: column;
    border: 0.1rem solid ${theme.colors.grey};
    border-radius: 0.6rem;
    overflow: hidden;

    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? 'visible' : 'hidden'};

    button {
      padding: 1rem;
      border-bottom: 1px solid ${theme.colors.contrast};
      background: none;
      font-size: ${theme.fontSizes.default};
      font-weight: 600;
      text-align: left;
      float: left;
      width: 100%;
      color: ${theme.colors.label};
      transition: all 0.5s ease;

      &:hover {
        background: ${theme.colors.controlFocus};
      }

      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
