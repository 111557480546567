import styled, { keyframes, css } from 'styled-components';
import ScrollArea from 'react-scrollbar';
import { lighten } from 'polished';

import { HeaderMenuContentProps, HeaderMenuLogoProps } from './interface';

export const HeaderMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  float: left;
`;

export const HeaderMenuLogo = styled.div<HeaderMenuLogoProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    height: 100%;
    box-shadow: ${!$visible &&
    'inset 0 -0.1rem 0.6rem -0.1rem rgba(255, 255, 255, 0.5)'};
    width: ${$visible ? '27rem' : 'auto'};
    justify-content: space-between;
    background: ${theme.colors.primaryDark};
    padding: 0 1rem 0 0rem;
    margin-right: 1rem;

    button {
      float: left;
      height: 100%;
      transition: all 0.5s ease;
      padding: 1.7rem 0;
      background: none;
      cursor: pointer;
      caret-color: transparent;
    }

    button:hover {
      border-left: 0.5rem solid #f5f5f5;
    }

    img {
      width: ${$visible ? 'auto' : '5rem'};
      height: ${$visible ? '100%' : '5rem'};
      padding: ${$visible ? '0rem' : '0.5rem'};
      float: left;
      margin-left: 1.5rem;
      margin-right: ${$visible ? '0rem' : '1.5rem'};
      transition: all 0.5s ease;
      /* border-radius: ${$visible ? 'none' : '100%'};
      border: ${$visible
        ? '0rem solid rgba(255,255,255, 0.2)'
        : '0.1rem solid rgba(255,255,255, 0.2)'}; */
    }
  `}
`;

export const HeaderMenuControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
`;

export const HeaderMenuControlIc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  padding: 1rem;
  border-radius: 100%;
  transition: all 0.5s ease;

  &:hover {
    background: #0098da;
  }
`;

const visibleContentAnimation = keyframes`
  from {
    opacity: 0;
    transform:  translate(-100%, 0);
  }
  to {
    opacity: 1;
    transform:  translate(0, 0);
  }
`;

const invisibleContentAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform:  translate(-100%, 0);
  }
`;

export const HeaderMenuContent = styled.div<HeaderMenuContentProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 27rem;
    height: 100%;
    background: linear-gradient(
      180deg,
      ${theme.colors.primaryDark},
      ${theme.colors.primaryDark},
      ${theme.colors.primaryDark},
      ${theme.colors.orange}
    );
    box-shadow: 1rem 0 1rem -0.8rem rgba(115, 115, 115, 0.55);
    top: 8rem;
    opacity: 0;
    z-index: 2000;
    transform: translate(-100%, 0);
    animation: ${$visible ? visibleContentAnimation : invisibleContentAnimation}
      0.6s forwards;

    @media (max-width: 1024px) {
      width: 100%;
      padding-bottom: 8rem;
      background: ${theme.colors.primaryDark};
    }
  `}
`;

export const HeaderMenuLink = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    width: 100%;

    a {
      display: flex;
      width: 100%;
    }

    menu {
      position: fixed;
      left: 27rem;
      top: 0rem;
      height: 100%;
      width: 60rem;
      background: linear-gradient(
        180deg,
        ${theme.colors.primaryDark},
        ${theme.colors.primaryDark},
        ${theme.colors.primaryDark},
        ${theme.colors.orange}
      );
      /* background: ${lighten(0.01, theme.colors.primaryDark)}; */
      /* box-shadow: ${theme.shadows.highlight}; */
      box-shadow: 1rem 0 1rem -0.8rem rgba(115, 115, 115, 0.55);
      opacity: 0;
      z-index: 1800;
      visibility: hidden;
      display: flex;
      flex-direction: row;

      .scrollarea {
        float: left;
        width: 100%;
      }

      .scrollarea-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        list-style-type: none;
        float: left;
        width: 100%;
      }

      ul {
        width: 100%;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;

        span {
          font-size: ${theme.fontSizes.default};
          font-weight: 400;
          cursor: pointer;
          color: ${theme.colors.greyLowerOpacity};
          padding-bottom: 1rem;
          /* text-decoration: underline; */
          transition: all 0.5s ease;
        }

        /* &:hover span {
          color: ${theme.colors.primary};
        } */

        li {
          cursor: pointer;
          font-size: ${theme.fontSizes.large};
          border-left: 0.5rem solid transparent;
          margin-left: -1.5rem;
          /* padding: 0rem 0; */
          /* padding-left: 1rem; */
          transition: all 0.5s ease;

          button {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            color: ${theme.colors.white};
            background-color: transparent;

            span {
              color: ${theme.colors.white};
              padding: 1rem 1.5rem;
              font-weight: 500;
              font-size: 1.6rem;
            }
          }

          &:hover {
            border-color: ${theme.colors.white};
            background: ${theme.colors.primary};
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
          }
        }
      }
    }

    .header-menu-link-title {
      /* border-left: 0.5rem solid #f5f; */
    }

    &:hover {
      menu {
        opacity: 1;
        visibility: visible;
        z-index: 5000;
      }

      .header-menu-link-title {
        background: ${theme.colors.primary};
        border-left: 0.5rem solid #f5f5f5;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;

      menu {
        position: inherit;
        left: 0px;
        top: 0px;
        width: 100%;
        height: auto;
        background: transparent;
        visibility: visible !important;
        opacity: 1 !important;

        .scrollarea-content {
          display: flex;
          flex-direction: column;
        }
      }
    }
  `}
`;

export const HeaderMenuLinkTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    float: left;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    /* box-shadow: inset 0 -1px 12px -8px ${theme.colors.primary}; */
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      background: #f5f5f5;
    }

    span {
      font-size: ${theme.fontSizes.default};
      color: #f5f5f5;
      font-weight: 500;
      /* border: 0.1rem solid #f5f; */
    }

    div {
      display: flex;
      width: 100%;

      svg {
        margin-right: 1rem;
      }
    }

    @media (max-width: 1024px) {
      svg:last-child {
        display: none;
      }
    }
  `}
`;

export const Scroll = styled(ScrollArea).attrs({
  contentStyle: {
    paddingBottom: 20,
  },
})``;

export const NameLogo = styled.div`
  ${({ theme }) => css`
    /* display: flex;
    flex-direction: column; */

    span {
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.white};
      font-weight: bold;
    }
    span:nth-of-type(2n) {
      font-size: ${theme.fontSizes.big};
      color: ${theme.colors.orange};
      font-weight: bold;
    }
  `}
`;
