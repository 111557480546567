import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 2rem;
`;

export const Line = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    border-bottom: 0.1rem solid ${theme.colors.grey};
    transition: all 0.5s ease;
    background: ${theme.colors.default};

    &:nth-child(odd) {
      background: ${theme.colors.defaultHighlight};
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: ${theme.colors.liHouver};
    }

    span {
      width: 50%;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.default};
      float: left;
      padding: 1rem;
      font-weight: 600;
    }

    span:first-child {
      border-right: 0.1rem solid ${theme.colors.grey};
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 20rem;
  }
`;
