import React, { useState } from 'react';

import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { GoGraph } from 'react-icons/go';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import { ImCheckmark } from 'react-icons/im';
import { HiDocument } from 'react-icons/hi';
import { BsFillGridFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';

import { Button } from '~/components';

import {
  Container,
  Content,
  Summary,
  SummaryLeft,
  SummaryRight,
  Config,
  Title,
  IC,
  Control,
  List,
  Card,
  Info,
  Right,
  Name,
  Description,
  Generate,
} from './styles';

import { TipoProps } from './interface';

const Produto: React.FC<TipoProps> = ({
  tipo,
  tabelabilidade,
  controleDeTabelabilidade,
}) => {
  const { colors } = useTheme();
  const history = useNavigate();

  const [listagemVisivel, setListagemVisivel] = useState(false);

  const inverterVisibilidade = () => {
    setListagemVisivel(!listagemVisivel);
  };

  const selecionarTipo = (id: string) => {
    history(`/relatorio/${id}`);
  };

  const tipos = [
    {
      id: 'tipo-1',
      nome: 'Tipo 1',
      descricao: 'Veja a relação de pedidos de um determinado período',
    },
    {
      id: 'tipo-2',
      nome: 'Tipo 2',
      descricao: 'Veja a relação de pedidos de um determinado período',
    },
    {
      id: 'tipo-3',
      nome: 'Tipo 3',
      descricao: 'Veja a relação de pedidos de um determinado período',
    },
    {
      id: 'tipo-4',
      nome: 'Tipo 4',
      descricao: 'Veja a relação de pedidos de um determinado período',
    },
  ];

  return (
    <Container>
      <Content>
        <Summary>
          <SummaryLeft>
            <IC>
              <GoGraph size={36} color={colors.label} />
            </IC>
            <Title>{`Relatorío ${tipo}`}</Title>
            <Control onClick={inverterVisibilidade}>
              {listagemVisivel ? (
                <MdArrowDropUp size={72} color={colors.label} />
              ) : (
                <MdArrowDropDown size={72} color={colors.label} />
              )}
            </Control>
          </SummaryLeft>
          <SummaryRight>
            <Config onClick={controleDeTabelabilidade}>
              {tabelabilidade ? (
                <BsFillGridFill size={32} color={colors.label} />
              ) : (
                <FaThList size={32} color={colors.label} />
              )}
            </Config>
          </SummaryRight>
        </Summary>

        {listagemVisivel ? (
          <List>
            {tipos.map(cTipo => (
              <React.Fragment key={cTipo.id}>
                <Card>
                  <Info>
                    <HiDocument size={80} color={colors.label} />
                    <Right>
                      <Name>{`Relatório ${cTipo.nome}`}</Name>
                      <Description>{cTipo.descricao}</Description>
                      {tipo !== cTipo.id ? (
                        <Generate>
                          <Button
                            onClick={() => selecionarTipo(cTipo.id)}
                            type="button"
                            background={colors.green}
                          >
                            SELECIONAR
                          </Button>
                        </Generate>
                      ) : null}
                    </Right>
                  </Info>
                  {tipo === cTipo.id ? (
                    <ImCheckmark size={42} color={colors.green} />
                  ) : null}
                </Card>
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Content>
    </Container>
  );
};

export default Produto;
