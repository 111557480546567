import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { MdNotificationsActive } from 'react-icons/md';

import {
  HeaderNotificationsContainer,
  HeaderNotificationsControl,
  HeaderNotificationsControlIc,
  HeaderNotificationsCount,
  HeaderNotificationsContent,
} from './styles';

const HeaderNotifications: React.FC = () => {
  const { colors } = useTheme();
  const [showContent, setShowContent] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handlerHideContent = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setShowContent(false);
    }
  };

  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlerHideContent, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handlerHideContent, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <HeaderNotificationsContainer ref={ref}>
      <HeaderNotificationsControl onClick={() => setShowContent(!showContent)}>
        <HeaderNotificationsControlIc>
          <MdNotificationsActive size={26} color={colors.label} />
        </HeaderNotificationsControlIc>
        <HeaderNotificationsCount>0</HeaderNotificationsCount>
      </HeaderNotificationsControl>
      <HeaderNotificationsContent $visible={showContent}>
        <h1>Nenhuma notificação!</h1>
      </HeaderNotificationsContent>
    </HeaderNotificationsContainer>
  );
};

export default HeaderNotifications;
