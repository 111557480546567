import styled, { css, keyframes } from 'styled-components';
import ScrollArea from 'react-scrollbar';

import {
  FinderContainerProps,
  FinderItemProps,
  FinderLabelProps,
} from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinderContainer = styled.div<FinderContainerProps>`
  ${({ theme, $visible }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0rem;
    top: 0rem;
    background: ${theme.colors.overlay};
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? '$visible' : 'hidden'};

    @media (max-width: 1024px) {
      padding: 2rem;
    }
  `}
`;

export const FinderContent = styled.div`
  ${({ theme }) => css`
    width: 68rem;
    height: auto;
    border-radius: 0.6rem;
    background: ${theme.colors.background};
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    padding: 2rem 2rem;

    header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-bottom: 2rem;

      div {
        background: ${theme.colors.controlNotFocus};
        border-radius: ${theme.radio.default};
        border: 0.2rem solid ${theme.colors.defaultHighlight};
        /* color: #666360; */
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        padding: 1rem;
        // margin-right: 1rem;

        button {
          margin-left: 0.5rem;
          cursor: pointer;
          transition: all 0.5s ease;
          background: none;

          &:hover {
            opacity: 0.6;
          }
        }

        input {
          flex: 1;
          background: transparent;
          border: 0;
          color: ${theme.colors.label};
          text-transform: uppercase;
          /* font-weight: 400; */
          font-size: 1.8rem;

          &::placeholder {
            color: ${theme.colors.placeholder};
          }

          ::-webkit-input-placeholder {
            /* WebKit browsers */
            text-transform: none;
          }
          :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            text-transform: none;
          }
          ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            text-transform: none;
          }
          :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            text-transform: none;
          }
          ::placeholder {
            /* Recent browsers */
            text-transform: none;
          }

          &:-webkit-autofill {
            border: none;
            /* -webkit-box-shadow: 0 0 0rem 100rem ${theme.colors
              .secondary} inset; */
            -webkit-box-shadow: transparent !important;
            -webkit-text-fill-color: ${theme.colors.label};
            transition: background-color 5000s ease-in-out 0s;
          }

          &::-ms-reveal {
            background-color: ${theme.colors.white} !important;
            border-radius: 0.3rem;
            padding: 0.1rem;
            margin-top: 0.1rem;
            /* display: none; */
          }
        }
      }

      /* button {
        cursor: pointer;
        transition: all 0.5s ease;
        background: none;
        &:hover {
          opacity: 0.2;
        }
      } */
    }

    main {
      float: left;
      width: 100%;
    }
  `}
`;

export const FinderLabel = styled.div<FinderLabelProps>`
  ${({ theme, $onlysearch }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    float: left;

    button {
      background: ${$onlysearch ? 'transparent' : theme.colors.controlNotFocus};
      border-radius: ${theme.radio.default};
      width: 100%;
      border: ${!$onlysearch
        ? `0.2rem solid ${theme.colors.defaultHighlight}`
        : null};
      color: ${theme.colors.label};
      display: flex;
      align-items: center;
      height: 5rem;
      /* margin-right: 5px; */
      float: left;
      padding: ${!$onlysearch ? `0 1rem 0 1rem` : 0};
      justify-content: center;

      cursor: pointer;
      transition: all 0.5s ease;

      span {
        flex: 1;
        font-size: ${theme.fontSizes.large};
        float: left;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0rem;
        margin: 0rem;
      }

      span.empty {
        color: ${theme.colors.placeholder};
      }

      span.isok {
        color: ${theme.colors.label};
      }
    }

    ${!$onlysearch &&
    css`
      svg {
        margin-left: 1rem;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          opacity: 0.5;
        }
      }
    `}
  `}
`;

const finderAnimation = keyframes`
  	0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
`;

export const FinderLoading = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    float: left;
    transition: all 0.5s ease;
    animation: ${finderAnimation} 2s linear infinite;
  }
`;

export const FinderEmpty = styled.div`
  ${({ theme }) => css`
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: ${theme.colors.placeholder};
    padding-bottom: 2rem;
  `}
`;

export const FinderList = styled.div`
  float: left;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;

  .finder-scroll-scrollarea {
    float: left;
    height: 50rem;
    width: 100%;
  }

  .finder-scroll-scrollarea-content {
    width: 100%;
    float: left;
  }
`;

export const FinderScroll = styled(ScrollArea).attrs({
  className: 'finder-scroll-scrollarea',
  contentClassName: 'finder-scroll-scrollarea-content',
  contentStyle: {
    paddingBottom: 0,
  },
})`
  height: 50rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.defaultHighlight};
`;

export const FinderItem = styled.div<FinderItemProps>`
  ${({ theme, $active }) => css`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border: 0.1rem solid ${theme.colors.defaultHighlight};
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    background: ${theme.colors.controlNotFocus};
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-left-color: ${$active
      ? theme.colors.primary
      : 'rgba(255,255,255,0.1)'};

    &:hover {
      background: ${theme.colors.controlFocus};
      // border-color: rgba(255, 255, 255, 0.3);
      border-color: ${theme.colors.primaryDark};
    }

    svg {
      float: left;
      opacity: ${$active ? '1' : '0.2'};
    }

    span {
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.1rem 0 0 1.5rem;
      padding: 0;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      span {
        font-size: ${theme.fontSizes.default};
        color: ${theme.colors.placeholder};
      }

      strong {
        font-size: ${theme.fontSizes.default};
        margin: 0.2rem 0.8rem 0rem 0.8rem;
      }
    }
  `}
`;

export const ContentButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;

  button {
    width: 100%;
  }

  button + button {
    margin-left: 1rem;
  }
`;
