import styled, { css } from 'styled-components';

import { ContentProps } from './interface';

export const Container = styled.div`
  ${({ theme }) => css`
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.background};
    box-shadow: ${theme.shadows.highlight};
    border-right: 0.1rem solid;
    flex: 1;
    border-color: ${theme.colors.defaultHighlight};
    padding-bottom: 2rem;
  `};
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.verticalidade ? '80rem' : '36rem')};
  float: left;
`;

export const Nav = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    float: left;
    width: 100%;
    padding: 0.5rem;
    background: ${theme.colors.overlay};
    border: 0.1rem solid;
    border-color: ${theme.colors.defaultHighlight};
  `};
`;

export const Control = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: none;
  padding: 1rem;
`;
