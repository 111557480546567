import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0rem;
  top: 0rem;

  @media (max-width: 1024px) {
    position: inherit;
    flex-direction: column;
  }
`;

export const ToolBar = styled.div`
  ${({ theme }) => css`
    width: 10rem;
    height: 100%;
    background: ${theme.colors.defaultHighlight};
    display: flex;
    flex-direction: column;
    height: 100%;
    float: left;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  `}
`;

export const ToolBarContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ToolLogo = styled.button.attrs({
  type: 'button',
})`
  background: #0175a7;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 2rem;
  &:hover {
    background: ${lighten(0.05, '#0175a7')};
  }
  img {
    float: left;
    max-width: 5rem;
  }
`;

export const NameLogo = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    span {
      font-size: ${theme.fontSizes.large};
      color: ${theme.colors.white};
      font-weight: bold;
    }
    span:nth-of-type(2n) {
      font-size: ${theme.fontSizes.large};
      color: ${theme.colors.orange};
      font-weight: bold;
    }
  `}
`;

export const ToolBtn = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    background: none;
    display: flex;
    flex-direction: column;
    float: left;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 2rem;
    &:hover {
      background: ${theme.colors.defaultHighlight};
    }
    span {
      font-size: 1.6rem;
      color: ${theme.colors.label};
      margin-top: 1rem;
    }

    @media (max-width: 1024px) {
      flex-direction: row;
      flex: 1;
      width: auto;
      justify-content: flex-start;
      padding: 0rem 2rem;

      span {
        font-size: 1.6rem;
        padding: 2rem;
      }

      svg {
        width: 3.2rem;
      }
    }
  `}
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  flex-direction: column;
  padding: 0 1.5rem 1.5rem 1.5rem;
  height: 100%;
  float: left;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    background: ${theme.colors.background};
    padding: 1.5rem;
    height: 100%;
    float: left;
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  `}
`;
