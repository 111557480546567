import React from 'react';
import { InfoProps } from './interface';
import {
  HeaderDescription,
  HeaderDescriptionInfoLeft,
  HeaderDescriptionInfoRight,
  StatusTag,
} from './styles';

const Info: React.FC<InfoProps> = ({
  data_abertura,
  status,
  descricao,
  compartilhada,
}) => {
  return (
    <HeaderDescription>
      <HeaderDescriptionInfoLeft>
        <div>
          <strong>Descrição</strong>
          <span>{descricao}</span>
        </div>

        <div>
          <strong>Data de abertura</strong>
          <span>{data_abertura}</span>
        </div>
      </HeaderDescriptionInfoLeft>

      <HeaderDescriptionInfoRight>
        <div>
          <strong>Compartilhado?</strong>
          <span>{compartilhada ? 'Sim' : 'Não'}</span>
        </div>
        <div>
          <strong>Status</strong>
          <StatusTag status={status}>{status}</StatusTag>
        </div>
      </HeaderDescriptionInfoRight>
    </HeaderDescription>
  );
};

export default Info;
