import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 90px;
  padding: 3rem;
  overflow: hidden;
  // z-index: 6000; Jhown aqui estava impactando no summary do header

  &:empty {
    padding: 0rem;
  }
`;
