import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { Button } from '~/components';

import { Container, Content, Area, Item, Control, Footer } from './styles';

import { SomatizadoresProps } from './interface';

const Somatizadores: React.FC<SomatizadoresProps> = () => {
  const { colors } = useTheme();

  const itens = ['Coluna 1', 'Coluna 2', 'Coluna 3', 'Coluna 4'];

  const [inativos, setInativos] = useState<string[]>([]);

  const verificarInatividade = (pItem: string) => {
    return inativos.includes(pItem);
  };

  const inverterInatividade = (pItem: string) => {
    if (verificarInatividade(pItem)) {
      setInativos([...inativos.filter(cItem => cItem !== pItem)]);
    } else {
      setInativos([...inativos, pItem]);
    }
  };

  return (
    <Container>
      <Content>
        <Area>
          {itens.map(item => (
            <Item key={item} inativo={verificarInatividade(item)}>
              <Control onClick={() => inverterInatividade(item)}>
                {verificarInatividade(item) ? (
                  <ImCheckboxUnchecked
                    size={42}
                    color={colors.defaultHighlight}
                  />
                ) : (
                  <ImCheckboxChecked size={42} color={colors.label} />
                )}
              </Control>
              {item}
            </Item>
          ))}
        </Area>

        <Footer>
          <div>
            <Button type="button" background={colors.green}>
              APLICAR
            </Button>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Somatizadores;
