import React from 'react';

import { ListPaginationContainer, Container } from './styles';

import { ListPaginationProps } from './interface';

const ListPagination: React.FC<ListPaginationProps> = ({
  onRequestChangePage,
  activePage,
  totalPages,
}) => {
  const handlerOnRequestChangePage = (page: number) => {
    if (onRequestChangePage) {
      onRequestChangePage(`${page}`);
    }
  };

  const lastPage = totalPages;
  const firstPage = 1;

  let beforePage = activePage - 1;
  if (beforePage <= 0) {
    beforePage = 1;
  }
  if (beforePage === activePage) {
    beforePage = 0;
  }

  let afterPage = activePage + 1;
  if (afterPage >= totalPages) {
    afterPage = 0;
  }

  return (
    <Container>
      <span>
        * Utilize a primeira coluna para filtrar ou ordenar os registros.
      </span>
      <ListPaginationContainer>
        <button
          type="button"
          onClick={() => handlerOnRequestChangePage(firstPage)}
        >
          &laquo;
        </button>
        <span>...</span>
        {beforePage ? (
          <button
            type="button"
            onClick={() => handlerOnRequestChangePage(beforePage)}
          >
            {beforePage}
          </button>
        ) : null}
        <button
          type="button"
          onClick={() => handlerOnRequestChangePage(activePage)}
          className="active"
        >
          {activePage}
        </button>
        {afterPage ? (
          <button
            type="button"
            onClick={() => handlerOnRequestChangePage(afterPage)}
          >
            {afterPage}
          </button>
        ) : null}
        <span>...</span>
        <button
          type="button"
          onClick={() => handlerOnRequestChangePage(lastPage)}
        >
          &raquo;
        </button>
      </ListPaginationContainer>
    </Container>
  );
};

export default ListPagination;
