import React, { useEffect } from 'react';

import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Form } from '@unform/web';

import { Container } from './styles';

import { ModalValorProps } from './interface';

import { Button, InputNumber, Modal } from '~/components';
import { FormContainer } from './styles';

const ModalValor: React.FC<ModalValorProps> = ({
  name,
  title,
  visible,
  onRequestClose,
  onRequestSave,
}) => {
  const { colors } = useTheme();
  const handlerOnRequestClose = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handlerOnRequestSave = useCallback(
    (data: any) => {
      const { [name]: valor } = data;
      onRequestSave(valor.replace(',', '.'));
      onRequestClose();
    },
    [onRequestSave, onRequestClose, name],
  );

  useEffect(() => {
    setTimeout(() => {
      const inputValorNode = document.querySelector<HTMLInputElement>(
        `input[name=${name}]`,
      );
      if (inputValorNode) {
        inputValorNode.focus();
      }
    }, 300);
  }, [visible, name]);

  return (
    <Modal
      title={title}
      width="40rem"
      visible={visible}
      scrollable
      onRequestClose={handlerOnRequestClose}
    >
      <Container>
        <FormContainer>
          <Form onSubmit={handlerOnRequestSave}>
            <InputNumber name={name} placeholder="0,00" selectAllInFocus />
            <Button type="submit" background={colors.green}>
              Confirmar
            </Button>
          </Form>
        </FormContainer>
      </Container>
    </Modal>
  );
};

export default ModalValor;
